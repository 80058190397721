import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/JobReasonReport/Filter'

export default createListViewComponent({
  entityName: entityNames.jobReasonReport,
  filterTemplateType: 'JobReasonReport',
  initialFilterDto: (self) => ({
    dateRange: { dateRangeType: 'YearToDate' },
    completionStatus: 'All',
  }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
})(Filter)
