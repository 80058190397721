import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import Component from '../components/Settings'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
})

const mapDispatchToProps = (dispatch, props) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
