import { snakeCase } from 'lodash'
import { createActions } from 'helpers/actions'
import { createPost, createGet } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.fileTemplates
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName),

  uploadFile: (params) => ({
    type: `${ENTITY_NAME}_UPLOAD_FILE`,
    payload: createPost(entityName, { action: 'upload' })(params),
  }),

  getValidSettings: ({ fileTemplateId }) => ({
    type: `${ENTITY_NAME}_VALID_SETTINGS_GET`,
    payload: createGet(entityName, { action: `${fileTemplateId}/clientSettings` })(),
  }),

  getInvalidSettings: ({ fileTemplateId }) => ({
    type: `${ENTITY_NAME}_INVALID_SETTINGS_GET`,
    payload: createGet(entityName, { action: `${fileTemplateId}/clientSettings` })({ type: 'error' }),
  }),

  getUploadProgress: () => ({
    type: `${ENTITY_NAME}_UPLOAD_PROGRESS_GET`,
    payload: createPost(entityName, { action: 'getProgress' })(),
  }),

  getUploadResults: (params) => ({
    type: `${ENTITY_NAME}_UPLOAD_RESULTS_GET`,
    payload: createPost(entityName, { action: 'listUploadResults' })(params),
  }),

  downloadUploadResults: ({ acceptMimeType, ...rest }) => ({
    type: `${ENTITY_NAME}_UPLOAD_RESULTS_DOWNLOAD`,
    payload: createPost(entityName, {
      action: 'listUploadResults',
      responseType: 'blob',
      acceptMimeType,
    })({ ...rest, pageIndex: 0, pageSize: undefined }),
  }),

  applyUpdate: () => ({
    type: `${ENTITY_NAME}_APPLY_UPDATE`,
    payload: createPost(entityName, { action: 'applyUpdate' })(),
  }),

  cancelUpload: () => ({
    type: `${ENTITY_NAME}_UPLOAD_CANCEL`,
    payload: createPost(entityName, { action: 'cancelUpload' })(),
  }),

  clearUploadResults: () => ({
    type: `${ENTITY_NAME}_UPLOAD_RESULTS_CLEAR`,
    payload: createPost(entityName, { action: 'clearUploadResults' })(),
  }),

  getFileTemplateFields: ({ objectType = '' } = {}) => ({
    type: `${ENTITY_NAME}_FIELDS_GET`,
    payload: createGet(entityName, { action: 'fieldSettings' })({ objectType }),
  }),
}
