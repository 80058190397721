import { camelCase } from 'lodash'
import { createListModalComponent } from 'factories/ListModal'
import Filter from 'containers/Lists/Associations/Filter'

export default createListModalComponent({
  getStorageKey: (self) => `lists.associations.${camelCase(self.props.settingsType)}`,
  getSettingsType: (self) => self.props.settingsType,
  transformGetItemsParams: (self) => ({
    listType: self.props.filterProps.listType,
    listId: self.props.filterProps.listId,
  }),
  allowDelete: (self) => self.props.allowDelete,
})(Filter)
