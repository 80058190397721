import * as React from 'react'
import produce from 'immer'
import { Pagination, Spin, Form, Table, Menu } from 'antd'
import { get, debounce, isEmpty, pick } from 'lodash'
import { showError } from 'helpers/errors'
import {
  getColumns,
  mapGetItemsFields,
  showTotal,
  createHandleCustomizeOk,
  pageSizeOptions,
  createHandleColumnResize,
  createListViewHandleTableChange,
  getTableWidth,
} from 'helpers/listViews'
import { getKeyValueItem, setKeyValueItem } from 'helpers/keyValueStorage'
import { createResetState, DEBOUNCE } from 'helpers/utils'
import { tableCellComponents } from 'helpers/listItems'
import { t } from 'helpers/i18n'
import { Emitter } from 'helpers/events'
import { LIST_MODAL_CLEAR_SELECTION } from 'options/events'
import Customize from 'elements/Customize'
import ResizableCell from 'elements/ResizableCell'
import Select, { Option, getOptionProps } from 'elements/Select'
import Toolbar from 'elements/Toolbar'
import FilterContainer from 'elements/FilterContainer'
import SelectedRows from 'elements/SelectedRows'
import { Row, Col } from 'elements/Grid'

const getStorageKey = (self) => `selectListItems.${self.props.settingsType}`

class Component extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.searchItems = debounce(this.fetchItems, DEBOUNCE)
    this.handleCustomizeOk = createHandleCustomizeOk(this)
    this.handleColumnResize = createHandleColumnResize(this)
    this.handleTableChange = createListViewHandleTableChange(this)
    this.resetState = createResetState(this, {
      search: '',
      searchFields: [],
      searchType: 'AllWords',
      pageIndex: 1,
      pageSize: 20,
      selectedRowKeys: [],
      selectedColumnKeys: [],
      sortedColumnKeys: [],
      columnWidths: {},
      customizeColumnsVisible: false,
      sortBy: 'barcode',
      sortOrder: 'ascend',
    })
    this.saveState = debounce(this.saveState, 2500)
  }

  async componentDidMount() {
    const savedState = await getKeyValueItem(getStorageKey(this), {})
    await this.resetState(savedState)
    await this.fetchSettings()

    Emitter.on(LIST_MODAL_CLEAR_SELECTION, this.clearSelection)
  }

  componentWillUnmount() {
    this.saveState.flush()
    Emitter.off(LIST_MODAL_CLEAR_SELECTION, this.clearSelection)
  }

  clearSelection = () =>
    this.setState({ selectedRowKeys: [] }, () => {
      this.props.onSelect?.([], { listId: this.state.listId })
    })

  fetchSettings = async () => {
    try {
      const { listType = 'Product' } = this.props.filterProps ?? {}
      const responses = await Promise.all([
        this.props.getSettings({ type: this.props.settingsType }),
        this.props.getLists({ type: listType }),
      ])

      this.setState(
        {
          fieldSettings: responses[0].value.data.fieldSettings,
          lists: get(responses[1], 'value.data.items', []),
        },
        this.fetchItems
      )

      return responses
    } catch (error) {
      showError({ error })

      return error
    }
  }

  getListItemMode = () =>
    `${this.props.filterProps?.listType ?? 'Product'}List` +
    `${this.props.filterProps?.entityType ?? 'Product'}Record`

  fetchItems = async () => {
    const {
      listType,
      locationId,
      listItemMode = this.getListItemMode(),
      domainObjectType = 'None',
    } = this.props.filterProps ?? {}
    const { fieldSettings = [] } = this.state
    const listId = this.state.listId || get(this.state, 'lists[0].id')

    if (listId) {
      try {
        this.setState({ listId, loading: true })

        const response = await this.props.getItems(listId, {
          listId,
          listType,
          locationId,
          listItemMode,
          domainObjectType,
          ...pick(this.state, ['pageIndex', 'pageSize', 'search', 'searchType']),
          ...mapGetItemsFields({
            fieldSettings,
            ...pick(this.state, ['sortBy', 'sortOrder', 'selectedColumnKeys', 'searchFields']),
          }),
        })

        this.setState(
          produce((draft) => {
            draft.items = response?.value?.data?.items ?? []
            draft.pagination = draft.pagination ?? {}
            draft.pagination.current = draft.pageIndex
            draft.pagination.pageSize = draft.pageSize
            draft.pagination.total = response?.value?.data?.recordCount ?? draft.items.length ?? 0
          })
        )
      } catch (error) {
        showError({ error })
      } finally {
        this.setState({ loading: false, selectedRowKeys: [] }, () => {
          this.props.onFilter?.(this.state.filterDto)
          this.props.onSelect?.([], this.state.filterDto)
          this.saveState()
        })
      }
    }
  }

  saveState = () => {
    if (window.location.search) {
      return
    }

    setKeyValueItem(
      getStorageKey(this),
      pick(this.state, [
        'columnWidths',
        'pageSize',
        'selectedColumnKeys',
        'sortBy',
        'sortOrder',
        'sortedColumnKeys',
      ])
    )
  }

  handlePaginationChange = (pageIndex, pageSize) => this.setState({ pageIndex, pageSize }, this.fetchItems)

  handleListIdChange = (value) => {
    this.props.onFilter?.({ listId: value })

    this.setState({ listId: value, pageIndex: 1 }, this.fetchItems)
  }

  render() {
    const { items = [], pagination = {}, fieldSettings = [] } = this.state

    if (isEmpty(fieldSettings)) {
      return <Spin />
    }

    const columns = getColumns({
      self: this,
      fieldSettings,
      tableCellComponents,
      onResize: this.handleColumnResize,
      ...pick(this.state, ['sortBy', 'sortOrder', 'selectedColumnKeys', 'sortedColumnKeys', 'columnWidths']),
    })
    const width = isEmpty(columns) ? '100%' : getTableWidth(columns, true)

    return (
      <>
        <div className="tofino-list-modal">
          <div className="tofino-inner-container">
            <div className="tofino-toolbar-container">
              <Toolbar
                search={this.state.search}
                onSearchChange={(e) =>
                  this.setState({ search: e.target.value, pageIndex: 1 }, this.searchItems)
                }
                fieldSettings={fieldSettings}
                searchFields={this.state.searchFields}
                onSearchFieldsChange={(values) => this.setState({ searchFields: values }, this.fetchItems)}
                searchType={this.state.searchType}
                onSearchTypeChange={(value) => this.setState({ searchType: value }, this.fetchItems)}
                actionsMenuItems={[
                  fieldSettings.length > 1 ? (
                    <Menu.Item key="customizeColumns">{t('customizeColumns')}</Menu.Item>
                  ) : null,
                ].filter(Boolean)}
                onActionsMenuClick={({ key }) => {
                  if (key === 'customizeColumns') {
                    this.setState({ customizeColumnsVisible: true })
                  }
                }}
              />
            </div>
            <FilterContainer>
              <Row>
                <Col third>
                  <Form.Item label={t('list')} colon={false}>
                    <Select
                      value={this.state.listId}
                      onChange={this.handleListIdChange}
                      placeholder={t('select')}
                      allowClear={false}
                    >
                      {(this.state.lists ?? []).map((each) => (
                        <Option key={each.id} value={each.id}>
                          <span {...getOptionProps(each)}>{each.name}</span>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </FilterContainer>
            <div className="mb-12">
              <SelectedRows selectedRowKeys={this.state.selectedRowKeys} />
            </div>
            <div className="tofino-table-container">
              <Table
                childrenColumnName={[]}
                rowKey={(record) => record.id}
                loading={this.state.loading}
                columns={columns}
                dataSource={items}
                pagination={false}
                onChange={this.handleTableChange}
                size="middle"
                locale={{ emptyText: t('noData') }}
                rowSelection={{
                  selectedRowKeys: this.state.selectedRowKeys,
                  onChange: (rowKeys, rows) =>
                    this.setState({ selectedRowKeys: rowKeys }, () => {
                      this.props.onSelect?.(rows)
                    }),
                }}
                style={{ width }}
                components={{ header: { cell: ResizableCell } }}
              />
            </div>
            {!isEmpty(items) && pagination && (
              <div style={{ display: 'flex', padding: '12px 0' }}>
                <Pagination
                  current={pagination.current ?? 0}
                  total={pagination.total ?? 0}
                  pageSize={pagination.pageSize ?? 0}
                  pageSizeOptions={pageSizeOptions}
                  onChange={this.handlePaginationChange}
                  onShowSizeChange={this.handlePaginationChange}
                  showTotal={showTotal}
                  size="small"
                  showTitle={false}
                  showSizeChanger
                  showLessItems
                />
              </div>
            )}
          </div>
        </div>
        <Customize
          displayableColumns={fieldSettings.filter((each) => each.isDisplayable)}
          visible={this.state.customizeColumnsVisible}
          onOk={this.handleCustomizeOk}
          onCancel={() => this.setState({ customizeColumnsVisible: false })}
          selectedColumnKeys={this.state.selectedColumnKeys}
          sortedColumnKeys={this.state.sortedColumnKeys}
          columnWidths={this.state.columnWidths}
        />
      </>
    )
  }
}

export default Component
