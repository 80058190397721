import { snakeCase } from 'lodash'
import { createGet } from 'helpers/api'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'

const entityName = entityNames.tenants
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName),

  getTenantInfo: (params) => ({
    type: `${ENTITY_NAME}_INFO_GET`,
    payload: createGet(entityName, {
      action: `subdomain/${encodeURIComponent(params.subdomain || 'undefined')}`,
    })(),
  }),
}
