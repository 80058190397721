export default [
  'Arial, Helvetica, sans-serif',
  "'Arial Black', Gadget, sans-serif",
  "'Comic Sans MS', Textile, cursive",
  "'Courier New', Courier, monospace",
  "Georgia, 'Times New Roman', Times, serif",
  'Impact, Charcoal, sans-serif',
  "'Lucida Console', Monaco, monospace",
  "'Lucida Sans Unicode', 'Lucida Grande', sans-serif",
  "'Palatino Linotype', 'Book Antiqua', Palatino, serif",
  'Tahoma, Geneva, sans-serif',
  "'Times New Roman', Times, serif",
  "'Trebuchet MS', Helvetica, sans-serif",
  'Verdana, Geneva, sans-serif',
]
