import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import usageGroupings from 'options/usageGroupings'
import Select, { Option, getOptionProps } from 'elements/Select'
import SelectProductCategories from 'containers/ProductCategories/Select'
import DateRangePicker from 'elements/DateRangePicker'
import { Row, Col } from 'elements/Grid'
import {
  createDropdownRender,
  createSearchJobs,
  createSearchAssets,
  createSearchOperators,
  createSearchSuppliers,
} from 'helpers/formViews'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Checkbox from 'elements/Checkbox'
import InputNumber from 'elements/InputNumber'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchAssets = createSearchAssets(this)
    this.searchOperators = createSearchOperators(this)
    this.searchSuppliers = createSearchSuppliers(this)
    this.searchJobs = createSearchJobs(this)
  }

  async componentDidMount() {
    const {
      locationGroupIds,
      locationIds,
      jobsForAssetIds,
      operatorGroupIds,
      operatorIds,
      supplierIds,
      jobIds,
      includeInactiveSuppliers,
      productCategoryIds,
    } = this.props.filterDto

    try {
      await Promise.all([
        this.props.getLocationGroups(),
        this.props.getLocations({ locationGroupIds, locationIds }),
        this.props.getJobs(jobIds ? { jobIds } : undefined),
        this.props.getAssetCategories(),
        this.props.getAssets(jobsForAssetIds ? { assetIds: jobsForAssetIds } : undefined),
        this.props.getSuppliers({ supplierIds, active: includeInactiveSuppliers ? 'All' : 'Active' }),
        this.props.getOperatorGroups(),
        this.props.getOperators({ operatorIds, operatorGroupIds }),
        this.props.getProductCategories({ productCategoryIds }),
        this.props.getUsers({ vending: 'All' }),
      ])
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto, jobs, assets, operators, suppliers } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker filterDto={filterDto} setFilterValue={this.setFilterValue} />
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('job')}>
              <Select
                onChange={(value) => this.setFilterValue('jobIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.jobIds}
                dropdownRender={createDropdownRender(jobs)}
                onSearch={this.searchJobs}
                showSearch
              >
                {(jobs?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('assetCategory')}>
              <Select
                onChange={(value) => this.setFilterValue('jobsForAssetCategoryIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.jobsForAssetCategoryIds}
              >
                {(this.props.assetCategories ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('asset')}>
              <Select
                onChange={(value) => this.setFilterValue('jobsForAssetIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.jobsForAssetIds}
                dropdownRender={createDropdownRender(assets)}
                onSearch={this.searchAssets}
                showSearch
              >
                {(assets?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('productCategory')}>
              <SelectProductCategories
                value={filterDto.productCategoryIds}
                placeholder={t('all')}
                onChange={(value) => this.setFilterValue('productCategoryIds', value)}
                dataSource={this.props.productCategories ?? []}
              />
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('operatorGroups')}>
              <Select
                onChange={(values) => this.setFilterValue('operatorGroupIds', values)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.operatorGroupIds}
              >
                <Option value={-1}>{t('unassigned')}</Option>
                {(this.props.operatorGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('operator')}>
              <Select
                onChange={(value) => this.setFilterValue('operatorIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.operatorIds}
                dropdownRender={createDropdownRender(operators)}
                onSearch={this.searchOperators}
                showSearch
              >
                {(operators?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('suppliers')}>
              <Select
                onChange={(value) => this.setFilterValue('supplierIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.supplierIds}
                dropdownRender={createDropdownRender(suppliers)}
                onSearch={this.searchSuppliers}
                showSearch
              >
                {(suppliers?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('user')}>
              <Select
                onChange={(value) => this.setFilterValue('userNames', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.userNames}
                showSearch
              >
                {(this.props.users ?? []).map((each) => (
                  <Option key={each.userName} value={each.userName}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('orderId')}>
              <InputNumber
                onChange={(value) => this.setFilterValue('orderId', value)}
                value={filterDto.orderId}
                placeholder={t('all')}
                allowSteppers={false}
                allowClear
              />
            </Form.Item>
            <Form.Item label={t('reportGrouping')}>
              <Select
                onChange={(value) => this.setFilterValue('reportGroupings', value)}
                mode="multiple"
                placeholder={t('none')}
                value={filterDto.reportGroupings}
              >
                {Object.entries(usageGroupings).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Checkbox
                onChange={(e) => this.setFilterValue('includeInactiveLocations', e.target.checked)}
                checked={filterDto.includeInactiveLocations}
              >
                {t('includeInactiveLocations')}
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox
                onChange={(e) => this.setFilterValue('includeInactiveSuppliers', e.target.checked)}
                checked={filterDto.includeInactiveSuppliers}
              >
                {t('includeInactiveSuppliers')}
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox
                onChange={(e) => this.setFilterValue('includeInactiveUsers', e.target.checked)}
                checked={filterDto.includeInactiveUsers}
              >
                {t('includeInactiveUsers')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
