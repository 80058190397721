import * as React from 'react'
import styled from 'styled-components'
import { Input, Button, Dropdown, Menu, Form, Radio, Badge, Tooltip } from 'antd'
import { orderBy } from 'lodash'
import { mapSearchFields } from 'helpers/listViews'
import { t } from 'helpers/i18n'
import Select, { Option } from 'elements/Select'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import { Row, Col } from 'elements/Grid'

const SearchContainer = styled(Col)`
  @media print {
    display: none;
  }

  text-align: center;
  margin-bottom: 12px;
  white-space: nowrap;

  button {
    vertical-align: middle;
    margin-top: -3px;
  }

  .ant-badge-dot,
  .ant-badge-count {
    font-size: 10px;
    margin-right: 7px;
    width: 16px;
    min-width: 16px;
    line-height: 16px;
    height: 16px;
    padding: 0 3px;
    z-index: 2;
  }

  .ant-input-search {
    width: 160px;
    margin-right: 6px;
  }
`

const ActionsContainer = styled(Col)`
  @media print {
    display: none;
  }

  text-align: right;
  margin-bottom: 12px;
  white-space: nowrap;

  & > span,
  & > button {
    margin-left: 6px;
    vertical-align: middle;

    &:first-child {
      margin-left: 0;
    }
  }
`

function Component(props) {
  const {
    title,
    search,
    searchType,
    onSearchChange,
    onSearchFieldsChange,
    onSearchTypeChange,
    onActionsMenuClick,
    actionsMenuItems,
    afterSearchContents,
    afterActionsContents,
    searchFields = [],
    fieldSettings = [],
    allowActionsMenu = true,
  } = props

  const [visible, setVisible] = React.useState(false)

  const searchFieldsOptions = fieldSettings.filter((each) => each.searchEnumValue >= 0)

  return (
    <>
      <Row type="flex" justify="space-between" className={props.className} style={props.style}>
        <Col
          style={{
            textAlign: 'left',
            marginBottom: '12px',
            paddingRight: '12px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          <h2 title={title}>{title}</h2>
        </Col>
        <SearchContainer>
          {onSearchChange && (
            <>
              <Input.Search
                placeholder={t('search')}
                onChange={onSearchChange}
                value={search}
                maxLength={200}
                allowClear
              />
              {(onSearchTypeChange || onSearchFieldsChange) && (
                <Badge count={searchFields.length}>
                  <Tooltip placement="bottom" title={`${t('searchSettings')}`}>
                    <Button
                      style={{ padding: '0 8px', paddingTop: '1px', marginRight: '6px' }}
                      onClick={() => setVisible(true)}
                    >
                      <Icon type="Settings" />
                    </Button>
                  </Tooltip>
                </Badge>
              )}
            </>
          )}
          {afterSearchContents}
        </SearchContainer>
        <ActionsContainer>
          {onActionsMenuClick && allowActionsMenu && (
            <Dropdown
              overlay={<Menu onClick={onActionsMenuClick}>{actionsMenuItems}</Menu>}
              trigger={['click']}
            >
              <Button>
                {t('actions')}
                <Icon type="KeyboardArrowDown" />
              </Button>
            </Dropdown>
          )}
          {afterActionsContents}
        </ActionsContainer>
      </Row>
      <Modal title={t('searchSettings')} visible={visible} onCancel={() => setVisible(false)} footer={null}>
        {onSearchFieldsChange && searchFieldsOptions.length > 1 && (
          <Form.Item label={t('searchBy')} colon={false}>
            <Select
              placeholder={t('allSearchFields')}
              mode="multiple"
              value={mapSearchFields(searchFields, fieldSettings)}
              onChange={onSearchFieldsChange}
            >
              {orderBy(searchFieldsOptions, [
                (each) =>
                  t(each.columnHeadingLanguageKey || each.recordLabelLanguageKey || each.dtoFieldName),
              ]).map((each) => (
                <Option key={each.dtoFieldName} value={each.dtoFieldName}>
                  {t(each.columnHeadingLanguageKey || each.recordLabelLanguageKey || each.dtoFieldName)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {onSearchTypeChange && (
          <Form.Item label={t('searchType')} colon={false}>
            <Radio.Group value={searchType} onChange={(e) => onSearchTypeChange(e.target.value)}>
              <Radio value="AllWords">{t('allWords')}</Radio>
              <Radio value="AnyWord">{t('anyWord')}</Radio>
              <Radio value="Contains">{t('contains')}</Radio>
            </Radio.Group>
          </Form.Item>
        )}
      </Modal>
    </>
  )
}

export default Component
