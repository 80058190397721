import { snakeCase } from 'lodash'
import { createActions } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.externalApprovals
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName),

  approveItem: ({ key, ...rest }) => ({
    type: `${ENTITY_NAME}_APPROVE`,
    payload: createPost(entityName, { action: `${key}/approve` })(rest),
  }),

  rejectItem: ({ key, ...rest }) => ({
    type: `${ENTITY_NAME}_REJECT`,
    payload: createPost(entityName, { action: `${key}/reject` })(rest),
  }),
}
