import { createListModalComponent } from 'factories/ListModal'
import { PRIVATE_DATABASE } from 'options/products'
import Filter from 'containers/Products/Select/Filter'

export default createListModalComponent({
  getStorageKey: (self) => 'selectProduct',
  getSettingsType: (self) => 'selectProduct',
  initialFilterDto: (self) => ({
    active: 'Active',
    catalogTableName: PRIVATE_DATABASE,
  }),
})(Filter)
