import produce from 'immer'
import { Form } from 'antd'
import { set } from 'lodash'
import { t } from 'helpers/i18n'
import Checkbox from 'elements/Checkbox'
import Help from 'elements/Help'
import { Row, Col } from 'elements/Grid'

function Component(props) {
  const { values, onChange, readOnly } = props

  function setValue(key, value) {
    onChange(
      produce(values, (draft) => {
        set(draft, key, value)
      })
    )
  }

  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: t('moduleSettingsInfo') }} />
      <Row>
        <Col xs={24} sm={10}>
          <Form.Item className="mb-12">
            <Checkbox checked disabled>
              {t('enablePurchasing')}
            </Checkbox>
          </Form.Item>
          <Form.Item className="mb-12">
            <Checkbox
              checked={values?.enableRequisitions}
              onChange={(e) => setValue('enableRequisitions', e.target.checked)}
              readOnly={readOnly}
            >
              {t('enableRequisitions')}
            </Checkbox>
          </Form.Item>
          <Form.Item className="mb-12">
            <Checkbox
              checked={values?.enableRFQ}
              onChange={(e) => setValue('enableRFQ', e.target.checked)}
              readOnly={readOnly}
            >
              {t('enableRFQs')}
            </Checkbox>
          </Form.Item>
          <Form.Item className="mb-12">
            <Checkbox
              checked={values?.enableInventory}
              onChange={(e) => setValue('enableInventory', e.target.checked)}
              readOnly={readOnly}
            >
              {t('enableInventoryManagement')}
            </Checkbox>
          </Form.Item>
          <Form.Item className="mb-12">
            <Checkbox
              checked={values?.enableAssetManagement}
              onChange={(e) => setValue('enableAssetManagement', e.target.checked)}
              readOnly={readOnly}
            >
              {t('enableAssetManagement')}
            </Checkbox>
          </Form.Item>
          <Form.Item className="mb-12">
            <Checkbox
              checked={values?.enableForms}
              onChange={(e) => setValue('enableForms', e.target.checked)}
              readOnly={readOnly}
            >
              {t('enableForms')}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} sm={10}>
          <Form.Item className="mb-12">
            <Checkbox
              checked={values?.enablePricing}
              onChange={(e) => setValue('enablePricing', e.target.checked)}
              readOnly={readOnly}
            >
              {t('showPricing')}
            </Checkbox>
          </Form.Item>
          <Form.Item className="mb-12">
            <Checkbox
              checked={values?.enableTax}
              onChange={(e) => setValue('enableTax', e.target.checked)}
              readOnly={readOnly}
            >
              {t('enableTaxModule')} <Help title={t('enableTaxModuleInfo')} />
            </Checkbox>
          </Form.Item>
          <Form.Item className="mb-12">
            <Checkbox
              checked={values?.enableVendingIntegration}
              onChange={(e) => setValue('enableVendingIntegration', e.target.checked)}
              readOnly={readOnly}
            >
              {t('enableVendingIntegration')}
            </Checkbox>
          </Form.Item>
          <Form.Item className="mb-12">
            <Checkbox
              checked={values?.enablePunchout}
              onChange={(e) => setValue('enablePunchout', e.target.checked)}
              readOnly={readOnly}
            >
              {t('enablePunchout')}
            </Checkbox>
          </Form.Item>
          <Form.Item className="mb-12">
            <Checkbox
              checked={values?.enableDocumentLibrary}
              onChange={(e) => setValue('enableDocumentLibrary', e.target.checked)}
              readOnly={readOnly}
            >
              {t('enableDocumentLibrary')}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default Component
