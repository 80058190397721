import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import { createChildItemsDispatchToProps } from 'helpers/formViews'
import actions from 'actions/jobs'
import locationActions from 'actions/locations'
import jobReasonActions from 'actions/jobReasons'
import jobStatusOptionActions from 'actions/jobStatusOptions'
import operatorActions from 'actions/operators'
import userActions from 'actions/users'
import assetActions from 'actions/assets'
import documentActions from 'actions/documents'
import assetToleranceActions from 'actions/assets/assetTolerances'
import jobChecklistActions from 'actions/jobs/jobChecklist'
import Component from 'components/Jobs/Print'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getSettings: (params) => dispatch(actions.getSettings(params)),
  getLocations: (params) =>
    dispatch(locationActions.getItems({ ...params, locationVendingTypes: ['NonVending'] })),
  getJobReasons: (params) => dispatch(jobReasonActions.getOptions(params)),
  getJobStatusOptions: (params) => dispatch(jobStatusOptionActions.getOptions(params)),
  getUsers: (params) => dispatch(userActions.getItems(params)),
  getOperators: (params) =>
    dispatch(
      operatorActions.getOptions({
        pageIndex: 1,
        pageSize: 1,
        ...params,
        active: 'Active',
      })
    ),
  getOperator: (params) => dispatch(operatorActions.getItem(params)),
  getAsset: (params) => dispatch(assetActions.getItem(params)),
  getAssetTolerancesSettings: (params) => dispatch(assetToleranceActions.getSettings(params)),
  getJobChecklistSettings: (params) => dispatch(jobChecklistActions.getSettings(params)),
  getDocumentContents: (params) => dispatch(documentActions.getContents(params)),
  ...createChildItemsDispatchToProps(dispatch, 'Checklist', jobChecklistActions),
  ...createChildItemsDispatchToProps(dispatch, 'Tolerance', assetToleranceActions),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
