import { get, isUndefined } from 'lodash'
import entityNames from 'options/entityNames'
import auth from './auth'

const createSelectors = (entityName) => ({
  selectListView: (state = {}) => {
    if (isUndefined(get(state, entityName))) {
      throw new Error(`state['${entityName}'] is undefined`)
    }

    return get(state, `${entityName}.list`, {})
  },

  selectListViewItems: (state = {}) => {
    if (isUndefined(get(state, entityName))) {
      throw new Error(`state['${entityName}'] is undefined`)
    }

    return get(state, `${entityName}.list.items`, [])
  },

  selectListViewTotals: (state = {}) => {
    if (isUndefined(get(state, entityName))) {
      throw new Error(`state['${entityName}'] is undefined`)
    }

    return get(state, `${entityName}.list.pageTotals`, {})
  },

  selectListViewPagination: (state = {}) => {
    if (isUndefined(get(state, entityName))) {
      throw new Error(`state['${entityName}'] is undefined`)
    }

    return get(state, `${entityName}.pagination`, {})
  },

  selectListViewLoading: (state = {}) => {
    if (isUndefined(get(state, entityName))) {
      throw new Error(`state['${entityName}'] is undefined`)
    }

    return get(state, `${entityName}.loading`)
  },

  selectOptions: (state, { itemsOnly = true } = {}) => {
    if (isUndefined(get(state, entityName))) {
      throw new Error(`state['${entityName}'] is undefined`)
    }

    return get(state, `${entityName}.options${itemsOnly ? '.items' : ''}`, [])
  },

  selectCalendarView: (state = {}) => {
    if (isUndefined(get(state, entityName))) {
      throw new Error(`state['${entityName}'] is undefined`)
    }

    return get(state, `${entityName}.calendar`, {})
  },

  selectCalendarViewItems: (state = {}) => {
    if (isUndefined(get(state, entityName))) {
      throw new Error(`state['${entityName}'] is undefined`)
    }

    return get(state, `${entityName}.calendar.items`, [])
  },

  selectCalendarViewTotals: (state = {}) => {
    if (isUndefined(get(state, entityName))) {
      throw new Error(`state['${entityName}'] is undefined`)
    }

    return get(state, `${entityName}.calendar.pageTotals`, {})
  },
})

export default {
  ...Object.entries(entityNames).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: createSelectors(value) }),
    {}
  ),
  auth,
  jobTemplates: createSelectors(entityNames.jobs),
  usageDetailsReport: createSelectors(entityNames.usageOrderReport),
}
