import { connect } from 'react-redux'
import selectors from 'selectors'
import supplierActions from 'actions/suppliers'
import Component from 'components/DashboardViewer/Filter/Suppliers'

const mapStateToProps = (state, props) => ({
  suppliers: selectors.suppliers.selectOptions(state, { itemsOnly: false }),
})

const mapDispatchToProps = (dispatch, props) => ({
  getSuppliers: (params) =>
    dispatch(
      supplierActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
      })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
