import * as React from 'react'
import produce from 'immer'
import { Form, Input, Spin, Button, Tooltip, Tabs } from 'antd'
import { set, cloneDeep } from 'lodash'
import { showError, resourceNotFound, ValidationError } from 'helpers/errors'
import { createLabelFactory, createFieldFactory, createHandleSubmit, isReadOnly } from 'helpers/formViews'
import fontNames from 'options/fontNames'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import InputNumber from 'elements/InputNumber'
import Select, { Option } from 'elements/Select'
import Checkbox from 'elements/Checkbox'
import ColorPicker from 'elements/ColorPicker'
import { Row, Col } from 'elements/Grid'

class FormView extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.handleSubmit = createHandleSubmit(this)
  }

  componentDidMount() {
    this.fetchItem()
  }

  getItemId = () => [this.state.item?.id, this.props.linkTargetRecord?.id].find(Boolean)

  fetchItem = async ({ itemId = this.getItemId() } = {}) => {
    try {
      const responses = await Promise.all([
        itemId
          ? this.props.getItem(itemId).catch((error) => {
              this.props.onCancel?.()
              throw error
            })
          : !this.props.linkTargetRecord
            ? this.props.newItem({
                name: `${t('section')} ${this.props.parentRecord.sections.length + 1}`,
                position: this.props.parentRecord.sections.length + 1,
                height: 200,
                showHeader: false,
                headerBackgroundColor: '#E0E0E0',
                headerForegroundColor: '#333333',
                headerFontName: '',
                headerFontSize: 16,
                boldHeader: true,
                italicHeader: false,
                underlineHeader: false,
                showSectionBorder: true,
                charts: [],
              })
            : resourceNotFound(this),
        this.props.getSettings({ type: 'section' }),
      ])

      const item = cloneDeep(responses[0].value.data)

      this.setState({
        item,
        fieldSettings: responses[1].value.data.fieldSettings,
      })
    } catch (error) {
      showError({ error })
    }
  }

  setItemValue = (name, value) =>
    this.setState(
      produce((draft) => {
        set(draft.item, name, value)

        this.props.form.setFieldsValue({ [name]: value })
      })
    )

  render() {
    const { item, fieldSettings } = this.state

    if (!item || !fieldSettings) {
      return <Spin />
    }

    const createLabel = createLabelFactory(fieldSettings)
    const createFieldDecorator = createFieldFactory(this.props.form, item, fieldSettings)
    const readOnly = isReadOnly(this)

    const siblingRecords = this.props.parentRecord.sections

    return (
      <Form layout="vertical" colon={false} onSubmit={readOnly ? stopEvent : this.handleSubmit}>
        <div className="form-items-container">
          <ValidationError errors={this.state.validationErrors} />
          <Tabs defaultActiveKey={item.id ? 'appearance' : 'section'}>
            <Tabs.TabPane key="section" tab={t('section')} forceRender>
              <Form.Item label={createLabel('name')}>
                {createFieldDecorator('name')(<Input readOnly={readOnly} autoFocus />)}
              </Form.Item>
              <Row>
                <Col xs={12}>
                  <Form.Item label={createLabel('position')}>
                    {createFieldDecorator('position')(
                      <InputNumber
                        min={1}
                        max={item.id ? siblingRecords.length : siblingRecords.length + 1}
                        readOnly={readOnly}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label={createLabel('height')}>
                    {createFieldDecorator('height')(<InputNumber min={64} readOnly={readOnly} />)}
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane key="appearance" tab={t('appearance')} forceRender>
              <Form.Item>
                {createFieldDecorator('showHeader', { valuePropName: 'checked' })(
                  <Checkbox
                    readOnly={readOnly}
                    onChange={(e) => this.setItemValue('showHeader', e.target.checked)}
                  >
                    {createLabel('showHeader')}
                  </Checkbox>
                )}
              </Form.Item>
              {item.showHeader && (
                <>
                  <Row>
                    <Col xs={12}>
                      <Form.Item label={createLabel('headerFontName')}>
                        {createFieldDecorator('headerFontName')(
                          <Select placeholder={t('default')} allowClear={false} readOnly={readOnly}>
                            <Option key="" value="">
                              {t('default')}
                            </Option>
                            {fontNames.map((each) => (
                              <Option key={each} value={each}>
                                <span style={{ fontFamily: each }}>{each}</span>
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item label={createLabel('headerFontSize')}>
                        {createFieldDecorator('headerFontSize')(<InputNumber min={8} readOnly={readOnly} />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Form.Item>
                        {createFieldDecorator('boldHeader', { valuePropName: 'checked' })(
                          <Checkbox readOnly={readOnly}>{createLabel('boldHeader')}</Checkbox>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item>
                        {createFieldDecorator('underlineHeader', { valuePropName: 'checked' })(
                          <Checkbox readOnly={readOnly}>{createLabel('underlineHeader')}</Checkbox>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Form.Item>
                        {createFieldDecorator('italicHeader', { valuePropName: 'checked' })(
                          <Checkbox readOnly={readOnly}>{createLabel('italicHeader')}</Checkbox>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item label={createLabel('headerBackgroundColor')}>
                    <Row type="flex" gutter={6}>
                      <Col>
                        <ColorPicker
                          value={item.headerBackgroundColor}
                          onChange={(value) => this.setItemValue('headerBackgroundColor', value)}
                          readOnly={readOnly}
                        />
                      </Col>
                      <Col>
                        <ColorPicker
                          value={item.headerForegroundColor}
                          onChange={(value) => this.setItemValue('headerForegroundColor', value)}
                          readOnly={readOnly}
                          foreground
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </>
              )}
              <Form.Item>
                {createFieldDecorator('showSectionBorder', { valuePropName: 'checked' })(
                  <Checkbox readOnly={readOnly}>{createLabel('showSectionBorder')}</Checkbox>
                )}
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className="form-buttons-container">
          <Button onClick={this.props.onCancel}>{t(readOnly ? 'close' : 'cancel')}</Button>{' '}
          {!readOnly && (
            <Tooltip title={t('childRecordSaveInfo')} placement="topLeft">
              <Button type="primary" htmlType="submit" loading={this.state.saveAndCloseButtonLoading}>
                {!this.props.linkTargetRecord ? t('add') : t('update')}
              </Button>
            </Tooltip>
          )}
        </div>
      </Form>
    )
  }
}

export default Form.create()(FormView)
