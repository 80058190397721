import * as React from 'react'
import produce from 'immer'
import { Form, Input, Spin, Button, Tooltip, Card } from 'antd'
import { isNil, set, isEmpty, get, intersection } from 'lodash'
import { showError } from 'helpers/errors'
import { isReadOnly } from 'helpers/formViews'
import { stopEvent } from 'helpers/events'
import linkTargets from 'options/linkTargets'
import { createGetLabelTemplateWithImages } from 'helpers/labelTemplates'
import { createPrintHandler, resetPrintHandler } from 'helpers/print'
import tagTypes from 'options/tagTypes'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import InputNumber from 'elements/InputNumber'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import Print from 'components/PrintLabel/Print'
import Drawer from 'elements/Drawer'
import SelectProductCategories from 'containers/ProductCategories/Select'
import { Row, Col } from 'elements/Grid'

const LabelTemplatesFormView = linkTargets.labelTemplateRecord.formComponent

class FormView extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.printableRef = React.createRef()
    this.getLabelTemplateWithImages = createGetLabelTemplateWithImages(this)
  }

  componentDidMount() {
    this.fetchSettings()
  }

  promiseState = (state = {}) => new Promise((resolve) => this.setState(state, resolve))

  fetchSettings = async () => {
    try {
      const item = { numberOfCopies: 1 }

      const responses = await Promise.all([
        this.props.getLabelTemplateTypes(),
        this.props.getProductCategories(),
        this.props.getLocations({ includeAllLocations: true }),
        this.props.getAssetCategories(),
        this.props.getOperatorGroups(),
        this.props.getLocationGroups(),
      ])

      this.setState({
        item,
        labelsToPrint: [],
        labelTemplateTypes: get(responses[0], 'value.data.items', []),
        productCategories: get(responses[1], 'value.data.items', []),
        locations: get(responses[2], 'value.data.items', []),
        assetCategories: get(responses[3], 'value.data.items', []),
        operatorGroups: get(responses[4], 'value.data.items', []),
        locationGroups: get(responses[5], 'value.data.items', []),
      })
    } catch (error) {
      showError({ error })
    }
  }

  setItemValue = (name, value) =>
    this.setState(
      produce((draft) => {
        set(draft.item, name, value)
      }),
      async () => {
        try {
          if (name === 'labelTemplateType') {
            const responses = await Promise.all([
              this.props.getLabelTemplates({ labelTemplateType: value }),
              this.props.getLabelTemplateFields({ type: value }),
            ])

            this.setState(
              produce((draft) => {
                draft.labelsToPrint = []
                draft.labelTemplates = get(responses[0], 'value.data.items', [])
                draft.labelTemplateFields = get(responses[1], 'value.data.items', [])
                draft.item.labelTemplateId = undefined
                draft.item.productCategoryIds = []
                draft.item.locationGroupId = undefined
                draft.item.locationGroupIds = []
                draft.item.locationIds = []
                draft.item.assetCategoryIds = []
                draft.item.operatorGroupIds = []
                draft.item.tagType = undefined
              })
            )
          }

          if (name === 'productCategoryIds') {
            if (!isEmpty(value)) {
              const response = await this.props.getProducts({ categoryIds: value })

              this.setState({ labelsToPrint: response.value.data.items })
            } else {
              this.setState({ labelsToPrint: [] })
            }
          }

          if (name === 'locationIds' && this.state.item.labelTemplateType === 'Inventory') {
            if (!isEmpty(value)) {
              const response = await this.props.getInventoryItems({ locationIds: value })

              this.setState({ labelsToPrint: response.value.data.items })
            } else {
              this.setState({ labelsToPrint: [] })
            }
          }

          if (name === 'assetCategoryIds') {
            if (!isEmpty(value)) {
              const response = await this.props.getAssets({ assetCategoryIds: value })

              this.setState({ labelsToPrint: response.value.data.items })
            } else {
              this.setState({ labelsToPrint: [] })
            }
          }

          if (name === 'locationGroupIds' && this.state.item.labelTemplateType === 'Location') {
            this.setState(
              produce((draft) => {
                draft.item.locationIds = []
              })
            )

            if (!isEmpty(value)) {
              const response = await this.props.getLocations({ locationGroupIds: value })

              this.setState({ labelsToPrint: response.value.data.items })
            } else {
              this.setState({ labelsToPrint: [] })
            }
          }

          if (name === 'locationGroupIds' && this.state.item.labelTemplateType === 'OverstockLocation') {
            if (!isEmpty(value)) {
              const response = await this.props.getOverstockLocations({ locationGroupIds: value })

              this.setState({ labelsToPrint: response.value.data.items })
            } else {
              this.setState({ labelsToPrint: [] })
            }
          }

          if (name === 'locationIds' && this.state.item.labelTemplateType === 'Location') {
            if (!isEmpty(value)) {
              const { locationGroupIds } = this.state.item

              const response = await this.props.getLocations({
                locationIds: value,
                locationGroupIds,
                alwaysIncludeLocationIds: false,
              })

              this.setState({ labelsToPrint: response.value.data.items })
            } else {
              this.setState({ labelsToPrint: [] })
            }
          }

          if (name === 'locationGroupId' && this.state.item.labelTemplateType === 'OverstockInventory') {
            if (!isNil(value)) {
              const response = await this.props.getOverstockInventoryItems({ locationGroupId: value })

              this.setState({ labelsToPrint: response.value.data.items })
            } else {
              this.setState({ labelsToPrint: [] })
            }
          }

          if (name === 'operatorGroupIds') {
            if (!isEmpty(value)) {
              const response = await this.props.getOperators({ operatorGroupIds: value })

              this.setState({ labelsToPrint: response.value.data.items })
            } else {
              this.setState({ labelsToPrint: [] })
            }
          }

          if (name === 'tagType') {
            if (!isEmpty(value)) {
              const response = await this.props.getTagListItems({ tagType: value })

              this.setState({ labelsToPrint: response.value.data.items })
            } else {
              this.setState({ labelsToPrint: [] })
            }
          }
        } catch (error) {
          showError({ error })
        }
      }
    )

  handleSubmit = async (e) => {
    stopEvent(e)

    try {
      this.setState({ printPreviewLoading: true })

      const response = await this.props.getLabelTemplate(this.state.item.labelTemplateId)

      const itemWithImages = await this.getLabelTemplateWithImages(response.value.data)

      this.setState({
        selectedLabelTemplate: itemWithImages,
        printPreviewVisible: true,
      })
    } catch (error) {
      showError({ error })
    } finally {
      this.setState({ printPreviewLoading: false })
    }
  }

  handleEditLabelTemplateClose = async () => {
    try {
      await this.promiseState({ editLabelTemplateVisible: false, editLabelTemplateSaving: false })

      const { labelTemplateType } = this.state.item ?? {}

      const response = await this.props.getLabelTemplates({ labelTemplateType })

      this.setState({ labelTemplates: response.value.data.items })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { item, labelsToPrint = [], labelTemplates = [], labelTemplateFields = [] } = this.state

    if (!item) {
      return <Spin />
    }

    const labelTemplate = labelTemplates.find((one) => one.id === item.labelTemplateId)

    const readOnly = isReadOnly(this)

    return (
      <>
        <Form layout="vertical" colon={false} onSubmit={readOnly ? stopEvent : this.handleSubmit}>
          <h2>{this.props.title}</h2>
          <Card>
            <Form.Item label={t('labelTemplateType')} required>
              <Select
                value={item.labelTemplateType}
                onChange={(value) => this.setItemValue('labelTemplateType', value)}
                placeholder={t('select')}
                allowClear
              >
                {(this.state.labelTemplateTypes ?? [])
                  .filter((each) => each.labelTemplateType !== 'OrderItem')
                  .map((each) => (
                    <Option key={each.labelTemplateType} value={each.labelTemplateType}>
                      {each.displayName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('labelTemplate')} required>
              <div className="tofino-label-buttons">
                <Tooltip title={t('editLabelTemplate')} placement="topLeft">
                  <Button
                    size="small"
                    onClick={() =>
                      this.setState({
                        editLabelTemplateVisible: true,
                        editLabelTemplateSaving: false,
                      })
                    }
                    disabled={isNil(item.labelTemplateId)}
                  >
                    <Icon type="Edit" />
                  </Button>
                </Tooltip>
              </div>
              <Select
                value={item.labelTemplateId}
                onChange={(value) => this.setItemValue('labelTemplateId', value)}
                placeholder={t('select')}
                disabled={!item.labelTemplateType}
              >
                {labelTemplates.map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('description')}>
              <Input.TextArea value={labelTemplate?.description ?? ''} rows={4} disabled />
            </Form.Item>
            {item.labelTemplateType === 'Product' && (
              <Form.Item label={t('productCategory')} required>
                <SelectProductCategories
                  value={item.productCategoryIds}
                  onChange={(values) => this.setItemValue('productCategoryIds', values)}
                  placeholder={t('select')}
                  dataSource={this.state.productCategories ?? []}
                />
              </Form.Item>
            )}
            {item.labelTemplateType === 'Inventory' && (
              <Form.Item label={t('location')} required>
                <Select
                  value={item.locationIds}
                  onChange={(value) => this.setItemValue('locationIds', value)}
                  placeholder={t('select')}
                  mode="multiple"
                >
                  {(this.state.locations ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {item.labelTemplateType === 'Asset' && (
              <Form.Item label={t('assetCategory')} required>
                <Select
                  value={item.assetCategoryIds}
                  onChange={(value) => this.setItemValue('assetCategoryIds', value)}
                  placeholder={t('select')}
                  mode="multiple"
                >
                  {(this.state.assetCategories ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {item.labelTemplateType === 'OverstockInventory' && (
              <Form.Item label={t('locationGroup')} required>
                <Select
                  value={item.locationGroupId}
                  onChange={(value) => this.setItemValue('locationGroupId', value)}
                  placeholder={t('select')}
                >
                  {(this.state.locationGroups ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {['Location', 'OverstockLocation'].includes(item.labelTemplateType) && (
              <Form.Item label={t('locationGroup')} required>
                <Select
                  value={item.locationGroupIds}
                  onChange={(value) => this.setItemValue('locationGroupIds', value)}
                  placeholder={t('select')}
                  mode="multiple"
                >
                  {(this.state.locationGroups ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {item.labelTemplateType === 'Location' && (
              <Form.Item label={t('location')}>
                <Select
                  value={item.locationIds}
                  onChange={(value) => this.setItemValue('locationIds', value)}
                  placeholder={t('all')}
                  disabled={isEmpty(item.locationGroupIds)}
                  mode="multiple"
                >
                  {(this.state.locations ?? [])
                    .filter((each) => !isEmpty(intersection(each.locationGroupIds, item.locationGroupIds)))
                    .map((each) => (
                      <Option key={each.id} value={each.id}>
                        <span {...getOptionProps(each)}>{each.displayName}</span>
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            {item.labelTemplateType === 'Operator' && (
              <Form.Item label={t('operatorGroup')} required>
                <Select
                  value={item.operatorGroupIds}
                  onChange={(value) => this.setItemValue('operatorGroupIds', value)}
                  placeholder={t('select')}
                  mode="multiple"
                >
                  {(this.state.operatorGroups ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {item.labelTemplateType === 'Tag' && (
              <Form.Item label={t('tagType')} required>
                <Select
                  value={item.tagType}
                  onChange={(value) => this.setItemValue('tagType', value)}
                  placeholder={t('select')}
                >
                  {Object.entries(tagTypes).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {t(value)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Row>
              <Col xs={12}>
                <Form.Item label={t('labelsToPrint')}>
                  <Input value={labelsToPrint.length} disabled />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label={t('copies')}>
                  <InputNumber
                    value={item.numberOfCopies}
                    onChange={(value) => this.setItemValue('numberOfCopies', Math.max(value, 1))}
                    min={1}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="text-right">
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.printPreviewLoading}
                disabled={isEmpty(labelsToPrint) || !item.labelTemplateId}
              >
                {t('printPreview')}
              </Button>
            </div>
          </Card>
        </Form>
        <Modal
          title={t('printPreview')}
          okText={t('print')}
          onOk={createPrintHandler(this.printableRef)}
          onCancel={() => this.setState({ printPreviewVisible: false }, resetPrintHandler)}
          width={992}
          visible={this.state.printPreviewVisible}
        >
          <div ref={this.printableRef} className="tofino-print-preview">
            <Print
              items={labelsToPrint}
              labelTemplateFields={labelTemplateFields}
              labelTemplate={this.state.selectedLabelTemplate}
              numberOfCopies={item.numberOfCopies}
            />
          </div>
        </Modal>
        <Drawer
          title={t('editLabelTemplate')}
          size={linkTargets.labelTemplateRecord.formSize}
          visible={this.state.editLabelTemplateVisible}
          onClose={this.handleEditLabelTemplateClose}
          saving={this.state.editLabelTemplateSaving}
        >
          <LabelTemplatesFormView
            linkTargetRecord={labelTemplate}
            onSave={(pending) => this.setState({ editLabelTemplateSaving: pending })}
            onSaveAndClose={this.handleEditLabelTemplateClose}
            onCancel={this.handleEditLabelTemplateClose}
          />
        </Drawer>
      </>
    )
  }
}

export default Form.create()(FormView)
