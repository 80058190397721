import { createListModalComponent } from 'factories/ListModal'
import { createY2KNullTableCellComponent } from 'helpers/listViews'
import Filter from 'containers/Inventory/SelectOverstockItemInventory/Filter'

export default createListModalComponent({
  getStorageKey: (self) => 'inventory.listView.selectOverstockItemInventory',
  getSettingsType: (self) => 'overstockItemInventory',
  tableCellComponents: {
    lastCountDate: createY2KNullTableCellComponent('lastCountDate'),
  },
})(Filter)
