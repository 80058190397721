import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Progress from 'elements/Progress'
import Filter from 'containers/Reports/JobCompletionDetailReport/Filter'

export default createListViewComponent({
  entityName: entityNames.jobCompletionDetailReport,
  filterTemplateType: 'JobCompletionDetailReport',
  initialFilterDto: (self) => ({
    dateRange: { dateRangeType: 'YearToDate' },
    approvalStatus: 'All',
    completionStatus: 'All',
  }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  tableCellComponents: {
    checklistPercentComplete: (self, item) => (
      <Progress percent={Math.round(item.checklistPercentComplete * 100)} size="small" />
    ),
  },
})(Filter)
