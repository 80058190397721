import * as React from 'react'
import { Form } from 'antd'
import { get, uniqBy } from 'lodash'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import chartAccessTypes from 'options/dashboards/chartAccessTypes'
import Select, { Option, OptGroup } from 'elements/Select'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      const response = await this.props.getChartMetricTypes()

      this.setState({ chartMetricTypes: get(response, 'value.data.items', []) })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto = {} } = this.props
    const { chartMetricTypes = [] } = this.state

    const chartMetricCategoryTypes = uniqBy(
      chartMetricTypes.map(({ categoryType, categoryName }) => ({
        categoryType,
        categoryName,
      })),
      'categoryType'
    )

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <Form.Item label={t('metric')}>
              <Select
                value={filterDto.metricType}
                onChange={(value) => this.setFilterValue('metricType', value)}
                placeholder={t('all')}
                showSearch
                allowClear
              >
                {chartMetricCategoryTypes.map((category) => (
                  <OptGroup key={category.categoryType} label={category.categoryName}>
                    {chartMetricTypes
                      .filter((metric) => metric.categoryType === category.categoryType)
                      .map((metric) => (
                        <Option key={metric.type} value={metric.type}>
                          {metric.name}
                        </Option>
                      ))}
                  </OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('access')}>
              <Select
                onChange={(value) => this.setFilterValue('accessType', value || 'All')}
                value={filterDto.accessType}
              >
                <Option value="All">{t('all')}</Option>
                {Object.entries(chartAccessTypes).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
