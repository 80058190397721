import * as React from 'react'
import styled from 'styled-components'
import { Tree, Form, Spin, Menu, Button, Tooltip, Input, message } from 'antd'
import {
  isEmpty,
  get,
  isArray,
  isPlainObject,
  flattenDeep,
  uniq,
  orderBy,
  set,
  pick,
  flowRight,
} from 'lodash'
import { useImmer } from 'use-immer'
import { saveAs } from 'file-saver'
import { useDebouncedCallback } from 'use-debounce'
import { showError, showClientNotifications } from 'helpers/errors'
import { setStorageItem, getStorageItem } from 'helpers/localStorage'
import { t } from 'helpers/i18n'
import { getPageTitle, getUserPermission } from 'helpers/auth'
import { stopEvent } from 'helpers/events'
import { DEBOUNCE } from 'helpers/utils'
import Page from 'elements/Page'
import Toolbar from 'elements/Toolbar'
import Icon from 'elements/Icon'
import Drawer from 'elements/Drawer'
import FileUpload from 'elements/FileUpload'
import Modal from 'elements/Modal'

const getTreeDataKeys = (node) => {
  if (isArray(node)) {
    return node.map(getTreeDataKeys)
  }

  if (isPlainObject(node)) {
    return [node.key, ...getTreeDataKeys(node.children)]
  }

  return ''
}

const getStorageKey = () => 'tools.documentLibrary'

const Container = styled(Page)`
  * {
    cursor: ${(props) => (props.saving ? 'progress !important' : 'auto')};
  }
`

const Folders = styled(Tree)`
  margin-bottom: 24px;

  .ant-tree-node-selected {
    font-weight: bold;

    .ant-btn {
      padding: 0;
      height: auto;
      border: none;
      box-shadow: none;
      vertical-align: middle;
      margin-left: 6px;
      background-color: transparent;
    }
  }
`

function Component(props) {
  const { getSettings, getItems, createItems, downloadItem, deleteItems, settingsType } = props

  const [state, updateState] = useImmer({
    search: '',
    expandedKeys: ['/'],
    selectedKeys: [],
    ...getStorageItem(getStorageKey()),
  })

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  const searchItems = useDebouncedCallback((value) => fetchItems(value), DEBOUNCE)

  function handleDownloadClick(node) {
    return async (e) => {
      stopEvent(e)

      try {
        const response = await downloadItem({
          request: {
            domainObjectId: node.domainObjectId,
            domainObjectType: node.domainObjectType,
            documentType: 'ObjectDocument',
            documentName: node.fileName,
            documentPath: node.filePath,
          },
        })

        saveAs(response.value.data, node.fileName)
      } catch (error) {
        showError({ error })
      }
    }
  }

  function handleDeleteClick(node) {
    return async (e) => {
      stopEvent(e)

      try {
        const response = await deleteItems([
          {
            domainObjectId: node.domainObjectId,
            domainObjectType: node.domainObjectType,
            documentType: 'ObjectDocument',
            documentName: node.fileName,
            documentPath: node.filePath,
            isFolder: node.isFolder,
          },
        ])

        showClientNotifications({ response })

        if (response.value.data.failureCount > 0) {
          throw new Error()
        }

        searchItems(state.search)
      } catch (error) {
        showError({ error })
      }
    }
  }

  function handleCreateFolderClick(node) {
    return (e) => {
      stopEvent(e)

      updateState((draft) => {
        draft.createFolderParent = node
        draft.createFolderVisible = true
        draft.createFolderName = ''
      })
    }
  }

  async function handleCreateFolderOk() {
    if (!isEmpty(state.createFolderName)) {
      try {
        setState('createFolderLoading', true)

        const response = await createItems([
          {
            documentType: 'ObjectDocument',
            domainObjectType: 'Library',
            fileName: state.createFolderName,
            filePath: [state.createFolderParent?.filePath, state.createFolderParent?.fileName]
              .filter(Boolean)
              .join('/'),
            isFolder: true,
          },
        ])

        showClientNotifications({ response })

        if (response.value.data.failureCount > 0) {
          throw new Error()
        }

        searchItems(state.search)

        updateState((draft) => {
          draft.createFolderName = ''
          draft.createFolderVisible = false
          draft.createFolderParent = null
        })
      } catch (error) {
        showError({ error })
      } finally {
        setState('createFolderLoading', false)
      }
    }
  }

  function handleUploadClick(node) {
    return (e) => {
      stopEvent(e)

      updateState((draft) => {
        draft.fileUploadParent = node
        draft.fileUploadVisible = true
      })
    }
  }

  async function handleFileUpload(fileList = []) {
    if (isEmpty(fileList)) {
      setState('fileUploadVisible', true)
    } else {
      try {
        setState('fileUploadLoading', true)

        const response = await createItems([
          {
            documentType: 'ObjectDocument',
            domainObjectType: 'Library',
            fileName: fileList[0].fileName,
            filePath: [state.fileUploadParent?.filePath, state.fileUploadParent?.fileName]
              .filter(Boolean)
              .join('/'),
            isFolder: false,
            fileType: fileList[0].fileType,
            contents: fileList[0].fileContents,
          },
        ])

        showClientNotifications({ response })

        if (response.value.data.failureCount > 0) {
          throw new Error()
        }

        searchItems(state.search)

        updateState((draft) => {
          draft.fileUploadParent = null
          draft.fileUploadVisible = false
        })
      } catch (error) {
        showError({ error })
      } finally {
        setState('fileUploadLoading', false)
      }
    }
  }

  function getTreeData(node, parentKey = '') {
    if (isArray(node)) {
      return orderBy(
        node.map((each) => getTreeData(each, parentKey)),
        ['isFolder'],
        ['desc']
      )
    }

    if (isPlainObject(node)) {
      const key = `${parentKey}${node.fileName}/`
      const title = node.fileName ? node.fileName : t('documents')
      return {
        ...node,
        key,
        title: (
          <>
            <span style={{ marginRight: '18px' }}>{title}</span>
            {(state?.selectedKeys ?? []).includes(key) &&
              node.isFolder &&
              key === '/' &&
              getUserPermission('DocumentLibrary') === 'ReadWrite' && (
                <>
                  <Tooltip title={t('uploadFile')}>
                    <Button onClick={handleUploadClick(node)}>
                      <Icon type="Publish" size={18} />
                    </Button>
                  </Tooltip>
                  <Tooltip title={t('createFolder')}>
                    <Button onClick={handleCreateFolderClick(node)}>
                      <Icon type="CreateNewFolder" size={18} />
                    </Button>
                  </Tooltip>
                </>
              )}
            {(state?.selectedKeys ?? []).includes(key) &&
              node.isFolder &&
              key !== '/' &&
              getUserPermission('DocumentLibrary') === 'ReadWrite' && (
                <>
                  <Tooltip title={t('uploadFile')}>
                    <Button onClick={handleUploadClick(node)}>
                      <Icon type="Publish" size={18} />
                    </Button>
                  </Tooltip>
                  <Tooltip title={t('createFolder')}>
                    <Button onClick={handleCreateFolderClick(node)}>
                      <Icon type="CreateNewFolder" size={18} />
                    </Button>
                  </Tooltip>
                  {isEmpty(node.children) && (
                    <Tooltip title={t('delete')}>
                      <Button onClick={handleDeleteClick(node)}>
                        <Icon type="Delete" size={18} />
                      </Button>
                    </Tooltip>
                  )}
                </>
              )}
            {(state?.selectedKeys ?? []).includes(key) && !node.isFolder && (
              <>
                <Tooltip title={t('download')}>
                  <Button onClick={handleDownloadClick(node)}>
                    <Icon type="GetApp" size={18} />
                  </Button>
                </Tooltip>
                {getUserPermission('DocumentLibrary') === 'ReadWrite' && (
                  <Tooltip title={t('delete')}>
                    <Button onClick={handleDeleteClick(node)}>
                      <Icon type="Delete" size={18} />
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
          </>
        ),
        children: orderBy(
          (node?.children ?? []).map((each) => getTreeData(each, key)),
          ['isFolder'],
          ['desc']
        ),
        isLeaf: isEmpty(node.children),
        icon: isEmpty(parentKey) ? (
          <Icon type="LibraryBooks" />
        ) : !node.isFolder ? (
          <Icon type="InsertDriveFile" outlined />
        ) : (
          <Icon type="Folder" />
        ),
      }
    }

    return node
  }

  const treeData = [
    getTreeData({
      fileName: '',
      children: state?.items ?? [],
      isFolder: true,
    }),
  ]

  async function fetchItems(value) {
    try {
      const response = await getItems({
        search: value,
        request: {
          documentType: 'ObjectDocument',
          domainObjectType: 'Library',
        },
      })

      setState('items', get(response, 'value.data.items', []))
    } catch (error) {
      showError({ error })
    }
  }

  async function fetchSettings() {
    try {
      const response = await getSettings({ type: settingsType })

      setState('fieldSettings', get(response, 'value.data.fieldSettings', []))
    } catch (error) {
      showError({ error })
    }
  }

  function handleActionsMenuClick({ key }) {
    switch (key) {
      case 'expandAll':
        setState('expandedKeys', flowRight(uniq, flattenDeep, getTreeDataKeys)(treeData))
        break

      case 'collapseAll':
        setState('expandedKeys', [])
        break

      default:
        message.warn(t('underDevelopment'))
        break
    }
  }

  React.useEffect(() => {
    fetchSettings()
  }, [])

  React.useEffect(() => {
    fetchItems()
  }, [])

  React.useEffect(() => {
    setStorageItem(getStorageKey(), pick(state, ['expandedKeys']))
  }, [state.expandedKeys])

  const pageTitle = getPageTitle()

  if (isEmpty(state.fieldSettings)) {
    return (
      <Container title={pageTitle} scrollable>
        <Spin />
      </Container>
    )
  }

  return (
    <>
      <Container title={pageTitle} scrollable>
        <Toolbar
          title={pageTitle}
          search={state.search}
          onSearchChange={(e) => {
            setState('search', e.target.value)
            searchItems(e.target.value)
          }}
          actionsMenuItems={[
            <Menu.Item key="expandAll">{t('expandAll')}</Menu.Item>,
            <Menu.Item key="collapseAll">{t('collapseAll')}</Menu.Item>,
          ]}
          onActionsMenuClick={handleActionsMenuClick}
        />
        {!isEmpty(treeData) && (
          <Folders
            treeData={treeData}
            expandedKeys={state.expandedKeys}
            onExpand={(values) => setState('expandedKeys', values)}
            onSelect={(values) => setState('selectedKeys', values)}
            selectedKeys={state.selectedKeys}
            defaultExpandAll
            showIcon
          />
        )}
      </Container>
      <Drawer
        title={t('uploadFile')}
        size="xs"
        visible={state.fileUploadVisible}
        onClose={() =>
          updateState((draft) => {
            draft.fileUploadParent = null
            draft.fileUploadVisible = false
          })
        }
        saving={state.fileUploadLoading}
      >
        <FileUpload
          onUpload={handleFileUpload}
          onCancel={() =>
            updateState((draft) => {
              draft.fileUploadParent = null
              draft.fileUploadVisible = false
            })
          }
          loading={state.fileUploadLoading}
        />
      </Drawer>
      <Modal
        title={t('createFolder')}
        visible={state.createFolderVisible}
        okText={t('create')}
        okButtonProps={{
          loading: state.createFolderLoading,
          disabled: isEmpty(state.createFolderName),
        }}
        onOk={handleCreateFolderOk}
        onCancel={() =>
          updateState((draft) => {
            draft.createFolderParent = null
            draft.createFolderVisible = false
            draft.createFolderName = ''
          })
        }
      >
        <Form.Item label={t('folderName')} colon={false} required>
          <Input
            value={state.createFolderName}
            onChange={(e) => setState('createFolderName', e.target.value)}
            onPressEnter={handleCreateFolderOk}
          />
        </Form.Item>
      </Modal>
    </>
  )
}

export default Form.create()(Component)
