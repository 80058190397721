import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import actions from 'actions/operatorGroups'
import Component from 'components/Operators/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  operatorGroups: selectors.operatorGroups.selectOptions(state),
})

export default connect(mapStateToProps, { getOperatorGroups: actions.getOptions })(Component)
