import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/JobUsageReport/Filter'

export default createListViewComponent({
  entityName: entityNames.jobUsageReport,
  filterTemplateType: 'JobUsageReport',
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
})(Filter)
