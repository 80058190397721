import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import { createDocumentsDispatchToProps } from 'helpers/formViews'
import documentActions from 'actions/documents'
import actions from 'actions/labelTemplates'
import productCategoryActions from 'actions/productCategories'
import productActions from 'actions/products'
import locationActions from 'actions/locations'
import overstockLocationActions from 'actions/overstockLocations'
import inventoryActions from 'actions/inventory'
import assetCategoryActions from 'actions/assetCategories'
import assetActions from 'actions/assets'
import operatorGroupActions from 'actions/operatorGroups'
import operatorActions from 'actions/operators'
import tagListActions from 'actions/tagLists'
import locationGroupActions from 'actions/locationGroups'
import overstockInventoryActions from 'actions/overstockInventory'
import Component from 'components/PrintLabels/FormView'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getLabelTemplateTypes: (params) => dispatch(actions.getLabelTemplateTypes(params)),
  getLabelTemplateFields: (params) => dispatch(actions.getLabelTemplateFields(params)),
  getLabelTemplate: (itemId) => dispatch(actions.getItem(itemId)),
  getLabelTemplates: (params) => dispatch(actions.getItems(params)),
  getProductCategories: (params) =>
    dispatch(productCategoryActions.getOptions({ ...params, active: 'All', pageIndex: 0 })),
  getProducts: (params) => dispatch(productActions.getItems({ ...params, pageIndex: 1, pageSize: 1000 })),
  getLocationGroups: (params) => dispatch(locationGroupActions.getOptions(params)),
  getLocations: (params) =>
    dispatch(locationActions.getItems({ locationVendingTypes: ['NonVending'], ...params })),
  getOverstockLocations: (params) =>
    dispatch(overstockLocationActions.getItems({ ...params, pageIndex: 1, pageSize: 1000 })),
  getInventoryItems: (params) =>
    dispatch(inventoryActions.getItems({ ...params, pageIndex: 1, pageSize: 1000 })),
  getAssetCategories: (params) => dispatch(assetCategoryActions.getOptions(params)),
  getAssets: (params) => dispatch(assetActions.getItems({ ...params, pageIndex: 1, pageSize: 1000 })),
  getOperatorGroups: (params) => dispatch(operatorGroupActions.getOptions(params)),
  getOperators: (params) => dispatch(operatorActions.getItems({ ...params, pageIndex: 1, pageSize: 1000 })),
  getTagListItems: (params) => dispatch(tagListActions.getItems({ ...params, pageIndex: 1, pageSize: 1000 })),
  getOverstockInventoryItems: (params) =>
    dispatch(overstockInventoryActions.getItems({ ...params, pageIndex: 1, pageSize: 1000 })),
  ...createDocumentsDispatchToProps(dispatch, documentActions),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
