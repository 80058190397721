import { createSideFormContainer } from 'factories/SideForm'
import inventoryActions from 'actions/inventory'
import jobActions from 'actions/jobs'
import Component from 'components/Issue/FormView/SideForm'

export default createSideFormContainer({
  extraStateToProps: (state, props) => ({ settingsType: props.settingsType ?? 'issue' }),
  extraDispatchToProps: (dispatch, props) => ({
    getBarcodes: (params) =>
      dispatch(
        inventoryActions.getOptions({
          pageIndex: 1,
          pageSize: 100,
          ...params,
        })
      ),
    getJobs: (params) =>
      dispatch(
        jobActions.getOptions({
          pageIndex: 1,
          pageSize: 100,
          active: 'All',
          ...params,
        })
      ),
  }),
})(Component)
