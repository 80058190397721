import moment from 'moment'
import { isEmpty, toString as str, isDate } from 'lodash'
import store from 'helpers/store'
import selectors from 'selectors'

export const ZERO_HOUR = moment('2000-01-01', 'YYYY-MM-DD')
export const MIN_TIME = moment().clone().add(-1000, 'years')
export const MAX_TIME = moment().clone().add(1000, 'years')

export const isValidDate = (value) => {
  const text = isDate(value) ? value.toJSON() : str(value)

  return !isEmpty(text) && !text.startsWith('0001-01-01') && moment(value).isValid()
}

export const tryParseMoment = (value, defaultValue) => (isValidDate(value) ? moment(value) : defaultValue)

export const tryParseDate = (value, defaultValue) =>
  isValidDate(value) ? moment(value).clone().toDate() : defaultValue

const getLocaleFormat = (locale, showSeconds = false) => {
  switch (locale) {
    case 'en':
      return { date: 'M/D/YYYY', time: showSeconds ? 'h:mm:ss A' : 'h:mm A', separator: ', ' }

    case 'fr':
      return { date: 'DD/MM/YYYY', time: showSeconds ? 'HH:mm:ss' : 'HH:mm', separator: ', ' }

    case 'es':
      return { date: 'D/M/YYYY', time: showSeconds ? 'H:mm:ss' : 'H:mm', separator: ' ' }

    default:
      return {
        date: 'YYYY-MM-DD',
        time: showSeconds ? 'HH:mm:ss' : 'HH:mm',
        separator: ' ',
      }
  }
}

export const getDateTimeFormat = ({ showDate = true, showTime = false, showSeconds = false } = {}) => {
  const locale = selectors.auth.locale(store.getState())
  const format = getLocaleFormat(locale, showSeconds)

  if (showTime) {
    return showDate ? `${format.date}${format.separator}${format.time}` : format.time
  }

  return showDate ? format.date : ''
}

export const formatDateTime = (value, options = {}) => {
  const text = isDate(value) ? value.toJSON() : str(value)

  return isValidDate(text) ? moment(text).format(getDateTimeFormat(options)) : ''
}

export const formatUserTime = (dateTime, userName, showTime = true) =>
  isValidDate(dateTime)
    ? `${formatDateTime(dateTime, { showTime })}${userName ? ` (${userName})` : ''}`
    : null

export const getStartDate = (dateRangeType, lastNValue = 1) => {
  switch (dateRangeType) {
    case 'Today':
      return moment().startOf('day')

    case 'Yesterday':
      return moment().subtract(1, 'days').startOf('day')

    case 'MonthToDate':
      return moment().startOf('month')

    case 'YearToDate':
      return moment().startOf('year')

    case 'LastWeek':
      return moment().subtract(1, 'weeks').startOf('week')

    case 'LastMonth':
      return moment().subtract(1, 'months').startOf('month')

    case 'LastYear':
      return moment().subtract(1, 'years').startOf('year')

    case 'LastNDays':
      return moment().subtract(lastNValue, 'days')

    case 'LastNWeeks':
      return moment().subtract(lastNValue, 'weeks')

    case 'LastNMonths':
      return moment().subtract(lastNValue, 'months')

    case 'NextNDays':
      return moment().startOf('day')

    default:
      return moment()
  }
}

export const getEndDate = (dateRangeType, lastNValue = 1) => {
  switch (dateRangeType) {
    case 'Today':
      return moment().endOf('day')

    case 'Yesterday':
      return moment().subtract(1, 'days').endOf('day')

    case 'LastWeek':
      return moment().subtract(1, 'weeks').endOf('week')

    case 'LastMonth':
      return moment().subtract(1, 'months').endOf('month')

    case 'LastYear':
      return moment().subtract(1, 'years').endOf('year')

    case 'NextNDays':
      return moment().add(lastNValue, 'days')

    default:
      return moment()
  }
}

export const isWeekend = (day) => [6, 7].includes(moment(day).isoWeekday())
