import * as React from 'react'
import { Form, Input } from 'antd'
import { isNil, cloneDeep, set, unset } from 'lodash'
import { showError } from 'helpers/errors'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import { Row, Col } from 'elements/Grid'
import {
  createDropdownRender,
  createSearchJobs,
  createSearchOperators,
  createSearchSuppliers,
} from 'helpers/formViews'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { getTenantDisplayName } from 'helpers/utils'

const periodTypes = {
  Hours: 'hours',
  Days: 'days',
  Months: 'months',
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchOperators = createSearchOperators(this)
    this.searchSuppliers = createSearchSuppliers(this)
    this.searchJobs = createSearchJobs(this)
  }

  componentDidMount() {
    const { locationGroupIds, locationIds, operatorGroupIds, operatorIds, supplierIds } = this.props.filterDto
    const isTrmsOrDcrib = this.props.tenantTypeIsTrms || this.props.userIsDcribCustomer

    Promise.all([
      this.props.getLocationGroups(),
      this.props.getLocations({ locationGroupIds, locationIds }),
      isTrmsOrDcrib && this.props.getJobs(),
      this.props.getSuppliers(supplierIds ? { supplierIds } : undefined),
      isTrmsOrDcrib && this.props.getOperatorGroups(),
      isTrmsOrDcrib && this.props.getOperators({ operatorIds, operatorGroupIds }),
      this.props.getTenants({
        tenantType: 'Trms',
        supplierLinkTenantsOnly: true,
      }),
      this.props.userIsDcribDistributor ? this.props.getCustomers() : Promise.resolve(null),
    ]).catch((error) => showError({ error }))
  }

  changeTenantId = async (tenantId) => {
    const filterDto = cloneDeep(this.props.filterDto)

    if (!isNil(tenantId)) {
      set(filterDto, 'targetTenantId', tenantId)
    } else {
      unset(filterDto, 'targetTenantId')
    }

    try {
      await this.props.getLocationGroups(this.props.userIsInRoleSupplier ? { tenantId } : undefined)
      await this.props.onChange({ ...filterDto })
    } catch (error) {
      showError({ error })
    }
  }

  changeCustomerId = async (customerId) => {
    const filterDto = cloneDeep(this.props.filterDto)

    if (!isNil(customerId)) {
      set(filterDto, 'customerIds', [customerId])
    } else {
      unset(filterDto, 'customerIds')
    }

    try {
      await this.props.getLocationGroups({ customerId })
      await this.props.onChange({ ...filterDto })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto, jobs, operators, suppliers } = this.props

    const isTrmsOrDcrib = this.props.tenantTypeIsTrms || this.props.userIsDcribCustomer

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker filterDto={filterDto} setFilterValue={this.setFilterValue} />
            <Form.Item label={t('period')}>
              <Select
                onChange={(value) => this.setFilterValue('periodType', value || 'Days')}
                value={filterDto.periodType}
              >
                {Object.entries(periodTypes).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('barcode')}>
              <Input
                onChange={(e) => this.setFilterValue('barcode', e.target.value)}
                value={filterDto.barcode}
                placeholder={t('required')}
                autoFocus
              />
            </Form.Item>
            {isTrmsOrDcrib && (
              <Form.Item label={t('locationGroup')}>
                <Select
                  onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                  placeholder={t('all')}
                  value={filterDto.locationGroupIds}
                  mode="multiple"
                >
                  {(this.props.locationGroups ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {isTrmsOrDcrib && (
              <Form.Item label={t('location')}>
                <Select
                  onChange={(value) => this.setFilterValue('locationIds', value)}
                  mode="multiple"
                  placeholder={t('all')}
                  value={filterDto.locationIds}
                >
                  {(this.props.locations ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          {isTrmsOrDcrib && (
            <Col fourth>
              <Form.Item label={t('job')}>
                <Select
                  onChange={(value) => this.setFilterValue('jobIds', value)}
                  mode="multiple"
                  placeholder={t('all')}
                  value={filterDto.jobIds}
                  dropdownRender={createDropdownRender(jobs)}
                  onSearch={this.searchJobs}
                  showSearch
                >
                  {(jobs?.items ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={t('operatorGroups')}>
                <Select
                  onChange={(values) => this.setFilterValue('operatorGroupIds', values)}
                  mode="multiple"
                  placeholder={t('all')}
                  value={filterDto.operatorGroupIds}
                >
                  <Option value={-1}>{t('unassigned')}</Option>
                  {(this.props.operatorGroups ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={t('operator')}>
                <Select
                  onChange={(value) => this.setFilterValue('operatorIds', value)}
                  mode="multiple"
                  placeholder={t('all')}
                  value={filterDto.operatorIds}
                  dropdownRender={createDropdownRender(operators)}
                  onSearch={this.searchOperators}
                  showSearch
                >
                  {(operators?.items ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col fourth>
            {this.props.tenantTypeIsTrms && (
              <Form.Item label={t('suppliers')}>
                <Select
                  onChange={(value) => this.setFilterValue('supplierIds', value)}
                  mode="multiple"
                  placeholder={t('all')}
                  value={filterDto.supplierIds}
                  dropdownRender={createDropdownRender(suppliers)}
                  onSearch={this.searchSuppliers}
                  showSearch
                >
                  {(suppliers?.items ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {this.props.userIsInRoleSupplier && (
              <Form.Item label={t('customer')} required>
                <Select
                  onChange={(value) => this.changeTenantId(value)}
                  placeholder={t('select')}
                  value={filterDto.targetTenantId}
                >
                  {(this.props.tenants ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{getTenantDisplayName(each)}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {this.props.userIsInRoleDistributor && (
              <Form.Item label={t('customer')}>
                <Select
                  placeholder={t('select')}
                  onChange={(value) => this.changeCustomerId(value)}
                  value={filterDto.customerIds}
                >
                  {(this.props.customers ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col filter />
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
