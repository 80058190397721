import * as React from 'react'
import produce from 'immer'
import { Form, Input, Spin, Button, Tooltip } from 'antd'
import { isNil, get, set, isEmpty } from 'lodash'
import { showError, showValidationError, ValidationError } from 'helpers/errors'
import { createLabelFactory, createFieldFactory, isReadOnly } from 'helpers/formViews'
import { createGetLabelTemplateWithImages } from 'helpers/labelTemplates'
import { stopEvent } from 'helpers/events'
import linkTargets from 'options/linkTargets'
import { createPrintHandler, resetPrintHandler } from 'helpers/print'
import { getStorageItem, setStorageItem } from 'helpers/localStorage'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import InputNumber from 'elements/InputNumber'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import Print from './Print'
import Drawer from 'elements/Drawer'
import { Row, Col } from 'elements/Grid'

const getStorageKey = () => 'printLabel.selectedTemplateIds'

const LabelTemplatesFormView = linkTargets.labelTemplateRecord.formComponent

class FormView extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.printableRef = React.createRef()
    this.getLabelTemplateWithImages = createGetLabelTemplateWithImages(this)
  }

  componentDidMount() {
    this.fetchSettings()
  }

  promiseState = (state = {}) => new Promise((resolve) => this.setState(state, resolve))

  fetchSettings = async () => {
    try {
      const item = {
        labelTemplateType: this.props.type,
        numberOfCopies: 1,
      }

      const responses = await Promise.all([
        this.props.getSettings({ type: 'printLabels' }),
        this.props.getLabelTemplates({ labelTemplateType: this.props.type }),
        this.props.getLabelTemplateFields({ type: this.props.type }),
      ])

      this.setState(
        {
          item,
          labelsToPrint: this.props.items,
          fieldSettings: responses[0].value.data.fieldSettings,
          labelTemplates: get(responses[1], 'value.data.items', []),
          labelTemplateFields: get(responses[2], 'value.data.items', []),
        },
        this.loadSelectedTemplateId
      )
    } catch (error) {
      showError({ error })
    }
  }

  setItemValue = (name, value) =>
    this.setState(
      produce((draft) => {
        set(draft.item, name, value)

        this.props.form.setFieldsValue({ [name]: value })
      }),
      this.saveSelectedTemplateId
    )

  handleSubmit = (e) => {
    stopEvent(e)

    this.props.form.validateFields(async (errors, values) => {
      this.setState({ validationErrors: errors })

      if (!isEmpty(errors)) {
        showValidationError()
        return
      }

      try {
        this.setState({ printPreviewLoading: true })

        const item = await this.props.getLabelTemplate(values.labelTemplateId).then((r) => r.value.data)

        const itemWithImages = await this.getLabelTemplateWithImages(item)

        this.setState({
          selectedLabelTemplate: itemWithImages,
          printPreviewVisible: true,
        })
      } catch (error) {
        showError({ error })
      } finally {
        this.setState({ printPreviewLoading: false })
      }
    })
  }

  handleEditLabelTemplateClose = async () => {
    try {
      await this.promiseState({ editLabelTemplateVisible: false, editLabelTemplateSaving: false })

      const { labelTemplateType } = this.state.item ?? {}

      const response = await this.props.getLabelTemplates({ labelTemplateType })

      this.setState({ labelTemplates: response.value.data.items })
    } catch (error) {
      showError({ error })
    }
  }

  loadSelectedTemplateId = () =>
    this.setState(
      produce((draft) => {
        const selectedTemplateIds = getStorageItem(getStorageKey(), {})
        const typeTemplateId = get(selectedTemplateIds, draft.item.labelTemplateType, null)
        const labelTemplateId = draft.labelTemplates.find((one) => one.id === typeTemplateId)?.id

        draft.item.labelTemplateId = labelTemplateId

        this.props.form.setFieldsValue({ labelTemplateId })
      })
    )

  saveSelectedTemplateId = () => {
    const { labelTemplateType, labelTemplateId } = this.state.item
    const selectedTemplateIds = getStorageItem(getStorageKey(), {})

    setStorageItem(getStorageKey(), {
      ...selectedTemplateIds,
      [labelTemplateType]: labelTemplateId,
    })
  }

  render() {
    const { item, fieldSettings, labelTemplates = [], labelTemplateFields = [] } = this.state

    if (!item || !fieldSettings) {
      return <Spin />
    }

    const createLabel = createLabelFactory(fieldSettings)
    const createFieldDecorator = createFieldFactory(this.props.form, item, fieldSettings)

    const labelTemplate = labelTemplates.find((one) => one.id === item.labelTemplateId)

    const readOnly = isReadOnly(this)

    return (
      <>
        <Form layout="vertical" colon={false} onSubmit={readOnly ? stopEvent : this.handleSubmit}>
          <div className="form-items-container">
            <ValidationError errors={this.state.validationErrors} />
            <Form.Item label={createLabel('labelTemplateType')}>
              <Input value={t(item.labelTemplateType)} disabled />
            </Form.Item>
            <Form.Item label={createLabel('labelTemplateId')}>
              <div className="tofino-label-buttons">
                <Tooltip title={t('editLabelTemplate')} placement="topLeft">
                  <Button
                    size="small"
                    onClick={() =>
                      this.setState({
                        editLabelTemplateVisible: true,
                        editLabelTemplateSaving: false,
                      })
                    }
                    disabled={isNil(item.labelTemplateId)}
                  >
                    <Icon type="Edit" />
                  </Button>
                </Tooltip>
              </div>
              {createFieldDecorator('labelTemplateId')(
                <Select
                  onChange={(value) => this.setItemValue('labelTemplateId', value)}
                  placeholder={t('select')}
                  allowClear={false}
                >
                  {labelTemplates.map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.name}</span>
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={createLabel('description')}>
              <Input.TextArea value={get(labelTemplate, 'description', '')} rows={4} disabled />
            </Form.Item>
            <Row>
              <Col xs={12}>
                <Form.Item label={createLabel('labelsToPrint')}>
                  <Input value={this.state.labelsToPrint.length} disabled />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label={createLabel('numberOfCopies')}>
                  {createFieldDecorator('numberOfCopies')(
                    <InputNumber
                      onChange={(value) => this.setItemValue('numberOfCopies', Math.max(value, 1))}
                      min={1}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="form-buttons-container">
            <Button className="mr-6" onClick={this.props.onCancel}>
              {t('cancel')}
            </Button>
            <Button type="primary" htmlType="submit" loading={this.state.printPreviewLoading}>
              {t('printPreview')}
            </Button>
          </div>
        </Form>
        <Modal
          title={t('printPreview')}
          okText={t('print')}
          onOk={createPrintHandler(this.printableRef)}
          onCancel={() => this.setState({ printPreviewVisible: false }, resetPrintHandler)}
          width={992}
          visible={this.state.printPreviewVisible}
        >
          <div ref={this.printableRef} className="tofino-print-preview">
            <Print
              items={this.state.labelsToPrint}
              labelTemplateFields={labelTemplateFields}
              labelTemplate={this.state.selectedLabelTemplate}
              numberOfCopies={item.numberOfCopies}
            />
          </div>
        </Modal>
        <Drawer
          title={t('editLabelTemplate')}
          size={linkTargets.labelTemplateRecord.formSize}
          visible={this.state.editLabelTemplateVisible}
          onClose={this.handleEditLabelTemplateClose}
          saving={this.state.editLabelTemplateSaving}
        >
          <LabelTemplatesFormView
            linkTargetRecord={labelTemplate}
            onSave={(pending) => this.setState({ editLabelTemplateSaving: pending })}
            onSaveAndClose={this.handleEditLabelTemplateClose}
            onCancel={this.handleEditLabelTemplateClose}
          />
        </Drawer>
      </>
    )
  }
}

export default Form.create()(FormView)
