import { snakeCase } from 'lodash'
import { createChildActions } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { createChildPost } from 'helpers/api'

const entityName = entityNames.assets
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const childName = 'assetTolerances'
const CHILD_NAME = snakeCase(childName).toUpperCase()

export default {
  ...createChildActions(entityName, childName),

  createHistoryItems: (parentId, items) => ({
    type: `${ENTITY_NAME}_${CHILD_NAME}_HISTORY_ITEMS_CREATE`,
    payload: createChildPost(entityName, childName, { action: 'history' })(parentId, items),
  }),
}
