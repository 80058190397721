import * as React from 'react'
import { Form } from 'antd'
import { set, cloneDeep, isNil, unset } from 'lodash'
import { showError } from 'helpers/errors'
import statusOptions from 'options/rfqs/statusOptions'
import { createSearchSuppliers, createDropdownRender } from 'helpers/formViews'
import { HARD_SPACE, getTenantDisplayName } from 'helpers/utils'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import Checkbox from 'elements/Checkbox'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchSuppliers = createSearchSuppliers(this)
  }

  async componentDidMount() {
    try {
      const { locationGroupIds, locationIds, supplierIds, tenantId } = this.props.filterDto
      await Promise.all([
        this.props.getLocationGroups(),
        this.props.getLocations({ locationGroupIds, locationIds }),
        this.props.getSuppliers(supplierIds ? { supplierIds } : undefined),
        this.props.getUsers(this.props.userIsInRoleSupplier ? { tenantId } : undefined),
        this.props.getTenants({
          tenantType: 'Trms',
          supplierLinkTenantsOnly: true,
        }),
        this.props.userIsDcribDistributor ? this.props.getCustomers() : Promise.resolve(null),
      ])
    } catch (error) {
      showError({ error })
    }
  }

  changeTenantId = async (tenantId) => {
    const filterDto = cloneDeep(this.props.filterDto)

    if (!isNil(tenantId)) {
      set(filterDto, 'tenantId', tenantId)
    } else {
      unset(filterDto, 'tenantId')
    }

    unset(filterDto, 'createdBy')

    try {
      await this.props.getUsers(this.props.userIsInRoleSupplier ? { tenantId } : undefined)
      await this.props.onChange({ ...filterDto })
    } catch (error) {
      showError({ error })
    }
  }

  changeCustomerId = async (customerId) => {
    const filterDto = cloneDeep(this.props.filterDto)

    if (!isNil(customerId)) {
      set(filterDto, 'customerId', customerId)
    } else {
      unset(filterDto, 'customerId')
    }

    unset(filterDto, 'createdBy')

    try {
      await this.props.getUsers({ customerId })
      await this.props.onChange({ ...filterDto })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto, suppliers, tenantTypeIsTrms } = this.props

    const renderLocationGroup = (
      <Form.Item label={t('locationGroup')}>
        <Select
          onChange={(values) => this.setFilterValue('locationGroupIds', values)}
          placeholder={t('all')}
          value={filterDto.locationGroupIds}
          mode="multiple"
        >
          {(this.props.locationGroups ?? []).map((each) => (
            <Option key={each.id} value={each.id}>
              <span {...getOptionProps(each)}>{each.displayName}</span>
            </Option>
          ))}
        </Select>
      </Form.Item>
    )

    const renderLocation = (
      <Form.Item label={t('location')}>
        <Select
          onChange={(value) => this.setFilterValue('locationIds', value)}
          mode="multiple"
          placeholder={t('all')}
          value={filterDto.locationIds}
        >
          {(this.props.locations ?? []).map((each) => (
            <Option key={each.id} value={each.id}>
              <span {...getOptionProps(each)}>{each.displayName}</span>
            </Option>
          ))}
        </Select>
      </Form.Item>
    )

    const renderSupplier = (
      <Form.Item label={t('supplier')}>
        <Select
          onChange={(value) => this.setFilterValue('supplierIds', value)}
          mode="multiple"
          placeholder={t('all')}
          value={filterDto.supplierIds}
          dropdownRender={createDropdownRender(suppliers)}
          onSearch={this.searchSuppliers}
          showSearch
        >
          {(suppliers?.items ?? []).map((each) => (
            <Option key={each.id} value={each.id}>
              <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
            </Option>
          ))}
        </Select>
      </Form.Item>
    )

    const renderCreatedBy = (
      <Form.Item label={t('createdBy')}>
        <Select
          onChange={(value) => this.setFilterValue('createdBy', value)}
          mode="multiple"
          placeholder={t('all')}
          value={filterDto.createdBy}
          showSearch
        >
          {(this.props.users ?? []).map((each) => (
            <Option key={each.userName} value={each.userName}>
              <span {...getOptionProps(each)}>{each.displayName}</span>
            </Option>
          ))}
        </Select>
      </Form.Item>
    )

    const renderStatus = (
      <Form.Item label={t('status')}>
        <Select
          onChange={(value) => this.setFilterValue('status', value)}
          mode="multiple"
          placeholder={t('all')}
          value={filterDto.status}
        >
          {Object.entries(statusOptions)
            .filter(([key, value]) => tenantTypeIsTrms || ['Open', 'Closed', 'Cancelled'].includes(key))
            .map(([key, value]) => (
              <Option key={key} value={key}>
                {t(value)}
              </Option>
            ))}
        </Select>
      </Form.Item>
    )

    const renderTenant = (
      <Form.Item label={t('customer')} required>
        <Select
          onChange={(value) => this.changeTenantId(value)}
          placeholder={t('select')}
          value={filterDto.tenantId}
          allowClear={false}
        >
          {(this.props.tenants ?? []).map((each) => (
            <Option key={each.id} value={each.id}>
              <span {...getOptionProps(each)}>{getTenantDisplayName(each)}</span>
            </Option>
          ))}
        </Select>
      </Form.Item>
    )

    const renderCustomer = (
      <Form.Item label={t('customer')}>
        <Select
          placeholder={t('all')}
          onChange={(value) => this.changeCustomerId(value)}
          value={filterDto.customerId}
        >
          {(this.props.customers ?? []).map((each) => (
            <Option key={each.id} value={each.id}>
              <span {...getOptionProps(each)}>{each.displayName}</span>
            </Option>
          ))}
        </Select>
      </Form.Item>
    )

    const renderExpedited = (
      <Form.Item label={HARD_SPACE}>
        <Checkbox
          onChange={(e) => this.setFilterValue('expeditedOnly', e.target.checked)}
          checked={filterDto.expeditedOnly}
        >
          {t('expeditedOnly')}
        </Checkbox>
      </Form.Item>
    )

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker filterDto={filterDto} setFilterValue={this.setFilterValue} />
          </Col>
          {tenantTypeIsTrms && (
            <>
              <Col fourth>
                {renderLocationGroup}
                {renderLocation}
              </Col>
              <Col fourth>
                {renderSupplier}
                {renderCreatedBy}
              </Col>
              <Col fourth>
                {renderStatus}
                {renderExpedited}
              </Col>
            </>
          )}
          {this.props.userIsDcribCustomer && (
            <>
              <Col fourth>
                {renderLocationGroup}
                {renderLocation}
              </Col>
              <Col fourth>
                {renderStatus}
                {renderCreatedBy}
              </Col>
              <Col fourth>{renderExpedited}</Col>
            </>
          )}
          {this.props.userIsDcribDistributor && (
            <>
              <Col fourth>{renderStatus}</Col>
              <Col fourth>
                {renderCustomer}
                {renderExpedited}
              </Col>
            </>
          )}
          {this.props.userIsInRoleSupplier && (
            <>
              <Col fourth>{renderStatus}</Col>
              <Col fourth>
                {renderTenant}
                {renderExpedited}
              </Col>
            </>
          )}
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
