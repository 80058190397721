import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import statusOptions from 'options/statusOptions'
import Select, { Option, getOptionProps } from 'elements/Select'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      await this.props.getOperatorGroups()
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto = {}, allowFilterByStatus } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <Form.Item label={t('operatorGroups')}>
              <Select
                onChange={(values) => this.setFilterValue('operatorGroupIds', values)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.operatorGroupIds}
              >
                <Option value={-1}>{t('unassigned')}</Option>
                {(this.props.operatorGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {allowFilterByStatus && (
            <Col third>
              <Form.Item label={t('status')}>
                <Select
                  onChange={(value) => this.setFilterValue('active', value || 'Active')}
                  value={filterDto.active || 'Active'}
                >
                  {Object.entries(statusOptions).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {t(value)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
