import styled from 'styled-components'
import chartTypes from 'options/dashboards/chartTypes'
import { tryParseJSON } from 'helpers/utils'
import Icon from 'elements/Icon'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-width: 1px;
  border-color: #d9d9d9;
  border-style: ${(props) => (props.showBorder ? 'solid' : 'dashed')};
  min-height: ${(props) => `${props.sectionHeight}px`};
`

const Wrapper = styled.div``

const Name = styled.div`
  font-size: 13px;
  text-align: center;
`

function Component(props) {
  const { chart = {}, section } = props

  const { showBorder = false } = tryParseJSON(chart.chartJson, {})
  const { iconType, iconRotation = 0 } = chartTypes[chart.chartType]

  return (
    <Container showBorder={showBorder} sectionHeight={section.height}>
      {iconType && (
        <Wrapper>
          <Icon
            type={iconType}
            style={iconRotation ? { transform: `rotate(${iconRotation}deg)` } : {}}
            size={42}
          />
        </Wrapper>
      )}
      <Name>{chart.name}</Name>
    </Container>
  )
}

export default Component
