import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { allowPricing } from 'helpers/auth'
import Filter from 'containers/Reports/PriceSavingsReport/Filter'

export default createListViewComponent({
  entityName: entityNames.priceSavingsReport,
  filterTemplateType: 'PriceSavingsReport',
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  tableSummary: (self) =>
    allowPricing() ? `${t('totalSavings:')} ${toLocaleCurrency(self.props.list?.totalSavings ?? 0)}` : '',
})(Filter)
