import styled from 'styled-components'
import produce from 'immer'
import { set, clamp } from 'lodash'
import { Form, Radio, Input } from 'antd'
import characterTypes from 'options/settings/characterTypes'
import { t } from 'helpers/i18n'
import { HARD_SPACE } from 'helpers/utils'
import InputNumber from 'elements/InputNumber'
import Checkbox from 'elements/Checkbox'
import Help from 'elements/Help'
import Select, { Option } from 'elements/Select'
import { Row, Col } from 'elements/Grid'

const Header = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .ant-radio-wrapper {
    margin-right: 24px;
    margin-bottom: 12px;
  }
`

function Component(props) {
  const { values, onChange, readOnly } = props

  function setValue(key, value) {
    onChange(
      produce(values, (draft) => {
        set(draft, key, value)
      })
    )
  }

  const definitionModeBasic = values?.definitionMode === 'Basic'

  return (
    <>
      <Header>
        <Radio.Group
          value={values?.definitionMode}
          onChange={(e) => !readOnly && setValue('definitionMode', e.target.value)}
        >
          <Radio value="Basic">{t('basicPasswordDefinition')}</Radio>
          <Radio value="Advanced">{t('advancedPasswordDefinition')}</Radio>
        </Radio.Group>
      </Header>
      <div className="pt-24">
        <Row gutter={24}>
          <Col third>
            <Row>
              <Col xs={12}>
                <Form.Item label={t('minimumLength')}>
                  <InputNumber
                    value={values?.minLength}
                    onChange={(value) => setValue('minLength', clamp(value, 1, 64))}
                    disabled={definitionModeBasic}
                    readOnly={readOnly}
                    min={1}
                    max={64}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label={t('maximumLength')}>
                  <InputNumber
                    value={values?.maxLength}
                    onChange={(value) => setValue('maxLength', clamp(value, 1, 64))}
                    disabled={definitionModeBasic}
                    readOnly={readOnly}
                    min={1}
                    max={64}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label={t('firstCharacterType')}>
              <Select
                value={values?.firstCharacterType}
                onChange={(value) => setValue('firstCharacterType', value)}
                disabled={definitionModeBasic}
                readOnly={readOnly}
                allowClear={false}
              >
                {Object.entries(characterTypes).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('lastCharacterType')}>
              <Select
                value={values?.lastCharacterType}
                onChange={(value) => setValue('lastCharacterType', value)}
                disabled={definitionModeBasic}
                readOnly={readOnly}
                allowClear={false}
              >
                {Object.entries(characterTypes).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('allowableSpecialCharacters')}>
              <Input
                value={values?.validSpecialCharacters}
                onChange={(e) => setValue('validSpecialCharacters', e.target.value)}
                disabled={definitionModeBasic}
                readOnly={readOnly}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('numberInvalidHistoricalPasswords')}{' '}
                  <Help title={t('numberInvalidHistoricalPasswordsInfo')} />
                </span>
              }
            >
              <InputNumber
                value={values?.numInvalidHistoricalPasswords}
                onChange={(value) => setValue('numInvalidHistoricalPasswords', value)}
                disabled={definitionModeBasic}
                max={64}
                readOnly={readOnly}
              />
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={HARD_SPACE}>
              <Checkbox
                checked={values?.allowAlphaLowercase}
                onChange={(e) => setValue('allowAlphaLowercase', e.target.checked)}
                disabled={definitionModeBasic}
                readOnly={readOnly}
              >
                {t('allowAlphaLowercase')}
              </Checkbox>
            </Form.Item>
            <Form.Item label={HARD_SPACE}>
              <Checkbox
                checked={values?.allowAlphaUppercase}
                onChange={(e) => setValue('allowAlphaUppercase', e.target.checked)}
                disabled={definitionModeBasic}
                readOnly={readOnly}
              >
                {t('allowAlphaUppercase')}
              </Checkbox>
            </Form.Item>
            <Form.Item label={HARD_SPACE}>
              <Checkbox
                checked={values?.allowNumeric}
                onChange={(e) => setValue('allowNumeric', e.target.checked)}
                disabled={definitionModeBasic}
                readOnly={readOnly}
              >
                {t('allowNumeric')}
              </Checkbox>
            </Form.Item>
            <Form.Item label={HARD_SPACE}>
              <Checkbox
                checked={values?.canContainUsername}
                onChange={(e) => setValue('canContainUsername', e.target.checked)}
                disabled={definitionModeBasic}
                readOnly={readOnly}
              >
                {t('canContainUserName')}
              </Checkbox>
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('maximumFailedLoginAttempts')} <Help title={t('maximumFailedLoginAttemptsInfo')} />
                </span>
              }
            >
              <InputNumber
                value={values?.maxFailedLoginAttempts}
                onChange={(value) => setValue('maxFailedLoginAttempts', value)}
                disabled={definitionModeBasic}
                max={64}
                readOnly={readOnly}
              />
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('minimumLowercaseCharacters')}>
              <InputNumber
                value={values?.minLowercaseCharacters}
                onChange={(value) => setValue('minLowercaseCharacters', value)}
                disabled={definitionModeBasic}
                max={64}
                readOnly={readOnly}
              />
            </Form.Item>
            <Form.Item label={t('minimumUppercaseCharacters')}>
              <InputNumber
                value={values?.minUppercaseCharacters}
                onChange={(value) => setValue('minUppercaseCharacters', value)}
                disabled={definitionModeBasic}
                max={64}
                readOnly={readOnly}
              />
            </Form.Item>
            <Form.Item label={t('minimumNumericCharacters')}>
              <InputNumber
                value={values?.minNumericCharacters}
                onChange={(value) => setValue('minNumericCharacters', value)}
                disabled={definitionModeBasic}
                max={64}
                readOnly={readOnly}
              />
            </Form.Item>
            <Form.Item label={t('minimumSpecialCharacters')}>
              <InputNumber
                value={values?.minSpecialCharacters}
                onChange={(value) => setValue('minSpecialCharacters', value)}
                disabled={definitionModeBasic}
                max={64}
                readOnly={readOnly}
              />
            </Form.Item>
            <Form.Item label={t('maximumRepeatCount')}>
              <InputNumber
                value={values?.maxRepeatCount}
                onChange={(value) => setValue('maxRepeatCount', clamp(value, 1, 64))}
                disabled={definitionModeBasic}
                min={1}
                max={64}
                readOnly={readOnly}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Component
