import { createListViewComponent } from 'factories/ListView'
import Filter from 'containers/JobTemplates/Filter'

export default createListViewComponent({
  entityName: 'jobTemplates',
  filterTemplateType: 'JobTemplate',
  createButtonTextId: 'createTemplate',
  primaryLinkTarget: 'jobTemplateRecord',
  initialFilterDto: (self) => ({
    dateRangeField: 'All',
    dateRange: { dateRangeType: 'YearToDate' },
  }),
  getFileTemplateTypes: (self) => ['JobsTemplate'],
  getSettingsType: (self) => 'jobTemplates',
})(Filter)
