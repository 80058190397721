import * as React from 'react'
import { Form, Button, Input } from 'antd'
import { showError, showClientNotifications } from 'helpers/errors'
import { createDropdownRender, createSearchSuppliers } from 'helpers/formViews'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { formatDateTime } from 'helpers/dateTime'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import Checkbox from 'elements/Checkbox'
import Help from 'elements/Help'
import SelectProductCategories from 'containers/ProductCategories/Select'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchSuppliers = createSearchSuppliers(this)
  }

  componentDidMount() {
    const { locationGroupIds, locationIds, supplierIds, productCategoryIds } = this.props.filterDto

    Promise.all([
      this.props.getLocationGroups(),
      this.props.getLocations({ locationGroupIds, locationIds }),
      this.props.getSuppliers(supplierIds ? { supplierIds } : undefined),
      this.props.getProductCategories({ productCategoryIds }),
    ]).catch((error) => showError({ error }))
  }

  handleRebuildDeficiencyClick = async () => {
    try {
      this.setState({ rebuildDeficiencyLoading: true })

      const response = await this.props.rebuildDeficiency()

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }

      await this.props.refreshToken(true)

      this.setFilterValue('lastDeficiencyReportUpdate', this.props.customer.lastDeficiencyReportUpdate)
    } catch (error) {
      showError({ error })
    } finally {
      this.setState({ rebuildDeficiencyLoading: false })
    }
  }

  render() {
    const { customer, filterDto, suppliers } = this.props
    const lastDeficiencyReportUpdate = formatDateTime(customer.lastDeficiencyReportUpdate, {
      showTime: true,
    })

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('supplier')}>
              <Select
                onChange={(value) => this.setFilterValue('supplierIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.supplierIds}
                dropdownRender={createDropdownRender(suppliers)}
                onSearch={this.searchSuppliers}
                showSearch
              >
                {(suppliers?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('lastRebuiltDate')} <Help title={t('lastRebuiltDateInfo')} />
                </span>
              }
            >
              <Row>
                <Col xs={12}>
                  <Input value={lastDeficiencyReportUpdate} title={lastDeficiencyReportUpdate} disabled />
                </Col>
                <Col xs={12}>
                  <Button
                    type="primary"
                    onClick={this.handleRebuildDeficiencyClick}
                    loading={this.state.rebuildDeficiencyLoading}
                    block
                  >
                    {t('rebuild')}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('productCategory')}>
              <SelectProductCategories
                value={filterDto.productCategoryIds}
                placeholder={t('all')}
                onChange={(value) => this.setFilterValue('productCategoryIds', value)}
                dataSource={this.props.productCategories ?? []}
              />
            </Form.Item>
          </Col>
          <Col fourth>
            <div className="mt-24">
              <Checkbox
                onChange={(e) => this.setFilterValue('includeAssetCountInOnHandQuantity', e.target.checked)}
                checked={filterDto.includeAssetCountInOnHandQuantity}
              >
                {t('includeAssetCountInOnHandQuantity')} <Help title={t('includeAssetCountInfo')} />
              </Checkbox>
            </div>
            <div className="mt-24">
              <Checkbox
                onChange={(e) => this.setFilterValue('onlyShowAssetReorderBarcodes', e.target.checked)}
                checked={filterDto.onlyShowAssetReorderBarcodes}
              >
                {t('assetReorderBarcodes')} <Help title={t('assetReorderBarcodesInfo')} />
              </Checkbox>
            </div>
            <div className="mt-24">
              <Checkbox
                onChange={(e) => this.setFilterValue('includeDoNotReorderItems', e.target.checked)}
                checked={filterDto.includeDoNotReorderItems}
              >
                {t('includeDoNotReorderItems')}
              </Checkbox>
            </div>
            <div className="mt-24">
              <Checkbox
                onChange={(e) => this.setFilterValue('onlyShowCriticalInventoryItems', e.target.checked)}
                checked={filterDto.onlyShowCriticalInventoryItems}
              >
                {t('critical')}
              </Checkbox>
            </div>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
