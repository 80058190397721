import { connect } from 'react-redux'
import selectors from 'selectors'
import productCategoryActions from 'actions/productCategories'
import productActions from 'actions/products'
import Component from 'components/DashboardViewer/Filter/Products'

const mapStateToProps = (state, props) => ({
  productCategories: selectors.productCategories.selectOptions(state),
  products: selectors.products.selectOptions(state, { itemsOnly: false }),
})

const mapDispatchToProps = (dispatch, props) => ({
  getProductCategories: (params) =>
    dispatch(productCategoryActions.getOptions({ ...params, active: 'All', pageIndex: 0 })),
  getProducts: (params) => dispatch(productActions.getOptions({ ...params, pageIndex: 1, pageSize: 100 })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
