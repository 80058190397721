import { snakeCase, memoize } from 'lodash'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { createGet } from 'helpers/api'

const entityName = entityNames.entityLists
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const getEntityTypes = memoize(createGet(entityName, { action: 'entityTypes' }), JSON.stringify)

export default {
  ...createActions(entityName),

  getEntityTypes: (params) => ({
    type: `${ENTITY_NAME}_ENTITY_TYPES_GET`,
    payload: getEntityTypes(params),
  }),
}
