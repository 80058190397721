import * as React from 'react'
import { Form, Input, message } from 'antd'
import { cloneDeep, isNil, set, unset } from 'lodash'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import { Row, Col } from 'elements/Grid'
import DateRangePicker from 'elements/DateRangePicker'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      const { locationGroupId } = this.props.filterDto

      if (!locationGroupId) {
        message.error(t('selectLocationGroupFirst'))
      }

      await this.props.getLocationGroups()
    } catch (error) {
      showError({ error })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filterDto.locationGroupId && !this.props.filterDto.locationGroupId) {
      message.error(t('selectLocationGroupFirst'))
    }
  }

  setLocationGroupId = (locationGroupId) => {
    const params = cloneDeep(this.props.filterDto)

    if (!isNil(locationGroupId)) {
      const { overstockLocationId } =
        (this.props.locationGroups ?? []).find((one) => one.id === locationGroupId) ?? {}

      set(params, 'locationGroupId', locationGroupId)
      set(params, 'overstockLocationId', overstockLocationId)
    } else {
      unset(params, 'locationGroupId')
      unset(params, 'overstockLocationId')

      message.error(t('selectLocationGroupFirst'))
    }

    this.props.onChange({ ...params })
  }

  render() {
    const { filterDto } = this.props
    const { overstockLocationName } =
      (this.props.locationGroups ?? []).find((one) => one.id === filterDto.locationGroupId) ?? {}

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker filterDto={filterDto} setFilterValue={this.setFilterValue} />
          </Col>
          <Col fourth>
            <Form.Item label={t('locationGroup')} required>
              <Select
                onChange={(value) => this.setLocationGroupId(value)}
                placeholder={t('select')}
                value={filterDto.locationGroupId}
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('overstockLocation')}>
              <Input value={overstockLocationName} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} xl={6}>
            <Row>
              <Col half>
                <Form.Item label={t('firstBinLocation')}>
                  <Input
                    value={filterDto.firstBinLocation}
                    onChange={(e) => this.setFilterValue('firstBinLocation', e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col half>
                <Form.Item label={t('lastBinLocation')}>
                  <Input
                    value={filterDto.lastBinLocation}
                    onChange={(e) => this.setFilterValue('lastBinLocation', e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
