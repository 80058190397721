import { Button, Form } from 'antd'
import { createListViewComponent } from 'factories/ListView'
import { isNil } from 'lodash'
import { t } from 'helpers/i18n'
import { showClientNotifications, showError } from 'helpers/errors'
import { getUserPermission } from 'helpers/auth'
import entityNames from 'options/entityNames'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import Select, { Option, getOptionProps } from 'elements/Select'
import PrintLabel from 'containers/PrintLabel'
import Filter from 'containers/Locations/Filter'

export default createListViewComponent({
  entityName: entityNames.locations,
  filterTemplateType: 'Location',
  createButtonTextId: 'createLocation',
  primaryLinkTarget: 'locationRecord',
  allowDelete: (self) => false,
  allowCreate: (self) => !self.props.userIsDcribCustomer || !isNil(self.props.impersonator),
  allowDeactivate: (self) => !self.props.userIsDcribCustomer,
  transformGetItemsParams: (self) => ({ includeAllLocations: true }),
  getFileTemplateTypes: (self) => (self.props.tenantGroupIsCardinal ? ['Locations'] : []),
  extraRowActions: (self) => (
    <>
      <PrintLabel
        type="Location"
        items={self.state.selectedRowKeys.map((each) => self.state.items.find((one) => one.id === each))}
        parentHasUnsavedChanges={self.hasUnsavedChanges()}
      />
      {getUserPermission('Inventory') === 'Edit' && self.props.tenantGroupIsCardinal ? (
        <>
          <Button
            onClick={async () => {
              try {
                const response = await self.props.getLocations()

                self.setState({
                  duplicateInventoryLocations: response.value.data.items,
                  duplicateInventoryVisible: true,
                  duplicateInventoryToLocationId: undefined,
                })
              } catch (error) {
                showError({ error })
              }
            }}
            disabled={self.state.selectedRowKeys?.length !== 1}
          >
            <Icon type="ContentCopy" />
            {t('duplicateInventory')}
          </Button>
          <Modal
            title={t('duplicateInventory')}
            visible={self.state.duplicateInventoryVisible}
            okText={t('duplicate')}
            okButtonProps={{
              disabled: isNil(self.state.duplicateInventoryToLocationId),
              loading: self.state.duplicateInventoryLoading,
            }}
            onOk={() =>
              Modal.confirm({
                autoFocusButton: 'ok',
                title: t('confirmDuplicateInventory'),
                okType: 'primary',
                okText: t('duplicate'),
                cancelText: t('cancel'),
                onOk: async () => {
                  try {
                    self.setState({ duplicateInventoryLoading: true })

                    const response = await self.props.duplicateInventory({
                      fromLocationId: self.state.selectedRowKeys[0],
                      toLocationId: self.state.duplicateInventoryToLocationId,
                    })

                    showClientNotifications({ response })

                    self.setState({
                      duplicateInventoryVisible: false,
                      duplicateInventoryToLocationId: undefined,
                    })

                    self.fetchItems()
                  } catch (error) {
                    showError({ error })
                  } finally {
                    self.setState({ duplicateInventoryLoading: false })
                  }
                },
              })
            }
            cancelText={t('close')}
            onCancel={() =>
              self.setState({
                duplicateInventoryVisible: false,
                duplicateInventoryToLocationId: undefined,
              })
            }
          >
            <Form.Item label={t('fromLocation')} colon={false}>
              <Select value={self.state.selectedRowKeys[0]} disabled>
                {(self.state.duplicateInventoryLocations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('toLocation')} colon={false} required>
              <Select
                onChange={(value) => self.setState({ duplicateInventoryToLocationId: value })}
                placeholder={t('select')}
                value={self.state.duplicateInventoryToLocationId}
              >
                {(self.state.duplicateInventoryLocations ?? [])
                  .filter((each) => each.id !== self.state.selectedRowKeys[0])
                  .map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Modal>
        </>
      ) : null}
    </>
  ),
})(Filter)
