import * as React from 'react'
import { Spin, Form, Input } from 'antd'
import { isEmpty, get, isNil } from 'lodash'
import { useImmer } from 'use-immer'
import { showError } from 'helpers/errors'
import { createLabelFactory } from 'helpers/formViews'
import { stopEvent } from 'helpers/events'
import { formatUserTime } from 'helpers/dateTime'
import { Header, Title } from 'helpers/print'
import { t } from 'helpers/i18n'
import DatePicker from 'elements/DatePicker'
import Select, { Option, getOptionProps } from 'elements/Select'
import RfqItems from 'containers/Rfqs/Print/Items'
import RfqSupplierItems from 'containers/Rfqs/Print/Suppliers'
import { Row, Col } from 'elements/Grid'

function Component(props) {
  const { parentRecord, customer, tenant } = props

  const [state, updateState] = useImmer({ item: parentRecord })

  const { item } = state

  React.useEffect(() => {
    async function fetchSettings() {
      try {
        const customerId = item.customerId || props.user.customerId
        const targetTenantId = props.filterDto?.tenantId ?? 0

        const responses = await Promise.all([
          props.getSettings({ type: 'rfq' }),
          props
            .getDocumentContents({
              request: {
                domainObjectId: tenant.id,
                domainObjectType: 'Customer',
                documentType: 'PurchaseOrderLogo',
                documentName: tenant.subdomain,
              },
            })
            .catch(() => {}),
          props.getAddressList({ type: 'shipto', targetTenantId, customerId }),
          isNil(item.rfqItems) ? props.getRfqItems(item.id, { targetTenantId }) : Promise.resolve(null),
          isNil(item.rfqSupplierItems)
            ? props.getRfqSupplierItems(item.id, { targetTenantId })
            : Promise.resolve(null),
        ])

        updateState((draft) => {
          draft.fieldSettings = responses[0].value.data.fieldSettings
          draft.logo = get(responses[1], 'value.data', {})
          draft.shipToAddressList = get(responses[2], 'value.data.items', [])
          draft.rfqItems = item.rfqItems ?? get(responses[3], 'value.data.items', [])
          draft.rfqSupplierItems = item.rfqSupplierItems ?? get(responses[4], 'value.data.items', [])
        })
      } catch (error) {
        showError({ error })
      }
    }

    fetchSettings()
  }, [])

  const title = `${customer.displayName} - ${t('rfq')} ${item.id}`
  const createLabel = createLabelFactory(state.fieldSettings)

  return isEmpty(state.fieldSettings) ? (
    <Spin />
  ) : (
    <>
      <Title>
        <Col xs={16}>
          <h2>{title}</h2>
        </Col>
        <Col xs={8} className="text-right">
          {state.logo && state.logo.fileType && state.logo.contents && (
            <img src={`data:${state.logo.fileType};base64,${state.logo.contents}`} alt="" />
          )}
        </Col>
      </Title>
      <Header>
        <Form layout="vertical" colon={false} onSubmit={stopEvent}>
          <Row>
            <Col xs={8}>
              <Form.Item label={t('rfqId')}>
                <Input value={item.id} readOnly />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={createLabel('createDate')}>
                <Input value={formatUserTime(item.createDate, item.userName) || t('na')} readOnly />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={createLabel('statusName')}>
                <Input value={item.statusName} readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={16}>
              <Form.Item label={createLabel('shipToAddressId')}>
                <Select value={item.shipToAddressId} readOnly>
                  {(state.shipToAddressList ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={createLabel('rfqNumber')}>
                <Input value={item.rfqNumber} readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Form.Item label={createLabel('requisitionerName')}>
                <Input value={item.requisitionerName} placeholder="" readOnly />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={createLabel('buyerName')}>
                <Input value={item.buyerName} readOnly />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={createLabel('responseDueDate')}>
                <DatePicker value={item.responseDueDate} placeholder="" readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Form.Item label={createLabel('requisitionerPhone')}>
                <Input value={item.requisitionerPhone} type="tel" readOnly />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={createLabel('buyerPhone')}>
                <Input value={item.buyerPhone} type="tel" readOnly />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={createLabel('deliveryDueDate')}>
                <DatePicker value={item.deliveryDueDate} placeholder="" readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Form.Item label={createLabel('requisitionerEmail')}>
                <Input value={item.requisitionerEmail} type="email" readOnly />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={createLabel('buyerEmail')}>
                <Input value={item.buyerEmail} type="email" readOnly />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Header>
      <RfqItems
        title={t('itemsTab')}
        settingsType="rfq"
        items={state.rfqItems}
        parentRecord={{
          ...item,
          rfqItems: state.rfqItems,
          rfqSupplierItems: state.rfqSupplierItems,
        }}
      />
      <RfqSupplierItems
        title={t('suppliers')}
        settingsType="rfq"
        items={state.rfqSupplierItems}
        parentRecord={{
          ...item,
          rfqItems: state.rfqItems,
          rfqSupplierItems: state.rfqSupplierItems,
        }}
      />
      <h3>{t('comments')}</h3>
      <div
        dangerouslySetInnerHTML={{ __html: item.customerComment }}
        className="ant-input h-auto whitespace-pre-wrap mt-3 min-h-96"
      />
    </>
  )
}

export default Component
