import { Form, Button } from 'antd'
import { isNil, isEmpty } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import linkTargets from 'options/linkTargets'
import { getUserPermission } from 'helpers/auth'
import { showError, showClientNotifications } from 'helpers/errors'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import Modal from 'elements/Modal'
import Icon from 'elements/Icon'
import Drawer from 'elements/Drawer'
import PrintLabel from 'containers/PrintLabel'
import Filter from 'containers/Assets/Filter'
import ProcessSetupSheet from 'containers/ProcessSetupSheet/FormView'

const JobsFormView = linkTargets.jobRecord.formComponent

export default createListViewComponent({
  entityName: entityNames.assets,
  filterTemplateType: 'Asset',
  createButtonTextId: 'createAsset',
  primaryLinkTarget: 'assetRecord',
  initialFilterDto: (self) => ({
    dateRangeField: 'All',
    dateRange: { dateRangeType: 'YearToDate' },
    periodForCheckoutCount: 0,
    periodForCheckoutCountType: 'Days',
  }),
  extraRowActions: (self) => (
    <>
      <PrintLabel
        type="Asset"
        items={self.state.selectedRowKeys.map((each) => self.state.items.find((one) => one.id === each))}
        parentHasUnsavedChanges={self.hasUnsavedChanges()}
      />
      <Button
        onClick={() => self.setState({ createJobVisible: true, createJobSaving: false })}
        disabled={self.state.selectedRowKeys.length !== 1}
      >
        <Icon type="ContentPaste" />
        {t('createJob')}
      </Button>
      <Button
        onClick={() => self.setState({ processSetupSheetVisible: true })}
        disabled={self.state.selectedRowKeys.length !== 1}
      >
        <Icon type="SettingsApplications" />
        {t('processSetupSheet')}
      </Button>
      <Button
        disabled={isEmpty(self.state.selectedRowKeys)}
        onClick={async () => {
          try {
            self.setState({ checkInLoading: true })

            const response = await self.props.checkInAssets({ assetIds: self.state.selectedRowKeys })

            showClientNotifications({ response })

            if (response.value.data.failureCount > 0) {
              throw new Error()
            }

            self.setState({ checkInLoading: false }, self.fetchItems)
          } catch (error) {
            showError({ error })
          } finally {
            self.setState({ checkInLoading: false })
          }
        }}
        loading={self.state.checkInLoading}
      >
        {!self.state.checkInLoading && <Icon type="Input" />}
        {t('checkIn')}
      </Button>
      <Button
        disabled={isEmpty(self.state.selectedRowKeys)}
        onClick={async () => {
          try {
            const response = await self.props.getLocations()

            self.setState({
              moveAssetsLocations: response.value.data.items,
              moveAssetsVisible: true,
              moveAssetsNewLocationId: undefined,
            })
          } catch (error) {
            showError({ error })
          }
        }}
      >
        <Icon type="Send" />
        {t('move')}
      </Button>
      <Modal
        title={`${t('moveAssets')} (${self.state.selectedRowKeys.length} ${t('items')})`}
        visible={self.state.moveAssetsVisible}
        okText={t('move')}
        okButtonProps={{
          loading: self.state.moveAssetsLoading,
          disabled: isNil(self.state.moveAssetsNewLocationId),
        }}
        onOk={async () => {
          try {
            self.setState({ moveAssetsLoading: true })

            const response = await self.props.moveAssets({
              assetIds: self.state.selectedRowKeys,
              moveToLocationId: self.state.moveAssetsNewLocationId,
            })

            showClientNotifications({ response })

            if (response.value.data.failureCount > 0) {
              throw new Error()
            }

            self.setState({ moveAssetsVisible: false, moveAssetsNewLocationId: undefined }, self.fetchItems)
          } catch (error) {
            showError({ error })
          } finally {
            self.setState({ moveAssetsLoading: false })
          }
        }}
        onCancel={() => self.setState({ moveAssetsVisible: false, moveAssetsNewLocationId: undefined })}
      >
        <Form.Item label={t('location')} colon={false} required>
          <Select
            onChange={(value) => self.setState({ moveAssetsNewLocationId: value })}
            placeholder={t('select')}
            value={self.state.moveAssetsNewLocationId}
          >
            {(self.state.moveAssetsLocations ?? []).map((each) => (
              <Option key={each.id} value={each.id}>
                <span {...getOptionProps(each)}>{each.displayName}</span>
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Modal>
      <Drawer
        title={t('createJob')}
        size={linkTargets.jobRecord.formSize}
        visible={self.state.createJobVisible}
        onClose={() => self.setState({ createJobVisible: false })}
        saving={self.state.createJobSaving}
      >
        <JobsFormView
          assetId={self.state.selectedRowKeys[0]}
          locationId={self.props.items.find((one) => one.id === self.state.selectedRowKeys[0])?.locationId ?? 0}
          onSave={async (pending, savedItem) => {
            if (!pending) {
              await self.fetchItems()
            }
          }}
          onSaveAndClose={() => self.setState({ createJobVisible: false })}
          onCancel={() => self.setState({ createJobVisible: false })}
        />
      </Drawer>
      <Drawer
        title={t('processSetupSheet')}
        size="lg"
        visible={self.state.processSetupSheetVisible}
        onClose={() => self.setState({ processSetupSheetVisible: false })}
      >
        <ProcessSetupSheet
          assetId={self.state.selectedRowKeys[0]}
          onCancel={() => self.setState({ processSetupSheetVisible: false })}
        />
      </Drawer>
    </>
  ),

  deleteDisabled: (self) => getUserPermission('Assets') !== 'Edit',
  createDisabled: (self) => getUserPermission('Assets') !== 'Edit',
  getFileTemplateTypes: (self) => ['ProductsAT'],
})(Filter)
