import { Form, Row, Col } from 'antd'
import dateRangeTypes from 'options/dateRangeTypes'
import { t } from 'helpers/i18n'
import dateGroupTypes from 'options/dateGroupTypes'
import { getStartDate, getEndDate } from 'helpers/dateTime'
import { createGetFieldLabel } from 'helpers/dashboards'
import Select, { Option } from 'elements/Select'
import InputNumber from 'elements/InputNumber'
import DatePicker from 'elements/DatePicker'

function Component(props) {
  const { fields = [], filterDto = {}, setFilterValue = () => {}, readOnly } = props

  const dateRangeType = filterDto?.dateRange?.dateRangeType ?? 'YearToDate'
  const isTypeCustom = dateRangeType === 'Custom'
  const isTypeLastN = dateRangeType.startsWith('LastN')
  const lastNValue = filterDto?.dateRange?.lastNValue ?? 1
  const pickersDisabled = !isTypeCustom

  function handleDateRangeTypeChange(value) {
    if (value === 'Custom') {
      setFilterValue('dateRange.customStartDate', getStartDate(dateRangeType, lastNValue))
      setFilterValue('dateRange.customEndDate', getEndDate(dateRangeType, lastNValue))
    } else {
      setFilterValue('dateRange.customStartDate', undefined)
      setFilterValue('dateRange.customEndDate', undefined)
    }

    if (value.startsWith('LastN')) {
      setFilterValue('dateRange.lastNValue', lastNValue)
    } else {
      setFilterValue('dateRange.lastNValue', undefined)
    }

    setFilterValue('dateRange.dateRangeType', value)
  }

  const getFieldLabel = createGetFieldLabel(fields)

  return (
    <>
      {fields.find((one) => one.type === 'dateRangeType') && (
        <Form.Item label={getFieldLabel('dateRangeType')}>
          <Select
            style={{
              width: isTypeLastN ? 'calc(50% - 3px)' : '100%',
              marginRight: isTypeLastN ? '6px' : '0px',
            }}
            onChange={handleDateRangeTypeChange}
            value={dateRangeType}
            allowClear={false}
            readOnly={readOnly}
          >
            {Object.entries(dateRangeTypes).map(([key, value]) => (
              <Option key={key} value={key}>
                {t(value)}
              </Option>
            ))}
          </Select>
          <InputNumber
            onChange={(value) => setFilterValue('dateRange.lastNValue', Math.max(value, 1))}
            style={{
              width: 'calc(50% - 3px)',
              display: isTypeLastN ? '' : 'none',
            }}
            value={filterDto?.dateRange?.lastNValue}
            min={1}
            readOnly={readOnly}
          />
        </Form.Item>
      )}
      {fields.find((one) => one.type === 'dateRange') && (
        <Form.Item label={getFieldLabel('dateRange')}>
          <Row gutter={6}>
            <Col xs={12}>
              <DatePicker
                value={
                  isTypeCustom
                    ? filterDto?.dateRange?.customStartDate
                    : getStartDate(dateRangeType, lastNValue)
                }
                placeholder={t('startDate')}
                setTimeMode="start"
                onChange={(value) => setFilterValue('dateRange.customStartDate', value.toJSON())}
                disabled={pickersDisabled}
                allowClear={false}
                readOnly={readOnly}
              />
            </Col>
            <Col xs={12}>
              <DatePicker
                value={
                  isTypeCustom ? filterDto?.dateRange?.customEndDate : getEndDate(dateRangeType, lastNValue)
                }
                placeholder={t('endDate')}
                setTimeMode="end"
                onChange={(value) => setFilterValue('dateRange.customEndDate', value.toJSON())}
                disabled={pickersDisabled}
                allowClear={false}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </Form.Item>
      )}
      {fields.find((one) => one.type === 'dateGroupType') && (
        <Form.Item label={getFieldLabel('dateGroupType')}>
          <Select
            value={filterDto?.dateRange?.dateGroupType ?? 'Day'}
            onChange={(value) => setFilterValue('dateRange.dateGroupType', value || 'Day')}
            allowClear={false}
            readOnly={readOnly}
          >
            {Object.entries(dateGroupTypes).map(([key, value]) => (
              <Option key={key} value={key}>
                {t(value)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </>
  )
}

export default Component
