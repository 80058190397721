import * as React from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'
import { showError } from 'helpers/errors'
import { getPageTitle } from 'helpers/auth'
import Page from 'elements/Page'
import FormView from 'containers/Issue/FormView'

const Container = styled(Page)`
  .tofino-layout__content {
    padding: 0 !important;
  }

  .tofino-inner-container {
    height: auto !important;
  }

  .form-items-container {
    padding: 16px 24px;
    height: calc(100vh - 195px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .form-buttons-container {
    padding: 16px 24px;
    text-align: right;
    white-space: nowrap;
    border-top: 1px solid #e8e8e8;
  }

  .tofino-child-list-view {
    .tofino-table-container {
      flex: none;

      @media screen {
        overflow: auto;
      }
    }
  }
`

function Component(props) {
  const [item, setItem] = React.useState(null)

  async function fetchDraftOrder() {
    try {
      const response = await props.getDraftOrder()

      setItem(response.value.data)
    } catch (error) {
      showError({ error })
    }
  }

  React.useEffect(() => {
    fetchDraftOrder()
  }, [])

  const pageTitle = getPageTitle()

  return (
    <Container title={pageTitle}>
      {item ? (
        <FormView
          key={item.id}
          linkTargetRecord={{ id: item.id, orderId: item.id }}
          onSubmitItem={fetchDraftOrder}
        />
      ) : (
        <div style={{ padding: '16px 24px' }}>
          <Spin />
        </div>
      )}
    </Container>
  )
}

export default Component
