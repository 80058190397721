import * as React from 'react'
import { cloneDeep, orderBy, uniqBy, camelCase } from 'lodash'

export const DashboardContext = React.createContext(null)

export const sortCharts = (unsorted, injected) => {
  const row = cloneDeep(orderBy(unsorted, ['position']))

  if (injected) {
    row.splice(injected.position - 1, 0, injected)
  }

  row.forEach((col, colIndex) => {
    col.position = colIndex + 1
  })

  if (injected && getTotalWidth(row) > 24) {
    throw new Error('dashboardSectionOverflowError')
  }

  return row
}

export const getTotalWidth = (charts = []) =>
  charts.map((chart) => getChartWidth(chart.widthType)).reduce((acc, each) => acc + each, 0)

export const createChart = (params) => ({
  name: '',
  sectionId: 0,
  position: 1,
  widthType: 'OneThird',
  linkToDashboardRefresh: true,
  dataRefreshMinutes: 60,
  linkToDashboardFilter: true,
  filterSettings: [],
  comparisonDateRangeType: 'LastYear',
  comparisonLastNValue: 1,
  comparisonRankingType: 'LowerPrimaryIsBetter',
  ...params,
})

export const getChartWidth = (widthType) => {
  switch (widthType) {
    case 'OneSixth':
      return 24 / 6
    case 'OneQuarter':
      return 24 / 4
    case 'OneThird':
      return 24 / 3
    case 'OneHalf':
      return 24 / 2
    case 'TwoThirds':
      return (24 / 3) * 2
    case 'ThreeQuarters':
      return (24 / 4) * 3
    default:
      return 24
  }
}

export const getSectionProps = (section) => ({
  title: section.showHeader ? section.name : undefined,
  bordered: section.showSectionBorder,
  headStyle: section.showHeader
    ? {
        color: section.headerForegroundColor,
        backgroundColor: section.headerBackgroundColor,
        fontFamily: section.headerFontName,
        fontSize: Math.max(section.headerFontSize, 8),
        fontWeight: section.boldHeader ? 600 : 'normal',
        fontStyle: section.italicHeader ? 'italic' : 'normal',
        textDecoration: section.underlineHeader ? 'underline' : 'none',
      }
    : undefined,
  bodyStyle: { minHeight: `${section.height + 12}px` },
})

export const normalizeFilterSettings = (filterSettings = []) =>
  uniqBy(filterSettings, JSON.stringify).map(({ type, ...rest }) => ({
    type: camelCase(type),
    ...rest,
  }))

export const createGetFieldLabel = (fields) => (type) => fields.find((one) => one.type === type)?.name ?? type
