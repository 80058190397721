import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import Filter from 'containers/Users/Filter'

export default createListViewComponent({
  entityName: entityNames.users,
  getIdField: (self) => 'userName',
  filterTemplateType: 'User',
  createButtonTextId: 'createUser',
  primaryLinkTarget: 'userRecord',
  initialFilterDto: (self) => ({ out: 'All', vending: 'All', active: 'Active' }),
  allowDelete: (self) => false,
  allowDeactivate: (self) => true,
  getRowSelectionCheckboxProps: (self) => (record) => ({
    disabled: record.userName === self.props.user?.userName,
  }),
  getRowClassName: (self) => (record, index) =>
    record.userName === self.props.user?.userName ? 'table-row-warning' : '',
  getDeactivateButtonTooltip: (self) => t('userSelfDeactivateTooltip'),
})(Filter)
