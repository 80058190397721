import * as React from 'react'
import produce from 'immer'
import { Pagination, Spin, Table, Menu } from 'antd'
import { debounce, isEmpty, isNil, get, pick } from 'lodash'
import { showError, showClientNotifications } from 'helpers/errors'
import {
  getColumns,
  mapGetItemsFields,
  showTotal,
  createHandleCustomizeOk,
  pageSizeOptions,
  createHandleColumnResize,
  createListViewHandleTableChange,
  getTableWidth,
} from 'helpers/listViews'
import { createHandleFilterChange } from 'helpers/filters'
import { getKeyValueItem, setKeyValueItem } from 'helpers/keyValueStorage'
import { createResetState, DEBOUNCE } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { Emitter } from 'helpers/events'
import { LIST_MODAL_CLEAR_SELECTION } from 'options/events'
import Customize from 'elements/Customize'
import ResizableCell from 'elements/ResizableCell'
import Toolbar from 'elements/Toolbar'
import FilterContainer from 'elements/FilterContainer'
import TableRowActions from 'elements/TableRowActions'
import { Row, Col } from 'elements/Grid'

export default ({
    getStorageKey = (self) => self.props.storageKey ?? '__LIST_MODAL__',
    getSettingsType = (self) => '',
    initialFilterDto = (self) => ({ ...self.props.initialFilterDto }),
    initialState = (self) => ({ ...self.props.initialState }),
    transformGetItemsParams = (self) => ({}),
    tableCellComponents = {},
    totalsRowComponents = {},
    extraTableButtons,
    sharedComponents,
    tableHeadGroups = (self) => ({
      // dtoFieldName: () => []
    }),
    allowDelete = (self) => false,
    allowActionsMenu = (self) => true,
    getDeleteButtonTooltip = (self) => null,
    getDeleteItemsOptions = (self) => ({}),
    getRowClassName = (self) => (record, index) => '',
    getRowSelectionCheckboxProps = (self) => (record) => ({
      // disabled
    }),
  }) =>
  (Filter) =>
    class ListModal extends React.Component {
      state = {}

      constructor(props) {
        super(props)

        this.searchItems = debounce(this.fetchItems, DEBOUNCE)
        this.handleCustomizeOk = createHandleCustomizeOk(this)
        this.handleFilterChange = createHandleFilterChange(this)
        this.handleColumnResize = createHandleColumnResize(this)
        this.handleTableChange = createListViewHandleTableChange(this)
        this.resetState = createResetState(this, {
          search: '',
          searchFields: [],
          searchType: 'AllWords',
          pageIndex: 1,
          pageSize: 20,
          selectedRowKeys: [],
          selectedColumnKeys: [],
          sortedColumnKeys: [],
          columnWidths: {},
          customizeColumnsVisible: false,
          filterDto: {
            ...initialFilterDto(this),
            ...this.props.initialFilterDto,
          },
          ...initialState(this),
        })
        this.saveState = debounce(this.saveState, 2500)
      }

      async componentDidMount() {
        const savedState = await getKeyValueItem(getStorageKey(this), {})
        await this.resetState(savedState)
        await this.fetchSettings()

        Emitter.on(LIST_MODAL_CLEAR_SELECTION, this.clearSelection)
      }

      componentWillUnmount() {
        this.saveState.flush()
        Emitter.off(LIST_MODAL_CLEAR_SELECTION, this.clearSelection)
      }

      clearSelection = () =>
        this.setState({ selectedRowKeys: [] }, () => {
          this.props.onSelect?.([], this.state.filterDto)
        })

      fetchSettings = async () => {
        try {
          const { settingsType = getSettingsType(this) } = this.props
          const response = await this.props.getSettings({ type: settingsType })

          this.setState({ fieldSettings: get(response, 'value.data.fieldSettings', []) }, this.fetchItems)
        } catch (error) {
          showError({ error })
        }
      }

      fetchItems = async () => {
        try {
          this.setState({ loading: true })

          const { fieldSettings = [] } = this.state
          const response = await this.props.getItems({
            ...this.state.filterDto,
            ...pick(this.state, ['pageIndex', 'pageSize', 'search', 'searchType']),
            ...mapGetItemsFields({
              fieldSettings,
              ...pick(this.state, ['sortBy', 'sortOrder', 'selectedColumnKeys', 'searchFields']),
            }),
            ...transformGetItemsParams(this),
          })
          this.setState(
            produce((draft) => {
              draft.items = response?.value?.data?.items ?? []
              draft.pagination = draft.pagination ?? {}
              draft.pagination.pageSize = draft.pageSize
              draft.pagination.current = draft.pageIndex
              draft.pagination.total = response?.value?.data?.recordCount ?? draft.items.length ?? 0
            })
          )
        } catch (error) {
          showError({ error })
        } finally {
          this.setState({ loading: false, selectedRowKeys: [] }, () => {
            this.props.onFilter?.(this.state.filterDto)
            this.props.onSelect?.([], this.state.filterDto)
            this.saveState()
          })
        }
      }

      saveState = () => {
        if (window.location.search) {
          return
        }

        setKeyValueItem(getStorageKey(this), {
          ...pick(this.state, [
            'columnWidths',
            'pageSize',
            'selectedColumnKeys',
            'sortBy',
            'sortOrder',
            'sortedColumnKeys',
          ]),
          ...(this.state.filterDto?.showImages ? { filterDto: { showImages: true } } : {}),
        })
      }

      handleClearFilterClick = () => {
        const { dateRange, dateRangeField } = this.state.filterDto

        this.setState(
          {
            filterDto: { dateRange, dateRangeField },
            pageIndex: 1,
            search: '',
            searchType: 'AllWords',
            searchFields: [],
          },
          this.fetchItems
        )
      }

      handlePaginationChange = (pageIndex, pageSize) =>
        this.setState({ pageIndex, pageSize }, this.fetchItems)

      handleConfirmDelete = async () => {
        try {
          this.setState({ deleteButtonLoading: true })

          const { selectedRowKeys = [] } = this.state
          const response = await this.props.deleteItems(selectedRowKeys, getDeleteItemsOptions(this))

          this.props.onDelete?.(selectedRowKeys)

          showClientNotifications({ response })
        } catch (error) {
          showError({ error })
        } finally {
          this.setState({ deleteButtonLoading: false }, this.fetchItems)
        }
      }

      render() {
        const { mode = 'default' } = this.props
        const { items = [], pagination = {}, fieldSettings = [] } = this.state

        if (isEmpty(fieldSettings)) {
          return <Spin />
        }

        const columns = getColumns({
          self: this,
          fieldSettings,
          tableCellComponents,
          totalsRowComponents,
          tableHeadGroups,
          ...pick(this.state, [
            'sortOrder',
            'sortBy',
            'selectedColumnKeys',
            'sortedColumnKeys',
            'columnWidths',
            'filterDto',
          ]),
          onResize: this.handleColumnResize,
        })
        const width = isEmpty(columns) ? '100%' : getTableWidth(columns, true)

        // console.log({ items, fieldSettings })

        return (
          <>
            <div className="tofino-list-modal">
              <div className="tofino-inner-container">
                <div className="tofino-toolbar-container">
                  <Toolbar
                    search={this.state.search}
                    searchType={this.state.searchType}
                    onSearchChange={(e) =>
                      this.setState({ search: e.target.value, pageIndex: 1 }, this.searchItems)
                    }
                    onSearchTypeChange={(value) => this.setState({ searchType: value }, this.fetchItems)}
                    searchFields={this.state.searchFields}
                    fieldSettings={fieldSettings}
                    onSearchFieldsChange={(values) =>
                      this.setState({ searchFields: values }, this.fetchItems)
                    }
                    actionsMenuItems={[
                      fieldSettings.length > 1 ? (
                        <Menu.Item key="customizeColumns">{t('customizeColumns')}</Menu.Item>
                      ) : null,
                    ].filter(Boolean)}
                    onActionsMenuClick={({ key }) => {
                      if (key === 'customizeColumns') {
                        this.setState({ customizeColumnsVisible: true })
                      }
                    }}
                    allowActionsMenu={allowActionsMenu(this)}
                  />
                </div>
                {!isNil(Filter) && (
                  <FilterContainer
                    onClear={this.handleClearFilterClick}
                    disabled={this.props.filterProps?.disabled}
                  >
                    <Filter
                      filterDto={this.state.filterDto}
                      onChange={this.handleFilterChange}
                      items={items}
                      {...this.props.filterProps}
                    />
                  </FilterContainer>
                )}
                <Row type="flex" justify="space-between" className="mb-12">
                  <Col className="text-left">
                    {mode === 'multiple' && (
                      <TableRowActions
                        selectedRowKeys={this.state.selectedRowKeys}
                        // delete
                        allowDelete={allowDelete(this)}
                        onDelete={this.handleConfirmDelete}
                        deleteTooltip={getDeleteButtonTooltip(this)}
                        deleteLoading={this.state.deleteButtonLoading}
                      />
                    )}
                  </Col>
                  <Col className="text-right">{extraTableButtons?.(this)}</Col>
                </Row>
                <div className="tofino-table-container">
                  <Table
                    childrenColumnName={[]}
                    rowKey={(record) => record.id}
                    loading={this.state.loading}
                    columns={columns}
                    dataSource={items}
                    pagination={false}
                    onChange={this.handleTableChange}
                    size="middle"
                    locale={{ emptyText: t('noData') }}
                    rowSelection={{
                      type: mode === 'multiple' ? 'checkbox' : 'radio',
                      selectedRowKeys: this.state.selectedRowKeys,
                      onChange: (rowKeys, rows) =>
                        this.setState({ selectedRowKeys: rowKeys }, () => {
                          this.props.onSelect?.(rows, this.state.filterDto)
                        }),
                      getCheckboxProps: getRowSelectionCheckboxProps(this),
                    }}
                    style={{ width }}
                    components={{ header: { cell: ResizableCell } }}
                    rowClassName={getRowClassName(this)}
                  />
                </div>
                {!isEmpty(items) && pagination && (
                  <div style={{ display: 'flex', padding: '12px 0' }}>
                    <Pagination
                      current={pagination.current ?? 0}
                      total={pagination.total ?? 0}
                      pageSize={pagination.pageSize ?? 20}
                      pageSizeOptions={pageSizeOptions}
                      onChange={this.handlePaginationChange}
                      onShowSizeChange={this.handlePaginationChange}
                      showTotal={showTotal}
                      size="small"
                      showTitle={false}
                      showSizeChanger
                      showLessItems
                    />
                  </div>
                )}
              </div>
            </div>
            <Customize
              displayableColumns={fieldSettings.filter((each) => each.isDisplayable)}
              visible={this.state.customizeColumnsVisible}
              onOk={this.handleCustomizeOk}
              onCancel={() => this.setState({ customizeColumnsVisible: false })}
              selectedColumnKeys={this.state.selectedColumnKeys}
              sortedColumnKeys={this.state.sortedColumnKeys}
              columnWidths={this.state.columnWidths}
            />
            {sharedComponents?.(this)}
          </>
        )
      }
    }
