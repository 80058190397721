import { Button } from 'antd'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { createPrintHandler, resetPrintHandler } from 'helpers/print'
import Modal from 'elements/Modal'
import Icon from 'elements/Icon'
import Filter from 'containers/Rfqs/Filter'
import Print from 'containers/Rfqs/Print'

export default createListViewComponent({
  entityName: entityNames.rfqs,
  filterTemplateType: 'Rfq',
  createButtonTextId: 'createRfq',
  primaryLinkTarget: 'rfqRecord',
  allowCreate: (self) => !self.props.userIsInRoleSupplier && !self.props.userIsInRoleDistributor,
  allowDelete: (self) => !self.props.userIsInRoleSupplier && !self.props.userIsInRoleDistributor,
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
  extraRowActions: (self) => (
    <>
      <Button
        disabled={self.state.selectedRowKeys.length !== 1}
        onClick={async () => {
          try {
            self.setState({ printLoading: true })

            const printItems = await Promise.all(
              self.state.selectedRowKeys.map((each) => self.props.getItem(each).then((r) => r.value.data))
            )

            self.setState({
              printItems,
              printVisible: true,
            })
          } catch (error) {
            showError({ error })
          } finally {
            self.setState({ printLoading: false })
          }
        }}
        loading={self.state.printLoading}
      >
        <Icon type="print" />
        {t('print')}
      </Button>
      <Modal
        title={t('printPreview')}
        okText={t('print')}
        onOk={createPrintHandler(self.printableRef)}
        onCancel={() => self.setState({ printVisible: false }, resetPrintHandler)}
        width={992}
        visible={self.state.printVisible}
      >
        <div ref={self.printableRef} className="tofino-print-preview">
          {(self.state.printItems ?? [])
            .filter((each) => self.state.selectedRowKeys.includes(each.id))
            .map((item) => (
              <div key={item.id} className="mb-24">
                <Print
                  parentRecord={{
                    ...item,
                  }}
                />
              </div>
            ))}
        </div>
      </Modal>
    </>
  ),
})(Filter)
