import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/FormBatches/Filter'

export default createListViewComponent({
  entityName: entityNames.formBatches,
  createButtonTextId: 'createFormBatch',
  primaryLinkTarget: 'formBatchRecord',
  getRowSelectionCheckboxProps: (self) => (record) => ({ disabled: record.completedItems > 0 }),
  initialFilterDto: (self) => ({ completionStatus: 'All' }),
})(Filter)
