import * as React from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'
import pluralize from 'pluralize'
import { useDebouncedCallback } from 'use-debounce'
import { Form, Input } from 'antd'
import { get, set, uniq, toLower, cloneDeep, camelCase, isEmpty } from 'lodash'
import { showError, showClientNotifications, showValidationError } from 'helpers/errors'
import { createDropdownRender, filterByValue } from 'helpers/formViews'
import { stopEvent } from 'helpers/events'
import linkTargets from 'options/linkTargets'
import { calculateOrderTotals } from 'helpers/procurement'
import { t } from 'helpers/i18n'
import { DEBOUNCE } from 'helpers/utils'
import Modal from 'elements/Modal'
import AutoComplete, { Suffix } from 'elements/AutoComplete'
import Drawer from 'elements/Drawer'

const OrderForm = linkTargets.orderRecord.formComponent

const TAG_TYPES = ['2', '1', '3', 'A', 'B', 'C', 'D', 'E']

const Tags = styled(Modal)`
  .ant-modal-body {
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
`

function FormView(props) {
  const { user, form, customer, selectedItems } = props

  const refs = {
    ref1: React.useRef(null),
    ref2: React.useRef(null),
    ref3: React.useRef(null),
    refA: React.useRef(null),
    refB: React.useRef(null),
    refC: React.useRef(null),
    refD: React.useRef(null),
    refE: React.useRef(null),
  }

  const [state, updateState] = useImmer({
    jobDisplayName: '',
    assetDisplayName: '',
    operatorDisplayName: '',
  })

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  const tagsEnabled = TAG_TYPES.some((type) =>
    get(user, `coreUserSettings.tagSettings.tag${type}EnabledPurch`)
  )

  function calculateQuantityOrdered(each) {
    const quantityOrdered = each.max - each.onHand - each.onOrder

    return ['Accumulate', 'RoundDown', 'RoundUp'].includes(each.orderCalculationType) ||
      (each.orderCalculationType === 'Default' &&
        ['Accumulate', 'RoundDown', 'RoundUp'].includes(customer.generalSettings.orderCalculationType))
      ? Math.ceil(quantityOrdered / each.packageSize) * each.packageSize
      : quantityOrdered
  }

  async function createOrderForSelectedItems() {
    try {
      setState('tagModalOkButtonLoading', true)

      const responses = await Promise.all([
        props.newOrder(),
        props.populateOrderItems(
          selectedItems.map((each) => ({
            ...each,
            userName: user.userName,
            quantityOrdered: calculateQuantityOrdered(each),
            assetBarcode: get(state, 'asset.barcode', state.assetBarcode),
            assetId: state.asset?.id,
            assetName: get(state, 'asset.name', `*${state.assetDisplayName}`),
            jobBarcode: get(state, 'job.barcode', state.jobDisplayName),
            jobId: state.job?.id,
            jobName: get(state, 'job.name', `*${state.jobDisplayName}`),
            jobNumber: state.job?.number,
            operatorBarcode: get(state, 'operator.barcode', state.operatorDisplayName),
            operatorId: state.operator?.id,
            operatorName: get(state, 'operator.name', `*${state.operatorDisplayName}`),
            operatorNumber: state.operator?.number,
            tagA: state.tagA,
            tagB: state.tagB,
            tagC: state.tagC,
            tagD: state.tagD,
            tagE: state.tagE,
            quantityIssued: undefined,
          }))
        ),
      ])

      const item = cloneDeep(responses[0].value.data)

      const orderItems = get(responses[1], 'value.data.items', []).map((each) => ({
        ...each,
        id: undefined,
        orderItemId: undefined,
        timeScanned: new Date().toJSON(),
        serverTimeScanned: new Date().toJSON(),
      }))

      const supplierIds = orderItems
        .filter((each) => Boolean(each.supplierId) && each.quantityOrdered > 0)
        .map((each) => each.supplierId)

      const suppliers = await Promise.all(
        [...new Set(supplierIds)].map((each) => props.getSupplier(each).then((r) => r.value.data))
      )

      const purchaseOrderNumbers = await Promise.all(
        supplierIds.map((supplierId) =>
          props.generatePurchaseOrderNumber({ orderId: 0, supplierId }).then((each) => ({
            supplierId,
            ...each.value.data,
          }))
        )
      )

      const orderSuppliers = suppliers.map((each) => ({
        adjustments: 0,
        confirmedDescription: 'No',
        freight: 0,
        purchaseOrderNumber: '',
        shippingInstructions: each.shippingInstructions,
        supplierId: each.id,
        supplierName: each.name,
      }))

      Object.assign(item, calculateOrderTotals({ customer, item, orderItems }))

      orderSuppliers.forEach((each) => {
        try {
          each.purchaseOrderNumber = purchaseOrderNumbers.find(
            (one) => one.supplierId === each.supplierId
          ).purchaseOrderNumber
        } catch (error) {
          console.warn(error)
        }
      })

      const response = await props.saveOrder({
        order: item,
        orderItems: { creating: orderItems },
        orderSuppliers: { creating: orderSuppliers },
      })

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }

      const order = cloneDeep(response.value.data.items[0])

      updateState((draft) => {
        draft.tagsModalVisible = false
        draft.orderFormVisible = true
        draft.orderFormSaving = false
        draft.linkTargetRecord = { ...order, orderId: order.id }
      })
    } catch (error) {
      showError({ error })
    } finally {
      setState('tagModalOkButtonLoading', false)
    }
  }

  function beforeValidateFields() {
    updateState((draft) => {
      const fieldsValue = {}

      if (
        state.operatorDisplayName &&
        user.coreUserSettings.tagSettings.tag2EnabledPurch &&
        uniq((state?.operators?.items ?? []).map((each) => each.displayName)).length === 1
      ) {
        const operator = draft.operators.items[0]

        ;['id', 'barcode', 'displayName', 'name', 'number'].forEach((one) => {
          set(draft, camelCase(`operator-${one}`), operator[one])
        })

        set(fieldsValue, 'operatorDisplayName', draft.operatorDisplayName)
      }

      if (
        state.jobDisplayName &&
        user.coreUserSettings.tagSettings.tag1EnabledPurch &&
        uniq((state?.jobs?.items ?? []).map((each) => each.displayName)).length === 1
      ) {
        const job = draft.jobs.items[0]

        ;['id', 'barcode', 'displayName', 'name', 'number'].forEach((one) => {
          set(draft, camelCase(`job-${one}`), job[one])
        })

        set(fieldsValue, 'jobDisplayName', draft.jobDisplayName)
      }

      if (
        state.assetDisplayName &&
        user.coreUserSettings.tagSettings.tag3EnabledPurch &&
        uniq((state?.assets?.items ?? []).map((each) => each.displayName)).length === 1
      ) {
        const asset = draft.assets.items[0]

        ;['id', 'barcode', 'displayName', 'name', 'number'].forEach((one) => {
          set(draft, camelCase(`asset-${one}`), asset[one])
        })

        set(fieldsValue, 'assetDisplayName', draft.assetDisplayName)
      }

      ;['A', 'B', 'C', 'D', 'E'].forEach((type) => {
        if (user.coreUserSettings.tagSettings[`tag${type}EnabledPurch`]) {
          const filteredTagListItems = (state?.tagListItems ?? [])
            .filter((each) => each.tagType === type)
            .map((each) => each.description)
            .filter((each) => toLower(each).indexOf(toLower(get(state, `tag${type}`))) > -1)

          if (filteredTagListItems.length === 1) {
            set(draft, `tag${type}`, filteredTagListItems[0])

            set(fieldsValue, `tag${type}`, filteredTagListItems[0])
          }
        }
      })

      form.setFieldsValue(fieldsValue)
    })

    return new Promise((resolve) => {
      window.setTimeout(resolve, 1000)
    })
  }

  async function handleSubmit(e) {
    stopEvent(e)

    await beforeValidateFields()

    form.validateFieldsAndScroll((errors, values) => {
      if (!isEmpty(errors)) {
        showValidationError()
        return
      }

      updateState((draft) => {
        draft.assetDisplayName = values.assetDisplayName
        draft.assetId = (state?.assets?.items ?? []).find(
          (one) => one.displayName === values.assetDisplayName
        )?.id
        draft.jobDisplayName = values.jobDisplayName
        draft.jobId = (state?.jobs?.items ?? []).find((one) => one.displayName === values.jobDisplayName)?.id
        draft.operatorDisplayName = values.operatorDisplayName
        draft.operatorId = (state?.operators?.items ?? []).find(
          (one) => one.displayName === values.operatorDisplayName
        )?.id
        draft.tagA = values.tagA
        draft.tagB = values.tagB
        draft.tagC = values.tagC
        draft.tagD = values.tagD
        draft.tagE = values.tagE
      })

      createOrderForSelectedItems()
    })
  }

  function handleTagsModalCancel() {
    setState('tagsModalVisible', false)

    window.setTimeout(props.onCancel, 500)
  }

  async function fetchAssets(search) {
    try {
      const response = await props.getAssets({
        search,
        assetIds: state.assetId ? [state.assetId] : undefined,
      })

      setState('assets', response.value.data)
    } catch (error) {
      showError({ error })
    }
  }

  async function fetchJobs(search) {
    try {
      const response = await props.getJobs({
        search,
        jobIds: state.jobId ? [state.jobId] : undefined,
      })

      setState('jobs', response.value.data)
    } catch (error) {
      showError({ error })
    }
  }

  async function fetchOperators(search) {
    try {
      const response = await props.getOperators({
        search,
        operatorIds: state.operatorId ? [state.operatorId] : undefined,
      })

      setState('operators', response.value.data)
    } catch (error) {
      showError({ error })
    }
  }

  function handlePressEnter(current) {
    return async function (e) {
      stopEvent(e)

      await beforeValidateFields()

      const currentIndex = TAG_TYPES.indexOf(current)

      if (currentIndex > -1) {
        TAG_TYPES.some((next, nextIndex) => {
          if (nextIndex > currentIndex) {
            try {
              get(refs, `ref${next}`).current.focus()

              return true
            } catch (error) {
              console.warn(error)
            }
          }
          return false
        })
      }
    }
  }

  function setFormValue(key, value) {
    updateState((draft) => {
      set(draft, key, value)

      form.setFieldsValue({ [key]: value })
    })
  }

  function setTagValue(key, value) {
    updateState((draft) => {
      const item = get(draft, `${pluralize(key)}.items`, []).find((one) => one.displayName === value)

      set(draft, `${key}DisplayName`, value)
      ;['id', 'barcode', 'name', 'number'].forEach((one) => {
        set(draft, `${camelCase(`${key}-${one}`)}`, get(item, one))
      })

      set(draft, key, item)

      form.setFieldsValue({ [`${key}DisplayName`]: value })
    })
  }

  function handleOrderFormClose() {
    updateState((draft) => {
      draft.orderFormVisible = false
      draft.tagsModalVisible = false
    })

    window.setTimeout(() => props.onSaveAndClose?.(), 500)
  }

  React.useEffect(() => {
    if (tagsEnabled) {
      console.log('Purchase tag(s) were enabled; showing tags modal.')
      setState('tagsModalVisible', true)
    } else {
      console.log('Purchase tag(s) were not enabled; not showing tags modal.')
      createOrderForSelectedItems()
    }
  }, [])

  React.useEffect(() => {
    if (state.tagsModalVisible) {
      if (user.coreUserSettings.tagSettings.tag1EnabledPurch) {
        fetchJobs()
      }

      if (user.coreUserSettings.tagSettings.tag2EnabledPurch) {
        fetchOperators()
      }

      if (user.coreUserSettings.tagSettings.tag3EnabledPurch) {
        fetchAssets()
      }
    }
  }, [state.tagsModalVisible])

  React.useEffect(() => {
    async function fetchTagListItems() {
      try {
        const response = await props.getTagListItems({ tagType: 'All' })

        setState('tagListItems', get(response, 'value.data.items', []))
      } catch (error) {
        showError({ error })
      }
    }

    if (state.tagsModalVisible && tagsEnabled) {
      fetchTagListItems()
    }
  }, [state.tagsModalVisible, tagsEnabled])

  const searchJobs = useDebouncedCallback((search) => fetchJobs(search), DEBOUNCE)
  const searchOperators = useDebouncedCallback((search) => fetchOperators(search), DEBOUNCE)
  const searchAssets = useDebouncedCallback((search) => fetchAssets(search), DEBOUNCE)

  return (
    <>
      <Tags
        title={t('createOrder')}
        visible={state.tagsModalVisible}
        okText={t('create')}
        onOk={handleSubmit}
        okButtonProps={{
          loading: state.tagModalOkButtonLoading,
          disabled: isEmpty(selectedItems),
        }}
        onCancel={handleTagsModalCancel}
      >
        <Form layout="vertical" onSubmit={handleSubmit} colon={false}>
          {user.coreUserSettings.tagSettings.tag2EnabledPurch && (
            <Form.Item label={customer.tagSettings.tag2}>
              {form.getFieldDecorator('operatorDisplayName', {
                rules: [
                  ...(customer.tagSettings.tag2Required
                    ? [
                        {
                          required: true,
                          message: t('requiredField'),
                        },
                      ]
                    : []),
                  ...(customer.tagSettings.tag2Restricted
                    ? [
                        {
                          validator: (rule, value, callback) => {
                            callback(
                              isEmpty(value) ||
                                (state.operators?.items ?? []).map((each) => each.displayName).includes(value)
                                ? []
                                : [new Error(t('invalidValueEntered'))]
                            )
                          },
                        },
                      ]
                    : []),
                ],
                initialValue: state.operatorDisplayName,
                validateTrigger: false,
              })(
                <AutoComplete
                  ref={refs.ref2}
                  defaultActiveFirstOption={false}
                  dataSource={(state?.operators?.items ?? []).map((each) => each.displayName)}
                  dropdownRender={createDropdownRender(state.operators)}
                  onChange={(value) => setTagValue('operator', value)}
                  onSearch={searchOperators}
                >
                  <Input
                    onPressEnter={handlePressEnter('2')}
                    autoComplete="off"
                    suffix={<Suffix />}
                    allowClear
                  />
                </AutoComplete>
              )}
            </Form.Item>
          )}
          {user.coreUserSettings.tagSettings.tag1EnabledPurch && (
            <Form.Item label={customer.tagSettings.tag1}>
              {form.getFieldDecorator('jobDisplayName', {
                rules: [
                  ...(customer.tagSettings.tag1Required
                    ? [
                        {
                          required: true,
                          message: t('requiredField'),
                        },
                      ]
                    : []),
                  ...(customer.tagSettings.tag1Restricted
                    ? [
                        {
                          validator: (rule, value, callback) => {
                            callback(
                              isEmpty(value) ||
                                (state.jobs?.items ?? []).map((each) => each.displayName).includes(value)
                                ? []
                                : [new Error(t('invalidValueEntered'))]
                            )
                          },
                        },
                      ]
                    : []),
                ],
                initialValue: state.jobDisplayName,
                validateTrigger: false,
              })(
                <AutoComplete
                  ref={refs.ref1}
                  defaultActiveFirstOption={false}
                  dataSource={(state.jobs?.items ?? []).map((each) => each.displayName)}
                  dropdownRender={createDropdownRender(state.jobs)}
                  onChange={(value) => setTagValue('job', value)}
                  onSearch={searchJobs}
                >
                  <Input
                    onPressEnter={handlePressEnter('1')}
                    autoComplete="off"
                    suffix={<Suffix />}
                    allowClear
                  />
                </AutoComplete>
              )}
            </Form.Item>
          )}
          {user.coreUserSettings.tagSettings.tag3EnabledPurch && (
            <Form.Item label={customer.tagSettings.tag3}>
              {form.getFieldDecorator('assetDisplayName', {
                rules: [
                  ...(customer.tagSettings.tag3Required
                    ? [
                        {
                          required: true,
                          message: t('requiredField'),
                        },
                      ]
                    : []),
                  ...(customer.tagSettings.tag3Restricted
                    ? [
                        {
                          validator: (rule, value, callback) => {
                            callback(
                              isEmpty(value) ||
                                (state.assets?.items ?? []).map((each) => each.displayName).includes(value)
                                ? []
                                : [new Error(t('invalidValueEntered'))]
                            )
                          },
                        },
                      ]
                    : []),
                ],
                initialValue: state.assetDisplayName,
                validateTrigger: false,
              })(
                <AutoComplete
                  ref={refs.ref3}
                  defaultActiveFirstOption={false}
                  dataSource={(state.assets?.items ?? []).map((each) => each.displayName)}
                  dropdownRender={createDropdownRender(state.assets)}
                  onChange={(value) => setTagValue('asset', value)}
                  onSearch={searchAssets}
                >
                  <Input
                    onPressEnter={handlePressEnter('3')}
                    autoComplete="off"
                    suffix={<Suffix />}
                    allowClear
                  />
                </AutoComplete>
              )}
            </Form.Item>
          )}
          {['A', 'B', 'C', 'D', 'E'].map(
            (tagType) =>
              user.coreUserSettings.tagSettings[`tag${tagType}EnabledPurch`] && (
                <Form.Item key={tagType} label={customer.tagSettings[`tag${tagType}`]}>
                  {form.getFieldDecorator(`tag${tagType}`, {
                    rules: [
                      ...(customer.tagSettings[`tag${tagType}Required`]
                        ? [
                            {
                              required: true,
                              message: t('requiredField'),
                            },
                          ]
                        : []),
                      ...(customer.tagSettings[`tag${tagType}Restricted`]
                        ? [
                            {
                              type: 'enum',
                              enum: (state?.tagListItems ?? [])
                                .filter((each) => each.tagType === tagType)
                                .map((each) => each.description),
                              message: t('invalidValueEntered'),
                            },
                          ]
                        : []),
                    ],
                    initialValue: get(state, `tag${tagType}`),
                    validateTrigger: false,
                  })(
                    customer.tagSettings[`tag${tagType}ShowList`] ? (
                      <AutoComplete
                        ref={refs[`ref${tagType}`]}
                        defaultActiveFirstOption={false}
                        dataSource={(state?.tagListItems ?? [])
                          .filter((each) => each.tagType === tagType)
                          .map((each) => each.description)}
                        filterOption={filterByValue}
                        onChange={(value) => setFormValue(`tag${tagType}`, value)}
                      >
                        <Input
                          onPressEnter={handlePressEnter(tagType)}
                          autoComplete="off"
                          suffix={<Suffix />}
                          allowClear
                        />
                      </AutoComplete>
                    ) : (
                      <Input
                        ref={refs[`ref${tagType}`]}
                        onPressEnter={handlePressEnter(tagType)}
                        autoComplete="off"
                        allowClear
                      />
                    )
                  )}
                </Form.Item>
              )
          )}
        </Form>
      </Tags>
      <Drawer
        title={t('createOrder')}
        size={linkTargets.orderRecord.formSize}
        visible={state.orderFormVisible}
        onClose={handleOrderFormClose}
        saving={state.orderFormSaving}
      >
        <OrderForm
          linkTargetRecord={state.linkTargetRecord}
          onSave={(pending) => setState('orderFormSaving', pending)}
          onSaveAndClose={handleOrderFormClose}
          onCancel={handleOrderFormClose}
        />
      </Drawer>
    </>
  )
}

export default Form.create()(FormView)
