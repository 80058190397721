import * as React from 'react'
import { Form } from 'antd'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option } from 'elements/Select'
import Help from 'elements/Help'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  render() {
    const { filterDto = {}, allowCalculatedMetrics = true, allowScatterMetrics = true } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <Form.Item label={t('category')}>
              <Select
                onChange={(value) => this.setFilterValue('categoryType', value)}
                value={filterDto.categoryType}
                placeholder={t('all')}
                allowClear
              >
                {(this.props.categoryTypes ?? []).map((each) => (
                  <Option key={each.categoryType} value={each.categoryType}>
                    {each.categoryName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {allowCalculatedMetrics && (
            <Col third>
              <Form.Item
                label={
                  <span>
                    {t('metricType')} <Help title={t('metricTypeInfo')} />
                  </span>
                }
              >
                <Select
                  onChange={(value) => this.setFilterValue('metricType', value)}
                  value={filterDto.metricType}
                  placeholder={t('all')}
                  allowClear
                >
                  <Option key="Single">{t('single')}</Option>
                  <Option key="Multiple">{t('multiple')}</Option>
                </Select>
              </Form.Item>
            </Col>
          )}
          {allowScatterMetrics && (
            <Col third>
              <Form.Item
                label={
                  <span>
                    {t('chartType')} <Help title={t('scatterChartTypeInfo')} />
                  </span>
                }
              >
                <Select
                  onChange={(value) => this.setFilterValue('chartType', value)}
                  value={filterDto.chartType}
                  placeholder={t('all')}
                  allowClear
                >
                  <Option key="Scatter">{t('scatter')}</Option>
                  <Option key="Other">{t('other')}</Option>
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
