import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/DashboardTemplates/Filter'

export default createListViewComponent({
  entityName: entityNames.dashboardTemplates,
  createButtonTextId: 'createDashboardTemplate',
  primaryLinkTarget: 'dashboardTemplateRecord',
  initialFilterDto: (self) => ({ active: 'Active', accessType: 'All' }),
})(Filter)
