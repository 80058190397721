import styled from 'styled-components'
import { get, camelCase, toString as str, isEmpty, isFinite } from 'lodash'
import Barcode from 'react-barcode'
import { QRCodeSVG } from 'qrcode.react'
import { formatDateTime } from 'helpers/dateTime'
import { formatValue } from 'helpers/listViews'
import { t } from 'helpers/i18n'

const ONE_PIXEL_GIF = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

const Container = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  display: inline-block;
  border-color: black;
`

function Component(props) {
  const {
    field,
    item = {},
    unit,
    labelTemplateFields = [],
    barcodeFormat,
    lineHeight,
    highlightable,
    highlighted,
  } = props

  const toPixels = (value) => (unit === 'cm' ? (value / 2.54) * 96 : value * 96)

  const getText = () => {
    if (field.fieldType === 'CustomText') {
      return field.customText
    }

    const labelTemplateField = labelTemplateFields.find((one) => one.fieldType === field.fieldType)
    const dtoFieldName = get(labelTemplateField, 'dtoFieldName', field.fieldType)
      .split('.')
      .map(camelCase)
      .join('.')

    if (field.fieldType === 'InventoryLastReceivedDate') {
      const value = !item.id ? new Date().toJSON() : get(item, dtoFieldName, '')

      return formatDateTime(value, { showDate: true, showTime: true }) || t('none')
    }

    if (field.fieldType === 'Price') {
      const value = get(item, dtoFieldName)

      return isFinite(value) ? formatValue({ value, displayFormat: 'Currency2' }) : field.previewText
    }

    return !item.id ? field.previewText : get(item, dtoFieldName, '')
  }

  const getImage = () => {
    if (field.fieldType === 'Image') {
      return field?.image?.fileType && field?.image?.contents
        ? `data:${field.image.fileType};base64,${field.image.contents}`
        : ONE_PIXEL_GIF
    }

    if (['AssetImage', 'ProductImage'].includes(field.fieldType)) {
      return !isEmpty(item.objectImageContentBase64)
        ? `data:${item.objectImageContentType};base64,${item.objectImageContentBase64}`
        : ONE_PIXEL_GIF
    }

    return null
  }

  const getContent = () => {
    if (highlightable) {
      return null
    }

    if (field.fieldType.match(/BarcodeImage/)) {
      return (
        <Barcode
          format={barcodeFormat === 'Code128' ? 'CODE128' : 'CODE39'}
          value={getText()}
          width={1}
          height={toPixels(lineHeight - field.topMargin - field.bottomMargin) + 2}
          margin={0}
          displayValue={false}
        />
      )
    }

    if (field.fieldType === 'Image') {
      return (
        <img
          style={{ maxWidth: `${field.width}${unit}`, maxHeight: `${lineHeight}${unit}` }}
          src={getImage()}
          alt={field.fieldTypeName}
        />
      )
    }

    if (['AssetImage', 'ProductImage'].includes(field.fieldType)) {
      if (!item.id) {
        return (
          <div
            style={{
              width: `${field.width}${unit}`,
              height: `${lineHeight}${unit}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: field.alignment,
              backgroundColor: '#f5f5f5',
            }}
          >
            <div>{field.fieldTypeName}</div>
          </div>
        )
      }

      if (isEmpty(item.objectImageContentBase64)) {
        return null
      }

      return (
        <img
          style={{ maxWidth: `${field.width}${unit}`, maxHeight: `${lineHeight}${unit}` }}
          src={getImage()}
          alt={field.fieldTypeName}
        />
      )
    }

    if (field.fieldType.match(/QR/)) {
      return (
        <QRCodeSVG
          size={`${Math.min(field.width, lineHeight)}${unit}`}
          imageSettings={{
            x: 0,
            y: 0,
          }}
          value={getText()}
        />
      )
    }

    return `${str(field.preamble)}${getText()}${str(field.postamble)}`
  }

  return (
    <Container
      style={{
        color: field.showAsNegative ? 'white' : 'black',
        backgroundColor: highlighted
          ? 'rgba(0, 173, 255, 0.15)'
          : highlightable
            ? 'transparent'
            : field.showAsNegative
              ? 'black'
              : 'white',
        outline: highlighted ? '1px dashed rgba(0, 173, 255, 0.75)' : 'none',
        width: `${field.width}${unit}`,
        height: `${lineHeight}${unit}`,
        borderStyle: field.showBorder ? 'solid' : 'none',
        borderWidth: field.showBorder ? `${field.borderWidth}${unit}` : '0',
        textAlign: field.alignment,
        paddingTop: `${field.topMargin}${unit}`,
        paddingBottom: `${field.bottomMargin}${unit}`,
        paddingLeft: `${field.leftMargin}${unit}`,
        paddingRight: `${field.rightMargin}${unit}`,
        fontFamily: field.fontName ? field.fontName : undefined,
        fontSize: Math.max(field.fontSize, 8),
        fontWeight: field.bold ? 'bold' : 'normal',
        fontStyle: field.italic ? 'italic' : 'normal',
        textDecoration: field.underline ? 'underline' : 'none',
        whiteSpace: field.wrapText ? 'pre-wrap' : 'pre',
        zIndex: highlighted ? '2' : 'auto',
      }}
    >
      {getContent()}
    </Container>
  )
}

export default Component
