import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { t } from 'helpers/i18n'
import { createSearchSuppliers, createDropdownRender, isReadOnly } from 'helpers/formViews'
import statusOptions from 'options/statusOptions'
import { createGetFieldLabel } from 'helpers/dashboards'
import Select, { Option, getOptionProps } from 'elements/Select'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchSuppliers = createSearchSuppliers(this)
  }

  async componentDidMount() {
    try {
      const { supplierIds = [], supplierRecordStatus = 'All' } = this.props.filterDto

      await this.props.getSuppliers({ supplierIds, active: supplierRecordStatus })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { fields = [], filterDto = {}, suppliers } = this.props

    const getFieldLabel = createGetFieldLabel(fields)
    const readOnly = isReadOnly(this)

    return (
      <>
        {fields.find((one) => one.type === 'supplierIds') && (
          <Form.Item label={getFieldLabel('supplierIds')}>
            <Select
              onChange={(value) => this.setFilterValue('supplierIds', value)}
              mode="multiple"
              placeholder={t('all')}
              value={filterDto.supplierIds}
              dropdownRender={createDropdownRender(suppliers)}
              onSearch={this.searchSuppliers}
              readOnly={readOnly}
              showSearch
            >
              {(suppliers?.items ?? []).map((each) => (
                <Option key={each.id} value={each.id}>
                  <span {...getOptionProps(each)}>{each.name}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'supplierRecordStatus') && (
          <Form.Item label={getFieldLabel('supplierRecordStatus')}>
            <Select
              onChange={(value) => this.setFilterValue('supplierRecordStatus', value || 'All')}
              value={filterDto?.supplierRecordStatus ?? 'All'}
              readOnly={readOnly}
            >
              {Object.entries(statusOptions).map(([key, value]) => (
                <Option key={key} value={key}>
                  {t(value)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </>
    )
  }
}

export default Form.create()(Filter)
