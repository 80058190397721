import * as React from 'react'
import { Form } from 'antd'
import produce from 'immer'
import { get, debounce } from 'lodash'
import { showError } from 'helpers/errors'
import sourceOptions from 'options/forms/sourceOptions'
import formStatusOptions from 'options/forms/formStatusOptions'
import formStatusCompletionOptions from 'options/forms/formStatusCompletionOptions'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import { Row, Col } from 'elements/Grid'
import {
  createDropdownRender,
  createSearchCustomerGroups,
  createSearchFormBatches,
  createSearchFormTemplates,
} from 'helpers/formViews'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { DEBOUNCE } from 'helpers/utils'
import Help from 'elements/Help'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchCustomerGroups = createSearchCustomerGroups(this)
    this.searchFormBatches = createSearchFormBatches(this)
    this.searchFormTemplates = createSearchFormTemplates(this)
    this.searchCustomersByNumber = debounce(this.searchCustomersByNumber, DEBOUNCE)
  }

  async componentDidMount() {
    try {
      const { formTemplateIds = [] } = this.props.filterDto ?? {}
      const responses = await Promise.all([
        this.props.getFormTemplates({ formTemplateIds }),
        this.props.userIsDcribDistributor ? this.props.getCustomerGroups() : Promise.resolve(null),
      ])

      this.setState({ customerNumberSelectionList: responses[0].value.data })
    } catch (error) {
      showError({ error })
    }
  }

  searchCustomersByNumber = async (search) => {
    try {
      const { customerId, customerIds = [] } = this.props.filterDto
      const response = await this.props.getCustomerNumberSelectionList({
        search,
        customerIds: customerId ? [customerId] : customerIds,
      })

      this.setState({ customerNumberSelectionList: get(response, 'value.data', {}) })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto, formTemplates, userIsDcribDistributor } = this.props

    const dateRangeFields =
      filterDto.status === 'Incomplete'
        ? { AssignedDateOnly: 'assignedDateOnly' }
        : {
            AssignedDateOrCompletedDate: 'assignedDateOrCompletedDate',
            AssignedDateAndCompletedDate: 'assignedDateAndCompletedDate',
            AssignedDateOnly: 'assignedDateOnly',
            CompletedDateOnly: 'completedDateOnly',
          }

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker
              filterDto={filterDto}
              setFilterValue={this.setFilterValue}
              dateRangeFields={dateRangeFields}
              defaultDateRangeField={
                filterDto.status === 'Incomplete' ? 'AssignedDateOnly' : 'AssignedDateOrCompletedDate'
              }
            />
            {userIsDcribDistributor && (
              <Form.Item label={t('customerGroup')}>
                <Select
                  onChange={(value) => this.setFilterValue('customerGroupId', value)}
                  placeholder={t('all')}
                  value={filterDto.customerGroupId}
                  allowClear
                >
                  {(this.props.customerGroups ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col fourth>
            <Form.Item label={t('source')}>
              <Select
                onChange={(value) => this.setFilterValue('objectType', value || 'All')}
                value={filterDto.objectType}
              >
                {Object.entries(sourceOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('template')} <Help title={t('selectFormTemplateColumnInfo')} />
                </span>
              }
            >
              <Select
                onChange={(value) => this.setFilterValue('formTemplateId', value)}
                placeholder={t('all')}
                value={filterDto.formTemplateId}
                dropdownRender={createDropdownRender(formTemplates)}
                onSearch={this.searchFormTemplates}
              >
                {(formTemplates?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) =>
                  this.props.onChange(
                    produce(filterDto, (draft) => {
                      draft.status = value

                      if (value === 'Incomplete') {
                        draft.dateRangeField = 'AssignedDateOnly'
                      }
                    })
                  )
                }
                placeholder={t('all')}
                value={filterDto.status}
              >
                {Object.entries(formStatusCompletionOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('deficiency')}>
              <Select
                onChange={(value) => this.setFilterValue('deficiency', value)}
                placeholder={t('all')}
                value={filterDto.deficiency}
              >
                {Object.entries(formStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('reviewed')}>
              <Select
                onChange={(value) => this.setFilterValue('reviewed', value)}
                placeholder={t('all')}
                value={filterDto.reviewed}
              >
                {Object.entries(formStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('corrected')}>
              <Select
                onChange={(value) => this.setFilterValue('corrected', value)}
                placeholder={t('all')}
                value={filterDto.corrected}
              >
                {Object.entries(formStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
