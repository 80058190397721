import { memoize, snakeCase } from 'lodash'
import { createActions } from 'helpers/actions'
import { createGet } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.lists
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const getListTypes = memoize(createGet(entityName, { action: 'listTypes' }), JSON.stringify)

export default {
  ...createActions(entityName),

  getListTypes: (params) => ({
    type: `${ENTITY_NAME}_TYPES_GET`,
    payload: getListTypes(params),
  }),
}
