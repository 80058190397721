import { connect } from 'react-redux'
import { memoize } from 'lodash'
import { createAuthStateToProps } from 'helpers/auth'
import actions from 'actions/orders'
import orderItemActions from 'actions/orders/orderItems'
import orderSupplierActions from 'actions/orders/orderSuppliers'
import tagListActions from 'actions/tagLists'
import assetActions from 'actions/assets'
import jobActions from 'actions/jobs'
import operatorActions from 'actions/operators'
import supplierActions from 'actions/suppliers'
import Component from 'components/Reports/InventoryDeficiencyReport/ConvertToOrder'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  newOrder: (params) => dispatch(actions.newItem(params)),
  populateOrderItems: (params) => dispatch(orderItemActions.populateOrderItems(params)),
  saveOrder: (params) => dispatch(actions.saveOrder(params)),
  getSupplier: memoize((supplierId) => dispatch(supplierActions.getItem(supplierId))),
  generatePurchaseOrderNumber: (params) => dispatch(orderSupplierActions.generatePurchaseOrderNumber(params)),
  getTagListItems: (params) => dispatch(tagListActions.getOptions(params)),
  getAssets: (params) =>
    dispatch(
      assetActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
      })
    ),
  getJobs: (params) =>
    dispatch(
      jobActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
      })
    ),
  getOperators: (params) =>
    dispatch(
      operatorActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
        active: 'Active',
      })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
