import * as React from 'react'
import { Form } from 'antd'
import { orderBy, get } from 'lodash'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      const responses = await Promise.all([
        this.props.getUsers(),
        this.props.getObjectTypes(),
        this.props.getActionTypes(),
      ])

      this.setState({
        users: get(responses[0], 'value.data.items', []),
        objectTypes: get(responses[1], 'value.data.items', []),
        actionTypes: get(responses[2], 'value.data.items', []),
      })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto = {} } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <DateRangePicker
              filterDto={filterDto}
              setFilterValue={this.setFilterValue}
              rangePickerLabel={t('createdDate')}
            />
          </Col>
          <Col third>
            <Form.Item label={t('user')}>
              <Select
                onChange={(value) => this.setFilterValue('userNames', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.userNames}
                showSearch
              >
                {(this.state.users ?? []).map((each) => (
                  <Option key={each.userName} value={each.userName}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('itemType')}>
              <Select
                onChange={(value) => this.setFilterValue('domainObjects', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.domainObjects}
              >
                {orderBy(this.state.objectTypes ?? [], ['displayName']).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('actionType')}>
              <Select
                onChange={(value) => this.setFilterValue('actionTypes', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.actionTypes}
              >
                {(this.state.actionTypes ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
