import { Input } from 'antd'
import { createChildTablePrintComponent } from 'factories/ChildTablePrint'
import { formatValue } from 'helpers/listViews'
import { formatUserTime } from 'helpers/dateTime'

export default createChildTablePrintComponent({
  tableCellComponents: {
    newValue: (self, item) => <Input value={item.newValue ? item.newValue : ''} readOnly />,
    lastValue: (self, item) => {
      if (!item?.lastUpdated) {
        return ''
      }

      const text = formatValue({
        value: item.lastValue,
        displayFormat: self.props.fieldSettings.find((one) => one.dtoFieldName === 'lastValue').displayFormat,
      })

      return item.lastValue > item.max || item.lastValue < item.min ? (
        <div className="table-cell-error">
          <span>{text}</span>
        </div>
      ) : (
        text
      )
    },
    lastUpdated: (self, item) => formatUserTime(item.lastUpdateDate, item.lastUpdatedBy),
  },
})()
