import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import assetCategoryActions from 'actions/assetCategories'
import assetActions from 'actions/assets'
import assetToleranceActions from 'actions/assets/assetTolerances'
import Component from 'components/Reports/AssetToleranceReport/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  assetCategories: selectors.assetCategories.selectOptions(state),
  assets: selectors.assets.selectOptions(state, { itemsOnly: false }),
})

const mapDispatchToProps = (dispatch, props) => ({
  getAssetCategories: (params) => dispatch(assetCategoryActions.getOptions(params)),
  getAssets: (params) =>
    dispatch(
      assetActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
      })
    ),
  getAssetTolerances: (assetId, params) => dispatch(assetToleranceActions.getChildItems(assetId, params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
