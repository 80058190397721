import { connect } from 'react-redux'
import { createAuthStateToProps, getUserPermission } from 'helpers/auth'
import selectors from 'selectors'
import locationActions from 'actions/locations'
import Component from 'components/Inventory/Select/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  locations: selectors.locations.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getLocations: (params) =>
    dispatch(
      locationActions.getOptions({
        active: 'All',
        includeAllLocations: getUserPermission('ViewInventoryAllLocations') === 'Yes',
        ...params,
      })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
