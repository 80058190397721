import * as React from 'react'
import { getLegend, getTitle, getPieSeries, getToolbox } from 'helpers/charts'
import { DashboardContext } from 'helpers/dashboards'
import Chart from 'echarts-for-react/lib/core'

function Component(props) {
  const { echarts, theme } = React.useContext(DashboardContext)
  const { chart, section, data, showToolbox } = props

  const option = {
    toolbox: showToolbox ? getToolbox({ theme }) : undefined,
    tooltip: {},
    title: getTitle({ chart }),
    legend: getLegend({ chart }),
    series: getPieSeries({ chart, data }),
  }

  return (
    <Chart
      echarts={echarts}
      theme={theme?.name}
      style={{ width: '100%', minHeight: `${section.height}px` }}
      option={option}
    />
  )
}

export default Component
