import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/InventoryLastActivityReport/Filter'

export default createListViewComponent({
  entityName: entityNames.inventoryLastActivityReport,
  filterTemplateType: 'InventoryLastActivityReport',
  allowCreate: (self) => false,
  allowSelection: (self) => false,
})(Filter)
