import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import actions from 'actions/punchout'
import Component from 'components/Punchout'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  getPunchoutRequest: actions.getItem,
  createPunchoutRequest: actions.createItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
