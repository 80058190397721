import { snakeCase, memoize } from 'lodash'
import { createGet } from 'helpers/api'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'

const entityName = entityNames.customerDetails
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const defaultActions = createActions(entityName, {
  entityUrl: 'products/0/customerDetails',
})

const getSettings = memoize(
  createGet(entityName, {
    action: 'clientSettings',
    entityUrl: 'products/customerDetails',
  }),
  JSON.stringify
)

export default {
  ...defaultActions,

  getSettings: (params) => ({
    type: `${ENTITY_NAME}_SETTINGS_GET`,
    payload: getSettings(params),
  }),
}
