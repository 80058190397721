import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import locationGroupActions from 'actions/locationGroups'
import locationActions from 'actions/locations'
import userActions from 'actions/users'
import Component from 'components/Reports/InventoryTransferReport/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  locationGroups: selectors.locationGroups.selectOptions(state),
  locations: selectors.locations.selectOptions(state),
  users: selectors.users.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getLocationGroups: (params) => dispatch(locationGroupActions.getOptions(params)),
  getLocations: (params) =>
    dispatch(locationActions.getOptions({ ...params, active: 'All', locationVendingTypes: ['NonVending'] })),
  getUsers: (params) => dispatch(userActions.getOptions({ ...params, active: 'All' })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
