import { createListViewContainer } from 'factories/ListView'
import selectors from 'selectors'
import actions from 'actions/reports/inventoryMinMaxReport'
import inventoryActions from 'actions/inventory'
import Component from 'components/Reports/InventoryMinMaxReport'

export default createListViewContainer({
  actions,
  selectors: selectors.inventoryMinMaxReport,
  extraDispatchToProps: (dispatch, props) => ({
    updateMinMax: (params) => dispatch(inventoryActions.updateMinMax(params)),
  }),
})(Component)
