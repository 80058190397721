export default {
  All: 'all',
  AllOpenOrders: 'allOpenOrders',
  Draft: 'draft',
  New: 'new',
  PendingApproval: 'pendingApproval',
  Sent: 'sent',
  Received: 'received',
  PartiallyReceived: 'partiallyReceived',
  Cancelled: 'cancelled',
  Processed: 'processed',
  ReceivedLate: 'receivedLate',
  Backorder: 'backorder',
  Overdue: 'overdue',
}
