import { isEmpty } from 'lodash'
import { getScoped } from './localStorage'
import { createPost } from './api'

const entityName = 'keyValues'

const getByKey = createPost(entityName, { action: 'getByKey' })
const setByKey = createPost(entityName, { action: 'setByKey' })

const keyValueIds = {}

export async function getKeyValueItem(key, defaultValue) {
  try {
    const scopedKey = getScoped(key)
    const response = await getByKey({ key: scopedKey })

    if (response?.data?.id) {
      keyValueIds[scopedKey] = response.data.id
    } else {
      return defaultValue
    }

    return isEmpty(response?.data?.value) ? defaultValue : JSON.parse(response.data.value)
  } catch (error) {
    return defaultValue
  }
}

export async function setKeyValueItem(key, value) {
  const scopedKey = getScoped(key)
  const response = await setByKey({
    id: keyValueIds[scopedKey] ?? 0,
    key: scopedKey,
    value: JSON.stringify(value),
  })

  if (response?.data?.id) {
    keyValueIds[scopedKey] = response.data.id
  }

  return response
}
