import { createGlobalStyle } from 'styled-components'
import {
  RED,
  BLUE,
  GREY,
  LIGHT_YELLOW,
  LIGHT_GREEN,
  LIGHT_RED,
  LIGHTER_YELLOW,
  LIGHTER_GREEN,
  LIGHTER_RED,
  LIGHTER_GREY,
} from 'options/colors'
import { MINIMUM_COLUMN_WIDTH } from 'helpers/listViews'

const styled = { createGlobalStyle }

export const GlobalStyle = styled.createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  @media print {
    html,
    body {
      border: 1px solid white;
      height: 99%;
      page-break-after: avoid;
      page-break-before: avoid;
    }
  }

  h2 {
    margin: 0;
    font-size: 18px;
    line-height: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-collapse {
    .ant-collapse-header {
      font-weight: 500;
    }
  }

  .ant-dropdown-menu-item {
    padding: 6px 12px !important;
  }

  .ant-menu-dark {
    .ant-menu-item:hover {
      background-color: #013a70;
    }

    .ant-menu-item:not(.ant-menu-item-selected):hover {
      color: #bbb !important;
    }

    .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-selected {
      color: #bbb;

      .ant-menu-submenu-title:hover {
        background-color: #013a70;
        color: #bbb !important;
      }

      .ant-menu-submenu-arrow {
        &::before,
        &::after {
          background-color: #bbb !important;
        }
      }
    }
    .ant-menu-submenu-active-child {
      color: orange !important;

      .ant-menu-submenu-title:hover {
        color: orange !important;
      }

      .ant-menu-submenu-arrow {
        &::before,
        &::after {
          background-color: orange !important;
        }
      }
    }
  }

  .ant-form-item-children {
    .ant-checkbox-wrapper {
      color: ${GREY};
      padding-top: 5px;
      min-height: 32px;
    }
  }

  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 3px;
    vertical-align: middle;
  }

  .ant-btn .anticon {
    vertical-align: middle;
  }

  .ant-calendar-picker {
    width: 100%;
  }

  .align-top {
    vertical-align: top;
  }

  .align-middle {
    vertical-align: middle;
  }

  .font-medium {
    font-weight: 500;
  }

  .font-semibold {
    font-weight: 600;
  }

  .font-bold {
    font-weight: 700;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center;
  }

  .bg-gray-light {
    background-color: ${LIGHTER_GREY};
  }

  .color-red {
    color: ${RED};
  }

  .color-blue {
    color: ${BLUE};
  }

  .text-black {
    color: black;
  }

  .text-disabled {
    color: rgba(0, 0, 0, 0.55) !important;
  }

  .h-auto {
    height: auto;
  }

  .w-full {
    width: 100%;
  }

  .w-half {
    width: 50%;
  }

  .min-w-full {
    min-width: 100%;
  }

  .min-h-screen {
    min-height: 100vh;
  }

  .min-h-96 {
    min-height: 96px !important;
  }

  .whitespace-pre {
    white-space: pre;
  }

  .whitespace-nowrap {
    white-space: nowrap !important;
  }

  .whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .m-0 {
    margin: 0;
  }

  .p-0 {
    padding: 0;
  }

  .m-12 {
    margin: 12px;
  }

  .my-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .my-12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .my-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .ml-3 {
    margin-left: 3px;
  }

  .ml-6 {
    margin-left: 6px;
  }

  .ml-12 {
    margin-left: 12px;
  }

  .mr-6 {
    margin-right: 6px;
  }

  .mr-12 {
    margin-right: 12px;
  }

  .mr-24 {
    margin-right: 24px;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-4 {
    margin-bottom: 4px;
  }

  .mb-6 {
    margin-bottom: 6px;
  }

  .mb-12 {
    margin-bottom: 12px;
  }

  .mb-18 {
    margin-bottom: 18px;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .mt-0 {
    margin-top: 0;
  }

  .mt-3 {
    margin-top: 3px;
  }

  .mt-6 {
    margin-top: 6px;
  }

  .mt-12 {
    margin-top: 12px;
  }

  .mt-24 {
    margin-top: 24px;
  }

  .p-0 {
    padding: 0;
  }

  .p-12 {
    padding: 12px;
  }

  .pt-24 {
    padding-top: 24px;
  }

  .pt-6 {
    padding-top: 6px;
  }

  .pr-6 {
    padding-right: 6px;
  }

  .pr-12 {
    padding-right: 12px;
  }

  .float-right {
    float: right;
  }

  .relative {
    position: relative;
  }

  .flex {
    display: flex;
  }

  .flex-row {
    flex-direction: row;
  }

  .hidden {
    display: none;
  }

  .visible {
    visibility: visible;
  }

  .invisible {
    visibility: hidden;
  }

  .flex-initial {
    flex: 0 1 auto;
  }

  .flex-1 {
    flex: 1 1 0%;
  }

  .flex-auto {
    flex: 1 1 auto;
  }

  .flex-no-wrap {
    flex-wrap: nowrap;
  }

  .flex-none {
    flex: none;
  }

  .font-size-16 {
    font-size: 16px;
  }

  .opacity-25 {
    opacity: 0.25;
    filter: alpha(opacity=25);
  }

  .opacity-50 {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .break-word {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
  }

  .pointer-events-none {
    pointer-events: none;
  }

  .print-only {
    @media screen {
      display: none;
    }
  }

  .print-hidden {
    @media print {
      display: none;
    }
  }

  .ant-select {
    * {
      cursor: pointer !important;
    }
  }

  .ant-input-search,
  .ant-select-search {
    .ant-input-suffix {
      .anticon {
        display: none;
      }
      .anticon:first-child {
        display: block;
      }
    }
  }

  .inline-block {
    display: inline-block;
  }

  .ant-btn-sm {
    min-height: 24px;
    min-width: 24px;
    padding: 1px 3px;
  }

  .react-resizable-handle {
    z-index: 2;

    &:hover {
      background-color: #fefff7;
    }
  }

  @media print {
    .ant-modal,
    .ant-drawer,
    .ant-modal-mask,
    .ant-layout-sider,
    .ant-layout-header,
    .tofino-list-row-actions {
      display: none !important;
    }
  }

  .form-field-error {
    background-color: ${LIGHT_RED};

    input,
    .ant-select-selection {
      background-color: ${LIGHT_RED};
    }
  }

  .form-item-error {
    input,
    .ant-select-selection {
      background-color: ${LIGHT_RED} !important;
    }
  }

  .form-field-warning {
    background-color: ${LIGHT_YELLOW};

    input,
    .ant-select-selection {
      background-color: ${LIGHT_YELLOW};
    }
  }

  .form-item-warning {
    input,
    .ant-select-selection {
      background-color: ${LIGHT_YELLOW} !important;
    }
  }

  .form-field-success {
    background-color: ${LIGHT_GREEN};

    input,
    .ant-select-selection {
      background-color: ${LIGHT_GREEN};
    }
  }

  .form-item-success {
    input,
    .ant-select-selection {
      background-color: ${LIGHT_GREEN} !important;
    }
  }

  .form-field-highlight {
    background-color: ${LIGHT_YELLOW};

    input,
    .ant-select-selection {
      background-color: ${LIGHT_YELLOW};
    }
  }

  .form-item-highlight {
    input,
    .ant-select-selection {
      background-color: ${LIGHT_YELLOW} !important;
    }
  }

  .ant-dropdown-menu-submenu-title {
    padding-right: 42px;

    .ant-dropdown-menu-submenu-arrow {
      right: 0;
    }
  }

  .ant-list {
    .ant-list-header {
      color: #666;
      background-color: ${LIGHTER_GREY};
      font-weight: 500;
    }
  }

  .tofino-label-buttons {
    height: 22px;
    margin-top: -28px;
    margin-bottom: 6px;
    float: right;
  }

  .ant-radio-group {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: white;
      background-color: #1890ff;
    }
  }

  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      margin-right: 8px;
      margin-left: 0;
    }

    &.tofino-checkbox-group-vertical {
      .ant-checkbox-wrapper {
        display: block;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .ant-radio-group {
    &.tofino-radio-group-vertical {
      .ant-radio-wrapper {
        display: block;
      }
    }

    .ant-radio-wrapper {
      padding-top: 5px;
      min-height: 32px;
    }
  }

  .ant-drawer {
    .ant-drawer-header {
      .ant-drawer-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .ant-input-disabled,
  .ant-select-disabled,
  input:disabled,
  .ant-checkbox-wrapper-disabled span + span {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  .ant-form-vertical {
    .ant-form-item-label {
      padding-right: 8px;
    }
  }

  .ant-form-item-label {
    label {
      white-space: pre-line;
    }
  }

  .ant-tabs-tab {
    user-select: none;
  }

  .ant-tabs-tabpane {
    padding-bottom: 16px;
  }

  .ant-card {
    &.ant-card-bordered {
      border: 1px solid #d9d9d9;
    }
  }

  .ant-select-dropdown-menu-item-group-title {
    color: #666;
    font-size: 13px;
    font-weight: 700;
  }

  .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
    padding-left: 36px;
  }

  .ant-input-number {
    &.hide-steppers {
      .ant-input-number-handler-wrap {
        display: none;
      }
    }
  }

  .ant-table-wrapper {
    .ant-table {
      thead {
        th {
          min-width: ${MINIMUM_COLUMN_WIDTH}px !important;

          &.ant-table-column-has-sorters {
            cursor: default !important;
          }
        }

        .react-resizable {
          position: relative;
        }

        .react-resizable-handle {
          position: absolute;
          width: 10px;
          height: 100%;
          bottom: 0;
          right: 0;
          cursor: col-resize;
          z-index: 1;

          &:hover {
            background-color: #e8e8e8;
          }
        }

        .with-tooltip {
          & > .anticon,
          .ant-table-column-title {
            border-bottom: dashed 1px;
          }
        }

        .ant-table-column-sorters {
          position: relative;
          display: inline-block;
          cursor: pointer;
        }
      }

      td {
        position: relative;

        .table-cell-success,
        .table-cell-warning,
        .table-cell-error {
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          padding-right: 8px;
          padding-left: 8px;
        }

        .table-cell-success {
          background-color: ${LIGHTER_GREEN} !important;
        }

        .table-cell-warning {
          background-color: ${LIGHTER_YELLOW} !important;
        }

        .table-cell-error {
          background-color: ${LIGHTER_RED} !important;
        }
      }

      .ant-table-selection-col,
      .ant-table-selection-column {
        width: ${MINIMUM_COLUMN_WIDTH}px !important;
      }

      .ant-table-row {
        &.table-row-strong {
          font-weight: 500 !important;
        }

        &.table-row-warning {
          background-color: ${LIGHT_YELLOW} !important;
        }

        &.table-row-success {
          background-color: ${LIGHT_GREEN} !important;
        }

        &.table-row-error {
          background-color: ${LIGHT_RED} !important;
        }

        &.table-row-page-totals {
          color: ${GREY};
          font-weight: 500;
          background-color: ${LIGHTER_GREY};

          td {
            padding: 12px 8px !important;
          }

          .ant-table-selection-column > * {
            display: none;
          }
        }
      }

      & > .ant-table-content > .ant-table-scroll {
        & > .ant-table-header.ant-table-hide-scrollbar {
          overflow-x: hidden !important;
          overflow-y: scroll !important;
          margin-bottom: 0 !important;
        }
        & > .ant-table-body {
          overflow-x: hidden !important;
        }
      }
    }

    .ant-table-pagination {
      float: left !important;
    }

    .ant-table-empty {
      .ant-table-body {
        overflow: hidden !important;
      }
    }

    .ant-table-fixed-header .ant-table-scroll .ant-table-header {
      overflow: inherit;
    }
  }

  .tofino-modal {
    top: 64px;
    padding-bottom: unset;

    @media (max-width: 576px) {
      top: 8px !important;
    }

    .ant-modal-content {
      .ant-modal-body {
        overflow: auto;
        max-height: calc(100vh - 235px);

        @media (max-width: 576px) {
          max-height: calc(100vh - 125px);
        }
      }
    }

    &--form-container {
      .ant-modal-body {
        position: relative;
        padding-bottom: 76px;

        .form-buttons-container {
          position: absolute;
          bottom: 0;
          width: 100%;
          border-top: 1px solid #e8e8e8;
          padding: 10px 16px;
          text-align: right;
          left: 0;
          background: white;
          border-radius: 0 0 3px 3px;
        }
      }
    }

    &--wide-modal {
      @media (max-width: 992px) {
        max-width: calc(100vw - 16px);
        margin: auto;
      }
    }
  }

  .tofino-drawer {
    transform: none !important;

    .form-items-actions {
      position: absolute;
      right: 56px;
      top: 12px;
    }

    .form-items-container {
      height: calc(100vh - 110px);
      overflow-x: hidden;
      overflow-y: auto;
      margin-right: -24px;
      padding-right: 24px;
      margin-top: -24px;
      padding-top: 24px;
    }

    .form-buttons-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      left: 0;
      background: white;
      border-radius: 0 0 3px 3px;
    }

    &--duplex {
      @media screen and (max-width: 480px) {
        .ant-drawer-content-wrapper {
          width: 100% !important;
        }
      }
    }

    &--xs {
      @media screen and (max-width: 480px) {
        .ant-drawer-content-wrapper {
          width: 100% !important;
        }
      }
    }

    &--sm {
      @media screen and (max-width: 576px) {
        .ant-drawer-content-wrapper {
          width: 100% !important;
        }
      }
    }

    &--md {
      @media screen and (max-width: 768px) {
        .ant-drawer-content-wrapper {
          width: 100% !important;
        }
      }
    }

    &--lg {
      @media screen and (max-width: 992px) {
        .ant-drawer-content-wrapper {
          width: 100% !important;
        }
      }
    }

    &--xl {
      @media screen and (max-width: 1323px) {
        .ant-drawer-content-wrapper {
          width: 100% !important;
        }
      }
    }

    &--xxl {
      @media screen and (max-width: 1600px) {
        .ant-drawer-content-wrapper {
          width: 100% !important;
        }
      }
    }

    &--fs {
      .ant-drawer-content-wrapper {
        width: 100% !important;
      }
    }

    &--side-drawer {
      transform: none !important;

      & > * {
        transition: none !important;
      }

      .form-items-actions {
        right: 24px;
      }

      .ant-drawer-content-wrapper {
        box-shadow: none !important;
        border-right: 1px solid rgba(0, 0, 0, 0.15);
      }

      .ant-drawer-content {
        background-color: #f7f7f7;

        .ant-drawer-header,
        .form-buttons-container {
          background-color: #f7f7f7;
        }
      }
    }
  }

  .tofino-layout {
    &__content {
      background-color: white;
      padding: 24px;
      padding-bottom: 0;
      margin: 0;
      min-height: 280px;

      &--scrollable {
        @media screen and (min-width: 768px) {
          height: calc(100vh - 64px);
          overflow-y: auto !important;
        }
      }
    }

    &__container {
      background-color: white;

      .react-resizable {
        position: relative;
      }

      .react-resizable-handle {
        position: absolute;
        width: 15px;
        height: 100%;
        bottom: 0;
        right: -15px;
        cursor: col-resize;
      }

      .ant-layout-sider-collapsed {
        .react-resizable-handle {
          display: none;
        }
      }

      .ant-layout-sider {
        ::-webkit-scrollbar {
          width: 12px;
          background: rgb(31, 31, 31);
        }

        ::-webkit-scrollbar-track {
          border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #002140;
          border-radius: 10px;
        }

        .ant-layout-sider-children {
          .ant-menu-inline-collapsed {
            width: 64px !important;

            .ant-menu-item,
            .ant-menu-submenu-title {
              padding: 0 24px !important;
            }
          }
        }
      }

      &.ant-layout-has-sider > .ant-layout-content {
        overflow: hidden;
      }
    }
  }

  .tofino-list-view {
    @media screen and (min-width: 992px) and (min-height: 720px) {
      .tofino-inner-container {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 90px);
      }
    }

    @media screen and (max-width: 992px), screen and (max-height: 720px) {
      .tofino-table-container {
        .ant-table-body {
          max-height: unset !important;
          overflow-y: unset !important;
        }
      }
    }

    .tofino-table-container {
      flex: 1 1 0%;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  .tofino-child-list-view {
    .tofino-table-container {
      width: 100%;
      overflow-x: auto;
    }

    &.tofino-table-not-empty {
      .tofino-table-container {
        .ant-table-wrapper {
          width: unset !important;

          .ant-table-body {
            width: 100%;
            overflow-x: auto;
          }
        }
      }
    }
  }

  .tofino-list-modal,
  .tofino-child-list-modal {
    .tofino-inner-container {
      display: flex;
      flex-direction: column;

      @media screen {
        height: calc(100vh - 300px);
      }

      @media screen and (max-width: 576px) {
        height: calc(100vh - 175px);
      }

      .tofino-toolbar-container {
        position: absolute;
        top: 12px;
        width: calc(100% - 70px);
      }

      .tofino-table-container {
        @media screen {
          overflow: auto;
        }
      }
    }
  }

  .tofino-after-search {
    display: inline-block;

    .ant-badge-dot,
    .ant-badge-count {
      margin-right: 1px;
      background-color: orange;
    }
  }

  .tofino-print-preview {
    h3 {
      margin-top: 6px;
      font-weight: 500;
      font-size: 15px;
      color: black;
    }

    label {
      white-space: nowrap;
    }

    .ant-input {
      min-height: 32px;
    }

    .ant-table-wrapper {
      width: 100% !important;
    }
  }
`
