import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import supplierActions from 'actions/suppliers'
import locationGroupActions from 'actions/locationGroups'
import locationActions from 'actions/locations'
import userActions from 'actions/users'
import tenantActions from 'actions/tenants'
import customerActions from 'actions/customers'
import Component from 'components/Orders/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  suppliers: selectors.suppliers.selectOptions(state, { itemsOnly: false }),
  locationGroups: selectors.locationGroups.selectOptions(state),
  locations: selectors.locations.selectOptions(state),
  users: selectors.users.selectOptions(state),
  tenants: selectors.tenants.selectOptions(state),
  customers: selectors.customers.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getSuppliers: (params) =>
    dispatch(
      supplierActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
        active: 'All',
      })
    ),
  getLocationGroups: (params) => dispatch(locationGroupActions.getOptions(params)),
  getLocations: (params) => dispatch(locationActions.getOptions({ ...params, active: 'All' })),
  getUsers: (params) => dispatch(userActions.getOptions({ ...params, active: 'All', vending: 'NonVending' })),
  getTenants: (params) => dispatch(tenantActions.getOptions(params)),
  getCustomers: (params) => dispatch(customerActions.getOptions(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
