import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/LocationOrderReport/Filter'
import { t } from 'helpers/i18n'

export default createListViewComponent({
  entityName: entityNames.locationOrderReport,
  filterTemplateType: 'LocationOrderReport',
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  tableHeadGroups: (self) => {
    if (
      self.state.filterDto?.displayMode === 'QuantitiesBySupplier' &&
      self.state.filterDto.supplierIds?.length
    ) {
      const supplierIds = [...self.state.filterDto.supplierIds, -1, -2]
      const supplierNames = [
        ...(self.state.filterDto.supplierNames ?? self.state.filterDto.supplierIds),
        t('other'),
        t('total'),
      ]

      return {
        itemsCounted: () =>
          supplierIds.map((each, index) => ({
            title: supplierNames[index],
            key: `supplierCounts[${index}].itemsCounted`,
            dataIndex: `supplierCounts[${index}].itemsCounted`,
            width: 150,
            align: 'right',
          })),
        itemsOrdered: () =>
          supplierIds.map((each, index) => ({
            title: supplierNames[index],
            key: `supplierCounts[${index}].itemsOrdered`,
            dataIndex: `supplierCounts[${index}].itemsOrdered`,
            width: 150,
            align: 'right',
          })),
      }
    }

    return {}
  },
})(Filter)
