import produce from 'immer'
import { isEmpty } from 'lodash'
import { baseReportUrl } from 'config'
import { createActions, normalizeDateRange } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default createActions(entityNames.assetToleranceReport, {
  basePath: 'report/assetReports',
  baseUrl: baseReportUrl,
  allowGetItems: (params) => !isEmpty(params.assetIds),
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      normalizeDateRange(draft)
    }),
})
