import { Form, Button } from 'antd'
import { get, isNil, isEmpty } from 'lodash'
import cx from 'clsx'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { getUserPermission } from 'helpers/auth'
import { showError, showClientNotifications } from 'helpers/errors'
import { t } from 'helpers/i18n'
import { PRIVATE_DATABASE } from 'options/products'
import { formatValue } from 'helpers/listViews'
import Modal from 'elements/Modal'
import Select, { Option } from 'elements/Select'
import Icon from 'elements/Icon'
import SelectSupplier from 'containers/Suppliers/Select'
import PrintLabel from 'containers/PrintLabel'
import Filter from 'containers/Products/Filter'

const allowEditProducts = ({ props, state }) => {
  const tenantType = props?.tenant?.tenantType
  const userIsInRoleDistributor = props?.user?.userIsInRoleDistributor
  const isPrivateDatabase = state.filterDto?.catalogTableName === PRIVATE_DATABASE

  if (!isPrivateDatabase) {
    return false
  }

  if (tenantType === 'Dcrib' && !userIsInRoleDistributor) {
    return false
  }

  return getUserPermission('Products') === 'Edit'
}

export default createListViewComponent({
  entityName: entityNames.products,
  filterTemplateType: 'Product',
  createButtonTextId: 'createProduct',
  primaryLinkTarget: 'productRecord',
  initialFilterDto: (self) => ({
    active: 'Active',
    catalogTableName: self.props.tenant.tenantType === 'SupplierPortal' ? undefined : PRIVATE_DATABASE,
  }),
  deleteDisabled: (self) => getUserPermission('Products') !== 'Edit',
  createDisabled: (self) => getUserPermission('Products') !== 'Edit',
  allowCreate: allowEditProducts,
  allowDelete: allowEditProducts,
  extraRowActions: (self) => {
    const { tenantType } = self.props.tenant ?? {}
    const isPrivateDatabase = self.state.filterDto?.catalogTableName === PRIVATE_DATABASE
    const { catalogTableName } = self.state.filterDto ?? {}

    if (tenantType !== 'Trms') {
      return null
    }

    return (
      <>
        <PrintLabel
          type="Product"
          items={self.state.selectedRowKeys.map((each) => self.state.items.find((one) => one.id === each))}
          parentHasUnsavedChanges={self.hasUnsavedChanges()}
        />
        {getUserPermission('Products') === 'Edit' ? (
          isPrivateDatabase ? (
            <>
              <Button
                disabled={isEmpty(self.state.selectedRowKeys)}
                onClick={() => self.setState({ setPreferredSupplierVisible: true })}
              >
                <Icon type="LocalShipping" />
                {t('setPreferredSupplier')}
              </Button>
              <Button
                disabled={isEmpty(self.state.selectedRowKeys)}
                onClick={async () => {
                  try {
                    const productCatalogList = await self.props.getProductCatalogs()

                    self.setState({ productCatalogs: productCatalogList?.value?.data ?? [] })
                  } catch (error) {
                    showError({ error })
                  }

                  self.setState({ setLinkedCatalogVisible: true })
                }}
              >
                <Icon type="link" />
                {t('setLinkedCatalog')}
              </Button>
              <Modal
                title={`${t('setPreferredSupplier')} (${self.state.selectedRowKeys.length} ${t('items')})`}
                visible={self.state.setPreferredSupplierVisible}
                okText={t('update')}
                okButtonProps={{
                  disabled: isNil(self.state.newSupplierId),
                  loading: self.state.setPreferredSupplierLoading,
                }}
                onOk={async () => {
                  try {
                    self.setState({ setPreferredSupplierLoading: true })

                    const response = await self.props.setPreferredSupplier({
                      productIds: self.state.selectedRowKeys,
                      newSupplierId: self.state.newSupplierId,
                    })

                    showClientNotifications({ response })

                    if (response.value.data.failureCount > 0) {
                      throw new Error()
                    }

                    self.setState(
                      {
                        setPreferredSupplierVisible: false,
                        newSupplierId: undefined,
                      },
                      self.fetchItems
                    )
                  } catch (error) {
                    showError({ error })
                  } finally {
                    self.setState({ setPreferredSupplierLoading: false })
                  }
                }}
                onCancel={() =>
                  self.setState({
                    setPreferredSupplierVisible: false,
                    newSupplierId: undefined,
                  })
                }
                width={992}
              >
                <SelectSupplier
                  onSelect={(values) => self.setState({ newSupplierId: get(values, '[0].id') })}
                  filterProps={{ disabled: true }}
                />
              </Modal>
              <Modal
                title={`${t('setLinkedCatalog')} (${self.state.selectedRowKeys.length} ${t('items')})`}
                visible={self.state.setLinkedCatalogVisible}
                okText={t('update')}
                okButtonProps={{
                  disabled: isNil(self.state.newCatalogTableName),
                  loading: self.state.setLinkedCatalogLoading,
                }}
                onOk={async () => {
                  try {
                    self.setState({ setLinkedCatalogLoading: true })

                    const response = await self.props.setLinkedCatalog({
                      productIds: self.state.selectedRowKeys,
                      catalogTableName: self.state.newCatalogTableName,
                    })

                    showClientNotifications({ response })

                    if (response.value.data.failureCount > 0) {
                      throw new Error()
                    }

                    self.setState(
                      {
                        setLinkedCatalogVisible: false,
                        newCatalogTableName: null,
                      },
                      self.fetchItems
                    )
                  } catch (error) {
                    showError({ error })
                  } finally {
                    self.setState({ setLinkedCatalogLoading: false })
                  }
                }}
                onCancel={() =>
                  self.setState({
                    setLinkedCatalogVisible: false,
                    newCatalogTableName: null,
                  })
                }
              >
                <Form.Item label={t('catalog')} colon={false}>
                  <Select
                    onChange={(value) => self.setState({ newCatalogTableName: value })}
                    placeholder={t('select')}
                    value={self.state.newCatalogTableName}
                  >
                    <Option value="">{t('none')}</Option>
                    {(self.state.productCatalogs ?? []).map((each) => (
                      <Option key={each.catalogTableName} value={each.catalogTableName}>
                        {each.longName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Modal>
            </>
          ) : (
            <Button
              disabled={isEmpty(self.state.selectedRowKeys)}
              onClick={async () => {
                try {
                  const response = await self.props.copyToPrivate({
                    catalogProductIds: self.state.selectedRowKeys,
                    catalogTableName,
                  })

                  showClientNotifications({ response })
                } catch (error) {
                  showError({ error })
                }
              }}
            >
              <Icon type="ContentCopy" />
              {t('copyToDatabase')}
            </Button>
          )
        ) : null}
      </>
    )
  },
  transformGetItemsParams: (self) => {
    const { catalogTableName } = self.state.filterDto ?? {}
    const isPrivateDatabase = catalogTableName === PRIVATE_DATABASE
    const { active } = self.state.filterDto ?? {}

    return {
      useReferenceCatalog: !isPrivateDatabase,
      catalogTableName: !isPrivateDatabase ? catalogTableName : '',
      active: active || 'All',
    }
  },
  getFileTemplateTypes: (self) =>
    self.props.tenant?.tenantType === 'SupplierPortal'
      ? ['CatalogProduct']
      : self.state.filterDto?.catalogTableName === PRIVATE_DATABASE
        ? ['Products', 'SupplierPrices', 'CustomerPrices', 'ProductInCategory', 'ProductReferencePrices']
        : [],
  getSettingsType: (self) => (self.state.filterDto?.catalogTableName !== PRIVATE_DATABASE ? 'catalogProducts' : ''),
  getDeleteItemsOptions: (self) => {
    const { catalogTableName } = self.state.filterDto ?? {}
    return catalogTableName !== PRIVATE_DATABASE ? { catalogTableName } : ''
  },
  tableCellComponents: {
    effectivePrice: (self, item, column, text) => (
      <span className={cx({ 'font-semibold': item.supplierPricingInEffect })}>
        {formatValue({
          value: item.effectivePrice,
          displayFormat: column.displayFormat,
          dtoFieldName: column.dtoFieldName,
        })}
      </span>
    ),
  },
})(Filter)
