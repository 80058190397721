import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'

export default ({ actions, extraStateToProps, extraDispatchToProps } = {}) => {
  if (extraStateToProps) {
    console.error('extraStateToProps in FormView container is deprecated')
  }

  return (Component) => {
    const mapStateToProps = (state, props) => ({
      ...createAuthStateToProps(state, props),
      ...(extraStateToProps ? extraStateToProps(state, props) : {}),
    })

    const mapDispatchToProps = (dispatch, props) => ({
      newItem: (data) => Promise.resolve({ value: { data } }),
      getItem: (itemId) => dispatch(actions.getItem(itemId)),
      createItem: (params, options) => dispatch(actions.createItem(params, options)),
      updateItem: (params, options) => dispatch(actions.updateItem(params, options)),
      createItems: (params, options) => dispatch(actions.createItems(params, options)),
      updateItems: (params, options) => dispatch(actions.updateItems(params, options)),
      getSettings: (params) => dispatch(actions.getSettings(params)),
      ...(extraDispatchToProps ? extraDispatchToProps(dispatch, props) : {}),
    })

    return connect(mapStateToProps, mapDispatchToProps)(Component)
  }
}
