import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  componentDidMount() {
    Promise.all([this.props.getLocations(), this.props.getUsers()]).catch((error) => showError({ error }))
  }

  render() {
    const { filterDto = {} } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <DateRangePicker
              filterDto={filterDto}
              setFilterValue={this.setFilterValue}
              rangePickerLabel={t('dateRange')}
            />
          </Col>
          <Col third>
            <Form.Item label={t('fromLocation')}>
              <Select
                onChange={(value) => this.setFilterValue('fromLocationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.fromLocationIds}
              >
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('toLocation')}>
              <Select
                onChange={(value) => this.setFilterValue('toLocationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.toLocationIds}
              >
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('user')}>
              <Select
                onChange={(value) => this.setFilterValue('userNames', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.userNames}
                showSearch
              >
                {(this.props.users ?? []).map((each) => (
                  <Option key={each.userName} value={each.userName}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
