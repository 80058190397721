export default [
  {
    dtoFieldName: 'tag',
    columnHeadingLanguageKey: 'tag',
    columnHeadingTooltipLanguageKey: 'tagColumnTooltip',
    isDisplayable: true,
    displayByDefault: true,
    columnWidth: 64,
    sortByEnumValue: -1,
    linkTarget: 'none',
    alignment: 'Left',
    displayFormat: 'Text',
  },
  {
    dtoFieldName: 'label',
    columnHeadingLanguageKey: 'label',
    columnHeadingTooltipLanguageKey: '',
    isDisplayable: true,
    displayByDefault: true,
    columnWidth: 192,
    sortByEnumValue: -1,
    linkTarget: 'none',
    alignment: 'Left',
    displayFormat: 'Text',
  },
  {
    dtoFieldName: 'enabled',
    columnHeadingLanguageKey: 'issue',
    columnHeadingTooltipLanguageKey: '',
    isDisplayable: true,
    displayByDefault: true,
    columnWidth: 80,
    sortByEnumValue: -1,
    linkTarget: 'none',
    alignment: 'Center',
    displayFormat: 'Text',
  },
  {
    dtoFieldName: 'enabledPurch',
    columnHeadingLanguageKey: 'purchase',
    columnHeadingTooltipLanguageKey: '',
    isDisplayable: true,
    displayByDefault: true,
    columnWidth: 80,
    sortByEnumValue: -1,
    linkTarget: 'none',
    alignment: 'Center',
    displayFormat: 'Text',
  },
  {
    dtoFieldName: 'enabledAssets',
    columnHeadingLanguageKey: 'assets',
    columnHeadingTooltipLanguageKey: '',
    isDisplayable: true,
    displayByDefault: true,
    columnWidth: 80,
    sortByEnumValue: -1,
    linkTarget: 'none',
    alignment: 'Center',
    displayFormat: 'Text',
  },
  {
    dtoFieldName: 'required',
    columnHeadingLanguageKey: 'required',
    columnHeadingTooltipLanguageKey: 'requiredTagColumnTooltip',
    isDisplayable: true,
    displayByDefault: true,
    columnWidth: 80,
    sortByEnumValue: -1,
    linkTarget: 'none',
    alignment: 'Center',
    displayFormat: 'Text',
  },
  {
    dtoFieldName: 'restricted',
    columnHeadingLanguageKey: 'restricted',
    columnHeadingTooltipLanguageKey: 'restrictedTagColumnTooltip',
    isDisplayable: true,
    displayByDefault: true,
    columnWidth: 80,
    sortByEnumValue: -1,
    linkTarget: 'none',
    alignment: 'Center',
    displayFormat: 'Text',
  },
  {
    dtoFieldName: 'showList',
    columnHeadingLanguageKey: 'showList',
    columnHeadingTooltipLanguageKey: '',
    isDisplayable: true,
    displayByDefault: true,
    columnWidth: 80,
    sortByEnumValue: -1,
    linkTarget: 'none',
    alignment: 'Center',
    displayFormat: 'Text',
  },
]
