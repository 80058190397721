import actions from 'actions/dashboardTemplates'
import { createFormViewContainer } from 'factories/FormView'
import Component from 'components/DashboardTemplates/FormView'

export default createFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    newItem: (params) => dispatch(actions.newItem(params)),
    getChartTypes: (params) => dispatch(actions.getChartTypes(params)),
    getChartMetricTypes: (params) => dispatch(actions.getChartMetricTypes(params)),
    getDistributionTypes: (params) => dispatch(actions.getDistributionTypes(params)),
    getDimensionTypes: (params) => dispatch(actions.getDimensionTypes(params)),
  }),
})(Component)
