import * as React from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'
import { Spin, Collapse } from 'antd'
import { toLower, set } from 'lodash'
import { t } from 'helpers/i18n'
import { getPageTitle } from 'helpers/auth'
import { showError } from 'helpers/errors'
import Page from 'elements/Page'
import Toolbar from 'elements/Toolbar'
import Icon from 'elements/Icon'
import { Row, Col } from 'elements/Grid'

const Container = styled.div`
  margin-bottom: 24px;

  .ant-collapse-content-box {
    .ant-row {
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid #e8e8e8;

      &:last-child {
        border-bottom: none;
      }
    }
  }
`

function Component(props) {
  const pageTitle = getPageTitle()

  const [state, updateState] = useImmer({
    search: '',
    items: [],
    loading: false,
  })

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  async function fetchItems() {
    try {
      setState('loading', true)

      const response = await props.getItems()

      setState('items', response.value.data.items)
    } catch (error) {
      showError({ error })
    } finally {
      setState('loading', false)
    }
  }

  React.useEffect(() => {
    fetchItems()
  }, [])

  const filteredItems = (state?.items ?? []).filter((each) => {
    const keyword = toLower(state.search)
    return toLower(each.name).includes(keyword) || toLower(each.description).includes(keyword)
  })
  const categories = filteredItems.map((each) => each.category).filter((v, i, a) => a.indexOf(v) === i) // uniq

  return (
    <Page title={pageTitle} scrollable>
      <Toolbar
        title={pageTitle}
        search={state.search}
        onSearchChange={(e) => setState('search', e.target.value)}
      />
      <Container>
        {state.loading ? (
          <Spin />
        ) : (
          <Collapse defaultActiveKey={categories}>
            {categories.map((category) => {
              const header = filteredItems.find((one) => one.category === category)
              const rows = filteredItems.filter((each) => each.category === category)

              return (
                <Collapse.Panel key={category} header={header.categoryDescription || t('na')}>
                  {rows.map((each) => (
                    <Row key={each.id}>
                      <Col xs={8} sm={4}>
                        <a href={each.url} target="_blank" rel="noopener noreferrer">
                          <Icon type={each.iconName} size={28} />
                        </a>
                      </Col>
                      <Col xs={16} sm={8} className="font-medium">
                        <a href={each.url} target="_blank" rel="noopener noreferrer">
                          {each.name}
                        </a>
                      </Col>
                      <Col half>{each.description}</Col>
                    </Row>
                  ))}
                </Collapse.Panel>
              )
            })}
          </Collapse>
        )}
      </Container>
    </Page>
  )
}

export default Component
