import * as React from 'react'
import { Checkbox as AntCheckbox } from 'antd'

export const { Group: CheckboxGroup } = AntCheckbox

function Checkbox(props) {
  const { forwardedRef, onChange = () => {}, readOnly, ...rest } = props

  return <AntCheckbox ref={forwardedRef} onChange={readOnly ? () => {} : onChange} {...rest} />
}

export default React.forwardRef((props, ref) => <Checkbox {...props} forwardedRef={ref} />)
