import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/AssetDowntimeSummaryReport/Filter'

export default createListViewComponent({
  entityName: entityNames.assetDowntimeSummaryReport,
  filterTemplateType: 'AssetDowntimeSummaryReport',
  initialFilterDto: (self) => ({
    dateRange: { dateRangeType: 'YearToDate' },
    summarizeBy: 'Location',
    meantimeBasedOn: 'AssetDowntime',
  }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
})(Filter)
