import * as React from 'react'
import { useSelector } from 'react-redux'
import { DatePicker as AntDatePicker } from 'antd'
import { tryParseMoment, getDateTimeFormat } from 'helpers/dateTime'
import selectors from 'selectors'

export const { MonthPicker } = AntDatePicker

function DatePicker(props) {
  const locale = useSelector(selectors.auth.locale)
  const {
    forwardedRef,
    value,
    onChange = () => {},
    setTimeMode = 'start',
    readOnly,
    style,
    showTime,
    format = getDateTimeFormat({ showTime }),
    allowClear = true,
    ...rest
  } = props

  function handleChange(input) {
    if (readOnly) {
      return
    }

    onChange(
      input
        ? new Date(
            setTimeMode === 'start'
              ? input.clone().toDate().setHours(0, 0, 0, 0)
              : setTimeMode === 'end'
                ? input.clone().toDate().setHours(23, 59, 59, 0)
                : input.clone().toDate()
          )
        : null
    )
  }

  return (
    <AntDatePicker
      ref={forwardedRef}
      value={tryParseMoment(value)}
      onChange={handleChange}
      style={{ width: '100%', ...style }}
      format={format}
      showTime={
        showTime
          ? {
              format,
              use12Hours: locale === 'en',
            }
          : false
      }
      {...rest}
      readOnly={readOnly}
      allowClear={readOnly ? false : allowClear}
    />
  )
}

export default React.forwardRef((props, ref) => <DatePicker {...props} forwardedRef={ref} />)
