import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/AssetCostHistoryReport/Filter'

export default createListViewComponent({
  entityName: entityNames.assetCostHistoryReport,
  filterTemplateType: 'AssetCostHistoryReport',
  initialFilterDto: (self) => ({
    dateRange: { dateRangeType: 'YearToDate' },
    period: 'Month',
  }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
})(Filter)
