import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import listActions from 'actions/lists'
import locationGroupActions from 'actions/locationGroups'
import Component from 'components/OverstockInventory/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  locationGroups: selectors.locationGroups.selectOptions(state),
  lists: selectors.lists.selectOptions(state),
})

export default connect(mapStateToProps, {
  getLocationGroups: locationGroupActions.getOptions,
  getLists: listActions.getOptions,
})(Component)
