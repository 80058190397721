import * as React from 'react'
import { Form } from 'antd'
import { HARD_SPACE } from 'helpers/utils'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { createSearchSuppliers, createDropdownRender } from 'helpers/formViews'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import Help from 'elements/Help'
import InputNumber from 'elements/InputNumber'
import SelectProductCategories from 'containers/ProductCategories/Select'
import { Row, Col } from 'elements/Grid'
import Checkbox from 'elements/Checkbox'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchSuppliers = createSearchSuppliers(this)
  }

  async componentDidMount() {
    try {
      const { locationIds, locationGroupIds, productCategoryIds, supplierIds } = this.props.filterDto

      await Promise.all([
        this.props.getLocationGroups(),
        this.props.getLocations({ locationGroupIds, locationIds }),
        this.props.getSuppliers(supplierIds ? { supplierIds } : undefined),
        this.props.getProductCategories({ productCategoryIds }),
      ])
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto = {}, suppliers } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker
              filterDto={filterDto}
              setFilterValue={this.setFilterValue}
              dateRangeTypeLabel={
                <span>
                  {t('dateRangeType')} <Help title={t('minMaxDateRangeInfo')} />
                </span>
              }
            />
          </Col>
          <Col fourth>
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('productCategory')}>
              <SelectProductCategories
                value={filterDto.productCategoryIds}
                placeholder={t('all')}
                onChange={(value) => this.setFilterValue('productCategoryIds', value)}
                dataSource={this.props.productCategories ?? []}
              />
            </Form.Item>
            <Form.Item label={t('preferredSupplier')}>
              <Select
                onChange={(value) => this.setFilterValue('supplierIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.supplierIds}
                dropdownRender={createDropdownRender(suppliers)}
                onSearch={this.searchSuppliers}
                showSearch
              >
                <Option value={0}>{t('notAssigned')}</Option>
                {(suppliers?.items ?? [])
                  .filter((each) => each.id)
                  .map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item
              label={
                <span>
                  {t('targetDaysOnHand')} <Help title={t('targetDaysOnHandInfo')} />
                </span>
              }
            >
              <InputNumber
                onChange={(value) => this.setFilterValue('targetDaysOnHand', value)}
                value={filterDto.targetDaysOnHand}
                min={0}
              />
            </Form.Item>
            <Row>
              <Col xs={12}>
                <Form.Item label={HARD_SPACE}>
                  <Checkbox
                    onChange={(e) => this.setFilterValue('doNotReorderItemOnly', e.target.checked)}
                    checked={filterDto.doNotReorderItemOnly}
                  >
                    {t('doNotReorder')} <Help title={t('noInventoryReorderInfo')} />
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label={HARD_SPACE}>
                  <Checkbox
                    onChange={(e) => this.setFilterValue('criticalItemsOnly', e.target.checked)}
                    checked={filterDto.criticalItemsOnly}
                  >
                    {t('critical')}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
