import Field from './Field'
import styled from 'styled-components'

const Container = styled.div`
  color: black;
  display: block;
  whitespace: nowrap;
`

function Component(props) {
  const {
    line,
    item,
    unit,
    barcodeFormat,
    highlightable,
    labelTemplateFields = [],
    highlighted: { fieldIndex = -1 } = {},
  } = props

  return (
    <Container
      style={{
        whiteSpace: 'nowrap',
        overflow: highlightable ? 'visible' : 'hidden',
        minHeight: `${line.height}${unit}`,
        maxHeight: `${line.height}${unit}`,
      }}
    >
      {(line?.fields ?? []).map((each, index) => (
        <Field
          key={each.id}
          field={each}
          item={item}
          unit={unit}
          barcodeFormat={barcodeFormat}
          lineHeight={line.height}
          labelTemplateFields={labelTemplateFields}
          highlightable={highlightable}
          highlighted={fieldIndex === index}
        />
      ))}
    </Container>
  )
}

export default Component
