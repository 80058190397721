import produce from 'immer'
import { snakeCase, isEmpty } from 'lodash'
import { createActions } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.locations
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    useSelectionList: true,
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.alwaysIncludeLocationIds =
          draft.alwaysIncludeLocationIds === false ? false : !isEmpty(draft.locationIds)
      }),
  }),

  sendCreateNotification: (params = {}) => ({
    type: `${ENTITY_NAME}_SEND_CREATE_NOTIFICATION`,
    payload: createPost(entityName, { action: `${params.id}/sendCreateNotification` })(params),
  }),

  sendUpdateNotification: (params = {}) => ({
    type: `${ENTITY_NAME}_SEND_UPDATE_NOTIFICATION`,
    payload: createPost(entityName, { action: `${params.id}/sendUpdateNotification` })(params),
  }),

  getAddressList: (params = {}) => ({
    type: `${ENTITY_NAME}_ADDRESS_LIST_GET`,
    payload: createPost(entityName, { action: `addressList?type=${params.type}` })(params),
  }),

  generateTopUpOrder: (params = {}) => ({
    type: `${ENTITY_NAME}_GENERATE_TOP_UP_ORDER`,
    payload: createPost(entityName, { action: `${params.id}/topup` })(params),
  }),

  getItemCount: (params = {}) => ({
    type: `${ENTITY_NAME}_ITEM_COUNT_GET`,
    payload: createPost(entityName, { action: `${params.locationId}/getItemCount` })(params),
  }),
}
