import * as React from 'react'
import { Form } from 'antd'
import { uniqBy, orderBy } from 'lodash'
import { filterByValue } from 'helpers/formViews'
import { createSetFilterValue } from 'helpers/filters'
import usagePurchaseOptions from 'options/orders/usagePurchaseOptions'
import { t } from 'helpers/i18n'
import Select, { Option } from 'elements/Select'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  render() {
    const { filterDto, items = [] } = this.props
    const suppliers = uniqBy(orderBy(items, ['supplierId']), 'supplierId')

    return (
      <Row>
        <Col md={24} lg={8}>
          {suppliers.length === 1 ? (
            <Form.Item label={t('supplier')}>
              <Select value={suppliers[0].supplierId} allowClear={false}>
                {suppliers.map((each) => (
                  <Option key={each.supplierId} value={each.supplierId}>
                    {each.supplierName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item label={t('supplier')}>
              <Select
                onChange={(value) => this.setFilterValue('supplierId', value)}
                placeholder={t('all')}
                value={filterDto?.supplierId}
                filterOption={filterByValue}
                showSearch
                allowClear
              >
                {suppliers.map((each) => (
                  <Option key={each.supplierId} value={each.supplierId}>
                    {each.supplierName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Col>
        <Col md={24} lg={8}>
          <Form.Item label={t('usagePurchase')}>
            <Select
              onChange={(value) => this.setFilterValue('usagePurchase', value)}
              placeholder={t('all')}
              value={filterDto?.usagePurchase}
              showSearch={false}
              allowClear
            >
              {Object.entries(usagePurchaseOptions).map(([key, value]) => (
                <Option key={key} value={key}>
                  {t(value)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    )
  }
}

export default Form.create()(Filter)
