import actions from 'actions/lists'
import { createFormViewContainer } from 'factories/FormView'
import { createChildItemsDispatchToProps } from 'helpers/formViews'
import listItemActions from 'actions/lists/listItems'
import Component from 'components/Lists/FormView'

export default createFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    newItem: (params) => dispatch(actions.newItem(params)),
    populateListItems: (params) => dispatch(listItemActions.populateListItems(params)),
    ...createChildItemsDispatchToProps(dispatch, 'List', listItemActions),
  }),
})(Component)
