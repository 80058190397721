import * as React from 'react'
import styled from 'styled-components'
import { Spin, Table, Tooltip, Button } from 'antd'
import { isEmpty, pick, debounce } from 'lodash'
import {
  getChildColumns,
  createHandleCustomizeOk,
  createHandleColumnResize,
  createChildListViewHandleTableChange,
} from 'helpers/listViews'
import { createResetState, sortItems } from 'helpers/utils'
import { showError } from 'helpers/errors'
import { t } from 'helpers/i18n'
import { getKeyValueItem, setKeyValueItem } from 'helpers/keyValueStorage'
import ResizableCell from 'elements/ResizableCell'
import Customize from 'elements/Customize'
import { Row, Col } from 'elements/Grid'
import Icon from 'elements/Icon'

const Container = styled.div`
  .ant-table-thead > tr > th {
    background-color: #f5f5f5;

    .ant-table-column-sorter {
      .anticon-caret-up.off,
      .anticon-caret-down.off {
        display: none;
      }

      .anticon-caret-up.on,
      .anticon-caret-down.on {
        margin-top: 6px;
      }
    }
  }
`

export default ({
    entityName,
    childName,
    getStorageKey = (self) =>
      self.props.storageKey
        ? self.props.storageKey
        : entityName && childName
          ? [entityName, 'printView', childName, self.props.settingsType].filter(Boolean).join('.')
          : '__CHILD_LIST_PRINT_VIEW__',
    tableCellComponents = {},
    sharedComponents,
    extraTableSummary,
    totalsRowComponents = {},
    tableHeadGroups = (self) => ({
      // dtoFieldName: () => []
    }),
    getRowClassName = (self) => (record, index) => '',
    getIdField = (self) => 'id',
  } = {}) =>
  () => {
    if (isEmpty(entityName)) {
      throw new Error('entityName is empty')
    }

    if (isEmpty(childName)) {
      throw new Error('childName is empty')
    }

    return class ChildListPrint extends React.Component {
      state = {}

      constructor(props) {
        super(props)

        this.handleColumnResize = createHandleColumnResize(this)
        this.handleCustomizeOk = createHandleCustomizeOk(this, true)
        this.handleTableChange = createChildListViewHandleTableChange(this)
        this.resetState = createResetState(this, {
          selectedColumnKeys: [],
          sortedColumnKeys: [],
          columnWidths: {},
          customizeColumnsVisible: false,
        })
        this.saveState = debounce(this.saveState, 2500)
      }

      async componentDidMount() {
        const savedState = await getKeyValueItem(getStorageKey(this), {})
        await this.resetState(savedState)
        await this.fetchSettings()
      }

      componentWillUnmount() {
        this.saveState.flush()
      }

      fetchSettings = async () => {
        try {
          const response = await this.props.getSettings({ type: this.props.settingsType })

          this.setState({ fieldSettings: response.value.data.fieldSettings })
        } catch (error) {
          showError({ error })
        }
      }

      saveState = () =>
        setKeyValueItem(
          getStorageKey(this),
          pick(this.state, ['columnWidths', 'selectedColumnKeys', 'sortBy', 'sortOrder', 'sortedColumnKeys'])
        )

      render() {
        const { items = [] } = this.props
        const { fieldSettings = [] } = this.state

        if (isEmpty(fieldSettings)) {
          return <Spin />
        }

        const columns = getChildColumns({
          self: this,
          fieldSettings,
          tableCellComponents,
          totalsRowComponents,
          tableHeadGroups,
          onResize: this.handleColumnResize,
          selectedColumnKeys: this.state.selectedColumnKeys,
          ...pick(this.state, ['columnWidths', 'sortBy', 'sortedColumnKeys', 'sortOrder']),
        })
        this.dataSource = [...sortItems(items, this.state.sortBy, this.state.sortOrder)]

        // console.log({ items, fieldSettings })

        return (
          <>
            <Container>
              <Row type="flex" justify="space-between my-6">
                <Col className="text-left">
                  <h3>{this.props.title}</h3>
                </Col>
                <Col className="text-right">
                  {extraTableSummary && extraTableSummary(this)}
                  <Tooltip title={t('customizeColumns')} placement="topRight">
                    <Button
                      size="small"
                      onClick={() => this.setState({ customizeColumnsVisible: true })}
                      disabled={isEmpty(fieldSettings)}
                      className="print-hidden mt-6"
                    >
                      <Icon type="ViewColumn" />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
              <Table
                childrenColumnName={[]}
                rowKey={getIdField(this)}
                columns={columns}
                dataSource={this.dataSource}
                rowClassName={getRowClassName(this)}
                size="middle"
                components={{ header: { cell: ResizableCell } }}
                pagination={false}
                onChange={this.handleTableChange}
                locale={{ emptyText: t('noData') }}
              />
              <div className="mt-6">{`${this.dataSource.length ?? 0} ${t('items')}`}</div>
            </Container>
            <Customize
              displayableColumns={fieldSettings.filter((each) => each.isDisplayable)}
              visible={this.state.customizeColumnsVisible}
              onOk={this.handleCustomizeOk}
              onCancel={() => this.setState({ customizeColumnsVisible: false })}
              selectedColumnKeys={this.state.selectedColumnKeys}
              sortedColumnKeys={this.state.sortedColumnKeys}
              columnWidths={this.state.columnWidths}
            />
            {sharedComponents?.(this)}
          </>
        )
      }
    }
  }
