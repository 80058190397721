import * as React from 'react'
import { Select as AntSelect } from 'antd'
import { filterByValue } from 'helpers/formViews'
import { LIGHT_GREY } from 'options/colors'
import { Down } from 'elements/Icon'

export const { Option, OptGroup } = AntSelect

export const getOptionProps = (each, style) =>
  each.active === false ? { style: { ...style, textDecoration: 'line-through' } } : style

function Select(props) {
  const {
    forwardedRef,
    readOnly,
    style,
    onSearch,
    dropdownRender,
    mode,
    fullWidth = true,
    onChange = () => {},
    allowClear = true,
  } = props

  if (dropdownRender && !onSearch) {
    throw new Error('dropdownRender has been provided without onSearch handler')
  }

  const render = (
    <AntSelect
      ref={forwardedRef}
      dropdownMatchSelectWidth={false}
      filterOption={!onSearch ? filterByValue : undefined}
      maxTagCount={3}
      style={fullWidth ? { width: '100%', ...style } : style}
      onDropdownVisibleChange={(open) => !open && onSearch?.('')}
      showSearch
      {...props}
      dropdownRender={onSearch ? dropdownRender : undefined}
      readOnly={readOnly}
      onChange={readOnly ? () => {} : onChange}
      allowClear={readOnly ? false : allowClear}
    />
  )

  return mode === 'multiple' ? (
    <div style={{ position: 'relative', cursor: 'pointer' }}>
      {render}
      <Down
        style={{
          position: 'absolute',
          fontSize: '12px',
          color: LIGHT_GREY,
          fill: LIGHT_GREY,
          right: '12px',
          top: '12px',
          pointerEvents: 'none',
        }}
      />
    </div>
  ) : (
    render
  )
}

export default React.forwardRef((props, ref) => <Select {...props} forwardedRef={ref} />)
