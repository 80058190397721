import * as React from 'react'
import styled, { css } from 'styled-components'
import produce from 'immer'
import { useImmer } from 'use-immer'
import { Input, List, Spin } from 'antd'
import { set, isEmpty, isBoolean, toLower } from 'lodash'
import { useDebouncedCallback } from 'use-debounce'
import { tryParseInt, tryParseFloat, DEBOUNCE } from 'helpers/utils'
import { showError } from 'helpers/errors'
import basePriceCalculationTypes from 'options/customers/basePriceCalculationTypes'
import orderCalculationTypes from 'options/customers/orderCalculationTypes'
import inventoryTypes from 'options/customers/inventoryTypes'
import scanTypes from 'options/customers/scanTypes'
import profilePriorityTypes from 'options/customers/profilePriorityTypes'
import issueLimitConflictResolutionTypes from 'options/customers/issueLimitConflictResolutionTypes'
import issueLimitExceptionActionTypes from 'options/customers/issueLimitExceptionActionTypes'
import receivingModes from 'options/customers/receivingModes'
import { createDropdownRender } from 'helpers/formViews'
import { t } from 'helpers/i18n'
import Checkbox from 'elements/Checkbox'
import Help from 'elements/Help'
import InputNumber from 'elements/InputNumber'
import Select, { Option, getOptionProps } from 'elements/Select'
import Icon from 'elements/Icon'
import { Row, Col } from 'elements/Grid'

export const SettingsList = styled(List)`
  label {
    color: rgba(0, 0, 0, 0.65) !important;
    white-space: nowrap;
  }

  .ant-list-items {
    overflow: auto;

    @media screen and (min-width: 768px) {
      max-height: calc(100vh - 500px);
    }

    .ant-list-item {
      min-width: 480px;
      min-height: 57px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-input,
    .ant-input-number {
      margin-left: 8px;
      margin-right: 8px;
    }

    .ant-select {
      margin-left: 8px;
      margin-right: 8px;
      width: auto;
      min-width: 150px;
    }
  }
`

export const ListItem = styled(List.Item)`
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.45);

      * {
        color: rgba(0, 0, 0, 0.45) !important;
      }
    `}
`

const Level2 = styled.span`
  display: flex;
  margin-left: 24px;
  height: 32px;
  align-items: center;
`

const fieldOptions = {
  scanType: Object.entries(scanTypes),
  orderCalculationType: Object.entries(orderCalculationTypes),
  basePriceCalculation: Object.entries(basePriceCalculationTypes),
  receiveManagedItemsAs: Object.entries(inventoryTypes),
  receiveNonManagedItemsAs: Object.entries(inventoryTypes),
  issueFirstFrom: Object.entries(inventoryTypes),
  profilePriority: Object.entries(profilePriorityTypes),
  issueLimitConflictResolution: Object.entries(issueLimitConflictResolutionTypes),
  issueLimitExceptionAction: Object.entries(issueLimitExceptionActionTypes),
  receivingMode: Object.entries(receivingModes),
}

function Component(props) {
  const { values, onChange, readOnly, getSettings, getSuppliers } = props

  const [state, updateState] = useImmer({})
  const { fieldSettings, suppliers, filterBy = '' } = state

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  async function fetchSuppliers(search) {
    try {
      const response = await getSuppliers({
        search,
        supplierIds: values.priceOverrideSupplier ? [values.priceOverrideSupplier] : undefined,
      })

      setState('suppliers', response.value.data)
    } catch (error) {
      showError({ error })
    }
  }

  const searchSuppliers = useDebouncedCallback((search) => fetchSuppliers(search), DEBOUNCE)

  async function fetchSettings() {
    try {
      const response = await getSettings({ type: 'generalSettings' })

      setState('fieldSettings', response.value.data.fieldSettings)
    } catch (error) {
      showError({ error })
    }
  }

  React.useEffect(() => {
    fetchSettings()
    fetchSuppliers()
  }, [])

  function setValue(key, value) {
    onChange(
      produce(values, (draft) => {
        set(draft, key, value)
      })
    )
  }

  if (!fieldSettings) {
    return <Spin />
  }

  const defaultStartingPo = fieldSettings.find((one) => one.dtoFieldName === 'defaultStartingPo')
  const autoGeneratePoSequence = fieldSettings.find((one) => one.dtoFieldName === 'autoGeneratePoSequence')
  const issueItemsUsingBasePrice = fieldSettings.find(
    (one) => one.dtoFieldName === 'issueItemsUsingBasePrice'
  )
  const cycleCountExpiryDays = fieldSettings.find((one) => one.dtoFieldName === 'cycleCountExpiryDays')

  const dataSource = fieldSettings
    .filter((each) => each.displayByDefault) // NOTE: displayable !== displayByDefault
    .filter((each) => {
      if (isEmpty(filterBy)) {
        return true
      }

      let text = t(each.recordLabelLanguageKey)

      if (each.dtoFieldName === 'autoGeneratePo') {
        text += defaultStartingPo ? t(defaultStartingPo.recordLabelLanguageKey) : ''
        text += autoGeneratePoSequence ? t(autoGeneratePoSequence.recordLabelLanguageKey) : ''
      }

      if (each.dtoFieldName === 'lockIssuedPrice') {
        text += issueItemsUsingBasePrice ? t(issueItemsUsingBasePrice.recordLabelLanguageKey) : ''
      }

      return toLower(text).indexOf(toLower(filterBy)) > -1
    })

  return (
    <>
      <Row className="mb-18">
        <Col third>
          <Input.Search
            placeholder={t('search')}
            defaultValue={filterBy}
            onChange={(e) => setState('filterBy', e.target.value)}
            maxLength={200}
            allowClear
          />
        </Col>
      </Row>
      <SettingsList
        header={t('settings')}
        dataSource={dataSource}
        renderItem={({ dtoFieldName, recordLabelLanguageKey, recordInfoLanguageKey, isEnabled }) => {
          switch (dtoFieldName) {
            case 'autoGeneratePo':
              return (
                <ListItem disabled={!isEnabled}>
                  <Checkbox
                    checked={values[dtoFieldName]}
                    onChange={(e) => setValue(dtoFieldName, e.target.checked)}
                    readOnly={readOnly}
                    className="mb-12"
                    disabled={!isEnabled}
                  >
                    <span dangerouslySetInnerHTML={{ __html: t(recordLabelLanguageKey) }} />{' '}
                    {!isEmpty(recordInfoLanguageKey) && <Help title={t(recordInfoLanguageKey)} />}
                  </Checkbox>{' '}
                  {defaultStartingPo && (
                    <Level2>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t(defaultStartingPo.recordLabelLanguageKey),
                        }}
                      />
                      <Input
                        style={{ width: '150px' }}
                        readOnly={readOnly}
                        disabled={!isEnabled || !defaultStartingPo.isEnabled || !values[dtoFieldName]}
                        value={values.defaultStartingPo}
                        onChange={(e) => setValue('defaultStartingPo', e.target.value)}
                      />
                      {!isEmpty(defaultStartingPo.recordInfoLanguageKey) && (
                        <Help title={t(defaultStartingPo.recordInfoLanguageKey)} />
                      )}
                    </Level2>
                  )}{' '}
                  {autoGeneratePoSequence && (
                    <Level2>
                      <Checkbox
                        checked={values.autoGeneratePoSequence}
                        onChange={(e) => setValue('autoGeneratePoSequence', e.target.checked)}
                        readOnly={readOnly}
                        disabled={!isEnabled || !autoGeneratePoSequence.isEnabled || !values[dtoFieldName]}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t(autoGeneratePoSequence.recordLabelLanguageKey),
                          }}
                        />{' '}
                        {!isEmpty(autoGeneratePoSequence.recordInfoLanguageKey) && (
                          <Help title={t(autoGeneratePoSequence.recordInfoLanguageKey)} />
                        )}
                      </Checkbox>
                    </Level2>
                  )}
                </ListItem>
              )

            case 'lockIssuedPrice':
              return (
                <ListItem disabled={!isEnabled}>
                  <Checkbox
                    checked={values[dtoFieldName]}
                    onChange={(e) => setValue(dtoFieldName, e.target.checked)}
                    readOnly={readOnly}
                    className="mb-18"
                    disabled={!isEnabled}
                  >
                    <span dangerouslySetInnerHTML={{ __html: t(recordLabelLanguageKey) }} />{' '}
                    {!isEmpty(recordInfoLanguageKey) && <Help title={t(recordInfoLanguageKey)} />}
                  </Checkbox>{' '}
                  {issueItemsUsingBasePrice && (
                    <Level2>
                      <Checkbox
                        checked={values.issueItemsUsingBasePrice}
                        onChange={(e) => setValue('issueItemsUsingBasePrice', e.target.checked)}
                        readOnly={readOnly}
                        disabled={!isEnabled || !issueItemsUsingBasePrice.isEnabled || !values[dtoFieldName]}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t(issueItemsUsingBasePrice.recordLabelLanguageKey),
                          }}
                        />{' '}
                        {!isEmpty(issueItemsUsingBasePrice.recordInfoLanguageKey) && (
                          <Help title={t(issueItemsUsingBasePrice.recordInfoLanguageKey)} />
                        )}
                      </Checkbox>
                    </Level2>
                  )}
                </ListItem>
              )

            case 'poPrefix':
              return (
                <ListItem disabled={!isEnabled}>
                  <Row type="flex">
                    <Col className="pt-6">
                      <Icon type="Check" />
                    </Col>
                    <Col>
                      <span dangerouslySetInnerHTML={{ __html: t(recordLabelLanguageKey) }} />
                      <Input
                        value={values[dtoFieldName]}
                        onChange={(e) => setValue(dtoFieldName, e.target.value)}
                        readOnly={readOnly}
                        style={{ width: '150px' }}
                        disabled={!isEnabled}
                      />
                      {!isEmpty(recordInfoLanguageKey) && <Help title={t(recordInfoLanguageKey)} />}
                    </Col>
                  </Row>
                </ListItem>
              )

            case 'taxRate':
              return (
                <ListItem disabled={!isEnabled}>
                  <Row type="flex">
                    <Col className="pt-6">
                      <Icon type="Check" />
                    </Col>
                    <Col>
                      <span dangerouslySetInnerHTML={{ __html: t(recordLabelLanguageKey) }} />
                      <InputNumber
                        value={values[dtoFieldName]}
                        onChange={(e) => setValue(dtoFieldName, tryParseFloat(e, 0))}
                        readOnly={readOnly}
                        style={{ width: '80px' }}
                        disabled={!isEnabled}
                        allowSteppers={false}
                        precision={2}
                        min={0}
                      />
                      {!isEmpty(recordInfoLanguageKey) && <Help title={t(recordInfoLanguageKey)} />}
                    </Col>
                  </Row>
                </ListItem>
              )

            case 'scanType':
            case 'orderCalculationType':
            case 'basePriceCalculation':
            case 'receiveManagedItemsAs':
            case 'receiveNonManagedItemsAs':
            case 'issueFirstFrom':
            case 'profilePriority':
            case 'issueLimitConflictResolution':
            case 'issueLimitExceptionAction':
            case 'receivingMode':
              return (
                <ListItem disabled={!isEnabled}>
                  <Row type="flex">
                    <Col className="pt-6">
                      <Icon type="Check" />
                    </Col>
                    <Col>
                      <span dangerouslySetInnerHTML={{ __html: t(recordLabelLanguageKey) }} />
                      <Select
                        value={values[dtoFieldName]}
                        onChange={(e) => setValue(dtoFieldName, e)}
                        allowClear={false}
                        fullWidth={false}
                        readOnly={readOnly}
                        disabled={!isEnabled}
                      >
                        {fieldOptions[dtoFieldName].map(([key, value]) => (
                          <Option key={key} value={key}>
                            {t(value)}
                          </Option>
                        ))}
                      </Select>
                      {!isEmpty(recordInfoLanguageKey) && <Help title={t(recordInfoLanguageKey)} />}
                    </Col>
                  </Row>
                </ListItem>
              )

            case 'priceOverrideSupplier':
              return (
                <ListItem disabled={!isEnabled}>
                  <Row type="flex">
                    <Col className="pt-6">
                      <Icon type="Check" />
                    </Col>
                    <Col>
                      <span dangerouslySetInnerHTML={{ __html: t(recordLabelLanguageKey) }} />
                      <Select
                        onChange={(e) => setValue(dtoFieldName, e || 0)}
                        value={values[dtoFieldName]}
                        dropdownRender={createDropdownRender(suppliers)}
                        onSearch={searchSuppliers}
                        fullWidth={false}
                        readOnly={readOnly}
                        disabled={!isEnabled}
                        showSearch
                      >
                        <Option value={0}>{t('notAssigned')}</Option>
                        {(suppliers?.items ?? [])
                          .filter((each) => each.id)
                          .map((each) => (
                            <Option key={each.id} value={each.id}>
                              <span {...getOptionProps(each)}>{each.name}</span>
                            </Option>
                          ))}
                      </Select>
                      {!isEmpty(recordInfoLanguageKey) && <Help title={t(recordInfoLanguageKey)} />}
                    </Col>
                  </Row>
                </ListItem>
              )

            case 'deleteExpiredCycleCounts':
              return (
                <ListItem disabled={!isEnabled}>
                  <Checkbox
                    checked={values[dtoFieldName]}
                    onChange={(e) => setValue(dtoFieldName, e.target.checked)}
                    readOnly={readOnly}
                    disabled={!isEnabled}
                  >
                    <span dangerouslySetInnerHTML={{ __html: t(recordLabelLanguageKey) }} />
                    <InputNumber
                      value={values.cycleCountExpiryDays}
                      onChange={(e) => setValue('cycleCountExpiryDays', tryParseInt(e, 0))}
                      readOnly={readOnly}
                      disabled={!isEnabled || !cycleCountExpiryDays.isEnabled || !values[dtoFieldName]}
                      style={{ width: '75px' }}
                      min={0}
                    />
                    {t('days')} {!isEmpty(recordInfoLanguageKey) && <Help title={t(recordInfoLanguageKey)} />}
                  </Checkbox>
                </ListItem>
              )

            case 'showPackageQuantities':
              return (
                <ListItem disabled={!isEnabled}>
                  <Checkbox
                    checked={values[dtoFieldName]}
                    onChange={(e) => setValue(dtoFieldName, e.target.checked)}
                    readOnly={readOnly}
                    disabled={
                      !isEnabled ||
                      ['scanType', 'orderCalculationType'].some((one) => values[one] !== 'PerEach')
                    }
                  >
                    <span dangerouslySetInnerHTML={{ __html: t(recordLabelLanguageKey) }} />{' '}
                    {!isEmpty(recordInfoLanguageKey) && <Help title={t(recordInfoLanguageKey)} />}
                  </Checkbox>
                </ListItem>
              )

            default:
              return (
                <ListItem disabled={!isEnabled}>
                  {isBoolean(values[dtoFieldName]) ? (
                    <Checkbox
                      checked={values[dtoFieldName]}
                      onChange={(e) => setValue(dtoFieldName, e.target.checked)}
                      readOnly={readOnly}
                      disabled={!isEnabled}
                    >
                      <span dangerouslySetInnerHTML={{ __html: t(recordLabelLanguageKey) }} />{' '}
                      {!isEmpty(recordInfoLanguageKey) && <Help title={t(recordInfoLanguageKey)} />}
                    </Checkbox>
                  ) : (
                    <span>
                      {t(recordLabelLanguageKey)}: {values[dtoFieldName]}{' '}
                      {!isEmpty(recordInfoLanguageKey) && <Help title={t(recordInfoLanguageKey)} />}
                    </span>
                  )}
                </ListItem>
              )
          }
        }}
        bordered
      />
    </>
  )
}

export default Component
