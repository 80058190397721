import { notification } from 'antd'
import produce from 'immer'
import styled from 'styled-components'
import { isEmpty, debounce, camelCase } from 'lodash'
import { tryParseFloat, DEBOUNCE } from 'helpers/utils'
import { getChangedItems } from 'helpers/formViews'
import { t } from 'helpers/i18n'

export const getReceivedItems = (originals = [], items = []) => {
  const { creating, updating } = getChangedItems(originals, items)

  return [
    ...creating.filter((created) => created.quantityReceived + created.quantityReceivedDelta > 0),
    ...updating.filter((updated) => {
      const original = originals.find((one) => one.id === updated.id) ?? {
        quantityReceived: 0,
        quantityReceivedDelta: 0,
      }

      return (
        original.quantityReceived + original.quantityReceivedDelta <
        updated.quantityReceived + updated.quantityReceivedDelta
      )
    }),
  ]
}

export const recalculateQuantityOrdered = (each, customer) =>
  each.scanType === 'Package' || (each.scanType === 'Default' && customer.generalSettings.scanType === 'Package')
    ? each.quantityOrdered * each.packageSize
    : each.quantityOrdered

export const recalculateQuantity = (each, customer) =>
  each.scanType === 'Package' || (each.scanType === 'Default' && customer.generalSettings.scanType === 'Package')
    ? each.quantity * each.packageSize
    : each.quantity

export const createGeneratePurchaseOrderNumbers = (self) =>
  debounce(async () => {
    try {
      self.setState({ formButtonsDisabled: true })

      const purchaseOrderNumbers = await Promise.all(
        self.state.orderSupplierItems.map((each) =>
          each.purchaseOrderNumber
            ? Promise.resolve(each)
            : self.props
                .generatePurchaseOrderNumber({
                  orderId: each.orderId > 0 ? each.orderId : 0,
                  supplierId: each.supplierId,
                })
                .then((response) => ({
                  orderId: each.orderId > 0 ? each.orderId : 0,
                  supplierId: each.supplierId,
                  ...response.value.data,
                }))
        )
      )

      self.setState(
        produce((draft) => {
          draft.orderSupplierItems.forEach((each) => {
            if (each.id < 0 && isEmpty(each.purchaseOrderNumber)) {
              try {
                each.purchaseOrderNumber = purchaseOrderNumbers.find(
                  (one) => one.supplierId === each.supplierId
                ).purchaseOrderNumber
              } catch (error) {
                console.warn(error)
              }
            }
          })

          draft.purchaseOrderNumbers = purchaseOrderNumbers
        })
      )
    } catch (error) {
      console.warn(error)
    } finally {
      self.setState({ formButtonsDisabled: false })
    }
  }, DEBOUNCE)

export const calculateRequisitionTotals = ({ customer, item: requisition, requisitionItems }) => {
  const exemptTotal = requisitionItems
    .filter((each) => !each.taxable)
    .reduce((total, each) => total + each.quantity * each.price, 0)

  const taxableTotal = requisitionItems
    .filter((each) => each.taxable)
    .reduce((total, each) => total + each.quantity * each.price, 0)

  const taxTotal = customer.moduleSettings.enableTax ? taxableTotal * requisition.taxRate : 0

  return {
    exemptTotal,
    taxableTotal,
    taxTotal,
    requisitionTotal: exemptTotal + taxableTotal + taxTotal,
  }
}

export const calculateOrderTotals = ({ customer, item: order, orderItems, orderSupplierItems = [] }) => {
  const exemptTotal = orderItems
    .filter((each) => !each.taxable)
    .reduce((total, each) => total + each.quantityOrdered * each.price, 0)

  const taxableTotal = orderItems
    .filter((each) => each.taxable)
    .reduce((total, each) => total + each.quantityOrdered * each.price, 0)

  const taxTotal = customer.moduleSettings.enableTax ? taxableTotal * order.taxRate : 0
  const subTotal = exemptTotal + taxableTotal + taxTotal

  const adjustmentsTotal = orderSupplierItems.map((each) => each.adjustments).reduce((total, each) => total + each, 0)

  const freightTotal = orderSupplierItems.map((each) => each.freight).reduce((total, each) => total + each, 0)

  return {
    exemptTotal,
    taxableTotal,
    taxTotal,
    subTotal,
    adjustmentsTotal,
    freightTotal,
    orderTotal: subTotal + adjustmentsTotal + freightTotal,
  }
}

export const BadgeContainer = styled.div`
  display: inline-block;

  .ant-badge-dot,
  .ant-badge-count {
    background-color: #1890ff;
    font-size: 10px;
    width: 16px;
    min-width: 16px;
    line-height: 16px;
    height: 16px;
    padding: 0 3px;
    z-index: 2;
  }
`

export const getCustomerTagSettingItems = (tagSettings = {}) =>
  ['1', '2', '3', 'A', 'B', 'C', 'D', 'E'].map((id) => ({
    id,
    tag: `Tag ${id}`,
    label: tagSettings[`tag${id}`],
    enabled: tagSettings[`tag${id}Enabled`],
    enabledPurch: tagSettings[`tag${id}EnabledPurch`],
    enabledAssets: tagSettings[`tag${id}EnabledAssets`],
    required: tagSettings[`tag${id}Required`],
    restricted: tagSettings[`tag${id}Restricted`],
    showList: tagSettings[`tag${id}ShowList`],
  }))

export const getCustomerTagSettings = (tagSettingItems = []) =>
  tagSettingItems.reduce(
    (acc, each) => ({
      ...acc,
      [`tag${each.id}`]: each.label || `Tag ${each.id}`,
      [`tag${each.id}Enabled`]: each.enabled,
      [`tag${each.id}EnabledPurch`]: each.enabledPurch,
      [`tag${each.id}EnabledAssets`]: each.enabledAssets,
      [`tag${each.id}Required`]: each.required,
      [`tag${each.id}Restricted`]: each.restricted,
      [`tag${each.id}ShowList`]: each.showList,
    }),
    {}
  )

export const getUserTagSettingItems = (tagSettings = {}) =>
  ['1', '2', '3', 'A', 'B', 'C', 'D', 'E'].map((id) => ({
    id,
    tag: `tag${id}`,
    enabled: tagSettings[`tag${id}Enabled`],
    enabledPurch: tagSettings[`tag${id}EnabledPurch`],
    enabledAssets: tagSettings[`tag${id}EnabledAssets`],
  }))

export const getUserTagSettings = (tagSettingItems = []) =>
  tagSettingItems.reduce(
    (acc, each) => ({
      ...acc,
      [`tag${each.id}Enabled`]: each.enabled,
      [`tag${each.id}EnabledPurch`]: each.enabledPurch,
      [`tag${each.id}EnabledAssets`]: each.enabledAssets,
    }),
    {}
  )

export const validateQuantityReceived = debounce((quantityOrdered, value) => {
  if (value > quantityOrdered) {
    notification.close('errorQuantityReceived')
    notification.error({
      key: 'errorQuantityReceived',
      message: t('error'),
      description: t('errorQuantityReceived'),
    })
  }
}, DEBOUNCE)

export const validateQuantityOrdered = debounce((quantityReceived, value) => {
  if (value < quantityReceived) {
    notification.close('errorQuantityOrdered')
    notification.error({
      key: 'errorQuantityOrdered',
      message: t('error'),
      description: t('errorQuantityOrdered'),
    })
  }
}, DEBOUNCE)

export const validateQuantityIssued = debounce((quantity, value) => {
  if (value > quantity) {
    notification.close('errorQuantityIssued')
    notification.error({
      key: 'errorQuantityIssued',
      message: t('error'),
      description: t('errorQuantityIssued'),
    })
  }
}, DEBOUNCE)

export const validateQuantityRequisitioned = debounce((quantityIssued, value) => {
  if (value < quantityIssued) {
    notification.close('errorQuantityRequisitioned')
    notification.error({
      key: 'errorQuantityRequisitioned',
      message: t('error'),
      description: t('errorQuantityRequisitioned'),
    })
  }
}, DEBOUNCE)

export const createOrderItemsTransformSaveItemParams =
  (self) =>
  ({ params } = {}) => {
    const price = tryParseFloat(params.price, 0)
    const location = (self.props.locations ?? []).find((one) => one.id === params.locationId)
    const supplier = (self.props.suppliers?.items ?? []).find((one) => one.id === params.supplierId)
    const operator = (self.state?.operators?.items ?? []).find((one) => one.id === params.operatorId)
    const job = (self.state?.jobs?.items ?? []).find((one) => one.id === params.jobId)
    const asset = (self.state?.assets?.items ?? []).find((one) => one.id === params.assetId)

    return {
      price,
      jobName: job?.name ?? params?.jobDisplayName,
      operatorName: operator?.name ?? params?.operatorDisplayName,
      assetName: asset?.name ?? params?.assetDisplayName,
      assetBarcode: asset?.barcode ?? params?.assetBarcode,
      locationName: location?.name,
      locationBarcode: location?.barcode,
      supplierName: supplier?.name,
      operatorBarcode: operator?.barcode ?? params?.operatorBarcode,
      operatorNumber: operator?.number ?? params?.operatorNumber,
      jobBarcode: job?.barcode ?? params?.jobBarcode,
      jobNumber: job?.number ?? params?.jobNumber,
      locationDisplayName: undefined,
      jobDisplayName: undefined,
      operatorDisplayName: undefined,
      assetDisplayName: undefined,
      populated: false,
    }
  }

export const copyTagValuesToAllItemsClientSettings = {
  dtoFieldName: 'copyTagValuesToAllItems',
  columnHeadingLanguageKey: '',
  recordLabelLanguageKey: 'copyTagValuesToAllItems',
  linkTarget: 'none',
  linkTargetIsReadOnly: false,
  columnHeadingTooltipLanguageKey: '',
  columnHeadingIconName: '',
  columnWidth: 0,
  alignment: 'Left',
  defaultColumnNumber: -1,
  displayByDefault: false,
  displayFormat: 'Text',
  isDisplayable: false,
  isRequired: false,
  maxLength: 0,
  recordInfoLanguageKey: 'copyTagValuesToAllItemsInfo',
  searchEnumValue: -1,
  sortByEnumValue: -1,
  totalCalculationType: 'Sum',
  isExportable: false,
  totalColumnEnumValue: -1,
  isMovable: true,
  hideSuccessiveDuplicates: false,
  isEnabled: true,
  typeId: 'TSCore.Attributes.ClientFieldAttributes, TSCore, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null',
}

export const getTagFieldNames = () => [
  ...['id', 'barcode', 'displayName', 'name', 'number'].map((each) => camelCase(`job-${each}`)),
  ...['id', 'barcode', 'displayName', 'name', 'number'].map((each) => camelCase(`asset-${each}`)),
  ...['id', 'barcode', 'displayName', 'name', 'number'].map((each) => camelCase(`operator-${each}`)),
  'tagA',
  'tagB',
  'tagC',
  'tagD',
  'tagD',
  'tagE',
]
