import * as React from 'react'
import { Form } from 'antd'
import { get, debounce } from 'lodash'
import { showError } from 'helpers/errors'
import formStatusOptions from 'options/forms/formStatusOptions'
import formStatusCompletionOptions from 'options/forms/formStatusCompletionOptions'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import { Row, Col } from 'elements/Grid'
import {
  createDropdownRender,
  createSearchCustomerGroups,
  createSearchFormBatches,
  createSearchFormTemplates,
} from 'helpers/formViews'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { DEBOUNCE } from 'helpers/utils'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchCustomerGroups = createSearchCustomerGroups(this)
    this.searchFormBatches = createSearchFormBatches(this)
    this.searchFormTemplates = createSearchFormTemplates(this)
    this.searchCustomersByNumber = debounce(this.searchCustomersByNumber, DEBOUNCE)
  }

  async componentDidMount() {
    try {
      const { customerGroupId, customerId, formTemplateIds = [], formBatchIds = [] } = this.props.filterDto
      const responses = await Promise.all([
        this.props.getCustomerNumberSelectionList({ customerIds: customerId ? [customerId] : [] }),
        this.props.getFormBatches({ formBatchIds }),
        this.props.getFormTemplates({ formTemplateIds }),
        this.props.getCustomerGroups({ customerGroupIds: customerGroupId ? [customerGroupId] : [] }),
      ])

      this.setState({ customerNumberSelectionList: get(responses[0], 'value.data', {}) })
    } catch (error) {
      showError({ error })
    }
  }

  searchCustomersByNumber = async (search) => {
    try {
      const { customerId, customerIds = [] } = this.props.filterDto
      const response = await this.props.getCustomerNumberSelectionList({
        search,
        customerIds: customerId ? [customerId] : customerIds,
      })

      this.setState({ customerNumberSelectionList: get(response, 'value.data', {}) })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto, formBatches, formTemplates, customerGroups } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker filterDto={filterDto} setFilterValue={this.setFilterValue} />
            <Form.Item label={t('region')}>
              <Select
                onChange={(value) => this.setFilterValue('customerGroupId', value)}
                placeholder={t('all')}
                value={filterDto.customerGroupId}
                dropdownRender={createDropdownRender(customerGroups)}
                onSearch={this.searchCustomerGroups}
              >
                {(customerGroups?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.description}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('branch')}>
              <Select
                onChange={(value) => this.setFilterValue('customerId', value)}
                placeholder={t('all')}
                value={filterDto.customerId}
                dropdownRender={createDropdownRender(this.state.customerNumberSelectionList)}
                onSearch={this.searchCustomersByNumber}
              >
                {(this.state.customerNumberSelectionList?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('formBatch')}>
              <Select
                onChange={(value) => this.setFilterValue('formBatchIds', value)}
                placeholder={t('all')}
                mode="multiple"
                value={filterDto.formBatchIds}
                dropdownRender={createDropdownRender(formBatches)}
                onSearch={this.searchFormBatches}
              >
                {(formBatches?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('template')}>
              <Select
                onChange={(value) => this.setFilterValue('formTemplateIds', value)}
                placeholder={t('all')}
                mode="multiple"
                value={filterDto.formTemplateIds}
                dropdownRender={createDropdownRender(formTemplates)}
                onSearch={this.searchFormTemplates}
              >
                {(formTemplates?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('deficiency')}>
              <Select
                onChange={(value) => this.setFilterValue('deficiency', value)}
                placeholder={t('all')}
                value={filterDto.deficiency}
              >
                {Object.entries(formStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('reviewed')}>
              <Select
                onChange={(value) => this.setFilterValue('reviewed', value)}
                placeholder={t('all')}
                value={filterDto.reviewed}
              >
                {Object.entries(formStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('corrected')}>
              <Select
                onChange={(value) => this.setFilterValue('corrected', value)}
                placeholder={t('all')}
                value={filterDto.corrected}
              >
                {Object.entries(formStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) => this.setFilterValue('status', value)}
                placeholder={t('all')}
                value={filterDto.status}
              >
                {Object.entries(formStatusCompletionOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
