import { Input } from 'antd'
import cx from 'clsx'
import entityNames from 'options/entityNames'
import { createChildListPrintComponent } from 'factories/ChildListPrint'
import { DARK_GREEN } from 'options/colors'
import { getUserPermission } from 'helpers/auth'
import Icon from 'elements/Icon'

export default createChildListPrintComponent({
  entityName: entityNames.requisitions,
  childName: 'requisitionItems',
  tableCellComponents: {
    quantity: (self, item) => <Input value={item.quantity} readOnly />,
    quantityIssued: (self, item) => {
      const quantityIssuedEnabled =
        self.props.parentRecord?.approved === true &&
        ['Open', 'Closed'].includes(self.props.parentRecord?.status) &&
        getUserPermission('Issue') === 'Yes'

      return (
        <div className="whitespace-nowrap pr-12">
          <Input
            value={item.quantityIssued}
            className={cx({
              'form-field-success':
                item.quantityIssued === item.quantity ||
                (self.props.customer.generalSettings.allowOverIssueFromRequisition &&
                  item.quantityIssued > item.quantity),
              'form-field-error':
                !self.props.customer.generalSettings.allowOverIssueFromRequisition &&
                item.quantityIssued > item.quantity,
            })}
            disabled={!quantityIssuedEnabled}
            min={0}
            readOnly
          />
        </div>
      )
    },
    itemOrdered: (self, item) => (item.itemOrdered ? <Icon type="Done" color={DARK_GREEN} size={20} /> : null),
  },
})()
