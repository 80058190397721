import * as React from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'
import { uniq, set, isNil, isEmpty } from 'lodash'
import { Form as AntForm, Input, Collapse, Button } from 'antd'
import { t } from 'helpers/i18n'
import { Emitter, stopEvent } from 'helpers/events'
import { normalizeFilterSettings } from 'helpers/dashboards'
import { DASHBOARD_FILTER_SAVING } from 'options/events'
import Dates from 'containers/DashboardViewer/Filter/Dates'
import Assets from 'containers/DashboardViewer/Filter/Assets'
import Jobs from 'containers/DashboardViewer/Filter/Jobs'
import Locations from 'containers/DashboardViewer/Filter/Locations'
import People from 'containers/DashboardViewer/Filter/People'
import Products from 'containers/DashboardViewer/Filter/Products'
import Suppliers from 'containers/DashboardViewer/Filter/Suppliers'
import DataSelection from 'containers/DashboardViewer/Filter/DataSelection'

const filterTypes = {
  Dates,
  Assets,
  Jobs,
  Locations,
  People,
  Products,
  Suppliers,
  DataSelection,
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
}

const Form = styled(AntForm)`
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 4px 0;

    .ant-form-item {
      margin: 0;
      padding: 4px 8px;

      .ant-form-item-label {
        padding: 8px 0 !important;
      }
    }
  }
`

function Component(props) {
  const { template, onCancel, onUpdate, readOnly } = props
  const filterSettings = normalizeFilterSettings(template?.filterSettings ?? [])
  const categoryTypes = uniq(filterSettings.map((each) => each.categoryType))

  const [state, updateState] = useImmer({ filterDto: props.filterDto ?? {} })

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  function handleSubmit(e) {
    stopEvent(e)

    if (!readOnly) {
      onUpdate(state.filterDto)
    }
  }

  function setFilterValue(path, value) {
    updateState((draft) => {
      if (!isNil(value)) {
        set(draft.filterDto, path, value)
      } else {
        set(draft.filterDto, path, undefined)
      }
    })
  }

  function handleFilterSaving(value) {
    setState('filterSaving', value)
  }

  React.useEffect(() => {
    Emitter.on(DASHBOARD_FILTER_SAVING, handleFilterSaving)

    return () => Emitter.off(DASHBOARD_FILTER_SAVING, handleFilterSaving)
  }, [])

  return (
    <Form layout="vertical" colon={false} onSubmit={handleSubmit} {...formItemLayout}>
      <div className="form-items-container">
        {!isEmpty(categoryTypes) && (
          <Collapse defaultActiveKey={categoryTypes}>
            {categoryTypes.map((categoryType) => {
              const header = filterSettings.find((one) => one.categoryType === categoryType).categoryName
              const fields = filterSettings.filter((one) => one.categoryType === categoryType)
              const Filter = filterTypes[categoryType]

              return (
                <Collapse.Panel key={categoryType} header={header || t('na')}>
                  {Filter ? (
                    <Filter
                      fields={fields}
                      filterDto={state.filterDto}
                      setFilterValue={setFilterValue}
                      primaryMetricType={template?.primaryMetricType}
                      readOnly={readOnly}
                    />
                  ) : (
                    fields.map((field) => (
                      <Form.Item key={field.type} label={field.name}>
                        <Input disabled />
                      </Form.Item>
                    ))
                  )}
                </Collapse.Panel>
              )
            })}
          </Collapse>
        )}
      </div>
      <div className="form-buttons-container">
        {readOnly ? (
          <Button onClick={onCancel}>{t('close')}</Button>
        ) : (
          <>
            <Button onClick={onCancel}>{t('cancel')}</Button>{' '}
            <Button type="primary" htmlType="submit" loading={state.filterSaving}>
              {t('update')}
            </Button>
          </>
        )}
      </div>
    </Form>
  )
}

export default Component
