import produce from 'immer'
import { set } from 'lodash'

export const TRANSLATIONS_GET = 'TRANSLATIONS_GET'

export default (
  state = {
    locales: [
      { code: 'en', iso2: 'en', name: 'English', nativeName: 'English', isDefault: true },
      { code: 'fr', iso2: 'fr', name: 'French', nativeName: 'Français', isDefault: false },
      { code: 'es', iso2: 'es', name: 'Spanish', nativeName: 'Español', isDefault: false },
    ],
  },
  action
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case `${TRANSLATIONS_GET}_FULFILLED`:
        draft.translations = action.payload.data
        set(window, 'i18n.translations', draft.translations)
        break

      default:
        break
    }
  })
