import { snakeCase } from 'lodash'
import { createGet } from 'helpers/api'

const entityName = 'admin'
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  requestTimeout: (params) => ({
    type: `${ENTITY_NAME}_REQUEST_TIMEOUT`,
    payload: createGet(entityName, { action: 'timeout' })(params),
  }),
}
