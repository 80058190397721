import { connect } from 'react-redux'
import selectors from 'selectors'
import assetCategoryActions from 'actions/assetCategories'
import locationActions from 'actions/locations'
import Component from 'components/Assets/Select/Filter'

const mapStateToProps = (state, props) => ({
  assetCategories: selectors.assetCategories.selectOptions(state),
  locations: selectors.locations.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getAssetCategories: (params) => dispatch(assetCategoryActions.getOptions(params)),
  getLocations: (params) =>
    dispatch(locationActions.getOptions({ ...params, active: 'All', locationVendingTypes: ['NonVending'] })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
