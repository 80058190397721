import produce from 'immer'
import { snakeCase } from 'lodash'
import { createActions, normalizeDateRange } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.tickets
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        normalizeDateRange(draft, 'Updated')
      }),
  }),

  sendCreateNotification: (ticketId, params) => ({
    type: `${ENTITY_NAME}_SEND_CREATE_NOTIFICATION`,
    payload: createPost(entityName, { action: `${ticketId}/sendCreateNotification` })(params),
  }),

  sendUpdateNotification: (ticketId, params) => ({
    type: `${ENTITY_NAME}_SEND_UPDATE_NOTIFICATION`,
    payload: createPost(entityName, { action: `${ticketId}/sendUpdateNotification` })(params),
  }),

  createComment: (ticketId, params) => ({
    type: `${ENTITY_NAME}_COMMENTS_CREATE`,
    payload: createPost(entityName, { action: `${ticketId}/comment` })(params),
  }),
}
