import { partition, sum } from 'lodash'
import { createCalendarViewComponent } from 'factories/CalendarView'
import { t, toLocaleNumber, toLocaleCurrency } from 'helpers/i18n'
import { RED, YELLOW, DARK_GREEN } from 'options/colors'
import { getUserPermission } from 'helpers/auth'
import entityNames from 'options/entityNames'
import Filter from 'containers/Jobs/CalendarView/Filter'

export default createCalendarViewComponent({
  entityName: entityNames.jobs,
  filterTemplateType: 'JobCalendar',
  createButtonTextId: 'createJob',
  primaryLinkTarget: 'jobRecord',
  getSettingsType: (self) => 'JobCalendarTiles',
  getSummarySettingsType: (self) => 'JobCalendarSummary',
  getDateField: (self) => {
    switch (self.state.filterDto?.dateRangeField) {
      case 'CompletionDate':
        return 'completionDate'

      case 'DueDate':
        return 'dueDate'

      default:
        return 'createdDate'
    }
  },
  getTileTitle: (self) => (item) => (item.isScheduledJob ? t('scheduled') : ''),
  getSummaryTitle: (self) => (item) => t('jobSummary'),
  allowDelete: (self) => (item) => getUserPermission('Jobs') === 'All' && !item.isScheduledJob,
  initialFilterDto: (self) => ({
    active: 'Active',
    approvalStatus: 'All',
    jobStatusOptionId: -1,
    jobsToInclude: 'ScheduledJobsOnly',
    overdueStatus: 'All',
    operatorCapacityBasedOn: 'DueDateOnly',
    dateRangeField: 'DueDate',
  }),
  getTotalValues: (self) => {
    const totalableItems = self.getTotalableItems()
    const [scheduledJobs, createdJobs] = partition(totalableItems, (each) => each.isScheduledJob)
    const budgetHours = totalableItems.filter((each) => each.budgetHours !== 0)
    const capacityHours = totalableItems.filter((each) => each.operatorCapacityHours !== 0)
    const labourHours = totalableItems.filter((each) => each.labourHours !== 0)
    const partCost = totalableItems.filter((each) => each.partCost !== 0)
    const labourCost = totalableItems.filter((each) => each.labourCost !== 0)
    const totalCost = totalableItems.filter((each) => each.totalCost !== 0)

    return [
      {
        key: 'createdJobs',
        title: t('createdJobs'),
        value: toLocaleNumber(createdJobs.length),
        ids: createdJobs.map((each) => each.id),
      },
      {
        key: 'scheduledJobs',
        title: t('scheduledJobs'),
        value: toLocaleNumber(scheduledJobs.length),
        ids: scheduledJobs.map((each) => each.id),
      },
      {
        key: 'budgetHours',
        title: t('budgetHours'),
        value: toLocaleNumber(sum(budgetHours.map((each) => each.budgetHours))),
        ids: budgetHours.map((each) => each.id),
      },
      {
        key: 'capacityHours',
        title: t('capacityHours'),
        value: (
          <span style={{ color: RED }}>
            {toLocaleNumber(sum(capacityHours.map((each) => each.operatorCapacityHours)))}
          </span>
        ),
        ids: capacityHours.map((each) => each.id),
      },
      {
        key: 'labourHours',
        title: t('labourHours'),
        value: (
          <span style={{ color: DARK_GREEN }}>
            {toLocaleNumber(sum(labourHours.map((each) => each.labourHours)))}
          </span>
        ),
        ids: labourHours.map((each) => each.id),
      },
      {
        key: 'partsCost',
        title: t('partsCost'),
        value: toLocaleCurrency(sum(partCost.map((each) => each.partCost))),
        ids: partCost.map((each) => each.id),
      },
      {
        key: 'labourCost',
        title: t('labourCost'),
        value: toLocaleCurrency(sum(labourCost.map((each) => each.labourCost))),
        ids: labourCost.map((each) => each.id),
      },
      {
        key: 'totalCost',
        title: t('totalCost'),
        value: toLocaleCurrency(sum(totalCost.map((each) => each.totalCost))),
        ids: totalCost.map((each) => each.id),
      },
    ]
  },
  getCategorizationFields: (self) => [
    { dtoFieldName: 'locationName' },
    { dtoFieldName: 'jobGroupDescriptions', isArray: true },
    { dtoFieldName: 'reasonDescription' },
    { dtoFieldName: 'assetCategoryName' },
    { dtoFieldName: 'assetDescription' },
    { dtoFieldName: 'createdBy' },
    { dtoFieldName: 'assignedToName' },
    { dtoFieldName: 'activeDescription' },
    { dtoFieldName: 'statusDescription' },
    {
      dtoFieldName: 'approvalStatusDescription',
      options: [
        { value: t('none'), color: RED },
        { value: t('begin'), color: YELLOW },
        { value: t('close'), color: DARK_GREEN },
      ],
    },
    { dtoFieldName: 'priority' },
    {
      dtoFieldName: 'overdueStatusDescription',
      options: [
        { value: t('no'), color: DARK_GREEN },
        { value: t('yes'), color: RED },
      ],
    },
    { dtoFieldName: 'assetIsCriticalDescription' },
    {
      dtoFieldName: 'capacityHoursLessThanBudgetHoursDescription',
      options: [
        { value: t('yes'), color: RED },
        { value: t('no'), color: DARK_GREEN },
      ],
    },
    {
      dtoFieldName: 'labourHoursGreaterThanBudgetHoursDescription',
      options: [
        { value: t('yes'), color: RED },
        { value: t('no'), color: DARK_GREEN },
      ],
    },
  ],
})(Filter)
