import { Button } from 'antd'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import Operators from 'containers/IssueLimitLists/Operators'
import Filter from 'containers/IssueLimitLists/Filter'

export default createListViewComponent({
  entityName: entityNames.issueLimitLists,
  filterTemplateType: 'IssueLimitList',
  createButtonTextId: 'createIssueLimitList',
  primaryLinkTarget: 'issueLimitRecord',
  getSettingsType: (self) => 'issueLimitLists',
  extraRowActions: (self) => {
    const selectedItem = self.state.items.find((one) => one.id === self.state.selectedRowKeys[0])
    return (
      <>
        <Button
          onClick={() => self.setState({ operatorsVisible: true })}
          disabled={self.state.selectedRowKeys.length !== 1}
        >
          <Icon type="AssignmentInd" />
          {t('operators')}
        </Button>
        <Modal
          title={t('operatorsUsingLimitList')}
          visible={self.state.operatorsVisible}
          onCancel={() => self.setState({ operatorsVisible: false, selectedRowKeys: [] })}
          footer={
            <Button onClick={() => self.setState({ operatorsVisible: false, selectedRowKeys: [] })}>
              {t('close')}
            </Button>
          }
          width={992}
        >
          <Operators
            filterProps={{
              issueLimitListId: selectedItem?.id,
              issueLimitListName: selectedItem?.name,
              locationName: selectedItem?.locationName,
            }}
            onCreate={() => self.fetchItems({ resetSelectedRowKeysOnFetchItems: false })}
            onDelete={() => self.fetchItems({ resetSelectedRowKeysOnFetchItems: false })}
            mode="multiple"
            allowDelete
          />
        </Modal>
      </>
    )
  },
})(Filter)
