import { Popover } from 'antd'
import { isEmpty } from 'lodash'
import { DARK_GREEN } from 'options/colors'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'

export const tableCellComponents = {
  quantityOnHand: (self, item) =>
    item.quantityOnHand > 0 ? (
      <>
        <span className="mr-6">{item.quantityOnHand}</span>
        <Popover
          title={t('onhand')}
          content={<span dangerouslySetInnerHTML={{ __html: item.onHandInfo }} />}
          placement="topRight"
        >
          <span
            className="align-middle"
            style={{ visibility: isEmpty(item.onHandInfo) ? 'hidden' : 'visible' }}
          >
            <Icon type="Search" />
          </span>
        </Popover>
      </>
    ) : (
      ''
    ),
  quantityOnOrder: (self, item) =>
    item.quantityOnOrder > 0 ? (
      <>
        <span className="mr-6">{item.quantityOnOrder}</span>
        <Popover
          title={t('onOrder')}
          content={<span dangerouslySetInnerHTML={{ __html: item.onOrderInfo }} />}
          placement="topRight"
        >
          <span
            className="align-middle"
            style={{ visibility: isEmpty(item.onOrderInfo) ? 'hidden' : 'visible' }}
          >
            <Icon type="Search" />
          </span>
        </Popover>
      </>
    ) : (
      ''
    ),
  itemOrdered: (self, item) => (item.itemOrdered ? <Icon type="Done" color={DARK_GREEN} size={20} /> : null),
}
