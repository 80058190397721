import loginDefault from 'images/login.png'
import loginOpticrib from 'images/login.opticrib.png'

export const getTheme = () =>
  window.location.hostname.toLowerCase().startsWith('opticrib')
    ? {
        login: {
          background: loginOpticrib,
          primaryColor: '#009d6c',
          formPosition: 'calc(50% + 280px)',
        },
        welcome: { primaryColor: '#00b37a', secondaryColor: '#009969' },
      }
    : {
        login: {
          background: loginDefault,
          primaryColor: '#1890ff',
          formPosition: 'calc(50% + 120px)',
        },
        welcome: { primaryColor: '#0084ff', secondaryColor: '#0069cc' },
      }
