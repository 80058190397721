import * as React from 'react'
import { Row as AntRow, Col as AntCol } from 'antd'

const ForwardedRow = ({ forwardedRef, ...rest }) => <AntRow ref={forwardedRef} gutter={12} {...rest} />

export const Row = React.forwardRef((props, ref) => <ForwardedRow {...props} forwardedRef={ref} />)

const ForwardedCol = ({ forwardedRef, filter, half, fourth, third, twoThirds, ...rest }) => (
  <AntCol
    ref={forwardedRef}
    {...(half
      ? { xs: 24, sm: 12 }
      : fourth
        ? { xs: 24, md: 6 }
        : third
          ? { xs: 24, sm: 8 }
          : twoThirds
            ? { xs: 24, sm: 16 }
            : {})}
    {...rest}
  />
)

export const Col = React.forwardRef((props, ref) => <ForwardedCol {...props} forwardedRef={ref} />)
