import { createListModalComponent } from 'factories/ListModal'
import Filter from 'containers/Operators/Select/Filter'

export default createListModalComponent({
  getStorageKey: (self) => 'selectOperator',
  getSettingsType: (self) => 'selectOperator',
  initialFilterDto: (self) => ({
    active: 'Active',
  }),
})(Filter)
