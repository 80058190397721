import * as React from 'react'
import { Spin, Form, Input, Dropdown, Menu, Button, Tabs, message } from 'antd'
import { useImmer } from 'use-immer'
import styled from 'styled-components'
import { showError } from 'helpers/errors'
import { createLabelFactory } from 'helpers/formViews'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { Row, Col } from 'elements/Grid'
import { formatDateTime } from 'helpers/dateTime'
import { createPrintHandler, resetPrintHandler } from 'helpers/print'
import Icon from 'elements/Icon'
import FormButtons from 'elements/FormButtons'
import Modal from 'elements/Modal'
import AssociatedAssets from 'containers/ProcessSetupSheet/FormView/AssociatedAssets'
import AssociatedProducts from 'containers/ProcessSetupSheet/FormView/AssociatedProducts'
import Print from 'containers/ProcessSetupSheet/Print'

const AssociatedProductsWrapper = styled.div`
  .ant-table-body {
    table {
      border-collapse: collapse;

      tr.tofino-associated-products-sequence-separator {
        border-bottom: solid #fafafa 55px;
      }
    }
  }
`

function Component(props) {
  const { assetId: itemId } = props

  const [state, updateState] = useImmer({})

  const printableRef = React.useRef()

  function handleActionsMenuClick({ key }) {
    switch (key) {
      case 'print':
        updateState((draft) => {
          draft.printVisible = true
        })
        break

      default:
        message.warn(t('underDevelopment'))
        break
    }
  }

  React.useEffect(() => {
    async function fetchSettings() {
      try {
        const responses = await Promise.all([
          props.getItem(itemId),
          props.getSettings({ type: props.settingsType }),
          props.getListItemsSettings({ type: 'assetListProcessSetupSheet' }),
          props.getListItemsSettings({ type: 'productListProcessSetupSheet' }),
          props.getCombinedListForEntity({ pageIndex: 0, listType: 'Asset', entityId: itemId }),
          props.getCombinedListForEntity({ pageIndex: 0, listType: 'Product', entityId: itemId }),
        ])

        updateState((draft) => {
          draft.item = responses[0].value.data
          draft.fieldSettings = responses[1].value.data.fieldSettings
          draft.assetListSettings = responses[2].value.data.fieldSettings
          draft.productListSettings = responses[3].value.data.fieldSettings
          draft.assetList = responses[4].value.data
          draft.productList = responses[5].value.data
        })
      } catch (error) {
        showError({ error })
      }
    }

    fetchSettings()
  }, [])

  const { fieldSettings, item } = state

  if (!item || !fieldSettings) {
    return <Spin />
  }

  const createLabel = createLabelFactory(fieldSettings)

  // console.log({ props, state })

  return (
    <>
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Dropdown
          overlay={
            <Menu onClick={handleActionsMenuClick}>
              <Menu.Item key="print">{t('print')}</Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Button className="form-items-actions">
            {t('actions')}
            <Icon type="KeyboardArrowDown" />
          </Button>
        </Dropdown>
        <div className="form-items-container">
          <Row>
            <Col third>
              <Form.Item label={createLabel('barcode')}>
                <Input value={item.barcode} disabled />
              </Form.Item>
            </Col>
            <Col third>
              <Form.Item label={createLabel('itemNumber')}>
                <Input value={item.itemNumber} disabled />
              </Form.Item>
            </Col>
            <Col third>
              <Form.Item label={createLabel('locationName')}>
                <Input value={item.locationName} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col twoThirds>
              <Form.Item label={createLabel('description')}>
                <Input value={item.description} disabled />
              </Form.Item>
            </Col>
            <Col third>
              <Form.Item label={createLabel('revisionDate')}>
                <Input value={formatDateTime(item.revisionDate)} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Tabs>
            <Tabs.TabPane key="associatedAssets" tab={t('associatedAssets')} forceRender>
              <AssociatedAssets fieldSettings={state.assetListSettings} items={state.assetList.items} />
            </Tabs.TabPane>
            <Tabs.TabPane key="associatedProducts" tab={t('associatedProducts')} forceRender>
              <AssociatedProductsWrapper>
                <AssociatedProducts
                  fieldSettings={state.productListSettings}
                  items={state.productList.items}
                  totalValue={state.productList.totalValue}
                />
              </AssociatedProductsWrapper>
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className="form-buttons-container">
          <FormButtons onCancel={props.onCancel} readOnly />
        </div>
      </Form>
      <Modal
        title={t('printPreview')}
        okText={t('print')}
        onOk={createPrintHandler(printableRef)}
        onCancel={() => {
          updateState((draft) => {
            draft.printVisible = false
          })
          resetPrintHandler()
        }}
        width={992}
        visible={state.printVisible}
      >
        <div ref={printableRef} className="tofino-print-preview">
          <Print assetId={props.assetId} />
        </div>
      </Modal>
    </>
  )
}

export default Component
