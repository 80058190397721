import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import linkTargets from 'options/linkTargets'
import Filter from 'containers/SupplierIntegration/Filter'

const editDrawerLinkTarget = linkTargets.supplierIntegrationRecord

export default createListViewComponent({
  entityName: entityNames.supplierIntegration,
  filterTemplateType: 'SupplierIntegration',
  createButtonTextId: 'createXmlRecipient',
  primaryLinkTarget: 'supplierIntegrationRecord',
  allowSearching: (self) => false,
  allowCreate: (self) => true,
  deleteDisabled: (self) =>
    self.state.selectedRowKeys.length !== 1 ||
    self.props.items.find((one) => one.id === self.state.selectedRowKeys[0])?.isFromMaster,
  allowSelection: (self) => true,
  allowDeactivate: (self) => false,
  initialFilterDto: (self) => ({ integrationType: 'All' }),
  tableCellComponents: {
    name: (self, item) => {
      const { name, supplierIntegrationType, supplierIntegrationId, isFromMaster } = item

      if (supplierIntegrationType === 'SupplierPortal') {
        return name
      }

      return (
        <a
          onClick={() =>
            self.setState({
              editDrawerLinkTarget,
              editDrawerLinkTargetText: name,
              editDrawerLinkTargetRecord: { supplierIntegrationId, isFromMaster },
              editDrawerVisible: true,
              editDrawerSaving: false,
              editDrawerLinkTargetReadOnly: isFromMaster,
            })
          }
        >
          {name}
        </a>
      )
    },
  },
})(Filter)
