import * as React from 'react'
import styled from 'styled-components'
import { Form, Dropdown, Menu, Button, Spin, Input, Card, Table, message } from 'antd'
import { isEmpty, isNil, set, get, uniq, pick } from 'lodash'
import produce from 'immer'
import cx from 'clsx'
import {
  createHandleCustomizeOk,
  showTotal,
  pageSizeOptions,
  getTableWidth,
  createHandleColumnResize,
  createChildListViewHandleTableChange,
} from 'helpers/listViews'
import { setStorageItem, getStorageItem } from 'helpers/localStorage'
import { createResetState, tryParseInt, tryParseQueryString } from 'helpers/utils'
import { getStatus, getColumns, calculateUnissuedQuantity } from 'helpers/pickList'
import { showError, showClientNotifications, showValidationError } from 'helpers/errors'
import linkTargets from 'options/linkTargets'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import { getPageTitle } from 'helpers/auth'
import InputNumber from 'elements/InputNumber'
import Select, { Option, getOptionProps } from 'elements/Select'
import ResizableCell from 'elements/ResizableCell'
import Drawer from 'elements/Drawer'
import Page from 'elements/Page'
import Icon from 'elements/Icon'
import Customize from 'elements/Customize'
import { Row, Col } from 'elements/Grid'
import UnderDevelopment from 'elements/UnderDevelopment'

const getStorageKey = () => 'inventory.issueFromPickList'

const FormContainer = styled(Card)`
  margin-bottom: 24px;

  .ant-card-body {
    padding: 24px 12px;
  }
`

const TableContainer = styled(Col)`
  overflow: auto;

  @media screen and (min-width: 768px) {
    height: calc(100vh - 175px);
  }
`

class Component extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.barcodeRef = React.createRef()
    this.quantityRef = React.createRef()

    this.handleCustomizeOk = createHandleCustomizeOk(this)
    this.handleColumnResize = createHandleColumnResize(this)
    this.handleTableChange = createChildListViewHandleTableChange(this)
    this.resetState = createResetState(
      this,
      {
        selectedColumnKeys: [],
        sortedColumnKeys: [],
        columnWidths: {},
      },
      getStorageItem(getStorageKey(), {}),
      tryParseQueryString(window.location.search) ?? {}
    )
  }

  async componentDidMount() {
    await this.resetState()
    await this.fetchSettings()

    const itemId = tryParseInt(tryParseQueryString(window.location.search).requisitionId)

    if (itemId) {
      this.setState({ itemId }, () => this.fetchItem({ itemId }))
    }
  }

  promiseState = (state = {}) => new Promise((resolve) => this.setState(state, resolve))

  fetchSettings = async () => {
    try {
      const responses = await Promise.all([
        this.props.getSettings({ type: 'issuePickList' }),
        this.props.getLocations({ locationVendingTypes: ['NonVending'] }),
      ])

      this.setState({
        fieldSettings: responses[0].value.data.fieldSettings,
        locations: get(responses[1], 'value.data.items', []),
      })

      return responses
    } catch (error) {
      showError({ error })

      return error
    }
  }

  fetchItem = async ({ itemId = this.state.itemId } = {}) => {
    try {
      this.setState({ loading: true })

      const item = await this.props.getItem(itemId).catch((error) => {
        this.props.onCancel?.()
        throw error
      })
      const response = await this.props.getChildItems(item.value.data.id, {
        includeOnHandCounts: true,
      })
      const childItems = response.value.data.items
      const childItemLocationIds = uniq(childItems.map((each) => each.locationId))

      this.setState(
        produce((draft) => {
          draft.filteredLocations = draft.locations.filter((each) => childItemLocationIds.includes(each.id))
          draft.locationId = draft.filteredLocations.map((each) => each.id).includes(draft.locationId)
            ? draft.locationId
            : get(draft.filteredLocations, '[0].id')
          draft.item = { ...item.value.data, requisitionId: itemId }
          draft.childItems = childItems
          draft.pageIndex = 1

          if (draft.barcode && draft.locationId) {
            draft.quantity = calculateUnissuedQuantity({
              customer: this.props.customer,
              childItems,
              barcode: draft.barcode,
              locationId: draft.locationId,
            })
          }
        }),
        () => this.props.form.setFieldsValue(this.state)
      )
    } catch (error) {
      showError({ error })
    } finally {
      this.setState({ loading: false }, this.saveState)
    }
  }

  saveState = () =>
    setStorageItem(
      getStorageKey(),
      pick(this.state, [
        'columnWidths',
        'pageSize',
        'selectedColumnKeys',
        'sortBy',
        'sortOrder',
        'sortedColumnKeys',
      ])
    )

  handleActionsMenuClick = ({ key }) => {
    switch (key) {
      case 'customizeColumns':
        this.setState({ customizeColumnsVisible: true })
        break

      default:
        message.warn(t('underDevelopment'))
        break
    }
  }

  handlePressEnter = (e) => {
    stopEvent(e)

    this.props.form.validateFields(['itemId'], (errors, values) => {
      if (isEmpty(errors)) {
        this.fetchItem(values)
      } else {
        showValidationError()
      }
    })
  }

  handleKeyPress = (e) => {
    if (e.key.length === 1 && /\D/.test(e.key)) {
      stopEvent(e)
    }
  }

  setFormValue = (name, value) => {
    const { childItems = [] } = this.state

    this.setState(
      produce((draft) => {
        set(draft, name, value)

        this.props.form.setFieldsValue({ [name]: value })
      }),
      () => {
        if (name === 'itemId') {
          this.setState({ item: null, childItems: [], pageIndex: 1 }, () =>
            this.props.form.resetFields(['locationId', 'barcode', 'quantity'])
          )
        }

        if (['barcode', 'locationId'].includes(name)) {
          const quantity = calculateUnissuedQuantity({
            customer: this.props.customer,
            childItems,
            barcode: this.props.form.getFieldValue('barcode'),
            locationId: this.props.form.getFieldValue('locationId'),
          })

          this.setState({ quantity }, () =>
            this.props.form.setFields({
              locationId: {
                value: this.props.form.getFieldValue('locationId'),
                errors: null,
              },
              barcode: { value: this.props.form.getFieldValue('barcode'), errors: null },
              quantity: { value: quantity, errors: null },
            })
          )
        }
      }
    )
  }

  handleSubmit = (e) => {
    const { item, childItems, itemId } = this.state

    stopEvent(e)

    this.props.form.validateFields(async (errors, values) => {
      if (!isEmpty(errors)) {
        showValidationError()
        return
      }

      const { locationId, barcode, quantity } = values

      const updating = childItems.filter((each) => each.barcode === barcode && each.locationId === locationId)

      try {
        this.setState({ saving: true })

        const response = await this.props.saveItem({
          requisition: item,
          requisitionItems: {
            updating: produce(updating, (draft) => {
              let remaining = quantity

              while (remaining > 0) {
                const next = draft.find((one) => one.quantityIssued < one.quantity)

                if (next) {
                  const delta = Math.min(remaining, next.quantity - next.quantityIssued)
                  next.quantityIssued += delta
                  remaining -= delta
                } else {
                  if (this.props.customer.generalSettings.allowOverIssueFromRequisition) {
                    draft[draft.length - 1].quantityIssued += remaining
                  }

                  remaining = 0
                }
              }
            }),
          },
        })

        if (isNil(response.value.data)) {
          throw new Error('Save response is invalid')
        }

        showClientNotifications({ response })

        if (response.value.data.failureCount > 0) {
          throw new Error()
        }

        const reset = {
          barcode: '',
          quantity: null,
        }

        this.setState(reset, () => {
          this.props.form.resetFields(['barcode', 'quantity'])
          this.barcodeRef.current?.focus?.()
        })

        await this.fetchItem({ itemId })
      } catch (error) {
        showError({ error })
      } finally {
        this.setState({ saving: false })
      }
    })
  }

  handleLinkTargetClick = ({ column, record, text, readOnly = true }) => {
    if (process.env.NODE_ENV === 'development') console.log({ column, record, text, readOnly })

    const editDrawerLinkTarget = linkTargets[column.linkTarget]
    const { drawerTitleLanguageKey } = editDrawerLinkTarget ?? {}

    this.setState({
      editDrawerVisible: true,
      editDrawerSaving: false,
      editDrawerLinkTarget,
      editDrawerLinkTargetRecord: record,
      editDrawerLinkTargetText: drawerTitleLanguageKey ? `${t(drawerTitleLanguageKey)} - ${text}` : text,
      editDrawerReadOnly: readOnly,
    })
  }

  handleDrawerClose = () =>
    this.setState({
      editDrawerVisible: false,
      editDrawerSaving: false,
      editDrawerLinkTarget: null,
      editDrawerLinkTargetRecord: null,
      editDrawerLinkTargetText: '',
    })

  render() {
    const {
      item,
      barcode,
      locationId,
      childItems = [],
      fieldSettings = [],
      filteredLocations = [],
    } = this.state

    const pageTitle = getPageTitle()

    if (isEmpty(fieldSettings)) {
      return (
        <Page title={pageTitle}>
          <Spin />
        </Page>
      )
    }

    const columns = getColumns({
      self: this,
      fieldSettings,
      ...pick(this.state, ['columnWidths', 'selectedColumnKeys', 'sortBy', 'sortedColumnKeys', 'sortOrder']),
      tableCellComponents: {
        quantityIssued: (self, record) => (
          <div
            className={cx({
              'table-cell-success':
                record.quantityIssued === record.quantity ||
                (this.props.customer.generalSettings.allowOverIssueFromRequisition &&
                  record.quantityIssued > record.quantity),
              'table-cell-error':
                !this.props.customer.generalSettings.allowOverIssueFromRequisition &&
                record.quantityIssued > record.quantity,
            })}
          >
            {record.quantityIssued}
          </div>
        ),
      },
      onClick: this.handleLinkTargetClick,
      onResize: this.handleColumnResize,
    })
    const width = isEmpty(columns) ? '100%' : getTableWidth(columns)
    const { statusLanguageKey, statusStyle } = getStatus(item)
    const canIssue = item && ['Open', 'Closed'].includes(item.status) && item.approved
    const filteredChildItems = childItems.filter(
      (each) =>
        (isEmpty(barcode) || each.barcode.indexOf(barcode) > -1) &&
        (isNil(locationId) || each.locationId === locationId)
    )
    const EditComponent = get(this.state, 'editDrawerLinkTarget.formComponent', UnderDevelopment)

    return (
      <>
        <Page title={pageTitle}>
          <Row type="flex" justify="space-between" className="whitespace-nowrap">
            <Col className="text-left mb-12 pr-12">
              <h2 title={pageTitle}>{pageTitle}</h2>
            </Col>
            <Col className="text-right mb-12">
              <Dropdown
                overlay={
                  <Menu onClick={this.handleActionsMenuClick}>
                    <Menu.Item key="customizeColumns">{t('customizeColumns')}</Menu.Item>
                  </Menu>
                }
                trigger={['click']}
              >
                <Button className="mr-6">
                  {t('actions')}
                  <Icon type="KeyboardArrowDown" />
                </Button>
              </Dropdown>
            </Col>
          </Row>
          <Row type="flex" gutter={24}>
            <Col xs={24} sm={12} lg={6}>
              <FormContainer>
                <Form layout="vertical" onSubmit={this.handleSubmit} colon={false}>
                  <Form.Item label={t('requisitionId')}>
                    <Row gutter={6}>
                      <Col xs={16}>
                        {this.props.form.getFieldDecorator('itemId', {
                          rules: [
                            {
                              required: true,
                              message: t('requiredField'),
                            },
                          ],
                          initialValue: this.state.itemId,
                        })(
                          <Input
                            onChange={(e) => this.setFormValue('itemId', e.target.value)}
                            onPressEnter={this.handlePressEnter}
                            onKeyPress={this.handleKeyPress}
                            autoFocus
                          />
                        )}
                      </Col>
                      <Col xs={8}>
                        <Button
                          onClick={() =>
                            this.handleLinkTargetClick({
                              column: { linkTarget: 'requisitionRecord' },
                              record: item,
                              text: this.state.itemId,
                              readOnly: false,
                            })
                          }
                          disabled={!item}
                          block
                        >
                          {t('open')}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item label={t('requisitionStatus')}>
                    <Input
                      value={statusLanguageKey ? t(statusLanguageKey) : ''}
                      style={statusStyle}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item label={t('location')}>
                    {this.props.form.getFieldDecorator('locationId', {
                      rules: [
                        {
                          required: true,
                          message: t('requiredField'),
                        },
                      ],
                      initialValue: locationId,
                    })(
                      <Select
                        onChange={(value) => this.setFormValue('locationId', value)}
                        disabled={!canIssue}
                        allowClear={false}
                      >
                        {filteredLocations.map((each) => (
                          <Option key={each.id} value={each.id}>
                            <span {...getOptionProps(each)}>{each.displayName}</span>
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item label={t('barcode')}>
                    {this.props.form.getFieldDecorator('barcode', {
                      rules: [
                        {
                          required: true,
                          message: t('requiredField'),
                        },
                        {
                          type: 'enum',
                          enum: filteredChildItems.map((each) => each.barcode),
                          message: t('barcodeDoesNotExistOnRequisition'),
                        },
                      ],
                      initialValue: barcode,
                    })(
                      <Input
                        ref={this.barcodeRef}
                        onChange={(e) => this.setFormValue('barcode', e.target.value)}
                        onPressEnter={(e) => {
                          stopEvent(e)
                          this.quantityRef.current?.focus?.()
                        }}
                        disabled={!canIssue}
                        allowClear
                      />
                    )}
                  </Form.Item>
                  <Form.Item label={t('quantity')}>
                    {this.props.form.getFieldDecorator('quantity', {
                      rules: [
                        {
                          required: true,
                          message: t('requiredField'),
                        },
                        ...(!this.props.customer.generalSettings.allowOverIssueFromRequisition
                          ? [
                              {
                                type: 'number',
                                min: 0,
                                max: calculateUnissuedQuantity({
                                  barcode,
                                  customer: this.props.customer,
                                  childItems,
                                  locationId,
                                }),
                                message: t('errorQuantityIssued'),
                              },
                            ]
                          : []),
                      ],
                      initialValue: this.state.quantity,
                    })(
                      <InputNumber
                        ref={this.quantityRef}
                        onChange={(value) => this.setFormValue('quantity', value)}
                        disabled={!canIssue}
                        min={0}
                      />
                    )}
                  </Form.Item>
                  <div className="text-right">
                    <Button type="primary" htmlType="submit" disabled={!canIssue} loading={this.state.saving}>
                      {t('issue')}
                    </Button>
                  </div>
                </Form>
              </FormContainer>
            </Col>
            <TableContainer xs={24} sm={12} lg={18}>
              <Table
                childrenColumnName={[]}
                rowKey={(record) => record.id}
                loading={this.state.loading}
                columns={columns}
                dataSource={filteredChildItems}
                size="middle"
                locale={{ emptyText: t('noData') }}
                style={{ width }}
                components={{ header: { cell: ResizableCell } }}
                pagination={{
                  showTotal,
                  pageSizeOptions,
                  showSizeChanger: true,
                  pageSize: this.state.pageSize,
                }}
                onChange={this.handleTableChange}
              />
            </TableContainer>
          </Row>
        </Page>
        <Drawer
          title={this.state.editDrawerLinkTargetText}
          size={this.state.editDrawerLinkTarget?.formSize}
          visible={this.state.editDrawerVisible}
          onClose={this.handleDrawerClose}
          saving={this.state.editDrawerSaving}
        >
          <EditComponent
            linkTargetRecord={this.state.editDrawerLinkTargetRecord}
            readOnly={this.state.editDrawerReadOnly}
            onSave={(pending) =>
              this.promiseState({ editDrawerSaving: pending }).then(async () => {
                await this.props.onSave?.(pending)

                if (!pending) {
                  await this.fetchItem()
                }
              })
            }
            onSaveAndClose={this.handleDrawerClose}
            onCancel={this.handleDrawerClose}
          />
        </Drawer>
        <Customize
          displayableColumns={fieldSettings.filter((each) => each.isDisplayable)}
          visible={this.state.customizeColumnsVisible}
          onOk={this.handleCustomizeOk}
          onCancel={() => this.setState({ customizeColumnsVisible: false })}
          selectedColumnKeys={this.state.selectedColumnKeys}
          sortedColumnKeys={this.state.sortedColumnKeys}
          columnWidths={this.state.columnWidths}
        />
      </>
    )
  }
}

export default Form.create()(Component)
