import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import locationGroupActions from 'actions/locationGroups'
import locationActions from 'actions/locations'
import assetCategoryActions from 'actions/assetCategories'
import productCategoryActions from 'actions/productCategories'
import Component from 'components/Reports/AssetAssociatedInventoryReport/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  assetCategories: selectors.assetCategories.selectOptions(state),
  productCategories: selectors.productCategories.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getLocationGroups: (params) => dispatch(locationGroupActions.getOptions(params)),
  getLocations: (params) =>
    dispatch(locationActions.getOptions({ ...params, active: 'All', locationVendingTypes: ['NonVending'] })),
  getAssetCategories: (params) => dispatch(assetCategoryActions.getOptions(params)),
  getProductCategories: (params) =>
    dispatch(productCategoryActions.getOptions({ ...params, active: 'All', pageIndex: 0 })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
