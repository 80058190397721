import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'

export default createListViewComponent({
  entityName: entityNames.unitsOfMeasure,
  createButtonTextId: 'createUOM',
  primaryLinkTarget: 'unitOfMeasureRecord',
  allowDelete: (self) => false,
  allowSelection: (self) => false,
})()
