import * as React from 'react'
import { useImmer } from 'use-immer'
import { useHistory } from 'react-router-dom'
import { Form, Input } from 'antd'
import { isEmpty, set } from 'lodash'
import { showError, showClientNotifications, showValidationError } from 'helpers/errors'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import Modal from 'elements/Modal'

function FormView(props) {
  const { form, approve, reject, acknowledge, getItem, approveItem, rejectItem } = props

  const key = reject || approve || acknowledge
  const action = approve || acknowledge ? approveItem : reject ? rejectItem : () => Promise.resolve(true)

  const history = useHistory()

  const [state, updateState] = useImmer({})
  const { loading, visible, item } = state

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  function handleSubmit(e) {
    stopEvent(e)

    form.validateFields(async (errors, values) => {
      if (!isEmpty(errors)) {
        showValidationError()
        return
      }

      try {
        setState('loading', true)

        const response = await action({ key, ...values })

        showClientNotifications({ response })

        if (response.value.data.failureCount > 0) {
          throw new Error()
        }

        setState('visible', false)

        history.push('/login')
      } catch (error) {
        showError({ error })
      } finally {
        setState('loading', false)
      }
    })
  }

  function handleCancel() {
    setState('visible', false)

    history.push('/login')
  }

  React.useEffect(() => {
    async function fetchItem() {
      try {
        const response = await getItem(key)

        updateState((draft) => {
          draft.item = response.value.data
          draft.visible = true
        })
      } catch (error) {
        showError({ error })
      }
    }

    if (key) {
      fetchItem()
    } else {
      updateState((draft) => {
        draft.item = null
        draft.visible = false
      })
    }
  }, [approve, reject])

  return (
    <Modal
      title={item?.confirmationTitle}
      okText={reject ? t('reject') : acknowledge ? t('ok') : t('approve')}
      okType={reject ? 'danger' : 'primary'}
      okButtonProps={{ loading }}
      onOk={handleSubmit}
      onCancel={handleCancel}
      visible={visible}
    >
      <p>{item?.confirmationMessage}</p>
      {item?.passwordRequired && (
        <Form layout="vertical" colon={false} onSubmit={handleSubmit}>
          <Form.Item label={t('password')}>
            {form.getFieldDecorator('password', {
              rules: [{ required: true, message: t('requiredField') }],
            })(<Input.Password type="password" />)}
          </Form.Item>
        </Form>
      )}
    </Modal>
  )
}

export default Form.create()(FormView)
