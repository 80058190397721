import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import operatorGroupActions from 'actions/operatorGroups'
import Component from 'components/Operators/Select/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  operatorGroups: selectors.operatorGroups.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getOperatorGroups: (params) => dispatch(operatorGroupActions.getOptions(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
