import { createListViewContainer } from 'factories/ListView'
import selectors from 'selectors'
import actions from 'actions/formInstances'
import Component from 'components/FormInstances'

export default createListViewContainer({
  actions,
  selectors: selectors.formInstances,
  extraDispatchToProps: (dispatch, props) => ({
    downloadAttachmentsMultiple: (params) => dispatch(actions.downloadAttachmentsMultiple(params)),
  }),
})(Component)
