import { connect } from 'react-redux'
import selectors from 'selectors'
import assetCategoryActions from 'actions/assetCategories'
import assetActions from 'actions/assets'
import Component from 'components/DashboardViewer/Filter/Assets'

const mapStateToProps = (state, props) => ({
  assetCategories: selectors.assetCategories.selectOptions(state),
  assets: selectors.assets.selectOptions(state, { itemsOnly: false }),
})

const mapDispatchToProps = (dispatch, props) => ({
  getAssetCategories: (params) => dispatch(assetCategoryActions.getOptions(params)),
  getAssets: (params) => dispatch(assetActions.getOptions({ ...params, pageIndex: 1, pageSize: 100 })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
