import * as React from 'react'
import styled from 'styled-components'
import echarts from 'echarts/lib/echarts'
import { useImmer } from 'use-immer'
import { get, isNil, isEmpty, uniq, toString as str, orderBy, set, pick } from 'lodash'
import { Tabs, Button, Tooltip, Spin, Form, Table, Radio, Empty } from 'antd'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import linkTargets from 'options/linkTargets'
import { tryParseJSON, moveByDelta, sortByKeys } from 'helpers/utils'
import { DashboardContext } from 'helpers/dashboards'
import { setStorageItem, getStorageItem } from 'helpers/localStorage'
import { Emitter } from 'helpers/events'
import { DASHBOARD_FILTER_OPEN, DASHBOARD_TEMPLATE_FETCHED } from 'options/events'
import Icon from 'elements/Icon'
import Page from 'elements/Page'
import Drawer from 'elements/Drawer'
import Modal from 'elements/Modal'
import Select, { Option, getOptionProps } from 'elements/Select'
import Dashboard from 'containers/DashboardViewer/Dashboard'
import SelectTemplate from 'containers/DashboardTemplates/Select'
import FormView from 'containers/DashboardTemplates/FormView'
import { Row, Col } from 'elements/Grid'

const getStorageKey = () => 'dashboardViewer'

const Container = styled.div`
  .ant-tabs-bar > * {
    color: ${(props) => props.foregroundColor};
  }

  .ant-tabs-nav-scroll {
    min-height: 46px;
  }

  .ant-tabs-extra-content {
    line-height: initial;
  }

  .ant-tabs-tab {
    padding-right: 8px !important;

    .ant-btn-link {
      visibility: hidden;
      color: unset !important;
      margin-left: 8px;
      padding: 1px 0;

      .anticon {
        margin: 0 !important;
      }
    }

    &:hover,
    &.ant-tabs-tab-active {
      color: unset !important;

      .ant-btn-link {
        visibility: visible;
      }
    }
  }
`

const Buttons = styled.div`
  .ant-btn {
    padding: 0 8px;
    margin-left: 6px;
  }
`

const List = styled(Table)`
  .ant-table-header {
    overflow: hidden !important;
    margin: 0 !important;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 320px);
`

function Component(props) {
  const userThemeId = props.user?.dashboardThemeId ?? 0
  const userTemplateIds = props.user?.coreUserSettings?.dashboardTemplateIds ?? []

  const [state, updateState] = useImmer({ ...getStorageItem(getStorageKey(), {}) })

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  async function fetchTheme() {
    if (userThemeId) {
      try {
        const response = await props.getDashboardTheme(userThemeId).then((r) => r.value.data)

        echarts.registerTheme(response.name, tryParseJSON(response.styleJSON, {}))

        setState('theme', response)
      } catch (error) {
        showError({ error })
      }
    } else {
      setState('theme', {})
    }
  }

  async function fetchThemes() {
    try {
      const response = await props.getDashboardThemes({ active: 'Active' })

      setState('themes', get(response, 'value.data.items', []))
    } catch (error) {
      showError({ error })
    }
  }

  async function fetchTemplates() {
    try {
      setState('loading', true)

      if (isEmpty(userTemplateIds)) {
        setState('templates', [])
      } else {
        const response = await props.getDashboardTemplates({
          active: 'Active',
          dashboardTemplateIds: userTemplateIds,
        })

        setState('templates', sortByKeys(response.value.data.items, userTemplateIds, 'id'))
      }
    } catch (error) {
      showError({ error })
    } finally {
      setState('loading', false)
    }
  }

  function editDashboard(template) {
    updateState((draft) => {
      draft.editDashboardTitle = template.name
      draft.editDashboardVisible = true
      draft.editDashboardSaving = false
      draft.editDashboardRecord = { templateId: template.id }
    })
  }

  async function closeDashboard(template) {
    try {
      setState('closeDashboardSelected', template)

      await props.updateUser(
        {
          userName: props.user.userName,
          email: props.user.email,
          dashboardThemeId: userThemeId,
          coreUserSettings: {
            dashboardTemplateIds: uniq(userTemplateIds.filter((each) => each !== template.id)),
          },
        },
        { type: 'userDashboardSettings' }
      )

      await props.refreshToken(true)
    } catch (error) {
      showError({ error })
    } finally {
      setState('closeDashboardSelected', null)
    }
  }

  function handleSettingsOpen() {
    updateState((draft) => {
      draft.settingsVisible = true
      draft.settingsLoading = false
      draft.settingsThemeId = userThemeId
      draft.settingsTemplates = [...state.templates]
      draft.settingsHighlightedRowKey = null
    })
  }

  async function handleSettingsOk() {
    try {
      setState('settingsLoading', true)

      await props.updateUser(
        {
          userName: props.user.userName,
          email: props.user.email,
          dashboardThemeId: state.settingsThemeId,
          coreUserSettings: {
            dashboardTemplateIds: uniq((state?.settingsTemplates ?? []).map((each) => each.id)),
          },
        },
        { type: 'userDashboardSettings' }
      )

      await props.refreshToken(true)

      setState('settingsVisible', false)
    } catch (error) {
      showError({ error })
    } finally {
      setState('settingsLoading', false)
    }
  }

  function handleSettingsClose() {
    setState('settingsVisible', false)
  }

  function handleAddDashboardOpen() {
    updateState((draft) => {
      draft.addDashboardVisible = true
      draft.addDashboardLoading = false
      draft.addDashboardSelected = null
    })
  }

  async function handleAddDashboardOk() {
    try {
      setState('addDashboardLoading', true)

      const selectedId = state.addDashboardSelected?.id

      if (selectedId) {
        await props.updateUser(
          {
            userName: props.user.userName,
            email: props.user.email,
            dashboardThemeId: userThemeId,
            coreUserSettings: {
              dashboardTemplateIds: uniq([
                ...userTemplateIds.filter((each) => each !== selectedId),
                selectedId,
              ]),
            },
          },
          { type: 'userDashboardSettings' }
        )

        await props.refreshToken(true)
      }

      updateState((draft) => {
        draft.addDashboardVisible = false
        draft.tabsActiveKey = str(selectedId)
      })
    } catch (error) {
      showError({ error })
    } finally {
      setState('addDashboardLoading', false)
    }
  }

  function handleAddDashboardClose() {
    setState('addDashboardVisible', false)
  }

  function moveHighlightedTemplate(delta) {
    updateState((draft) => {
      draft.settingsTemplates = moveByDelta(
        draft.settingsTemplates,
        draft.settingsTemplates.findIndex((one) => one.id === state.settingsHighlightedRowKey),
        delta
      )
    })
  }

  function handleFilterSettingsOpen() {
    Emitter.emit(DASHBOARD_FILTER_OPEN, state.tabsActiveKey)
  }

  React.useEffect(() => {
    fetchThemes()
  }, [])

  React.useEffect(() => {
    fetchTheme()
  }, [userThemeId])

  React.useEffect(() => {
    fetchTemplates()
  }, [userTemplateIds])

  React.useEffect(() => {
    if (
      !isEmpty(state.templates) &&
      (isEmpty(state.tabsActiveKey) ||
        !(state?.templates ?? []).map((each) => str(each.id)).includes(state.tabsActiveKey))
    ) {
      setState('tabsActiveKey', str(get(state, 'templates[0].id')))
    }
  })

  React.useEffect(() => {
    setStorageItem(getStorageKey(), pick(state, ['tabsActiveKey']))
  }, [state.tabsActiveKey])

  function isFilterSettingsEmpty() {
    try {
      return isEmpty(
        (state?.templates ?? []).find((one) => str(one.id) === str(state.tabsActiveKey)).filterSettings
      )
    } catch (error) {
      return true
    }
  }

  React.useEffect(() => {
    function updateFilterSettings(response) {
      try {
        updateState((draft) => {
          draft.templates.find((one) => one.id === response.id).filterSettings = response.filterSettings
        })
      } catch (error) {
        showError({ error })
      }
    }

    Emitter.on(DASHBOARD_TEMPLATE_FETCHED, updateFilterSettings)

    return () => Emitter.off(DASHBOARD_TEMPLATE_FETCHED, updateFilterSettings)
  }, [])

  const styleJSON = tryParseJSON(state?.theme?.styleJSON ?? '{}', {})
  const foregroundColor = styleJSON?.title?.textStyle?.color ?? '#666666'
  const backgroundColor = styleJSON?.backgroundColor ?? '#ffffff'

  if (isNil(state.themes) || isNil(state.templates) || (userThemeId && isNil(state.theme))) {
    return (
      <Page>
        <Spin />
      </Page>
    )
  }

  return (
    <>
      <Page contentStyle={{ backgroundColor }} scrollable>
        <DashboardContext.Provider
          value={{ echarts, theme: state.theme, styleJSON, foregroundColor, backgroundColor }}
        >
          <Container foregroundColor={foregroundColor}>
            <Tabs
              activeKey={state.tabsActiveKey}
              onChange={(value) => setState('tabsActiveKey', value)}
              tabBarExtraContent={
                <Buttons>
                  <Tooltip title={t('settings')} placement="bottomLeft">
                    <Button onClick={handleSettingsOpen}>
                      <Icon type="Settings" bold />
                    </Button>
                  </Tooltip>
                  <Tooltip title={t('refreshDashboard')} placement="bottomLeft">
                    <Button onClick={() => fetchTemplates()} disabled={isEmpty(state.templates)}>
                      <Icon type="Refresh" bold />
                    </Button>
                  </Tooltip>
                  <Tooltip title={t('showDashboardFilter')} placement="bottomLeft">
                    <Button onClick={handleFilterSettingsOpen} disabled={isFilterSettingsEmpty()}>
                      <Icon type="FilterList" bold />
                    </Button>
                  </Tooltip>
                  <Tooltip title={t('addDashboard')} placement="bottomLeft">
                    <Button type="primary" onClick={handleAddDashboardOpen}>
                      <Icon type="Add" bold />
                    </Button>
                  </Tooltip>
                </Buttons>
              }
              forceRender={false}
            >
              {(state?.templates ?? []).map((each, tabIndex) => (
                <Tabs.TabPane
                  key={each.id}
                  tab={
                    <>
                      <span className="mr-6">{each.name}</span>
                      <Tooltip title={t('editDashboard')} placement="bottom">
                        <Button type="link" size="small" onClick={() => editDashboard(each)}>
                          <Icon type="Edit" />
                        </Button>
                      </Tooltip>
                      <Tooltip title={t('closeDashboard')} placement="bottom">
                        <Button onClick={() => closeDashboard(each)} type="link" size="small">
                          {state.closeDashboardSelected?.id === each.id ? (
                            <Spin size="small" />
                          ) : (
                            <Icon type="Close" />
                          )}
                        </Button>
                      </Tooltip>
                    </>
                  }
                >
                  <>
                    {state.loading ? (
                      <Spin />
                    ) : (
                      <Dashboard
                        key={each.id}
                        templateId={each.id}
                        active={str(state.tabsActiveKey) === str(each.id)}
                      />
                    )}
                  </>
                </Tabs.TabPane>
              ))}
            </Tabs>
            {isEmpty(state.templates) && (
              <Wrapper>
                <Empty style={{ color: foregroundColor }} description={t('addTemplateToViewer')} />
              </Wrapper>
            )}
          </Container>
        </DashboardContext.Provider>
      </Page>
      <Drawer
        title={state.editDashboardTitle}
        size={linkTargets.dashboardTemplateRecord.formSize}
        visible={state.editDashboardVisible}
        onClose={() => setState('editDashboardVisible', false)}
        saving={state.editDashboardSaving}
      >
        <FormView
          linkTargetRecord={state.editDashboardRecord}
          onSave={async (pending) => {
            setState('editDashboardSaving', pending)

            if (!pending) {
              await fetchTemplates()
            }
          }}
          onSaveAndClose={() => setState('editDashboardVisible', false)}
          onCancel={() => setState('editDashboardVisible', false)}
        />
      </Drawer>
      <Modal
        title={t('settings')}
        visible={state.settingsVisible}
        okText={t('update')}
        okButtonProps={{ loading: state.settingsLoading }}
        onOk={handleSettingsOk}
        onCancel={handleSettingsClose}
      >
        <Row>
          <Col xs={(state?.settingsTemplates?.length ?? 0) > 1 ? 21 : 24}>
            <Form.Item label={t('dashboardTheme')} colon={false}>
              <Select
                value={state.settingsThemeId}
                onChange={(value) => setState('settingsThemeId', value)}
                allowClear={false}
              >
                {state.settingsThemeId === 0 && <Option value={0}>{t('none')}</Option>}
                {orderBy(state?.themes ?? [], ['id']).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {(state?.settingsTemplates?.length ?? 0) > 1 && (
          <Row type="flex" justify="space-between" align="middle">
            <Col xs={21}>
              <List
                rowKey={(record) => record.id}
                columns={[
                  {
                    key: 'name',
                    title: t('tabOrder'),
                    dataIndex: 'name',
                  },
                  {
                    key: 'order',
                    align: 'center',
                    width: 60,
                    render: (each) => (
                      <Radio
                        checked={each.id === state.settingsHighlightedRowKey}
                        onChange={() => setState('settingsHighlightedRowKey', each.id)}
                      />
                    ),
                  },
                ]}
                dataSource={state?.settingsTemplates ?? []}
                size="small"
                pagination={false}
                scroll={{ y: 320 }}
              />
            </Col>
            <Col xs={3}>
              <Button
                type="primary"
                disabled={!state.settingsHighlightedRowKey}
                className="mb-12"
                onClick={() => moveHighlightedTemplate(-1)}
              >
                <Icon type="KeyboardArrowUp" />
              </Button>
              <Button
                type="primary"
                disabled={!state.settingsHighlightedRowKey}
                onClick={() => moveHighlightedTemplate(1)}
              >
                <Icon type="KeyboardArrowDown" />
              </Button>
            </Col>
          </Row>
        )}
      </Modal>
      <Modal
        title={t('selectDashboardTemplate')}
        visible={state.addDashboardVisible}
        okText={t('add')}
        okButtonProps={{
          loading: state.addDashboardLoading,
          disabled: isNil(state.addDashboardSelected),
        }}
        onOk={handleAddDashboardOk}
        onCancel={handleAddDashboardClose}
        width={992}
      >
        <SelectTemplate onSelect={(values) => setState('addDashboardSelected', values[0])} />
      </Modal>
    </>
  )
}

export default Component
