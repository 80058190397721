import * as React from 'react'
import styled from 'styled-components'
import { Form, Button } from 'antd'
import { HARD_SPACE } from 'helpers/utils'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
  }
`

function Component(props) {
  const { readOnly, value, onDelete, onUpload, label, showButtons = true, height = '125px' } = props
  const hasContents = value?.fileType && value?.contents

  const [visible, setVisible] = React.useState(false)

  return (
    <>
      <Form.Item label={label}>
        {showButtons && (
          <div className="tofino-label-buttons">
            <Button size="small" disabled={!hasContents || readOnly} onClick={onDelete}>
              <Icon type="Delete" />
            </Button>{' '}
            <Button size="small" onClick={onUpload} disabled={readOnly}>
              <Icon type="FileUpload" />
            </Button>
          </div>
        )}
        <Container style={{ height }}>
          {hasContents ? (
            <>
              <div
                style={{ cursor: 'pointer', width: '100%', height: '100%' }}
                onClick={() => setVisible(true)}
              >
                <img
                  style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                  src={`data:${value.fileType};base64,${value.contents}`}
                  alt={label}
                />
              </div>
              <Modal
                title={label || HARD_SPACE}
                visible={visible}
                footer={null}
                onCancel={() => setVisible(false)}
                width={992}
              >
                <Wrapper>
                  <img
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                    src={`data:${value.fileType};base64,${value.contents}`}
                    alt={label}
                  />
                </Wrapper>
              </Modal>
            </>
          ) : (
            <div style={{ zoom: 0.9, textAlign: 'center', color: '#bbb' }}>
              <Icon type="NoPhotography" size={96} color="#bbb" />
              <br />
              {t('noImage')}
            </div>
          )}
        </Container>
      </Form.Item>
    </>
  )
}

export default Component
