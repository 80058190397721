import { Alert } from 'antd'
import { t } from 'helpers/i18n'

export default function () {
  return (
    <div className="form-items-container">
      <Alert message={t('underDevelopment')} type="warning" showIcon />
    </div>
  )
}
