import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import actions from 'actions/locationGroups'
import Component from 'components/Locations/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  locationGroups: selectors.locationGroups.selectOptions(state),
})

export default connect(mapStateToProps, { getLocationGroups: actions.getOptions })(Component)
