import * as React from 'react'
import { Spin, Form, Input } from 'antd'
import { isEmpty, get, isNil } from 'lodash'
import { useImmer } from 'use-immer'
import { showError } from 'helpers/errors'
import { createLabelFactory } from 'helpers/formViews'
import { stopEvent } from 'helpers/events'
import { renderBarcode } from 'helpers/listViews'
import { Header, Title } from 'helpers/print'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DatePicker from 'elements/DatePicker'
import Checklist from 'containers/Jobs/Print/Checklist'
import Tolerances from 'containers/Jobs/Print/Tolerances'
import { Row, Col } from 'elements/Grid'

function Component(props) {
  const { parentRecord: item, customer, tenant, isTemplate } = props

  const [state, updateState] = useImmer({})

  React.useEffect(() => {
    async function fetchSettings() {
      try {
        const responses = await Promise.all([
          props.getSettings({ type: props.isTemplate ? 'jobTemplates' : 'job' }),
          !props.locations ? props.getLocations() : Promise.resolve(null),
          props.getJobReasons(),
          props.getJobStatusOptions(),
          !props.users ? props.getUsers() : Promise.resolve(null),
          item.assignedToId ? props.getOperator(item.assignedToId) : Promise.resolve(null),
          props.getOperators({ operatorIds: item.operatorIds }),
          item.assetId ? props.getAsset(item.assetId).catch(() => {}) : Promise.resolve(null),
          !props.logo
            ? props
                .getDocumentContents({
                  request: {
                    domainObjectId: tenant.id,
                    domainObjectType: 'Customer',
                    documentType: 'PurchaseOrderLogo',
                    documentName: tenant.subdomain,
                  },
                })
                .catch(() => {})
            : Promise.resolve(null),
          props.getAssetTolerancesSettings({ type: 'jobs' }),
          props.getJobChecklistSettings(),
          isNil(item.checklistItems) ? props.getChecklistItems(item.id) : Promise.resolve(null),
          isNil(item.toleranceItems) ? props.getToleranceItems(item.assetId) : Promise.resolve(null),
        ])

        updateState((draft) => {
          draft.fieldSettings = responses[0].value.data.fieldSettings
          draft.locations = props.locations ?? get(responses[1], 'value.data.items', [])
          draft.jobReasons = get(responses[2], 'value.data.items', [])
          draft.jobStatusOptions = get(responses[3], 'value.data.items', [])
          draft.users = props.users ?? get(responses[4], 'value.data.items', [])
          draft.assignedToOperator = get(responses[5], 'value.data', {})
          draft.operators = get(responses[6], 'value.data.items', [])
          draft.asset = get(responses[7], 'value.data', {})
          draft.logo = props.logo ?? get(responses[8], 'value.data', {})
          draft.assetTolerancesSettings = get(responses[9], 'value.data.fieldSettings', [])
          draft.jobChecklistSettings = get(responses[10], 'value.data.fieldSettings', [])
          draft.checklistItems = item.checklistItems ?? get(responses[11], 'value.data.items', [])
          draft.toleranceItems = item.toleranceItems ?? get(responses[12], 'value.data.items', [])
        })
      } catch (error) {
        showError({ error })
      }
    }

    fetchSettings()
  }, [])

  const title = `${customer.displayName} - ${t(isTemplate ? 'jobTemplate' : 'job')} ${item.barcode}`
  const createLabel = createLabelFactory(state.fieldSettings)

  return isEmpty(state.fieldSettings) ? (
    <Spin />
  ) : (
    <>
      <Title>
        <Col xs={16}>
          <h2>{title}</h2>
        </Col>
        <Col xs={8} className="text-right">
          {state.logo && state.logo.fileType && state.logo.contents && (
            <img src={`data:${state.logo.fileType};base64,${state.logo.contents}`} alt="" />
          )}
        </Col>
      </Title>
      <Header>
        <Form layout="vertical" colon={false} onSubmit={stopEvent}>
          <Row>
            <Col xs={8}>
              <Form.Item label={createLabel('barcode')}>
                <Row>
                  <Col xs={item.id ? 12 : 24}>
                    <Input value={item.id} readOnly />
                  </Col>
                  {Boolean(item.id) && (
                    <Col xs={12}>
                      {renderBarcode({
                        marginTop: 0,
                        marginBottom: 0,
                        value: item.id,
                      })}
                    </Col>
                  )}
                </Row>
              </Form.Item>
            </Col>
            <Col xs={16}>
              <Form.Item label={createLabel('description')}>
                <Input value={item.description} readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Form.Item label={createLabel('locationName')}>
                <Select value={item.locationId} readOnly>
                  <Option value={0}>{t('none')}</Option>
                  {(state?.locations ?? [])
                    .filter((each) => each.id)
                    .map((each) => (
                      <Option key={each.id} value={each.id}>
                        <span {...getOptionProps(each)}>{each.displayName}</span>
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={createLabel('name')}>
                <Input value={item.name} readOnly />
              </Form.Item>
            </Col>
            {isTemplate ? (
              <Col xs={8}>
                <Form.Item label={createLabel('duration')}>
                  <Input value={item.duration} readOnly />
                </Form.Item>
              </Col>
            ) : (
              <>
                <Col xs={4}>
                  <Form.Item label={createLabel('dueDate')}>
                    <DatePicker value={item.dueDate} placeholder="" readOnly />
                  </Form.Item>
                </Col>
                <Col xs={4}>
                  <Form.Item label={createLabel('statusOptionId')}>
                    <Select value={item.statusOptionId} readOnly>
                      <Option value={0}>{t('none')}</Option>
                      {(state?.jobStatusOptions ?? [])
                        .filter((each) => each.id)
                        .map((each) => (
                          <Option key={each.id} value={each.id}>
                            <span {...getOptionProps(each)}>{each.displayName}</span>
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col xs={8}>
              <Form.Item label={createLabel('reasonId')}>
                <Select value={item.reasonId} readOnly>
                  <Option value={0}>{t('none')}</Option>
                  {(state?.jobReasons ?? [])
                    .filter((each) => each.id)
                    .map((each) => (
                      <Option key={each.id} value={each.id}>
                        <span {...getOptionProps(each)}>{each.displayName}</span>
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={createLabel('notifyUser')}>
                <Select value={item.notifyUser} placeholder={t('none')} readOnly>
                  <Option value={null}>{t('none')}</Option>
                  {(state?.users ?? []).map((each) => (
                    <Option key={each.userName} value={each.userName}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Form.Item label={createLabel('completionDate')}>
                <DatePicker value={item.completionDate} placeholder="" readOnly />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Form.Item label={t('costCenter')}>
                <Input value={state.asset?.costCenter} placeholder="" readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Form.Item label={t('assetId')}>
                <Row>
                  <Col xs={state.asset?.barcode ? 12 : 24}>
                    <Input value={state.asset?.barcode} readOnly />
                  </Col>
                  {state.asset?.barcode && (
                    <Col xs={12}>
                      {renderBarcode({
                        marginTop: 0,
                        marginBottom: 0,
                        value: state.asset?.barcode,
                      })}
                    </Col>
                  )}
                </Row>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label={t('assetDescription')}>
                <Input value={state.asset?.description} readOnly />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Form.Item label={t('serialNumber')}>
                <Input value={state.asset?.serialNumber} readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Form.Item label={createLabel('assignedToId')}>
                <Row>
                  <Col xs={state.assignedToOperator?.barcode ? 12 : 24}>
                    <Input value={state.assignedToOperator?.number} readOnly />
                  </Col>
                  {state.assignedToOperator?.barcode && (
                    <Col xs={12}>
                      {renderBarcode({
                        marginTop: 0,
                        marginBottom: 0,
                        value: state.assignedToOperator?.barcode,
                      })}
                    </Col>
                  )}
                </Row>
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={t('primary')}>
                <Input value={state.assignedToOperator?.name} readOnly />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={t('secondary')}>
                <Input
                  value={(state?.operators ?? [])
                    .filter((each) => item.operatorIds.includes(each.id))
                    .map((each) => each.name)
                    .join(', ')}
                  readOnly
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Header>
      {!isEmpty(item.notes) && (
        <>
          <h3>{t('notes')}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: item.notes }}
            className="ant-input h-auto whitespace-pre-wrap mt-3"
          />
        </>
      )}
      {Boolean(item.assetId) && item.assetLOTONotes && props.enableAssetManagement && (
        <>
          <h3>{t('loto')}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: item.assetLOTONotes }}
            className="ant-input h-auto whitespace-pre-wrap mt-3"
          />
        </>
      )}
      {!isEmpty(state.checklistItems) && (
        <>
          <h3>{t('checklist')}</h3>
          <Checklist
            storageKey="jobs.formView.jobChecklist"
            fieldSettings={state.jobChecklistSettings}
            items={state.checklistItems}
          />
        </>
      )}
      {!isEmpty(item.instructions) && (
        <>
          <h3>{t('instructions')}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: item.instructions }}
            className="ant-input h-auto whitespace-pre-wrap mt-3 min-h-96"
          />
        </>
      )}
      <h3>{t('actionTaken')}</h3>
      <div
        dangerouslySetInnerHTML={{ __html: item.actionTaken }}
        className="ant-input whitespace-pre-wrap mt-3 min-h-96"
      />
      {!props.isTemplate && !isEmpty(state.toleranceItems) && (
        <>
          <h3>{t('tolerances')}</h3>
          <Tolerances
            settingsType="jobs"
            storageKey="assets.formView.assetTolerances.jobs"
            fieldSettings={state.assetTolerancesSettings}
            items={state.toleranceItems}
            parentRecord={item}
          />
        </>
      )}
    </>
  )
}

export default Component
