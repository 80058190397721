import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Spin } from 'antd'
import documentActions from 'actions/documents'
import Line from './Line'

function Component(props) {
  const {
    item = {},
    bordered,
    ghosted,
    highlightable,
    template = {},
    style = {},
    labelTemplateFields = [],
    highlighted: { lineIndex = -1, fieldIndex = -1 } = {},
    ...rest
  } = props

  const dispatch = useDispatch()

  const [objectImage, setObjectImage] = useState(null)
  const [objectImageFetching, setObjectImageFetching] = useState(false)

  async function fetchObjectImage({ domainObjectId, domainObjectType, documentName }) {
    setObjectImageFetching(true)

    const response = await dispatch(
      documentActions.getContents({
        request: {
          domainObjectId,
          domainObjectType,
          documentName,
          documentType: 'ObjectImage',
        },
      })
    ).catch(() => {})

    setObjectImage(response?.value.data)
    setObjectImageFetching(false)
  }

  const unit = template.unitType === 'Centimeters' ? 'cm' : 'in'
  const templateLines = template.lines ?? []
  const noObjectImage = !templateLines
    .flatMap((each) => each.fields)
    .some((one) => ['AssetImage', 'ProductImage'].includes(one.fieldType))

  const getContents = () => {
    if (ghosted) {
      return null
    }

    if (objectImageFetching) {
      return <Spin />
    }

    return templateLines.map((each, index) => (
      <Line
        key={each.id}
        line={each}
        item={{
          ...item,
          objectImageContentType: objectImage?.fileType,
          objectImageContentBase64: objectImage?.contents,
        }}
        unit={unit}
        barcodeFormat={template.barcodeFormat}
        labelTemplateFields={labelTemplateFields}
        highlightable={highlightable}
        highlighted={{ fieldIndex: lineIndex === index ? fieldIndex : -1 }}
      />
    ))
  }

  useEffect(() => {
    if (ghosted || noObjectImage) {
      return
    }

    if (template.labelTemplateType === 'Inventory' && item.productId && item.barcode) {
      fetchObjectImage({
        domainObjectId: item.productId,
        domainObjectType: 'Product',
        documentName: item.barcode,
      })
    }

    if (template.labelTemplateType === 'Asset' && item.id && item.barcode) {
      fetchObjectImage({ domainObjectId: item.id, domainObjectType: 'Asset', documentName: item.barcode })
    }

    if (template.labelTemplateType === 'Product' && item.id && item.barcode) {
      fetchObjectImage({ domainObjectId: item.id, domainObjectType: 'Product', documentName: item.barcode })
    }
  }, [template.labelTemplateType])

  return (
    <div
      style={{
        color: 'black',
        overflow: highlightable ? 'visible' : 'hidden',
        pageBreakInside: 'avoid',
        minWidth: `${template.width}${unit}`,
        maxWidth: `${template.width}${unit}`,
        minHeight: `${template.height}${unit}`,
        maxHeight: `${template.height}${unit}`,
        paddingTop: `${template.topMargin}${unit}`,
        paddingBottom: `${template.bottomMargin}${unit}`,
        paddingLeft: `${template.leftMargin}${unit}`,
        paddingRight: `${template.rightMargin}${unit}`,
        marginBottom: `${template.verticalGap}${unit}`,
        marginRight: template.mediaType === 'Sheet' ? `${template.horizontalGap}${unit}` : 0,
        border: bordered ? '1px solid #d9d9d9' : 'none',
        borderRadius: bordered ? '3px' : '0',
        position: highlightable ? 'absolute' : 'auto',
        top: highlightable ? '12px' : 'auto',
        left: highlightable ? '12px' : 'auto',
        zIndex: highlightable ? 999 : 'auto',
        ...style,
      }}
      {...rest}
    >
      {getContents()}
    </div>
  )
}

export default Component
