import { Button, Popover, Tooltip, message } from 'antd'
import { isNil, isEmpty, cloneDeep, isEqual } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import { t } from 'helpers/i18n'
import { createOnHandTableCellComponent, createOnOrderTableCellComponent } from 'components/Inventory'
import { createY2KNullTableCellComponent } from 'helpers/listViews'
import { Emitter } from 'helpers/events'
import { FORM_SUBMIT_EVENT } from 'options/events'
import { showClientNotifications, showError } from 'helpers/errors'
import entityNames from 'options/entityNames'
import PrintLabel from 'containers/PrintLabel'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import SelectItemInventory from 'containers/OverstockInventory/SelectItemInventory'
import TransferInventory from 'containers/Inventory/Transfer'
import TransferOverStockInventory from 'containers/OverstockInventory/Transfer'
import Filter from 'containers/OverstockInventory/Filter'

export default createListViewComponent({
  entityName: entityNames.overstockInventory,
  filterTemplateType: 'OverstockInventory',
  createButtonTextId: 'addToOverstock',
  primaryLinkTarget: 'overstockInventoryRecord',
  allowDelete: (self) => false,
  extraRowActions: (self) => {
    const hasUnsavedChanges = self.hasUnsavedChanges()
    const selectedRowItems = self.state.selectedRowKeys.map((each) =>
      self.state.items.find((one) => one.id === each)
    )

    return (
      <>
        <PrintLabel
          type="OverstockInventory"
          items={selectedRowItems}
          parentHasUnsavedChanges={hasUnsavedChanges}
        />
        <Button
          disabled={self.state.selectedRowKeys.length !== 1}
          onClick={() => {
            if (hasUnsavedChanges) {
              message.error(t('saveChangesFirst'))
            } else {
              self.setState({ transferOverstockInventoryVisible: true })
            }
          }}
        >
          <Icon type="SwapHoriz" />
          {t('transfer')}
        </Button>
        <Modal
          title={`${t('transferToInventory')} - ${selectedRowItems[0]?.barcode}`}
          visible={self.state.transferOverstockInventoryVisible}
          onCancel={() => self.setState({ transferOverstockInventoryVisible: false })}
          okText={t('transfer')}
          onOk={() => Emitter.emit(FORM_SUBMIT_EVENT)}
          okButtonProps={{ loading: self.state.transferOverstockInventoryLoading }}
          width={750}
        >
          <TransferOverStockInventory
            parentRecord={selectedRowItems[0]}
            onSave={(pending) => self.promiseState({ transferOverstockInventoryLoading: pending })}
            onSaveAndClose={() =>
              self.setState({ transferOverstockInventoryVisible: false }, self.fetchItems)
            }
            onCancel={() => self.setState({ transferOverstockInventoryVisible: false })}
          />
        </Modal>
      </>
    )
  },
  tableCellComponents: {
    onHand: createOnHandTableCellComponent('onHand'),
    onHand2: createOnHandTableCellComponent('onHand2'),
    onOrder: createOnOrderTableCellComponent(true),
    lastCountDate: createY2KNullTableCellComponent('lastCountDate'),
    totalInventoryOnHand: (self, item) => {
      if (item.totalInventoryOnHand === 0) {
        return <span className="mr-24">0</span>
      }

      return (
        <div>
          <a
            onClick={() => {
              if (self.hasUnsavedChanges()) {
                return message.error(t('saveChangesFirst'))
              }

              if (!self.state.filterDto.locationGroupId) {
                return message.error(t('selectLocationGroupFirst'))
              }

              return self.setState({
                itemInventoryItem: item,
                itemInventorySelectedItem: null,
                itemInventoryLoading: false,
                itemInventoryVisible: true,
                itemInventoryTimestamp: Date.now(),
              })
            }}
            className="mr-6"
          >
            {item.totalInventoryOnHand}
          </a>
          <Popover
            title={t('inventoryOnHand')}
            content={<span dangerouslySetInnerHTML={{ __html: item.inventoryOnHandInfo }} />}
          >
            <span
              className="align-middle color-blue"
              style={{ visibility: isEmpty(item.inventoryOnHandInfo) ? 'hidden' : 'visible' }}
            >
              <Icon type="Search" />
            </span>
          </Popover>
        </div>
      )
    },
  },
  sharedComponents: (self) => (
    <>
      <Modal
        title={t('itemInventory')}
        visible={self.state.itemInventoryVisible}
        onCancel={() => self.setState({ itemInventoryVisible: false })}
        footer={
          <>
            <Button onClick={() => self.setState({ itemInventoryVisible: false })}>{t('close')}</Button>
            <Button
              onClick={() => self.setState({ transferInventoryVisible: true })}
              loading={self.state.itemInventoryLoading}
              type="primary"
              disabled={isNil(self.state.itemInventorySelectedItem)}
            >
              {t('transferToOverstock')}
            </Button>
          </>
        }
        width={992}
      >
        <SelectItemInventory
          key={self.state.itemInventoryTimestamp}
          initialFilterDto={{
            barcode: self.state.itemInventoryItem?.barcode,
            locationGroupIds: [self.state.filterDto.locationGroupId],
          }}
          initialState={{
            sortBy: 'locationName',
            sortOrder: 'ascend',
          }}
          onSelect={(values) => self.setState({ itemInventorySelectedItem: values[0] })}
          filterProps={{
            disabled: true,
            barcode: self.state.itemInventoryItem?.barcode,
            description: self.state.itemInventoryItem?.itemDescription,
          }}
        />
      </Modal>
      <Modal
        title={`${t('transferToOverstock')} - ${self.state.itemInventorySelectedItem?.barcode}`}
        visible={self.state.transferInventoryVisible}
        onCancel={() => self.setState({ transferInventoryVisible: false })}
        okText={t('transfer')}
        onOk={() => Emitter.emit(FORM_SUBMIT_EVENT)}
        okButtonProps={{ loading: self.state.transferInventoryLoading }}
        width={750}
      >
        <TransferInventory
          parentRecord={self.state.itemInventorySelectedItem}
          onSave={(pending) => self.promiseState({ transferInventoryLoading: pending })}
          onSaveAndClose={() =>
            self.setState(
              { transferInventoryVisible: false, itemInventoryTimestamp: Date.now() },
              self.fetchItems
            )
          }
          onCancel={() => self.setState({ transferInventoryVisible: false })}
          initialState={{
            toLocationType: 'OverstockLocation',
            item: self.state.itemInventoryItem?.overstockLocationId
              ? { toOverstockLocationId: self.state.itemInventoryItem.overstockLocationId }
              : undefined,
          }}
        />
      </Modal>
    </>
  ),
  totalsRowComponents: {
    totalInventoryOnHand: (self, item, column, text) => (
      <Tooltip title={t(column.totalCalculationType)}>
        <span style={{ marginRight: '22px' }}>{text}</span>
      </Tooltip>
    ),
  },
  createDisabled: (self) =>
    !self.state.filterDto.locationGroupId || isEmpty(self.state.filterDto.overstockLocationName),
  transformGetItemsParams: (self) => ({ overstockLocationName: undefined }),
  extraTableButtons: (self) => {
    if (isEmpty(self.state.items)) {
      return null
    }

    const items = self.state.items.filter((existing) => {
      const original = self.props.items.find((one) => one.id === existing.id)

      return !isEqual(existing, original)
    })

    const updateItems = async () => {
      try {
        self.setState({ saveButtonLoading: true })

        const response = await self.props.updateItems(items)

        showClientNotifications({ response })

        self.setState({ saveButtonLoading: false }, self.fetchItems)
      } catch (error) {
        showClientNotifications({ error })
        showError({ error })
      } finally {
        self.setState({ saveButtonLoading: false })
      }
    }

    return (
      <>
        <Tooltip title={t('unsavedChangesWillBeLost')}>
          <Button
            className="mr-6"
            onClick={() => self.setState({ items: cloneDeep(self.props.items) })}
            disabled={!self.hasUnsavedChanges()}
          >
            {t('cancel')}
          </Button>
        </Tooltip>
        <Button
          type="primary"
          onClick={() => {
            if (items.some((one) => one.onHand === 0 && one.onHand2 === 0)) {
              Modal.confirm({
                autoFocusButton: 'ok',
                title: t('confirmMultipleDeleteForZeroOnHand'),
                okText: t('continue'),
                okType: 'primary',
                cancelText: t('cancel'),
                onOk: updateItems,
              })
            } else {
              updateItems()
            }
          }}
          loading={self.state.saveButtonLoading}
          disabled={!self.hasUnsavedChanges()}
        >
          {t('save')}
        </Button>
      </>
    )
  },
})(Filter)
