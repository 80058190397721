import produce from 'immer'
import { isEmpty } from 'lodash'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default createActions(entityNames.suppliers, {
  useSelectionList: true,
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.alwaysIncludeSupplierIds =
        draft.alwaysIncludeSupplierIds === false ? false : !isEmpty(draft.supplierIds)
    }),
  transformGetOptionsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.alwaysIncludeSupplierIds =
        draft.alwaysIncludeSupplierIds === false ? false : !isEmpty(draft.supplierIds)
      draft.active = draft.active ?? 'Active'
    }),
})
