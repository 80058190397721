import * as React from 'react'
import { Form } from 'antd'
import { range } from 'lodash'
import { showError } from 'helpers/errors'
import { createDropdownRender, createSearchAssets, createSearchOperators } from 'helpers/formViews'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import { Row, Col } from 'elements/Grid'

const dateRangeFields = {
  All: 'all',
  CreatedDate: 'createdDate',
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchAssets = createSearchAssets(this)
    this.searchOperators = createSearchOperators(this)
  }

  componentDidMount() {
    const { locationGroupIds, locationIds, assetCategoryIds, assetIds } = this.props.filterDto

    Promise.all([
      this.props.getLocationGroups(),
      this.props.getLocations({ locationGroupIds, locationIds }),
      this.props.getJobGroups({ includeUnassignedSelection: false }),
      this.props.getAssetCategories(),
      this.props.getJobReasons(),
      this.props.getAssets({ assetCategoryIds, assetIds }),
      this.props.getUsers(),
    ]).catch((error) => showError({ error }))
  }

  render() {
    const { filterDto, assets, enableAssetManagement } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            {enableAssetManagement && (
              <DateRangePicker
                filterDto={filterDto}
                setFilterValue={this.setFilterValue}
                dateRangeFields={dateRangeFields}
              />
            )}
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('jobGroup')}>
              <Select
                onChange={(value) => this.setFilterValue('jobGroupIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.jobGroupIds}
              >
                {(this.props.jobGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {!enableAssetManagement && (
              <Form.Item label={t('priority')}>
                <Select
                  onChange={(value) => this.setFilterValue('priority', value)}
                  value={filterDto.priority}
                  placeholder={t('all')}
                  allowClear
                >
                  {range(1, 7).map((each) => (
                    <Option key={each} value={each}>
                      {each}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {enableAssetManagement && (
              <>
                <Form.Item label={t('reason')}>
                  <Select
                    onChange={(value) => this.setFilterValue('jobReasonIds', value)}
                    mode="multiple"
                    placeholder={t('all')}
                    value={filterDto.jobReasonIds}
                  >
                    {(this.props.jobReasons ?? []).map((each) => (
                      <Option key={each.id} value={each.id}>
                        <span {...getOptionProps(each)}>{each.displayName}</span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={t('assetCategory')}>
                  <Select
                    onChange={(value) => this.setFilterValue('assetCategoryIds', value)}
                    mode="multiple"
                    placeholder={t('all')}
                    value={filterDto.assetCategoryIds}
                  >
                    {(this.props.assetCategories ?? []).map((each) => (
                      <Option key={each.id} value={each.id}>
                        <span {...getOptionProps(each)}>{each.displayName}</span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={t('asset')}>
                  <Select
                    onChange={(values) => this.setFilterValue('assetIds', values)}
                    value={filterDto.assetIds}
                    dropdownRender={createDropdownRender(assets)}
                    onSearch={this.searchAssets}
                    placeholder={t('all')}
                    mode="multiple"
                    showSearch
                  >
                    {(assets?.items ?? []).map((each) => (
                      <Option key={each.id} value={each.id}>
                        <span {...getOptionProps(each)}>{each.displayName}</span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
          </Col>
          <Col third>
            <Form.Item label={t('createdBy')}>
              <Select
                onChange={(value) => this.setFilterValue('createdBy', value)}
                value={filterDto.createdBy}
                placeholder={t('all')}
                showSearch
              >
                {(this.props.users ?? []).map((each) => (
                  <Option key={each.userName} value={each.userName}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {enableAssetManagement && (
              <Form.Item label={t('priority')}>
                <Select
                  onChange={(value) => this.setFilterValue('priority', value)}
                  value={filterDto.priority}
                  placeholder={t('all')}
                  allowClear
                >
                  {range(1, 7).map((each) => (
                    <Option key={each} value={each}>
                      {each}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
