import { isBoolean } from 'lodash'
import { Input } from 'antd'
import produce from 'immer'
import { createChildListViewComponent } from 'factories/ChildListView'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import { isReadOnly } from 'helpers/formViews'
import Checkbox from 'elements/Checkbox'

const createCheckboxTableCellComponent = (field) => (self, item) => (
  <Checkbox
    checked={item[field]}
    onChange={async (e) => {
      try {
        await self.props.onChange(
          produce(self.props.items, (draft) => {
            draft.find((one) => one.id === item.id)[field] = e.target.checked
          })
        )
      } catch (error) {
        console.warn(error)
      }
    }}
    readOnly={isReadOnly(self)}
  />
)

export default createChildListViewComponent({
  entityName: entityNames.customers,
  childName: 'tagSettings',
  allowCreate: (self) => false,
  allowDelete: (self) => false,
  allowSelection: (self) => false,
  allowCustomize: (self) => false,
  allowPagination: (self) => false,
  allowSorting: (self) => false,
  tableCellComponents: {
    label: (self, item) => (
      <Input
        value={item.label}
        onChange={async (e) => {
          try {
            await self.props.onChange(
              produce(self.props.items, (draft) => {
                draft.find((one) => one.id === item.id).label = e.target.value
              })
            )
          } catch (error) {
            console.warn(error)
          }
        }}
        readOnly={isReadOnly(self)}
      />
    ),
    enabled: createCheckboxTableCellComponent('enabled'),
    enabledPurch: createCheckboxTableCellComponent('enabledPurch'),
    required: createCheckboxTableCellComponent('required'),
    restricted: createCheckboxTableCellComponent('restricted'),
    enabledAssets: (self, item) =>
      isBoolean(item.enabledAssets) ? (
        <Checkbox
          checked={item.enabledAssets}
          onChange={async (e) => {
            try {
              await self.props.onChange(
                produce(self.props.items, (draft) => {
                  draft.find((one) => one.id === item.id).enabledAssets = e.target.checked
                })
              )
            } catch (error) {
              console.warn(error)
            }
          }}
          readOnly={isReadOnly(self)}
        />
      ) : (
        t('na')
      ),
    showList: (self, item) =>
      isBoolean(item.showList) ? (
        <Checkbox
          checked={item.showList}
          onChange={async (e) => {
            try {
              await self.props.onChange(
                produce(self.props.items, (draft) => {
                  draft.find((one) => one.id === item.id).showList = e.target.checked
                })
              )
            } catch (error) {
              console.warn(error)
            }
          }}
          readOnly={isReadOnly(self)}
        />
      ) : (
        t('na')
      ),
  },
})()
