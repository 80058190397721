import styled from 'styled-components'
import { Card } from 'antd'
import { Row } from 'elements/Grid'

const root = document.getElementById('root')
const print = document.createElement('div')
print.setAttribute('id', 'print')
document.body.appendChild(print)

export const createPrintHandler = (printableRef) => () => {
  if (root && print) {
    root.classList.add('hidden')
    print.innerHTML = printableRef?.current?.innerHTML ?? ''
    window.print()
  }
}

export const resetPrintHandler = () => {
  if (root && print) {
    print.innerHTML = ''
    root.classList.remove('hidden')
  }
}

export const Header = styled(Card)`
  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-card-body {
    padding: 12px;
  }

  .ant-form-item-label {
    font-weight: normal;
    line-height: 24px;
  }
`

export const Title = styled(Row)`
  page-break-before: always;

  h2 {
    display: inline-block;
    white-space: nowrap;
    font-size: 20px;
    line-height: 41px;
  }

  img {
    height: 41px;
  }

  @media screen {
    margin-top: -12px;
    margin-bottom: 12px;
  }

  @media print {
    margin: 12px 0;
  }
`
