import { createListViewComponent } from 'factories/ListView'
import Filter from 'containers/Jobs/Future/Filter'

export default createListViewComponent({
  entityName: 'futureJobs',
  filterTemplateType: 'FutureJob',
  initialFilterDto: (self) => ({
    approvalStatus: 'All',
    overdueStatus: 'All',
    dateRangeField: 'All',
    dateRange: { dateRangeType: 'YearToDate' },
    isFuture: true,
  }),
  allowSelection: (self) => false,
  allowDelete: (self) => false,
  allowCreate: (self) => false,
  getSettingsType: (self) => 'futureJobs',
  transformGetItemsParams: (self) => ({
    isFuture: true,
    status: 'All',
    approvalStatus: 'All',
    overdueStatus: 'All',
    closed: false,
  }),
})(Filter)
