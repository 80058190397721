import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/JobCostReport/Filter'

export default createListViewComponent({
  entityName: entityNames.jobCostReport,
  filterTemplateType: 'JobCostReport',
  initialFilterDto: (self) => ({
    dateRange: { dateRangeType: 'YearToDate' },
    dateRangeField: 'JobCreateDate',
    completionStatus: 'All',
    includeSupplierLabour: true,
  }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
})(Filter)
