import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import statusOptions from 'options/statusOptions'
import versionOptions from 'options/formTemplates/versionOptions'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      await this.props.getFormGroups()
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto = {} } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <Form.Item label={t('formGroup')}>
              <Select
                onChange={(value) => this.setFilterValue('formGroupId', value)}
                placeholder={t('all')}
                value={filterDto.formGroupId}
                allowClear
              >
                {(this.props.formGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) => this.setFilterValue('active', value || 'Active')}
                value={filterDto.active || 'Active'}
              >
                {Object.entries(statusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('applicationVersion')}>
              <Select
                onChange={(value) => this.setFilterValue('versionType', value || 'NonLegacy')}
                value={filterDto.versionType}
              >
                {Object.entries(versionOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
