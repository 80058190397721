import produce from 'immer'
import { Form, Row, Col } from 'antd'
import { get, set, isNil, unset } from 'lodash'
import dateRangeTypes from 'options/dateRangeTypes'
import { t } from 'helpers/i18n'
import { getStartDate, getEndDate } from 'helpers/dateTime'
import { createLabelFactory } from 'helpers/formViews'
import comparisonRankingTypes from 'options/dashboards/comparisonRankingTypes'
import Select, { Option } from 'elements/Select'
import InputNumber from 'elements/InputNumber'
import DatePicker from 'elements/DatePicker'

function Component(props) {
  const { item = {}, onChange = () => {}, readOnly } = props

  const dateRangeType = get(item, 'comparisonDateRangeType', 'LastYear')
  const isTypeCustom = dateRangeType === 'Custom'
  const isTypeLastN = dateRangeType.startsWith('LastN')
  const lastNValue = item?.comparisonLastNValue ?? 1
  const pickersDisabled = !isTypeCustom

  function handleDateRangeTypeChange(value) {
    onChange(
      produce(item, (draft) => {
        if (value === 'Custom') {
          draft.comparisonStartDate = getStartDate(dateRangeType, lastNValue)
          draft.comparisonEndDate = getEndDate(dateRangeType, lastNValue)
        } else {
          unset(draft, 'comparisonStartDate')
          unset(draft, 'comparisonEndDate')
        }

        if (value.startsWith('LastN')) {
          draft.comparisonLastNValue = lastNValue
        } else {
          unset(draft, 'comparisonLastNValue')
        }

        draft.comparisonDateRangeType = value
      })
    )
  }

  function setItemValue(path, value) {
    onChange(
      produce(item, (draft) => {
        if (isNil(value)) {
          set(draft, path, undefined)
        } else {
          set(draft, path, value)
        }
      })
    )
  }

  const createLabel = createLabelFactory(props.fieldSettings)

  return (
    <>
      <Form.Item label={createLabel('comparisonDateRangeType')}>
        <Select
          style={{
            width: isTypeLastN ? 'calc(50% - 3px)' : '100%',
            marginRight: isTypeLastN ? '6px' : '0px',
          }}
          onChange={handleDateRangeTypeChange}
          value={dateRangeType}
          allowClear={false}
          readOnly={readOnly}
        >
          {Object.entries(dateRangeTypes).map(([key, value]) => (
            <Option key={key} value={key}>
              {t(value)}
            </Option>
          ))}
        </Select>
        <InputNumber
          onChange={(value) => setItemValue('comparisonLastNValue', Math.max(value, 1))}
          style={{
            width: 'calc(50% - 3px)',
            display: !isTypeLastN ? 'none' : undefined,
          }}
          value={item?.comparisonLastNValue}
          min={1}
          readOnly={readOnly}
        />
      </Form.Item>
      <Form.Item label={t('dateRange')}>
        <Row gutter={6}>
          <Col xs={12}>
            <DatePicker
              value={isTypeCustom ? item?.comparisonStartDate : getStartDate(dateRangeType, lastNValue)}
              placeholder={t('startDate')}
              setTimeMode="start"
              onChange={(value) => setItemValue('comparisonStartDate', value.toJSON())}
              disabled={pickersDisabled}
              allowClear={false}
              readOnly={readOnly}
            />
          </Col>
          <Col xs={12}>
            <DatePicker
              value={isTypeCustom ? item?.comparisonEndDate : getEndDate(dateRangeType, lastNValue)}
              placeholder={t('endDate')}
              setTimeMode="end"
              onChange={(value) => setItemValue('comparisonEndDate', value.toJSON())}
              disabled={pickersDisabled}
              allowClear={false}
              readOnly={readOnly}
            />
          </Col>
        </Row>
      </Form.Item>
      <Row gutter={6}>
        <Col>
          <Form.Item label={createLabel('comparisonRankingType')}>
            <Select
              value={get(item, 'comparisonRankingType', 'LowerPrimaryIsBetter')}
              onChange={(value) => setItemValue('comparisonRankingType', value)}
              allowClear={false}
            >
              {Object.entries(comparisonRankingTypes).map(([key, value]) => (
                <Option key={key} value={key}>
                  {t(value)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default Component
