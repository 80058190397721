import { Button, message } from 'antd'
import { createChildListViewComponent } from 'factories/ChildListView'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'

export default createChildListViewComponent({
  entityName: entityNames.assets,
  childName: 'assetHistory',
  allowCreate: (self) => false,
  allowDelete: (self) => false,
  allowSelection: (self) => false,
  extraTableButtons: (self) => (
    <Button size="small" onClick={() => message.warn(t('underDevelopment'))}>
      <Icon type="FileDownload" />
    </Button>
  ),
  allowCustomize: (self) => true,
})()
