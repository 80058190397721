import * as React from 'react'
import styled from 'styled-components'
import { Spin, Empty } from 'antd'
import { useImmer } from 'use-immer'
import { toString as str, get, set } from 'lodash'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/scatter'
import 'echarts/lib/chart/gauge'
import 'echarts/lib/component/grid'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/legendScroll'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/toolbox'
import { showError } from 'helpers/errors'
import { tryParseJSON } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { Emitter } from 'helpers/events'
import { DASHBOARD_CHART_FILTER_OPEN, DASHBOARD_FETCH_TEMPLATE, DASHBOARD_REFRESH_DATA } from 'options/events'
import Drawer from 'elements/Drawer'
import Area from './Area'
import Bar from './Bar'
import Column from './Column'
import Donut from './Donut'
import Gauge from './Gauge'
import Line from './Line'
import Pie from './Pie'
import Scatter from './Scatter'
import Scorecard from './Scorecard'
import Filter from 'containers/DashboardViewer/Filter'

const chartTypes = {
  Area,
  Bar,
  Column,
  Donut,
  Gauge,
  Line,
  Pie,
  Scatter,
  Scorecard,
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: #d9d9d9;
  border-style: ${(props) => (props.showBorder ? 'solid' : 'none')};
  min-height: ${(props) => `${props.sectionHeight}px`};
`

function Component(props) {
  const {
    chartId,
    section,
    theme,
    getChart,
    getChartData,
    updateChart,
    active,
    readOnly,
    showToolbox,
    ...rest
  } = props

  const [state, updateState] = useImmer({ loading: true, chart: {} })

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  async function fetchChart() {
    try {
      setState('loading', true)

      const $chart = await getChart(chartId).then((r) => r.value.data)

      const filterDto = $chart.linkToDashboardFilter ? props.filterDto : tryParseJSON($chart.filterJson, {})

      const $data = $chart.userIsAuthorized
        ? await getChartData({ chartId, ...filterDto }).then((r) => r.value.data)
        : null

      updateState((draft) => {
        draft.chart = $chart
        draft.data = $data
      })
    } catch (error) {
      showError({ error })
    } finally {
      setState('loading', false)
    }
  }

  async function refreshChartData() {
    try {
      if (state.chart?.userIsAuthorized) {
        const filterDto = state.chart?.linkToDashboardFilter
          ? props.filterDto
          : tryParseJSON(state.chart?.filterJson, {})

        const $data = await getChartData({ chartId, ...filterDto }).then((r) => r.value.data)

        setState('data', $data)
      } else {
        setState('data', null)
      }
    } catch (error) {
      showError({ error })
    }
  }

  function handleFilterSettingsOpen(id) {
    if (str(id) === str(chartId)) {
      setState('filterSettingsVisible', true)
    }
  }

  async function handleFilterSettingsUpdate(values) {
    try {
      setState('filterSettingsSaving', true)

      await updateChart({ ...state.chart, filterJson: JSON.stringify(values) })
      await fetchChart()

      setState('filterSettingsVisible', false)
    } catch (error) {
      showError({ error })
    } finally {
      Emitter.emit(DASHBOARD_FETCH_TEMPLATE)

      setState('filterSettingsSaving', false)
    }
  }

  function handleFilterSettingsClose() {
    setState('filterSettingsVisible', false)
  }

  React.useEffect(() => {
    fetchChart()
  }, [chartId])

  React.useEffect(() => {
    if (state.chart?.linkToDashboardFilter) {
      fetchChart()
    }
  }, [JSON.stringify(props.filterDto)])

  React.useEffect(() => {
    Emitter.on(DASHBOARD_CHART_FILTER_OPEN, handleFilterSettingsOpen)

    return () => Emitter.off(DASHBOARD_CHART_FILTER_OPEN, handleFilterSettingsOpen)
  }, [])

  const refreshInterval = React.useRef(null)

  React.useEffect(() => {
    if (state.chart?.linkToDashboardRefresh) {
      Emitter.on(DASHBOARD_REFRESH_DATA, refreshChartData)

      return () => Emitter.off(DASHBOARD_REFRESH_DATA, refreshChartData)
    }

    window.clearInterval(refreshInterval.current)

    if (active) {
      refreshInterval.current = window.setInterval(
        () => {
          refreshChartData()
        },
        get(state, 'chart.dataRefreshMinutes', 60) * 60 * 1000
      )
    }

    return () => {
      window.clearInterval(refreshInterval.current)
    }
  }, [get(state, 'chart.dataRefreshMinutes', 60), state.chart?.linkToDashboardRefresh, active])

  const { showBorder } = tryParseJSON(state.chart?.chartJson, {})
  const Chart = chartTypes[state.chart?.chartType]

  return (
    <>
      <Container showBorder={showBorder} sectionHeight={section.height} {...rest}>
        {state.loading ? (
          <Spin />
        ) : !state.chart?.userIsAuthorized ? (
          <Empty description={t('notAuthorizedToViewChart')} />
        ) : Chart ? (
          <Chart chart={state.chart} data={state.data} section={section} showToolbox={showToolbox} />
        ) : (
          <Empty description={null} />
        )}
      </Container>
      <Drawer
        title={t('chartFilterSettings')}
        visible={state.filterSettingsVisible}
        onClose={handleFilterSettingsClose}
        saving={state.filterSettingsSaving}
        size="sm"
      >
        {state.loading ? (
          <Spin />
        ) : (
          <Filter
            template={state.chart}
            filterDto={tryParseJSON(state.chart?.filterJson, {})}
            onUpdate={handleFilterSettingsUpdate}
            onCancel={handleFilterSettingsClose}
            readOnly={readOnly}
          />
        )}
      </Drawer>
    </>
  )
}

export default Component
