import { connect } from 'react-redux'
import selectors from 'selectors'
import actions from 'actions/jobReasonGroups'
import Component from 'components/JobReasons/Filter'

const mapStateToProps = (state, props) => ({
  jobReasonGroups: selectors.jobReasonGroups.selectOptions(state),
})

export default connect(mapStateToProps, { getJobReasonGroups: actions.getOptions })(Component)
