import * as React from 'react'
import styled from 'styled-components'
import { Button, Spin, Card } from 'antd'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'

const Container = styled(Card)`
  position: relative;
  margin-bottom: 16px;
  min-width: 320px;

  .ant-card-body {
    padding: 24px;
  }

  .ant-form-item {
    margin-bottom: 6px !important;
  }

  .ant-form label {
    font-size: 13px;
  }

  .ant-checkbox-wrapper {
    white-space: nowrap;
  }

  .ant-select-search__field__placeholder {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  .ant-select-selection__placeholder {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  *::placeholder {
    color: rgba(0, 0, 0, 0.65) !important;
  }
`

const Buttons = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 9;

  button {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
    padding: 0 3px;
  }
`

function Component(props) {
  const { onClear, onClose, children, clearText = t('clearAll'), loading = false, disabled = false } = props

  return (
    <Spin spinning={loading}>
      <Container>
        <Buttons>
          {onClear && !disabled && (
            <Button type="link" onClick={onClear} size="small">
              {clearText}
            </Button>
          )}
          {onClose && (
            <Button type="link" onClick={onClose} size="small">
              <Icon type="Close" size={14} />
            </Button>
          )}
        </Buttons>
        <>{children}</>
      </Container>
    </Spin>
  )
}

export default Component
