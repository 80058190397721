import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import {
  createDropdownRender,
  createSearchSuppliers,
  createSearchJobs,
  createSearchOperators,
  createSearchAssets,
} from 'helpers/formViews'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import SelectProductCategories from 'containers/ProductCategories/Select'
import { Row, Col } from 'elements/Grid'

const issueLimitStatusOptions = {
  UnderLimit: 'underLimit',
  AtLimit: 'atLimit',
  OverLimitAndAllowed: 'overLimitAndAllowed',
  OverLimitAndDenied: 'overLimitAndDenied',
}

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchSuppliers = createSearchSuppliers(this)
    this.searchJobs = createSearchJobs(this)
    this.searchOperators = createSearchOperators(this)
    this.searchAssets = createSearchAssets(this)
  }

  async componentDidMount() {
    try {
      const {
        assetCategoryIds,
        assetIds,
        jobIds,
        locationGroupIds,
        locationIds,
        operatorGroupIds,
        operatorIds,
        productCategoryIds,
        supplierIds,
      } = this.props.filterDto

      await Promise.all([
        this.props.getLocationGroups(),
        this.props.getLocations({ locationGroupIds, locationIds }),
        this.props.getSuppliers(supplierIds ? { supplierIds } : undefined),
        this.props.getProductCategories(productCategoryIds ? { productCategoryIds } : undefined),
        this.props.getJobs(jobIds ? { jobIds } : undefined),
        this.props.getAssetCategories(),
        this.props.getAssets({ assetCategoryIds, assetIds }),
        this.props.getOperatorGroups(),
        this.props.getOperators({ operatorIds, operatorGroupIds }),
        this.props.getUsers(),
      ])
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto = {}, suppliers, assets, jobs, operators } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker filterDto={filterDto} setFilterValue={this.setFilterValue} />
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('productCategory')}>
              <SelectProductCategories
                value={filterDto.productCategoryIds}
                placeholder={t('all')}
                onChange={(value) => this.setFilterValue('productCategoryIds', value)}
                dataSource={this.props.productCategories ?? []}
              />
            </Form.Item>
            <Form.Item label={t('preferredSupplier')}>
              <Select
                onChange={(value) => this.setFilterValue('supplierIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.supplierIds}
                dropdownRender={createDropdownRender(suppliers)}
                onSearch={this.searchSuppliers}
                showSearch
              >
                <Option value={0}>{t('notAssigned')}</Option>
                {(suppliers?.items ?? [])
                  .filter((each) => each.id)
                  .map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('assetCategory')}>
              <Select
                onChange={(value) => this.setFilterValue('assetCategoryIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.assetCategoryIds}
              >
                {(this.props.assetCategories ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('asset')}>
              <Select
                onChange={(values) => this.setFilterValue('assetIds', values)}
                value={filterDto.assetIds}
                dropdownRender={createDropdownRender(assets)}
                onSearch={this.searchAssets}
                placeholder={t('all')}
                mode="multiple"
                showSearch
              >
                {(assets?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('job')}>
              <Select
                onChange={(value) => this.setFilterValue('jobIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.jobIds}
                dropdownRender={createDropdownRender(jobs)}
                onSearch={this.searchJobs}
                showSearch
              >
                {(jobs?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('operatorGroups')}>
              <Select
                onChange={(values) => this.setFilterValue('operatorGroupIds', values)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.operatorGroupIds}
              >
                <Option value={-1}>{t('unassigned')}</Option>
                {(this.props.operatorGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('operator')}>
              <Select
                onChange={(value) => this.setFilterValue('operatorIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.operatorIds}
                dropdownRender={createDropdownRender(operators)}
                onSearch={this.searchOperators}
                showSearch
              >
                {(operators?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('issuedBy')}>
              <Select
                onChange={(value) => this.setFilterValue('userNames', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.userNames}
                showSearch
              >
                {(this.props.users ?? []).map((each) => (
                  <Option key={each.userName} value={each.userName}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('issueStatus')}>
              <Select
                onChange={(value) => this.setFilterValue('issueLimitStatus', value)}
                value={filterDto.issueLimitStatus}
                placeholder={t('all')}
                allowClear
              >
                {Object.entries(issueLimitStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
