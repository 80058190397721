import { Button } from 'antd'
import { isEmpty } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import ConvertToOrder from 'containers/Reports/InventoryDeficiencyReport/ConvertToOrder'
import Icon from 'elements/Icon'
import Filter from 'containers/Reports/InventoryDeficiencyReport/Filter'

export default createListViewComponent({
  entityName: entityNames.inventoryDeficiencyReport,
  filterTemplateType: 'InventoryDeficiencyReport',
  allowCreate: (self) => false,
  extraRowActions: (self) => {
    const selectedItems = (self.props.items ?? []).filter((each) =>
      (self.state.selectedRowKeys ?? []).includes(each.id)
    )

    return (
      <>
        <Button onClick={() => self.setState({ showConvertToOrder: true })} disabled={isEmpty(selectedItems)}>
          <Icon type="ShoppingCart" /> {t('createOrder')}
        </Button>
        {self.state.showConvertToOrder && (
          <ConvertToOrder
            selectedItems={selectedItems}
            onSave={() => {}}
            onSaveAndClose={() =>
              self.setState({ selectedRowKeys: [], showConvertToOrder: false }, self.fetchItems)
            }
            onCancel={() => self.setState({ showConvertToOrder: false })}
          />
        )}
      </>
    )
  },
  allowDelete: (self) => false,
})(Filter)
