import * as React from 'react'
import moment from 'moment'
import { Spin, Badge, Dropdown, Menu, Tooltip, Button, Form, Input, Radio, Popover, message } from 'antd'
import {
  get,
  debounce,
  isEmpty,
  isEqual,
  toString as str,
  trim,
  toLower,
  remove,
  isFunction,
  isNil,
  pick,
  omit,
  range,
  uniq,
  capitalize,
  camelCase,
  isArray,
  set,
  drop,
} from 'lodash'
import produce from 'immer'
import { showError, showClientNotifications } from 'helpers/errors'
import { createPrintHandler, resetPrintHandler } from 'helpers/print'
import {
  formatValue,
  mapGetCalendarFields,
  mapSearchFields,
  filterUntitled,
  getVisibleColumnKeys,
  isValidLinkTarget,
  getReportJSON,
} from 'helpers/listViews'
import { setStorageItem, getStorageItem } from 'helpers/localStorage'
import linkTargets from 'options/linkTargets'
import {
  mergeAll,
  tryParseJSON,
  strEqual,
  sortByKeys,
  HARD_SPACE,
  DEBOUNCE,
  decodeState,
} from 'helpers/utils'
import { getSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { t } from 'helpers/i18n'
import { getPageTitle } from 'helpers/auth'
import { TOKEN_REFRESHED_BY_FORCE } from 'options/events'
import { STARTUP_REDIRECT_SESSION_KEY } from 'options/auth'
import { Emitter, stopEvent } from 'helpers/events'
import { isWeekend, isValidDate } from 'helpers/dateTime'
import { GOOGLE_COLORS } from 'options/colors'
import Drawer from 'elements/Drawer'
import Toolbar from 'elements/Toolbar'
import Page from 'elements/Page'
import FilterContainer from 'elements/FilterContainer'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import Select, { Option } from 'elements/Select'
import SelectFilterTemplates from 'containers/FilterTemplates/Select'
import UnderDevelopment from 'elements/UnderDevelopment'
import { Row, Col } from 'elements/Grid'
import Customize from 'elements/Customize'
import Help from 'elements/Help'
import Settings from '../containers/Settings'
import {
  Bullet,
  Calendar,
  Cell,
  Color,
  Colors,
  Container,
  Delete,
  More,
  Number,
  SelectMode,
  Summary,
  Tile,
  TileBody,
  TileHeader,
  Title,
  ToggleButton,
  TotalValue,
  Totals,
} from './Styled'

const saveStateFields = [
  'filterDto',
  'search',
  'searchType',
  'selectedColumnKeys',
  'selectedSummaryColumnKeys',
  'sortedColumnKeys',
  'sortedSummaryColumnKeys',
  'sortBy',
  'sortOrder',
  'calendarMode',
  'selectedDate',
  'showLabelsOnTiles',
  'showWeekends',
  'showTotals',
  'showCategorization',
  'showFilter',
  'categorizationFieldName',
]

export default ({
    entityName,
    getStorageKey = (self) =>
      self.props.storageKey
        ? self.props.storageKey
        : entityName
          ? `${entityName}.calendarView`
          : '__CALENDAR_VIEW__',
    getIdField = (self) => 'id',
    getDateField = (self) => 'createdDate',
    filterTemplateType,
    primaryLinkTarget,
    createButtonTextId = 'create',
    createButtonLinkTarget = linkTargets[primaryLinkTarget], // { formComponent, formSize } or memoize((self) => ({ formComponent, formSize }), self => {})
    initialFilterDto = (self) => ({ ...self.props.initialFilterDto }),
    initialSearchFields = (self) => [],
    allowSearching = (self) => true,
    allowSelection = (self) => true,
    allowDelete = (self) => (item) => true,
    allowCreate = (self) => true,
    createDisabled = (self) => false,
    allowActionsMenu = (self) => true,
    getSettingsType = (self) => 'CalendarTiles',
    getSummarySettingsType = (self) => 'CalendarSummary',
    transformGetCalendarItemsParams = (self) => ({}),
    allowCustomize = (self) => true,
    getDeleteItemsOptions = (self) => ({}),
    getTileTitle = (self) => (item) => null,
    getSummaryTitle = (self) => (item) => null,
    getTotalValues = (self) => [],
    getCategorizationFields = (self) => [],
  }) =>
  (Filter) => {
    if (isEmpty(entityName)) {
      throw new Error('entityName is empty')
    }

    return class CalendarView extends React.Component {
      state = {
        calendarMode: 'Month',
        selectedDate: moment().toJSON(),
        search: '',
        searchFields: initialSearchFields(this),
        searchType: 'AllWords',
        sortBy: null,
        sortOrder: null,
        selectedRowKeys: [],
        selectedColumnKeys: [],
        selectedSummaryColumnKeys: [],
        sortedColumnKeys: [],
        sortedSummaryColumnKeys: [],
        categorizationFieldName: '',
        categorizationOptions: [],
        showLabelsOnTiles: false,
        showWeekends: false,
        showCategorization: true,
        showTotals: true,
        items: [],
        fieldSettings: [],
        summaryFieldSettings: [],
        filterTemplates: [],
        filterTemplateKey: Date.now(),
        filterDto: { ...initialFilterDto(this) },
      }

      cancelablePromises = []

      constructor(props) {
        super(props)

        this.printableRef = React.createRef()
        this.saveState = debounce(this.saveState, DEBOUNCE)
        this.searchItems = debounce(this.fetchItems, DEBOUNCE)
      }

      async componentDidMount() {
        await this.fetchSettings()
        await this.resetState()

        const startupLinkTarget = getSessionItem(STARTUP_REDIRECT_SESSION_KEY)

        if (!isEmpty(startupLinkTarget)) {
          try {
            const { linkTargetName, linkTargetText, linkTargetRecord } = startupLinkTarget
            const editDrawerLinkTarget = linkTargets[linkTargetName]
            const { drawerTitleLanguageKey } = editDrawerLinkTarget ?? {}

            if (linkTargetRecord) {
              this.setState({
                editDrawerVisible: true,
                editDrawerSaving: false,
                editDrawerLinkTarget,
                editDrawerLinkTargetRecord: linkTargetRecord,
                editDrawerLinkTargetText: drawerTitleLanguageKey
                  ? `${t(drawerTitleLanguageKey)} - ${linkTargetText}`
                  : linkTargetText,
              })
            }
          } catch (error) {
            showError({ error })
          } finally {
            removeSessionItem(STARTUP_REDIRECT_SESSION_KEY)
          }
        }

        Emitter.on(TOKEN_REFRESHED_BY_FORCE, this.handleTokenRefreshedByForce)
      }

      componentWillUnmount() {
        Emitter.off(TOKEN_REFRESHED_BY_FORCE, this.handleTokenRefreshedByForce)

        this.cancelablePromises.forEach((each) => each.cancel?.())
        this.cancelablePromises = []
      }

      promiseState = (state = {}) => new Promise((resolve) => this.setState(state, resolve))

      handleCustomizeOk = ({ selectedColumnKeys = [], sortedColumnKeys = [] }) =>
        this.setState(
          {
            customizeColumnsVisible: false,
            selectedColumnKeys,
            sortedColumnKeys,
          },
          this.fetchItems
        )

      handleCustomizeSummaryOk = ({ selectedColumnKeys = [], sortedColumnKeys = [] }) =>
        this.setState(
          {
            customizeSummaryColumnsVisible: false,
            selectedSummaryColumnKeys: selectedColumnKeys,
            sortedSummaryColumnKeys: sortedColumnKeys,
          },
          this.fetchItems
        )

      handleTokenRefreshedByForce = async () => {
        await this.fetchSettings()
        await this.fetchItems()
      }

      async componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
          await this.fetchSettings()
          await this.resetState()
        }
      }

      handleFilterChange = (filterDto) => this.setState({ filterDto }, this.searchItems)

      fetchSettings = async () => {
        try {
          const { settingsType = getSettingsType(this), summarySettingsType = getSummarySettingsType(this) } =
            this.props
          const [fieldSettings, summaryFieldSettings] = await Promise.all([
            this.props
              .getSettings({ type: settingsType })
              .then((r) => get(r, 'value.data.fieldSettings', [])),
            this.props
              .getSettings({ type: summarySettingsType })
              .then((r) => get(r, 'value.data.fieldSettings', [])),
          ])

          const nextState = {
            fieldSettings,
            summaryFieldSettings,
          }

          this.setState(nextState)

          return nextState
        } catch (error) {
          showError({ error })

          return error
        }
      }

      resetState = async () => {
        if (this.props.location.search) {
          const decodedState = mergeAll({}, { filterDto: initialFilterDto(this) }, decodeState())

          return this.setState(decodedState, this.fetchItems)
        } else if (filterTemplateType) {
          try {
            this.setState({ filterTemplateLoading: true })

            const { showCategorization, showFilter, showTotals, filterTemplateId } = getStorageItem(
              getStorageKey(this),
              {}
            )

            const filterTemplates = await this.fetchFilterTemplates()

            const filterTemplate = filterTemplateId
              ? await this.props
                  .getFilterTemplate(filterTemplateId)
                  .then((r) => r.value.data)
                  .catch(() => filterTemplates.find(filterUntitled))
              : filterTemplates.find(filterUntitled)

            return this.setState(
              {
                showFilter,
                showTotals,
                showCategorization,
                filterTemplates,
                filterTemplateId: filterTemplate?.id,
                filterTemplateKey: Date.now(),
                ...tryParseJSON(filterTemplate?.filterJSON),
              },
              this.fetchItems
            )
          } catch (error) {
            showError({ error })

            return this.setState({ filterTemplateLoading: false })
          }
        } else {
          return this.setState(
            {
              filterDto: { ...initialFilterDto(this) },
              ...getStorageItem(getStorageKey(this)),
            },
            this.fetchItems
          )
        }
      }

      getSelectedMoment = () => {
        const selectedMoment = moment(this.state.selectedDate)

        if (selectedMoment.isValid()) {
          return selectedMoment
        }

        const selectedDate = moment().toJSON()

        this.setState({ selectedDate })

        return moment(selectedDate)
      }

      getCustomDateRange = () => {
        const selectedMoment = this.getSelectedMoment()

        // this.state.calendarMode === 'Day'
        let customStartDate = selectedMoment.clone().startOf('day')
        let customEndDate = selectedMoment.clone().endOf('day')

        if (this.state.calendarMode === 'Week') {
          customStartDate = selectedMoment.clone().startOf('week')
          customEndDate = selectedMoment.clone().endOf('week')
        }

        if (this.state.calendarMode === 'Month') {
          customStartDate = selectedMoment.clone().startOf('month').clone().startOf('week')
          customEndDate = selectedMoment.clone().endOf('month').clone().endOf('week')
        }

        return {
          customStartDate: customStartDate.toJSON(),
          customEndDate: customEndDate.toJSON(),
        }
      }

      getCalendarDays = () => {
        const { customStartDate, customEndDate } = this.getCustomDateRange()

        const days = moment(customEndDate).diff(moment(customStartDate), 'days')

        return range(days + 1).map((each) => moment(customStartDate).clone().add(each, 'day'))
      }

      // NOTE: Whatever is visible even if not in the same month
      getVisibleItems = () => {
        const dateField = getDateField(this)

        return this.state.items.filter((item) => {
          const dateValue = get(item, dateField)

          if (!isValidDate(dateValue)) {
            return false
          }

          if (this.state.calendarMode !== 'Day' && !this.state.showWeekends && isWeekend(dateValue)) {
            return false
          }

          return true
        })
      }

      // NOTE: Whatever is visible and in the same month
      getTotalableItems = () => {
        if (!isEmpty(this.state.selectedTotal?.ids)) {
          const idField = getIdField(this)

          return this.state.items.filter((each) => this.state.selectedTotal.ids.includes(get(each, idField)))
        }

        const dateField = getDateField(this)
        const selectedMoment = this.getSelectedMoment()

        return this.state.items.filter((item) => {
          const dateValue = get(item, dateField)

          if (!isValidDate(dateValue)) {
            return false
          }

          if (this.state.calendarMode !== 'Day' && !this.state.showWeekends && isWeekend(dateValue)) {
            return false
          }

          if (this.state.calendarMode === 'Month' && !selectedMoment.isSame(moment(dateValue), 'month')) {
            return false
          }

          if (this.state.selectedColor) {
            const tileColors = this.getTileColors(item)
            return this.isSelectedByColor(tileColors)
          }

          return true
        })
      }

      getCategorizationOptions = () => {
        if (!this.state.categorizationFieldName) {
          return []
        }

        const field = getCategorizationFields(this).find(
          (one) => one.dtoFieldName === this.state.categorizationFieldName
        )

        if (field.options) {
          return field.options.map((each) => each.value)
        }

        const unsorted = this.getVisibleItems().map((each) => get(each, this.state.categorizationFieldName))
        const options = unsorted
          .flat()
          .slice()
          .sort()
          .map((each) => str(each))

        return uniq(field?.isArray && unsorted.some((one) => isEmpty(one)) ? ['', ...options] : options)
      }

      getTileColors = (item) => {
        const fieldName = this.state.categorizationFieldName

        if (!fieldName) {
          return [GOOGLE_COLORS[0]]
        }

        const fieldValue = get(item, fieldName)

        const indexes = this.state.categorizationOptions.map((option, index) => {
          if (isArray(fieldValue) && fieldValue.map((each) => str(each)).includes(option)) {
            return index
          }

          if (isArray(fieldValue) && isEmpty(fieldValue) && option === '') {
            return index
          }

          if (fieldValue === option) {
            return index
          }

          return -1
        })

        const field = getCategorizationFields(this).find(
          (one) => one.dtoFieldName === this.state.categorizationFieldName
        )

        if (field.options) {
          const colors = field.options.map((each) => each.color)

          return indexes.filter((each) => each >= 0).map((each) => colors[each])
        }

        return indexes
          .filter((each) => each >= 0)
          .map((each) => GOOGLE_COLORS[Math.max(each, 0) % GOOGLE_COLORS.length])
      }

      fetchItems = async () => {
        this.cancelablePromises.forEach((each) => each.cancel?.())
        this.cancelablePromises = []

        const dateField = getDateField(this)
        const { customStartDate, customEndDate } = this.getCustomDateRange()
        const { settingsType = getSettingsType(this) } = this.props
        const fieldSettings = await this.props
          .getSettings({ type: settingsType })
          .then((r) => get(r, 'value.data.fieldSettings', []))
          .catch(() => [])

        try {
          const cancelablePromise = this.props.getItems({
            dateRange: { customStartDate, customEndDate },
            ...this.state.filterDto,
            ...pick(this.state, ['search', 'searchType']),
            ...mapGetCalendarFields({
              fieldSettings,
              ...pick(this.state, ['searchFields', 'sortBy', 'sortOrder']),
            }),
            ...transformGetCalendarItemsParams(this),
          })

          this.cancelablePromises.push(cancelablePromise)

          const response = await cancelablePromise

          const items = get(response, 'value.data.items', [])
          const reportJSON = getReportJSON(response)
          const outOfRange = items.filter((each) => {
            const dateValue = get(each, dateField)
            return customStartDate > dateValue || dateValue > customEndDate
          })

          console.assert(isEmpty(outOfRange), 'not all returned items belong to the selected date range', {
            customStartDate,
            customEndDate,
            items,
            dateField,
            outOfRange,
          })

          if (items.length > 300 && !window.confirm(t('confirmCalendarItemsInfo'))) {
            this.setState({
              items: [],
              reportJSON,
              fieldSettings,
              summaryVisible: {},
              selectedColor: null,
              selectedTotal: null,
              categorizationOptions: [],
            })
            return
          }

          this.setState(
            {
              items,
              reportJSON,
              fieldSettings,
              summaryVisible: {},
              selectedTotal: null,
              selectedColor: null,
            },
            () => this.setState({ categorizationOptions: this.getCategorizationOptions() })
          )
        } catch (error) {
          showError({ error })
        } finally {
          this.setState({ filterTemplateLoading: false }, () => {
            this.cancelablePromises = []
            this.props.onFilter?.(this.state.filterDto)
            this.props.onSelect?.([], this.state.filterDto)
            this.saveState()
          })
        }
      }

      saveState = async () => {
        if (this.props.location.search) {
          return
        }

        if (!filterTemplateType) {
          setStorageItem(getStorageKey(this), pick(this.state, saveStateFields))
          return
        }

        const filterJSON = this.getFilterJSON()

        const filterTemplates = !isEmpty(this.state.filterTemplates)
          ? this.state.filterTemplates
          : await this.fetchFilterTemplates()

        const filterTemplate =
          filterTemplates.find((one) => one.id === this.state.filterTemplateId) ||
          filterTemplates.find(filterUntitled)

        const { showFilter, showCategorization, showTotals } = this.state
        const filterTemplateId = filterTemplate?.id

        setStorageItem(getStorageKey(this), {
          showFilter,
          showTotals,
          showCategorization,
          filterTemplateId,
        })

        if (filterTemplate && isEmpty(filterTemplate.name) && filterTemplate.filterJSON !== filterJSON) {
          try {
            const saved = await this.props
              .updateFilterTemplate({
                ...filterTemplate,
                filterJSON,
                reportJSON: this.state.reportJSON,
              })
              .then((r) => r.value.data)

            this.setState(
              produce((draft) => {
                remove(draft.filterTemplates, (one) => one.id === saved.id)
                draft.filterTemplates.push(saved)
              })
            )
          } catch (error) {
            showError({ error })
          }
        }
      }

      getFilterJSON = () =>
        JSON.stringify(
          omit(
            {
              ...pick(this.state, saveStateFields),
              searchFields: mapSearchFields(this.state.searchFields, this.state.fieldSettings),
            },
            ['showFilter', 'showCategorization', 'showTotals']
          )
        )

      isSelectedByColor = (colors = []) => {
        if (!this.state.selectedColor) {
          return true
        }

        return colors.includes(this.state.selectedColor)
      }

      isSelectedByTotal = (id) => {
        if (!this.state.selectedTotal) {
          return true
        }

        return this.state.selectedTotal.ids.includes(id)
      }

      handleToggleFilterClick = () =>
        this.setState((prev) => ({ showFilter: !prev.showFilter }), this.saveState)

      handleClearFilterClick = () =>
        this.setState(
          {
            filterDto: { ...initialFilterDto(this) },
            search: '',
            searchType: 'AllWords',
            searchFields: initialSearchFields(this),
          },
          () => {
            if (this.props.location.search) {
              this.props.history.push(this.props.location.pathname)
            }

            this.fetchItems()
          }
        )

      handleLinkTargetClick = ({ column, record, text, readOnly }) => {
        if (process.env.NODE_ENV === 'development') console.log({ column, record, text, readOnly })

        const editDrawerLinkTarget = linkTargets[column.linkTarget]
        const { drawerTitleLanguageKey } = editDrawerLinkTarget ?? {}

        this.setState({
          editDrawerVisible: true,
          editDrawerSaving: false,
          editDrawerLinkTarget,
          editDrawerLinkTargetRecord: record,
          editDrawerLinkTargetColumn: column,
          editDrawerLinkTargetReadOnly: readOnly || column.linkTargetIsReadOnly,
          editDrawerLinkTargetText: drawerTitleLanguageKey ? `${t(drawerTitleLanguageKey)} - ${text}` : text,
        })
      }

      handleConfirmDelete = async () => {
        try {
          this.setState({ deleteButtonLoading: true })

          const { selectedRowKeys = [] } = this.state
          const response = await this.props.deleteItems(selectedRowKeys, getDeleteItemsOptions(this))

          this.props.onDelete?.(selectedRowKeys)

          showClientNotifications({ response })
        } catch (error) {
          showError({ error })
        } finally {
          this.setState({ deleteButtonLoading: false }, this.fetchItems)
        }
      }

      handleDrawerClose = () =>
        this.setState({
          createDrawerVisible: false,
          editDrawerVisible: false,
          createDrawerSaving: false,
          editDrawerSaving: false,
        })

      handleActionsMenuClick = ({ key = '' }) => {
        if (this.hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
          return
        }

        if (this.hasUnsavedFilter()) {
          message.error(t('saveFilterFirst'), 3.5)
          return
        }

        switch (key) {
          case 'customizeColumns':
            this.setState({ customizeColumnsVisible: true })
            break

          case 'customizeSummaryColumns':
            this.setState({ customizeSummaryColumnsVisible: true })
            break

          case 'print':
            if (this.hasUnsavedChanges()) {
              message.error(t('saveChangesFirst'))
            } else {
              this.setState({ printVisible: true })
            }
            break

          default:
            message.warn(t('underDevelopment'))
            break
        }
      }

      handleFilterMenuClick = async ({ key }) => {
        let { filterTemplates } = this.state

        if (key === 'sharedFilterTemplate') {
          this.setState({ selectSharedFilterTemplateVisible: true })
        }

        if (key === 'saveFilterTemplate') {
          try {
            const filterTemplate = filterTemplates.find((one) => one.id === this.state.filterTemplateId)
            const filterJSON = this.getFilterJSON()

            const saved = await this.props
              .updateFilterTemplate({
                ...filterTemplate,
                filterJSON,
                reportJSON: this.state.reportJSON,
              })
              .then((r) => r.value.data)

            this.setState(
              produce((draft) => {
                draft.filterTemplates.find((one) => one.id === saved.id).filterJSON = saved.filterJSON
              }),
              this.fetchItems
            )
          } catch (error) {
            showError({ error })
          }
        }

        if (key === 'moveFilterTemplate') {
          const filterTemplate = filterTemplates.find((one) => one.id === this.state.filterTemplateId)

          this.setState({
            moveFilterTemplateVisible: true,
            moveFilterTemplateInput: filterTemplate.name,
          })
        }

        if (key === 'deleteFilterTemplate') {
          const { numberOfScheduledTasks } = filterTemplates.find(
            (one) => one.id === this.state.filterTemplateId
          )

          Modal.confirm({
            autoFocusButton: 'ok',
            title: t('confirmFilterDeleteTitle'),
            content: numberOfScheduledTasks
              ? `${t('confirmFilterDeleteDescription')} ${numberOfScheduledTasks}`
              : t('confirmUnusedFilterDeleteDescription'),
            okText: t('delete'),
            okType: 'danger',
            cancelText: t('cancel'),
            onOk: async () => {
              try {
                const response = await this.props.deleteFilterTemplate(this.state.filterTemplateId)

                showClientNotifications({ response })

                if (response.value.data.failureCount > 0) {
                  throw new Error()
                }

                filterTemplates = await this.fetchFilterTemplates()

                const filterTemplate = await this.props
                  .getFilterTemplate(filterTemplates.find(filterUntitled).id)
                  .then((r) => r.value.data)

                this.setState(
                  {
                    filterTemplates,
                    filterTemplateId: filterTemplate.id,
                    filterTemplateKey: Date.now(),
                    filterTemplateLoading: true,
                    ...tryParseJSON(filterTemplate.filterJSON),
                  },
                  this.fetchItems
                )
              } catch (error) {
                showError({ error })
              }
            },
          })
        }

        if (key.match(/^\d*$/)) {
          try {
            const filterTemplate = await this.props.getFilterTemplate(key).then((r) => r.value.data)

            this.setState(
              {
                filterTemplateId: filterTemplate.id,
                filterTemplateKey: Date.now(),
                filterTemplateLoading: true,
                ...tryParseJSON(filterTemplate.filterJSON),
              },
              this.fetchItems
            )
          } catch (error) {
            showError({ error })
          }
        }
      }

      handleMoveFilterTemplate = async () => {
        let { filterTemplates } = this.state

        if (this.props.location.search) {
          return
        }

        try {
          this.setState({ moveFilterTemplateSaving: true })

          const name = trim(this.state.moveFilterTemplateInput)
          const filterJSON = this.getFilterJSON()
          const filterTemplate = filterTemplates.find((one) => strEqual(one.name, name))
          const reportJSON = this.state.reportJSON

          const saved =
            filterTemplate && strEqual(filterTemplate.owner, this.props.user.userName)
              ? await this.props.updateFilterTemplate({
                  id: filterTemplate.id,
                  name,
                  filterJSON,
                  filterTemplateType,
                  reportJSON,
                })
              : await this.props.createFilterTemplate({
                  name,
                  filterJSON,
                  filterTemplateType,
                  reportJSON,
                })

          filterTemplates = await this.fetchFilterTemplates()

          this.setState(
            {
              filterTemplates,
              filterTemplateId: saved.value.data.id,
              moveFilterTemplateVisible: false,
            },
            this.fetchItems
          )
        } catch (error) {
          showError({ error })
        } finally {
          this.setState({ moveFilterTemplateSaving: false })
        }
      }

      handleSelectSharedFilterTemplate = async () => {
        try {
          const { id } = this.state.selectedSharedFilterTemplate
          const filterTemplate = await this.props.getFilterTemplate(id).then((r) => r.value.data)

          this.setState(
            {
              filterTemplateId: filterTemplate.id,
              filterTemplateKey: Date.now(),
              filterTemplateLoading: true,
              ...tryParseJSON(filterTemplate.filterJSON),
            },
            this.fetchItems
          )
        } catch (error) {
          showError({ error })
        }
        this.setState({ selectSharedFilterTemplateVisible: false })
      }

      fetchFileTemplates = (fileTemplateTypes) =>
        !isEmpty(fileTemplateTypes)
          ? this.props
              .getFileTemplates({
                objectType: fileTemplateTypes.length === 1 ? fileTemplateTypes[0] : undefined,
              })
              .then((r) => get(r, 'value.data.items', []))
              .catch(() => {})
          : Promise.resolve([])

      fetchFilterTemplates = async () => {
        const filterTemplates = await this.props
          .getFilterTemplates({
            filterTemplateType,
            owner: this.props.user.userName,
            includeShared: true,
          })
          .then((r) => get(r, 'value.data.items', []))

        if (filterTemplates.find(filterUntitled)) {
          return filterTemplates
        }

        await this.props.createFilterTemplate({
          name: '',
          filterTemplateType,
          filterJSON: this.getFilterJSON(),
          reportJSON: this.state.reportJSON,
        })

        return this.fetchFilterTemplates()
      }

      getPageTitle = () => {
        const filterTemplate = (this.state.filterTemplates ?? []).find(
          (one) => one.id === this.state.filterTemplateId
        )

        const owner = get(filterTemplate, 'owner', '')
        const ownerLabel = owner && !strEqual(owner, this.props.user.userName) ? `(${owner})` : ''

        return [getPageTitle(), `${get(filterTemplate, 'name', '')} ${ownerLabel}`]
          .map(trim)
          .filter((each) => !isEmpty(each))
          .join(' - ')
      }

      getCalendarTitle = () => {
        const selectedMoment = this.getSelectedMoment()

        if (this.state.calendarMode === 'Day') {
          return selectedMoment.format('DD MMMM YYYY')
        }

        if (this.state.calendarMode === 'Week') {
          const { customStartDate, customEndDate } = this.getCustomDateRange()

          return `${[customStartDate, customEndDate]
            .map((each) => moment(each).format('MMMM YYYY'))
            .filter((v, i, a) => a.indexOf(v) === i)
            .join(' - ')} (${t('week')} ${selectedMoment.week()})`
        }

        return selectedMoment.format('MMMM YYYY')
      }

      hasUnsavedChanges = () =>
        !isEmpty(this.props.items) &&
        !isEmpty(this.state.items) &&
        !isEqual(this.props.items, this.state.items)

      hasUnsavedFilter = () => {
        try {
          const { filterTemplates, filterTemplateId } = this.state
          const filterTemplate = filterTemplates.find((one) => one.id === filterTemplateId)

          if (isEmpty(filterTemplate?.name)) {
            return false
          }

          return !isEqual(JSON.parse(filterTemplate?.filterJSON), JSON.parse(this.getFilterJSON()))
        } catch (error) {
          return false
        }
      }

      moveSelectedMoment = (delta) => {
        this.setState(
          produce((draft) => {
            const unit = {
              Day: 'days',
              Week: 'weeks',
              Month: 'months',
            }[draft.calendarMode]

            do {
              draft.selectedDate = moment(draft.selectedDate).add(delta, unit).toJSON()
            } while (draft.calendarMode === 'Day' && !draft.showWeekends && isWeekend(draft.selectedDate))

            draft.items = []
            draft.categorizationOptions = []
          }),
          () => this.searchItems()
        )
      }

      filterWeekends = (day) => this.state.showWeekends || !isWeekend(day)

      getSortedColumns = () => {
        const visibleColumnKeys = isEmpty(this.state.selectedColumnKeys)
          ? getVisibleColumnKeys(this.state.fieldSettings)
          : this.state.selectedColumnKeys
        const unsortedColumns = visibleColumnKeys.flatMap((key) =>
          this.state.fieldSettings.filter((one) => one.dtoFieldName === key)
        )
        return sortByKeys(unsortedColumns, this.state.sortedColumnKeys)
      }

      getSortedSummaryColumns = () => {
        const visibleColumnKeys = isEmpty(this.state.selectedSummaryColumnKeys)
          ? getVisibleColumnKeys(this.state.summaryFieldSettings)
          : this.state.selectedSummaryColumnKeys
        const unsortedColumns = visibleColumnKeys.flatMap((key) =>
          this.state.summaryFieldSettings.filter((one) => one.dtoFieldName === key)
        )
        return sortByKeys(unsortedColumns, this.state.sortedSummaryColumnKeys)
      }

      renderCell = (day, { insideModal = false, printPreview = false } = {}) => {
        const maxTilesPerDay = printPreview ? 999 : 3
        const idField = getIdField(this)
        const dateField = getDateField(this)
        const selectedMoment = this.getSelectedMoment()

        if (isEmpty(dateField)) {
          throw new Error('getDateField is returning empty!')
        }

        const isSameMonth = this.state.calendarMode !== 'Month' || day.isSame(selectedMoment, 'month')

        const tiles = (this.state.items ?? []).filter((item) => {
          const dateValue = get(item, dateField)
          return isValidDate(dateValue) && moment(dateValue).isSame(day, 'day')
        })

        const contents = (
          <Cell isSameMonth={isSameMonth}>
            <Number
              isSelected={day.isSame(selectedMoment, 'day')}
              isToday={day.isSame(moment(), 'day')}
              onClick={() => this.setState({ selectedDate: day.toJSON() })}
            >
              {day.format('D')}
            </Number>
            {tiles
              .filter((item, index) => {
                if (this.state.calendarMode === 'Day') {
                  return true
                }

                if (insideModal) {
                  return index >= maxTilesPerDay
                }

                return index < maxTilesPerDay
              })
              .map((item) => {
                const id = get(item, idField)
                const tileTitle = getTileTitle(this)(item)
                const summaryTitle = getSummaryTitle(this)(item)
                const tileColors = this.getTileColors(item)
                const headerColor = tileColors.length > 1 ? '#999' : tileColors[0] ?? GOOGLE_COLORS[0]

                return (
                  <Tile
                    key={id}
                    isSelectedByColor={!isSameMonth || this.isSelectedByColor(tileColors)}
                    isSelectedByTotal={!isSameMonth || this.isSelectedByTotal(id)}
                    className="break-word"
                  >
                    <Popover
                      title={summaryTitle}
                      placement="rightTop"
                      content={
                        <Summary>
                          {this.getSortedSummaryColumns().map((each, index) => {
                            const text = formatValue({
                              value: get(item, each.dtoFieldName),
                              displayFormat: each.displayFormat,
                              dtoFieldName: each.dtoFieldName,
                            })
                            const label = !isEmpty(text) ? text : '---'
                            const link = isValidLinkTarget({
                              linkTarget: each.linkTarget,
                              text,
                              primaryLinkTarget,
                            }) ? (
                              <a
                                onClick={() => {
                                  this.setState(
                                    produce((draft) => {
                                      set(draft, `summaryVisible.${id}`, false)
                                      draft.extraTilesModalVisible = false
                                      draft.extraTilesModalMoment = null
                                      draft.extraTilesModalTitle = ''
                                    })
                                  )
                                  this.handleLinkTargetClick({ record: item, column: each, text, index })
                                }}
                              >
                                {label}
                              </a>
                            ) : (
                              label
                            )

                            return (
                              <div key={each.dtoFieldName} className="mb-6">
                                <div style={{ fontSize: '13px', color: '#999' }}>
                                  {t(
                                    each.columnHeadingLanguageKey ||
                                      each.recordLabelLanguageKey ||
                                      each.dtoFieldName
                                  )}
                                </div>
                                {link}
                              </div>
                            )
                          })}
                        </Summary>
                      }
                      trigger={!printPreview ? ['click'] : []}
                      visible={!printPreview && get(this.state, `summaryVisible.${id}`)}
                      onVisibleChange={(visible) =>
                        this.setState(
                          produce((draft) => {
                            if (!printPreview) {
                              set(draft, `summaryVisible.${id}`, visible)
                            }
                          })
                        )
                      }
                    >
                      <TileHeader
                        style={{
                          backgroundColor: headerColor,
                          border: `1px solid ${headerColor}`,
                        }}
                      >
                        <div>{!isEmpty(tileTitle) ? tileTitle : HARD_SPACE}</div>
                        {!printPreview && (
                          <Delete>
                            <Icon type="MoreHoriz" className="cursor-pointer" />
                            {allowDelete(this)(item) && (
                              <Icon
                                type="Close"
                                className="ml-3 cursor-pointer"
                                onClick={(e) => {
                                  stopEvent(e)
                                  Modal.confirm({
                                    autoFocusButton: 'ok',
                                    title: t('confirmDeleteItem'),
                                    okType: 'danger',
                                    okText: t('delete'),
                                    cancelText: t('cancel'),
                                    onOk: async () => {
                                      try {
                                        this.setState({ loading: true })

                                        const selectedRowKeys = [get(item, idField)]
                                        const response = await this.props.deleteItems(
                                          selectedRowKeys,
                                          getDeleteItemsOptions(this)
                                        )

                                        this.props.onDelete?.(selectedRowKeys)

                                        showClientNotifications({ response })
                                      } catch (error) {
                                        showError({ error })
                                      } finally {
                                        this.setState({ loading: false }, this.fetchItems)
                                      }
                                    },
                                  })
                                }}
                              />
                            )}
                          </Delete>
                        )}
                      </TileHeader>
                    </Popover>
                    <TileBody>
                      {tileColors.length > 1 &&
                        tileColors.map((each) => (
                          <Bullet key={each} color={each}>
                            {HARD_SPACE}
                          </Bullet>
                        ))}
                      {this.getSortedColumns().map((each, index, list) => {
                        const text = formatValue({
                          value: get(item, each.dtoFieldName),
                          displayFormat: each.displayFormat,
                          dtoFieldName: each.dtoFieldName,
                        })
                        const label = !isEmpty(text) ? text : '---'
                        const link = isValidLinkTarget({
                          linkTarget: each.linkTarget,
                          text,
                          primaryLinkTarget,
                        }) ? (
                          <a
                            onClick={() =>
                              this.handleLinkTargetClick({ record: item, column: each, text, index })
                            }
                          >
                            {label}
                          </a>
                        ) : (
                          label
                        )

                        if (this.state.calendarMode === 'Day') {
                          return (
                            <span key={each.dtoFieldName}>
                              {this.state.showLabelsOnTiles && (
                                <span style={{ color: '#999' }}>
                                  {t(
                                    each.columnHeadingLanguageKey ||
                                      each.recordLabelLanguageKey ||
                                      each.dtoFieldName
                                  )}
                                  :{' '}
                                </span>
                              )}
                              {link} {index < list.length - 1 ? <span className="separator"> | </span> : null}
                            </span>
                          )
                        }

                        return (
                          <div key={each.dtoFieldName}>
                            {this.state.showLabelsOnTiles && (
                              <div style={{ fontSize: '13px', color: '#999' }}>
                                {t(
                                  each.columnHeadingLanguageKey ||
                                    each.recordLabelLanguageKey ||
                                    each.dtoFieldName
                                )}
                              </div>
                            )}
                            {link}
                          </div>
                        )
                      })}
                    </TileBody>
                  </Tile>
                )
              })}
            {!insideModal && this.state.calendarMode !== 'Day' && tiles.length > maxTilesPerDay ? (
              <More
                onClick={() =>
                  this.setState({
                    extraTilesModalTitle: capitalize(day.format('dddd')),
                    extraTilesModalVisible: true,
                    extraTilesModalMoment: day,
                  })
                }
                style={
                  isSameMonth &&
                  this.state.selectedColor &&
                  drop(tiles, maxTilesPerDay).some((tile) =>
                    this.getTileColors(tile).includes(this.state.selectedColor)
                  )
                    ? { borderColor: this.state.selectedColor }
                    : {}
                }
              >{`${tiles.length - maxTilesPerDay} ${t('more...')}`}</More>
            ) : null}
          </Cell>
        )

        if (insideModal) {
          return contents
        }

        return (
          <td
            key={day.toJSON()}
            style={{
              ...(isWeekend(day) ? { backgroundColor: '#fcfcfc' } : {}),
              opacity: isSameMonth ? 1 : 0.4,
            }}
            onClick={
              !isSameMonth ? () => this.setState({ selectedDate: day.toJSON() }, this.fetchItems) : undefined
            }
          >
            {contents}
          </td>
        )
      }

      render() {
        const { items = [], fieldSettings = [], summaryFieldSettings = [], filterTemplates = [] } = this.state

        if (isEmpty(fieldSettings)) {
          return (
            <Page className="tofino-calendar-view" title={this.getPageTitle()} scrollable loading>
              <Spin />
            </Page>
          )
        }

        const selectedMoment = this.getSelectedMoment()
        const field = getCategorizationFields(this).find(
          (one) => one.dtoFieldName === this.state.categorizationFieldName
        )
        const filterTemplateName = filterTemplates.find((one) => one.id === this.state.filterTemplateId)?.name
        const filterTemplateIsShared =
          toLower(filterTemplates.find((one) => one.id === this.state.filterTemplateId)?.owner) !==
          toLower(this.props.user.userName)
        const CreateFormView = get(
          isFunction(createButtonLinkTarget) ? createButtonLinkTarget(this) : createButtonLinkTarget,
          'formComponent',
          UnderDevelopment
        )
        const EditFormView = this.state.editDrawerLinkTarget?.formComponent ?? UnderDevelopment
        const columnWidth = this.state.showWeekends ? '14.28%' : '20%'
        const calendarDays = this.getCalendarDays()
        const totalValues = getTotalValues(this)
        const allowTotals = !isEmpty(totalValues)
        const loading =
          this.props.loading ||
          this.state.loading ||
          this.state.deleteButtonLoading ||
          this.state.deactivateButtonLoading ||
          this.state.filterTemplateLoading

        const renderCalendar = (printPreview) => (
          <Container>
            {printPreview && (
              <Title>
                <h2>
                  {this.getPageTitle()} - {this.getCalendarTitle()}
                </h2>
              </Title>
            )}
            <table>
              {!printPreview && (
                <thead>
                  <tr>
                    {this.state.showCategorization ? (
                      <th style={{ width: '250px' }}>
                        <Row gutter={0}>
                          <Col xs={12}>{t('categorization')}</Col>
                          <Col xs={12} className="text-right">
                            <ToggleButton
                              onClick={() =>
                                this.setState(
                                  { showCategorization: false, selectedColor: null },
                                  this.saveState
                                )
                              }
                            >
                              <Icon type="KeyboardArrowLeft" size={24} />
                            </ToggleButton>
                          </Col>
                        </Row>
                      </th>
                    ) : null}
                    <th>
                      <Row gutter={0}>
                        <Col xs={12}>
                          {!this.state.showCategorization ? (
                            <Tooltip title={t('showCategorization')} placement="topRight">
                              <ToggleButton
                                onClick={() => this.setState({ showCategorization: true }, this.saveState)}
                                style={{ marginRight: '18px' }}
                              >
                                <Icon type="KeyboardArrowRight" size={24} />
                              </ToggleButton>
                            </Tooltip>
                          ) : null}
                          <Button.Group className="mr-12">
                            <Button
                              onClick={() =>
                                this.setState({ selectedDate: moment().toJSON() }, this.fetchItems)
                              }
                            >
                              {t('today')}
                            </Button>
                            <Button onClick={() => this.moveSelectedMoment(-1)}>
                              <Icon type="KeyboardArrowLeft" />
                            </Button>
                            <Button onClick={() => this.moveSelectedMoment(+1)}>
                              <Icon type="KeyboardArrowRight" />
                            </Button>
                          </Button.Group>{' '}
                          {this.getCalendarTitle()}
                        </Col>
                        <Col xs={12} className="text-right">
                          <SelectMode
                            value={this.state.calendarMode}
                            buttonStyle="solid"
                            onChange={(e) => this.setState({ calendarMode: e.target.value }, this.fetchItems)}
                          >
                            {['Day', 'Week', 'Month'].map((each) => (
                              <Radio.Button key={each} value={each}>
                                {t(camelCase(each))}
                              </Radio.Button>
                            ))}
                          </SelectMode>
                          <Button
                            style={{ padding: '1px 8px', marginLeft: '6px' }}
                            onClick={() => this.setState({ settingsVisible: true })}
                          >
                            <Icon type="Settings" />
                          </Button>
                          {!this.state.showTotals && allowTotals && (
                            <Tooltip title={t('showTotals')}>
                              <ToggleButton
                                style={{ marginLeft: '18px' }}
                                onClick={() => this.setState({ showTotals: true }, this.saveState)}
                              >
                                <Icon type="KeyboardArrowDown" size={24} />
                              </ToggleButton>
                            </Tooltip>
                          )}
                        </Col>
                      </Row>
                    </th>
                  </tr>
                </thead>
              )}
              <tbody>
                <tr>
                  {this.state.showCategorization && (
                    <td style={{ width: '250px' }}>
                      <Form.Item label={t('colourCodeTilesBy')} colon={false}>
                        <Select
                          value={this.state.categorizationFieldName || ''}
                          onChange={(value) =>
                            this.setState({ categorizationFieldName: value || '' }, () =>
                              this.setState(
                                {
                                  selectedColor: null,
                                  categorizationOptions: this.getCategorizationOptions(),
                                },
                                this.saveState
                              )
                            )
                          }
                        >
                          <Option value="">{t('noColourCoding')}</Option>
                          {getCategorizationFields(this)
                            .map((each) =>
                              this.state.fieldSettings.find((one) => one.dtoFieldName === each.dtoFieldName)
                            )
                            .filter(Boolean)
                            .map((each) => (
                              <Option key={each.dtoFieldName} value={each.dtoFieldName}>
                                {t(
                                  each.columnHeadingLanguageKey ||
                                    each.recordLabelLanguageKey ||
                                    each.dtoFieldName
                                )}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={
                          <span>
                            {t('colours')} {!printPreview && <Help title={t('calendarColoursInfo')} />}
                          </span>
                        }
                        colon={false}
                      >
                        <Colors printPreview>
                          {this.state.categorizationFieldName ? (
                            this.state.categorizationOptions.map((each, index) => {
                              const googleColor = GOOGLE_COLORS[index % GOOGLE_COLORS.length]
                              const color = field?.options
                                ? field.options.find((one) => one.value === each)?.color ?? googleColor
                                : googleColor

                              return (
                                <Color
                                  key={`${each}-${index}`}
                                  onClick={() =>
                                    this.setState(
                                      produce((draft) => {
                                        draft.selectedTotal = null
                                        draft.selectedColor = draft.selectedColor === color ? null : color
                                      })
                                    )
                                  }
                                  isSelected={color === this.state.selectedColor}
                                >
                                  <Bullet color={color}>{HARD_SPACE}</Bullet>
                                  <span style={{ verticalAlign: 'middle' }}>{each || t('none')}</span>
                                </Color>
                              )
                            })
                          ) : (
                            <Color>
                              <Bullet color={GOOGLE_COLORS[0]}>{HARD_SPACE}</Bullet>
                              <span style={{ verticalAlign: 'middle' }}>{t('allTiles')}</span>
                            </Color>
                          )}
                        </Colors>
                      </Form.Item>
                    </td>
                  )}
                  <td>
                    {this.state.showTotals && allowTotals && (
                      <Totals>
                        {!printPreview && (
                          <Icon
                            type="Close"
                            style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }}
                            onClick={() => this.setState({ showTotals: false, selectedTotal: null })}
                          />
                        )}
                        <div className="flex">
                          {totalValues.map((each) => (
                            <TotalValue
                              key={each.key}
                              onClick={() =>
                                this.setState(
                                  produce((draft) => {
                                    draft.selectedTotal = each.key === draft.selectedTotal?.key ? null : each
                                  })
                                )
                              }
                              isSelected={each.key === this.state.selectedTotal?.key}
                            >
                              <div>{each.title}</div>
                              <div>{each.value}</div>
                            </TotalValue>
                          ))}
                        </div>
                      </Totals>
                    )}
                    <Calendar>
                      <table>
                        <thead>
                          <tr>
                            {this.state.calendarMode === 'Day' && (
                              <th
                                style={{
                                  backgroundColor: isWeekend(this.state.selectedDate) ? '#fbfbfb' : 'inherit',
                                }}
                              >
                                {capitalize(selectedMoment.format('dddd'))}
                              </th>
                            )}
                            {this.state.calendarMode === 'Week' &&
                              calendarDays.filter(this.filterWeekends).map((day) => (
                                <th
                                  key={day.toJSON()}
                                  style={{
                                    width: columnWidth,
                                    backgroundColor: isWeekend(day) ? '#fbfbfb' : 'inherit',
                                  }}
                                >
                                  {capitalize(day.format('dddd'))}
                                </th>
                              ))}
                            {this.state.calendarMode === 'Month' &&
                              calendarDays
                                .filter((day, index) => index < 7)
                                .filter(this.filterWeekends)
                                .map((day) => (
                                  <th
                                    key={day.toJSON()}
                                    style={{
                                      width: columnWidth,
                                      backgroundColor: isWeekend(day) ? '#fcfcfc' : 'inherit',
                                    }}
                                  >
                                    {capitalize(day.format('dddd'))}
                                  </th>
                                ))}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.calendarMode === 'Day' && (
                            <tr>{calendarDays.map((each) => this.renderCell(each, { printPreview }))}</tr>
                          )}
                          {this.state.calendarMode === 'Week' && (
                            <tr>
                              {calendarDays
                                .filter(this.filterWeekends)
                                .map((each) => this.renderCell(each, { printPreview }))}
                            </tr>
                          )}
                          {this.state.calendarMode === 'Month' &&
                            range(calendarDays.length / 7).map((week) => (
                              <tr key={week}>
                                {calendarDays
                                  .slice(week * 7, (week + 1) * 7)
                                  .filter(this.filterWeekends)
                                  .map((each) => this.renderCell(each, { printPreview }))}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </Calendar>
                  </td>
                </tr>
              </tbody>
            </table>
          </Container>
        )

        return (
          <>
            <Page title={this.getPageTitle()} loading={loading} scrollable>
              <Toolbar
                title={this.getPageTitle()}
                search={this.state.search}
                onSearchChange={
                  allowSearching(this)
                    ? (e) => this.setState({ search: e.target.value }, this.searchItems)
                    : undefined
                }
                searchType={this.state.searchType}
                onSearchTypeChange={(value) => this.setState({ searchType: value }, this.searchItems)}
                searchFields={this.state.searchFields}
                fieldSettings={fieldSettings}
                onSearchFieldsChange={(values) => this.setState({ searchFields: values }, this.searchItems)}
                actionsMenuItems={[
                  <Menu.Item key="customizeColumns">{t('customizeTiles')}</Menu.Item>,
                  <Menu.Item key="customizeSummaryColumns">{t('customizeSummary')}</Menu.Item>,
                  <Menu.Divider key="divider" />,
                  <Menu.Item key="print" disabled={loading}>
                    {t('print...')}
                  </Menu.Item>,
                ]}
                onActionsMenuClick={allowActionsMenu(this) ? this.handleActionsMenuClick : undefined}
                afterSearchContents={
                  <div className="tofino-after-search">
                    {!isNil(Filter) && filterTemplateType && isEmpty(this.props.location.search) ? (
                      <Badge {...(this.hasUnsavedFilter() && !filterTemplateIsShared ? { count: '!' } : {})}>
                        <Dropdown.Button
                          trigger={['hover', 'click']}
                          onClick={this.handleToggleFilterClick}
                          overlay={
                            <Menu
                              onClick={this.handleFilterMenuClick}
                              selectedKeys={
                                filterTemplateIsShared
                                  ? ['sharedFilterTemplate']
                                  : this.state.filterTemplateId
                                    ? [str(this.state.filterTemplateId)]
                                    : []
                              }
                            >
                              <Menu.SubMenu title={t('open')}>
                                {filterTemplates.filter(filterUntitled).map((each) => (
                                  <Menu.Item key={each.id}>{t('default')}</Menu.Item>
                                ))}
                                <Menu.Item key="sharedFilterTemplate">{t('shared')}</Menu.Item>
                                <Menu.Divider />
                                {filterTemplates
                                  .filter(
                                    (each) => each.name && strEqual(each.owner, this.props.user.userName)
                                  )
                                  .map((each) => (
                                    <Menu.Item key={each.id}>{each.name}</Menu.Item>
                                  ))}
                              </Menu.SubMenu>
                              <Menu.Item
                                key="saveFilterTemplate"
                                disabled={
                                  isEmpty(filterTemplateName) ||
                                  !this.hasUnsavedFilter() ||
                                  filterTemplateIsShared
                                }
                              >
                                {t('save')}{' '}
                                {this.hasUnsavedFilter() && !filterTemplateIsShared && (
                                  <Badge status="warning" />
                                )}
                              </Menu.Item>
                              <Menu.Item key="moveFilterTemplate">{t('saveAs')}</Menu.Item>
                              <Menu.Divider />
                              <Menu.Item
                                key="deleteFilterTemplate"
                                disabled={isEmpty(filterTemplateName) || filterTemplateIsShared}
                              >
                                {t('delete')}
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          {t(this.state.showFilter ? 'hideFilter' : 'showFilter')}
                        </Dropdown.Button>
                      </Badge>
                    ) : !isNil(Filter) ? (
                      <Button onClick={this.handleToggleFilterClick}>
                        {t(this.state.showFilter ? 'hideFilter' : 'showFilter')}
                      </Button>
                    ) : null}
                  </div>
                }
                afterActionsContents={
                  allowCreate(this) ? (
                    <Tooltip title={t(createButtonTextId)}>
                      <Button
                        type="primary"
                        onClick={() => this.setState({ createDrawerVisible: true })}
                        disabled={createDisabled(this)}
                        style={{ padding: '0 8px' }}
                      >
                        <Icon type="Add" bold />
                      </Button>
                    </Tooltip>
                  ) : null
                }
                allowCustomize={allowCustomize(this)}
              />
              {!isNil(Filter) && this.state.showFilter && (
                <FilterContainer
                  onClear={this.handleClearFilterClick}
                  onClose={this.handleToggleFilterClick}
                  clearText={this.props.location.search ? t('resetFilter') : t('clearAll')}
                  loading={this.state.filterTemplateLoading}
                >
                  <Filter
                    key={this.state.filterTemplateKey}
                    filterDto={this.state.filterDto}
                    onChange={this.handleFilterChange}
                    items={items}
                    {...this.props.filterProps}
                  />
                </FilterContainer>
              )}
              <Spin spinning={loading}>{renderCalendar(false)}</Spin>
            </Page>
            <Drawer
              title={t(createButtonTextId)}
              size={get(
                isFunction(createButtonLinkTarget) ? createButtonLinkTarget(this) : createButtonLinkTarget,
                'formSize'
              )}
              visible={this.state.createDrawerVisible}
              onClose={this.handleDrawerClose}
              saving={this.state.createDrawerSaving}
            >
              <CreateFormView
                filterDto={this.state.filterDto}
                onSave={(pending) =>
                  this.promiseState({ createDrawerSaving: pending }).then(async () => {
                    if (!pending) {
                      await this.fetchItems()
                    }
                  })
                }
                onSaveAndClose={this.handleDrawerClose}
                onCancel={this.handleDrawerClose}
              />
            </Drawer>
            <Drawer
              title={this.state.editDrawerLinkTargetText}
              size={this.state.editDrawerLinkTarget?.formSize}
              visible={this.state.editDrawerVisible}
              onClose={this.handleDrawerClose}
              saving={this.state.editDrawerSaving}
            >
              <EditFormView
                linkTargetRecord={this.state.editDrawerLinkTargetRecord}
                filterDto={this.state.filterDto}
                onSave={(pending) =>
                  this.promiseState({ editDrawerSaving: pending }).then(async () => {
                    if (!pending) {
                      await this.fetchItems()
                    }
                  })
                }
                onSaveAndClose={this.handleDrawerClose}
                onCancel={this.handleDrawerClose}
                readOnly={this.state.editDrawerLinkTargetReadOnly}
              />
            </Drawer>
            <Modal
              title={t('saveAs')}
              okText={t('save')}
              onOk={this.handleMoveFilterTemplate}
              okButtonProps={{ loading: this.state.moveFilterTemplateSaving }}
              onCancel={() => this.setState({ moveFilterTemplateVisible: false })}
              visible={this.state.moveFilterTemplateVisible}
            >
              <Form.Item label={t('name')} colon={false}>
                <Input
                  value={this.state.moveFilterTemplateInput}
                  onChange={(e) => this.setState({ moveFilterTemplateInput: e.target.value })}
                  placeholder={t('default')}
                  onPressEnter={this.handleMoveFilterTemplate}
                  autoFocus
                />
              </Form.Item>
            </Modal>
            <Modal
              title={t('selectSharedFilter')}
              visible={this.state.selectSharedFilterTemplateVisible}
              okText={t('select')}
              onOk={this.handleSelectSharedFilterTemplate}
              okButtonProps={{
                loading: this.state.selectFilterTemplateLoading,
                disabled: isNil(this.state.selectedSharedFilterTemplate),
              }}
              onCancel={() => this.setState({ selectSharedFilterTemplateVisible: false })}
              width={750}
            >
              <SelectFilterTemplates
                onSelect={(values) => this.setState({ selectedSharedFilterTemplate: values[0] })}
                initialFilterDto={{ filterTemplateType, includeShared: true }}
              />
            </Modal>
            <Customize
              title={t('customizeTiles')}
              header={t('field')}
              displayableColumns={fieldSettings.filter((each) => each.isDisplayable)}
              visible={this.state.customizeColumnsVisible}
              onOk={this.handleCustomizeOk}
              onCancel={() => this.setState({ customizeColumnsVisible: false })}
              selectedColumnKeys={this.state.selectedColumnKeys}
              sortedColumnKeys={this.state.sortedColumnKeys}
            />
            <Customize
              title={t('customizeSummary')}
              header={t('field')}
              displayableColumns={summaryFieldSettings.filter((each) => each.isDisplayable)}
              visible={this.state.customizeSummaryColumnsVisible}
              onOk={this.handleCustomizeSummaryOk}
              onCancel={() => this.setState({ customizeSummaryColumnsVisible: false })}
              selectedColumnKeys={this.state.selectedSummaryColumnKeys}
              sortedColumnKeys={this.state.sortedSummaryColumnKeys}
            />
            <Settings
              sortableColumns={fieldSettings.filter((each) => each.sortByEnumValue > -1)}
              values={this.state}
              visible={this.state.settingsVisible}
              onChange={(values) => this.setState({ ...values, settingsVisible: false }, this.fetchItems)}
              onCancel={() => this.setState({ settingsVisible: false })}
              allowTotals={allowTotals}
            />
            <Modal
              title={this.state.extraTilesModalTitle}
              visible={this.state.extraTilesModalVisible}
              footer={null}
              onCancel={() =>
                this.setState({
                  extraTilesModalTitle: null,
                  extraTilesModalVisible: false,
                  extraTilesModalMoment: null,
                })
              }
            >
              <div style={{ marginTop: '-12px' }}>
                {this.state.extraTilesModalVisible
                  ? this.renderCell(this.state.extraTilesModalMoment, { insideModal: true })
                  : null}
              </div>
            </Modal>
            <Modal
              title={t('printPreview')}
              okText={t('print')}
              onOk={createPrintHandler(this.printableRef)}
              onCancel={() => this.setState({ printVisible: false }, resetPrintHandler)}
              width={992}
              visible={this.state.printVisible}
            >
              <div ref={this.printableRef} className="tofino-print-preview">
                {renderCalendar(true)}
              </div>
            </Modal>
          </>
        )
      }
    }
  }
