import { Button } from 'antd'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { getUserPermission } from 'helpers/auth'
import { encodeState } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { createPrintHandler, resetPrintHandler } from 'helpers/print'
import { showError } from 'helpers/errors'
import Icon from 'elements/Icon'
import Progress from 'elements/Progress'
import Filter from 'containers/Jobs/Filter'
import Modal from 'elements/Modal'
import Print from 'containers/Jobs/Print'

export default createListViewComponent({
  entityName: entityNames.jobs,
  filterTemplateType: 'Job',
  createButtonTextId: 'createJob',
  primaryLinkTarget: 'jobRecord',
  initialFilterDto: (self) => ({
    approvalStatus: 'All',
    overdueStatus: 'All',
    dateRangeField: 'All',
    dateRange: { dateRangeType: 'YearToDate' },
    isFuture: false,
    assignedToId: -1,
    jobStatusOptionId: -1,
    active: 'Active',
  }),
  deleteDisabled: (self) =>
    getUserPermission('Jobs') !== 'All' ||
    (self.props.customer.generalSettings.requireMasterToDeleteJobs && getUserPermission('Master') !== 'Yes'),
  extraRowActions: (self) => (
    <>
      <Button
        disabled={self.state.selectedRowKeys.length === 0}
        onClick={async () => {
          try {
            self.setState({ printLoading: true })

            const printItems = await Promise.all(
              self.state.selectedRowKeys.map((each) => self.props.getItem(each).then((r) => r.value.data))
            )

            const [users, locations, logo] = await Promise.all([
              self.props.getUsers(),
              self.props.getLocations(),
              self.props
                .getDocumentContents({
                  request: {
                    domainObjectId: self.props.tenant.id,
                    domainObjectType: 'Customer',
                    documentType: 'PurchaseOrderLogo',
                    documentName: self.props.tenant.subdomain,
                  },
                })
                .catch(() => {}),
            ])

            self.setState({
              printItems,
              printUsers: users?.value?.data?.items,
              printLocations: locations?.value?.data?.items,
              printLogo: logo?.value?.data ?? {},
              printVisible: true,
            })
          } catch (error) {
            showError({ error })
          } finally {
            self.setState({ printLoading: false })
          }
        }}
        loading={self.state.printLoading}
      >
        {!self.state.printLoading && <Icon type="print" />}
        {t('print')}
      </Button>
      {getUserPermission('Reports') !== 'No' && [
        <Button
          key="orderReport"
          disabled={self.state.selectedRowKeys.length !== 1}
          onClick={() =>
            self.props.history.push(
              `/reports/jobs/usageOrderReport?${encodeState({
                showFilter: true,
                filterDto: {
                  jobIds: self.state.selectedRowKeys,
                  showOrdering: true,
                },
              })}`
            )
          }
        >
          <Icon type="InsertChart" />
          {t('orderReport')}
        </Button>,
        <Button
          key="jobCostReport"
          disabled={self.state.selectedRowKeys.length !== 1}
          onClick={() => {
            const selectedItem = self.state.items.find((one) => one.id === self.state.selectedRowKeys[0])
            self.props.history.push(
              `/reports/jobs/jobCostReport?${encodeState({
                search: selectedItem?.barcode,
                showFilter: true,
                filterDto: {
                  jobIds: self.state.selectedRowKeys,
                  dateRange: self.state.filterDto.dateRange,
                  dateRangeField: 'JobCreateDate',
                  completionStatus: 'All',
                },
                selectedColumnKeys: [
                  'jobBarcode',
                  'jobNumber',
                  'jobName',
                  'jobDescription',
                  'createdDate',
                  'reasonDescription',
                  'assetBarcode',
                  'assetDescription',
                  'itemBarcode',
                  'itemDescription',
                  'partsCost',
                  'labourCost',
                  'totalCost',
                  'actualHours',
                ],
              })}`
            )
          }}
        >
          <Icon type="InsertChart" />
          {t('jobCostReport')}
        </Button>,
      ]}
      <Modal
        title={t('printPreview')}
        okText={t('print')}
        onOk={createPrintHandler(self.printableRef)}
        onCancel={() => self.setState({ printVisible: false }, resetPrintHandler)}
        width={992}
        visible={self.state.printVisible}
      >
        <div ref={self.printableRef} className="tofino-print-preview">
          {(self.state.printItems ?? [])
            .filter((each) => self.state.selectedRowKeys.includes(each.id))
            .map((item) => (
              <div key={item.id} className="mb-24">
                <Print
                  parentRecord={{
                    ...item,
                  }}
                  users={self.state.printUsers}
                  locations={self.state.printLocations}
                  logo={self.state.printLogo}
                />
              </div>
            ))}
        </div>
      </Modal>
    </>
  ),
  tableCellComponents: {
    checklistCompletePercent: (self, item) =>
      item.checklistItemCount ? (
        <Progress percent={Math.round(item.checklistCompletePercent * 100)} size="small" />
      ) : null,
  },
  getFileTemplateTypes: (self) => ['Jobs'],
})(Filter)
