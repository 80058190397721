import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import productCategoryActions from 'actions/productCategories'
import customerActions from 'actions/customers'
import Component from 'components/CustomerDetails/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  productCategories: selectors.productCategories.selectOptions(state),
  customers: selectors.customers.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getProductCategories: (params) =>
    dispatch(productCategoryActions.getOptions({ ...params, active: 'All', pageIndex: 0 })),
  getCustomers: (params) => dispatch(customerActions.getOptions({ ...params, active: 'All', pageIndex: 0 })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
