import produce from 'immer'
import { isNil, snakeCase, isEmpty, unset } from 'lodash'
import { createActions, normalizeDateRange } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const getPeriodForCheckoutCountInDays = (count, type) =>
  !isNil(count) && !isNil(type) ? count * (type === 'Years' ? 365 : type === 'Months' ? 30 : 1) : undefined

const entityName = entityNames.assets
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    useSelectionList: true,
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        const { filterByStatusChangeDate = false } = draft

        if (
          !filterByStatusChangeDate ||
          (filterByStatusChangeDate &&
            (isNil(draft.statusChangeStartDate) || isNil(draft.statusChangeEndDate)))
        ) {
          unset(draft, 'filterByStatusChangeDate')
          unset(draft, 'statusChangeStartDate')
          unset(draft, 'statusChangeEndDate')
        }

        draft.locationVendingTypes = ['NonVending']

        draft.alwaysIncludeAssetIds = draft.alwaysIncludeAssetIds === false ? false : !isEmpty(draft.assetIds)
        draft.alwaysIncludeAssetBarcodes =
          draft.alwaysIncludeAssetBarcodes === false ? false : !isEmpty(draft.assetBarcodes)

        draft.periodForCheckoutCountInDays = getPeriodForCheckoutCountInDays(
          draft.periodForCheckoutCount,
          draft.periodForCheckoutCountType
        )

        unset(draft, 'periodForCheckoutCount')
        unset(draft, 'periodForCheckoutCountType')

        normalizeDateRange(draft)
      }),
  }),

  checkInAssets: (params = {}) => ({
    type: `${ENTITY_NAME}_CHECK_IN`,
    payload: createPost(entityName, { action: 'checkIn' })(params),
  }),

  moveAssets: (params = {}) => ({
    type: `${ENTITY_NAME}_MOVE`,
    payload: createPost(entityName, { action: 'move' })(params),
  }),

  updateMileage: ({ id, mileage }) => ({
    type: `${ENTITY_NAME}_UPDATE_MILEAGE`,
    payload: createPost(entityName, { action: `${id}/updateMileage` })({ mileage }),
  }),

  updateServiceHours: ({ id, serviceHours }) => ({
    type: `${ENTITY_NAME}_UPDATE_SERVICE_HOURS`,
    payload: createPost(entityName, { action: `${id}/updateServiceHours` })({ serviceHours }),
  }),

  updateTracking: (params = {}) => ({
    type: `${ENTITY_NAME}_TRACKING`,
    payload: createPost(entityName, { action: `${params.id}/updateTracking` })(params),
  }),

  generateId: (params) => ({
    type: `${ENTITY_NAME}_GENERATE_ID`,
    payload: createPost(entityName, { action: '?generateId=true' })(params),
  }),
}
