import { get, range } from 'lodash'
import moment from 'moment'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/MonthlyDetailReport/Filter'
import { t } from 'helpers/i18n'

export default createListViewComponent({
  entityName: entityNames.monthlyDetailReport,
  filterTemplateType: 'MonthlyDetailReport',
  initialFilterDto: (self) => ({
    dateRange: {
      dateRangeType: 'TwelveMonthPeriod',
      customStartDate: moment().startOf('month'),
      customEndDate: moment().startOf('month').add(12, 'months').subtract(1, 'seconds'),
    },
  }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  tableHeadGroups: (self) => {
    const monthNames = [
      t('jan'),
      t('feb'),
      t('mar'),
      t('apr'),
      t('may'),
      t('jun'),
      t('july'),
      t('aug'),
      t('sep'),
      t('oct'),
      t('nov'),
      t('dec'),
    ]
    const start = get(self, 'state.filterDto.dateRange.customStartDate', moment().startOf('month'))
    const months = range(0, 12).map((n) => monthNames[moment(start).clone().add(n, 'months').month()])
    const findIndex = (title) => monthNames.indexOf(title)

    return {
      orderedByMonth: () =>
        months.map((title) => ({
          title,
          key: `monthlyOrderedDetails[${findIndex(title)}].quantity`,
          dataIndex: `monthlyOrderedDetails[${findIndex(title)}].quantity`,
          width: 64,
          align: 'right',
        })),
      issuedByMonth: () =>
        months.map((title) => ({
          title,
          key: `monthlyIssuedDetails[${findIndex(title)}].quantity`,
          dataIndex: `monthlyIssuedDetails[${findIndex(title)}].quantity`,
          width: 64,
          align: 'right',
        })),
      receivedByMonth: () =>
        months.map((title) => ({
          title,
          key: `monthlyReceivedDetails[${findIndex(title)}].quantity`,
          dataIndex: `monthlyReceivedDetails[${findIndex(title)}].quantity`,
          width: 64,
          align: 'right',
        })),
    }
  },
})(Filter)
