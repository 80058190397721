import * as React from 'react'
import produce from 'immer'
import { Form, Input, Spin, Button, Tooltip, Tabs, Dropdown, Menu, message } from 'antd'
import { get, set, cloneDeep, uniqBy, max, isEmpty } from 'lodash'
import { showError, resourceNotFound, ValidationError } from 'helpers/errors'
import { tryParseJSON, HARD_SPACE } from 'helpers/utils'
import { createLabelFactory, createFieldFactory, createHandleSubmit, isReadOnly } from 'helpers/formViews'
import { t } from 'helpers/i18n'
import { createChart } from 'helpers/dashboards'
import chartLegendPositions from 'options/dashboards/chartLegendPositions'
import { stopEvent } from 'helpers/events'
import InputNumber from 'elements/InputNumber'
import Select, { Option, OptGroup, getOptionProps } from 'elements/Select'
import Checkbox from 'elements/Checkbox'
import Icon from 'elements/Icon'
import Settings from './Settings'
import FormButtons from 'elements/FormButtons'
import Ranges from './Ranges'
import Comparison from './Comparison'
import Modal from 'elements/Modal'
import SelectDashboardChart from 'containers/DashboardCharts/Select'
import Help from 'elements/Help'
import Metrics from 'containers/DashboardTemplates/FormView/Metrics'
import { Row, Col } from 'elements/Grid'

const ALLOWED_CHART_TYPES = ['Area', 'Line', 'Donut', 'Bar', 'Column', 'Pie', 'Scatter', 'Gauge', 'Scorecard']

const SECONDARY_CHART_TYPES = {
  Line: 'line',
  Column: 'column',
}

class FormView extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.handleSubmit = createHandleSubmit(this)
  }

  componentDidMount() {
    this.fetchItem()
  }

  transformSaveItemParams = ({ item }) => ({ chartJson: JSON.stringify(item?.chartJson ?? {}) })

  getItemId = () => [this.state.item?.id, this.props.linkTargetRecord?.id].find(Boolean)

  fetchItem = async ({ itemId = this.getItemId() } = {}) => {
    try {
      const responses = await Promise.all([
        itemId
          ? this.props.getItem(itemId).catch((error) => {
              this.props.onCancel?.()
              throw error
            })
          : !this.props.linkTargetRecord
            ? this.props.newItem(
                createChart({
                  sectionId: this.props.parentRecord.id,
                  position: max(this.props.parentRecord.charts.map((each) => each.position)) + 1,
                })
              )
            : resourceNotFound(this),
        this.props.getSettings({ type: 'chart' }),
        this.props.getChartMetricTypes(),
      ])

      const item = cloneDeep(responses[0].value.data)

      item.chartJson = tryParseJSON(item.chartJson, {})

      const { primaryMetricType = 'None', chartType = 'None', secondaryMetricType = 'None' } = item

      const [chartTypes, distributionTypes, dimensionTypes] = await Promise.all([
        primaryMetricType !== 'None'
          ? this.props.getChartTypes({ primaryMetricType })
          : Promise.resolve(null),
        primaryMetricType !== 'None' && chartType !== 'None'
          ? this.props.getDistributionTypes({
              chartType,
              primaryMetricType,
              secondaryMetricType,
            })
          : Promise.resolve(null),
        primaryMetricType !== 'None' && chartType !== 'None'
          ? this.props.getDimensionTypes({
              chartType,
              primaryMetricType,
              secondaryMetricType,
            })
          : Promise.resolve(null),
      ])

      this.setState({
        item,
        fieldSettings: responses[1].value.data.fieldSettings,
        chartMetricTypes: get(responses[2], 'value.data.items', []),
        chartTypes: get(chartTypes, 'value.data.items', []),
        distributionTypes: get(distributionTypes, 'value.data.items', []),
        dimensionTypes: get(dimensionTypes, 'value.data.items', []),
      })
    } catch (error) {
      showError({ error })
    }
  }

  setItemValue = (name, value) =>
    this.setState(
      produce((draft) => {
        set(draft.item, name, value)

        this.props.form.setFieldsValue({ [name]: value })
      }),
      async () => {
        try {
          const { primaryMetricType, secondaryMetricType = 'None' } = this.state.item

          if (['primaryMetricType', 'secondaryMetricType'].includes(name)) {
            const chartTypes = await this.props
              .getChartTypes({ primaryMetricType })
              .then((r) => r.value.data.items)

            console.assert(chartTypes.length, `no chart types for metric type = ${primaryMetricType}`)

            const chartType = chartTypes.some((one) => one.type === this.state.item.chartType)
              ? this.state.item.chartType
              : get(chartTypes, '[0].type')

            this.setState(
              produce((draft) => {
                set(draft.item, 'chartType', chartType)

                this.props.form.setFieldsValue({ chartType })
              })
            )

            const [dimensionTypes, distributionTypes] = await Promise.all([
              this.props
                .getDimensionTypes({
                  chartType,
                  primaryMetricType,
                  secondaryMetricType,
                })
                .then((r) => r.value.data.items),
              this.props
                .getDistributionTypes({
                  chartType,
                  primaryMetricType,
                  secondaryMetricType,
                })
                .then((r) => r.value.data.items),
            ])

            const dimensionType = dimensionTypes
              .map((each) => each.type)
              .includes(this.state.item.dimensionType)
              ? this.state.item.dimensionType
              : get(dimensionTypes, '[0].type')

            const distributionType = distributionTypes
              .map((each) => each.type)
              .includes(this.state.item.distributionType)
              ? this.state.item.distributionType
              : undefined

            this.setState({ chartTypes, dimensionTypes, distributionTypes }, () => {
              this.setItemValue('dimensionType', dimensionType)
              this.setItemValue('distributionType', distributionType)
            })
          }

          if (name === 'chartType') {
            const chartType = value

            const [dimensionTypes, distributionTypes] = await Promise.all([
              this.props
                .getDimensionTypes({
                  chartType,
                  primaryMetricType,
                  secondaryMetricType,
                })
                .then((r) => r.value.data.items),
              this.props
                .getDistributionTypes({
                  chartType,
                  primaryMetricType,
                  secondaryMetricType,
                })
                .then((r) => r.value.data.items),
            ])

            const dimensionType = dimensionTypes
              .map((each) => each.type)
              .includes(this.state.item.dimensionType)
              ? this.state.item.dimensionType
              : get(dimensionTypes, '[0].type')

            const distributionType = distributionTypes
              .map((each) => each.type)
              .includes(this.state.item.distributionType)
              ? this.state.item.distributionType
              : undefined

            this.setState({ dimensionTypes, distributionTypes }, () => {
              this.setItemValue('dimensionType', dimensionType)
              this.setItemValue('distributionType', distributionType)
            })
          }

          if (name === 'primaryMetricType') {
            const primaryMetric = this.state.chartMetricTypes.find((one) => one.type === value)
            this.setItemValue('chartJson.primaryMetricTitle', primaryMetric?.name)
            this.setItemValue('chartJson.primaryMetricIdentifier', primaryMetric?.identifier)

            if (!isEmpty(primaryMetric.defaultDistributionIdentifier)) {
              this.setItemValue(
                'chartJson.distributionIdentifier',
                primaryMetric.defaultDistributionIdentifier
              )
            }
          }

          if (name === 'secondaryMetricType') {
            const secondaryMetric = this.state.chartMetricTypes.find((one) => one.type === value)
            this.setItemValue('chartJson.secondaryMetricTitle', secondaryMetric?.name)
            this.setItemValue('chartJson.secondaryMetricIdentifier', secondaryMetric?.identifier)

            if (value) {
              this.setItemValue('chartJson.secondaryChartType', 'Line')
            } else {
              this.setItemValue('chartJson.secondaryChartType', undefined)
            }
          }

          if (name === 'dimensionType') {
            const dimension = this.state.dimensionTypes.find((one) => one.type === value)
            this.setItemValue('chartJson.dimensionTitle', dimension?.name)
            this.setItemValue('chartJson.dimensionIdentifier', dimension?.identifier)
          }

          if (name === 'distributionType') {
            const primaryMetric = this.state.chartMetricTypes.find((one) => one.type === primaryMetricType)
            const distribution = this.state.distributionTypes.find((one) => one.type === value)
            this.setItemValue('chartJson.distributionTitle', distribution?.name)
            this.setItemValue(
              'chartJson.distributionIdentifier',
              get(distribution, 'identifier', primaryMetric.defaultDistributionIdentifier)
            )
          }
        } catch (error) {
          showError({ error })
        }
      }
    )

  handleActionsMenuClick = ({ key }) => {
    switch (key) {
      case 'copyFromExistingRecord':
        this.setState({
          copyFromExistingVisible: true,
          copyFromExistingSelectedRecord: null,
        })
        break

      default:
        message.warn(t('underDevelopment'))
        break
    }
  }

  handleCopyFromExisting = async () => {
    try {
      this.setState({ copyFromExistingLoading: true })

      const { id, position, sectionId, chartJson, widthType, ...rest } = await this.props
        .getExistingItem(this.state.copyFromExistingSelectedRecord.id)
        .then((r) => r.value.data)
        .then(cloneDeep)

      this.setState(
        produce((draft) => {
          Object.assign(draft.item, rest)
          draft.item.chartJson = tryParseJSON(chartJson, {})

          draft.copyFromExistingVisible = false
          draft.copyFromExistingSelectedRecord = null
        }),
        async () => {
          try {
            const { primaryMetricType = 'None', chartType = 'None', secondaryMetricType = 'None' } = rest

            const [chartTypes, distributionTypes, dimensionTypes] = await Promise.all([
              primaryMetricType !== 'None'
                ? this.props.getChartTypes({ primaryMetricType })
                : Promise.resolve(null),
              primaryMetricType !== 'None' && chartType !== 'None'
                ? this.props.getDistributionTypes({
                    chartType,
                    primaryMetricType,
                    secondaryMetricType,
                  })
                : Promise.resolve(null),
              primaryMetricType !== 'None' && chartType !== 'None'
                ? this.props.getDimensionTypes({
                    chartType,
                    primaryMetricType,
                    secondaryMetricType,
                  })
                : Promise.resolve(null),
            ])

            this.setState({
              chartTypes: get(chartTypes, 'value.data.items', []),
              distributionTypes: get(distributionTypes, 'value.data.items', []),
              dimensionTypes: get(dimensionTypes, 'value.data.items', []),
            })
          } catch (error) {
            showError({ error })
          }
        }
      )
    } catch (error) {
      showError({ error })
    } finally {
      this.setState({ copyFromExistingLoading: false })
    }
  }

  render() {
    const { item, fieldSettings, chartMetricTypes = [] } = this.state

    if (!item || !fieldSettings) {
      return <Spin />
    }

    const createLabel = createLabelFactory(fieldSettings)
    const createFieldDecorator = createFieldFactory(this.props.form, item, fieldSettings)

    const readOnly = isReadOnly(this)

    const chartMetricCategoryTypes = uniqBy(
      chartMetricTypes.map(({ categoryType, categoryName }) => ({
        categoryType,
        categoryName,
      })),
      'categoryType'
    )

    const section = (this.props.rootRecord?.sections ?? []).find((one) => one.id === item.sectionId)
    const positions = (section?.charts ?? []).map((each) => each.position)

    return (
      <>
        <Form layout="vertical" colon={false} onSubmit={readOnly ? stopEvent : this.handleSubmit}>
          <Dropdown
            overlay={
              <Menu onClick={this.handleActionsMenuClick}>
                <Menu.Item key="copyFromExistingRecord" disabled={readOnly}>
                  {t('copyFromDashboardChart')}
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button className="form-items-actions">
              {t('actions')}
              <Icon type="KeyboardArrowDown" />
            </Button>
          </Dropdown>
          <div className="form-items-container">
            <ValidationError errors={this.state.validationErrors} />
            <Row>
              <Col half>
                <Form.Item label={createLabel('primaryMetricType')}>
                  <Row type="flex" justify="space-between" gutter={6}>
                    <Col className="flex-1">
                      {createFieldDecorator('primaryMetricType')(
                        <Select
                          onChange={(value) => this.setItemValue('primaryMetricType', value)}
                          placeholder={t('none')}
                          allowClear={false}
                          readOnly={readOnly}
                          showSearch
                        >
                          {chartMetricCategoryTypes.map((category) => (
                            <OptGroup key={category.categoryType} label={category.categoryName}>
                              {chartMetricTypes
                                .filter((metric) => metric.categoryType === category.categoryType)
                                .map((metric) => (
                                  <Option key={metric.type} value={metric.type}>
                                    {metric.name}
                                  </Option>
                                ))}
                            </OptGroup>
                          ))}
                        </Select>
                      )}
                    </Col>
                    <Col>
                      <Metrics
                        items={chartMetricTypes}
                        onSelect={(values) => this.setItemValue('primaryMetricType', get(values, '[0].type'))}
                        filterProps={{ categoryTypes: chartMetricCategoryTypes }}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col half>
                <Form.Item label={createLabel('chartType')}>
                  {createFieldDecorator('chartType')(
                    <Select
                      onChange={(value) => this.setItemValue('chartType', value)}
                      allowClear={false}
                      placeholder={t('none')}
                      readOnly={readOnly}
                      disabled={!item.primaryMetricType}
                    >
                      {(this.state.chartTypes ?? []).map((each) => (
                        <Option key={each.type} value={each.type}>
                          {each.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col half>
                <Form.Item label={createLabel('name')}>
                  {createFieldDecorator('name')(<Input readOnly={readOnly} />)}
                </Form.Item>
              </Col>
              <Col half>
                <Form.Item label={HARD_SPACE}>
                  <Checkbox
                    checked={get(item.chartJson, 'showTitleOnChart', true)}
                    onChange={(e) => this.setItemValue('chartJson.showTitleOnChart', e.target.checked)}
                    readOnly={readOnly}
                  >
                    {t('showAsChartTitle')}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Tabs defaultActiveKey={item.id ? 'data' : 'chart'}>
              <Tabs.TabPane key="chart" tab={t('chart')} forceRender>
                {this.props.rootRecord && (
                  <>
                    <Row>
                      <Col half>
                        <Form.Item label={createLabel('sectionId')}>
                          {createFieldDecorator('sectionId')(
                            <Select
                              onChange={(value) => this.setItemValue('sectionId', value)}
                              allowClear={false}
                              readOnly={readOnly}
                            >
                              {this.props.rootRecord.sections.map((each) => (
                                <Option key={each.id} value={each.id}>
                                  <span {...getOptionProps(each)}>{each.name}</span>
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col half>
                        <Form.Item label={createLabel('position')}>
                          {createFieldDecorator('position')(
                            <InputNumber
                              min={1}
                              max={isEmpty(positions) ? 1 : max(positions) + 1}
                              readOnly={readOnly}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Settings
                      item={item}
                      onChange={(value) => this.setState({ item: value })}
                      readOnly={readOnly}
                    />
                  </>
                )}
                <Form.Item>
                  <Checkbox
                    checked={item.chartJson.showBorder}
                    onChange={(e) => this.setItemValue('chartJson.showBorder', e.target.checked)}
                    readOnly={readOnly}
                  >
                    {t('showBorder')}
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  {createFieldDecorator('linkToDashboardFilter', { valuePropName: 'checked' })(
                    <Checkbox readOnly={readOnly}>{createLabel('linkToDashboardFilter')}</Checkbox>
                  )}
                </Form.Item>
                <Form.Item>
                  {createFieldDecorator('linkToDashboardRefresh', { valuePropName: 'checked' })(
                    <Checkbox
                      onChange={(e) => this.setItemValue('linkToDashboardRefresh', e.target.checked)}
                      readOnly={readOnly}
                    >
                      {createLabel('linkToDashboardRefresh')}
                    </Checkbox>
                  )}
                </Form.Item>
                <Row>
                  <Col third>
                    <Form.Item label={createLabel('dataRefreshMinutes')}>
                      {createFieldDecorator('dataRefreshMinutes')(
                        <InputNumber
                          min={1}
                          max={60}
                          readOnly={readOnly}
                          disabled={item.linkToDashboardRefresh}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Tabs.TabPane>
              {ALLOWED_CHART_TYPES.includes(item.chartType) && (
                <Tabs.TabPane key="data" tab={t('data')} forceRender>
                  {['Line', 'Column'].includes(item.chartType) && (
                    <Row>
                      <Col half>
                        <Form.Item label={createLabel('secondaryMetricType')}>
                          <Row type="flex" justify="space-between" gutter={6}>
                            <Col className="flex-1">
                              <Select
                                value={item.secondaryMetricType}
                                onChange={(value) => this.setItemValue('secondaryMetricType', value)}
                                placeholder={t('none')}
                                readOnly={readOnly}
                                showSearch
                                allowClear
                              >
                                {chartMetricCategoryTypes.map((category) => (
                                  <OptGroup key={category.categoryType} label={category.categoryName}>
                                    {chartMetricTypes
                                      .filter((metric) => metric.categoryType === category.categoryType)
                                      .filter(
                                        (metric) =>
                                          metric.isCalculatedMetric === false &&
                                          metric.isScatterMetric === false
                                      )
                                      .map((metric) => (
                                        <Option key={metric.type} value={metric.type}>
                                          {metric.name}
                                        </Option>
                                      ))}
                                  </OptGroup>
                                ))}
                              </Select>
                            </Col>
                            <Col>
                              <Metrics
                                items={chartMetricTypes.filter(
                                  (metric) =>
                                    metric.isCalculatedMetric === false && metric.isScatterMetric === false
                                )}
                                onSelect={(values) =>
                                  this.setItemValue('secondaryMetricType', get(values, '[0].type'))
                                }
                                filterProps={{
                                  categoryTypes: chartMetricCategoryTypes,
                                  allowCalculatedMetrics: false,
                                  allowScatterMetrics: false,
                                }}
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                      {item.secondaryMetricType && item.secondaryMetricType !== 'None' && (
                        <Col half>
                          <Form.Item label={t('secondaryChartType')}>
                            <Select
                              value={get(item.chartJson, 'secondaryChartType', 'Line')}
                              onChange={(value) => this.setItemValue('chartJson.secondaryChartType', value)}
                              readOnly={readOnly}
                              placeholder={t('none')}
                              allowClear={false}
                            >
                              {Object.entries(SECONDARY_CHART_TYPES).map(([key, value]) => (
                                <Option key={key} value={key}>
                                  {t(value)}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  )}
                  {['Line', 'Area', 'Column', 'Bar', 'Scatter', 'Pie', 'Donut'].includes(item.chartType) && (
                    <Row>
                      <Col half>
                        <Form.Item label={createLabel('dimensionType')}>
                          {createFieldDecorator('dimensionType')(
                            <Select
                              onChange={(value) => this.setItemValue('dimensionType', value)}
                              readOnly={readOnly}
                              placeholder={t('none')}
                              disabled={!item.primaryMetricType}
                            >
                              {(this.state.dimensionTypes ?? []).map((each) => (
                                <Option key={each.type} value={each.type}>
                                  {each.name}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {['Line', 'Area', 'Column', 'Bar', 'Scatter'].includes(item.chartType) &&
                    !isEmpty(this.state.distributionTypes ?? []) && (
                      <Row>
                        <Col half>
                          <Form.Item label={createLabel('distributionType')}>
                            {createFieldDecorator('distributionType')(
                              <Select
                                onChange={(value) => this.setItemValue('distributionType', value)}
                                readOnly={readOnly}
                                placeholder={t('none')}
                              >
                                {(this.state.distributionTypes ?? []).map((each) => (
                                  <Option key={each.type} value={each.type}>
                                    {each.name}
                                  </Option>
                                ))}
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  {['Line', 'Area'].includes(item.chartType) && (
                    <Form.Item>
                      <Checkbox
                        checked={item.chartJson.showSmoothLine}
                        onChange={(e) => this.setItemValue('chartJson.showSmoothLine', e.target.checked)}
                        readOnly={readOnly}
                      >
                        {t('showSmoothLine')}
                      </Checkbox>
                    </Form.Item>
                  )}
                  {['Area', 'Column', 'Bar'].includes(item.chartType) && (
                    <Form.Item>
                      <Checkbox
                        checked={item.chartJson.stackDistributedData}
                        onChange={(e) =>
                          this.setItemValue('chartJson.stackDistributedData', e.target.checked)
                        }
                        readOnly={readOnly}
                      >
                        {t('stackDistributedData')}
                      </Checkbox>
                    </Form.Item>
                  )}
                  {['Area', 'Column', 'Bar', 'Line', 'Scatter'].includes(item.chartType) && (
                    <Form.Item>
                      <Checkbox
                        checked={item.chartJson.showGridlines}
                        onChange={(e) => this.setItemValue('chartJson.showGridlines', e.target.checked)}
                        readOnly={readOnly}
                      >
                        {t('showGridlines')}
                      </Checkbox>
                    </Form.Item>
                  )}
                  {['Gauge', 'Scorecard'].includes(item.chartType) && (
                    <Row>
                      <Col twoThirds>
                        <Ranges
                          values={item?.chartJson?.rangeSettings}
                          onChange={(values) => this.setItemValue('chartJson.rangeSettings', values)}
                          useThemeColors={item?.chartJson?.useThemeColors}
                          readOnly={readOnly}
                        />
                        {item.chartType === 'Scorecard' && (
                          <>
                            <Form.Item label={HARD_SPACE}>
                              <Checkbox
                                checked={item.includeComparisonValue}
                                onChange={(e) =>
                                  this.setItemValue('includeComparisonValue', e.target.checked)
                                }
                                readOnly={readOnly}
                              >
                                {createLabel('includeComparisonValue')}
                              </Checkbox>
                            </Form.Item>
                            {item.includeComparisonValue && (
                              <Comparison
                                item={item}
                                onChange={(value) => this.setState({ item: value })}
                                readOnly={readOnly}
                                fieldSettings={fieldSettings}
                              />
                            )}
                          </>
                        )}
                      </Col>
                      <Col third>
                        <Form.Item label={t('minimumValue')}>
                          <InputNumber
                            value={get(item, 'chartJson.minimumValue', 0)}
                            onChange={(value) => this.setItemValue('chartJson.minimumValue', value)}
                            readOnly={readOnly}
                          />
                        </Form.Item>
                        <Form.Item label={t('maximumValue')}>
                          <InputNumber
                            value={get(item, 'chartJson.maximumValue', 100)}
                            onChange={(value) => this.setItemValue('chartJson.maximumValue', value)}
                            readOnly={readOnly}
                          />
                        </Form.Item>
                        {item.chartType === 'Gauge' && (
                          <>
                            <Form.Item label={t('startAngle')}>
                              <InputNumber
                                value={get(item, 'chartJson.startAngle', 180)}
                                onChange={(value) => this.setItemValue('chartJson.startAngle', value)}
                                readOnly={readOnly}
                              />
                            </Form.Item>
                            <Form.Item label={t('endAngle')}>
                              <InputNumber
                                value={get(item, 'chartJson.endAngle', 0)}
                                onChange={(value) => this.setItemValue('chartJson.endAngle', value)}
                                readOnly={readOnly}
                              />
                            </Form.Item>
                            <Form.Item label={t('numberOfSegments')}>
                              <InputNumber
                                value={get(item, 'chartJson.splitNumber', 5)}
                                onChange={(value) =>
                                  this.setItemValue('chartJson.splitNumber', Math.max(value, 1))
                                }
                                min={1}
                                readOnly={readOnly}
                              />
                            </Form.Item>
                          </>
                        )}
                        <Checkbox
                          checked={item.chartJson.useThemeColors}
                          onChange={(e) => this.setItemValue('chartJson.useThemeColors', e.target.checked)}
                          readOnly={readOnly}
                        >
                          {t('useThemeColors')}
                        </Checkbox>
                      </Col>
                    </Row>
                  )}
                </Tabs.TabPane>
              )}
              {ALLOWED_CHART_TYPES.includes(item.chartType) && (
                <Tabs.TabPane key="labels" tab={t('labels')} forceRender>
                  <Row>
                    <Col half>
                      <Form.Item label={t('primaryMetricTitle')}>
                        <Input
                          value={item?.chartJson?.primaryMetricTitle}
                          onChange={(e) => this.setItemValue('chartJson.primaryMetricTitle', e.target.value)}
                          readOnly={readOnly}
                        />
                      </Form.Item>
                    </Col>
                    <Col half>
                      <Form.Item
                        label={
                          <span>
                            {t('primaryMetricIdentifier')} <Help title={t('identifierInfo')} />
                          </span>
                        }
                      >
                        <Input
                          value={item?.chartJson?.primaryMetricIdentifier}
                          onChange={(e) =>
                            this.setItemValue('chartJson.primaryMetricIdentifier', e.target.value)
                          }
                          readOnly={readOnly}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {['Line', 'Column'].includes(item.chartType) &&
                    item.secondaryMetricType &&
                    item.secondaryMetricType !== 'None' && (
                      <Row>
                        <Col half>
                          <Form.Item label={t('secondaryMetricTitle')}>
                            <Input
                              value={item?.chartJson?.secondaryMetricTitle}
                              onChange={(e) =>
                                this.setItemValue('chartJson.secondaryMetricTitle', e.target.value)
                              }
                              readOnly={readOnly}
                            />
                          </Form.Item>
                        </Col>
                        <Col half>
                          <Form.Item label={t('secondaryMetricIdentifier')}>
                            <Input
                              value={item?.chartJson?.secondaryMetricIdentifier}
                              onChange={(e) =>
                                this.setItemValue('chartJson.secondaryMetricIdentifier', e.target.value)
                              }
                              readOnly={readOnly}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  {['Line', 'Area', 'Column', 'Bar', 'Scatter', 'Pie', 'Donut'].includes(item.chartType) && (
                    <Row>
                      <Col half>
                        <Form.Item label={t('dimensionTitle')}>
                          <Input
                            value={item?.chartJson?.dimensionTitle}
                            onChange={(e) => this.setItemValue('chartJson.dimensionTitle', e.target.value)}
                            readOnly={readOnly}
                          />
                        </Form.Item>
                      </Col>
                      <Col half>
                        <Form.Item label={t('dimensionIdentifier')}>
                          <Input
                            value={item?.chartJson?.dimensionIdentifier}
                            onChange={(e) =>
                              this.setItemValue('chartJson.dimensionIdentifier', e.target.value)
                            }
                            readOnly={readOnly}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {['Line', 'Area', 'Column', 'Bar', 'Scatter', 'Pie', 'Donut'].includes(item.chartType) && (
                    <Row>
                      <Col half>
                        <Form.Item label={t('legendPosition')}>
                          <Select
                            value={item.chartJson.legendPosition}
                            onChange={(value) => this.setItemValue('chartJson.legendPosition', value)}
                            readOnly={readOnly}
                            placeholder={t('none')}
                          >
                            {Object.entries(chartLegendPositions).map(([key, value]) => (
                              <Option key={key} value={key}>
                                {t(value)}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      {['Line', 'Area', 'Column', 'Bar', 'Scatter'].includes(item.chartType) && (
                        <Col half>
                          <Form.Item label={t('distributionIdentifier')}>
                            <Input
                              value={item.chartJson.distributionIdentifier}
                              onChange={(e) =>
                                this.setItemValue('chartJson.distributionIdentifier', e.target.value)
                              }
                              readOnly={readOnly}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  )}
                </Tabs.TabPane>
              )}
            </Tabs>
          </div>
          <div className="form-buttons-container">
            {this.props.rootRecord ? (
              <>
                <Button onClick={this.props.onCancel}>{t(readOnly ? 'close' : 'cancel')}</Button>{' '}
                {!readOnly && (
                  <Tooltip title={t('childRecordSaveInfo')} placement="topLeft">
                    <Button type="primary" htmlType="submit" loading={this.state.saveAndCloseButtonLoading}>
                      {!this.props.linkTargetRecord ? t('add') : t('update')}
                    </Button>
                  </Tooltip>
                )}
              </>
            ) : (
              <>
                <FormButtons
                  readOnly={readOnly}
                  onCancel={this.props.onCancel}
                  onSubmit={this.handleSubmit}
                  saveButtonLoading={this.state.saveButtonLoading}
                  saveAndCloseButtonLoading={this.state.saveAndCloseButtonLoading}
                />
              </>
            )}
          </div>
        </Form>
        <Modal
          title={t('selectDashboardChart')}
          visible={this.state.copyFromExistingVisible}
          okText={t('copy')}
          onOk={this.handleCopyFromExisting}
          okButtonProps={{
            loading: this.state.copyFromExistingLoading,
            disabled: !this.state.copyFromExistingSelectedRecord,
          }}
          onCancel={() =>
            this.setState({
              copyFromExistingVisible: false,
              copyFromExistingSelectedRecord: null,
            })
          }
          width={992}
        >
          <SelectDashboardChart
            onSelect={(values) => this.setState({ copyFromExistingSelectedRecord: values[0] })}
          />
        </Modal>
      </>
    )
  }
}

export default Form.create()(FormView)
