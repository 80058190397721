import * as React from 'react'
import { useImmer } from 'use-immer'
import { Icon as AntIcon } from 'antd'
import { cloneDeep, isNil, set } from 'lodash'
import { showError } from 'helpers/errors'
import { t } from 'helpers/i18n'
import Modal from 'elements/Modal'
import SelectSupplier from 'containers/Punchout/SelectSupplier'

const CHECK_STATUS_INTERVAL = 2500

let stillMounted = false
let stopChecking = true
let punchoutWindow = null
let punchoutModal = null

function Component(props) {
  const { onCancel, onComplete, parentRecord, sideFormState, domainObjectType } = props

  const [state, updateState] = useImmer({})
  const { loading, visible, item } = state

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  async function handleModalOk() {
    const {
      assetDisplayName = '',
      locationDisplayName = '',
      jobDisplayName = '',
      operatorDisplayName = '',
      ...params
    } = cloneDeep(sideFormState)
    const asset = (params?.assets?.items ?? []).find((one) => one.id === params.assetId)
    const job = (params?.jobs?.items ?? []).find((one) => one.id === params.jobId)
    const location = (params?.locations?.items ?? []).find((one) => one.id === params.locationId)
    const operator = (params?.operators?.items ?? []).find((one) => one.id === params.operatorId)

    setState('loading', true)

    try {
      const punchoutRequest = await props
        .createPunchoutRequest({
          punchoutRequestType: domainObjectType,
          requestId: parentRecord.id,
          supplierId: item.id,
          assetId: asset?.id,
          assetName: asset?.name ?? `*${assetDisplayName}`,
          assetNumber: asset?.number,
          assetBarcode: asset?.barcode,
          jobId: job?.id,
          jobName: job?.name ?? `*${jobDisplayName}`,
          jobNumber: job?.number,
          jobBarcode:
            job?.barcode ?? (!props.customer.tagSettings.tag1Restricted ? jobDisplayName : undefined),
          locationId: location?.id,
          locationName: location?.name ?? `*${locationDisplayName}`,
          locationNumber: location?.number,
          locationBarcode: location?.barcode,
          operatorId: operator?.id,
          operatorName: operator?.name ?? `*${operatorDisplayName}`,
          operatorNumber: operator?.number,
          operatorBarcode: operator?.barcode,
          tagA: params.tagA,
          tagB: params.tagB,
          tagC: params.tagC,
          tagD: params.tagD,
          tagE: params.tagE,
        })
        .then((r) => r.value.data)

      if (isNil(punchoutRequest)) {
        throw new Error('Save response is invalid')
      }

      const createPunchoutModal = () =>
        Modal.confirm({
          title: t('accessingSupplierCatalogTitle'),
          content: <span dangerouslySetInnerHTML={{ __html: t('accessingSupplierCatalogDescription') }} />,
          okText: t('open'),
          onOk: () => {
            punchoutWindow = window.open(punchoutRequest.supplierUrl)
            punchoutModal = createPunchoutModal()
          },
          cancelText: t('cancel'),
          onCancel: () => {
            stopChecking = true
            if (punchoutWindow && !sessionStorage.getItem('debugPunchout')) {
              punchoutWindow.close()
            }
          },
          icon: <AntIcon type="loading" />,
        })

      punchoutModal = createPunchoutModal()

      punchoutWindow = window.open(punchoutRequest.supplierUrl)

      stopChecking = false

      checkPunchoutStatus(punchoutRequest.id)
    } catch (error) {
      showError({ error })
    } finally {
      setState('loading', false)
    }
  }

  async function checkPunchoutStatus(punchoutRequestId) {
    if (punchoutWindow?.closed) {
      try {
        stopChecking = true

        if (punchoutModal) {
          punchoutModal.destroy()
        }
      } catch (error) {
        console.warn(error)
      }
    }

    if (stillMounted && !stopChecking) {
      try {
        const response = await props.getPunchoutRequest(punchoutRequestId)

        if (response.value.data.status === 'Pending') {
          window.setTimeout(() => checkPunchoutStatus(punchoutRequestId), CHECK_STATUS_INTERVAL)
        } else {
          try {
            if (punchoutModal) {
              punchoutModal.destroy()
            }
            if (punchoutWindow && !sessionStorage.getItem('debugPunchout')) {
              punchoutWindow.close()
            }
          } catch (error) {
            console.warn(error)
          }

          onComplete()
        }
      } catch (error) {
        showError({ error })
      }
    }
  }

  function handleModalCancel() {
    stopChecking = true

    setState('visible', false)

    window.setTimeout(onCancel, 500)
  }

  React.useEffect(() => {
    stillMounted = true
    stopChecking = true

    setState('visible', true)

    return () => {
      stillMounted = false
      stopChecking = true

      try {
        if (punchoutModal) {
          punchoutModal.destroy()
        }
        if (punchoutWindow && !sessionStorage.getItem('debugPunchout')) {
          punchoutWindow.close()
        }
      } catch (error) {
        console.warn(error)
      }
    }
  }, [])

  React.useEffect(() => {
    setState('item', null)
  }, [visible])

  return (
    <>
      <Modal
        title={t('selectSupplierCatalog')}
        visible={visible}
        okText={t('select')}
        onOk={handleModalOk}
        okButtonProps={{
          loading,
          disabled: !item,
        }}
        onCancel={handleModalCancel}
        width={992}
      >
        <SelectSupplier onSelect={(values) => setState('item', values[0])} />
      </Modal>
    </>
  )
}

export default Component
