import { Button } from 'antd'
import { isEmpty } from 'lodash'
import { getUserPermission } from 'helpers/auth'
import { t } from 'helpers/i18n'
import { showClientNotifications, showError } from 'helpers/errors'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Icon from 'elements/Icon'
import Filter from 'containers/Reports/InventoryMinMaxReport/Filter'

export default createListViewComponent({
  entityName: entityNames.inventoryMinMaxReport,
  filterTemplateType: 'InventoryMinMaxReport',
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
  allowCreate: (self) => false,
  allowDelete: (self) => false,
  allowSelection: (self) => getUserPermission('Inventory') === 'Edit',
  extraRowActions: (self) =>
    getUserPermission('Inventory') === 'Edit' ? (
      <Button
        disabled={isEmpty(self.state.selectedRowKeys)}
        onClick={async () => {
          try {
            self.setState({ updateMinMaxLoading: true })

            const response = await self.props.updateMinMax(
              self.state.selectedRowKeys
                .map((each) => self.state.items.find((one) => one.id === each))
                .map((each) => ({
                  ...each,
                  min: each.suggestedMin,
                  max: each.suggestedMax,
                }))
            )

            showClientNotifications({ response })

            if (response.value.data.failureCount > 0) {
              throw new Error()
            }

            self.setState({ updateMinMaxLoading: false }, self.fetchItems)
          } catch (error) {
            showError({ error })
          } finally {
            self.setState({ updateMinMaxLoading: false })
          }
        }}
        loading={self.state.updateMinMaxLoading}
      >
        {!self.state.updateMinMaxLoading && <Icon type="Save" />}
        {t('updateMinMax')}
      </Button>
    ) : null,
})(Filter)
