import * as React from 'react'
import produce from 'immer'
import { isEmpty, orderBy, round } from 'lodash'
import { Form, Table, Tooltip, Button } from 'antd'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import { defaultRangeSettings } from 'helpers/charts'
import { isReadOnly } from 'helpers/formViews'
import Icon from 'elements/Icon'
import InputNumber from 'elements/InputNumber'
import ColorPicker from 'elements/ColorPicker'

function Component(props) {
  const { values = [], onChange = () => {} } = props

  const sortedValues = orderBy(isEmpty(values) ? defaultRangeSettings : values, [(each) => each[0]])

  const [selectedRowKeys, setSelectedRowKeys] = React.useState([])

  let dataSource = []

  try {
    dataSource = sortedValues.map((each, index, array) => ({
      index,
      from: index === 0 ? 0 : array[index - 1][0],
      to: each[0],
      color: each[1],
    }))
  } catch (error) {
    console.warn(error)
  }

  const readOnly = isReadOnly({ props })

  function addNew() {
    onChange([[sortedValues[0][0] / 2, '#999999'], ...sortedValues])

    setSelectedRowKeys([])
  }

  function deleteSelected() {
    onChange(
      sortedValues.filter((each, index) => (selectedRowKeys.includes(index) ? null : each)).filter(Boolean)
    )

    setSelectedRowKeys([])
  }

  function setNumber(index, number) {
    const minimum = index === 0 ? 0 : sortedValues[index - 1][0] - 0.01
    const maximum = sortedValues[index + 1][0] - 0.01

    if (minimum <= number && number <= maximum) {
      onChange(
        produce(sortedValues, (draft) => {
          draft[index][0] = number
        })
      )
    }
  }

  function setColor(index, color) {
    onChange(
      produce(sortedValues, (draft) => {
        draft[index][1] = color
      })
    )
  }

  return (
    <Form.Item label={t('rangeSettings')} className="mb-0">
      <div className="tofino-label-buttons">
        <Tooltip title={t('delete')}>
          <Button
            size="small"
            onClick={() => deleteSelected()}
            disabled={readOnly || isEmpty(selectedRowKeys)}
          >
            <Icon type="Delete" />
          </Button>
        </Tooltip>{' '}
        <Tooltip title={t('add')}>
          <Button type="primary" size="small" onClick={() => addNew()} disabled={readOnly}>
            <Icon type="Add" bold />
          </Button>
        </Tooltip>
      </div>
      <Table
        childrenColumnName={[]}
        rowKey="index"
        dataSource={dataSource}
        rowSelection={{
          selectedRowKeys,
          onChange: (rowKeys) => setSelectedRowKeys(rowKeys),
          getCheckboxProps: (record) => (record.index === dataSource.length - 1 ? { disabled: true } : {}),
        }}
        pagination={false}
      >
        <Table.Column
          key="from"
          title={t('from')}
          dataIndex="from"
          width="20%"
          render={(text, record, index) => round(text, 2)}
        />
        <Table.Column
          key="to"
          title={t('to')}
          dataIndex="to"
          width="30%"
          render={(text, record, index) =>
            index < dataSource.length - 1 ? (
              <InputNumber
                value={text}
                onChange={(number) => setNumber(index, number)}
                onPressEnter={stopEvent}
                readOnly={readOnly}
                step={0.01}
                min={0.01}
                max={sortedValues[index + 1][0] - 0.01}
                precision={2}
              />
            ) : (
              text
            )
          }
        />
        <Table.Column
          key="color"
          title={t('color')}
          dataIndex="color"
          width="30%"
          render={(text, record, index) => (
            <ColorPicker
              value={text}
              onChange={(color) => setColor(index, color)}
              readOnly={readOnly}
              disabled={props.useThemeColors}
            />
          )}
        />
      </Table>
    </Form.Item>
  )
}

export default Component
