import { Drawer as AntDrawer, Spin } from 'antd'
import { toString as str } from 'lodash'
import cx from 'clsx'
import { useWindowSize } from 'helpers/hooks'

const SIDE_WIDTH = 357
const DUPLEX_WIDTH = 1323

const duplexWidth = (windowWidth) => {
  if (windowWidth < SIDE_WIDTH * 2) {
    return windowWidth
  }

  return windowWidth > DUPLEX_WIDTH + SIDE_WIDTH
    ? Math.max(DUPLEX_WIDTH, SIDE_WIDTH)
    : Math.max(windowWidth - SIDE_WIDTH, SIDE_WIDTH)
}

function Drawer(props) {
  const {
    title,
    onCancel,
    closable = true,
    saving = false,
    size = 'xs',
    children,
    visible,
    sideDrawer,
    style,
    ...rest
  } = props

  const windowSize = useWindowSize()

  const className = cx(props.className, {
    'tofino-drawer': true,
    'tofino-drawer--xs': size === 'xs',
    'tofino-drawer--sm': size === 'sm',
    'tofino-drawer--md': size === 'md',
    'tofino-drawer--lg': size === 'lg',
    'tofino-drawer--xl': size === 'xl',
    'tofino-drawer--xxl': size === 'xxl',
    'tofino-drawer--fs': size === 'fs',
    'tofino-drawer--duplex': size === 'duplex',
    'tofino-drawer--side-drawer': sideDrawer,
  })

  function getWidth() {
    if (size === 'duplex') {
      return duplexWidth(windowSize.width)
    }

    return size === 'fs'
      ? '100%'
      : size === 'xxl'
        ? 1600
        : size === 'xl'
          ? DUPLEX_WIDTH
          : size === 'lg'
            ? 992
            : size === 'md'
              ? 768
              : size === 'sm'
                ? 576
                : 480
  }

  return (
    <AntDrawer
      className={className}
      title={str(title)}
      placement="right"
      width={getWidth()}
      closable={closable && !saving}
      visible={visible}
      maskClosable={false}
      {...rest}
      {...(sideDrawer
        ? {
            mask: false,
            placement: 'right',
            width: SIDE_WIDTH + 1,
            closable: false,
            style: {
              right: duplexWidth(windowSize.width) - 1,
              display: windowSize.width >= SIDE_WIDTH * 2 ? 'block' : 'none',
            },
          }
        : { style })}
      destroyOnClose
    >
      {saving && !sideDrawer && <Spin style={{ position: 'absolute', top: '18px', right: '18px' }} />}
      {visible && <>{children}</>}
    </AntDrawer>
  )
}

export default Drawer
