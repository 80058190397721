import { get, uniq, isNaN, isEmpty, toString as str, memoize, isNil, orderBy, clamp } from 'lodash'
import { tryParseJSON, tryParseFloat } from 'helpers/utils'
import { formatValue } from 'helpers/listViews'

export const getTitle = ({ chart }) => {
  const { showTitleOnChart = true, legendPosition } = tryParseJSON(chart.chartJson, {})

  return showTitleOnChart
    ? {
        text: chart.name,
        [legendPosition === 'top' ? 'bottom' : 'top']: 0,
        left: 'center',
        textStyle: { fontSize: 14, fontWeight: 600 },
      }
    : undefined
}

export const getLegend = ({ chart }) => {
  const { legendPosition } = tryParseJSON(chart.chartJson, {})

  switch (legendPosition) {
    case 'top':
    case 'bottom':
      return {
        type: 'scroll',
        [legendPosition]: 0,
        left: 'center',
      }

    case 'left':
    case 'right':
      return {
        type: 'scroll',
        [legendPosition]: 0,
        top: 'center',
        orient: 'vertical',
      }

    default:
      return undefined
  }
}

export const getToolbox = ({ theme }) => ({
  showTitle: false,
  feature: { saveAsImage: {} },
  iconStyle: {
    borderColor: tryParseJSON(theme?.styleJSON, {})?.title?.textStyle?.color ?? '#666666',
  },
})

export const getDataZoom = ({ chart, data }) =>
  get(data, 'items[0].primaryData', []).filter((each) => each.series === 1).length > 12
    ? { bottom: '10%' }
    : undefined

export const getGrid = ({ chart, data }) => ({
  containLabel: true,
  bottom: getDataZoom({ chart, data }) ? '20%' : '10%',
})

const getSeriesProps = ({ chart }) => {
  const { showSmoothLine } = tryParseJSON(chart.chartJson, {})

  switch (chart.chartType) {
    case 'Bar':
    case 'Column':
      return { type: 'bar' }

    case 'Area':
      return {
        type: 'line',
        areaStyle: {},
        smooth: showSmoothLine,
      }

    case 'Line':
      return {
        type: 'line',
        smooth: showSmoothLine,
      }

    case 'Scatter':
      return { type: 'scatter' }

    case 'Pie':
      return {
        type: 'pie',
        radius: '50%',
      }

    case 'Donut':
      return {
        type: 'pie',
        radius: ['20%', '50%'],
      }

    case 'Gauge':
      return {
        type: 'gauge',
        radius: '80%',
      }

    default:
      return {}
  }
}

const getSecondarySeriesProps = ({ chart }) => {
  const { showSmoothLine, secondaryChartType = 'Line' } = tryParseJSON(chart.chartJson, {})

  switch (secondaryChartType) {
    case 'Bar':
    case 'Column':
      return { type: 'bar' }

    case 'Area':
      return {
        type: 'line',
        areaStyle: {},
        smooth: showSmoothLine,
      }

    default:
      return {
        type: 'line',
        smooth: showSmoothLine,
      }
  }
}

export const getCategories = ({ data }) =>
  uniq(get(data, 'items[0].primaryData', []).map((each) => each.dimensionValue))

export const getPrimarySeries = ({ chart, data }) => {
  const {
    primaryMetricDisplayFormat: metricDisplayFormat = 'Text',
    primaryMetricTypeName,
    dimensionTypeName,
    distributionType,
    distributionTypeName,
  } = chart
  const primaryData = get(data, 'items[0].primaryData', [])
  const distributionValues = uniq(primaryData.map((each) => each.distributionValue))
  const {
    primaryMetricTitle,
    primaryMetricIdentifier,
    dimensionIdentifier,
    stackDistributedData,
    distributionIdentifier,
  } = tryParseJSON(chart.chartJson, {})

  const metricName = primaryMetricIdentifier || primaryMetricTypeName
  const dimensionName = dimensionIdentifier || dimensionTypeName
  const distributionName =
    distributionIdentifier || (distributionType !== 'None' ? distributionTypeName : undefined)

  return distributionValues.map((distributionValue) => ({
    ...getSeriesProps({ chart }),
    name: distributionValue || primaryMetricIdentifier || primaryMetricTitle,
    stack: stackDistributedData ? 'stack' : undefined,
    data: primaryData
      .filter((each) => each.distributionValue === distributionValue)
      .map((each) => parseValue(each.metricValue, metricDisplayFormat)),
    tooltip: {
      formatter: (params) => {
        const dimensionValue = params.name
        const metricValue = formatValue({
          value: params.value,
          displayFormat: metricDisplayFormat,
        })

        let tooltip = !isEmpty(distributionValue)
          ? `${distributionName ? `${distributionName}: ` : ''}${distributionValue}<br />`
          : ''

        tooltip += `${dimensionName}: ${dimensionValue}<br />${metricName}: ${metricValue}`

        return tooltip
      },
    },
  }))
}

export const getSecondarySeries = ({ chart, data }) => {
  const {
    secondaryMetricDisplayFormat: metricDisplayFormat = 'Text',
    secondaryMetricTypeName,
    dimensionTypeName,
  } = chart

  if (chart.secondaryMetricType && chart.secondaryMetricType !== 'None') {
    const secondaryData = get(data, 'items[0].secondaryData', [])
    const { secondaryMetricIdentifier, secondaryMetricTitle, dimensionIdentifier } = tryParseJSON(
      chart.chartJson,
      {}
    )

    const metricName = secondaryMetricIdentifier || secondaryMetricTypeName
    const dimensionName = dimensionIdentifier || dimensionTypeName

    return {
      ...getSecondarySeriesProps({ chart }),
      yAxisIndex: 1,
      name: secondaryMetricIdentifier || secondaryMetricTitle,
      data: secondaryData.map((each) => parseValue(each.metricValue, chart.metricDisplayFormat)),
      tooltip: {
        formatter: (params) => {
          const dimensionValue = params.name
          const metricValue = formatValue({
            value: params.value,
            displayFormat: metricDisplayFormat,
          })

          return `${dimensionName}: ${dimensionValue}<br />${metricName}: ${metricValue}`
        },
      },
    }
  }

  return null
}

export const getPieSeries = ({ chart, data }) => {
  const {
    primaryMetricDisplayFormat: metricDisplayFormat = 'Text',
    primaryMetricTypeName,
    dimensionTypeName,
  } = chart
  const { primaryMetricIdentifier, dimensionIdentifier } = tryParseJSON(chart.chartJson, {})

  const metricName = primaryMetricIdentifier || primaryMetricTypeName
  const dimensionName = dimensionIdentifier || dimensionTypeName

  return [
    {
      ...getSeriesProps({ chart }),
      data: get(data, 'items[0].primaryData', [])
        .filter((each) => each.series === 1)
        .map((each) => ({
          value: parseValue(each.metricValue, metricDisplayFormat),
          name: each.dimensionValue,
        })),
      tooltip: {
        formatter: (params) => {
          const dimensionValue = params.name
          const metricValue = formatValue({
            value: params.value,
            displayFormat: metricDisplayFormat,
          })

          return `${dimensionName}: ${dimensionValue}<br />${metricName}: ${metricValue}`
        },
      },
    },
  ]
}

export const defaultRangeSettings = [
  [0.2, '#00CC00'],
  [0.8, '#3399FF'],
  [1, '#FF3333'],
]

export const getGaugeSeries = ({ chart, data, theme }) => {
  const { primaryMetricDisplayFormat: metricDisplayFormat = 'Text', primaryMetricTypeName } = chart
  const {
    primaryMetricIdentifier,
    startAngle = 180,
    endAngle = 0,
    minimumValue = 0,
    maximumValue = 100,
    splitNumber = 5,
    rangeSettings = defaultRangeSettings,
    useThemeColors = false,
  } = tryParseJSON(chart.chartJson, {})

  const metricName = primaryMetricIdentifier || primaryMetricTypeName
  const themeColors = tryParseJSON(theme?.styleJSON, {})?.color ?? []

  return [
    {
      ...getSeriesProps({ chart }),
      startAngle,
      endAngle,
      min: minimumValue,
      max: maximumValue,
      splitNumber,
      axisLine: {
        lineStyle: {
          width: 15,
          color: useThemeColors
            ? rangeSettings.map((each, index) => [each[0], themeColors[index % themeColors.length]])
            : rangeSettings,
        },
      },
      data: get(data, 'items[0].primaryData', [])
        .filter((each) => each.series === 1)
        .map((each) => ({
          value: metricDisplayFormat.startsWith('Percent')
            ? parseValue(each.metricValue, metricDisplayFormat, 0) * 100
            : parseValue(each.metricValue, metricDisplayFormat, 0),
        })),
      detail: {
        fontSize: 16,
        formatter: (value) => {
          const metricValue = formatValue({
            value: metricDisplayFormat.startsWith('Percent') ? value / 100 : value,
            displayFormat: metricDisplayFormat,
          })

          return `${metricValue}`
        },
      },
      tooltip: {
        formatter: (params) => {
          const metricValue = formatValue({
            value: metricDisplayFormat.startsWith('Percent') ? params.value / 100 : params.value,
            displayFormat: metricDisplayFormat,
          })

          return `${metricName}: ${metricValue}`
        },
      },
    },
  ]
}

export const getScatterSeries = ({ chart, data }) => {
  const {
    primaryMetricDisplayFormat: metricDisplayFormat = 'Text',
    dimensionDisplayFormat = 'Text',
    primaryMetricTypeName,
    dimensionTypeName,
    distributionType,
    distributionTypeName,
  } = chart
  const primaryData = get(data, 'items[0].primaryData', [])
  const distributionValues = uniq(primaryData.map((each) => each.distributionValue))
  const { primaryMetricTitle, primaryMetricIdentifier, dimensionIdentifier, distributionIdentifier } =
    tryParseJSON(chart.chartJson, {})

  const metricName = primaryMetricIdentifier || primaryMetricTypeName
  const dimensionName = dimensionIdentifier || dimensionTypeName
  const distributionName =
    distributionIdentifier || (distributionType !== 'None' ? distributionTypeName : undefined)

  return distributionValues.map((distributionValue) => {
    const distributionData = primaryData
      .filter((each) => each.distributionValue === distributionValue)
      .filter((each) => each.metricValue !== '0' || each.dimensionValue !== '0')
    const symbolSize = memoize(([p0, p1]) => {
      const equals = distributionData.filter(
        (each) => each.metricValue === str(p0) && each.dimensionValue === str(p1)
      )
      return 5 + equals.length * 5
    })

    return {
      ...getSeriesProps({ chart }),
      symbolSize,
      name: distributionValue || primaryMetricIdentifier || primaryMetricTitle,
      data: distributionData.map((each) => [
        parseValue(each.dimensionValue, dimensionDisplayFormat),
        parseValue(each.metricValue, metricDisplayFormat),
      ]),
      tooltip: {
        formatter: (params) => {
          const dimensionValue = formatValue({
            value: params.data[0],
            displayFormat: dimensionDisplayFormat,
          })
          const metricValue = formatValue({
            value: params.data[1],
            displayFormat: metricDisplayFormat,
          })

          let tooltip = !isEmpty(distributionValue)
            ? `${distributionName ? `${distributionName}: ` : ''}${distributionValue}<br />`
            : ''

          tooltip += `${dimensionName}: ${dimensionValue}<br />${metricName}: ${metricValue}`

          return tooltip
        },
      },
    }
  })
}

const parseValue = (value, displayFormat = 'Text', defaultValue = null) => {
  if (displayFormat === 'Int') {
    const parsed = Number.parseInt(value)

    return !isNaN(parsed) ? parsed : defaultValue
  }

  if (displayFormat.startsWith('Float')) {
    const parsed = Number.parseFloat(value)

    return !isNaN(parsed) ? parsed : defaultValue
  }

  if (displayFormat.startsWith('Currency')) {
    const parsed = Number.parseFloat(value)

    return !isNaN(parsed) ? parsed : defaultValue
  }

  if (displayFormat.startsWith('Percent')) {
    const parsed = Number.parseFloat(value)

    return !isNaN(parsed) ? parsed : defaultValue
  }

  return value
}

export const getPrimaryAxisLabel = ({ chart }) => ({
  formatter: (value) =>
    formatValue({ value, displayFormat: get(chart, 'primaryMetricDisplayFormat', 'Text') }),
})

export const getSecondaryAxisLabel = ({ chart }) => ({
  formatter: (value) =>
    formatValue({ value, displayFormat: get(chart, 'secondaryMetricDisplayFormat', 'Text') }),
})

export const getDimensionAxisLabel = ({ chart }) => ({
  formatter: (value) => formatValue({ value, displayFormat: get(chart, 'dimensionDisplayFormat', 'Text') }),
})

export const getScorecardColor = ({ chart, data, theme }) => {
  const metricValue = get(data, 'items[0].primaryData[0].metricValue')
  const subtextColor = get(tryParseJSON(theme?.styleJSON, {}), 'title.subtextStyle.color', '#666666')

  if (isNil(metricValue)) {
    return subtextColor
  }

  const { primaryMetricDisplayFormat: metricDisplayFormat = 'Text' } = chart

  const value = metricDisplayFormat.startsWith('Percent')
    ? tryParseFloat(metricValue, 0) * 100
    : tryParseFloat(metricValue, 0)

  const {
    minimumValue = 0,
    maximumValue = 100,
    rangeSettings = defaultRangeSettings,
    useThemeColors = false,
  } = tryParseJSON(get(chart, 'chartJson', '{}'), {})

  const colors = orderBy(rangeSettings, [(each) => each[0]])
  const ratio = clamp((value - minimumValue) / (maximumValue - minimumValue), 0, 1)

  let index = 0

  for (let i = 0; i < colors.length; i++) {
    const from = i === 0 ? 0 : colors[i - 1][0]
    const to = i === colors.length - 1 ? 1 : colors[i + 1][0]

    if (ratio >= from && ratio <= to) {
      index = i
    }
  }

  if (useThemeColors) {
    const themeColors = get(tryParseJSON(theme?.styleJSON, {}), 'color', [])

    return themeColors[index % themeColors.length]
  }

  return colors[index][1]
}

export const getComparison = ({ chart, data, theme }) => {
  if (!chart.includeComparisonValue) {
    return {}
  }

  const {
    primaryMetricDisplayFormat: primaryDisplayFormat = 'Text',
    comparisonRankingType = 'LowerPrimaryIsBetter',
  } = chart
  const primaryValue = tryParseFloat(get(data, 'items[0].primaryData[0].metricValue'), 0)
  const comparisonValue = tryParseFloat(get(data, 'items[0].comparisonData[0].metricValue'), 0)

  const lowerColor = comparisonRankingType === 'LowerPrimaryIsBetter' ? '#00CC00' : '#FF3333'
  const higherColor = comparisonRankingType === 'LowerPrimaryIsBetter' ? '#FF3333' : '#00CC00'
  const defaultColor = get(tryParseJSON(theme?.styleJSON, {}), 'title.subtextStyle.color', '#666666')

  const comparisonColor =
    primaryValue > comparisonValue ? higherColor : primaryValue < comparisonValue ? lowerColor : defaultColor
  const comparisonIcon =
    comparisonValue > primaryValue
      ? 'TrendingDown'
      : comparisonValue < primaryValue
        ? 'TrendingUp'
        : 'TrendingFlat'
  const comparisonText = formatValue({ displayFormat: primaryDisplayFormat, value: comparisonValue })

  const differenceDisplayFormat = primaryDisplayFormat.startsWith('Percent')
    ? primaryDisplayFormat.replace('Percent', 'Float')
    : 'Percent'
  const differenceValue = primaryDisplayFormat.startsWith('Percent')
    ? (primaryValue - comparisonValue) * 100
    : comparisonValue === 0
      ? 1
      : (primaryValue - comparisonValue) / comparisonValue

  const differenceText = `(${differenceValue >= 0 ? '+' : ''}${formatValue({
    displayFormat: differenceDisplayFormat,
    value: differenceValue,
  })})`

  return { comparisonColor, comparisonIcon, comparisonText, differenceText }
}
