import produce from 'immer'
import { set, unset } from 'lodash'
import { getSessionItem, setSessionItem } from 'helpers/sessionStorage'
import { AUTH_SESSION_KEY } from 'options/auth'

export const LOGIN = 'LOGIN'
export const LOGO_GET = 'LOGO_GET'
export const LOGIN_AS_CUSTOMER = 'LOGIN_AS_CUSTOMER'
export const EXIT_AS_CUSTOMER = 'EXIT_AS_CUSTOMER'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'

export default (state = getSessionItem(AUTH_SESSION_KEY, {}), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case `${LOGIN}_FULFILLED`:
        draft.current = action.payload.data
        setSessionItem(AUTH_SESSION_KEY, { ...draft })
        break

      case `${LOGO_GET}_FULFILLED`:
        set(draft, 'current.logo', action.payload.data)
        setSessionItem(AUTH_SESSION_KEY, { ...draft })
        break

      case `${LOGIN_AS_CUSTOMER}_FULFILLED`:
        draft.impersonator = draft.current
        draft.current = action.payload.data
        setSessionItem(AUTH_SESSION_KEY, { ...draft })
        break

      case EXIT_AS_CUSTOMER:
        draft.current = draft.impersonator
        unset(draft, 'impersonator')
        setSessionItem(AUTH_SESSION_KEY, { ...draft })
        break

      case `${REFRESH_TOKEN}_FULFILLED`:
        Object.assign(draft.current, action.payload.data)
        setSessionItem(AUTH_SESSION_KEY, { ...draft })
        break

      default:
        break
    }
  })
