import * as React from 'react'
import { Spin, Table, Menu } from 'antd'
import { isEmpty, toLower, isNil, toString as str, pick, debounce } from 'lodash'
import { showError } from 'helpers/errors'
import {
  pageSizeOptions,
  getChildColumns,
  showTotal,
  createHandleCustomizeOk,
  createHandleColumnResize,
  createChildListViewHandleTableChange,
  getTableWidth,
} from 'helpers/listViews'
import { getKeyValueItem, setKeyValueItem } from 'helpers/keyValueStorage'
import { createResetState } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { Emitter } from 'helpers/events'
import { LIST_MODAL_CLEAR_SELECTION } from 'options/events'
import Customize from 'elements/Customize'
import ResizableCell from 'elements/ResizableCell'
import Toolbar from 'elements/Toolbar'
import SelectedRows from 'elements/SelectedRows'
import FilterContainer from 'elements/FilterContainer'

export default ({
    getStorageKey = (self) => self.props.storageKey ?? '__CHILD_LIST_MODAL__',
    getSettingsType = (self) => '',
    getRowClassName = (self) => (record, index) => '',
    getRowSelectionCheckboxProps = (self) => (record) => ({
      // disabled
    }),
    getIdField = (self) => 'id',
    allowPagination = (self) => true,
    tableCellComponents = {},
    totalsRowComponents = {},
    tableHeadGroups = (self) => ({
      // dtoFieldName: () => []
    }),
    initialFilterDto = (self) => ({ ...self.props.initialFilterDto }),
    initialState = (self) => ({ ...self.props.initialState }),
    filterItems = (self) => (each) => true,
  }) =>
  (Filter) =>
    class ChildListModal extends React.Component {
      state = {}

      constructor(props) {
        super(props)

        this.handleCustomizeOk = createHandleCustomizeOk(this)
        this.handleColumnResize = createHandleColumnResize(this)
        this.handleTableChange = createChildListViewHandleTableChange(this)
        this.resetState = createResetState(this, {
          search: '',
          pageSize: allowPagination(this) ? 20 : undefined,
          selectedRowKeys: [],
          selectedColumnKeys: [],
          sortedColumnKeys: [],
          columnWidths: {},
          customizeColumnsVisible: false,
          filterDto: {
            ...initialFilterDto(this),
            ...this.props.initialFilterDto,
          },
          ...initialState(this),
        })
        this.saveState = debounce(this.saveState, 2500)
      }

      async componentDidMount() {
        const savedState = await getKeyValueItem(getStorageKey(this), {})
        await this.resetState(savedState)
        await this.fetchSettings()

        Emitter.on(LIST_MODAL_CLEAR_SELECTION, this.clearSelection)
      }

      componentWillUnmount() {
        this.saveState.flush()
        Emitter.off(LIST_MODAL_CLEAR_SELECTION, this.clearSelection)
      }

      clearSelection = () =>
        this.setState({ selectedRowKeys: [] }, () => {
          this.props.onSelect?.([], this.state.filterDto)
        })

      fetchSettings = async () => {
        try {
          const { settingsType = getSettingsType(this) } = this.props
          const response = await this.props.getSettings({ type: settingsType })

          this.setState({ fieldSettings: response.value.data.fieldSettings }, this.fetchItems)

          return response
        } catch (error) {
          showError({ error })

          return error
        }
      }

      fetchItems = async () => {
        try {
          this.setState({ loading: true })

          const response = await this.props.getItems() // NOTE: this.props.getItems = this.props.items

          this.setState({ items: response.value.data.items })
        } catch (error) {
          showError({ error })
        } finally {
          this.setState({ loading: false, selectedRowKeys: [] }, () => {
            this.props.onFilter?.(this.state.filterDto)
            this.props.onSelect?.([], this.state.filterDto)
            this.saveState()
          })
        }
      }

      saveState = () => {
        if (window.location.search) {
          return
        }

        setKeyValueItem(
          getStorageKey(this),
          pick(this.state, [
            'columnWidths',
            'showFilter',
            'pageSize',
            'selectedColumnKeys',
            'sortBy',
            'sortOrder',
            'sortedColumnKeys',
          ])
        )
      }

      handleFilterChange = (filterDto) =>
        this.setState({ filterDto, selectedRowKeys: [], pageIndex: 1 }, () => {
          this.props.onFilter?.(filterDto)
          this.props.onSelect?.([], filterDto)
          this.saveState()
        })

      searchItems =
        (search = '') =>
        (item = {}) =>
          Object.values(item).map(str).map(toLower).join(' ').indexOf(toLower(search)) > -1

      render() {
        const { onSelect = () => {}, mode = 'default' } = this.props
        const { items = [], fieldSettings = [], filterDto = {} } = this.state

        if (isEmpty(fieldSettings)) {
          return <Spin />
        }

        const columns = getChildColumns({
          self: this,
          fieldSettings,
          tableCellComponents,
          totalsRowComponents,
          tableHeadGroups,
          ...pick(this.state, [
            'columnWidths',
            'selectedColumnKeys',
            'sortBy',
            'sortedColumnKeys',
            'sortOrder',
          ]),
          onResize: this.handleColumnResize,
        })
        const width = isEmpty(columns) ? '100%' : getTableWidth(columns, true)
        this.dataSource = items.filter(filterItems(this)).filter(this.searchItems(this.state.search))

        // console.log({ items, fieldSettings })

        return (
          <div className="tofino-child-list-modal">
            <div className="tofino-inner-container">
              <div className="tofino-toolbar-container">
                <Toolbar
                  search={this.state.search}
                  onSearchChange={(e) =>
                    this.setState(
                      { search: e.target.value, pageIndex: 1, selectedRowKeys: [] },
                      this.fetchItems
                    )
                  }
                  actionsMenuItems={[
                    fieldSettings.length > 1 ? (
                      <Menu.Item key="customizeColumns">{t('customizeColumns')}</Menu.Item>
                    ) : null,
                  ].filter(Boolean)}
                  onActionsMenuClick={({ key }) => {
                    if (key === 'customizeColumns') {
                      this.setState({ customizeColumnsVisible: true })
                    }
                  }}
                  fieldSettings={fieldSettings}
                />
              </div>
              {!isNil(Filter) && (
                <FilterContainer disabled={this.props.filterProps?.disabled}>
                  <Filter
                    filterDto={filterDto}
                    onChange={this.handleFilterChange}
                    items={items}
                    {...this.props.filterProps}
                  />
                </FilterContainer>
              )}
              {mode === 'multiple' && (
                <div className="mb-12">
                  <SelectedRows selectedRowKeys={this.state.selectedRowKeys} />
                </div>
              )}
              <div className="tofino-table-container">
                <Table
                  childrenColumnName={[]}
                  rowKey={getIdField(this)}
                  loading={this.state.loading}
                  columns={columns}
                  dataSource={this.dataSource}
                  pagination={
                    allowPagination(this)
                      ? {
                          showTotal,
                          pageSizeOptions,
                          showSizeChanger: true,
                          pageSize: this.state.pageSize,
                        }
                      : false
                  }
                  onChange={this.handleTableChange}
                  size="middle"
                  locale={{ emptyText: t('noData') }}
                  rowSelection={{
                    type: mode === 'multiple' ? 'checkbox' : 'radio',
                    selectedRowKeys: this.state.selectedRowKeys,
                    onChange: (rowKeys, rows) =>
                      this.setState({ selectedRowKeys: rowKeys }, () => onSelect(rows)),
                    getCheckboxProps: getRowSelectionCheckboxProps(this),
                  }}
                  style={{ width }}
                  components={{ header: { cell: ResizableCell } }}
                  rowClassName={getRowClassName(this)}
                />
              </div>
              <Customize
                displayableColumns={fieldSettings.filter((each) => each.isDisplayable)}
                visible={this.state.customizeColumnsVisible}
                onOk={this.handleCustomizeOk}
                onCancel={() => this.setState({ customizeColumnsVisible: false })}
                selectedColumnKeys={this.state.selectedColumnKeys}
                sortedColumnKeys={this.state.sortedColumnKeys}
                columnWidths={this.state.columnWidths}
              />
            </div>
          </div>
        )
      }
    }
