import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'

export default createListViewComponent({
  allowCreate: (self) => false,
  allowSearching: (self) => false,
  allowSelection: (self) => false,
  entityName: entityNames.notifications,
  tableCellComponents: {
    description: (self, item) => <span dangerouslySetInnerHTML={{ __html: item.description }} />,
  },
})()
