import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import userActions from 'actions/users'
import authActions from 'actions/auth'
import dashboardThemeActions from 'actions/dashboardThemes'
import dashboardTemplateActions from 'actions/dashboardTemplates'
import Component from 'components/DashboardViewer'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  updateUser: userActions.updateItem,
  refreshToken: authActions.refreshToken,
  getDashboardTheme: dashboardThemeActions.getItem,
  getDashboardThemes: dashboardThemeActions.getOptions,
  getDashboardTemplates: dashboardTemplateActions.getOptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
