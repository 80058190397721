import * as React from 'react'
import styled from 'styled-components'
import { getPageTitle } from 'helpers/auth'
import { t } from 'helpers/i18n'
import Page from 'elements/Page'
import Toolbar from 'elements/Toolbar'

const contactInfo = [
  { label: 'phone', value: '+1 (866) 231-8137', href: 'tel:+1-866-231-8137' },
  { label: 'email', value: 'info@tofinosoftware.com', href: 'mailto:info@tofinosoftware.com' },
]

const List = styled.dl`
  max-width: 320px;

  dt,
  dd {
    margin: 0;
    padding: 0;
    color: unset;
    font-weight: unset;
    display: block;
  }

  dt {
    margin-bottom: 4px;
  }

  dd {
    border-radius: 3px;
    margin-bottom: 24px;
    border: 1px solid #d9d9d9;
    padding: 4px 11px;
  }
`

function Component(props) {
  const pageTitle = getPageTitle()

  return (
    <Page title={pageTitle} scrollable>
      <Toolbar title={pageTitle} />
      <div dangerouslySetInnerHTML={{ __html: t('contactInfo') }} />
      <br />
      <List>
        {contactInfo.map((each) => [
          <dt key={each.label}>{t(each.label)}</dt>,
          <dd key={each.value}>
            <a href={each.href} target="_blank" rel="noreferrer">
              {each.value}
            </a>
          </dd>,
        ])}
      </List>
    </Page>
  )
}

export default Component
