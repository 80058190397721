import * as React from 'react'
import { cloneDeep, set } from 'lodash'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createDropdownRender, createSearchSuppliers } from 'helpers/formViews'
import orderStatusOptions from 'options/orders/statusOptions'
import orderTypeOptions from 'options/orders/orderTypes'
import { HARD_SPACE } from 'helpers/utils'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import Checkbox from 'elements/Checkbox'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchSuppliers = createSearchSuppliers(this)
  }

  componentDidMount() {
    const { locationGroupIds, locationIds, supplierIds, tenantId, excludeExternal } = this.props.filterDto

    Promise.all([
      this.props.getSuppliers(supplierIds ? { supplierIds } : undefined),
      this.props.getLocationGroups(),
      this.props.getLocations({
        locationGroupIds,
        locationIds,
        locationVendingTypes: excludeExternal ? ['NonVending'] : ['Vending', 'NonVending'],
      }),
      this.props.getUsers(this.props.userIsInRoleSupplier ? { tenantId } : undefined),
    ]).catch((error) => showError({ error }))
  }

  changeExcludeExternal = async (excludeExternal) => {
    const filterDto = cloneDeep(this.props.filterDto)

    set(filterDto, 'excludeExternal', excludeExternal)

    if (excludeExternal) {
      set(filterDto, 'locationGroupIds', [])
      set(filterDto, 'locationIds', [])
    }

    try {
      const { locationGroupIds, locationIds } = filterDto

      await this.props.getLocations({
        locationGroupIds,
        locationIds,
        locationVendingTypes: excludeExternal ? ['NonVending'] : ['Vending', 'NonVending'],
      })
      await this.props.onChange({ ...filterDto })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const {
      filterDto,
      suppliers,
      userIsInRoleSupplier,
      userIsInRoleDistributor,
      userIsDcribCustomer,
      tenantTypeIsTrms,
    } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <Form.Item label={t('dateToUseForCalendar')}>
              <Select
                onChange={(value) => this.setFilterValue('dateRangeField', value || 'CreatedDate')}
                value={filterDto.dateRangeField}
                placeholder={t('createdDate')}
              >
                <Option value="CreatedDate">{t('createdDate')}</Option>
                <Option value="SentDate">{t('sentDate')}</Option>
                <Option value="ApprovedDate">{t('approvedDate')}</Option>
                <Option value="ReceivedDate">{t('receivedDate')}</Option>
              </Select>
            </Form.Item>
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                <Option value={0}>{t('none')}</Option>
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) => this.setFilterValue('statusFilter', value || 'All')}
                value={filterDto.statusFilter || 'All'}
                placeholder={t('all')}
              >
                {Object.entries(orderStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('createdBy')}>
              <Select
                onChange={(value) => this.setFilterValue('createdByUserNames', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.createdByUserNames}
                showSearch
              >
                {(this.props.users ?? []).map((each) => (
                  <Option key={each.userName} value={each.userName}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {tenantTypeIsTrms && (
              <Form.Item label={t('supplier')}>
                <Select
                  onChange={(value) => this.setFilterValue('supplierIds', value)}
                  mode="multiple"
                  placeholder={t('all')}
                  value={filterDto.supplierIds}
                  dropdownRender={createDropdownRender(suppliers)}
                  onSearch={this.searchSuppliers}
                  showSearch
                >
                  {(suppliers?.items ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {!userIsInRoleSupplier && (
              <Form.Item label={t('usagePurchase')}>
                <Select
                  onChange={(value) => this.setFilterValue('issueOrderFilter', value)}
                  value={filterDto.issueOrderFilter}
                  placeholder={t('all')}
                >
                  {Object.entries(orderTypeOptions).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {t(value)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col fourth>
            {!userIsInRoleDistributor && !userIsInRoleSupplier && (
              <Form.Item label={HARD_SPACE}>
                <Checkbox
                  onChange={(e) => this.changeExcludeExternal(e.target.checked)}
                  checked={filterDto.excludeExternal}
                >
                  {t('excludeExternal')}
                </Checkbox>
              </Form.Item>
            )}
            {!userIsInRoleDistributor && !userIsInRoleSupplier && !userIsDcribCustomer && (
              <Form.Item label={HARD_SPACE}>
                <Checkbox
                  onChange={(e) => this.setFilterValue('includeUnconfirmedOnly', e.target.checked)}
                  checked={filterDto.includeUnconfirmedOnly}
                >
                  {t('showUnconfirmed')}
                </Checkbox>
              </Form.Item>
            )}
            <Form.Item label={HARD_SPACE}>
              <Checkbox
                onChange={(e) => this.setFilterValue('expeditedOnly', e.target.checked)}
                checked={filterDto.expeditedOnly}
              >
                {t('expeditedOnly')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
