import * as React from 'react'
import { Form } from 'antd'
import { range, defaultTo } from 'lodash'
import approvalStatusOptions from 'options/jobs/approvalStatus'
import overdueStatusOptions from 'options/jobs/overdueStatus'
import { showError } from 'helpers/errors'
import { createDropdownRender, createSearchAssets, createSearchOperators } from 'helpers/formViews'
import { HARD_SPACE } from 'helpers/utils'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { dateRangeFields } from 'options/jobs/dateRangeFields'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import Checkbox from 'elements/Checkbox'
import { Row, Col } from 'elements/Grid'

const completionStatusOptions = {
  All: 'all',
  Active: 'open',
  Inactive: 'closed',
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchAssets = createSearchAssets(this)
    this.searchOperators = createSearchOperators(this)
  }

  componentDidMount() {
    const { locationGroupIds, locationIds, assetCategoryIds, assetIds, assignedToId } = this.props.filterDto

    Promise.all([
      this.props.getLocationGroups(),
      this.props.getLocations({ locationGroupIds, locationIds }),
      this.props.getJobGroups(),
      this.props.getAssetCategories(),
      this.props.getJobReasons(),
      this.props.getJobStatus(),
      this.props.getAssets({ assetCategoryIds, assetIds }),
      this.props.getOperators(assignedToId ? { operatorIds: [assignedToId] } : undefined),
      this.props.getUsers(),
    ]).catch((error) => showError({ error }))
  }

  render() {
    const { filterDto, assets, operators, enableAssetManagement } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            {enableAssetManagement && (
              <DateRangePicker
                filterDto={filterDto}
                setFilterValue={this.setFilterValue}
                dateRangeFields={{ All: 'all', ...dateRangeFields }}
                extraDateRangeTypes={filterDto.dateRangeField === 'DueDate' ? { NextNDays: 'nextNDays' } : {}}
              />
            )}
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                <Option value={0}>{t('none')}</Option>
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('jobGroup')}>
              <Select
                onChange={(value) => this.setFilterValue('jobGroupIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.jobGroupIds}
              >
                {(this.props.jobGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('reason')}>
              <Select
                onChange={(value) => this.setFilterValue('jobReasonIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.jobReasonIds}
              >
                {(this.props.jobReasons ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {enableAssetManagement && (
              <>
                <Form.Item label={t('assetCategory')}>
                  <Select
                    onChange={(value) => this.setFilterValue('assetCategoryIds', value)}
                    mode="multiple"
                    placeholder={t('all')}
                    value={filterDto.assetCategoryIds}
                  >
                    {(this.props.assetCategories ?? []).map((each) => (
                      <Option key={each.id} value={each.id}>
                        <span {...getOptionProps(each)}>{each.displayName}</span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={t('asset')}>
                  <Select
                    onChange={(values) => this.setFilterValue('assetIds', values)}
                    value={filterDto.assetIds}
                    dropdownRender={createDropdownRender(assets)}
                    onSearch={this.searchAssets}
                    placeholder={t('all')}
                    mode="multiple"
                    showSearch
                  >
                    {(assets?.items ?? []).map((each) => (
                      <Option key={each.id} value={each.id}>
                        <span {...getOptionProps(each)}>{each.displayName}</span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
          </Col>
          <Col fourth>
            <Form.Item label={t('createdBy')}>
              <Select
                onChange={(value) => this.setFilterValue('createdBy', value)}
                value={filterDto.createdBy}
                placeholder={t('all')}
                showSearch
              >
                {(this.props.users ?? []).map((each) => (
                  <Option key={each.userName} value={each.userName}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {enableAssetManagement && (
              <Form.Item label={t('assignedTo')}>
                <Select
                  onChange={(value) => this.setFilterValue('assignedToId', defaultTo(value, -1))}
                  value={defaultTo(filterDto.assignedToId, -1)}
                  dropdownRender={createDropdownRender(operators)}
                  onSearch={this.searchOperators}
                  placeholder={t('all')}
                  allowClear
                  showSearch
                >
                  <Option value={-1}>{t('all')}</Option>
                  <Option value={0}>{t('none')}</Option>
                  {(operators?.items ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item label={t('completionStatus')}>
              <Select
                onChange={(value) => this.setFilterValue('active', value || 'Active')}
                value={filterDto.active ?? 'Active'}
              >
                {Object.entries(completionStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) => this.setFilterValue('jobStatusOptionId', defaultTo(value, -1))}
                value={defaultTo(filterDto.jobStatusOptionId, -1)}
                placeholder={t('all')}
                allowClear
              >
                <Option value={-1}>{t('all')}</Option>
                {(this.props.jobStatusOptions ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            {enableAssetManagement && (
              <Form.Item label={t('approvalStatus')}>
                <Select
                  onChange={(value) => this.setFilterValue('approvalStatus', value)}
                  value={filterDto.approvalStatus}
                  placeholder={t('all')}
                >
                  {Object.entries(approvalStatusOptions).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {t(value)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item label={t('supervisor')}>
              <Select
                onChange={(value) => this.setFilterValue('supervisors', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.supervisors}
                showSearch
              >
                {(this.props.users ?? []).map((each) => (
                  <Option key={each.userName} value={each.userName}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Row>
              <Col xs={12}>
                <Form.Item label={t('overdue')}>
                  <Select
                    onChange={(value) => this.setFilterValue('overdueStatus', value)}
                    value={filterDto.overdueStatus}
                    placeholder={t('all')}
                  >
                    {Object.entries(overdueStatusOptions).map(([key, value]) => (
                      <Option key={key} value={key}>
                        {t(value)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label={t('priority')}>
                  <Select
                    onChange={(value) => this.setFilterValue('priority', value)}
                    value={filterDto.priority}
                    placeholder={t('all')}
                    allowClear
                  >
                    {range(1, 7).map((each) => (
                      <Option key={each} value={each}>
                        {each}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {enableAssetManagement && (
              <>
                <Form.Item label={HARD_SPACE}>
                  <Row>
                    <Col xs={10}>
                      <Checkbox
                        onChange={(e) => this.setFilterValue('critical', e.target.checked)}
                        checked={filterDto.critical}
                      >
                        {t('critical')}
                      </Checkbox>
                    </Col>
                    <Col xs={14}>
                      <Checkbox
                        onChange={(e) => this.setFilterValue('trackDowntime', e.target.checked)}
                        checked={filterDto.trackDowntime}
                      >
                        {t('trackDowntime')}
                      </Checkbox>
                    </Col>
                  </Row>
                </Form.Item>
              </>
            )}
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
