import produce from 'immer'
import { snakeCase, isEmpty, unset, set } from 'lodash'
import { createActions, normalizeDateRange } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { createPost } from 'helpers/api'

const entityName = entityNames.jobs
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    useSelectionList: true,
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.locationVendingTypes = ['NonVending']
        draft.approvalStatus = draft.approvalStatus ?? 'All'
        draft.overdueStatus = draft.overdueStatus ?? 'All'
        draft.isFuture = draft.isFuture ?? false
        draft.alwaysIncludeJobIds = draft.alwaysIncludeJobIds === false ? false : !isEmpty(draft.jobIds)
        draft.active = draft.active ?? 'Active'
        draft.assignedToId = draft.assignedToId ?? -1

        normalizeDateRange(draft)
      }),
    transformGetCalendarItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.pageIndex = 0
        draft.locationVendingTypes = ['NonVending']
        draft.approvalStatus = draft.approvalStatus ?? 'All'
        draft.overdueStatus = draft.overdueStatus ?? 'All'
        draft.isFuture = draft.isFuture ?? false
        draft.alwaysIncludeJobIds = draft.alwaysIncludeJobIds === false ? false : !isEmpty(draft.jobIds)
        draft.active = draft.active ?? 'Active'
        draft.assignedToId = draft.assignedToId ?? -1
        draft.jobsToInclude = draft.jobsToInclude ?? 'CreatedJobsOnly'
        draft.operatorCapacityBasedOn = draft.operatorCapacityBasedOn ?? 'DueDateOnly'
        draft.dateRangeField = draft.dateRangeField ?? 'DueDate'

        set(draft, 'dateRange.dateRangeType', 'Custom')

        if (draft.jobsToInclude === 'ScheduledJobsOnly') {
          draft.active = 'Active'
          draft.jobStatusOptionId = -1
          draft.approvalStatus = 'All'
          draft.overdueStatus = 'All'

          if (draft.dateRangeField === 'CompletionDate') {
            draft.dateRangeField = 'DueDate'
          }
        }

        if (isEmpty(draft.search)) {
          unset(draft, 'searchType')
          unset(draft, 'searchFields')
        }
      }),
  }),

  requestApprovalToBegin: (jobId) => ({
    type: `${ENTITY_NAME}_REQUEST_BEGIN_APPROVAL`,
    payload: createPost(entityName, { action: `${jobId}/approvalRequest?type=RequestToBegin` })(),
  }),

  requestApprovalToClose: (jobId) => ({
    type: `${ENTITY_NAME}_REQUEST_CLOSE_APPROVAL`,
    payload: createPost(entityName, { action: `${jobId}/approvalRequest?type=RequestToClose` })(),
  }),

  generateId: (params) => ({
    type: `${ENTITY_NAME}_GENERATE_ID`,
    payload: createPost(entityName, { action: '?generateId=true' })(params),
  }),

  createFromTemplate: (jobId, params = {}) => ({
    type: `${ENTITY_NAME}_CREATE_FROM_TEMPLATE`,
    payload: createPost(entityName, { action: `${jobId}/createFromTemplate` })(params),
  }),

  emailJob: (jobId, params = []) => ({
    type: `${ENTITY_NAME}_EMAIL`,
    payload: createPost(entityName, { action: `${jobId}/email` })(params),
  }),
}
