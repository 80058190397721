import produce from 'immer'
import { snakeCase, set } from 'lodash'
import { combineReducers } from 'redux'
import auth from 'reducers/auth'
import i18n from 'reducers/i18n'
import entityNames from 'options/entityNames'

const createReducer =
  (entityName) =>
  (state = {}, action) =>
    produce(state, (draft) => {
      const ENTITY_NAME = snakeCase(entityName).toUpperCase()

      switch (action?.type) {
        case `${ENTITY_NAME}_LIST_VIEW_ITEMS_GET_PENDING`:
          draft.loading = true
          set(draft, 'pagination.current', action?.meta?.pageIndex ?? 0)
          set(draft, 'pagination.pageSize', action?.meta?.pageSize ?? 20)
          break

        case `${ENTITY_NAME}_LIST_VIEW_ITEMS_GET_FULFILLED`:
          draft.loading = false
          draft.list = action?.payload?.data ?? { items: [], recordCount: 0 }
          set(draft, 'pagination.total', action?.payload?.data?.recordCount ?? 0)
          break

        case `${ENTITY_NAME}_LIST_VIEW_ITEMS_GET_REJECTED`:
          draft.loading = false
          break

        case `${ENTITY_NAME}_OPTIONS_GET_FULFILLED`:
          draft.options = action?.payload?.data ?? { items: [], recordCount: 0 }
          break

        case `${ENTITY_NAME}_CALENDAR_VIEW_ITEMS_GET_PENDING`:
          draft.loading = true
          break

        case `${ENTITY_NAME}_CALENDAR_VIEW_ITEMS_GET_FULFILLED`:
          draft.loading = false
          draft.calendar = action?.payload?.data ?? { items: [], recordCount: 0 }
          break

        case `${ENTITY_NAME}_CALENDAR_VIEW_ITEMS_GET_REJECTED`:
          draft.loading = false
          break

        default:
          break
      }
    })

export default combineReducers({
  ...Object.entries(entityNames).reduce((acc, [key, value]) => ({ ...acc, [key]: createReducer(value) }), {}),
  auth,
  i18n,
})
