import produce from 'immer'
import cx from 'clsx'
import { Button, Tooltip, Input } from 'antd'
import { cloneDeep, isEmpty } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { showError, showClientNotifications } from 'helpers/errors'
import { t } from 'helpers/i18n'
import { RED } from 'options/colors'
import Icon from 'elements/Icon'
import InputNumber from 'elements/InputNumber'
import Filter from 'containers/ReturnToInventory/Filter'

export default createListViewComponent({
  entityName: entityNames.returnToInventory,
  allowSelection: (self) => false,
  allowCreate: (self) => false,
  getSettingsType: (self) => 'returnItems',
  tableCellComponents: {
    quantityReturned: (self, item) =>
      item.lockedForCycleCount ? (
        <Input
          value={item.quantityReturned}
          suffix={
            <Tooltip title={t('onhandQuantitiesLockedForCycleCount')} placement="topLeft">
              <Icon type="Lock" color={RED} />
            </Tooltip>
          }
          disabled
        />
      ) : (
        <InputNumber
          className={cx({
            'form-field-highlight':
              self.props.items.find((one) => one.id === item.id)?.quantityReturned !==
              self.state.items.find((one) => one.id === item.id)?.quantityReturned,
          })}
          value={item.quantityReturned}
          onChange={(value) =>
            self.setState(
              produce((draft) => {
                draft.items.find((one) => one.id === item.id).quantityReturned = value
              })
            )
          }
          max={item.quantityIssued}
          min={0}
        />
      ),
  },
  extraTableButtons: (self) => {
    if (isEmpty(self.state.items)) {
      return null
    }

    return (
      <>
        <Tooltip title={t('unsavedChangesWillBeLost')}>
          <Button
            className="mr-6"
            onClick={() => self.setState({ items: cloneDeep(self.props.items) })}
            disabled={!self.hasUnsavedChanges()}
          >
            {t('cancel')}
          </Button>
        </Tooltip>
        <Button
          type="primary"
          onClick={async () => {
            try {
              self.setState({ saveButtonLoading: true })

              const response = await self.props.returnItems(
                self.state.items
                  .filter((each) => each.quantityReturned)
                  .map((each) => ({
                    orderItemId: each.id,
                    quantityToReturn: each?.quantityReturned ?? 0,
                  }))
              )

              showClientNotifications({ response })

              self.setState({ saveButtonLoading: false }, self.fetchItems)
            } catch (error) {
              showError({ error })
            } finally {
              self.setState({ saveButtonLoading: false })
            }
          }}
          loading={self.state.saveButtonLoading}
          disabled={!self.hasUnsavedChanges()}
        >
          {t('save')}
        </Button>
      </>
    )
  },
})(Filter)
