import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import Filter from 'containers/Reports/AssetRentalReport/Filter'

export default createListViewComponent({
  entityName: entityNames.assetRentalReport,
  filterTemplateType: 'AssetRentalReport',
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  tableSummary: (self) => `${t('totalDuration:')} ${self.props.list?.totalRentalDuration ?? '0:0:0'}`,
})(Filter)
