import { isNil, camelCase, isEmpty, invoke } from 'lodash'
import { RED } from 'options/colors'
import { sortByKeys } from 'helpers/utils'
import {
  getVisibleColumnKeys,
  createSorter,
  formatValue,
  MINIMUM_COLUMN_WIDTH,
  renderBarcode,
  isValidLinkTarget,
  renderEmail,
  renderTel,
} from 'helpers/listViews'
import { t } from 'helpers/i18n'

export const getStatus = (requisition) => {
  if (isNil(requisition)) {
    return {}
  }

  const { status, approved } = requisition

  if (status === 'Draft') {
    return {
      statusLanguageKey: 'draftStatusCannotIssue',
      statusClassName: 'color-red',
      statusStyle: { color: RED },
    }
  }

  if (['Open', 'Closed'].includes(status) && !approved) {
    return {
      statusLanguageKey: 'pendingApprovalStatusCannotIssue',
      statusClassName: 'color-red',
      statusStyle: { color: RED },
    }
  }

  if (status === 'Cancelled') {
    return {
      statusLanguageKey: 'cancelledStatusCannotIssue',
      statusClassName: 'color-red',
      statusStyle: { color: RED },
    }
  }

  return {
    statusLanguageKey: camelCase(status),
    statusStyle: { color: 'rgba(0, 0, 0, 0.65)' },
  }
}

export const getColumns = ({
  self,
  fieldSettings,
  sortBy,
  sortOrder,
  onClick,
  onResize,
  columnWidths = {},
  tableCellComponents = {},
  selectedColumnKeys = [],
  sortedColumnKeys = [],
  allowSorting = true,
  primaryLinkTarget = '',
}) => {
  const visibleColumnKeys = isEmpty(selectedColumnKeys)
    ? getVisibleColumnKeys(fieldSettings)
    : selectedColumnKeys

  const unsortedColumns = visibleColumnKeys.flatMap((key) =>
    fieldSettings
      .filter((one) => one.dtoFieldName === key)
      .map((column) => {
        const {
          dtoFieldName,
          columnWidth,
          columnHeadingLanguageKey,
          alignment,
          linkTarget,
          columnHeadingTooltipLanguageKey,
          columnHeadingIconName,
          displayFormat,
          recordLabelLanguageKey,
        } = column

        return {
          key: dtoFieldName,
          dataIndex: dtoFieldName,
          sorter: allowSorting ? createSorter(dtoFieldName) : null,
          sortOrder: allowSorting && dtoFieldName === sortBy ? sortOrder : null,
          width: columnWidths[dtoFieldName]
            ? Math.max(columnWidths[dtoFieldName], MINIMUM_COLUMN_WIDTH)
            : columnWidth
              ? Math.max(columnWidth, MINIMUM_COLUMN_WIDTH)
              : MINIMUM_COLUMN_WIDTH,
          title: t(columnHeadingLanguageKey || recordLabelLanguageKey || dtoFieldName),
          align: columnHeadingIconName ? 'center' : alignment || 'left',
          render: (value, record, index) => {
            const text = formatValue({
              value,
              displayFormat,
              dtoFieldName,
            })

            if (displayFormat.startsWith('Barcode')) {
              return renderBarcode({ displayFormat, value })
            }

            if (tableCellComponents[dtoFieldName]) {
              return invoke(tableCellComponents, dtoFieldName, self, record, column, text)
            }

            if (
              onClick && // NOTE: Modal select lists must not render linkTargets
              isValidLinkTarget({ linkTarget, text, primaryLinkTarget })
            ) {
              return (
                <a
                  onClick={() => {
                    onClick({
                      record,
                      column,
                      text,
                      index,
                      readOnly: linkTarget !== primaryLinkTarget,
                    })
                  }}
                >
                  {text}
                </a>
              )
            } else if (displayFormat === 'Tel') {
              return renderTel(text)
            } else if (displayFormat === 'Email') {
              return renderEmail(text)
            }

            return text
          },
          onHeaderCell: (header) => ({
            columnHeadingTooltipLanguageKey,
            columnHeadingIconName,
            width: header.width,
            onResize: onResize(header),
          }),
        }
      })
  )

  const sortedColumns = sortByKeys(unsortedColumns, sortedColumnKeys)

  return sortedColumns
}

export const calculateUnissuedQuantity = ({ barcode, customer, childItems, locationId }) => {
  const filteredChildItems = childItems.filter(
    (one) => one.barcode === barcode && one.locationId === locationId
  )
  const totals = filteredChildItems.reduce(
    (acc, each) => ({
      quantity: acc.quantity + each.quantity,
      quantityIssued: acc.quantityIssued + each.quantityIssued,
    }),
    { quantity: 0, quantityIssued: 0 }
  )

  return isEmpty(filteredChildItems)
    ? null
    : Math.max(
        totals.quantity - totals.quantityIssued,
        customer.generalSettings.allowOverIssueFromRequisition ? 1 : 0
      )
}
