import * as React from 'react'
import styled from 'styled-components'
import cx from 'clsx'
import { Spin, Button, Tooltip, Input, Table } from 'antd'
import { get, isNil, isArray, isEmpty, toString as str, toLower, isFunction, pick, debounce } from 'lodash'
import produce from 'immer'
import { saveAs } from 'file-saver'
import { createResetState, sortItems } from 'helpers/utils'
import { showError } from 'helpers/errors'
import {
  getChildColumns,
  getTableWidth,
  createHandleCustomizeOk,
  pageSizeOptions,
  createHandleColumnResize,
  createChildListViewHandleTableChange,
  TOTALS_ROW_ID,
  getChildPageTotals,
  mapGetItemsFields,
} from 'helpers/listViews'
import { getKeyValueItem, setKeyValueItem } from 'helpers/keyValueStorage'
import linkTargets from 'options/linkTargets'
import { Emitter } from 'helpers/events'
import { t } from 'helpers/i18n'
import { isReadOnly } from 'helpers/formViews'
import TableRowActions from 'elements/TableRowActions'
import ResizableCell from 'elements/ResizableCell'
import Customize from 'elements/Customize'
import Drawer from 'elements/Drawer'
import Icon from 'elements/Icon'
import FilterContainer from 'elements/FilterContainer'
import { Row, Col } from 'elements/Grid'
import UnderDevelopment from 'elements/UnderDevelopment'

const SearchContainer = styled(Col)`
  text-align: center;

  .ant-input-search {
    width: 200px;

    .ant-input {
      height: 28px;
    }
  }
`

export default ({
    entityName,
    childName,
    getStorageKey = (self) =>
      self.props.storageKey
        ? self.props.storageKey
        : entityName && childName
          ? [entityName, 'formView', childName, self.props.settingsType].filter(Boolean).join('.')
          : '__CHILD_LIST_VIEW__',
    getIdField = (self) => 'id',
    createButtonTextId = 'create',
    createButtonLinkTarget, // { formComponent, formSize } or memoize((self) => ({ formComponent, formSize }), self => {})
    primaryLinkTarget, // NOTE: everything else will be read-only, except for secondaryLinkTargets
    secondaryLinkTargets = [],
    secondaryLinkTargetsRecipeFactories = {
      linkTarget: (self, record) => (draft) => {},
    },
    extraRowActions,
    extraTableButtons,
    sharedComponents,
    tableSummary,
    extraTableSummary,
    allowSelection = (self) => true,
    allowDelete = (self) => true,
    deleteDisabled = (self) => false,
    allowCreate = (self) => true,
    createDisabled = (self) => false,
    allowPagination = (self) => true,
    allowSorting = (self) => true,
    tableCellComponents = {},
    totalsRowComponents = {},
    tableHeadGroups = (self) => ({
      // dtoFieldName: () => []
    }),
    getRowClassName = (self) => (record, index) => '',
    allowCustomize = (self) => false,
    initialFilterDto = (self) => ({ ...self.props.initialFilterDto }),
    initialState = (self) => ({ ...self.props.initialState }),
    filterItems = (self) => (each) => true,
    allowSearching = (self) => false,
    getDeleteButtonTooltip = (self) => null,
    getRowSelectionCheckboxProps = (self) => (record) => ({
      // disabled
    }),
  }) =>
  (Filter) => {
    if (isEmpty(entityName)) {
      throw new Error('entityName is empty')
    }

    if (isEmpty(childName)) {
      throw new Error('childName is empty')
    }

    return class ChildListView extends React.Component {
      state = { pageIndex: 1 }

      constructor(props) {
        super(props)

        this.printableRef = React.createRef()
        this.handleCustomizeOk = createHandleCustomizeOk(this, true)
        this.handleColumnResize = createHandleColumnResize(this)
        this.handleTableChange = createChildListViewHandleTableChange(this)
        this.resetState = createResetState(this, {
          selectedRowKeys: [],
          columnWidths: {},
          createDrawerVisible: false,
          newItemParams: null,
          editDrawerVisible: false,
          fieldSettings: [],
          customizeColumnsVisible: false,
          selectedColumnKeys: [],
          sortedColumnKeys: [],
          showFilter: true,
          pageSize: allowPagination(this) ? 20 : undefined,
          filterDto: {
            ...initialFilterDto(this),
            ...this.props.initialFilterDto,
          },
          ...initialState(this),
        })
        this.saveState = debounce(this.saveState, 2500)
      }

      async componentDidMount() {
        const savedState = await getKeyValueItem(getStorageKey(this), {})
        await this.resetState(savedState)
        await this.fetchSettings()

        Emitter.on(`${entityName}.${childName}.createButtonClick`, this.handleCreateButtonClick)
        Emitter.on(`${entityName}.${childName}.downloadItems`, this.downloadItems)
      }

      componentWillUnmount() {
        this.saveState.flush()
        Emitter.off(`${entityName}.${childName}.createButtonClick`, this.handleCreateButtonClick)
        Emitter.off(`${entityName}.${childName}.downloadItems`, this.downloadItems)
      }

      fetchSettings = async () => {
        try {
          const response = await this.props.getSettings({ type: this.props.settingsType })

          this.setState({ fieldSettings: response.value.data.fieldSettings })
        } catch (error) {
          showError({ error })
        }
      }

      promiseState = (state = {}) => new Promise((resolve) => this.setState(state, resolve))

      saveState = () =>
        setKeyValueItem(
          getStorageKey(this),
          pick(this.state, [
            'columnWidths',
            'showFilter',
            'pageSize',
            'selectedColumnKeys',
            'sortBy',
            'sortOrder',
            'sortedColumnKeys',
          ])
        )

      handleFilterChange = (filterDto) =>
        this.setState({ filterDto, selectedRowKeys: [], pageIndex: 1 }, () => {
          this.props.onFilter?.(filterDto)
          this.props.onSelect?.([], filterDto)
          this.saveState()
        })

      handleCreateButtonClick = (newItemParams) =>
        this.setState({ createDrawerVisible: true, createDrawerSaving: false, newItemParams })

      handleLinkTargetClick = ({ column, record, text, readOnly }) => {
        if (process.env.NODE_ENV === 'development') console.log({ column, record, text, readOnly })

        const editDrawerLinkTarget = linkTargets[column.linkTarget]
        const { drawerTitleLanguageKey } = editDrawerLinkTarget ?? {}

        this.setState({
          editDrawerLinkTarget,
          editDrawerVisible: true,
          editDrawerSaving: false,
          editDrawerLinkTargetRecord: record,
          editDrawerLinkTargetColumn: column,
          editDrawerLinkTargetReadOnly: readOnly || column.linkTargetIsReadOnly,
          editDrawerLinkTargetSecondary: secondaryLinkTargets.includes(column.linkTarget),
          editDrawerLinkTargetText: drawerTitleLanguageKey ? `${t(drawerTitleLanguageKey)} - ${text}` : text,
        })
      }

      handleDrawerClose = () =>
        this.setState({
          createDrawerVisible: false,
          editDrawerVisible: false,
          newItemParams: null,
        })

      handleDrawerSaveAndClose = async (item) => {
        if (!item) {
          throw new Error('item is undefined')
        }

        try {
          this.setState({ createDrawerSaving: true, editDrawerSaving: true })

          const savedItems = isArray(item) ? item : [item]
          const items = this.props.items ?? []

          const changedItems = produce(items, (draft) => {
            const seed = Date.now() * -1
            savedItems.forEach((each, index) => {
              if (each.id) {
                // Edit using the existing id
                const existing = draft.findIndex((one) => one.id === each.id)

                if (existing > -1) {
                  draft[existing] = each
                } else {
                  draft.push(each)
                }
              } else {
                // Create with a negative id
                const id = seed - index

                draft.push({ ...each, id })
              }
            })
          })

          await this.props.onChange?.(changedItems)

          this.setState({
            createDrawerVisible: false,
            editDrawerVisible: false,
          })
        } catch (error) {
          showError({ error })
        } finally {
          this.setState({ createDrawerSaving: false, editDrawerSaving: false })
        }
      }

      handleSecondaryDrawerSave = async (pending, secondaryItem) => {
        this.setState({ createDrawerSaving: pending, editDrawerSaving: pending })

        if (pending) {
          return
        }

        if (isNil(secondaryItem)) {
          console.warn('secondary item is undefined')
          return
        }

        try {
          this.setState({ createDrawerSaving: true, editDrawerSaving: true })

          const createRecipe = get(
            secondaryLinkTargetsRecipeFactories,
            this.state.editDrawerLinkTargetColumn.linkTarget,
            (self, record) => (draft) => {}
          )

          const changedItems = produce(this.props.items, createRecipe(this, secondaryItem))

          await this.props.onChange?.(changedItems)
        } catch (error) {
          showError({ error })
        } finally {
          this.setState({ createDrawerSaving: false, editDrawerSaving: false })
        }
      }

      handleConfirmDelete = async () => {
        try {
          const changedItems = (this.props.items ?? []).filter(
            (each) => !(this.state.selectedRowKeys ?? []).includes(each.id)
          )
          await this.props.onChange?.(changedItems)
          await this.promiseState({ selectedRowKeys: [] })
          await this.props.onSelect?.([], this.state.filterDto)
          this.saveState()
        } catch (error) {
          showError({ error })
        }
      }

      handleToggleFilterClick = () =>
        this.setState((prev) => ({ showFilter: !prev.showFilter }), this.saveState)

      searchItems =
        (search = '') =>
        (item = {}) =>
          Object.values(item).map(str).map(toLower).join(' ').indexOf(toLower(search)) > -1

      downloadItems = async ({
        parentId = 0,
        acceptMimeType = 'text/csv',
        pageTitle = 'export',
        fileExtension = 'csv',
        ...rest
      }) => {
        try {
          const { sortedColumnKeys, selectedColumnKeys } = this.state
          const response = await this.props.downloadItems(parentId, {
            ...this.state.filterDto,
            ...pick(this.state, ['search', 'searchType']),
            acceptMimeType,
            pageTitle,
            ...mapGetItemsFields({
              ...pick(this.state, ['sortBy', 'sortOrder', 'fieldSettings', 'searchFields']),
              selectedColumnKeys: sortedColumnKeys.filter((each) => selectedColumnKeys.includes(each)),
            }),
            ...rest,
          })

          saveAs(response.value.data, `${pageTitle}.${fileExtension}`)
        } catch (error) {
          showError({ error })
        }
      }

      render() {
        const { items = [], pageTotals = {}, parentRecord, rootRecord, savingIsDeferred = true } = this.props
        const { showFilter = true, search = '', filterDto = {}, fieldSettings = [] } = this.state

        if (isEmpty(fieldSettings)) {
          return <Spin />
        }

        const columns = getChildColumns({
          self: this,
          primaryLinkTarget,
          secondaryLinkTargets,
          fieldSettings,
          pageTotals,
          tableCellComponents,
          totalsRowComponents,
          tableHeadGroups,
          onClick: this.handleLinkTargetClick,
          onResize: this.handleColumnResize,
          allowSorting: allowSorting(this),
          selectedColumnKeys: allowCustomize(this) ? this.state.selectedColumnKeys : [],
          ...pick(this.state, ['columnWidths', 'sortBy', 'sortedColumnKeys', 'sortOrder']),
        })
        const width = isEmpty(columns) ? '100%' : getTableWidth(columns, allowSelection(this))
        const CreateFormView = get(
          isFunction(createButtonLinkTarget) ? createButtonLinkTarget(this) : createButtonLinkTarget,
          'formComponent',
          UnderDevelopment
        )
        const EditFormView = get(this.state, 'editDrawerLinkTarget.formComponent', UnderDevelopment)
        const loading = this.props.loading || this.state.loading
        const readOnly = isReadOnly(this)
        const filteredItems = items.filter(filterItems(this)).filter(this.searchItems(search))
        this.dataSource = [...sortItems(filteredItems, this.state.sortBy, this.state.sortOrder)]

        const childPageTotals = getChildPageTotals({
          columns,
          dataSource: this.dataSource,
          pageTotals,
          fieldSettings,
        })

        if (childPageTotals) {
          const seed = -1111111111111
          for (let page = 1; page <= this.state.pageIndex; page++) {
            this.dataSource.splice(page * this.state.pageSize - 1, 0, {
              ...childPageTotals,
              id: page === this.state.pageIndex ? childPageTotals.id : seed - page,
            })
          }
        }

        const showTotal = (total, range) => {
          if (childPageTotals) {
            const visibleRange =
              total - this.state.pageIndex > 0
                ? `${range[0] - this.state.pageIndex + 1}-${range[1] - this.state.pageIndex}`
                : 0
            const visibleTotal = total - this.state.pageIndex
            return `${t('showing')} ${visibleRange} ${t('of')} ${visibleTotal} ${t('items')}`
          }

          return `${t('showing')} ${total > 0 ? `${range[0]}-${range[1]}` : 0} ${t('of')} ${total} ${t(
            'items'
          )}`
        }

        // console.log({ items, fieldSettings })

        return (
          <>
            <div
              className={cx('tofino-child-list-view', {
                'tofino-table-not-empty': !isEmpty(this.dataSource),
              })}
            >
              <div className="tofino-inner-container">
                {!isNil(Filter) && this.state.showFilter && (
                  <FilterContainer>
                    <Filter
                      filterDto={filterDto}
                      onChange={this.handleFilterChange}
                      parentRecord={parentRecord}
                      items={items}
                      {...this.props.filterProps}
                    />
                  </FilterContainer>
                )}
                <Row type="flex" justify="space-between" className="mb-12">
                  <Col className="text-left">
                    {allowSelection(this) && (
                      <TableRowActions
                        selectedRowKeys={this.state.selectedRowKeys}
                        extraActions={extraRowActions?.(this)}
                        // delete
                        deleteTooltip={getDeleteButtonTooltip(this)}
                        allowDelete={!readOnly && allowDelete(this)}
                        deleteDisabled={deleteDisabled(this)}
                        onDelete={this.handleConfirmDelete}
                      />
                    )}
                  </Col>
                  {allowSearching(this) && (
                    <SearchContainer>
                      <Input.Search
                        placeholder={t('search')}
                        defaultValue={this.state.search}
                        onChange={(e) =>
                          this.setState({ search: e.target.value, pageIndex: 1, selectedRowKeys: [] }, () => {
                            this.props.onSelect?.([], this.state.filterDto)
                          })
                        }
                        maxLength={200}
                        allowClear
                      />
                    </SearchContainer>
                  )}
                  <Col className="text-right">
                    {extraTableSummary && extraTableSummary(this)}
                    {!isNil(Filter) && (
                      <Button
                        style={{ fontSize: '13px', padding: '0 5px' }}
                        type="link"
                        size="small"
                        onClick={this.handleToggleFilterClick}
                      >
                        {t(showFilter ? 'hideFilter' : 'showFilter')}
                      </Button>
                    )}
                    {allowCustomize(this) && (
                      <Tooltip title={t('customizeColumns')} placement="topRight">
                        <Button size="small" onClick={() => this.setState({ customizeColumnsVisible: true })}>
                          <Icon type="ViewColumn" />
                        </Button>
                      </Tooltip>
                    )}{' '}
                    {extraTableButtons?.(this)}{' '}
                    {!readOnly && allowCreate(this) && (
                      <Tooltip title={t(createButtonTextId)} placement="topRight">
                        <Button
                          type="primary"
                          size="small"
                          onClick={this.handleCreateButtonClick}
                          disabled={createDisabled(this)}
                        >
                          <Icon type="Add" bold />
                        </Button>
                      </Tooltip>
                    )}
                  </Col>
                </Row>
                <div className="tofino-table-container">
                  <Table
                    childrenColumnName={[]}
                    rowKey={getIdField(this)}
                    loading={loading}
                    columns={columns}
                    dataSource={this.dataSource}
                    rowSelection={
                      allowSelection(this)
                        ? {
                            selectedRowKeys: this.state.selectedRowKeys,
                            onChange: (rowKeys) =>
                              this.setState({ selectedRowKeys: rowKeys }, this.saveState),
                            getCheckboxProps: (record) =>
                              record.id === TOTALS_ROW_ID
                                ? { disabled: true }
                                : getRowSelectionCheckboxProps(this)(record),
                          }
                        : undefined
                    }
                    rowClassName={(record, index) =>
                      cx(
                        { 'table-row-page-totals': record.id === TOTALS_ROW_ID },
                        getRowClassName(this)(record, index)
                      )
                    }
                    size="middle"
                    locale={{ emptyText: t('noData') }}
                    style={{ width }}
                    components={{ header: { cell: ResizableCell } }}
                    pagination={
                      allowPagination(this)
                        ? {
                            showTotal,
                            pageSizeOptions,
                            showSizeChanger: true,
                            pageSize: this.state.pageSize,
                          }
                        : false
                    }
                    onChange={this.handleTableChange}
                  />
                </div>
                {tableSummary && (
                  <div
                    className="text-right float-right w-half"
                    style={{ marginTop: isEmpty(items) ? '16px' : '-45px' }}
                  >
                    {tableSummary(this)}
                  </div>
                )}
                <Drawer
                  title={t(createButtonTextId)}
                  size={get(
                    isFunction(createButtonLinkTarget)
                      ? createButtonLinkTarget(this)
                      : createButtonLinkTarget,
                    'formSize'
                  )}
                  visible={this.state.createDrawerVisible}
                  onClose={this.handleDrawerClose}
                  saving={this.state.createDrawerSaving}
                >
                  <CreateFormView
                    onSave={() => {}}
                    onSaveAndClose={this.handleDrawerSaveAndClose}
                    onCancel={this.handleDrawerClose}
                    readOnly={readOnly}
                    parentRecord={parentRecord}
                    siblingRecords={items}
                    newItemParams={this.state.newItemParams}
                    savingIsDeferred={savingIsDeferred}
                  />
                </Drawer>
                <Drawer
                  title={this.state.editDrawerLinkTargetText}
                  size={this.state.editDrawerLinkTarget?.formSize}
                  visible={this.state.editDrawerVisible}
                  onClose={this.handleDrawerClose}
                  saving={this.state.editDrawerSaving}
                >
                  {this.state.editDrawerLinkTargetReadOnly ? (
                    <EditFormView
                      onCancel={this.handleDrawerClose}
                      linkTargetRecord={this.state.editDrawerLinkTargetRecord}
                      readOnly
                    />
                  ) : this.state.editDrawerLinkTargetSecondary ? (
                    <EditFormView
                      onSave={this.handleSecondaryDrawerSave}
                      onSaveAndClose={this.handleDrawerClose}
                      onCancel={this.handleDrawerClose}
                      linkTargetRecord={this.state.editDrawerLinkTargetRecord}
                      readOnly={readOnly}
                    />
                  ) : (
                    <EditFormView
                      onSave={() => {}}
                      onSaveAndClose={this.handleDrawerSaveAndClose}
                      onCancel={this.handleDrawerClose}
                      rootRecord={rootRecord}
                      parentRecord={parentRecord}
                      siblingRecords={items}
                      linkTargetRecord={this.state.editDrawerLinkTargetRecord}
                      readOnly={readOnly}
                      savingIsDeferred={savingIsDeferred}
                    />
                  )}
                </Drawer>
              </div>
            </div>
            <Customize
              displayableColumns={fieldSettings.filter((each) => each.isDisplayable)}
              visible={this.state.customizeColumnsVisible}
              onOk={this.handleCustomizeOk}
              onCancel={() => this.setState({ customizeColumnsVisible: false })}
              selectedColumnKeys={this.state.selectedColumnKeys}
              sortedColumnKeys={this.state.sortedColumnKeys}
              columnWidths={this.state.columnWidths}
            />
            {sharedComponents?.(this)}
          </>
        )
      }
    }
  }
