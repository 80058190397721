import { createChildListPrintContainer } from 'factories/ChildListPrint'
import actions from 'actions/orders/orderSuppliers'
import Component from 'components/Orders/Print/Suppliers'

export default createChildListPrintContainer({
  actions,
  extraStateToProps: (state, props) => ({ settingsType: props.settingsType ?? 'order' }),
  extraDispatchToProps: (dispatch, props) => ({
    previewPurchaseOrder: (params) => dispatch(actions.previewPurchaseOrder(params)),
  }),
})(Component)
