import { snakeCase } from 'lodash'
import { createChildActions } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { createOrphanedChildPost } from 'helpers/api'

const entityName = entityNames.lists
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const childName = 'listItems'
const CHILD_NAME = snakeCase(childName).toUpperCase()

export default {
  ...createChildActions(entityName, childName),

  populateListItems: (params) => ({
    type: `${ENTITY_NAME}_${CHILD_NAME}_POPULATE`,
    payload: createOrphanedChildPost(entityName, childName, { action: 'populate' })(params),
  }),

  getCombinedListForEntity: (params) => ({
    type: `${ENTITY_NAME}_${CHILD_NAME}_GET_COMBINED_LIST_FOR_ENTITY`,
    payload: createOrphanedChildPost(entityName, childName, { action: 'combinedListForEntity' })(params),
  }),
}
