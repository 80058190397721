import { isEqual } from 'lodash'
import { createChildListViewComponent } from 'factories/ChildListView'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { allowPricing } from 'helpers/auth'

export default createChildListViewComponent({
  entityName: 'processSetupSheet',
  childName: 'associatedProducts',
  allowCreate: (self) => false,
  allowDelete: (self) => false,
  allowSelection: (self) => false,
  allowCustomize: (self) => true,
  allowSearching: (self) => true,
  extraTableSummary: (self) =>
    allowPricing() ? (
      <span
        style={{ marginRight: '5px' }}
      >{`${t('totalToolingCost:')} ${toLocaleCurrency(self.props.totalValue)}`}</span>
    ) : undefined,
  getRowClassName: (self) => (record) => {
    try {
      if (self.state.sortBy !== 'sequenceValue') {
        return undefined
      }

      const index = self.dataSource.findIndex((one) => isEqual(one, record))
      const [itemSeq] = self.dataSource[index].sequenceValue.split('.')
      const [nextSeq] = self.dataSource[index + 1].sequenceValue.split('.')

      return itemSeq !== nextSeq ? 'tofino-associated-products-sequence-separator' : undefined
    } catch (error) {
      return undefined
    }
  },
})()
