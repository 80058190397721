import produce from 'immer'
import { snakeCase, isEmpty } from 'lodash'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { createPost } from 'helpers/api'

const entityName = entityNames.formTemplates
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.alwaysIncludeFormTemplateIds =
          draft.alwaysIncludeFormTemplateIds === false ? false : !isEmpty(draft.formTemplateIds)
      }),
  }),

  migrateToTofinoCloud: (params) => ({
    type: `${ENTITY_NAME}_MIGRATE_TO_TOFINO_CLOUD`,
    payload: createPost(entityName, { action: 'migrate' })(params),
  }),

  updateStatus: (formTemplateId, name, value) => ({
    type: `${ENTITY_NAME}_UPDATE_STATUS`,
    payload: createPost(entityName, { action: `${formTemplateId}/update${name}?status=${value}` })(),
  }),
}
