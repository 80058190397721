import { createChildListViewComponent } from 'factories/ChildListView'

export default createChildListViewComponent({
  entityName: 'processSetupSheet',
  childName: 'associatedAssets',
  allowCreate: (self) => false,
  allowDelete: (self) => false,
  allowSelection: (self) => false,
  allowCustomize: (self) => true,
  allowSearching: (self) => true,
})()
