import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import listActions from 'actions/lists'
import listItemActions from 'actions/lists/listItems'
import Component from 'components/Lists/Items/Select'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  getSettings: listItemActions.getSettings,
  getLists: listActions.getItems,
  getItems: listItemActions.getChildItems,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
