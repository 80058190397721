import { Button } from 'antd'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import linkTargets from 'options/linkTargets'
import { t } from 'helpers/i18n'
import { createPrintHandler, resetPrintHandler } from 'helpers/print'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import Print from 'containers/Requisitions/Print'
import Filter from 'containers/Requisitions/Filter'

export default createListViewComponent({
  entityName: entityNames.requisitions,
  filterTemplateType: 'Requisition',
  createButtonTextId: 'createRequisition',
  primaryLinkTarget: 'requisitionRecord',
  allowCreate: (self) => !self.props.userIsInRoleSupplier && !self.props.userIsInRoleDistributor,
  allowDelete: (self) => !self.props.userIsInRoleSupplier && !self.props.userIsInRoleDistributor,
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
  extraRowActions: (self) => (
    <>
      <Button disabled={self.state.selectedRowKeys.length !== 1} onClick={() => self.setState({ printVisible: true })}>
        <Icon type="print" />
        {t('print')}
      </Button>
      <Modal
        title={t('printPreview')}
        okText={t('print')}
        onOk={createPrintHandler(self.printableRef)}
        onCancel={() => self.setState({ printVisible: false }, resetPrintHandler)}
        width={992}
        visible={self.state.printVisible}
      >
        <div ref={self.printableRef} className="tofino-print-preview">
          {self.state.items
            .filter((each) => self.state.selectedRowKeys.includes(each.id))
            .map((item) => (
              <div key={item.id} className="mb-24">
                <Print
                  parentRecord={{
                    ...item,
                  }}
                />
              </div>
            ))}
        </div>
      </Modal>
    </>
  ),
  tableCellComponents: {
    relatedOrderId: (self, item, column, text) =>
      item.relatedOrderId ? (
        <a
          onClick={() =>
            self.setState({
              editDrawerLinkTarget: linkTargets.orderRecord,
              editDrawerLinkTargetText: `${t('order')} - ${text}`,
              editDrawerLinkTargetRecord: item,
              editDrawerVisible: true,
              editDrawerSaving: false,
            })
          }
        >
          {text}
        </a>
      ) : null,
  },
})(Filter)
