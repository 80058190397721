import { createChildListModalComponent } from 'factories/ChildListModal'
import Filter from 'containers/DashboardTemplates/FormView/Metrics/Filter'

export default createChildListModalComponent({
  getStorageKey: (self) => 'dashboardTemplates.selectChartMetric',
  getIdField: (self) => 'type',
  allowPagination: (self) => false,
  tableCellComponents: {
    description: (self, item) => <div dangerouslySetInnerHTML={{ __html: item.description }} />,
  },
  filterItems: (self) => (each) => {
    const { categoryType, metricType, chartType } = self.state?.filterDto ?? {}

    if (categoryType && each.categoryType !== categoryType) {
      return false
    }

    if (metricType === 'Single' && each.isCalculatedMetric === true) {
      return false
    }

    if (metricType === 'Multiple' && each.isCalculatedMetric === false) {
      return false
    }

    if (chartType === 'Scatter' && each.isScatterMetric === false) {
      return false
    }

    if (chartType === 'Other' && each.isScatterMetric === true) {
      return false
    }

    return true
  },
})(Filter)
