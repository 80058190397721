import * as React from 'react'
import { Form, message } from 'antd'
import { get, cloneDeep, isNil, set, unset } from 'lodash'
import { showError } from 'helpers/errors'
import { createDropdownRender, createSearchAssets } from 'helpers/formViews'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchAssets = createSearchAssets(this)
  }

  async componentDidMount() {
    try {
      const { assetCategoryIds, assetIds } = this.props.filterDto
      const assetId = get(assetIds, '[0]')

      if (!assetId) {
        message.error(t('selectAssetFirst'))
      }

      const responses = await Promise.all([
        assetId ? this.props.getAssetTolerances(assetId) : Promise.resolve(null),
        this.props.getAssetCategories(),
        this.props.getAssets({ assetCategoryIds, assetIds }),
      ])

      this.setState({ assetTolerances: get(responses[0], 'value.data.items', []) })
    } catch (error) {
      showError({ error })
    }
  }

  setAssetCategoryIds = async (assetCategoryIds) => {
    const params = cloneDeep(this.props.filterDto)

    if (!isNil(assetCategoryIds)) {
      set(params, 'assetCategoryIds', assetCategoryIds)
    } else {
      unset(params, 'assetCategoryIds')
    }

    unset(params, 'assetIds')
    unset(params, 'toleranceIds')

    try {
      await this.props.getAssets({ assetCategoryIds })
    } catch (error) {
      showError({ error })
    }

    this.props.onChange({ ...params })
  }

  setAssetId = async (assetId) => {
    const params = cloneDeep(this.props.filterDto)

    if (!isNil(assetId)) {
      set(params, 'assetIds', [assetId])
    } else {
      unset(params, 'assetIds')

      message.error(t('selectAssetFirst'))
    }

    unset(params, 'toleranceIds')

    try {
      const response = await (assetId ? this.props.getAssetTolerances(assetId) : Promise.resolve(null))

      this.setState({ assetTolerances: get(response, 'value.data.items', []) })
    } catch (error) {
      showError({ error })
    }

    this.props.onChange({ ...params })
  }

  render() {
    const { filterDto, assets } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <DateRangePicker filterDto={filterDto} setFilterValue={this.setFilterValue} />
          </Col>
          <Col third>
            <Form.Item label={t('assetCategory')}>
              <Select
                onChange={(value) => this.setAssetCategoryIds(value)}
                mode="multiple"
                placeholder={t('select')}
                value={filterDto.assetCategoryIds}
              >
                {(this.props.assetCategories ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('asset')} required>
              <Select
                onChange={(value) => this.setAssetId(value)}
                value={get(filterDto, 'assetIds[0]')}
                dropdownRender={createDropdownRender(assets)}
                onSearch={this.searchAssets}
                placeholder={t('select')}
                showSearch
                autoFocus
              >
                {(assets?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('tolerance')}>
              <Select
                onChange={(value) => this.setFilterValue('toleranceIds', value)}
                value={filterDto.toleranceIds}
                mode="multiple"
                placeholder={t('all')}
                allowClear
              >
                {(this.state.assetTolerances ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
