import { Tooltip, Button } from 'antd'
import { isEmpty } from 'lodash'
import { createListModalComponent } from 'factories/ListModal'
import { t } from 'helpers/i18n'
import { showError, showClientNotifications } from 'helpers/errors'
import { Emitter } from 'helpers/events'
import { LIST_MODAL_CLEAR_SELECTION } from 'options/events'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import SelectOperators from 'containers/Operators/Select'
import Filter from 'containers/IssueLimitLists/Operators/Filter'

export default createListModalComponent({
  getStorageKey: (self) => 'operatorIssueLimitLists',
  transformGetItemsParams: (self) => ({
    issueLimitListId: self.props.filterProps.issueLimitListId,
  }),
  allowDelete: (self) => !self.props.readOnly,
  extraTableButtons: (self) => (
    <>
      <Tooltip title={t('addFromOperators')} placement="topLeft">
        <Button
          type="primary"
          size="small"
          onClick={() => self.setState({ addFromOperatorsVisible: true })}
          disabled={self.props.readOnly}
        >
          <Icon type="Add" bold />
        </Button>
      </Tooltip>
      <Modal
        title={t('addFromOperators')}
        visible={self.state.addFromOperatorsVisible}
        onCancel={() => self.setState({ addFromOperatorsVisible: false })}
        footer={
          <>
            <Button onClick={() => self.setState({ addFromOperatorsVisible: false })}>{t('close')}</Button>
            <Button
              onClick={async () => {
                try {
                  const { issueLimitListId } = self.props.filterProps
                  const response = await self.props.createItem(
                    self.state.addFromOperatorsItems.map(({ operatorId }) => ({
                      operatorId,
                      issueLimitListId,
                    }))
                  )

                  showClientNotifications({ response })
                } catch (error) {
                  showClientNotifications({ error })
                  showError({ error })
                } finally {
                  self.fetchItems()
                  self.props.onCreate?.()
                  self.setState({ addFromOperatorsItems: [] })
                  Emitter.emit(LIST_MODAL_CLEAR_SELECTION)
                }
              }}
              loading={self.state.addFromOperatorsLoading}
              disabled={isEmpty(self.state.addFromOperatorsItems)}
              type="primary"
            >
              {t('addToList')}
            </Button>
          </>
        }
        width={992}
      >
        <SelectOperators
          onSelect={(values) => self.setState({ addFromOperatorsItems: values })}
          mode="multiple"
          filterProps={{ allowFilterByStatus: true }}
        />
      </Modal>
    </>
  ),
})(Filter)
