import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import statusOptions from 'options/statusOptions'
import userTypes from 'options/users/userTypes'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import { Row, Col } from 'elements/Grid'

const inOutOfOfficeOptions = {
  All: 'all',
  In: 'in',
  Out: 'out',
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      const { locationIds } = this.props.filterDto

      await Promise.all([this.props.getUserGroups(), this.props.getLocations({ locationIds })])
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto = {}, userIsInRoleSupplier, userIsInRoleDistributor } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          {!userIsInRoleSupplier && !userIsInRoleDistributor && (
            <Col third>
              <Form.Item label={t('group')}>
                <Select
                  onChange={(values) => this.setFilterValue('userGroupIds', values)}
                  placeholder={t('all')}
                  value={filterDto.userGroupIds}
                  mode="multiple"
                >
                  {(this.props.userGroups ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.description}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={t('type')}>
                <Select
                  onChange={(value) => this.setFilterValue('vending', value || 'All')}
                  value={filterDto.vending}
                >
                  {Object.entries(userTypes).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {t(value)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col third>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) => this.setFilterValue('active', value || 'Active')}
                value={filterDto.active || 'Active'}
              >
                {Object.entries(statusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {!userIsInRoleSupplier && !userIsInRoleDistributor && (
              <Form.Item label={t('authorizedForLocations')}>
                <Select
                  onChange={(value) => this.setFilterValue('authorizedLocationIds', value)}
                  mode="multiple"
                  placeholder={t('na')}
                  value={filterDto.authorizedLocationIds}
                >
                  {(this.props.locations ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col third>
            <Form.Item label={t('inOutOfOffice')}>
              <Select onChange={(value) => this.setFilterValue('out', value || 'All')} value={filterDto.out}>
                {Object.entries(inOutOfOfficeOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col />
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
