import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import userGroupActions from 'actions/userGroups'
import locationActions from 'actions/locations'
import Component from 'components/Users/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  userGroups: selectors.userGroups.selectOptions(state),
  locations: selectors.locations.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getUserGroups: (params) => dispatch(userGroupActions.getOptions(params)),
  getLocations: (params) =>
    dispatch(locationActions.getOptions({ ...params, active: 'All', locationVendingTypes: ['NonVending'] })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
