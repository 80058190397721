import { Tooltip, Button } from 'antd'
import { isEmpty } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { showClientNotifications, showError } from 'helpers/errors'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'
import Filter from 'containers/FormTemplates/Filter'

export default createListViewComponent({
  entityName: entityNames.formTemplates,
  filterTemplateType: 'FormTemplate',
  createButtonTextId: 'createFormTemplate',
  primaryLinkTarget: 'formTemplateRecord',
  initialFilterDto: (self) => ({ versionType: 'NonLegacy', active: 'Active' }),
  allowDelete: (self) => self.state.filterDto?.versionType !== 'Legacy',
  getSettingsType: (self) => (self.state.filterDto?.versionType === 'Legacy' ? 'legacyTemplate' : 'template'),
  extraRowActions: (self) =>
    self.state.filterDto?.versionType === 'Legacy' ? (
      <Tooltip title={t('migrateInfo')}>
        <Button
          disabled={isEmpty(self.state.selectedRowKeys)}
          onClick={async () => {
            try {
              self.setState({ migrateToTofinoCloudLoading: true })

              const response = await self.props.migrateToTofinoCloud(self.state.selectedRowKeys)

              showClientNotifications({ response })

              if (response.value.data.failureCount > 0) {
                throw new Error()
              }

              self.setState({ migrateToTofinoCloudLoading: false }, self.fetchItems)
            } catch (error) {
              showError({ error })
            } finally {
              self.setState({ migrateToTofinoCloudLoading: false })
            }
          }}
          loading={self.state.migrateToTofinoCloudLoading}
        >
          {!self.state.migrateToTofinoCloudLoading && <Icon type="DynamicFeed" />}
          {t('migrateToTofinoCloud')}
        </Button>
      </Tooltip>
    ) : null,
  getDeleteButtonTooltip: (self) => t('deleteFormTemplateTooltip'),
})(Filter)
