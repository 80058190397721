import { Button, Tooltip } from 'antd'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { showError } from 'helpers/errors'
import { t } from 'helpers/i18n'
import { sendClientLog } from 'helpers/auth'
import Icon from 'elements/Icon'
import Filter from 'containers/Customers/Filter'

export default createListViewComponent({
  entityName: entityNames.customers,
  filterTemplateType: 'Customer',
  createButtonTextId: 'createCustomer',
  primaryLinkTarget: 'customerRecord',
  extraRowActions: (self) =>
    self.props.userIsDcribDistributor ? (
      <Tooltip title={t('loginAsCustomerTooltip')}>
        <Button
          disabled={self.state.selectedRowKeys.length !== 1}
          onClick={async () => {
            try {
              const customerId = self.state.selectedRowKeys[0]
              await self.props.loginAsCustomer(customerId)
              await sendClientLog('Success', 'Login as Customer', { customerId })
            } catch (error) {
              window.impersonationInProgress = false
              showError({ error })
            }
          }}
        >
          <Icon type="SupervisorAccount" />
          {t('loginAsCustomer')}
        </Button>
      </Tooltip>
    ) : null,
  initialFilterDto: (self) => ({ active: 'Active' }),
})(Filter)
