import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import requisitionActions from 'actions/requisitions'
import requisitionItemActions from 'actions/requisitions/requisitionItems'
import locationActions from 'actions/locations'
import Component from 'components/IssueFromPickList'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  saveItem: requisitionActions.saveRequisition,
  getItem: requisitionActions.getItem,
  getSettings: requisitionItemActions.getSettings,
  getChildItems: requisitionItemActions.getChildItems,
  getLocations: locationActions.getItems,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
