import * as React from 'react'
import styled from 'styled-components'
import {
  Button,
  Card,
  Dropdown,
  Form,
  Input,
  Menu,
  Popconfirm,
  Spin,
  Tabs,
  Tooltip,
  Pagination,
  Table,
  message,
  notification,
} from 'antd'
import { useImmer } from 'use-immer'
import { isFinite, isEmpty, get, trim, isNil, set, remove } from 'lodash'
import { Base64 } from 'js-base64'
import * as CSV from 'csv-string'
import { saveAs } from 'file-saver'
import { v4 as uuid } from 'uuid'
import { showError, showClientNotifications } from 'helpers/errors'
import { getStorageItem, setStorageItem } from 'helpers/localStorage'
import {
  getColumns,
  filterTransferTableColumns,
  filterResultsTableColumns,
  TRANSFER_COLUMNS_OPTIONS,
} from 'helpers/inventoryTransfer'
import { getTableWidth, showTotal, pageSizeOptions } from 'helpers/listViews'
import { getPageTitle } from 'helpers/auth'
import { t } from 'helpers/i18n'
import { Emitter, stopEvent } from 'helpers/events'
import { tryParseInt } from 'helpers/utils'
import { LIST_MODAL_CLEAR_SELECTION } from 'options/events'
import FileUpload from 'elements/FileUpload'
import SelectedRows from 'elements/SelectedRows'
import Modal from 'elements/Modal'
import SelectInventory from 'containers/Inventory/Select'
import SelectListItems from 'containers/Lists/Items/Select'
import Page from 'elements/Page'
import Select, { Option, getOptionProps } from 'elements/Select'
import InputNumber from 'elements/InputNumber'
import Icon from 'elements/Icon'
import ResizableCell from 'elements/ResizableCell'
import Drawer from 'elements/Drawer'
import { Row, Col } from 'elements/Grid'

const getStorageKey = () => 'inventoryTransfer'

const Options = styled(Card)`
  margin: 16px 0;

  .ant-card-body {
    padding: 18px;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }
`

const TableActions = styled(Col)`
  text-align: right;

  .ant-btn {
    padding: 5px;
    line-height: 5px;
    height: 26px;
    margin-left: 5px;
  }
`

const TableContainer = styled.div`
  overflow: auto;

  .ant-table-pagination {
    display: none;
  }

  @media screen and (min-width: 992px) and (min-height: 720px) {
    .ant-table {
      height: calc(100vh - 450px);
    }
  }
`

const TableRowActions = styled(Button.Group)`
  button,
  button[disabled] {
    border: none;
    box-shadow: none;
    background: white;
  }
`

function Component(props) {
  const [state, updateState] = useImmer({
    columnsOption: 'showOnhandColumnsOnly',
    transferTableWidths: {},
    resultsTableWidths: {},
    selectedRowKeys: [],
    tabsActiveKey: 'transfer',
    transferTableDataSource: [],
    resultsTableDataSource: {},
    transferTablePagination: {},
    resultsTablePagination: {},
    ...getStorageItem(getStorageKey()),
  })
  const {
    columnsOption,
    fromLocationId,
    resultsTableDataSource,
    resultsTablePagination,
    resultsTableSettings,
    resultsTableSortedInfo,
    resultsTableWidths,
    selectedRowKeys,
    toLocationId,
    transferTableDataSource,
    transferTablePagination,
    transferTableSettings,
    transferTableSortedInfo,
    transferTableWidths,
  } = state

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  const transferTableColumns = getColumns({
    fieldSettings: filterTransferTableColumns(transferTableSettings, columnsOption),
    onResize:
      ({ key }) =>
      (e, { size }) => {
        updateState((draft) => {
          draft.transferTableWidths[key] = size.width
        })
      },
    columnWidths: transferTableWidths,
    ...transferTableSortedInfo,
    tableCellComponents: {
      onHandQuantityToMove: (record, column) => (
        <InputNumber
          value={record.onHandQuantityToMove}
          onChange={(value) => {
            updateState((draft) => {
              const index = draft.transferTableDataSource.findIndex((one) => one.id === record.id)

              draft.transferTableDataSource[index].onHandQuantityToMove = value
            })
          }}
          min={0}
        />
      ),
      onHand2QuantityToMove: (record, column) => (
        <InputNumber
          value={record.onHand2QuantityToMove}
          onChange={(value) => {
            updateState((draft) => {
              const index = draft.transferTableDataSource.findIndex((one) => one.id === record.id)

              draft.transferTableDataSource[index].onHand2QuantityToMove = value
            })
          }}
          min={0}
        />
      ),
      toLocationId: (record, column) => {
        const handleChangeLocationId = async (value) => {
          try {
            const [moveData] = await props
              .getMoveData([{ ...record, toLocationId: value }])
              .then((r) => get(r, 'value.data.items', []))

            updateState((draft) => {
              const index = draft.transferTableDataSource.findIndex((one) => one.id === record.id)

              Object.assign(draft.transferTableDataSource[index], {
                ...moveData,
                id: record.id,
                inventoryId: undefined,
              })
            })
          } catch (error) {
            showError({ error })
          }
        }
        return (
          <Select
            placeholder={t('all')}
            value={record.toLocationId}
            allowClear={false}
            onChange={handleChangeLocationId}
          >
            {(state?.locations ?? [])
              .filter(
                (location) =>
                  !transferTableDataSource
                    .filter((row) => row.barcode === record.barcode && row.id !== record.id)
                    .map((row) => row.toLocationId)
                    .includes(location.id)
              )
              .map((each) => (
                <Option key={each.id} value={each.id}>
                  <span {...getOptionProps(each)}>{each.name}</span>
                </Option>
              ))}
          </Select>
        )
      },
      toBinLocation: (record, column) =>
        record.barcodeExistsInToLocation ? (
          record.toBinLocation
        ) : (
          <Input
            value={record.toBinLocation}
            onChange={(e) =>
              updateState((draft) => {
                const index = draft.transferTableDataSource.findIndex((one) => one.id === record.id)
                draft.transferTableDataSource[index].toBinLocation = e.target.value
              })
            }
          />
        ),
      transferQuantity: (record, column) => {
        const { fromOnHandQuantity: from, onHandQuantityToMove: to } = record
        if (isFinite(from) && isFinite(to)) {
          return from < to ? from : to
        }
        return null
      },
      deficiencyQuantity: (record, column) => {
        const { fromOnHandQuantity: from, onHandQuantityToMove: to } = record
        if (isFinite(from) && isFinite(to)) {
          return from < to ? (
            <div className="table-cell-error">
              <span>{to - from}</span>
            </div>
          ) : (
            0
          )
        }
        return null
      },
      transfer2Quantity: (record, column) => {
        const { fromOnHand2Quantity: from, onHand2QuantityToMove: to } = record
        if (isFinite(from) && isFinite(to)) {
          return from < to ? from : to
        }
        return null
      },
      deficiency2Quantity: (record, column) => {
        const { fromOnHand2Quantity: from, onHand2QuantityToMove: to } = record
        if (isFinite(from) && isFinite(to)) {
          return from < to ? (
            <div className="table-cell-error">
              <span>{to - from}</span>
            </div>
          ) : (
            0
          )
        }
        return null
      },
    },
  })

  const resultsTableColumns = getColumns({
    fieldSettings: filterResultsTableColumns(resultsTableSettings, columnsOption),
    onResize:
      ({ key }) =>
      (e, { size }) => {
        updateState((draft) => {
          draft.resultsTableWidths[key] = size.width
        })
      },
    columnWidths: resultsTableWidths,
    ...resultsTableSortedInfo,
    tableCellComponents: {
      movedOnHandQuantity: (record, column) =>
        record.movedOnHandQuantity ? (
          record.movedOnHandQuantity
        ) : (
          <div className="table-cell-error">
            <span>{record.movedOnHandQuantity}</span>
          </div>
        ),
      movedOnHand2Quantity: (record, column) =>
        record.movedOnHand2Quantity ? (
          record.movedOnHand2Quantity
        ) : (
          <div className="table-cell-error">
            <span>{record.movedOnHand2Quantity}</span>
          </div>
        ),
    },
  })

  async function refreshTransferDataSource() {
    try {
      const moveData = await props
        .getMoveData(
          transferTableDataSource.map((each) => ({
            barcode: each.barcode,
            fromLocationId: each.fromLocationId,
            toLocationId: each.toLocationId,
            onHandQuantityToMove: each.onHandQuantityToMove,
            onHand2QuantityToMove: each.onHand2QuantityToMove,
          }))
        )
        .then((r) => get(r, 'value.data.items', []))
        .then((r) => r.map((each) => ({ ...each, id: uuid(), inventoryId: undefined })))

      setState('transferTableDataSource', moveData)

      return moveData
    } catch (error) {
      showError({ error })

      return error
    }
  }

  function handleFromLocationIdChange(value) {
    setState('fromLocationId', value)
  }

  function handleToLocationIdChange(value) {
    setState('toLocationId', value)
  }

  function handleActionsMenuClick({ key }) {
    switch (key) {
      case 'addFromFile':
        setState('fileUploadDrawerVisible', true)
        break

      case 'addFromInventory':
        updateState((draft) => {
          draft.addFromInventoryVisible = true
          draft.addFromInventoryItems = []
        })
        break

      case 'addFromList':
        updateState((draft) => {
          draft.addFromListVisible = true
          draft.addFromListItems = []
        })
        break

      case 'clearAllEnteredQuantities':
        updateState((draft) => {
          draft.transferTableDataSource.forEach((each) => {
            each.onHandQuantityToMove = 0
            each.onHand2QuantityToMove = 0
          })
        })
        break

      case 'downloadResults': {
        const displayables = resultsTableSettings.filter((each) => each.isDisplayable)
        const dtoFieldNames = displayables.map((each) => each.dtoFieldName)

        saveAs(
          new Blob(
            [
              CSV.stringify([
                displayables.map((each) => t(each.columnHeadingLanguageKey)),
                (resultsTableDataSource?.items ?? []).map((item) =>
                  dtoFieldNames.map((dtoFieldName) => get(item, dtoFieldName, ''))
                ),
              ]),
            ],
            { type: 'text/csv' }
          ),
          `${t('results')}.csv`
        )
        break
      }

      default:
        message.warn(t('underDevelopment'))
        break
    }
  }

  async function addItems(items = []) {
    try {
      const moveData = await props
        .getMoveData(items)
        .then((response) => {
          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          return response
        })
        .then((r) => get(r, 'value.data.items', []))
        .then((r) => r.map((each) => ({ ...each, id: uuid(), inventoryId: undefined })))

      updateState((draft) => {
        remove(draft.transferTableDataSource, (each) =>
          moveData.some(
            (one) =>
              one.barcode === each.barcode &&
              one.fromLocationId === each.fromLocationId &&
              one.toLocationId === each.toLocationId
          )
        )
        draft.transferTableDataSource = draft.transferTableDataSource.concat(moveData)
        draft.selectedRowKeys = []
        draft.fileUploadDrawerVisible = false
      })
    } catch (error) {
      showError({ error })
    }
  }

  function handleFileUpload(fileList = []) {
    if (isEmpty(fileList)) {
      setState('fileUploadDrawerVisible', true)
    } else {
      try {
        let items = []

        try {
          items = CSV.parse(Base64.decode(fileList[0].fileContents)).map((parts) => ({
            barcode: trim(parts[0]),
            fromLocationId: tryParseInt(parts[1], fromLocationId),
            toLocationId: tryParseInt(parts[2], toLocationId),
            onHandQuantityToMove: tryParseInt(parts[3], 0),
            onHand2QuantityToMove: tryParseInt(parts[4], 0),
          }))
        } catch (error) {
          throw new Error(t('selectedFileCannotBeUploaded'))
        }

        addItems(items)
      } catch (error) {
        notification.error({
          message: t('error'),
          description: t('selectedFileCannotBeUploaded'),
        })
      }
    }
  }

  async function transferRows(rows = []) {
    try {
      const response = await props.moveItems(
        rows.map((each) => ({
          barcode: each.barcode,
          fromLocationId: each.fromLocationId,
          fromBinLocation: each.fromBinLocation,
          toLocationId: each.toLocationId,
          toBinLocation: each.toBinLocation,
          onHandQuantityToMove:
            each.fromOnHandQuantity < each.onHandQuantityToMove
              ? each.fromOnHandQuantity
              : each.onHandQuantityToMove,
          onHand2QuantityToMove:
            each.fromOnHand2Quantity < each.onHand2QuantityToMove
              ? each.fromOnHand2Quantity
              : each.onHand2QuantityToMove,
        }))
      )

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }

      setState('resultsTableDataSource', response?.value?.data ?? [])
    } catch (error) {
      setState('resultsTableDataSource', error.data)
    }

    updateState((draft) => {
      draft.selectedRowKeys = []
      draft.tabsActiveKey = 'results'
    })

    refreshTransferDataSource()
  }

  async function handleTransferAllClick() {
    try {
      setState('transferAllLoading', true)

      await transferRows(transferTableDataSource)
    } finally {
      setState('transferAllLoading', false)
    }
  }

  async function handleRefreshOnHandClick() {
    try {
      setState('refreshOnHandLoading', true)

      await refreshTransferDataSource()
    } finally {
      setState('refreshOnHandLoading', false)
    }
  }

  async function handleTransferSelectedClick() {
    try {
      setState('transferSelectedLoading', true)

      await transferRows(transferTableDataSource.filter((each) => selectedRowKeys.includes(each.id)))
    } finally {
      setState('transferSelectedLoading', false)
    }
  }

  function handleTransferTableChange(pagination, filters, sorter = {}) {
    const { order, field } = sorter

    updateState((draft) => {
      draft.transferTableSortedInfo = {
        sortOrder: order,
        sortBy: field,
      }
      draft.selectedRowKeys = []
    })
  }

  function handleResultsTableChange(pagination, filters, sorter = {}) {
    const { order, field } = sorter

    updateState((draft) => {
      draft.resultsTableSortedInfo = {
        sortOrder: order,
        sortBy: field,
      }
    })
  }

  function handleAddFromInventorySubmit() {
    addItems(
      state.addFromInventoryItems.map((each) => ({
        barcode: each.barcode,
        fromLocationId,
        toLocationId,
      }))
    )

    Emitter.emit(LIST_MODAL_CLEAR_SELECTION)
  }

  function handleAddFromListSubmit() {
    addItems(
      state.addFromListItems.map((each) => ({
        barcode: each.barcode,
        fromLocationId,
        toLocationId,
      }))
    )

    Emitter.emit(LIST_MODAL_CLEAR_SELECTION)
  }

  function handleTransferTablePaginationChange(current, pageSize) {
    updateState((draft) => {
      draft.transferTablePagination.current = current
      draft.transferTablePagination.pageSize = pageSize
      draft.selectedRowKeys = []
    })
  }

  function handleResultsTablePaginationChange(current, pageSize) {
    updateState((draft) => {
      draft.resultsTablePagination.current = current
      draft.resultsTablePagination.pageSize = pageSize
    })
  }

  React.useEffect(() => {
    if (!isEmpty(state.locations)) {
      const locationIds = state.locations.map((each) => each.id)

      updateState((draft) => {
        if (isNil(draft.fromLocationId) || !locationIds.includes(draft.fromLocationId)) {
          draft.fromLocationId = state.locations[0].id
        }

        if (isNil(draft.toLocationId) || !locationIds.includes(draft.toLocationId)) {
          draft.toLocationId = state.locations[0].id
        }
      })
    }
  }, [state.locations])

  React.useEffect(() => {
    updateState((draft) => {
      draft.transferTablePagination.current = isEmpty(transferTableDataSource) ? 0 : 1
    })
  }, [transferTableDataSource])

  React.useEffect(() => {
    updateState((draft) => {
      draft.resultsTablePagination.current = isEmpty(resultsTableDataSource.items) ? 0 : 1
    })
  }, [resultsTableDataSource])

  React.useEffect(() => {
    async function fetchSettings() {
      try {
        const responses = await Promise.all([
          props.getTransferTableSettings(),
          props.getTransferResultsSettings(),
          props.getLocations({ locationVendingTypes: ['NonVending'] }),
        ])

        updateState((draft) => {
          draft.transferTableSettings = responses[0].value.data.fieldSettings
          draft.resultsTableSettings = responses[1].value.data.fieldSettings
          draft.locations = get(responses[2], 'value.data.items', [])
        })
      } catch (error) {
        showError({ error })
      }
    }

    fetchSettings()
  }, [])

  React.useEffect(() => {
    setStorageItem(getStorageKey(), {
      fromLocationId,
      toLocationId,
      columnsOption,
      transferTableWidths,
      transferTableSortedInfo,
      transferTableDataSource,
      resultsTableWidths,
      resultsTableSortedInfo,
      resultsTableDataSource,
    })
  })

  const pageTitle = getPageTitle()

  if (!transferTableSettings || !resultsTableSettings) {
    return (
      <Page title={pageTitle}>
        <Spin />
      </Page>
    )
  }

  return (
    <>
      <Page title={pageTitle}>
        <Row type="flex" justify="space-between">
          <Col xs={24} sm={6} className="whitespace-nowrap">
            <h2>{pageTitle}</h2>
          </Col>
          <Col xs={24} sm={6} className="text-right">
            <Dropdown
              overlay={
                <Menu onClick={handleActionsMenuClick}>
                  <Menu.Item key="addFromFile">{t('addFromFile')}</Menu.Item>
                  <Menu.Item key="addFromList">{t('addFromList')}</Menu.Item>
                  <Menu.Item key="addFromInventory">{t('addFromInventory')}</Menu.Item>
                  <Menu.Divider />
                  <Menu.Item key="clearAllEnteredQuantities" disabled={isEmpty(transferTableDataSource)}>
                    {t('clearAllEnteredQuantities')}
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button className="mr-6">
                {t('actions')}
                <Icon type="KeyboardArrowDown" />
              </Button>
            </Dropdown>
          </Col>
        </Row>
        <Options>
          <Form layout="vertical" colon={false} onSubmit={stopEvent}>
            <Row>
              <Col xs={24} md={8} lg={6}>
                <Form.Item label={t('from')}>
                  <Select value={fromLocationId} onChange={handleFromLocationIdChange} allowClear={false}>
                    {(state?.locations ?? []).map((each) => (
                      <Option key={each.id} value={each.id}>
                        <span {...getOptionProps(each)}>{each.displayName}</span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8} lg={6}>
                <Form.Item label={t('to')}>
                  <Select
                    value={toLocationId || fromLocationId}
                    onChange={handleToLocationIdChange}
                    allowClear={false}
                  >
                    {(state?.locations ?? []).map((each) => (
                      <Option key={each.id} value={each.id}>
                        <span {...getOptionProps(each)}>{each.displayName}</span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8} lg={6}>
                <Form.Item label={t('columns')}>
                  <Select
                    value={columnsOption}
                    onChange={(value) => setState('columnsOption', value)}
                    allowClear={false}
                    showSearch={false}
                  >
                    {TRANSFER_COLUMNS_OPTIONS.map((each) => (
                      <Option key={each} value={each}>
                        {t(each)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Options>
        <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
          <Tabs.TabPane key="transfer" tab={t('transfer')} forceRender>
            <Row type="flex" justify="space-between" className="whitespace-nowrap mb-12">
              <Col>
                <SelectedRows selectedRowKeys={selectedRowKeys} />
                <TableRowActions size="small">
                  <Popconfirm
                    title={t('confirmDeleteSelectedItems')}
                    okText={t('yes')}
                    cancelText={t('no')}
                    onConfirm={() =>
                      updateState((draft) => {
                        remove(draft.transferTableDataSource, (each) => selectedRowKeys.includes(each.id))
                        draft.selectedRowKeys = []
                      })
                    }
                    disabled={isEmpty(selectedRowKeys)}
                  >
                    <Button disabled={isEmpty(selectedRowKeys)}>
                      <Icon type="Delete" />
                      {t('delete')}
                    </Button>
                  </Popconfirm>
                  <Button
                    loading={state.transferSelectedLoading}
                    disabled={isEmpty(selectedRowKeys)}
                    onClick={handleTransferSelectedClick}
                  >
                    {!state.transferSelectedLoading && <Icon type="Directions" />}
                    {t('transfer')}
                  </Button>
                  <Button
                    disabled={isEmpty(selectedRowKeys)}
                    onClick={() => message.warn(t('underDevelopment'))}
                  >
                    <Icon type="ShoppingCart" />
                    {t('order')}
                  </Button>
                </TableRowActions>
              </Col>
              <TableActions>
                <Tooltip title={t('addFromFile')}>
                  <Button size="small" onClick={() => handleActionsMenuClick({ key: 'addFromFile' })}>
                    <Icon type="FileUpload" />
                  </Button>
                </Tooltip>
                <Tooltip title={t('addFromList')} placement="topLeft">
                  <Button size="small" onClick={() => handleActionsMenuClick({ key: 'addFromList' })}>
                    <Icon type="ContentPaste" />
                  </Button>
                </Tooltip>
                <Tooltip title={t('addFromInventory')} placement="topLeft">
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => handleActionsMenuClick({ key: 'addFromInventory' })}
                  >
                    <Icon type="Add" bold />
                  </Button>
                </Tooltip>
              </TableActions>
            </Row>
            <TableContainer>
              <Table
                childrenColumnName={[]}
                rowKey={(record) => record.id}
                columns={transferTableColumns}
                dataSource={transferTableDataSource}
                rowSelection={{
                  selectedRowKeys,
                  onChange: (rowKeys) => setState('selectedRowKeys', rowKeys),
                }}
                size="middle"
                locale={{ emptyText: t('noData') }}
                style={{ width: getTableWidth(transferTableColumns, true) }}
                components={{ header: { cell: ResizableCell } }}
                pagination={transferTablePagination}
                onChange={handleTransferTableChange}
              />
            </TableContainer>
            <Row className="mt-12">
              <Col xs={24} md={12} className="mb-12 text-left">
                <Pagination
                  current={transferTablePagination?.current ?? 0}
                  total={transferTableDataSource?.length ?? 0}
                  pageSize={transferTablePagination?.pageSize ?? 20}
                  pageSizeOptions={pageSizeOptions}
                  onChange={handleTransferTablePaginationChange}
                  onShowSizeChange={handleTransferTablePaginationChange}
                  showTotal={showTotal}
                  size="small"
                  showTitle={false}
                  showSizeChanger
                  showLessItems
                />
              </Col>
              <Col xs={24} md={12} className="mb-12 text-right">
                {!isEmpty(transferTableDataSource) && (
                  <>
                    <Popconfirm
                      title={t('confirmClearTransfer')}
                      okText={t('yes')}
                      cancelText={t('no')}
                      onConfirm={() =>
                        updateState((draft) => {
                          draft.transferTableDataSource = []
                          draft.selectedRowKeys = []
                        })
                      }
                    >
                      <Button>{t('clearTransfer')}</Button>
                    </Popconfirm>{' '}
                    <Button
                      onClick={handleRefreshOnHandClick}
                      loading={state.refreshOnHandLoading}
                      disabled={fromLocationId < 1}
                    >
                      {t('refreshOnHand')}
                    </Button>{' '}
                    <Button
                      type="primary"
                      onClick={handleTransferAllClick}
                      disabled={fromLocationId < 1}
                      loading={state.transferAllLoading}
                    >
                      {t('transferAll')}
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane key="results" tab={t('results')} disabled={isEmpty(resultsTableDataSource?.items)}>
            <Row type="flex" justify="space-between" className="whitespace-nowrap mb-12">
              <Col />
              <TableActions>
                <Tooltip title={t('downloadResults')} placement="topLeft">
                  <Button size="small" onClick={() => handleActionsMenuClick({ key: 'downloadResults' })}>
                    <Icon type="FileDownload" />
                  </Button>
                </Tooltip>
              </TableActions>
            </Row>
            <TableContainer>
              <Table
                childrenColumnName={[]}
                rowKey={(record) => record.id}
                columns={resultsTableColumns}
                dataSource={(resultsTableDataSource?.items ?? []).map((each) => ({
                  id: uuid(),
                  ...each,
                }))}
                size="middle"
                locale={{ emptyText: t('noData') }}
                style={{ width: getTableWidth(resultsTableColumns) }}
                components={{ header: { cell: ResizableCell } }}
                pagination={resultsTablePagination}
                onChange={handleResultsTableChange}
              />
            </TableContainer>
            <Row className="mt-12">
              <Col xs={24} md={12} className="mb-12 text-left">
                <Pagination
                  current={resultsTablePagination?.current || 0}
                  total={resultsTableDataSource?.items?.length ?? 0}
                  pageSize={resultsTablePagination?.pageSize || 20}
                  pageSizeOptions={pageSizeOptions}
                  onChange={handleResultsTablePaginationChange}
                  onShowSizeChange={handleResultsTablePaginationChange}
                  showTotal={showTotal}
                  size="small"
                  showTitle={false}
                  showSizeChanger
                  showLessItems
                />
              </Col>
              <Col xs={24} md={12} className="mb-12 text-right">
                {!isEmpty(resultsTableDataSource?.items) && (
                  <>
                    <span className="mr-12">
                      {get(resultsTableDataSource, 'clientNotifications[0].description')}
                    </span>
                    <Popconfirm
                      title={t('confirmClearResults')}
                      okText={t('yes')}
                      cancelText={t('no')}
                      onConfirm={() =>
                        updateState((draft) => {
                          draft.resultsTableDataSource = {}
                          draft.tabsActiveKey = 'transfer'
                        })
                      }
                    >
                      <Button>{t('clearResults')}</Button>
                    </Popconfirm>
                  </>
                )}
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Page>
      <Drawer
        title={t('addFromFile')}
        size="xs"
        visible={state.fileUploadDrawerVisible}
        onClose={() => setState('fileUploadDrawerVisible', false)}
      >
        <FileUpload
          accept=".csv,text/csv"
          onUpload={handleFileUpload}
          onCancel={() => setState('fileUploadDrawerVisible', false)}
          extra={{
            type: 'info',
            message: t('fileRequirements'),
            description: t('inventoryTransferUploadInfo'),
          }}
        />
      </Drawer>
      <Modal
        title={t('addFromInventory')}
        visible={state.addFromInventoryVisible}
        onCancel={() => setState('addFromInventoryVisible', false)}
        footer={
          <>
            <Button onClick={() => setState('addFromInventoryVisible', false)}>{t('close')}</Button>
            <Button
              onClick={handleAddFromInventorySubmit}
              loading={state.addFromInventoryLoading}
              type="primary"
              disabled={isEmpty(state.addFromInventoryItems)}
            >
              {t('addToTransfer')}
            </Button>
          </>
        }
        width={992}
      >
        <SelectInventory
          initialFilterDto={{
            locationIds: [fromLocationId],
            unlockedInventoryOnly: true,
          }}
          onSelect={(values) => setState('addFromInventoryItems', values)}
          mode="multiple"
          filterProps={{ disabled: true }}
        />
      </Modal>
      <Modal
        title={t('addFromList')}
        visible={state.addFromListVisible}
        onCancel={() => setState('addFromListVisible', false)}
        footer={
          <>
            <Button onClick={() => setState('addFromListVisible', false)}>{t('close')}</Button>
            <Button
              onClick={handleAddFromListSubmit}
              loading={state.addFromListLoading}
              type="primary"
              disabled={isEmpty(state.addFromListItems)}
            >
              {t('addToTransfer')}
            </Button>
          </>
        }
        width={992}
      >
        <SelectListItems
          onSelect={(values) => setState('addFromListItems', values)}
          settingsType="productListProcurementRecord"
          mode="multiple"
          filterProps={{
            locationId: fromLocationId,
            entityType: 'Order',
            listType: 'Product',
            listItemMode: 'ProductListProcurementRecord',
          }}
        />
      </Modal>
    </>
  )
}

export default Component
