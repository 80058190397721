import { isEmpty } from 'lodash'
import { login, loginSso, loginAsCustomer, getLogo } from 'helpers/auth'
import { LOGIN, LOGIN_AS_CUSTOMER, EXIT_AS_CUSTOMER, REFRESH_TOKEN } from 'reducers/auth'
import { createPost } from 'helpers/api'
import i18n from 'actions/i18n'
import { getStartupPage, getStartupRecord } from 'helpers/startupPage'
import entityNames from 'options/entityNames'
import { tryParseDate } from 'helpers/dateTime'
import { setSessionItem, getSessionItem } from 'helpers/sessionStorage'
import {
  REFRESH_TOKEN_INTERVAL,
  REFRESH_TOKEN_SESSION_KEY,
  USER_LOGGED_IN_SESSION_KEY,
  IMPERSONATION_SOURCE_URL,
  STARTUP_REDIRECT_SESSION_KEY,
} from 'options/auth'
import { TOKEN_REFRESHED_BY_FORCE } from 'options/events'
import { Emitter } from 'helpers/events'

export default {
  login:
    ({ tenant, userName, password, startupEntityName, startupEntityId, accessToken, idToken }) =>
    async (dispatch) => {
      sessionStorage.clear()

      const response = await dispatch({
        type: LOGIN,
        payload:
          accessToken && idToken
            ? loginSso({ tenant, accessToken, idToken })
            : login({ tenant, userName, password }),
      })

      setSessionItem(REFRESH_TOKEN_SESSION_KEY, new Date().toJSON())
      setSessionItem(USER_LOGGED_IN_SESSION_KEY, new Date().toJSON())

      await Promise.all([
        dispatch(i18n.getTranslations(response.value.data.user.languageCode)),
        dispatch(getLogo(response.value.data.tenant)).catch(() => {}),
      ])

      let startupPage = getStartupPage(response.value.data.menuItems)

      const startupRecord = getStartupRecord({
        startupEntityName,
        startupEntityId,
      })

      if (startupRecord) {
        startupPage = startupRecord.startupPage

        setSessionItem(STARTUP_REDIRECT_SESSION_KEY, startupRecord.startupLinkTarget)
      }

      return startupPage
    },

  loginAsCustomer: (customerId) => async (dispatch) => {
    window.impersonationInProgress = true

    const response = await dispatch({
      type: LOGIN_AS_CUSTOMER,
      payload: loginAsCustomer(customerId),
    })

    await dispatch(getLogo(response.value.data.tenant)).catch(() => {})

    window.location.href = getStartupPage(response.value.data.menuItems)
  },

  existAsCustomer: () => async (dispatch, getState) => {
    window.impersonationInProgress = true

    await dispatch({ type: EXIT_AS_CUSTOMER })

    window.location.href = IMPERSONATION_SOURCE_URL
  },

  refreshToken: (mustRefresh) => async (dispatch, getState) => {
    let response

    const shouldRefresh = () => {
      const lastTime = tryParseDate(getSessionItem(REFRESH_TOKEN_SESSION_KEY))
      const elapsedTime = lastTime ? Date.now() - lastTime.getTime() : REFRESH_TOKEN_INTERVAL + 1
      return elapsedTime > REFRESH_TOKEN_INTERVAL && !window.refreshTokenInProgress
    }

    if (mustRefresh || shouldRefresh()) {
      try {
        window.refreshTokenInProgress = true

        response = await dispatch({
          type: REFRESH_TOKEN,
          payload: createPost(entityNames.users, { action: 'refreshToken' })(),
        })

        if (mustRefresh) {
          await Promise.all([
            dispatch(i18n.getTranslations(response.value.data.user.languageCode)),
            dispatch(getLogo(response.value.data.tenant)).catch(() => {}),
          ])

          Emitter.emit(TOKEN_REFRESHED_BY_FORCE)
        }

        setSessionItem(REFRESH_TOKEN_SESSION_KEY, new Date().toJSON())

        if (isEmpty(response.value.data.menuItems)) {
          window.location.href = '/'
        }
      } finally {
        window.refreshTokenInProgress = false
      }
    }

    return response
  },
}
