import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import taskTypes from 'options/tasks/taskTypes'
import Filter from 'containers/Reports/Schedule/Filter'

export default createListViewComponent({
  entityName: entityNames.reportSchedules,
  filterTemplateType: 'ScheduledReport',
  createButtonTextId: 'scheduleReport',
  primaryLinkTarget: 'reportTaskRecord',
  initialFilterDto: (self) => ({
    active: 'Active',
    taskType: taskTypes.ScheduledReport,
  }),
  getSettingsType: (self) => 'reports',
})(Filter)
