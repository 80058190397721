import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { t } from 'helpers/i18n'
import { createSearchAssets, createDropdownRender, isReadOnly } from 'helpers/formViews'
import { createGetFieldLabel } from 'helpers/dashboards'
import meantimeBasedOn from 'options/assets/meantimeBasedOn'
import Select, { Option, getOptionProps } from 'elements/Select'
import Checkbox from 'elements/Checkbox'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchAssets = createSearchAssets(this)
  }

  async componentDidMount() {
    try {
      const { assetBarcodes = [], assetCategoryIds = [] } = this.props.filterDto

      await Promise.all([
        this.props.getAssetCategories(),
        this.props.getAssets({ assetBarcodes, assetCategoryIds }),
      ])
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { fields = [], filterDto = {}, assets } = this.props

    const getFieldLabel = createGetFieldLabel(fields)
    const readOnly = isReadOnly(this)

    return (
      <>
        {fields.find((one) => one.type === 'assetBarcodes') && (
          <Form.Item label={getFieldLabel('assetBarcodes')}>
            <Select
              onChange={(values) => this.setFilterValue('assetBarcodes', values)}
              value={filterDto.assetBarcodes}
              dropdownRender={createDropdownRender(assets)}
              onSearch={this.searchAssets}
              placeholder={t('all')}
              mode="multiple"
              readOnly={readOnly}
              showSearch
            >
              {(assets?.items ?? []).map((each) => (
                <Option key={each.barcode} value={each.barcode}>
                  {each.displayName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'assetCategoryIds') && (
          <Form.Item label={getFieldLabel('assetCategoryIds')}>
            <Select
              onChange={(value) => this.setFilterValue('assetCategoryIds', value)}
              mode="multiple"
              placeholder={t('all')}
              value={filterDto.assetCategoryIds}
              readOnly={readOnly}
            >
              {(this.props.assetCategories ?? []).map((each) => (
                <Option key={each.id} value={each.id}>
                  <span {...getOptionProps(each)}>{each.displayName}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'assetMeantimeBasedOn') && (
          <Form.Item label={getFieldLabel('assetMeantimeBasedOn')}>
            <Select
              onChange={(value) => this.setFilterValue('meantimeBasedOn', value)}
              value={filterDto.meantimeBasedOn || 'AssetDowntime'}
              allowClear={false}
              readOnly={readOnly}
            >
              {Object.entries(meantimeBasedOn).map(([key, value]) => (
                <Option key={key} value={key}>
                  {t(value)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'criticalAssetsOnly') && (
          <Form.Item label={getFieldLabel('criticalAssetsOnly')}>
            <Checkbox
              onChange={(e) => this.setFilterValue('criticalAssetsOnly', e.target.checked)}
              checked={filterDto.criticalAssetsOnly}
              readOnly={readOnly}
            ></Checkbox>
          </Form.Item>
        )}
      </>
    )
  }
}

export default Form.create()(Filter)
