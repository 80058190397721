import { Tooltip, Popover } from 'antd'
import { isEmpty } from 'lodash'
import { createListModalComponent } from 'factories/ListModal'
import { createY2KNullTableCellComponent } from 'helpers/listViews'
import { t } from 'helpers/i18n'
import { RED, DARK_GREEN } from 'options/colors'
import Icon from 'elements/Icon'
import Filter from 'containers/Inventory/Select/Filter'

const createOnHandTableCellComponent = (field) => (self, item) => (
  <div className="text-right">
    {item.lockedForCycleCount ? (
      <>
        <span className="mr-6">{item[field]}</span>
        <span className="align-middle">
          <Tooltip title={t('onhandQuantitiesLockedForCycleCount')}>
            <Icon type="Lock" color={RED} />
          </Tooltip>
        </span>
      </>
    ) : (
      <span className="mr-24">{item[field]}</span>
    )}
  </div>
)

export default createListModalComponent({
  getStorageKey: (self) => 'selectInventory',
  getSettingsType: (self) => 'selectInventory',
  tableCellComponents: {
    barcode: (self, item) => (
      <Tooltip title={item.lockedForCycleCount ? t('onhandQuantitiesLockedForCycleCount') : undefined}>
        <span style={item.lockedForCycleCount ? { color: RED } : {}}>{item.barcode}</span>
      </Tooltip>
    ),
    onHand: createOnHandTableCellComponent('onHand'),
    onHand2: createOnHandTableCellComponent('onHand2'),
    onOrder: (self, item) => (
      <div>
        {item.onOrder > 0 ? (
          <>
            <span className="mr-6">{item.onOrder}</span>
            <Popover
              title={t('onOrder')}
              content={<span dangerouslySetInnerHTML={{ __html: item.onOrderInfo }} />}
            >
              <span
                className="align-middle"
                style={{ visibility: isEmpty(item.onOrderInfo) ? 'hidden' : 'visible' }}
              >
                <Icon type="Search" />
              </span>
            </Popover>
          </>
        ) : (
          <span className="mr-24">{item.onOrder}</span>
        )}
      </div>
    ),
    lastCycleCount: createY2KNullTableCellComponent('lastCycleCount'),
    onHandStatus: (self, item) => {
      if (item.onHandStatus === 'GreaterThanMax') {
        return (
          <Tooltip title={item.onHandStatusInfo}>
            <Icon type="Speed" color={DARK_GREEN} />
          </Tooltip>
        )
      }

      if (item.onHandStatus === 'LessThanMin') {
        return (
          <Tooltip title={item.onHandStatusInfo}>
            <Icon type="Speed" color={RED} style={{ transform: 'scaleX(-1)' }} />
          </Tooltip>
        )
      }

      return null
    },
  },
})(Filter)
