import { connect } from 'react-redux'
import selectors from 'selectors'
import userActions from 'actions/users'
import actions from 'actions/dashboardTemplates'
import Component from 'components/DashboardTemplates/Filter'

const mapStateToProps = (state, props) => ({ users: selectors.users.selectOptions(state) })

const mapDispatchToProps = (dispatch, props) => ({
  getUsers: (params) => dispatch(userActions.getOptions({ ...params, active: 'All' })),
  getChartMetricTypes: (params) => dispatch(actions.getChartMetricTypes(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
