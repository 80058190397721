import * as React from 'react'
import { Form, Button } from 'antd'
import { isEmpty } from 'lodash'
import { showError, showClientNotifications } from 'helpers/errors'
import statusOptions from 'options/statusOptions'
import { createDropdownRender, createSearchSuppliers } from 'helpers/formViews'
import { HARD_SPACE } from 'helpers/utils'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { getUserPermission } from 'helpers/auth'
import { PRIVATE_DATABASE } from 'options/products'
import Select, { Option, getOptionProps } from 'elements/Select'
import SelectProductCategories from 'containers/ProductCategories/Select'
import Help from 'elements/Help'
import Checkbox from 'elements/Checkbox'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchSuppliers = createSearchSuppliers(this)
  }

  async componentDidMount() {
    try {
      const { preferredSupplierIds, productCategoryIds } = this.props.filterDto
      const [productCatalogs] = await Promise.all([
        this.props.getProductCatalogs(),
        this.props.getProductCategories({ productCategoryIds }),
        this.props.getSuppliers(preferredSupplierIds ? { supplierIds: preferredSupplierIds } : undefined),
      ])

      this.setState({ productCatalogs: productCatalogs?.value?.data ?? [] })
    } catch (error) {
      showError({ error })
    }
  }

  handleRefreshCatalogItemsClick = async () => {
    try {
      this.setState({ refreshCatalogItemsLoading: true })

      const response = await this.props.refreshCatalogItems({
        linkedCatalogTableName: this.props.filterDto.linkedCatalogTableName,
      })

      this.setFilterValue() // HACK: Refreshes the list

      showClientNotifications({ response })
    } catch (error) {
      showError({ error })
    } finally {
      this.setState({ refreshCatalogItemsLoading: false })
    }
  }

  render() {
    const { tenant, filterDto = {}, suppliers, userIsDcribCustomer } = this.props

    const isSupplierPortal = tenant.tenantType === 'SupplierPortal'
    const isTrms = tenant.tenantType === 'Trms'
    const databaseDefault = isSupplierPortal ? undefined : PRIVATE_DATABASE
    const databasePlaceholder = isSupplierPortal ? 'selectSupplierCatalog' : 'select'
    const isPrivateDatabase = filterDto.catalogTableName === PRIVATE_DATABASE

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <Form.Item label={t('database')}>
              <Select
                onChange={(value) => this.setFilterValue('catalogTableName', value || databaseDefault)}
                placeholder={t(databasePlaceholder)}
                value={filterDto.catalogTableName}
              >
                {!isSupplierPortal && <Option value="privateDatabase">{t(PRIVATE_DATABASE)}</Option>}
                {getUserPermission('FavoritesOnly') !== 'Yes' &&
                  (this.state.productCatalogs ?? []).map((each) => (
                    <Option key={each.catalogTableName} value={each.catalogTableName}>
                      {each.longName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            {isPrivateDatabase && (
              <Form.Item label={t('productCategory')}>
                <SelectProductCategories
                  value={filterDto.categoryIds}
                  placeholder={t('all')}
                  onChange={(value) => this.setFilterValue('categoryIds', value)}
                  dataSource={this.props.productCategories ?? []}
                  disabled={filterDto.noAssignedProductCategories}
                />
              </Form.Item>
            )}
            {isPrivateDatabase && (
              <Form.Item>
                <Checkbox
                  onChange={(e) => this.setFilterValue('noAssignedProductCategories', e.target.checked)}
                  checked={filterDto.noAssignedProductCategories}
                >
                  {t('onlyProductsWithNoAssignedProductCategories')}
                </Checkbox>
              </Form.Item>
            )}
          </Col>
          {isPrivateDatabase && (
            <Col fourth>
              {isTrms && (
                <Form.Item label={t('preferredSupplier')}>
                  <Select
                    onChange={(value) => this.setFilterValue('preferredSupplierIds', value)}
                    mode="multiple"
                    placeholder={t('all')}
                    value={filterDto.preferredSupplierIds}
                    dropdownRender={createDropdownRender(suppliers)}
                    onSearch={this.searchSuppliers}
                    showSearch
                  >
                    <Option value={0}>{t('notAssigned')}</Option>
                    {(suppliers?.items ?? [])
                      .filter((each) => each.id)
                      .map((each) => (
                        <Option key={each.id} value={each.id}>
                          <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              {!userIsDcribCustomer && (
                <Form.Item label={t('status')}>
                  <Select
                    onChange={(value) => this.setFilterValue('active', value || 'Active')}
                    value={filterDto.active || 'Active'}
                  >
                    {Object.entries(statusOptions).map(([key, value]) => (
                      <Option key={key} value={key}>
                        {t(value)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
          )}
          {isPrivateDatabase && (
            <Col fourth>
              <Form.Item label={t('linkedCatalog')}>
                <Select
                  onChange={(value) => this.setFilterValue('linkedCatalogTableName', value)}
                  value={filterDto.linkedCatalogTableName}
                  placeholder={t('all')}
                >
                  {(this.state.productCatalogs ?? []).map((each) => (
                    <Option key={each.catalogTableName} value={each.catalogTableName}>
                      {each.longName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {!isEmpty(filterDto.linkedCatalogTableName) && (
                <Form.Item label={HARD_SPACE}>
                  <Row>
                    <Col xs={12} className="whitespace-nowrap">
                      <Checkbox
                        onChange={(e) =>
                          this.setFilterValue('linkedCatalogDifferencesOnly', e.target.checked)
                        }
                        checked={filterDto.linkedCatalogDifferencesOnly}
                      >
                        {t('linkedCatalogDifferencesOnly')}
                      </Checkbox>
                      <Help title={t('linkedCatalogDifferencesOnlyInfo')} />
                    </Col>
                    <Col xs={12}>
                      <Button
                        type="primary"
                        onClick={this.handleRefreshCatalogItemsClick}
                        loading={this.state.refreshCatalogItemsLoading}
                        block
                      >
                        {t('update')}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              )}
            </Col>
          )}
          <Col fourth>
            {isPrivateDatabase && (
              <Form.Item>
                {getUserPermission('FavoritesOnly') === 'Yes' ? (
                  <Checkbox checked disabled>
                    {t('favorites')}
                  </Checkbox>
                ) : (
                  <Checkbox
                    onChange={(e) => this.setFilterValue('selectFavorites', e.target.checked)}
                    checked={filterDto.selectFavorites}
                  >
                    {t('favorites')}
                  </Checkbox>
                )}
              </Form.Item>
            )}
            {isPrivateDatabase && (
              <Form.Item>
                <Checkbox
                  onChange={(e) => this.setFilterValue('selectNonInventory', e.target.checked)}
                  checked={filterDto.selectNonInventory}
                >
                  {t('nonInventoryItem')}
                </Checkbox>
              </Form.Item>
            )}
            {isPrivateDatabase && (
              <Form.Item>
                <Checkbox
                  onChange={(e) => this.setFilterValue('selectCertificationRequired', e.target.checked)}
                  checked={filterDto.selectCertificationRequired}
                >
                  {t('certificationRequired')}
                </Checkbox>
              </Form.Item>
            )}
            <Form.Item label={isPrivateDatabase ? '' : HARD_SPACE}>
              <Checkbox
                onChange={(e) => this.setFilterValue('showImages', e.target.checked)}
                checked={filterDto.showImages}
              >
                {t('showImages')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
