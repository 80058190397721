export const FORM_SUBMIT_EVENT = 'FORM_SUBMIT_EVENT'
export const FORM_CHANGE_EVENT = 'FORM_CHANGE_EVENT'
export const FORM_INSTANCES_REFRESH = 'FORM_INSTANCES_REFRESH'
export const DASHBOARD_FILTER_OPEN = 'DASHBOARD_FILTER_OPEN'
export const DASHBOARD_FILTER_SAVING = 'DASHBOARD_FILTER_SAVING'
export const DASHBOARD_FETCH_TEMPLATE = 'DASHBOARD_FETCH_TEMPLATE'
export const DASHBOARD_TEMPLATE_FETCHED = 'DASHBOARD_TEMPLATE_FETCHED'
export const DASHBOARD_REFRESH_DATA = 'DASHBOARD_REFRESH_DATA'
export const DASHBOARD_CHART_FILTER_OPEN = 'DASHBOARD_CHART_FILTER_OPEN'
export const LIST_MODAL_CLEAR_SELECTION = 'LIST_MODAL_CLEAR_SELECTION'
export const DEFER_FORBIDDEN_ERROR_WITH = 'DEFER_FORBIDDEN_ERROR_WITH'
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS'
export const DOWNLOAD_PROGRESS = 'DOWNLOAD_PROGRESS'
export const TOKEN_REFRESHED_BY_FORCE = 'TOKEN_REFRESHED_BY_FORCE'
export const SIDE_FORM_ADD_FROM_MODAL_CLOSE = 'SIDE_FORM_ADD_FROM_MODAL_CLOSE'
