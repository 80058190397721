import produce from 'immer'
import { baseReportUrl } from 'config'
import { createActions, normalizeDateRange } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default createActions(entityNames.jobCostReport, {
  basePath: 'report/jobReports',
  baseUrl: baseReportUrl,
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.locationVendingTypes = ['NonVending']

      normalizeDateRange(draft, 'JobCreateDate')

      console.assert(draft.dateRangeField, 'dateRangeField is undefined')

      draft.dateSelectionType = draft.dateRangeField
    }),
})
