import { Input } from 'antd'
import cx from 'clsx'
import { min } from 'lodash'
import entityNames from 'options/entityNames'
import { createChildListPrintComponent } from 'factories/ChildListPrint'
import InputNumber from 'elements/InputNumber'

const getBestPrice = ({ record, suppliers = [] }) => {
  const supplierIds = suppliers.map((each) => each.supplierId)

  return min(
    (record?.supplierDetails ?? []).filter((x) => supplierIds.includes(x.supplierId) && x.price > 0).map((y) => y.price)
  )
}

const getBestSupplierIds = ({ suppliers = [], items = [] }) => {
  const totals = suppliers.map((supplier) =>
    items
      .map((each) => each.supplierDetails.find((one) => one.supplierId === supplier.supplierId))
      .map((each) => each?.price ?? 0)
      .reduce((acc, each) => acc + each, 0)
  )

  const best = min(totals.filter((each) => each > 0))

  return totals.map((each, index) => (each === best ? suppliers[index]?.supplierId : null)).filter(Boolean)
}

export default createChildListPrintComponent({
  entityName: entityNames.rfqs,
  childName: 'rfqItems',
  tableCellComponents: {
    quantity: (self, item) => (
      <InputNumber value={item.quantity} min={1} disabled={self.props.userIsInRoleSupplier} readOnly />
    ),
  },
  tableHeadGroups: (self) => {
    const suppliers = (self.props.parentRecord?.rfqSupplierItems ?? []).filter((each) => each.displaySupplier)

    return {
      price: () =>
        suppliers.map((each) => ({
          title: each.supplierName,
          key: `supplierDetails[${each.supplierPosition}].price`,
          dataIndex: `supplierDetails[${each.supplierPosition}].price`,
          width: 126,
          render: (_, record, index) => {
            try {
              const { items = [] } = self.props

              const { price, supplierId } = items
                .find((one) => one.id === record.id)
                .supplierDetails.find((one) => one.supplierId === each.supplierId)

              return (
                <div
                  className={cx({
                    'table-cell-success':
                      !self.props.userIsInRoleSupplier && getBestSupplierIds({ suppliers, items }).includes(supplierId),
                  })}
                >
                  <InputNumber
                    className={cx({
                      'form-field-success':
                        !self.props.userIsInRoleSupplier && getBestPrice({ record, suppliers }) === price,
                    })}
                    value={price}
                    min={0}
                    readOnly
                    currency
                  />
                </div>
              )
            } catch (error) {
              console.warn(error)

              return <Input disabled />
            }
          },
        })),
    }
  },
})()
