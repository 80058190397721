import * as React from 'react'
import { useImmer } from 'use-immer'
import styled from 'styled-components'
import { Spin, message } from 'antd'
import { withRouter } from 'react-router-dom'
import { toString as str, isEmpty, set } from 'lodash'
import { setStorageItem, getStorageItem } from 'helpers/localStorage'
import startupOptions from 'options/startupOptions'
import { STARTUP_PAGE_STORAGE_KEY } from 'options/auth'
import { getUserPermission, getLogoutUrl, logout } from 'helpers/auth'
import linkTargets from 'options/linkTargets'
import Modal from 'elements/Modal'
import { t } from 'helpers/i18n'
import { getTheme } from 'helpers/themes'
import { TOKEN_REFRESHED_BY_FORCE } from 'options/events'
import { Emitter } from 'helpers/events'
import Select, { Option } from 'elements/Select'
import Page from 'elements/Page'
import Drawer from 'elements/Drawer'

const theme = getTheme()

const Container = styled(Page)`
  background-color: rgb(31, 31, 31);

  .ant-layout {
    background-color: rgb(31, 31, 31);

    .tofino-layout__content {
      background-color: rgb(31, 31, 31);
    }
  }
`

const Contents = styled.div`
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: row;
`

const Left = styled.div`
  width: 40%;
  color: #fbfaf5;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`

const Right = styled.div`
  width: 60%;

  @media screen and (max-width: 992px) {
    display: none;
  }

  .welcome {
    height: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 24px 1fr 24px 1fr 24px 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: 1fr 24px 1fr 24px 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 24px 24px;
    grid-auto-flow: row;
    grid-template-areas:
      'orders orders assets reports'
      'orders orders jobs jobs'
      'inventory inventory jobs jobs';

    & > div {
      color: #fbfaf5;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
      border-radius: 3px;
      background: ${theme.welcome.primaryColor};
      background: linear-gradient(
        45deg,
        ${theme.welcome.primaryColor} 0%,
        ${theme.welcome.secondaryColor} 30%,
        ${theme.welcome.primaryColor} 100%
      );
      padding: 0 96px;
      cursor: pointer;
      font-size: 18px;

      @media screen and (max-width: 1600px) {
        padding: 0 48px;
        font-size: 16px;
      }
    }

    &__jobs {
      -ms-grid-row: 3;
      -ms-grid-row-span: 3;
      -ms-grid-column: 5;
      -ms-grid-column-span: 3;
      grid-area: jobs;
    }

    &__reports {
      -ms-grid-row: 1;
      -ms-grid-column: 7;
      grid-area: reports;
      padding: 0 12px !important;
    }

    &__assets {
      -ms-grid-row: 1;
      -ms-grid-column: 5;
      grid-area: assets;
      padding: 0 12px !important;
    }

    &__inventory {
      -ms-grid-row: 5;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      grid-area: inventory;
    }

    &__orders {
      -ms-grid-row: 1;
      -ms-grid-row-span: 3;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      grid-area: orders;
    }
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 380px;
  margin: 12px 0;

  .ant-select,
  .ant-select-opened,
  .ant-select-focused,
  .ant-select-selection {
    box-shadow: none;
    background-color: rgb(31, 31, 31);
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #bbb;

    .ant-select-selection__rendered {
      color: #bbb;
    }

    .anticon {
      color: #bbb !important;
    }
  }
`

const JobRequestForm = linkTargets.jobRequestRecord.formComponent

function Component(props) {
  const [state, updateState] = useImmer({
    timestamp: Date.now(),
    startupPage: getStorageItem(STARTUP_PAGE_STORAGE_KEY, startupOptions.showThisPage),
  })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  function handleJobRequestClose() {
    Modal.confirm({
      autoFocusButton: 'ok',
      title: t('confirmLogout'),
      content: t('unsavedChangesWillBeLost'),
      okText: t('logout'),
      okType: 'danger',
      cancelText: t('cancel'),
      onOk: () => {
        logout().finally(() => {
          sessionStorage.clear()
          window.location.href = getLogoutUrl({ props })
        })
      },
    })
  }

  function handleJobRequestSaveAndClose() {
    setState('jobRequestLoading', true)

    window.setTimeout(() => {
      updateState((draft) => {
        draft.jobRequestLoading = false
        draft.jobRequestSaving = false
      })
    }, 1000)
  }

  function handleBoxClick(key) {
    switch (key) {
      case 'jobs':
        if (getUserPermission('Jobs') !== 'None') {
          props.history.push('/jobs/jobs')
        } else {
          message.error(t('notAuthorizedForFeature'))
        }
        break

      case 'reports':
        if (getUserPermission('Reports') !== 'No') {
          props.history.push('/reports/schedule')
        } else {
          message.error(t('notAuthorizedForFeature'))
        }
        break

      case 'assets':
        if (getUserPermission('Assets') !== 'None' && props.customer.moduleSettings.enableAssetManagement) {
          props.history.push('/assets/assets')
        } else {
          message.error(t('notAuthorizedForFeature'))
        }
        break

      case 'inventory':
        if (getUserPermission('Inventory') !== 'None' && props.customer.moduleSettings.enableInventory) {
          props.history.push('/inventory/inventory')
        } else {
          message.error(t('notAuthorizedForFeature'))
        }
        break

      case 'orders':
        if (getUserPermission('Orders') !== 'None') {
          props.history.push('/orders/orders')
        } else {
          message.error(t('notAuthorizedForFeature'))
        }
        break

      default:
        message.warn(t('underDevelopment'))
        break
    }
  }

  React.useEffect(() => {
    setStorageItem(STARTUP_PAGE_STORAGE_KEY, str(state.startupPage))
  }, [state.startupPage])

  React.useEffect(() => {
    function handleTokenRefreshedByForce() {
      updateState((draft) => {
        draft.timestamp = Date.now()
      })
    }

    Emitter.on(TOKEN_REFRESHED_BY_FORCE, handleTokenRefreshedByForce)

    return () => Emitter.off(TOKEN_REFRESHED_BY_FORCE, handleTokenRefreshedByForce)
  }, [])

  return (
    <>
      <Container title={t('welcome')}>
        <Contents key={`${state.timestamp}_contents`}>
          <Left>
            <div
              dangerouslySetInnerHTML={{ __html: t('welcomeLine1') }}
              style={{ fontSize: '48px', letterSpacing: '3px' }}
            />
            <div
              dangerouslySetInnerHTML={{ __html: t('welcomeLine2') }}
              style={{ fontSize: '22px', lineHeight: '44px', letterSpacing: '1px' }}
            />
            <div
              dangerouslySetInnerHTML={{ __html: t('welcomeLine3') }}
              style={{ fontSize: '28px', lineHeight: '65px', letterSpacing: '1px' }}
            />
          </Left>
          <Right>
            <div className="welcome">
              {['jobs', 'reports', 'assets', 'inventory', 'orders'].map((each) => (
                <div key={each} className={`welcome__${each}`} onClick={() => handleBoxClick(each)}>
                  <div>
                    <img style={{ width: '64px', height: '64px' }} src={`/welcome_${each}.png`} alt="" />
                  </div>
                  <div>
                    <big>
                      <strong>{t(each)}</strong>
                    </big>
                  </div>
                  <div>{t(`welcome${each}Description`)}</div>
                </div>
              ))}
            </div>
          </Right>
        </Contents>
        {!isEmpty(props.menuItems) && (
          <Footer key={`${state.timestamp}_footer`}>
            <div style={{ color: '#bbb', marginRight: '12px', lineHeight: '27px' }}>{t('startupPage')}</div>
            <div style={{ flex: 1 }}>
              <Select
                value={str(state.startupPage) || startupOptions.showThisPage}
                onChange={(value) => setState('startupPage', value)}
                showSearch={false}
                allowClear={false}
              >
                {Object.entries(startupOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </div>
          </Footer>
        )}
      </Container>
      {getUserPermission('AllowCreateJobRequestOnly') === 'Yes' && (
        <Drawer
          title={t('createJobRequest')}
          size={linkTargets.jobRequestRecord.formSize}
          saving={state.jobRequestSaving}
          onClose={handleJobRequestClose}
          visible
        >
          {state.jobRequestLoading ? (
            <Spin />
          ) : (
            <JobRequestForm
              onSave={(pending) => setState('jobRequestSaving', pending)}
              onSaveAndClose={handleJobRequestSaveAndClose}
              onCancel={handleJobRequestClose}
            />
          )}
        </Drawer>
      )}
    </>
  )
}

export default withRouter(Component)
