import produce from 'immer'
import { isEmpty } from 'lodash'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default createActions(entityNames.productCategories, {
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.alwaysIncludeProductCategoryIds =
        draft.alwaysIncludeProductCategoryIds === false ? false : !isEmpty(draft.productCategoryIds)
    }),
  transformGetOptionsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.alwaysIncludeProductCategoryIds =
        draft.alwaysIncludeProductCategoryIds === false ? false : !isEmpty(draft.productCategoryIds)
      draft.active = draft.active ?? 'Active'
    }),
})
