import { Button, Tooltip } from 'antd'
import { isEmpty } from 'lodash'
import produce from 'immer'
import { createChildListViewComponent } from 'factories/ChildListView'
import entityNames from 'options/entityNames'
import { showError } from 'helpers/errors'
import { t } from 'helpers/i18n'
import { isReadOnly } from 'helpers/formViews'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import Checkbox from 'elements/Checkbox'

export default createChildListViewComponent({
  entityName: entityNames.orders,
  childName: 'orderSuppliers',
  allowCustomize: (self) => true,
  allowCreate: (self) => false,
  allowDelete: (self) => false,
  allowSelection: (self) => false,
  primaryLinkTarget: 'orderSupplierRecord',
  tableCellComponents: {
    purchaseOrderNumber: (self, item) => {
      if (!isEmpty(item.purchaseOrderNumber)) {
        return item.purchaseOrderNumber
      }

      const { placeHolderTooltipLanguageKey, placeHolderLanguageKey } =
        (self.props.parentRecord?.purchaseOrderNumbers ?? []).find(
          (one) => one.supplierId === item.supplierId
        ) || {}

      return placeHolderTooltipLanguageKey ? (
        <Tooltip title={t(placeHolderTooltipLanguageKey)}>
          <span style={{ fontStyle: 'italic', color: '#999' }}>{t(placeHolderLanguageKey)}</span>
        </Tooltip>
      ) : placeHolderLanguageKey ? (
        <span style={{ fontStyle: 'italic', color: '#999' }}>{t(placeHolderLanguageKey)}</span>
      ) : null
    },
    hold: (self, item) => (
      <Checkbox
        checked={item.hold}
        onChange={async (e) => {
          try {
            await self.props.onChange(
              produce(self.props.items, (draft) => {
                draft.find((one) => one.id === item.id).hold = e.target.checked
              })
            )
          } catch (error) {
            console.warn(error)
          }
        }}
        disabled={isReadOnly(self)}
      />
    ),
    previewPo: (self, item) => (
      <Button
        type="link"
        onClick={async () => {
          try {
            const response = await self.props.previewPurchaseOrder({
              targetTenantId: self.props.targetTenantId,
              supplierId: item.supplierId,
              order: {
                ...self.props.parentRecord,
                orderItems: undefined,
                orderSupplierItems: undefined,
                taxRate: self.props.parentRecord.taxRate,
              },
              orderItems: self.props.parentRecord.orderItems.map((each) => ({
                ...each,
                id: each.id > 0 ? each.id : 0,
                orderItemId: each.orderItemId > 0 ? each.orderItemId : 0,
              })),
              orderSuppliers: self.props.parentRecord.orderSupplierItems.map((each) => ({
                ...each,
                id: each.id > 0 ? each.id : 0,
                orderSupplierId: each.orderSupplierId.id > 0 ? each.orderSupplierId.id : 0,
              })),
            })

            self.setState({
              previewPoVisible: true,
              previewPoContents: response.value.data,
            })
          } catch (error) {
            showError({ error })
          }
        }}
      >
        <Icon type="Search" />
      </Button>
    ),
  },
  sharedComponents: (self) => (
    <Modal
      title={t('previewPO')}
      visible={self.state.previewPoVisible}
      okText={t('print')}
      onOk={() => {
        const win = window.open(
          '',
          'print',
          'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=992,height=680'
        )
        win.document.body.innerHTML = self.state.previewPoContents
        win.print()
      }}
      onCancel={() =>
        self.setState({
          previewPoVisible: false,
          previewPoContents: null,
        })
      }
      width={992}
    >
      <iframe
        style={{ width: '100%', height: '100vh', border: 'none' }}
        title={t('previewPO')}
        frameBorder={0}
        srcDoc={self.state.previewPoContents}
      />
    </Modal>
  ),
})()
