import { connect } from 'react-redux'
import styled from 'styled-components'
import { Dropdown, Menu, Badge } from 'antd'
import { isNil, trim, isEmpty } from 'lodash'
import { useWindowSize } from 'helpers/hooks'
import { createAuthStateToProps, getHamburgerSelectedKeys } from 'helpers/auth'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'

const Container = styled.div`
  padding: 10px 20px;
  text-align: right;

  .ant-badge-dot,
  .ant-badge-count {
    background-color: #1890ff;
  }
`

const BadgeContainer = styled.span`
  color: #bbb;
  cursor: pointer;
`

const Overlay = styled(Menu)`
  background-color: white;
  min-width: 200px;

  .ant-badge-dot {
    background-color: #1890ff;
  }
`

function Component(props) {
  const { menuItems, impersonator, notifications = [] } = props

  const windowSize = useWindowSize()
  const showNotifications = !isEmpty(notifications)

  return (
    <Container>
      <Dropdown
        overlay={
          <Overlay
            selectedKeys={[...getHamburgerSelectedKeys(), trim(window.location.pathname, '/')]}
            onClick={props.onClick}
            selectable={false}
          >
            {windowSize.width < 768 && [
              ...menuItems.map((each) => <Menu.Item key={each.key}>{t(each.languageKey)}</Menu.Item>),
              <Menu.Divider key="divider1" />,
            ]}
            {windowSize.width < 992 &&
              [
                impersonator && (
                  <Menu.Item key="existAsCustomer">
                    <Icon type="ExitToApp" /> {t('logBackIn')}
                  </Menu.Item>
                ),
                <Menu.Item key="customerSettings" disabled={props.customerSettingsDisabled}>
                  <Icon type="Store" /> {props.customerDisplayName}
                </Menu.Item>,
                <Menu.Item key="userSettings">
                  <Icon type="Person" /> {props.userDisplayName}
                </Menu.Item>,
                <Menu.Divider key="divider2" />,
              ].filter((each) => !isNil(each))}
            {showNotifications && [
              <Menu.Item key="support/releaseNotes">
                <Icon type="Notifications" /> {t('releaseNotes')} <Badge dot />
              </Menu.Item>,
              <Menu.Divider key="divider3" />,
            ]}
            <Menu.Item key="logout">
              <Icon type="PowerSettingsNew" /> {t('logout')}
            </Menu.Item>
          </Overlay>
        }
        trigger={['click']}
      >
        <BadgeContainer>
          {showNotifications ? (
            <Badge dot>
              <Icon type="Menu" size={32} />
            </Badge>
          ) : (
            <Icon type="Menu" size={32} />
          )}
        </BadgeContainer>
      </Dropdown>
    </Container>
  )
}

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

export default connect(mapStateToProps, null)(Component)
