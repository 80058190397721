import * as React from 'react'
import { Form } from 'antd'
import { range } from 'lodash'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { t } from 'helpers/i18n'
import { createGetFieldLabel } from 'helpers/dashboards'
import approvalStatusOptions from 'options/jobs/approvalStatus'
import overdueStatusOptions from 'options/jobs/overdueStatus'
import { dateRangeFields } from 'options/jobs/dateRangeFields'
import { isReadOnly } from 'helpers/formViews'
import Select, { Option, getOptionProps } from 'elements/Select'
import Help from 'elements/Help'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      const { jobReasonGroupIds } = this.props.filterDto

      await Promise.all([
        this.props.getJobGroups(),
        this.props.getJobReasons({ jobReasonGroupIds }),
        this.props.getJobReasonGroups(),
        this.props.getJobStatus(),
      ])
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { fields = [], filterDto = {} } = this.props

    const getFieldLabel = createGetFieldLabel(fields)
    const readOnly = isReadOnly(this)

    return (
      <>
        <Form.Item
          label={
            <span>
              {t('dateType')} <Help title={t('dateTypeInfo')} />
            </span>
          }
        >
          <Select
            value={filterDto.dateRangeField}
            onChange={(value) => this.setFilterValue('dateRangeField', value)}
            placeholder={t('createdDate')}
            readOnly={readOnly}
          >
            {Object.entries(dateRangeFields).map(([key, value]) => (
              <Option key={key} value={key}>
                {t(value)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {fields.find((one) => one.type === 'jobGroupIds') && (
          <Form.Item label={getFieldLabel('jobGroupIds')}>
            <Select
              value={filterDto.jobGroupIds}
              onChange={(value) => this.setFilterValue('jobGroupIds', value)}
              placeholder={t('all')}
              mode="multiple"
              readOnly={readOnly}
            >
              {(this.props.jobGroups ?? []).map((each) => (
                <Option key={each.id} value={each.id}>
                  <span {...getOptionProps(each)}>{each.displayName}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'jobReasonGroupIds') && (
          <Form.Item label={getFieldLabel('jobReasonGroupIds')}>
            <Select
              value={filterDto.jobReasonGroupIds}
              onChange={(value) => this.setFilterValue('jobReasonGroupIds', value)}
              placeholder={t('all')}
              mode="multiple"
              readOnly={readOnly}
            >
              {(this.props.jobReasonGroups ?? []).map((each) => (
                <Option key={each.id} value={each.id}>
                  <span {...getOptionProps(each)}>{each.displayName}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'jobReasonIds') && (
          <Form.Item label={getFieldLabel('jobReasonIds')}>
            <Select
              value={filterDto.jobReasonIds}
              onChange={(value) => this.setFilterValue('jobReasonIds', value)}
              placeholder={t('all')}
              mode="multiple"
              readOnly={readOnly}
            >
              {(this.props.jobReasons ?? []).map((each) => (
                <Option key={each.id} value={each.id}>
                  <span {...getOptionProps(each)}>{each.displayName}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'jobStatusOptionIds') && (
          <Form.Item label={getFieldLabel('jobStatusOptionIds')}>
            <Select
              value={filterDto.jobStatusOptionIds}
              onChange={(value) => this.setFilterValue('jobStatusOptionIds', value)}
              placeholder={t('all')}
              mode="multiple"
              readOnly={readOnly}
              allowClear
            >
              {(this.props.jobStatusOptions ?? []).map((each) => (
                <Option key={each.id} value={each.id}>
                  <span {...getOptionProps(each)}>{each.displayName}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'jobApprovalStatus') && (
          <Form.Item label={getFieldLabel('jobApprovalStatus')}>
            <Select
              value={filterDto.jobApprovalStatus}
              onChange={(value) => this.setFilterValue('jobApprovalStatus', value)}
              placeholder={t('all')}
              readOnly={readOnly}
            >
              {Object.entries(approvalStatusOptions).map(([key, value]) => (
                <Option key={key} value={key}>
                  {t(value)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'jobOverdueStatus') && (
          <Form.Item label={getFieldLabel('jobOverdueStatus')}>
            <Select
              value={filterDto.jobOverdueStatus}
              onChange={(value) => this.setFilterValue('jobOverdueStatus', value)}
              placeholder={t('all')}
              readOnly={readOnly}
            >
              {Object.entries(overdueStatusOptions).map(([key, value]) => (
                <Option key={key} value={key}>
                  {t(value)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'jobPriority') && (
          <Form.Item label={getFieldLabel('jobPriority')}>
            <Select
              value={filterDto.jobPriority}
              onChange={(value) => this.setFilterValue('jobPriority', value)}
              placeholder={t('all')}
              readOnly={readOnly}
              allowClear
            >
              {range(1, 7).map((each) => (
                <Option key={each} value={each}>
                  {each}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </>
    )
  }
}

export default Form.create()(Filter)
