import { connect } from 'react-redux'
import actions from 'actions/externalApprovals'
import Component from 'components/ExternalApproval'

const mapDispatchToProps = {
  getItem: actions.getItem,
  approveItem: actions.approveItem,
  rejectItem: actions.rejectItem,
}

export default connect(null, mapDispatchToProps)(Component)
