import { memoize } from 'lodash'
import { Switch, Redirect, Route } from 'react-router-dom'
import { Restricted } from 'helpers/auth'
import NotFound from 'components/NotFound'
import UnderDevelopment from 'components/UnderDevelopment'
// NOTE: Please make no changes above this line.
import AdditionalResources from 'containers/AdditionalResources'
import AssetAssociatedInventoryReport from 'containers/Reports/AssetAssociatedInventoryReport'
import AssetCategories from 'containers/AssetCategories'
import AssetCostHistoryReport from 'containers/Reports/AssetCostHistoryReport'
import AssetDowntimeDetailReport from 'containers/Reports/AssetDowntimeDetailReport'
import AssetDowntimeSummaryReport from 'containers/Reports/AssetDowntimeSummaryReport'
import AssetProductAssetsReport from 'containers/Reports/AssetProductAssetsReport'
import AssetRentalReport from 'containers/Reports/AssetRentalReport'
import AssetToleranceReport from 'containers/Reports/AssetToleranceReport'
import AssetTransferReport from 'containers/Reports/AssetTransferReport'
import AssetUtilizationReport from 'containers/Reports/AssetUtilizationReport'
import AssetWarrantyExpiryReport from 'containers/Reports/AssetWarrantyExpiryReport'
import Assets from 'containers/Assets'
import CardinalReport from 'containers/Reports/CardinalReport'
import CbVmiReport from 'containers/Reports/CbVmiReport'
import Contact from 'containers/Contact'
import CustomMappings from 'containers/CustomMappings'
import CustomerDetails from 'containers/CustomerDetails'
import CustomerGroups from 'containers/CustomerGroups'
import Customers from 'containers/Customers'
import CycleCounts from 'containers/CycleCounts'
import DashboardTemplates from 'containers/DashboardTemplates'
import DashboardThemes from 'containers/DashboardThemes'
import DashboardViewer from 'containers/DashboardViewer'
import DataTransferAuditReport from 'containers/Reports/DataTransferAuditReport'
import Documents from 'containers/Documents'
import ExcessInventoryReport from 'containers/Reports/ExcessInventoryReport'
import FastenalSafetyReport from 'containers/Reports/FastenalSafetyReport'
import FileTemplates from 'containers/FileTemplates'
import FormBatches from 'containers/FormBatches'
import FormDataReport from 'containers/Reports/FormDataReport'
import FormGroups from 'containers/FormGroups'
import FormInstances from 'containers/FormInstances'
import FormSubmissionReport from 'containers/Reports/FormSubmissionReport'
import FormTemplates from 'containers/FormTemplates'
import FutureJobs from 'containers/Jobs/Future'
import Inventory from 'containers/Inventory'
import InventoryCycleCountDifferenceReport from 'containers/Reports/InventoryCycleCountDifferenceReport'
import InventoryCycleCountFrequencyReport from 'containers/Reports/InventoryCycleCountFrequencyReport'
import InventoryDeficiencyReport from 'containers/Reports/InventoryDeficiencyReport'
import InventoryIssueStatusReport from 'containers/Reports/InventoryIssueStatusReport'
import InventoryLastActivityReport from 'containers/Reports/InventoryLastActivityReport'
import InventoryLastUsedReport from 'containers/Reports/InventoryLastUsedReport'
import InventoryLocationReport from 'containers/Reports/InventoryLocationReport'
import InventoryMinMaxReport from 'containers/Reports/InventoryMinMaxReport'
import InventoryMonthlyChangeReport from 'containers/Reports/InventoryMonthlyChangeReport'
import InventoryOnHandExceptionReport from 'containers/Reports/InventoryOnHandExceptionReport'
import InventoryReceivingAuditReport from 'containers/Reports/InventoryReceivingAuditReport'
import InventoryReceivingDetailReport from 'containers/Reports/InventoryReceivingDetailReport'
import InventoryReturnedItemsReport from 'containers/Reports/InventoryReturnedItemsReport'
import InventoryTransfer from 'containers/InventoryTransfer'
import InventoryTransferReport from 'containers/Reports/InventoryTransferReport'
import InventoryUsageReport from 'containers/Reports/InventoryUsageReport'
import Issue from 'containers/Issue'
import IssueFromPickList from 'containers/IssueFromPickList'
import IssueLimitLists from 'containers/IssueLimitLists'
import ItemHistoryReport from 'containers/Reports/ItemHistoryReport'
import JobAuditReport from 'containers/Reports/JobAuditReport'
import JobCalendar from 'containers/Jobs/CalendarView'
import JobCompletionDetailReport from 'containers/Reports/JobCompletionDetailReport'
import JobCompletionSummaryReport from 'containers/Reports/JobCompletionSummaryReport'
import JobCostReport from 'containers/Reports/JobCostReport'
import JobGroups from 'containers/JobGroups'
import JobLabourRates from 'containers/JobLabourRates'
import JobProgressReport from 'containers/Reports/JobProgressReport'
import JobReasonGroups from 'containers/JobReasonGroups'
import JobReasonReport from 'containers/Reports/JobReasonReport'
import JobReasons from 'containers/JobReasons'
import JobRequests from 'containers/JobRequests'
import JobSchedule from 'containers/JobSchedule'
import JobStatusOptions from 'containers/JobStatusOptions'
import JobTemplates from 'containers/JobTemplates'
import JobUsageReport from 'containers/Reports/JobUsageReport'
import Jobs from 'containers/Jobs'
import LabelTemplates from 'containers/LabelTemplates'
import Lists from 'containers/Lists'
import LocationCounts from 'containers/LocationCounts'
import LocationGroups from 'containers/LocationGroups'
import LocationOrderReport from 'containers/Reports/LocationOrderReport'
import Locations from 'containers/Locations'
import Login from 'containers/Login'
import MonthlyDetailReport from 'containers/Reports/MonthlyDetailReport'
import Notifications from 'containers/Notifications'
import OnTimeDeliveryReport from 'containers/Reports/OnTimeDeliveryReport'
import OpenOrdersReport from 'containers/Reports/OpenOrdersReport'
import OperatorGroups from 'containers/OperatorGroups'
import Operators from 'containers/Operators'
import OrderAuditReport from 'containers/Reports/OrderAuditReport'
import OrderCalendar from 'containers/Orders/CalendarView'
import OrderFrequencyReport from 'containers/Reports/OrderFrequencyReport'
import Orders from 'containers/Orders'
import OverstockInventory from 'containers/OverstockInventory'
import OverstockInventoryCycleCountDifferenceReport from 'containers/Reports/OverstockInventoryCycleCountDifferenceReport'
import OverstockLocations from 'containers/OverstockLocations'
import PriceSavingsReport from 'containers/Reports/PriceSavingsReport'
import PrintLabels from 'containers/PrintLabels'
import ProductCategories from 'containers/ProductCategories'
import Products from 'containers/Products'
import Profiles from 'containers/Profiles'
import RequisitionAuditReport from 'containers/Reports/RequisitionAuditReport'
import RequisitionCalendar from 'containers/Requisitions/CalendarView'
import RequisitionItemsReport from 'containers/Reports/RequisitionItemsReport'
import Requisitions from 'containers/Requisitions'
import ReturnToInventory from 'containers/ReturnToInventory'
import RfqAuditReport from 'containers/Reports/RfqAuditReport'
import Rfqs from 'containers/Rfqs'
import ScheduleReport from 'containers/Reports/Schedule'
import StevensReport from 'containers/Reports/StevensReport'
import SupplierDetails from 'containers/SupplierDetails'
import SupplierIntegration from 'containers/SupplierIntegration'
import Suppliers from 'containers/Suppliers'
import TagLists from 'containers/TagLists'
import Tenants from 'containers/Tenants'
import Tickets from 'containers/Tickets'
import TrackAssets from 'containers/TrackAssets'
import TransactionAuditReport from 'containers/Reports/TransactionAuditReport'
import UnitsOfMeasure from 'containers/UnitsOfMeasure'
import UploadData from 'containers/UploadData'
import UsageDetailsReport from 'containers/Reports/UsageDetailsReport'
import UsageOrderReport from 'containers/Reports/UsageOrderReport'
import UserActionReport from 'containers/Reports/UserActionReport'
import UserGroups from 'containers/UserGroups'
import UserTypes from 'containers/UserTypes'
import Users from 'containers/Users'
import Welcome from 'containers/Welcome'

const UserList = (props) => <Lists type="User" {...props} />
const AssetList = (props) => <Lists type="Asset" {...props} />
const ProductList = (props) => <Lists type="Product" {...props} />

const createRoutes = ({ possibleRoutes = [] }) => (
  <Switch>
    <Redirect from="/" to="/welcome" exact />
    <Route path="/login" component={Login} exact />
    <Restricted path="/welcome" component={Welcome} exact />
    <Restricted path="/orders/orders" component={Orders} exact />
    <Restricted path="/orders/rfqs" component={Rfqs} exact />
    <Restricted path="/orders/requisitions" component={Requisitions} exact />
    <Restricted path="/inventory/inventory" component={Inventory} exact />
    <Restricted path="/inventory/transfer" component={InventoryTransfer} exact />
    <Restricted path="/inventory/cycleCounts" component={CycleCounts} exact />
    <Restricted path="/inventory/locationCounts" component={LocationCounts} exact />
    <Restricted path="/inventory/returnToInventory" component={ReturnToInventory} exact />
    <Restricted path="/inventory/issue" component={Issue} exact />
    <Restricted path="/inventory/IssueFromPickList" component={IssueFromPickList} exact />
    <Restricted path="/jobs/jobs" component={Jobs} exact />
    <Restricted path="/jobs/futureJobs" component={FutureJobs} exact />
    <Restricted path="/jobs/jobTemplates" component={JobTemplates} exact />
    <Restricted path="/jobs/jobSchedule" component={JobSchedule} exact />
    <Restricted path="/support/supportCenter" component={Tickets} exact />
    <Restricted path="/support/releaseNotes" component={Notifications} exact />
    <Restricted path="/support/additionalResources" component={AdditionalResources} exact />
    <Restricted path="/support/contact" component={Contact} exact />
    <Restricted path="/reports/schedule" component={ScheduleReport} exact />
    <Restricted path="/reports/admin/stevens" component={StevensReport} exact />
    <Restricted path="/reports/admin/cardinal" component={CardinalReport} exact />
    <Restricted path="/reports/admin/cbvmi" component={CbVmiReport} exact />
    <Restricted path="/reports/admin/fastenalSafety" component={FastenalSafetyReport} exact />
    <Restricted path="/reports/audit/dataTransfersReport" component={DataTransferAuditReport} exact />
    <Restricted path="/reports/audit/orderAuditReport" component={OrderAuditReport} exact />
    <Restricted path="/reports/audit/requisitionAuditReport" component={RequisitionAuditReport} exact />
    <Restricted path="/reports/audit/rfqAuditReport" component={RfqAuditReport} exact />
    <Restricted path="/reports/audit/transactionsReport" component={TransactionAuditReport} exact />
    <Restricted
      path={['/forms/reports/formSubmissionsReport', '/reports/forms/formSubmissionsReport']}
      component={FormSubmissionReport}
      exact
    />
    <Restricted
      path={['/forms/reports/formDataReport', '/reports/forms/formDataReport']}
      component={FormDataReport}
      exact
    />
    <Restricted
      path={['/people/reports/userActionReport', '/reports/audit/userActionReport', '/reports/people/userActionReport']}
      component={UserActionReport}
      exact
    />
    <Restricted
      path={[
        '/inventory/setup/labelTemplates',
        '/assets/setup/labelTemplates',
        '/products/setup/labelTemplates',
        '/people/setup/labelTemplates',
        '/setup/people/labelTemplates',
        '/setup/labelTemplates',
      ]}
      component={LabelTemplates}
      exact
    />
    <Restricted path={['/jobs/setup/jobGroups', '/setup/jobs/jobGroups']} component={JobGroups} exact />
    <Restricted path={['/jobs/setup/jobReasons', '/setup/jobs/jobReasons']} component={JobReasons} exact />
    <Restricted
      path={['/jobs/setup/jobReasonGroups', '/setup/jobs/jobReasonGroups']}
      component={JobReasonGroups}
      exact
    />
    <Restricted
      path={['/jobs/setup/jobStatusOptions', '/setup/jobs/jobStatusOptions']}
      component={JobStatusOptions}
      exact
    />
    <Restricted path={['/jobs/setup/labourRates', '/setup/jobs/labourRates']} component={JobLabourRates} exact />
    <Restricted path="/jobs/jobRequests" component={JobRequests} exact />
    <Restricted
      path={['/jobs/reports/jobCompletionSummaryReport', '/reports/jobs/jobCompletionSummaryReport']}
      component={JobCompletionSummaryReport}
      exact
    />
    <Restricted
      path={['/jobs/reports/jobCompletionDetailReport', '/reports/jobs/jobCompletionDetailReport']}
      component={JobCompletionDetailReport}
      exact
    />
    <Restricted path={['/jobs/reports/jobCostReport', '/reports/jobs/jobCostReport']} component={JobCostReport} exact />
    <Restricted
      path={['/jobs/reports/jobProgressReport', '/reports/jobs/jobProgressReport']}
      component={JobProgressReport}
      exact
    />
    <Restricted
      path={['/jobs/reports/jobReasonReport', '/reports/jobs/jobReasonReport']}
      component={JobReasonReport}
      exact
    />
    <Restricted
      path={['/jobs/reports/jobUsageReport', '/reports/jobs/jobUsageReport']}
      component={JobUsageReport}
      exact
    />
    <Restricted
      path={['/jobs/reports/jobAuditReport', '/reports/jobs/jobAuditReport', '/reports/audit/jobAuditReport']}
      component={JobAuditReport}
      exact
    />
    <Restricted
      path={[
        '/orders/reports/usageOrderReport',
        '/jobs/reports/usageOrderReport',
        '/reports/orders/usageOrderReport',
        '/reports/jobs/usageOrderReport',
      ]}
      component={UsageOrderReport}
      exact
    />
    <Restricted
      path={['/inventory/reports/usageOrderReport', '/reports/inventory/usageOrderReport']}
      component={UsageDetailsReport}
      exact
    />
    <Restricted
      path={['/orders/reports/onTimeDeliveryReport', '/reports/orders/onTimeDeliveryReport']}
      component={OnTimeDeliveryReport}
      exact
    />
    <Restricted
      path={['/orders/reports/openOrdersReport', '/reports/orders/openOrdersReport']}
      component={OpenOrdersReport}
      exact
    />
    <Restricted
      path={[
        '/orders/reports/orderFrequencyReport',
        '/reports/orders/orderFrequencyReport',
        '/products/reports/orderFrequencyReport',
        '/reports/products/orderFrequencyReport',
      ]}
      component={OrderFrequencyReport}
      exact
    />
    <Restricted
      path={['/orders/reports/priceSavingsReport', '/reports/orders/priceSavingsReport']}
      component={PriceSavingsReport}
      exact
    />
    <Restricted
      path={['/orders/reports/requisitionItemsReport', '/reports/orders/requisitionItemsReport']}
      component={RequisitionItemsReport}
      exact
    />
    <Restricted
      path={['/assets/reports/assetAssociatedInventoryReport', '/reports/assets/assetAssociatedInventoryReport']}
      component={AssetAssociatedInventoryReport}
      exact
    />
    <Restricted path="/assets/assets" component={Assets} exact />
    <Restricted
      path={['/assets/reports/assetCostHistoryReport', '/reports/assets/assetCostHistoryReport']}
      component={AssetCostHistoryReport}
      exact
    />
    <Restricted
      path={['/assets/reports/assetDowntimeDetailReport', '/reports/assets/assetDowntimeDetailReport']}
      component={AssetDowntimeDetailReport}
      exact
    />
    <Restricted
      path={['/assets/reports/assetDowntimeSummaryReport', '/reports/assets/assetDowntimeSummaryReport']}
      component={AssetDowntimeSummaryReport}
      exact
    />
    <Restricted
      path={['/assets/reports/assetRentalReport', '/reports/assets/assetRentalReport']}
      component={AssetRentalReport}
      exact
    />
    <Restricted
      path={['/assets/reports/assetToleranceReport', '/reports/assets/assetToleranceReport']}
      component={AssetToleranceReport}
      exact
    />
    <Restricted
      path={['/assets/reports/assetTransferReport', '/reports/assets/assetTransferReport']}
      component={AssetTransferReport}
      exact
    />
    <Restricted
      path={['/assets/reports/productAssetsReport', '/reports/assets/productAssetsReport']}
      component={AssetProductAssetsReport}
      exact
    />
    <Restricted
      path={['/assets/reports/assetUtilizationReport', '/reports/assets/assetUtilizationReport']}
      component={AssetUtilizationReport}
      exact
    />
    <Restricted
      path={['/assets/reports/warrantyExpiryReport', '/reports/assets/warrantyExpiryReport']}
      component={AssetWarrantyExpiryReport}
      exact
    />
    <Restricted
      path={['/assets/setup/assetCategories', '/setup/assets/assetCategories']}
      component={AssetCategories}
      exact
    />
    <Restricted path="/assets/trackAssets" component={TrackAssets} exact />
    <Restricted path="/products/products" component={Products} exact />
    <Restricted path={['/products/setup/productLists', '/setup/products/productLists']} component={ProductList} exact />
    <Restricted path={['/assets/setup/assetLists', '/setup/assets/assetLists']} component={AssetList} exact />
    <Restricted path={['/people/setup/userLists', '/setup/people/userLists']} component={UserList} exact />
    <Restricted
      path={['/products/setup/productCategories', '/setup/products/productCategories']}
      component={ProductCategories}
      exact
    />
    <Restricted
      path={['/products/setup/unitsOfMeasure', '/setup/products/unitsOfMeasure']}
      component={UnitsOfMeasure}
      exact
    />
    <Restricted path={['/setup/people/profiles', '/people/setup/profiles']} component={Profiles} exact />
    <Restricted path="/people/users" component={Users} exact />
    <Restricted path="/people/operators" component={Operators} exact />
    <Restricted path="/forms/forms" component={FormInstances} exact />
    <Restricted path="/forms/formBatches" component={FormBatches} exact />
    <Restricted path="/forms/formTemplates" component={FormTemplates} exact />
    <Restricted
      path={['/forms/setup/formGroups', '/setup/formGroups', '/setup/forms/formGroups']}
      component={FormGroups}
      exact
    />
    <Restricted
      path={['/orders/reports/locationOrderReport', '/reports/orders/locationOrderReport']}
      component={LocationOrderReport}
      exact
    />
    <Restricted
      path={[
        '/assets/setup/locations',
        '/inventory/setup/locations',
        '/jobs/setup/locations',
        '/orders/setup/locations',
        '/setup/jobs/locations',
        '/setup/locations/locations',
      ]}
      component={Locations}
      exact
    />
    <Restricted path="/setup/locations/locationGroups" component={LocationGroups} exact />
    <Restricted
      path={[
        '/inventory/setup/suppliers',
        '/orders/setup/suppliers',
        '/setup/suppliers/suppliers',
        '/products/setup/suppliers',
      ]}
      component={Suppliers}
      exact
    />
    <Restricted path="/setup/suppliers/integration" component={SupplierIntegration} exact />
    <Restricted
      path={['/orders/setup/tagLists', '/assets/setup/tagLists', '/setup/tagLists']}
      component={TagLists}
      exact
    />
    <Restricted path={['/people/setup/userGroups', '/setup/people/userGroups']} component={UserGroups} exact />
    <Restricted path={['/people/setup/userTypes', '/setup/people/userTypes']} component={UserTypes} exact />
    <Restricted
      path={['/people/setup/operatorGroups', '/setup/people/operatorGroups']}
      component={OperatorGroups}
      exact
    />
    <Restricted
      path={['/inventory/reports/cycleCountDifferenceReport', '/reports/inventory/cycleCountDifferenceReport']}
      component={InventoryCycleCountDifferenceReport}
      exact
    />
    <Restricted
      path={['/inventory/reports/inventoryMinMaxReport', '/reports/inventory/inventoryMinMaxReport']}
      component={InventoryMinMaxReport}
      exact
    />
    <Restricted
      path={['/inventory/reports/cycleCountFrequencyReport', '/reports/inventory/cycleCountFrequencyReport']}
      component={InventoryCycleCountFrequencyReport}
      exact
    />
    <Restricted
      path={[
        '/inventory/reports/receivingAuditReport',
        '/reports/inventory/receivingAuditReport',
        '/orders/reports/receivingAuditReport',
        '/reports/orders/receivingAuditReport',
      ]}
      component={InventoryReceivingAuditReport}
      exact
    />
    <Restricted
      path={[
        '/inventory/reports/receivingDetailReport',
        '/reports/inventory/receivingDetailReport',
        '/orders/reports/receivingDetailReport',
        '/reports/orders/receivingDetailReport',
      ]}
      component={InventoryReceivingDetailReport}
      exact
    />
    <Restricted
      path={[
        '/inventory/reports/monthlyDetailReport',
        '/reports/inventory/monthlyDetailReport',
        '/orders/reports/monthlyDetailReport',
        '/reports/orders/monthlyDetailReport',
      ]}
      component={MonthlyDetailReport}
      exact
    />
    <Restricted
      path={['/inventory/reports/returnedItemsReport', '/reports/inventory/returnedItemsReport']}
      component={InventoryReturnedItemsReport}
      exact
    />
    <Restricted
      path={['/inventory/reports/inventoryTransferReport', '/reports/inventory/inventoryTransferReport']}
      component={InventoryTransferReport}
      exact
    />
    <Restricted
      path={['/inventory/reports/inventoryUsageReport', '/reports/inventory/inventoryUsageReport']}
      component={InventoryUsageReport}
      exact
    />
    <Restricted
      path={['/inventory/reports/inventoryDeficiencyReport', '/reports/inventory/inventoryDeficiencyReport']}
      component={InventoryDeficiencyReport}
      exact
    />
    <Restricted
      path={['/inventory/reports/excessInventoryReport', '/reports/inventory/excessInventoryReport']}
      component={ExcessInventoryReport}
      exact
    />
    <Restricted
      path={['/inventory/reports/unusedInventoryReport', '/reports/inventory/unusedInventoryReport']}
      component={InventoryLastUsedReport}
      exact
    />
    <Restricted
      path={['/inventory/reports/onhandExceptionReport', '/reports/inventory/onhandExceptionReport']}
      component={InventoryOnHandExceptionReport}
      exact
    />
    <Restricted
      path={['/inventory/reports/lastActivityReport', '/reports/inventory/lastActivityReport']}
      component={InventoryLastActivityReport}
      exact
    />
    <Restricted
      path={['/inventory/reports/monthlyInventoryChangeReport', '/reports/inventory/monthlyInventoryChangeReport']}
      component={InventoryMonthlyChangeReport}
      exact
    />
    <Restricted
      path={['/inventory/reports/inventoryByLocationReport', '/reports/inventory/inventoryByLocationReport']}
      component={InventoryLocationReport}
      exact
    />
    <Restricted
      path={[
        '/orders/reports/itemHistoryReport',
        '/inventory/reports/itemHistoryReport',
        '/products/reports/itemHistoryReport',
        '/reports/orders/itemHistoryReport',
        '/reports/inventory/itemHistoryReport',
        '/reports/products/itemHistoryReport',
      ]}
      component={ItemHistoryReport}
      exact
    />
    <Restricted path="/tools/printLabels" component={PrintLabels} exact />
    <Restricted path="/tools/documentLibrary" component={Documents} exact />
    <Restricted path="/tools/dataExchange/fileTemplates" component={FileTemplates} exact />
    <Restricted path="/tools/dataExchange/uploadData" component={UploadData} exact />
    <Restricted path="/tools/dataExchange/customMappings" component={CustomMappings} exact />
    <Restricted path="/customers/tenants" component={Tenants} exact />
    <Restricted path="/customers/customers" component={Customers} exact />
    <Restricted
      path={['/customers/setup/customerGroups', '/setup/customers/customerGroups']}
      component={CustomerGroups}
      exact
    />
    <Restricted path="/dashboards/dashboardViewer" component={DashboardViewer} exact />
    <Restricted path="/dashboards/dashboardTemplates" component={DashboardTemplates} exact />
    <Restricted
      path={['/dashboards/setup/dashboardThemes', '/setup/dashboardThemes']}
      component={DashboardThemes}
      exact
    />
    <Restricted path="/jobs/calendars/jobs" component={JobCalendar} exact />
    <Restricted path="/orders/calendars/requisitions" component={RequisitionCalendar} exact />
    <Restricted path="/orders/calendars/orders" component={OrderCalendar} exact />
    <Restricted
      path={['/inventory/setup/issueLimitLists', '/setup/issueLimitLists']}
      component={IssueLimitLists}
      exact
    />
    <Restricted
      path={['/inventory/reports/inventoryIssueStatus', '/reports/inventory/inventoryIssueStatus']}
      component={InventoryIssueStatusReport}
      exact
    />
    <Restricted path="/inventory/overstockInventory" component={OverstockInventory} exact />
    <Restricted
      path={[
        '/inventory/reports/overstockCycleCountDifferenceReport',
        '/reports/inventory/overstockCycleCountDifferenceReport',
      ]}
      component={OverstockInventoryCycleCountDifferenceReport}
      exact
    />
    <Restricted
      path={['/inventory/setup/overstockLocations', '/setup/locations/overstockLocations']}
      component={OverstockLocations}
      exact
    />
    <Restricted
      path={['/setup/customers/customerDetails', '/customers/setup/customerDetails', '/products/setup/customerDetails']}
      component={CustomerDetails}
      exact
    />
    <Restricted
      path={['/setup/suppliers/supplierDetails', '/products/setup/supplierDetails']}
      component={SupplierDetails}
      exact
    />
    {/* NOTE: Please make no changes below this line. */}
    <Restricted path={possibleRoutes} component={UnderDevelopment} exact />
    <Route path="*" component={NotFound} />
  </Switch>
)

export default memoize(createRoutes, JSON.stringify)
