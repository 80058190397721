import { connect } from 'react-redux'
import authActions from 'actions/auth'
import tenantActions from 'actions/tenants'
import Component from 'components/Login'

const mapDispatchToProps = {
  login: authActions.login,
  getTenantInfo: tenantActions.getTenantInfo,
}

export default connect(null, mapDispatchToProps)(Component)
