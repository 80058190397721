import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import productActions from 'actions/products'
import Component from 'components/Products/Select/Filter'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = { getProductCatalogs: productActions.getCatalogs }

export default connect(mapStateToProps, mapDispatchToProps)(Component)
