import { createChildTablePrintComponent } from 'factories/ChildTablePrint'
import { t } from 'helpers/i18n'
import { formatUserTime } from 'helpers/dateTime'

export default createChildTablePrintComponent({
  tableCellComponents: {
    complete: (self, item) => (item.complete ? t('yes') : ''),
    lastUserDate: (self, item) => formatUserTime(item.lastUserDate, item.lastUser),
  },
})()
