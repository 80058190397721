import { connect } from 'react-redux'
import selectors from 'selectors'
import userActions from 'actions/users'
import operatorActions from 'actions/operators'
import operatorGroupActions from 'actions/operatorGroups'
import Component from 'components/DashboardViewer/Filter/People'

const mapStateToProps = (state, props) => ({
  users: selectors.users.selectOptions(state),
  operatorGroups: selectors.operatorGroups.selectOptions(state),
  operators: selectors.operators.selectOptions(state, { itemsOnly: false }),
})

const mapDispatchToProps = (dispatch, props) => ({
  getUsers: (params) => dispatch(userActions.getItems(params)),
  getOperatorGroups: (params) => dispatch(operatorGroupActions.getOptions(params)),
  getOperators: (params) =>
    dispatch(
      operatorActions.getOptions({
        active: 'All',
        pageIndex: 1,
        pageSize: 100,
        ...params,
      })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
