import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import actions from 'actions/inventory'
import locationActions from 'actions/locations'
import Component from 'components/InventoryTransfer'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  getLocations: locationActions.getItems,
  getMoveData: actions.getMoveData,
  moveItems: actions.moveItems,
  getTransferTableSettings: actions.getTransferTableSettings,
  getTransferResultsSettings: actions.getTransferResultsSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
