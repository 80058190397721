import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import actions from 'actions/assets'
import documentActions from 'actions/documents'
import listItemsActions from 'actions/lists/listItems'
import Component from 'components/ProcessSetupSheet/Print'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getItem: (assetId) => dispatch(actions.getItem(assetId)),
  getSettings: (params) => dispatch(actions.getSettings(params)),
  getListItemsSettings: (params) => dispatch(listItemsActions.getSettings(params)),
  getCombinedListForEntity: (params) => dispatch(listItemsActions.getCombinedListForEntity(params)),
  getDocumentContents: (params) => dispatch(documentActions.getContents(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
