import * as React from 'react'
import styled from 'styled-components'
import { Form, Spin, Input, Dropdown, Menu, message } from 'antd'
import { isFinite, get, isEmpty, isEqual, isNil, debounce, pick } from 'lodash'
import cx from 'clsx'
import produce from 'immer'
import { showError, showClientNotifications } from 'helpers/errors'
import { t } from 'helpers/i18n'
import { formatDateTime } from 'helpers/dateTime'
import { filterByValue } from 'helpers/formViews'
import { getStorageItem, setStorageItem } from 'helpers/localStorage'
import { Emitter, stopEvent } from 'helpers/events'
import { FORM_SUBMIT_EVENT } from 'options/events'
import Select, { Option, getOptionProps } from 'elements/Select'
import InputNumber from 'elements/InputNumber'
import AutoComplete, { Suffix } from 'elements/AutoComplete'
import { Row, Col } from 'elements/Grid'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'

const Container = styled(Row)`
  .ant-form-item {
    margin-bottom: 12px !important;

    .ant-form-explain {
      display: none !important;
    }
  }
`

const getStorageKey = () => 'inventory.transfer.toLocationType'

class FormView extends React.Component {
  state = this.props.initialState ?? getStorageItem(getStorageKey(), { toLocationType: 'Location' })

  constructor(props) {
    super(props)

    this.fetchMoveData = debounce(this.fetchMoveData, 1500)
  }

  async componentDidMount() {
    if (isNil(this.props.parentRecord)) {
      this.props.onCancel?.()
      return
    }

    await this.fetchSettings()
    await this.fetchMoveData()

    Emitter.on(FORM_SUBMIT_EVENT, this.handleSubmit)
  }

  componentWillUnmount() {
    Emitter.off(FORM_SUBMIT_EVENT, this.handleSubmit)
  }

  componentDidUpdate(prevProps) {
    if (isNil(this.props.parentRecord)) {
      this.props.onCancel?.()
      return
    }

    if (!isEqual(prevProps.parentRecord, this.props.parentRecord)) {
      this.fetchMoveData()
    }
  }

  saveState = () =>
    setStorageItem(getStorageKey(), pick(this.state, ['toLocationType', 'item.toOverstockLocationId']))

  fetchSettings = async () => {
    try {
      const [locations, overstockLocations] = await Promise.all([
        this.props
          .getLocations({ locationVendingTypes: ['NonVending'] })
          .then((r) => get(r, 'value.data.items', [])),
        this.props.customer.moduleSettings.enableOverstockInventory
          ? await this.props.getOverstockLocations().then((r) => get(r, 'value.data.items', []))
          : Promise.resolve([]),
      ])

      this.setState({ locations, overstockLocations })
    } catch (error) {
      showError({ error })
    }
  }

  cancelablePromises = []

  fetchMoveData = async () => {
    this.cancelablePromises.forEach((each) => each.cancel?.())
    this.cancelablePromises = []

    try {
      this.setState({ fetchMoveDataInProgress: true })

      if (this.state.toLocationType === 'Location') {
        const {
          toLocationId = 0,
          onHandQuantityToMove = 0,
          onHand2QuantityToMove = 0,
        } = this.state.item ?? {}

        const cancelablePromise = this.props
          .getInventoryMoveData([
            {
              barcode: this.props.parentRecord.barcode,
              fromLocationId: this.props.parentRecord.locationId,
              toLocationId,
              onHandQuantityToMove,
              onHand2QuantityToMove,
            },
          ])
          .then((r) =>
            r.value.data.items.map((each) => ({
              ...each,
              id: undefined,
              toLocationId: each.toLocationId || null,
              onHandQuantityToMove: this.props.customer.generalSettings.enableFillToMax
                ? Math.max(0, each.fillToMaxQuantity)
                : each.onHandQuantityToMove,
            }))
          )

        this.cancelablePromises.push(cancelablePromise)

        const [item] = await cancelablePromise

        this.setState({ item })
      } else {
        const {
          toOverstockBinLocation = '',
          toOverstockLocationId = 0,
          onHandQuantityToMove = 0,
          onHand2QuantityToMove = 0,
        } = this.state.item ?? {}

        const overstockInventory = (this.state.overstockInventoryItems ?? []).find(
          (one) => one.displayName === toOverstockBinLocation
        )

        const cancelablePromise1 = this.props
          .getOverstockInventoryMoveData([
            {
              barcode: this.props.parentRecord.barcode,
              fromLocationId: this.props.parentRecord.locationId,
              toOverstockLocationId,
              toOverstockInventoryId: overstockInventory?.id ?? 0,
              toOverstockBinLocation: overstockInventory?.binLocation ?? toOverstockBinLocation,
              onHandQuantityToMove,
              onHand2QuantityToMove,
            },
          ])
          .then((r) =>
            r.value.data.items.map((each) => ({
              ...each,
              id: undefined,
              toOverstockBinLocation,
              toOverstockLocationId: each.toOverstockLocationId || null,
              onHandQuantityToMove: this.props.customer.generalSettings.enableFillToMax
                ? Math.max(0, each.fillToMaxQuantity)
                : each.onHandQuantityToMove,
            }))
          )

        this.cancelablePromises.push(cancelablePromise1)

        const overstockLocation = this.state.overstockLocations.find(
          (one) => one.id === toOverstockLocationId
        )

        const cancelablePromise2 = this.props
          .getOverstockInventoryItems({
            barcode: this.props.parentRecord.barcode,
            locationGroupId: overstockLocation?.locationGroupId ?? 0,
            sortByField: 'createdDate',
            sortOrder: 'ASC',
          })
          .then((r) =>
            get(r, 'value.data.items', []).map((each) => ({
              ...each,
              displayName: `${each.binLocation || `<${t('none')}>`} - ${formatDateTime(each.createdDate, {
                showTime: true,
                showSeconds: true,
              })}`,
            }))
          )

        this.cancelablePromises.push(cancelablePromise2)

        const [[item], overstockInventoryItems] = await Promise.all([cancelablePromise1, cancelablePromise2])

        this.setState({
          item,
          overstockInventoryItems,
        })
      }

      this.saveState()
    } catch (error) {
      showError({ error })
    } finally {
      this.setState({ fetchMoveDataInProgress: false })
    }
  }

  showValidationMessage = () => {
    if (this.props.customer.moduleSettings.enableOverstockInventory) {
      message.error(
        this.state.toLocationType === 'Location'
          ? t('selectToInventoryLocationFirst')
          : t('selectToOverstockInventoryLocationFirst')
      )
    } else {
      message.error(t('selectToLocationFirst'))
    }
  }

  handleSubmit = (e) => {
    stopEvent(e)

    this.props.form.validateFields((errors, values) => {
      if (isEmpty(errors)) {
        if (this.state.item.onHandQuantityToMove === 0 && this.state.item.onHand2QuantityToMove === 0) {
          message.error(t('errorNoTransferQuantity'))
          throw new Error()
        }

        const onHandQuantityToMove = Math.min(
          this.state.item.fromOnHandQuantity,
          this.state.item.onHandQuantityToMove
        )
        const onHand2QuantityToMove = Math.min(
          this.state.item.fromOnHand2Quantity,
          this.state.item.onHand2QuantityToMove
        )
        const moveItems = async () => {
          const { item, toLocationType } = this.state

          try {
            await this.props.onSave?.(true)

            let response

            if (toLocationType === 'Location') {
              response = await this.props.moveInventoryItems([
                {
                  ...item,
                  onHandQuantityToMove,
                  onHand2QuantityToMove,
                },
              ])
            } else {
              const { toOverstockBinLocation = '' } = this.state.item ?? {}

              const overstockInventory = (this.state.overstockInventoryItems ?? []).find(
                (one) => one.displayName === toOverstockBinLocation
              )

              response = await this.props.moveOverstockInventoryItems([
                {
                  ...item,
                  toOverstockInventoryId: overstockInventory?.id ?? 0,
                  toOverstockBinLocation: overstockInventory?.binLocation ?? toOverstockBinLocation,
                  onHandQuantityToMove,
                  onHand2QuantityToMove,
                },
              ])
            }

            showClientNotifications({ response })

            if (response.value.data.failureCount > 0) {
              throw new Error()
            }

            await this.props.onSave?.(false)
            await this.props.onSaveAndClose?.(response)
          } catch (error) {
            showError({ error })
            await this.props.onSave?.(false)
          }
        }

        if (
          this.state.toLocationType === 'Location' &&
          this.props.customer.generalSettings.enableFillToMax &&
          onHandQuantityToMove + onHand2QuantityToMove > this.state.item.fillToMaxQuantity
        ) {
          Modal.confirm({
            autoFocusButton: 'ok',
            title: t('confirmTransferQuantityTitle'),
            content: t('confirmTransferQuantity'),
            okType: 'primary',
            okText: t('transfer'),
            cancelText: t('cancel'),
            onOk: () => moveItems(),
          })
        } else {
          moveItems()
        }
      } else {
        this.showValidationMessage()
      }
    })
  }

  setItemValue = (name, value) =>
    this.setState(
      produce((draft) => {
        if (['onHandQuantityToMove', 'onHand2QuantityToMove'].includes(name)) {
          draft.item[name] = isFinite(value) ? value : 0
        } else {
          draft.item[name] = value
        }

        if (name === 'toOverstockLocationId') {
          draft.item.toOverstockBinLocation = ''
        }
      }),
      () => {
        this.props.onChange?.(this.state.item)

        if (['toLocationId', 'toOverstockLocationId'].includes(name)) {
          this.fetchMoveData()
          this.fetchMoveData.flush()
        }

        if (name === 'toOverstockBinLocation') {
          this.fetchMoveData()

          if (isEmpty(value)) {
            this.fetchMoveData.flush()
          }
        }
      }
    )

  getToLocationLabel = () => {
    const { enableOverstockInventory } = this.props.customer.moduleSettings
    const { toLocationType } = this.state

    if (this.props.initialState?.toLocationType) {
      return this.props.initialState.toLocationType === 'Location'
        ? t('toInventoryLocation')
        : t('toOverstockLocation')
    }

    return enableOverstockInventory ? (
      <Dropdown
        overlay={
          <Menu
            onClick={({ key }) =>
              this.setState(
                produce((draft) => {
                  draft.toLocationType = key
                  draft.item.toLocationId = undefined
                  draft.item.toOverstockLocationId = undefined
                  draft.item.toOverstockBinLocation = ''
                  draft.item.fillToMaxQuantity = 0
                  draft.item.toMin = 0
                  draft.item.toMax = 0
                  draft.item.toOnHandQuantity = 0
                  draft.item.toOnHand2Quantity = 0
                }),
                () => {
                  this.fetchMoveData()
                  this.fetchMoveData.flush()
                }
              )
            }
          >
            <Menu.Item key="Location">{t('toInventoryLocation')}</Menu.Item>
            <Menu.Item key="OverstockLocation">{t('toOverstockLocation')}</Menu.Item>
          </Menu>
        }
      >
        <a>
          {toLocationType === 'Location' ? t('toInventoryLocation') : t('toOverstockLocation')}{' '}
          <Icon type="KeyboardArrowDown" />
        </a>
      </Dropdown>
    ) : (
      t('toLocation')
    )
  }

  render() {
    const { parentRecord } = this.props
    const { item, toLocationType, fetchMoveDataInProgress } = this.state

    if (isNil(item) || isNil(this.state.locations) || isNil(item.onHandQuantityToMove)) {
      return <Spin />
    }

    const { enableFillToMax } = this.props.customer.generalSettings
    const { enableOverstockInventory } = this.props.customer.moduleSettings
    const transferQuantity = Math.min(item.onHandQuantityToMove, item.fromOnHandQuantity)
    const deficiencyQuantity =
      item.fromOnHandQuantity < item.onHandQuantityToMove
        ? item.onHandQuantityToMove - item.fromOnHandQuantity
        : 0

    const transfer2Quantity = Math.min(item.onHand2QuantityToMove, item.fromOnHand2Quantity)
    const deficiency2Quantity =
      item.fromOnHand2Quantity < item.onHand2QuantityToMove
        ? item.onHand2QuantityToMove - item.fromOnHand2Quantity
        : 0

    return (
      <Form
        layout="vertical"
        onSubmit={fetchMoveDataInProgress ? stopEvent : this.handleSubmit}
        colon={false}
      >
        <Container gutter={24}>
          <Col xs={12} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.15)' }}>
            <Form.Item label={enableOverstockInventory ? t('fromInventoryLocation') : t('fromLocation')}>
              <Input value={parentRecord.locationName} disabled />
            </Form.Item>
            <Form.Item label={t('binLocation')}>
              <Input value={parentRecord.binLocation} disabled />
            </Form.Item>
            <Row>
              <Col xs={12}>
                <Form.Item label={t('onHand')}>
                  <Input value={parentRecord.onHand} disabled />
                </Form.Item>
                <Form.Item label={t('quantity')}>
                  <InputNumber
                    value={item.onHandQuantityToMove}
                    onChange={(value) => {
                      if (enableFillToMax && !item.toLocationId && !item.toOverstockLocationId) {
                        this.showValidationMessage()
                      } else {
                        this.setItemValue('onHandQuantityToMove', value)
                      }
                    }}
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  label={t('transfer')}
                  className={cx({
                    'form-item-success':
                      transferQuantity === item.onHandQuantityToMove && item.onHandQuantityToMove > 0,
                    'form-item-warning': transferQuantity < item.onHandQuantityToMove,
                  })}
                >
                  <Input value={transferQuantity} disabled />
                </Form.Item>
                <Form.Item
                  label={t('deficiency')}
                  className={cx({
                    'form-item-error': deficiencyQuantity > 0,
                  })}
                >
                  <Input value={deficiencyQuantity} disabled />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label={t('onHand2')}>
                  <Input value={parentRecord.onHand2} disabled />
                </Form.Item>
                <Form.Item label={t('quantity2')}>
                  <InputNumber
                    value={item.onHand2QuantityToMove}
                    onChange={(value) => {
                      if (enableFillToMax && !item.toLocationId && !item.toOverstockLocationId) {
                        this.showValidationMessage()
                      } else {
                        this.setItemValue('onHand2QuantityToMove', value)
                      }
                    }}
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  label={t('transfer2')}
                  className={cx({
                    'form-item-success':
                      transfer2Quantity === item.onHand2QuantityToMove && item.onHand2QuantityToMove > 0,
                    'form-item-warning': transfer2Quantity < item.onHand2QuantityToMove,
                  })}
                >
                  <Input value={transfer2Quantity} disabled />
                </Form.Item>
                <Form.Item
                  label={t('deficiency2')}
                  className={cx({
                    'form-item-error': deficiency2Quantity > 0,
                  })}
                >
                  <Input value={deficiency2Quantity} disabled />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Form.Item label={this.getToLocationLabel()} required>
              {toLocationType === 'Location'
                ? this.props.form.getFieldDecorator('toLocationId', {
                    rules: [{ required: true, message: t('requiredField') }],
                    initialValue: item?.toLocationId,
                  })(
                    <Select
                      onChange={(value) => this.setItemValue('toLocationId', value)}
                      allowClear={false}
                      disabled={Boolean(this.props.initialState?.item?.toLocationId)}
                    >
                      {(this.state.locations ?? [])
                        .filter((each) => each.id !== this.props.parentRecord.locationId)
                        .map((each) => (
                          <Option key={each.id} value={each.id}>
                            <span {...getOptionProps(each)}>{each.displayName}</span>
                          </Option>
                        ))}
                    </Select>
                  )
                : this.props.form.getFieldDecorator('toOverstockLocationId', {
                    rules: [{ required: true, message: t('requiredField') }],
                    initialValue: item?.toOverstockLocationId,
                  })(
                    <Select
                      onChange={(value) => this.setItemValue('toOverstockLocationId', value)}
                      allowClear={false}
                      disabled={Boolean(this.props.initialState?.item?.toOverstockLocationId)}
                    >
                      {(this.state.overstockLocations ?? []).map((each) => (
                        <Option key={each.id} value={each.id}>
                          <span {...getOptionProps(each)}>{each.name}</span>
                        </Option>
                      ))}
                    </Select>
                  )}
            </Form.Item>
            <Form.Item label={t('binLocation')}>
              {toLocationType === 'Location' ? (
                <Input value={item.toBinLocation} disabled />
              ) : (
                <AutoComplete
                  key={item.toOverstockLocationId}
                  defaultActiveFirstOption={false}
                  dataSource={(this.state.overstockInventoryItems ?? []).map((each) => each.displayName)}
                  filterOption={filterByValue}
                  onChange={(value) => this.setItemValue('toOverstockBinLocation', value)}
                  disabled={!item.toOverstockLocationId}
                >
                  <Input onPressEnter={stopEvent} autoComplete="off" suffix={<Suffix />} allowClear />
                </AutoComplete>
              )}
            </Form.Item>
            {enableFillToMax && toLocationType === 'Location' && (
              <>
                <Form.Item label={t('fillToMax')}>
                  <Input value={item.fillToMaxQuantity} disabled />
                </Form.Item>
                <Row>
                  <Col xs={12}>
                    <Form.Item label={t('min')}>
                      <Input value={item.toMin} disabled />
                    </Form.Item>
                    <Form.Item label={t('onhand')}>
                      <Input value={item.toOnHandQuantity} disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item label={t('max')}>
                      <Input value={item.toMax} disabled />
                    </Form.Item>
                    <Form.Item label={t('onHand2')}>
                      <Input value={item.toOnHand2Quantity} disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {toLocationType === 'OverstockLocation' && (
              <>
                <Row>
                  <Col xs={12}>
                    <Form.Item label={t('onhand')}>
                      <Input value={item.toOnHandQuantity} disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item label={t('onHand2')}>
                      <Input value={item.toOnHand2Quantity} disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Container>
      </Form>
    )
  }
}

export default Form.create()(FormView)
