import produce from 'immer'
import { isEmpty } from 'lodash'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default createActions(entityNames.unitsOfMeasure, {
  memoizeGetOptions: false,
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.alwaysIncludeUnitsOfMeasureIds =
        draft.alwaysIncludeUnitsOfMeasureIds === false ? false : !isEmpty(draft.unitsOfMeasureIds)
    }),
})
