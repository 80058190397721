import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { getUserPermission } from 'helpers/auth'
import PrintLabel from 'containers/PrintLabel'
import Filter from 'containers/Operators/Filter'

export default createListViewComponent({
  entityName: entityNames.operators,
  filterTemplateType: 'Operator',
  createButtonTextId: 'createOperator',
  primaryLinkTarget: 'operatorRecord',
  initialFilterDto: (self) => ({
    active: 'Active',
    getCollections: true,
  }),
  createDisabled: (self) => getUserPermission('Operators') !== 'Edit',
  deleteDisabled: (self) => getUserPermission('Operators') !== 'Edit',
  getFileTemplateTypes: (self) => ['Operators'],
  extraRowActions: (self) => (
    <PrintLabel
      type="Operator"
      items={self.state.selectedRowKeys.map((each) => self.state.items.find((one) => one.id === each))}
      parentHasUnsavedChanges={self.hasUnsavedChanges()}
    />
  ),
})(Filter)
