import actions from 'actions/dashboardTemplates'
import dashboardChartActions from 'actions/dashboardCharts'
import { createChildFormViewContainer } from 'factories/ChildFormView'
import Component from 'components/DashboardCharts/FormView'

export default createChildFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    getExistingItem: (params) => dispatch(dashboardChartActions.getItem(params)),
    getChartTypes: (params) => dispatch(actions.getChartTypes(params)),
    getChartMetricTypes: (params) => dispatch(actions.getChartMetricTypes(params)),
    getDistributionTypes: (params) => dispatch(actions.getDistributionTypes(params)),
    getDimensionTypes: (params) => dispatch(actions.getDimensionTypes(params)),
  }),
})(Component)
