import * as React from 'react'
import { Form } from 'antd'
import { get, debounce } from 'lodash'
import { showError } from 'helpers/errors'
import { createDropdownRender } from 'helpers/formViews'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { DEBOUNCE } from 'helpers/utils'
import Select, { Option, getOptionProps } from 'elements/Select'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchCycleCounts = debounce(this.searchCycleCounts, DEBOUNCE)
  }

  async componentDidMount() {
    try {
      const { cycleCountId } = this.props.filterDto
      const response = await this.props.getCycleCountSelectionList({
        cycleCountIds: cycleCountId ? [cycleCountId] : [],
      })

      this.setState({ cycleCountSelectionList: get(response, 'value.data', {}) })
    } catch (error) {
      showError({ error })
    }
  }

  searchCycleCounts = async (search) => {
    try {
      const { cycleCountId, cycleCountIds = [] } = this.props.filterDto
      const response = await this.props.getCycleCountSelectionList({
        search,
        cycleCountIds: cycleCountId ? [cycleCountId] : cycleCountIds,
      })

      this.setState({ cycleCountSelectionList: get(response, 'value.data', {}) })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto = {} } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col lg={8} sm={12} xs={24}>
            <Form.Item label={t('cycleCountId')} required>
              <Select
                value={filterDto.cycleCountId}
                onChange={(value) => this.setFilterValue('cycleCountId', value)}
                placeholder={t('select')}
                dropdownRender={createDropdownRender(this.state.cycleCountSelectionList)}
                onSearch={this.searchCycleCounts}
              >
                {(this.state.cycleCountSelectionList?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
