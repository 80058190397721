import * as React from 'react'
import { AutoComplete as AntAutoComplete } from 'antd'
import { Down } from 'elements/Icon'

export function Suffix(props) {
  return <Down />
}

function AutoComplete(props) {
  const {
    forwardedRef,
    readOnly,
    children,
    dataSource,
    onChange = () => {},
    allowClear = true,
    ...rest
  } = props

  return (
    <AntAutoComplete
      ref={forwardedRef}
      dataSource={dataSource?.filter((v, i, a) => a.indexOf(v) === i)}
      {...rest}
      onChange={readOnly ? () => {} : onChange}
      allowClear={readOnly ? false : allowClear}
    >
      {children}
    </AntAutoComplete>
  )
}

export default React.forwardRef((props, ref) => <AutoComplete {...props} forwardedRef={ref} />)
