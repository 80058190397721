import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import assetActions from 'actions/assets'
import jobActions from 'actions/jobs'
import operatorActions from 'actions/operators'
import tagListActions from 'actions/tagLists'
import locationActions from 'actions/locations'
import listItemActions from 'actions/lists/listItems'
import productActions from 'actions/products'

export default ({ extraStateToProps, extraDispatchToProps } = {}) =>
  (Component) => {
    const mapStateToProps = (state, props) => ({
      ...createAuthStateToProps(state, props),
      ...(extraStateToProps ? extraStateToProps(state, props) : {}),
    })

    const mapDispatchToProps = (dispatch, props) => ({
      copyToPrivate: (params) => dispatch(productActions.copyToPrivate(params)),
      populateListItems: (params) => dispatch(listItemActions.populateListItems(params)),
      getTagListItems: (params) => dispatch(tagListActions.getOptions(params)),
      getLocations: (params) =>
        dispatch(locationActions.getOptions({ ...params, locationVendingTypes: ['NonVending'] })),
      getAssets: (params) =>
        dispatch(
          assetActions.getOptions({
            pageIndex: 1,
            pageSize: 100,
            ...params,
          })
        ),
      getJobs: (params) =>
        dispatch(
          jobActions.getOptions({
            pageIndex: 1,
            pageSize: 100,
            ...params,
          })
        ),
      getOperators: (params) =>
        dispatch(
          operatorActions.getOptions({
            pageIndex: 1,
            pageSize: 100,
            ...params,
            active: 'Active',
          })
        ),
      ...(extraDispatchToProps ? extraDispatchToProps(dispatch, props) : {}),
    })

    return connect(mapStateToProps, mapDispatchToProps)(Component)
  }
