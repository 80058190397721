import * as React from 'react'
import styled from 'styled-components'
import { Spin, Table } from 'antd'
import { isEmpty, isNil } from 'lodash'
import { getPrintColumns } from 'helpers/listViews'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { getKeyValueItem } from 'helpers/keyValueStorage'
import ResizableCell from 'elements/ResizableCell'

const Container = styled.div`
  .ant-table-thead > tr > th {
    background-color: #f5f5f5;

    .ant-table-column-sorter {
      .anticon-caret-up.off,
      .anticon-caret-down.off {
        display: none;
      }

      .anticon-caret-up.on,
      .anticon-caret-down.on {
        margin-top: 6px;
      }
    }
  }
`

export default ({
    tableCellComponents = {},
    totalsRowComponents = {},
    tableHeadGroups = (self) => ({
      // dtoFieldName: () => []
    }),
    getRowClassName = (self) => (record, index) => '',
    getIdField = (self) => 'id',
  } = {}) =>
  () =>
    class ChildTablePrint extends React.Component {
      state = {}

      async componentDidMount() {
        try {
          const savedState = await getKeyValueItem(this.props.storageKey, {})

          this.setState({ savedState })
        } catch (error) {
          showError({ error })
        }
      }

      render() {
        const { items = [], fieldSettings = [] } = this.props

        if (isEmpty(fieldSettings) || isNil(this.state.savedState)) {
          return <Spin />
        }

        const { sortBy, sortOrder, sortedColumnKeys = [], selectedColumnKeys = [] } = this.state.savedState

        const columns = getPrintColumns({
          sortBy,
          sortOrder,
          sortedColumnKeys,
          selectedColumnKeys,
          fieldSettings,
          tableCellComponents,
          totalsRowComponents,
          tableHeadGroups,
          self: this,
        })

        // console.log({ items, fieldSettings })

        return (
          <Container>
            <Table
              className="pointer-events-none"
              childrenColumnName={[]}
              rowKey={getIdField(this)}
              columns={columns}
              dataSource={items}
              rowClassName={getRowClassName(this)}
              size="middle"
              components={{ header: { cell: ResizableCell } }}
              pagination={false}
              onChange={this.handleTableChange}
              locale={{ emptyText: t('noData') }}
            />
            <div className="mt-6">{`${items?.length ?? 0} ${t('items')}`}</div>
          </Container>
        )
      }
    }
