import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import actions from 'actions/orders'
import { createChildItemsDispatchToProps, createDocumentsDispatchToProps } from 'helpers/formViews'
import documentActions from 'actions/documents'
import orderItemActions from 'actions/orders/orderItems'
import orderSupplierActions from 'actions/orders/orderSuppliers'
import locationActions from 'actions/locations'
import Component from 'components/Issue/FormView'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  settingsType: props.settingsType ?? 'issue',
})

const mapDispatchToProps = (dispatch, props) => ({
  getItem: (itemId) => dispatch(actions.getItem(itemId)),
  updateItem: (params) => dispatch(actions.updateItem(params)),
  getSettings: (params) => dispatch(actions.getSettings(params)),
  getAddressList: (params) => dispatch(locationActions.getAddressList(params)),
  validateIssueItems: (parentId, params) => dispatch(orderItemActions.validateIssueItems(parentId, params)),
  issueOrderItem: (parentId, params) => dispatch(orderItemActions.issueOrderItem(parentId, params)),
  submitItem: (orderId) => dispatch(actions.submitItem(orderId)),
  deleteItem: (orderId) => dispatch(actions.deleteItem(orderId)),
  generatePurchaseOrderNumber: (params) => dispatch(orderSupplierActions.generatePurchaseOrderNumber(params)),
  ...createDocumentsDispatchToProps(dispatch, documentActions),
  ...createChildItemsDispatchToProps(dispatch, 'Order', orderItemActions),
  ...createChildItemsDispatchToProps(dispatch, 'OrderSupplier', orderSupplierActions),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
