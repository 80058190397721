import { Link } from 'react-router-dom'
import { encodeState } from 'helpers/utils'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { RED, YELLOW, GREEN } from 'options/colors'
import Progress from 'elements/Progress'
import Filter from 'containers/Reports/JobCompletionSummaryReport/Filter'

const completionStatusMap = {
  openJobCount: 'Open',
  closedLateJobCount: 'ClosedLate',
  closedOnTimeJobCount: 'ClosedOnTime',
  totalJobCount: 'All',
}

const getSummarizedField = (summarizeBy, id) => {
  switch (summarizeBy) {
    case 'Location':
      return { locationIds: [id] }

    case 'JobGroup':
      return { jobGroupIds: [id] }

    case 'OperatorName':
      return { operatorIds: [id] }

    case 'AssetCategory':
      return { assetCategoryIds: [id] }

    case 'JobReason':
      return { jobReasonIds: [id] }

    case 'Priority':
      return { priority: id }

    case 'JobStatusOption':
      return { jobStatusOptionIds: [id] }

    case 'ApprovalStatus':
      return { approvalStatus: id }

    default:
      return {}
  }
}

const createCountTableCellComponent = (key) => (self, item) => {
  const {
    dateRange,
    approvalStatus,
    locationGroupId,
    locationIds,
    jobReasonIds,
    assetCategoryIds,
    jobGroupIds,
    operatorIds,
    jobStatusOptionIds,
    priority,
    summarizeBy,
  } = self.state.filterDto ?? {}

  return item[key] ? (
    <Link
      to={`/jobs/reports/jobCompletionDetailReport?${encodeState({
        showFilter: true,
        filterDto: {
          dateRange,
          approvalStatus,
          locationGroupId,
          locationIds,
          jobReasonIds,
          assetCategoryIds,
          jobGroupIds,
          operatorIds,
          jobStatusOptionIds,
          priority,
          completionStatus: completionStatusMap[key],
          ...getSummarizedField(summarizeBy, item.id),
        },
      })}`}
    >
      {item[key]}
    </Link>
  ) : (
    item[key]
  )
}

export default createListViewComponent({
  entityName: entityNames.jobCompletionSummaryReport,
  filterTemplateType: 'JobCompletionSummaryReport',
  initialFilterDto: (self) => ({
    dateRange: { dateRangeType: 'YearToDate' },
    approvalStatus: 'All',
    summarizeBy: 'Location',
  }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  tableCellComponents: {
    openJobCount: createCountTableCellComponent('openJobCount'),
    closedLateJobCount: createCountTableCellComponent('closedLateJobCount'),
    closedOnTimeJobCount: createCountTableCellComponent('closedOnTimeJobCount'),
    totalJobCount: createCountTableCellComponent('totalJobCount'),
    openJobPercent: (self, item) => {
      const percent = Math.round(item.openJobPercent * 100)
      return (
        <Progress
          percent={percent}
          strokeColor={percent <= 10 ? GREEN : percent <= 50 ? YELLOW : RED}
          size="small"
          status="normal"
        />
      )
    },
    closedLateJobPercent: (self, item) => {
      const percent = Math.round(item.closedLateJobPercent * 100)
      return (
        <Progress
          percent={percent}
          strokeColor={percent <= 10 ? GREEN : percent <= 50 ? YELLOW : RED}
          size="small"
          status="normal"
        />
      )
    },
    closedOnTimeJobPercent: (self, item) => {
      const percent = Math.round(item.closedOnTimeJobPercent * 100)
      return (
        <Progress
          percent={percent}
          strokeColor={percent < 50 ? RED : percent < 90 ? YELLOW : GREEN}
          size="small"
          status="normal"
        />
      )
    },
  },
})(Filter)
