import * as React from 'react'
import { Tooltip } from 'antd'
import { isNil } from 'lodash'
import { Resizable } from 'react-resizable'
import { MINIMUM_COLUMN_WIDTH } from 'helpers/listViews'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import Icon from 'elements/Icon'

function Component(props) {
  const { onResize, width, columnHeadingTooltipLanguageKey, columnHeadingIconName, children, ...rest } = props

  const [resizing, setResizing] = React.useState(false)

  if (isNil(width)) {
    return <th {...rest}>{children}</th>
  }

  const innerContents = (
    <span className={columnHeadingTooltipLanguageKey ? 'with-tooltip' : ''}>
      {columnHeadingIconName ? <Icon type={columnHeadingIconName} /> : children}
    </span>
  )

  const onClickHandler = resizing ? { onClick: stopEvent } : {}

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      onResizeStart={() => setResizing(true)}
      onResizeStop={() => window.setTimeout(() => setResizing(false), 250)}
      minConstraints={[MINIMUM_COLUMN_WIDTH, Infinity]}
      {...onClickHandler}
    >
      <th {...rest} {...onClickHandler}>
        {columnHeadingTooltipLanguageKey ? (
          <Tooltip title={<span dangerouslySetInnerHTML={{ __html: t(columnHeadingTooltipLanguageKey) }} />}>
            {innerContents}
          </Tooltip>
        ) : (
          innerContents
        )}
      </th>
    </Resizable>
  )
}

export default Component
