import { Menu } from 'antd'
import { createSideFormComponent } from 'factories/SideForm'
import { t } from 'helpers/i18n'
import Search from 'containers/Inventory/Select'

export default createSideFormComponent({
  getStorageKey: (self) => 'inventory.issue.sideForm',
  searchLanguageKey: 'searchInventory',
  addItemLanguageKey: 'issueItem',
  addItemsLanguageKey: 'validateIssue',
  addItemSuccessLanguageKey: 'issueItemSuccess',
  submitButtonLanguageKey: 'issue',
  tagSettingsEnabledSuffix: '',
  dropDownButtonMenuItems: (self) => {
    if (self.props.useInventoryBarcode) {
      return null
    }

    return [
      <Menu.Item key="addFromList">{t('addFromList')}</Menu.Item>,
      <Menu.Item key="addFromFile">{t('addFromFile')}</Menu.Item>,
    ]
  },
  dropdownButtonMenuClickHandler: (self) => (e) => {
    if (e.key === 'addFromList') {
      self.handleAddFromListClick?.()
    }

    if (e.key === 'addFromFile') {
      self.handleAddFromFileClick?.()
    }
  },
})(Search)
