import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import fileTemplates from 'actions/fileTemplates'
import filterTemplates from 'actions/filterTemplates'
import { createDownloadItemsDispatchToProps } from 'helpers/downloadItems'

export default ({ actions, selectors, extraStateToProps, extraDispatchToProps } = {}) => {
  if (extraStateToProps) {
    console.error('extraStateToProps in CalendarView container is deprecated')
  }

  return (Component) => {
    const mapStateToProps = (state, props) => ({
      ...createAuthStateToProps(state, props),
      calendar: selectors.selectCalendarView(state),
      items: selectors.selectCalendarViewItems(state),
      loading: selectors.selectListViewLoading(state),
      pageTotals: selectors.selectCalendarViewTotals(state),
      ...(extraStateToProps ? extraStateToProps(state, props) : {}),
    })

    const mapDispatchToProps = (dispatch, props) => ({
      getSettings: (params) => dispatch(actions.getSettings(params)),
      getItems: (params) => dispatch(actions.getCalendarViewItems(params)),
      createItem: (params, options) => dispatch(actions.createItem(params, options)),
      updateItem: (params, options) => dispatch(actions.updateItem(params, options)),
      deleteItem: (params) => dispatch(actions.deleteItem(params)),
      deleteItems: (params, options) => dispatch(actions.deleteItems(params, options)),
      deactivateItems: (params, options) => dispatch(actions.deactivateItems(params, options)),
      downloadItems: (params) => dispatch(actions.downloadItems(params)),
      getFileTemplates: (params) => dispatch(fileTemplates.getOptions(params)),
      getFileTemplate: (fileTemplateId) => dispatch(fileTemplates.getItem(fileTemplateId)),
      getFilterTemplates: (params) => dispatch(filterTemplates.getOptions(params)),
      getFilterTemplate: (filterTemplateId) => dispatch(filterTemplates.getItem(filterTemplateId)),
      createFilterTemplate: (params) => dispatch(filterTemplates.createItem(params)),
      updateFilterTemplate: (params) => dispatch(filterTemplates.updateItem(params)),
      deleteFilterTemplate: (params) => dispatch(filterTemplates.deleteItem(params)),
      ...createDownloadItemsDispatchToProps(dispatch),
      ...(extraDispatchToProps ? extraDispatchToProps(dispatch, props) : {}),
    })

    return connect(mapStateToProps, mapDispatchToProps)(Component)
  }
}
