import produce from 'immer'
import { baseReportUrl } from 'config'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default createActions(entityNames.assetAssociatedInventoryReport, {
  basePath: 'report/assetReports',
  baseUrl: baseReportUrl,
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.locationVendingTypes = ['NonVending']
    }),
})
