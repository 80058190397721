import { snakeCase, pick } from 'lodash'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { createPost } from 'helpers/api'

const entityName = entityNames.overstockInventory
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    useSelectionList: true,
    allowGetItems: (params) => params.locationGroupId || params.locationId,
  }),

  moveItems: (params) => ({
    type: `${ENTITY_NAME}_ITEMS_MOVE`,
    payload: createPost(entityName, { action: 'move' })(
      params.map((each) =>
        pick(each, [
          'barcode',
          'fromLocationId',
          'toLocationId',
          'fromBinLocation',
          'toBinLocation',
          'fromOverstockLocationId',
          'toOverstockLocationId',
          'fromOverstockBinLocation',
          'toOverstockBinLocation',
          'fromOverstockInventoryId',
          'toOverstockInventoryId',
          'onHandQuantityToMove',
          'onHand2QuantityToMove',
        ])
      )
    ),
  }),

  getMoveData: (params = []) => ({
    type: `${ENTITY_NAME}_GET_MOVE_DATA`,
    payload: createPost(entityName, { action: 'getMoveData' })(
      params.map((each) =>
        pick(each, [
          'barcode',
          'onHandQuantityToMove',
          'onHand2QuantityToMove',
          'fromLocationId',
          'toLocationId',
          'fromOverstockLocationId',
          'toOverstockLocationId',
          'fromOverstockBinLocation',
          'toOverstockBinLocation',
          'fromOverstockInventoryId',
          'toOverstockInventoryId',
        ])
      )
    ),
  }),
}
