import { isNil, toString as str, isEmpty } from 'lodash'
import { getSessionItem } from 'helpers/sessionStorage'
import { AUTH_SESSION_KEY } from 'options/auth'

export function getScoped(key) {
  const auth = getSessionItem(AUTH_SESSION_KEY, {})
  const subdomain = auth.current?.tenant?.subdomain
  const customerId = auth.current?.customer?.id
  const userName = auth.current?.user?.userName

  return [process.env.REACT_APP_NAME, subdomain, customerId, userName, key]
    .map(str)
    .filter((each) => !isEmpty(each))
    .join('.')
}

export function getStorageItem(key, defaultValue) {
  try {
    const serializedValue = localStorage.getItem(getScoped(key))

    return isNil(serializedValue) ? defaultValue : JSON.parse(str(serializedValue))
  } catch (error) {
    return defaultValue
  }
}

export function setStorageItem(key, value) {
  const text = JSON.stringify(value)
  localStorage.setItem(getScoped(key), text)
}
