import { partition, sum } from 'lodash'
import { createCalendarViewComponent } from 'factories/CalendarView'
import { t, toLocaleNumber, toLocaleCurrency } from 'helpers/i18n'
import entityNames from 'options/entityNames'
import Filter from 'containers/Requisitions/CalendarView/Filter'

export default createCalendarViewComponent({
  entityName: entityNames.requisitions,
  filterTemplateType: 'RequisitionCalendar',
  createButtonTextId: 'createRequisition',
  primaryLinkTarget: 'requisitionRecord',
  getSettingsType: (self) => 'RequisitionCalendarTiles',
  getSummarySettingsType: (self) => 'RequisitionCalendarSummary',
  getDateField: (self) => {
    switch (self.state.filterDto?.dateRangeField) {
      case 'ApprovedDate':
        return 'approvedDate'

      case 'DueDate':
        return 'deliveryDueDate'

      default:
        return 'createDate'
    }
  },
  getTileTitle: (self) => (item) => (item.isExpedited ? t('expedited') : ''),
  getSummaryTitle: (self) => (item) => t('requisitionSummary'),
  allowCreate: (self) => !self.props.userIsInRoleSupplier,
  allowDelete: (self) => (item) => !self.props.userIsInRoleSupplier && item.status === 'Draft',
  initialFilterDto: (self) => ({
    dateRangeField: 'CreatedDate',
  }),
  getTotalValues: (self) => {
    const totalableItems = self.getTotalableItems()
    const [expedited, standard] = partition(totalableItems, (each) => each.isExpedited)
    const requisitioned = totalableItems.filter((each) => each.quantityRequisitioned !== 0)
    const ordered = totalableItems.filter((each) => each.quantityOrdered !== 0)
    const issued = totalableItems.filter((each) => each.quantityIssued !== 0)
    const totalValue = totalableItems.filter((each) => each.requisitionTotal !== 0)
    const totalLines = totalableItems.filter((each) => each.totalLines !== 0)
    const nonStockLines = totalableItems.filter((each) => each.nonStockLines !== 0)

    return [
      {
        key: 'standard',
        title: t('standard'),
        value: toLocaleNumber(standard.length),
        ids: standard.map((each) => each.id),
      },
      {
        key: 'expedited',
        title: t('expedited'),
        value: toLocaleNumber(expedited.length),
        ids: expedited.map((each) => each.id),
      },
      {
        key: 'requisitioned',
        title: t('requisitioned'),
        value: toLocaleNumber(sum(requisitioned.map((each) => each.quantityRequisitioned))),
        ids: requisitioned.map((each) => each.id),
      },
      {
        key: 'ordered',
        title: t('ordered'),
        value: toLocaleNumber(sum(ordered.map((each) => each.quantityOrdered))),
        ids: ordered.map((each) => each.id),
      },
      {
        key: 'issued',
        title: t('issued'),
        value: toLocaleNumber(sum(issued.map((each) => each.quantityIssued))),
        ids: issued.map((each) => each.id),
      },
      {
        key: 'totalValue',
        title: t('totalValue'),
        value: toLocaleCurrency(sum(totalValue.map((each) => each.requisitionTotal))),
        ids: totalValue.map((each) => each.id),
      },
      {
        key: 'totalLines',
        title: t('totalLines'),
        value: toLocaleNumber(sum(totalLines.map((each) => each.totalLines))),
        ids: totalLines.map((each) => each.id),
      },
      {
        key: 'nonStockLines',
        title: t('nonStockLines'),
        value: toLocaleNumber(sum(nonStockLines.map((each) => each.nonStockLines))),
        ids: nonStockLines.map((each) => each.id),
      },
    ]
  },
  getCategorizationFields: (self) => [
    { dtoFieldName: 'itemLocationNames', isArray: true },
    { dtoFieldName: 'userName' },
    { dtoFieldName: 'statusName' },
  ],
})(Filter)
