import * as React from 'react'
import { Form, Input, Spin, Card } from 'antd'
import { set } from 'lodash'
import produce from 'immer'
import { showError, resourceNotFound, ValidationError } from 'helpers/errors'
import { createLabelFactory, createFieldFactory, createHandleSubmit, isReadOnly } from 'helpers/formViews'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import Select, { Option } from 'elements/Select'
import Help from 'elements/Help'
import Checkbox from 'elements/Checkbox'
import ForgotPassword from 'containers/ForgotPassword'
import FormButtons from 'elements/FormButtons'

class FormView extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.handleSubmit = createHandleSubmit(this)
  }

  componentDidMount() {
    this.fetchItem()
  }

  saveItemSuccess = () => this.props.refreshToken(true)

  getItemId = () => {
    const userName = [this.state.item?.userName, this.props.linkTargetRecord?.userName].find(Boolean)

    return userName ? encodeURIComponent(userName) : null
  }

  fetchItem = async ({ itemId = this.getItemId() } = {}) => {
    try {
      const responses = await Promise.all([
        itemId
          ? this.props.getItem(itemId).catch((error) => {
              this.props.onCancel?.()
              throw error
            })
          : !this.props.linkTargetRecord
            ? this.props.newItem({})
            : resourceNotFound(this),
        this.props.getSettings({ type: this.props.settingsType }),
      ])

      this.setState({
        item: responses[0].value.data,
        fieldSettings: responses[1].value.data.fieldSettings,
      })
    } catch (error) {
      showError({ error })
    }
  }

  setItemValue = (name, value) =>
    this.setState(
      produce((draft) => {
        set(draft.item, name, value)

        this.props.form.setFieldsValue({ [name]: value })
      })
    )

  saveItem = async (item) => {
    if (!item) {
      throw new Error('item is undefined')
    }

    const saved = item.id
      ? await this.props.updateItem(item, { type: 'userSettings' })
      : await this.props.createItem(item, { type: 'userSettings' })

    return saved
  }

  render() {
    const { user, tenant, customer, locales, impersonator } = this.props
    const { item, fieldSettings } = this.state

    if (!item || !fieldSettings) {
      return <Spin />
    }

    const createLabel = createLabelFactory(fieldSettings)
    const createFieldDecorator = createFieldFactory(this.props.form, item, fieldSettings)
    const readOnly = isReadOnly(this)

    return (
      <Form layout="vertical" colon={false} onSubmit={readOnly ? stopEvent : this.handleSubmit}>
        <div className="form-items-container">
          <ValidationError errors={this.state.validationErrors} />
          {!impersonator && (
            <Form.Item label={createLabel('email')}>
              {createFieldDecorator('email')(<Input autoFocus />)}
            </Form.Item>
          )}
          <Form.Item label={createLabel('languageCode')}>
            {createFieldDecorator('languageCode')(
              <Select onChange={(value) => this.setItemValue('languageCode', value)} allowClear={false}>
                {locales.map((each) => (
                  <Option key={each.code} value={each.code}>
                    {each.nativeName}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {!impersonator && (
            <>
              <Form.Item>
                <Checkbox
                  onChange={(e) => this.setItemValue('outOfOffice', e.target.checked)}
                  checked={item.outOfOffice}
                >
                  {createLabel('outOfOffice')}
                </Checkbox>
              </Form.Item>
              {!this.props.tenant.ssoEnabled && (
                <Form.Item>
                  <Checkbox
                    onChange={(e) => this.setItemValue('changePassword', e.target.checked)}
                    checked={item.changePassword}
                  >
                    {createLabel('changePassword')}
                  </Checkbox>
                </Form.Item>
              )}
            </>
          )}
          {!impersonator && item.changePassword && (
            <Card
              title={t('setNewPassword')}
              extra={
                customer.passwordSettings.definitionMode === 'Advanced' ? (
                  <Help title={item.passwordHelperText} placement="topLeft" />
                ) : undefined
              }
            >
              <Form.Item label={createLabel('currentPassword')}>
                {createFieldDecorator('currentPassword')(<Input.Password type="password" autoFocus />)}
              </Form.Item>
              <Form.Item label={createLabel('newPassword')}>
                {createFieldDecorator('newPassword')(<Input.Password type="password" />)}
              </Form.Item>
              <Form.Item label={t('confirmNewPassword')}>
                {createFieldDecorator('confirmPassword')(<Input.Password type="password" />)}
              </Form.Item>
              <ForgotPassword
                initialValues={{
                  tenant: tenant?.subdomain,
                  userName: user.userName,
                  email: user.email,
                }}
              />
            </Card>
          )}
        </div>
        <div className="form-buttons-container">
          <FormButtons
            readOnly={readOnly}
            onCancel={this.props.onCancel}
            onSubmit={this.handleSubmit}
            saveButtonLoading={this.state.saveButtonLoading}
            saveAndCloseButtonLoading={this.state.saveAndCloseButtonLoading}
          />
        </div>
      </Form>
    )
  }
}

export default Form.create()(FormView)
