import { snakeCase } from 'lodash'
import { createActions } from 'helpers/actions'
import { createPost } from 'helpers/api'
import { baseReportUrl } from 'config'
import entityNames from 'options/entityNames'

const entityName = entityNames.dashboardCharts
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName),

  getChartData: ({ chartId, ...rest }) => ({
    type: `${ENTITY_NAME}_DATA_GET`,
    payload: createPost(entityName, {
      action: 'data',
      path: `${baseReportUrl}/report/dashboardCharts/${chartId}`,
    })(rest),
  }),
}
