import * as React from 'react'
import { Form } from 'antd'
import statusOptions from 'options/statusOptions'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option } from 'elements/Select'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  render() {
    const { filterDto = {} } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) => this.setFilterValue('active', value || 'Active')}
                value={filterDto.active || 'Active'}
              >
                {Object.entries(statusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
