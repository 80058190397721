import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import actions from 'actions/tenantSettings'
import authActions from 'actions/auth'
import supplierIntegrationActions from 'actions/supplierIntegration'
import Component from 'components/Settings/Tenant'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  getItem: actions.getItem,
  updateItem: actions.updateItem,
  getSettings: actions.getSettings,
  refreshToken: authActions.refreshToken,
  getSupplierIntegrations: supplierIntegrationActions.getOptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
