import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/OnTimeDeliveryReport/Filter'

export default createListViewComponent({
  entityName: entityNames.onTimeDeliveryReport,
  filterTemplateType: 'OnTimeDeliveryReport',
  initialFilterDto: (self) => ({
    dateRangeField: 'ItemDueDate',
    dateRange: { dateRangeType: 'YearToDate' },
    locationStatus: 'All',
    supplierStatus: 'All',
  }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
})(Filter)
