import { memoize, snakeCase } from 'lodash'
import { createActions } from 'helpers/actions'
import { createGet } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.labelTemplates
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const getLabelTemplateTypes = memoize(createGet(entityName, { action: 'templateTypes' }), JSON.stringify)
const getLabelTemplateFields = memoize(createGet(entityName, { action: 'fieldSettings' }), JSON.stringify)

export default {
  ...createActions(entityName),

  getLabelTemplateTypes: (params) => ({
    type: `${ENTITY_NAME}_TYPES_GET`,
    payload: getLabelTemplateTypes(params),
  }),

  getLabelTemplateFields: (params) => ({
    type: `${ENTITY_NAME}_FIELDS_GET`,
    payload: getLabelTemplateFields(params),
  }),
}
