import { createListModalComponent } from 'factories/ListModal'

const MIME_TYPES = {
  Jpeg: 'image/jpeg',
  Png: 'image/png',
  Gif: 'image/gif',
  Unknown: 'image',
}

export default createListModalComponent({
  getStorageKey: (self) => 'punchout.selectSupplier',
  getSettingsType: (self) => 'punchoutSuppliers',
  initialFilterDto: (self) => ({ punchoutSuppliersOnly: true, active: 'Active' }),
  tableCellComponents: {
    punchoutLogoContents: (self, item) =>
      item.punchoutLogoContents ? (
        <img
          style={{ maxWidth: '150px' }}
          src={`data:${MIME_TYPES[item.punchoutLogoFileType]};base64,${item.punchoutLogoContents}`}
          alt={item.name}
        />
      ) : null,
  },
  allowActionsMenu: (self) => false,
})()
