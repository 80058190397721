import * as React from 'react'
import { pick } from 'lodash'
import { sendClientLog } from 'helpers/auth'

class Component extends React.Component {
  state = { error: null }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch() {
    sendClientLog('Error', 'Error Boundary', {
      error: pick(this.state.error, ['message', 'stack']),
    })
  }

  render() {
    if (this.state.error) {
      return (
        <div style={{ margin: '12px 24px' }}>
          <h1>Sorry, something went wrong.</h1>
          <p style={{ fontSize: 'larger' }}>
            Please <a onClick={() => window.location.reload(true)}>refresh</a> the page or try again later.
            You may also <a href="mailto:info@tofinosoftware.com">report</a> this error to us.
          </p>
          <p style={{ fontSize: 'smaller' }}>
            <pre>{this.state.error.stack}</pre>
          </p>
        </div>
      )
    }

    return this.props.children
  }
}

export default Component
