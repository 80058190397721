import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import locationGroupActions from 'actions/locationGroups'
import locationActions from 'actions/locations'
import jobReasonActions from 'actions/jobReasons'
import assetCategoryActions from 'actions/assetCategories'
import assetActions from 'actions/assets'
import operatorActions from 'actions/operators'
import Component from 'components/Reports/AssetCostHistoryReport/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  locationGroups: selectors.locationGroups.selectOptions(state),
  locations: selectors.locations.selectOptions(state),
  jobReasons: selectors.jobReasons.selectOptions(state),
  assetCategories: selectors.assetCategories.selectOptions(state),
  assets: selectors.assets.selectOptions(state, { itemsOnly: false }),
  operators: selectors.operators.selectOptions(state, { itemsOnly: false }),
})

const mapDispatchToProps = (dispatch, props) => ({
  getLocationGroups: (params) => dispatch(locationGroupActions.getOptions(params)),
  getLocations: (params) =>
    dispatch(locationActions.getOptions({ ...params, active: 'All', locationVendingTypes: ['NonVending'] })),
  getJobReasons: (params) => dispatch(jobReasonActions.getOptions(params)),
  getAssetCategories: (params) => dispatch(assetCategoryActions.getOptions(params)),
  getAssets: (params) =>
    dispatch(
      assetActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
      })
    ),
  getOperators: (params) =>
    dispatch(
      operatorActions.getOptions({
        active: 'All',
        pageIndex: 1,
        pageSize: 100,
        ...params,
      })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
