import { get, isNil, toString as str } from 'lodash'
import frFR from 'antd/lib/locale-provider/fr_FR'
import esES from 'antd/lib/locale-provider/es_ES'
import enUS from 'antd/lib/locale-provider/en_US'
import { baseUrl } from 'config'
import { tryParseFloat, strEqual } from 'helpers/utils'
import { apiClient, getConfig } from 'helpers/api'
import store from 'helpers/store'
import selectors from 'selectors'

export const getTranslations = async (code = 'en') => {
  const url = `${baseUrl}/api/localization/${code}?useIdAsKey=false`
  const group = `GET ${url}`

  try {
    const response = await apiClient.get(url, getConfig())
    console.groupCollapsed(group)
    console.log('Results:', response)
    console.groupEnd()

    return response
  } catch (error) {
    console.groupCollapsed(group)
    console.error(error)
    console.groupEnd()

    throw error
  }
}

export const createLocale = (locale) => {
  switch (locale) {
    case 'fr':
      return frFR

    case 'es':
      return esES

    default:
      return enUS
  }
}

export const t = (value) => {
  const key = str(value)

  if (['', 'Error'].includes(key)) {
    return value
  }

  if (key.match(/\s/)) {
    console.warn('Use a language key for:', value)

    return value
  }

  const translations = get(store.getState(), 'i18n.translations', {})

  let translation = translations[key]

  if (isNil(translation)) {
    translation = get(
      Object.entries(translations).find((each) => strEqual(each[0], key)),
      '[1]'
    )
  }

  if (isNil(translation)) {
    console.warn('Unable to translate key:', value)

    return value
  }

  return translation
}

export const toLocaleNumber = (value = 0, options = {}) => {
  const locale = selectors.auth.locale(store.getState())

  return value.toLocaleString ? value.toLocaleString(locale, options) : value
}

export const toLocaleCurrency = (value = 0) =>
  toLocaleNumber(tryParseFloat(value, 0), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  })
