import { createListViewContainer } from 'factories/ListView'
import selectors from 'selectors'
import actions from 'actions/assets'
import locationActions from 'actions/locations'
import Component from 'components/Assets'

export default createListViewContainer({
  actions,
  selectors: selectors.assets,
  extraDispatchToProps: (dispatch, props) => ({
    moveAssets: (params) => dispatch(actions.moveAssets(params)),
    checkInAssets: (params) => dispatch(actions.checkInAssets(params)),
    getLocations: (params) =>
      dispatch(locationActions.getItems({ ...params, locationVendingTypes: ['NonVending'] })),
  }),
})(Component)
