import produce from 'immer'
import { baseReportUrl } from 'config'
import { createActions, normalizeDateRange } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default createActions(entityNames.inventoryReturnedItemsReport, {
  basePath: 'report/inventoryReports',
  baseUrl: baseReportUrl,
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.locationVendingTypes = ['NonVending']

      normalizeDateRange(draft)
    }),
})
