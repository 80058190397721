import * as React from 'react'
import styled from 'styled-components'
import { Pagination, Spin, Badge, Dropdown, Menu, Tooltip, Button, Form, Input, Table, message } from 'antd'
import {
  get,
  debounce,
  isEmpty,
  isEqual,
  toString as str,
  trim,
  toLower,
  remove,
  isFunction,
  invoke,
  isNil,
  pick,
  omit,
  cloneDeep,
} from 'lodash'
import { saveAs } from 'file-saver'
import cx from 'clsx'
import produce from 'immer'
import { showError, showClientNotifications } from 'helpers/errors'
import {
  filterUntitled,
  getColumns,
  mapGetItemsFields,
  getTableWidth,
  showTotal,
  createHandleCustomizeOk,
  pageSizeOptions,
  createHandleColumnResize,
  createListViewHandleTableChange,
  mapSearchFields,
  getVisibleColumnKeys,
  TOTALS_ROW_ID,
  getPageTotals,
  getReportJSON,
  parseReportJSON,
} from 'helpers/listViews'
import { createHandleFilterChange } from 'helpers/filters'
import { setStorageItem, getStorageItem } from 'helpers/localStorage'
import { getSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import linkTargets from 'options/linkTargets'
import { STARTUP_REDIRECT_SESSION_KEY } from 'options/auth'
import { mergeAll, tryParseJSON, strEqual, DEBOUNCE, decodeState } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { getMimeType, getFileExtension } from 'helpers/downloadItems'
import { getPageTitle } from 'helpers/auth'
import { TOKEN_REFRESHED_BY_FORCE } from 'options/events'
import { Emitter } from 'helpers/events'
import TableRowActions from 'elements/TableRowActions'
import Customize from 'elements/Customize'
import ResizableCell from 'elements/ResizableCell'
import Drawer from 'elements/Drawer'
import Toolbar from 'elements/Toolbar'
import Page from 'elements/Page'
import FilterContainer from 'elements/FilterContainer'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import SelectFilterTemplates from 'containers/FilterTemplates/Select'
import { Row, Col } from 'elements/Grid'
import UnderDevelopment from 'elements/UnderDevelopment'

const TableSummary = styled(({ loading, ...rest }) => <Col {...rest} />)`
  padding-top: 5px;
  color: ${(props) => (props.loading ? '#CCC' : '#666')};
`

const ScheduleReport = linkTargets.reportTaskRecord.formComponent

const saveStateFields = [
  'columnWidths',
  'filterDto',
  'pageSize',
  'search',
  'searchType',
  'selectedColumnKeys',
  'sortBy',
  'sortOrder',
  'sortedColumnKeys',
  'searchFields',
  'showFilter',
]

export default ({
    entityName,
    getStorageKey = (self) =>
      self.props.storageKey ? self.props.storageKey : entityName ? `${entityName}.listView` : '__LIST_VIEW__',
    getIdField = (self) => 'id',
    filterTemplateType, // NOTE: If not specified, the filter will save locally
    primaryLinkTarget,
    createButtonTextId = 'create',
    createButtonLinkTarget = linkTargets[primaryLinkTarget], // { formComponent, formSize } or memoize((self) => ({ formComponent, formSize }), self => {})
    extraRowActions,
    extraTableButtons,
    tableSummary,
    sharedComponents,
    initialFilterDto = (self) => ({ ...self.props.initialFilterDto }),
    initialSearchFields = (self) => [],
    allowSearching = (self) => true,
    allowSelection = (self) => true,
    allowDelete = (self) => true,
    deleteDisabled = (self) => false,
    allowCreate = (self) => true,
    createDisabled = (self) => false,
    allowDeactivate = (self) => false,
    deactivateDisabled = (self) => false,
    allowActionsMenu = (self) => true,
    tableCellComponents = {},
    totalsRowComponents = {},
    tableHeadGroups = (self) => ({
      // dtoFieldName: () => []
    }),
    getSettingsType = (self) => '',
    getFileTemplateTypes = (self) => [],
    getRowClassName = (self) => (record, index) => '',
    getRowSelectionCheckboxProps = (self) => (record) => ({
      // disabled
    }),
    transformGetItemsParams = (self) => ({}),
    getDeleteButtonTooltip = (self) => null,
    getDeactivateButtonTooltip = (self) => null,
    allowCustomize = (self) => true,
    resetColumnsOnFieldSettingChanges = (self) => false,
    getDeleteItemsOptions = (self) => ({}),
    getDeactivateItemsOptions = (self) => ({}),
    allowInlineEditing = (self) => false,
  }) =>
  (Filter) => {
    if (isEmpty(entityName)) {
      throw new Error('entityName is empty')
    }

    return class ListView extends React.Component {
      state = {
        search: '',
        searchFields: initialSearchFields(this),
        searchType: 'AllWords',
        pageIndex: 1,
        pageSize: 20,
        selectedRowKeys: [],
        selectedColumnKeys: [],
        sortedColumnKeys: [],
        columnWidths: {},
        items: [],
        fieldSettings: [],
        filterTemplates: [],
        filterTemplateKey: Date.now(),
        filterDto: { ...initialFilterDto(this) },
        filterTemplateLoading: false,
      }

      cancelablePromises = []

      constructor(props) {
        super(props)

        this.printableRef = React.createRef()
        this.saveState = debounce(this.saveState, DEBOUNCE)
        this.searchItems = debounce(this.fetchItems, DEBOUNCE)
        this.handleCustomizeOk = createHandleCustomizeOk(this)
        this.handleFilterChange = createHandleFilterChange(this)
        this.handleColumnResize = createHandleColumnResize(this)
        this.handleTableChange = createListViewHandleTableChange(this)
        this.resizeTableBody = debounce(this.resizeTableBody, 250)
      }

      async componentDidMount() {
        await this.fetchSettings()
        await this.resetState()

        const startupLinkTarget = getSessionItem(STARTUP_REDIRECT_SESSION_KEY)

        if (!isEmpty(startupLinkTarget)) {
          try {
            const { linkTargetName, linkTargetText, linkTargetRecord } = startupLinkTarget
            const editDrawerLinkTarget = linkTargets[linkTargetName]
            const { drawerTitleLanguageKey } = editDrawerLinkTarget ?? {}

            if (linkTargetRecord) {
              this.setState({
                editDrawerVisible: true,
                editDrawerSaving: false,
                editDrawerLinkTarget,
                editDrawerLinkTargetRecord: linkTargetRecord,
                editDrawerLinkTargetText: drawerTitleLanguageKey
                  ? `${t(drawerTitleLanguageKey)} - ${linkTargetText}`
                  : linkTargetText,
              })
            }
          } catch (error) {
            showError({ error })
          } finally {
            removeSessionItem(STARTUP_REDIRECT_SESSION_KEY)
          }
        }

        Emitter.on(TOKEN_REFRESHED_BY_FORCE, this.handleTokenRefreshedByForce)

        window.addEventListener('resize', this.resizeTableBody)
      }

      componentWillUnmount() {
        Emitter.off(TOKEN_REFRESHED_BY_FORCE, this.handleTokenRefreshedByForce)

        this.cancelablePromises.forEach((each) => each.cancel?.())
        this.cancelablePromises = []

        window.removeEventListener('resize', this.resizeTableBody)
      }

      promiseState = (state = {}) => new Promise((resolve) => this.setState(state, resolve))

      handleTokenRefreshedByForce = () =>
        this.setState({ items: [] }, async () => {
          await this.fetchSettings()
          await this.fetchItems()
        })

      async componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
          await this.fetchSettings()
          await this.resetState()
        }
      }

      fetchSettings = async () => {
        try {
          const fileTemplateTypes = getFileTemplateTypes(this)
          const { settingsType = getSettingsType(this) } = this.props
          const [fieldSettings, fileTemplates] = await Promise.all([
            this.props
              .getSettings({ type: settingsType })
              .then((r) => get(r, 'value.data.fieldSettings', [])),
            this.fetchFileTemplates(fileTemplateTypes),
          ])

          const nextState = {
            fileTemplates,
            fileTemplateTypes,
            fieldSettings,
          }

          this.setState(nextState)

          return nextState
        } catch (error) {
          showError({ error })

          return error
        }
      }

      resetState = async () => {
        if (this.props.location.search) {
          const decodedState = mergeAll({}, { filterDto: initialFilterDto(this) }, decodeState())

          return this.setState(decodedState, this.fetchItems)
        } else if (filterTemplateType) {
          try {
            this.setState({ filterTemplateLoading: true })

            const { showFilter, filterTemplateId } = getStorageItem(getStorageKey(this), {})

            const filterTemplates = await this.fetchFilterTemplates()

            const filterTemplate = filterTemplateId
              ? await this.props
                  .getFilterTemplate(filterTemplateId)
                  .then((r) => r.value.data)
                  .catch(() => filterTemplates.find(filterUntitled))
              : filterTemplates.find(filterUntitled)

            return this.setState(
              {
                showFilter,
                filterTemplates,
                filterTemplateId: filterTemplate?.id,
                filterTemplateKey: Date.now(),
                ...tryParseJSON(filterTemplate?.filterJSON),
              },
              this.fetchItems
            )
          } catch (error) {
            showError({ error })

            return this.setState({ filterTemplateLoading: false })
          }
        } else {
          return this.setState(
            {
              filterDto: { ...initialFilterDto(this) },
              ...getStorageItem(getStorageKey(this)),
            },
            this.fetchItems
          )
        }
      }

      fetchItems = async ({ resetSelectedRowKeysOnFetchItems = true } = {}) => {
        this.cancelablePromises.forEach((each) => each.cancel?.())
        this.cancelablePromises = []

        const fileTemplateTypes = getFileTemplateTypes(this)
        const { settingsType = getSettingsType(this) } = this.props

        let fileTemplates = this.state.fileTemplates

        if (!isEqual(fileTemplateTypes, this.state.fileTemplateTypes)) {
          fileTemplates = await this.fetchFileTemplates(fileTemplateTypes)
        }

        const fieldSettings = await this.props
          .getSettings({ type: settingsType })
          .then((r) => get(r, 'value.data.fieldSettings', []))
          .catch(() => [])

        let selectedColumnKeys = this.state.selectedColumnKeys
        let sortedColumnKeys = this.state.sortedColumnKeys

        const prevVisibleColumnKeys = getVisibleColumnKeys(this.state.fieldSettings)
        const nextVisibleColumnKeys = getVisibleColumnKeys(fieldSettings)

        if (!isEqual(nextVisibleColumnKeys, prevVisibleColumnKeys)) {
          console.log('Updated visibleColumnKeys:', {
            prev: prevVisibleColumnKeys,
            next: nextVisibleColumnKeys,
          })

          if (resetColumnsOnFieldSettingChanges(this)) {
            selectedColumnKeys = nextVisibleColumnKeys
            sortedColumnKeys = []
          }
        }

        try {
          const cancelablePromise = this.props.getItems({
            ...this.state.filterDto,
            ...pick(this.state, ['pageIndex', 'pageSize', 'search', 'searchType']),
            ...mapGetItemsFields({
              selectedColumnKeys,
              fieldSettings,
              ...pick(this.state, ['searchFields', 'sortBy', 'sortOrder']),
            }),
            ...transformGetItemsParams(this),
          })

          this.cancelablePromises.push(cancelablePromise)

          const response = await cancelablePromise

          const items = get(response, 'value.data.items', [])

          if (!isEqual(this.props.items, items)) {
            console.error('incorrect selector in container: state.items !== props.items')
          }

          this.setState({
            items,
            pageTotals: get(response, 'value.data.pageTotals', {}),
            reportJSON: getReportJSON(response),
            fieldSettings,
            sortedColumnKeys,
            selectedColumnKeys,
            fileTemplateTypes,
            fileTemplates,
          })
        } catch (error) {
          showError({ error })
        } finally {
          this.setState(
            produce((draft) => {
              draft.filterTemplateLoading = false

              if (resetSelectedRowKeysOnFetchItems) {
                draft.selectedRowKeys = []
              }
            }),
            () => {
              this.cancelablePromises = []
              this.props.onFilter?.(this.state.filterDto)
              this.props.onSelect?.([], this.state.filterDto)
              this.saveState()
            }
          )
        }
      }

      downloadItems = async ({
        objectType = '',
        acceptMimeType = 'text/csv',
        fileName = '',
        selectedColumnKeys = this.state.sortedColumnKeys.filter((each) =>
          this.state.selectedColumnKeys.includes(each)
        ),
      } = {}) => {
        const { search, searchType, searchFields, sortBy, sortOrder, fieldSettings, filterTemplateId } =
          this.state

        try {
          message.info(t('saveAsMessage'))

          let filterDto = this.state.filterDto

          if (filterTemplateType && filterTemplateId) {
            const filterTemplate = await this.props.getFilterTemplate(filterTemplateId)

            filterDto = parseReportJSON(filterTemplate.value.data.reportJSON, filterDto)
          }

          console.assert(this.props[`download${objectType}Items`])

          const response = await invoke(this.props, `download${objectType}Items`, {
            ...filterDto,
            search,
            searchType,
            acceptMimeType,
            pageTitle: this.getPageTitle(),
            ...mapGetItemsFields({
              sortBy,
              sortOrder,
              selectedColumnKeys,
              fieldSettings,
              searchFields,
            }),
            ...transformGetItemsParams(this),
          })

          saveAs(response.value.data, fileName)
        } catch (error) {
          if (isFunction(error?.response?.data?.text)) {
            error.response.data
              .text()
              .then((text) => showError({ error: { response: { data: tryParseJSON(text, {}) } } }))
          } else {
            showError({ error })
          }
        }
      }

      saveState = async () => {
        if (this.props.location.search) {
          return
        }

        if (!filterTemplateType) {
          setStorageItem(getStorageKey(this), pick(this.state, saveStateFields))
          return
        }

        const filterJSON = this.getFilterJSON()

        const filterTemplates = !isEmpty(this.state.filterTemplates)
          ? this.state.filterTemplates
          : await this.fetchFilterTemplates()

        const filterTemplate =
          filterTemplates.find((one) => one.id === this.state.filterTemplateId) ||
          filterTemplates.find(filterUntitled)

        const { showFilter } = this.state
        const filterTemplateId = filterTemplate?.id

        setStorageItem(getStorageKey(this), {
          showFilter,
          filterTemplateId,
        })

        if (filterTemplate && isEmpty(filterTemplate.name) && filterTemplate.filterJSON !== filterJSON) {
          try {
            const saved = await this.props
              .updateFilterTemplate({
                ...filterTemplate,
                filterJSON,
                reportJSON: this.state.reportJSON,
              })
              .then((r) => r.value.data)

            this.setState(
              produce((draft) => {
                remove(draft.filterTemplates, (one) => one.id === saved.id)
                draft.filterTemplates.push(saved)
              })
            )
          } catch (error) {
            showError({ error })
          }
        }
      }

      getFilterJSON = () =>
        JSON.stringify(
          omit(
            {
              ...pick(this.state, saveStateFields),
              searchFields: mapSearchFields(this.state.searchFields, this.state.fieldSettings),
            },
            ['showFilter']
          )
        )

      handleToggleFilterClick = () =>
        this.setState((prev) => ({ showFilter: !prev.showFilter }), this.saveState)

      handleClearFilterClick = () => {
        const { dateRange, dateRangeField } = this.state.filterDto

        this.setState(
          {
            filterDto: { ...initialFilterDto(this), dateRange, dateRangeField },
            pageIndex: 1,
            search: '',
            searchType: 'AllWords',
            searchFields: initialSearchFields(this),
          },
          () => {
            if (this.props.location.search) {
              this.props.history.push(this.props.location.pathname)
            }

            this.fetchItems()
          }
        )
      }

      handleLinkTargetClick = ({ column, record, text, readOnly }) => {
        if (process.env.NODE_ENV === 'development') console.log({ column, record, text, readOnly })

        const editDrawerLinkTarget = linkTargets[column.linkTarget]
        const { drawerTitleLanguageKey } = editDrawerLinkTarget ?? {}

        this.setState({
          editDrawerVisible: true,
          editDrawerSaving: false,
          editDrawerLinkTarget,
          editDrawerLinkTargetRecord: record,
          editDrawerLinkTargetColumn: column,
          editDrawerLinkTargetReadOnly: readOnly || column.linkTargetIsReadOnly,
          editDrawerLinkTargetText: drawerTitleLanguageKey ? `${t(drawerTitleLanguageKey)} - ${text}` : text,
        })
      }

      handlePaginationChange = (pageIndex, pageSize) =>
        this.setState({ pageIndex, pageSize }, this.fetchItems)

      handleConfirmDelete = async () => {
        try {
          this.setState({ deleteButtonLoading: true })

          const { selectedRowKeys = [] } = this.state
          const response = await this.props.deleteItems(selectedRowKeys, getDeleteItemsOptions(this))

          this.props.onDelete?.(selectedRowKeys)

          showClientNotifications({ response })
        } catch (error) {
          showError({ error })
        } finally {
          this.setState({ deleteButtonLoading: false }, this.fetchItems)
        }
      }

      handleConfirmDeactivate = async () => {
        try {
          this.setState({ deactivateButtonLoading: true })

          const { selectedRowKeys = [] } = this.state
          const response = await this.props.deactivateItems(selectedRowKeys, getDeactivateItemsOptions(this))

          this.props.onDeactivate?.(selectedRowKeys)

          showClientNotifications({ response })
        } catch (error) {
          showError({ error })
        } finally {
          this.setState({ deactivateButtonLoading: false }, this.fetchItems)
        }
      }

      handleDrawerClose = () =>
        this.setState({
          createDrawerVisible: false,
          editDrawerVisible: false,
          createDrawerSaving: false,
          editDrawerSaving: false,
        })

      handleActionsMenuClick = async ({ key = '' }) => {
        if (this.hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
          return
        }

        if (this.hasUnsavedFilter()) {
          message.error(t('saveFilterFirst'), 3.5)
          return
        }

        switch (key) {
          case get(key.match(/^fileTemplate:\d+$/), 'input'):
            try {
              const fileTemplateId = key.replace(/^fileTemplate:/, '')
              const fileTemplate = await this.props.getFileTemplate(fileTemplateId).then((r) => r.value.data)
              const acceptMimeType = getMimeType(fileTemplate.fileTemplateFileType)

              this.downloadItems({
                acceptMimeType,
                selectedColumnKeys: fileTemplate.fields,
                fileName: `${fileTemplate.name}.${getFileExtension(acceptMimeType)}`,
                objectType: fileTemplate.objectType,
              })
            } catch (error) {
              showError({ error })
            }
            break

          case get(key.match(/^upload:.+$/), 'input'):
            try {
              const objectType = key.replace(/^upload:/, '')

              if (objectType !== 'CatalogProduct') {
                this.props.history.push(`/tools/dataExchange/uploadData?objectType=${objectType}`)
              } else {
                this.props.history.push(
                  `/tools/dataExchange/uploadData?objectType=${objectType}&catalogTableName=${this.state.filterDto.catalogTableName}`
                )
              }
            } catch (error) {
              showError({ error })
            }
            break

          case 'customizeColumns':
            this.setState({ customizeColumnsVisible: true })
            break

          case 'scheduleReport':
            this.setState({
              scheduleReportVisible: true,
              scheduleReportSaving: false,
            })
            break

          case 'text/csv':
          case 'text/html':
          case 'application/pdf':
          case 'application/vnd.ms-excel':
            this.downloadItems({
              acceptMimeType: key,
              fileName: `${this.getPageTitle()}.${getFileExtension(key)}`,
            })
            break

          default:
            message.warn(t('underDevelopment'))
            break
        }
      }

      handleFilterMenuClick = async ({ key }) => {
        let { filterTemplates } = this.state

        if (key === 'sharedFilterTemplate') {
          this.setState({ selectSharedFilterTemplateVisible: true })
        }

        if (key === 'saveFilterTemplate') {
          try {
            const filterTemplate = filterTemplates.find((one) => one.id === this.state.filterTemplateId)
            const filterJSON = this.getFilterJSON()

            const saved = await this.props
              .updateFilterTemplate({
                ...filterTemplate,
                filterJSON,
                reportJSON: this.state.reportJSON,
              })
              .then((r) => r.value.data)

            this.setState(
              produce((draft) => {
                draft.filterTemplates.find((one) => one.id === saved.id).filterJSON = saved.filterJSON
              }),
              this.fetchItems
            )
          } catch (error) {
            showError({ error })
          }
        }

        if (key === 'moveFilterTemplate') {
          const filterTemplate = filterTemplates.find((one) => one.id === this.state.filterTemplateId)

          this.setState({
            moveFilterTemplateVisible: true,
            moveFilterTemplateInput: filterTemplate.name,
          })
        }

        if (key === 'deleteFilterTemplate') {
          const { numberOfScheduledTasks } = filterTemplates.find(
            (one) => one.id === this.state.filterTemplateId
          )

          Modal.confirm({
            autoFocusButton: 'ok',
            title: t('confirmFilterDeleteTitle'),
            content: numberOfScheduledTasks
              ? `${t('confirmFilterDeleteDescription')} ${numberOfScheduledTasks}`
              : t('confirmUnusedFilterDeleteDescription'),
            okText: t('delete'),
            okType: 'danger',
            cancelText: t('cancel'),
            onOk: async () => {
              try {
                const response = await this.props.deleteFilterTemplate(this.state.filterTemplateId)

                showClientNotifications({ response })

                if (response.value.data.failureCount > 0) {
                  throw new Error()
                }

                filterTemplates = await this.fetchFilterTemplates()

                const filterTemplate = await this.props
                  .getFilterTemplate(filterTemplates.find(filterUntitled).id)
                  .then((r) => r.value.data)

                this.setState(
                  {
                    filterTemplates,
                    filterTemplateId: filterTemplate.id,
                    filterTemplateKey: Date.now(),
                    filterTemplateLoading: true,
                    ...tryParseJSON(filterTemplate.filterJSON),
                  },
                  this.fetchItems
                )
              } catch (error) {
                showError({ error })
              }
            },
          })
        }

        if (key.match(/^\d*$/)) {
          try {
            const filterTemplate = await this.props.getFilterTemplate(key).then((r) => r.value.data)

            this.setState(
              {
                filterTemplateId: filterTemplate.id,
                filterTemplateKey: Date.now(),
                filterTemplateLoading: true,
                ...tryParseJSON(filterTemplate.filterJSON),
              },
              this.fetchItems
            )
          } catch (error) {
            showError({ error })
          }
        }
      }

      handleMoveFilterTemplate = async () => {
        let { filterTemplates } = this.state

        if (this.props.location.search) {
          return
        }

        try {
          this.setState({ moveFilterTemplateSaving: true })

          const name = trim(this.state.moveFilterTemplateInput)
          const filterJSON = this.getFilterJSON()
          const filterTemplate = filterTemplates.find((one) => strEqual(one.name, name))
          const reportJSON = this.state.reportJSON
          const saved =
            filterTemplate && strEqual(filterTemplate.owner, this.props.user.userName)
              ? await this.props.updateFilterTemplate({
                  id: filterTemplate.id,
                  name,
                  filterJSON,
                  filterTemplateType,
                  reportJSON,
                })
              : await this.props.createFilterTemplate({
                  name,
                  filterJSON,
                  filterTemplateType,
                  reportJSON,
                })

          filterTemplates = await this.fetchFilterTemplates()

          this.setState(
            {
              filterTemplates,
              filterTemplateId: saved.value.data.id,
              moveFilterTemplateVisible: false,
            },
            this.fetchItems
          )
        } catch (error) {
          showError({ error })
        } finally {
          this.setState({ moveFilterTemplateSaving: false })
        }
      }

      handleSelectSharedFilterTemplate = async () => {
        try {
          const { id } = this.state.selectedSharedFilterTemplate
          const filterTemplate = await this.props.getFilterTemplate(id).then((r) => r.value.data)

          this.setState(
            {
              filterTemplateId: filterTemplate.id,
              filterTemplateKey: Date.now(),
              filterTemplateLoading: true,
              ...tryParseJSON(filterTemplate.filterJSON),
            },
            this.fetchItems
          )
        } catch (error) {
          showError({ error })
        }
        this.setState({ selectSharedFilterTemplateVisible: false })
      }

      fetchFileTemplates = (fileTemplateTypes) =>
        !isEmpty(fileTemplateTypes)
          ? this.props
              .getFileTemplates({
                objectType: fileTemplateTypes.length === 1 ? fileTemplateTypes[0] : undefined,
              })
              .then((r) => get(r, 'value.data.items', []))
              .catch(() => {})
          : Promise.resolve([])

      fetchFilterTemplates = async () => {
        const filterTemplates = await this.props
          .getFilterTemplates({
            filterTemplateType,
            owner: this.props.user.userName,
            includeShared: true,
          })
          .then((r) => get(r, 'value.data.items', []))

        if (filterTemplates.find(filterUntitled)) {
          return filterTemplates
        }

        await this.props.createFilterTemplate({
          name: '',
          filterTemplateType,
          filterJSON: this.getFilterJSON(),
          reportJSON: this.state.reportJSON,
        })

        return this.fetchFilterTemplates()
      }

      getPageTitle = () => {
        const filterTemplate = (this.state.filterTemplates ?? []).find(
          (one) => one.id === this.state.filterTemplateId
        )

        const owner = get(filterTemplate, 'owner', '')
        const ownerLabel = owner && !strEqual(owner, this.props.user.userName) ? `(${owner})` : ''

        return [getPageTitle(), `${get(filterTemplate, 'name', '')} ${ownerLabel}`]
          .map(trim)
          .filter((each) => !isEmpty(each))
          .join(' - ')
      }

      hasUnsavedChanges = () =>
        !isEmpty(this.props.items) &&
        !isEmpty(this.state.items) &&
        !isEqual(this.props.items, this.state.items)

      hasUnsavedFilter = () => {
        try {
          const filterTemplate = this.state.filterTemplates.find(
            (one) => one.id === this.state.filterTemplateId
          )

          if (isEmpty(filterTemplate?.name)) {
            return false
          }

          return !isEqual(JSON.parse(filterTemplate?.filterJSON), JSON.parse(this.getFilterJSON()))
        } catch (error) {
          return false
        }
      }

      resizeTableBody = () => {
        try {
          const tableContainer = document.getElementById('list-view-table-container')
          const tableHead = tableContainer.getElementsByClassName('ant-table-thead')[0]
          const tableBody = tableContainer.getElementsByClassName('ant-table-body')[0]
          const maxHeight = tableContainer.clientHeight - tableHead.clientHeight

          tableBody.style['max-height'] = maxHeight ? `${maxHeight}px` : '250px'
          tableBody.style['overflow-y'] = 'auto'
        } catch (error) {
          console.warn(error)
        }
      }

      render() {
        const { fieldSettings = [], filterTemplates = [] } = this.state

        if (isEmpty(fieldSettings)) {
          return (
            <Page className="tofino-list-view" title={this.getPageTitle()} loading>
              <Spin />
            </Page>
          )
        }

        const filterTemplateName = filterTemplates.find((one) => one.id === this.state.filterTemplateId)?.name
        const filterTemplateIsShared =
          toLower(filterTemplates.find((one) => one.id === this.state.filterTemplateId)?.owner) !==
          toLower(this.props.user.userName)

        const columns = getColumns({
          self: this,
          primaryLinkTarget,
          tableCellComponents,
          totalsRowComponents,
          tableHeadGroups,
          ...pick(this.state, [
            'sortBy',
            'sortedColumnKeys',
            'sortOrder',
            'filterDto',
            'columnWidths',
            'pageTotals',
            'fieldSettings',
          ]),
          onClick: this.handleLinkTargetClick,
          onResize: this.handleColumnResize,
          selectedColumnKeys: allowCustomize(this) ? this.state.selectedColumnKeys : [],
        })
        const width = isEmpty(columns) ? '100%' : getTableWidth(columns, allowSelection(this)) + 15
        const CreateFormView = get(
          isFunction(createButtonLinkTarget) ? createButtonLinkTarget(this) : createButtonLinkTarget,
          'formComponent',
          UnderDevelopment
        )
        const EditFormView = this.state.editDrawerLinkTarget?.formComponent ?? UnderDevelopment
        const loading =
          this.props.loading ||
          this.state.loading ||
          this.state.deleteButtonLoading ||
          this.state.deactivateButtonLoading ||
          this.state.filterTemplateLoading
        const uploadDataObjectTypes = getFileTemplateTypes(this).filter((each) =>
          Object.keys(this.props.allowedFileTemplateTypes).includes(each)
        )
        this.dataSource = [
          ...this.state.items,
          ...getPageTotals(this.state.items, columns, this.state.pageTotals),
        ]

        this.resizeTableBody()

        // console.log({ items: this.state.items, fieldSettings })

        return (
          <>
            <Page className="tofino-list-view" title={this.getPageTitle()} loading={loading}>
              <div className="tofino-inner-container">
                <Toolbar
                  title={this.getPageTitle()}
                  search={this.state.search}
                  onSearchChange={
                    allowSearching(this)
                      ? (e) => this.setState({ search: e.target.value, pageIndex: 1 }, this.searchItems)
                      : undefined
                  }
                  searchType={this.state.searchType}
                  onSearchTypeChange={(value) => this.setState({ searchType: value }, this.searchItems)}
                  searchFields={this.state.searchFields}
                  fieldSettings={fieldSettings}
                  onSearchFieldsChange={(values) => this.setState({ searchFields: values }, this.searchItems)}
                  actionsMenuItems={[
                    fieldSettings.length > 1 ? (
                      <Menu.Item key="customizeColumns">{t('customizeColumns')}</Menu.Item>
                    ) : null,
                    <Menu.SubMenu key="export" title={t('export')}>
                      <Menu.Item key="text/csv">{t('asCsv')}</Menu.Item>
                      <Menu.Item key="application/vnd.ms-excel">{t('asExcel')}</Menu.Item>
                      <Menu.Item key="application/pdf">{t('asPdf')}</Menu.Item>
                      <Menu.Item key="text/html">{t('asHtml')}</Menu.Item>
                    </Menu.SubMenu>,
                    uploadDataObjectTypes.length === 1 ? (
                      <Menu.SubMenu key="download" title={t('download')}>
                        {(this.state.fileTemplates ?? [])
                          .filter((each) => each.objectType === uploadDataObjectTypes[0])
                          .map((each) => (
                            <Menu.Item key={`fileTemplate:${each.id}`}>{each.name}</Menu.Item>
                          ))}
                      </Menu.SubMenu>
                    ) : uploadDataObjectTypes.length > 1 ? (
                      <Menu.SubMenu key="download" title={t('download')}>
                        {uploadDataObjectTypes.map((objectType) => (
                          <Menu.SubMenu
                            key={`objectType:${objectType}`}
                            title={t(this.props.allowedFileTemplateTypes[objectType])}
                          >
                            {(this.state.fileTemplates ?? [])
                              .filter((each) => each.objectType === objectType)
                              .map((each) => (
                                <Menu.Item key={`fileTemplate:${each.id}`}>{each.name}</Menu.Item>
                              ))}
                          </Menu.SubMenu>
                        ))}
                      </Menu.SubMenu>
                    ) : null,
                    uploadDataObjectTypes.length === 1 ? (
                      <Menu.Item key={`upload:${uploadDataObjectTypes[0]}`}>{t('upload')}</Menu.Item>
                    ) : uploadDataObjectTypes.length > 1 ? (
                      <Menu.SubMenu key="upload" title={t('upload')}>
                        {uploadDataObjectTypes.map((each) => (
                          <Menu.Item key={`upload:${each}`}>
                            {t(this.props.allowedFileTemplateTypes[each])}
                          </Menu.Item>
                        ))}
                      </Menu.SubMenu>
                    ) : null,
                    ...(!isNil(Filter) && filterTemplateType && isEmpty(this.props.location.search)
                      ? [
                          <Menu.Divider key="scheduleReportDivider" />,
                          <Menu.Item
                            key="scheduleReport"
                            disabled={isEmpty(filterTemplateName) || filterTemplateIsShared}
                          >
                            {t('scheduleReport')}
                          </Menu.Item>,
                        ]
                      : []),
                  ].filter(Boolean)}
                  onActionsMenuClick={allowActionsMenu(this) ? this.handleActionsMenuClick : undefined}
                  afterSearchContents={
                    <div className="tofino-after-search">
                      {!isNil(Filter) && filterTemplateType && isEmpty(this.props.location.search) ? (
                        <Badge
                          {...(this.hasUnsavedFilter() && !filterTemplateIsShared ? { count: '!' } : {})}
                        >
                          <Dropdown.Button
                            trigger={['hover', 'click']}
                            onClick={this.handleToggleFilterClick}
                            overlay={
                              <Menu
                                onClick={this.handleFilterMenuClick}
                                selectedKeys={
                                  filterTemplateIsShared
                                    ? ['sharedFilterTemplate']
                                    : this.state.filterTemplateId
                                      ? [str(this.state.filterTemplateId)]
                                      : []
                                }
                              >
                                <Menu.SubMenu title={t('open')}>
                                  {filterTemplates.filter(filterUntitled).map((each) => (
                                    <Menu.Item key={each.id}>{t('default')}</Menu.Item>
                                  ))}
                                  <Menu.Item key="sharedFilterTemplate">{t('shared')}</Menu.Item>
                                  <Menu.Divider />
                                  {filterTemplates
                                    .filter(
                                      (each) => each.name && strEqual(each.owner, this.props.user.userName)
                                    )
                                    .map((each) => (
                                      <Menu.Item key={each.id}>{each.name}</Menu.Item>
                                    ))}
                                </Menu.SubMenu>
                                <Menu.Item
                                  key="saveFilterTemplate"
                                  disabled={
                                    isEmpty(filterTemplateName) ||
                                    !this.hasUnsavedFilter() ||
                                    filterTemplateIsShared
                                  }
                                >
                                  {t('save')}{' '}
                                  {this.hasUnsavedFilter() && !filterTemplateIsShared && (
                                    <Badge status="warning" />
                                  )}
                                </Menu.Item>
                                <Menu.Item key="moveFilterTemplate">{t('saveAs')}</Menu.Item>
                                <Menu.Divider />
                                <Menu.Item
                                  key="deleteFilterTemplate"
                                  disabled={isEmpty(filterTemplateName) || filterTemplateIsShared}
                                >
                                  {t('delete')}
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            {t(this.state.showFilter ? 'hideFilter' : 'showFilter')}
                          </Dropdown.Button>
                        </Badge>
                      ) : !isNil(Filter) ? (
                        <Button onClick={this.handleToggleFilterClick}>
                          {t(this.state.showFilter ? 'hideFilter' : 'showFilter')}
                        </Button>
                      ) : null}
                    </div>
                  }
                  afterActionsContents={
                    allowCreate(this) ? (
                      <Tooltip title={t(createButtonTextId)} placement="bottomLeft">
                        <Button
                          type="primary"
                          onClick={() => this.setState({ createDrawerVisible: true })}
                          disabled={createDisabled(this)}
                          style={{ padding: '0 8px' }}
                        >
                          <Icon type="Add" bold />
                        </Button>
                      </Tooltip>
                    ) : null
                  }
                  allowCustomize={allowCustomize(this)}
                />
                {!isNil(Filter) && this.state.showFilter && (
                  <FilterContainer
                    onClear={this.handleClearFilterClick}
                    onClose={this.handleToggleFilterClick}
                    clearText={this.props.location.search ? t('resetFilter') : t('clearAll')}
                    loading={this.state.filterTemplateLoading}
                  >
                    <Filter
                      key={this.state.filterTemplateKey}
                      filterDto={this.state.filterDto}
                      onChange={this.handleFilterChange}
                      items={this.state.items ?? []}
                      {...this.props.filterProps}
                    />
                  </FilterContainer>
                )}
                {(allowSelection(this) || tableSummary) && (
                  <Row
                    type="flex"
                    justify="space-between"
                    className="tofino-list-row-actions whitespace-nowrap mb-12"
                  >
                    {allowSelection(this) && (
                      <Col>
                        <TableRowActions
                          selectedRowKeys={this.state.selectedRowKeys}
                          extraActions={extraRowActions?.(this)}
                          // delete
                          allowDelete={allowDelete(this)}
                          deleteDisabled={deleteDisabled(this)}
                          onDelete={this.handleConfirmDelete}
                          deleteTooltip={getDeleteButtonTooltip(this)}
                          deleteLoading={this.state.deleteButtonLoading}
                          // deactivate
                          allowDeactivate={allowDeactivate(this)}
                          deactivateDisabled={deactivateDisabled(this)}
                          onDeactivate={this.handleConfirmDeactivate}
                          deactivateTooltip={getDeactivateButtonTooltip(this)}
                          deactivateLoading={this.state.deactivateButtonLoading}
                        />
                      </Col>
                    )}
                    {tableSummary && <TableSummary loading={loading}>{tableSummary(this)}</TableSummary>}
                  </Row>
                )}
                <div id="list-view-table-container" className="tofino-table-container">
                  <Table
                    childrenColumnName={[]}
                    rowKey={getIdField(this)}
                    loading={loading}
                    columns={columns}
                    dataSource={this.dataSource}
                    rowSelection={
                      allowSelection(this)
                        ? {
                            selectedRowKeys: this.state.selectedRowKeys,
                            onChange: (rowKeys) => this.setState({ selectedRowKeys: rowKeys }),
                            getCheckboxProps: (record) =>
                              record.id === TOTALS_ROW_ID
                                ? { disabled: true }
                                : getRowSelectionCheckboxProps(this)(record),
                          }
                        : undefined
                    }
                    rowClassName={(record, index) => {
                      const original = this.props.items.find((one) => one.id === record.id)
                      const existing = this.state.items.find((one) => one.id === record.id)

                      return cx(
                        { 'table-row-strong': original && existing && !isEqual(original, existing) },
                        { 'table-row-page-totals': record.id === TOTALS_ROW_ID },
                        getRowClassName(this)(record, index)
                      )
                    }}
                    pagination={false}
                    onChange={this.handleTableChange}
                    size="middle"
                    locale={{ emptyText: t('noData') }}
                    style={{ width }}
                    components={{ header: { cell: ResizableCell } }}
                    scroll={{ y: 250 }}
                  />
                </div>
                <Row
                  type="flex"
                  justify="space-between"
                  style={{
                    margin: '24px 0',
                    height: '32px',
                  }}
                >
                  <Col>
                    {this.props.pagination && (
                      <Pagination
                        current={this.props.pagination.current ?? 0}
                        total={this.props.pagination.total ?? 0}
                        pageSize={this.props.pagination.pageSize ?? 20}
                        pageSizeOptions={pageSizeOptions}
                        onChange={this.handlePaginationChange}
                        onShowSizeChange={this.handlePaginationChange}
                        showTotal={showTotal}
                        size="small"
                        showTitle={false}
                        showSizeChanger
                        showLessItems
                      />
                    )}
                  </Col>
                  <Col className="text-right">
                    {extraTableButtons?.(this)}
                    {!isEmpty(this.state.items) && allowInlineEditing(this) ? (
                      <>
                        <Tooltip title={t('unsavedChangesWillBeLost')}>
                          <Button
                            className="mr-6"
                            onClick={() => this.setState({ items: cloneDeep(this.props.items) })}
                            disabled={!this.hasUnsavedChanges()}
                          >
                            {t('cancel')}
                          </Button>
                        </Tooltip>
                        <Button
                          type="primary"
                          onClick={async () => {
                            try {
                              this.setState({ saveButtonLoading: true })

                              const items = this.state.items.filter((existing) => {
                                const original = this.props.items.find((one) => one.id === existing.id)

                                return !isEqual(existing, original)
                              })

                              const response = await this.props.updateItems(items)

                              showClientNotifications({ response })

                              this.setState({ saveButtonLoading: false }, this.fetchItems)
                            } catch (error) {
                              showClientNotifications({ error })
                              showError({ error })
                            } finally {
                              this.setState({ saveButtonLoading: false })
                            }
                          }}
                          loading={this.state.saveButtonLoading}
                          disabled={!this.hasUnsavedChanges()}
                        >
                          {t('save')}
                        </Button>
                      </>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </Page>
            <Drawer
              title={t(createButtonTextId)}
              size={get(
                isFunction(createButtonLinkTarget) ? createButtonLinkTarget(this) : createButtonLinkTarget,
                'formSize'
              )}
              visible={this.state.createDrawerVisible}
              onClose={this.handleDrawerClose}
              saving={this.state.createDrawerSaving}
            >
              <CreateFormView
                filterDto={this.state.filterDto}
                onSave={(pending) =>
                  this.promiseState({ createDrawerSaving: pending }).then(async () => {
                    if (!pending) {
                      await this.fetchItems()
                    }
                  })
                }
                onSaveAndClose={this.handleDrawerClose}
                onCancel={this.handleDrawerClose}
              />
            </Drawer>
            <Drawer
              title={this.state.editDrawerLinkTargetText}
              size={this.state.editDrawerLinkTarget?.formSize}
              visible={this.state.editDrawerVisible}
              onClose={this.handleDrawerClose}
              saving={this.state.editDrawerSaving}
            >
              <EditFormView
                linkTargetRecord={this.state.editDrawerLinkTargetRecord}
                filterDto={this.state.filterDto}
                onSave={(pending) =>
                  this.promiseState({ editDrawerSaving: pending }).then(async () => {
                    if (!pending) {
                      await this.fetchItems()
                    }
                  })
                }
                onSaveAndClose={this.handleDrawerClose}
                onCancel={this.handleDrawerClose}
                readOnly={this.state.editDrawerLinkTargetReadOnly}
              />
            </Drawer>
            <Modal
              title={t('saveAs')}
              okText={t('save')}
              onOk={this.handleMoveFilterTemplate}
              okButtonProps={{ loading: this.state.moveFilterTemplateSaving }}
              onCancel={() => this.setState({ moveFilterTemplateVisible: false })}
              visible={this.state.moveFilterTemplateVisible}
            >
              <Form.Item label={t('name')} colon={false}>
                <Input
                  value={this.state.moveFilterTemplateInput}
                  onChange={(e) => this.setState({ moveFilterTemplateInput: e.target.value })}
                  placeholder={t('default')}
                  onPressEnter={this.handleMoveFilterTemplate}
                  autoFocus
                />
              </Form.Item>
            </Modal>
            <Modal
              title={t('selectSharedFilter')}
              visible={this.state.selectSharedFilterTemplateVisible}
              okText={t('select')}
              onOk={this.handleSelectSharedFilterTemplate}
              okButtonProps={{
                loading: this.state.selectFilterTemplateLoading,
                disabled: isNil(this.state.selectedSharedFilterTemplate),
              }}
              onCancel={() => this.setState({ selectSharedFilterTemplateVisible: false })}
              width={750}
            >
              <SelectFilterTemplates
                onSelect={(values) => this.setState({ selectedSharedFilterTemplate: values[0] })}
                initialFilterDto={{ filterTemplateType, includeShared: true }}
              />
            </Modal>
            <Drawer
              title={t('scheduleReport')}
              size={linkTargets.reportTaskRecord.formSize}
              visible={this.state.scheduleReportVisible}
              onClose={() => this.setState({ scheduleReportVisible: false })}
              saving={this.state.scheduleReportSaving}
            >
              <ScheduleReport
                name={this.getPageTitle()}
                filterTemplateType={filterTemplateType}
                filterTemplateId={this.state.filterTemplateId}
                onSave={(pending) =>
                  this.promiseState({ scheduleReportSaving: pending }).then(async () => {
                    if (!pending) {
                      await this.resetState()
                    }
                  })
                }
                onSaveAndClose={() => this.setState({ scheduleReportVisible: false })}
                onCancel={() => this.setState({ scheduleReportVisible: false })}
              />
            </Drawer>
            <Customize
              displayableColumns={fieldSettings.filter((each) => each.isDisplayable)}
              visible={this.state.customizeColumnsVisible}
              onOk={this.handleCustomizeOk}
              onCancel={() => this.setState({ customizeColumnsVisible: false })}
              selectedColumnKeys={this.state.selectedColumnKeys}
              sortedColumnKeys={this.state.sortedColumnKeys}
              columnWidths={this.state.columnWidths}
            />
            {sharedComponents?.(this)}
          </>
        )
      }
    }
  }
