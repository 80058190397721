import { snakeCase } from 'lodash'
import { baseReportUrl } from 'config'
import { createPost } from 'helpers/api'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'

const entityName = entityNames.inventoryCycleCountDifferenceReport
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const baseUrl = baseReportUrl
const basePath = 'report/inventoryReports'

const defaultActions = createActions(entityName, {
  baseUrl,
  basePath,
})

export default {
  ...defaultActions,

  emailReport: (params = {}) => ({
    type: `${ENTITY_NAME}_EMAIL`,
    payload: createPost(entityName, {
      baseUrl,
      basePath,
      action: 'email',
    })(params),
  }),
}
