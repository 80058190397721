import * as React from 'react'
import { Form, Input, Spin, Tabs } from 'antd'
import { isNil, set, get, range, isEmpty, orderBy } from 'lodash'
import produce from 'immer'
import Select, { Option, getOptionProps } from 'elements/Select'
import Checkbox from 'elements/Checkbox'
import Drawer from 'elements/Drawer'
import CheckboxList from 'elements/CheckboxList'
import InputImage from 'elements/InputImage'
import { Row, Col } from 'elements/Grid'
import { showError, showClientNotifications, resourceNotFound, ValidationError } from 'helpers/errors'
import {
  createLabelFactory,
  createFieldFactory,
  createHandleSubmit,
  createSaveImage,
  getChangedItems,
  isReadOnly,
} from 'helpers/formViews'
import { HARD_SPACE } from 'helpers/utils'
import { getUserPermission } from 'helpers/auth'
import { getCustomerTagSettings, getCustomerTagSettingItems } from 'helpers/procurement'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import FileUpload from 'elements/FileUpload'
import Settings from 'containers/Customers/FormView/Settings'
import Mobile from 'containers/Customers/FormView/Mobile'
import Tags from 'containers/Customers/FormView/Tags'
import Modules from 'containers/Customers/FormView/Modules'
import ApprovalTree from 'containers/Customers/FormView/ApprovalTree'
import Passwords from 'containers/Customers/FormView/Passwords'
import FormButtons from 'elements/FormButtons'

class FormView extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.handleSubmit = createHandleSubmit(this)
    this.saveImage = createSaveImage(this, 'Customer', 'PurchaseOrderLogo')
  }

  componentDidMount() {
    this.fetchItem()
  }

  saveItemSuccess = () => this.props.refreshToken(true)

  getItemId = () =>
    [
      this.state.item?.id,
      this.props.linkTargetRecord?.customerId,
      this.props.linkTargetRecord?.id, // NOTE: It can be opened from nav menu
    ].find(Boolean)

  fetchItem = async ({ itemId = this.getItemId() } = {}) => {
    try {
      const responses = await Promise.all([
        itemId
          ? this.props.getItem(itemId).catch((error) => {
              this.props.onCancel?.()
              throw error
            })
          : !this.props.linkTargetRecord
            ? this.props.newItem({})
            : resourceNotFound(this),
        this.props.getSettings({ type: this.props.settingsType }),
        this.props
          .getDocumentContents({
            request: {
              domainObjectId: this.props.tenant.id,
              domainObjectType: 'Customer',
              documentType: 'PurchaseOrderLogo',
              documentName: this.props.tenant.subdomain,
            },
          })
          .catch(() => {}),
        this.props.getUserGroups(),
        this.props.userIsDcribDistributor ? this.props.getCustomerGroups() : Promise.resolve(null),
        itemId ? this.props.getApprovalTreeItems(itemId) : Promise.resolve(null),
      ])

      this.setState({
        item: get(responses[0], 'value.data', {}),
        fieldSettings: get(responses[1], 'value.data.fieldSettings', []),
        image: get(responses[2], 'value.data', {}),
        imageOriginal: get(responses[2], 'value.data', {}),
        userGroups: get(responses[3], 'value.data.items', []),
        customerGroups: get(responses[4], 'value.data.items', []),
        approvalTreeItems: get(responses[5], 'value.data.items', []),
        approvalTreeItemsOriginal: get(responses[5], 'value.data.items', []),
        approvalTreeItemsTotals: get(responses[5], 'value.data.pageTotals', {}),
      })
    } catch (error) {
      showError({ error })
    }
  }

  setItemValue = (name, value) =>
    this.setState(
      produce((draft) => {
        set(draft.item, name, value)

        this.props.form.setFieldsValue({ [name]: value })
      })
    )

  saveApprovalTreeItems = async (customerId) => {
    const { creating, updating, deleting } = getChangedItems(
      this.state.approvalTreeItemsOriginal,
      this.state.approvalTreeItems
    )

    if (!isEmpty(deleting)) {
      const response = await this.props.deleteApprovalTreeItems(
        customerId,
        deleting.map((each) => each.id)
      )

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }

    if (!isEmpty(updating)) {
      const response = await this.props.updateApprovalTreeItems(
        customerId,
        updating.map((each) => ({ ...each, customerId }))
      )

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }

    if (!isEmpty(creating)) {
      const response = await this.props.createApprovalTreeItems(
        customerId,
        creating.map(({ id, ...rest }) => ({
          ...rest,
          customerId,
        }))
      )

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }
  }

  saveItem = async (item) => {
    if (!item) {
      throw new Error('item is undefined')
    }

    const saved = item.id ? await this.props.updateItem(item) : await this.props.createItem(item)

    await Promise.all([
      this.saveApprovalTreeItems(saved.value.data.id),
      this.saveImage(saved.value.data.id, this.props.tenant.subdomain),
    ])

    return saved
  }

  handleFileUpload = (fileList = []) => {
    if (fileList[0]) {
      this.setState({
        image: {
          contents: fileList[0].fileContents,
          fileType: fileList[0].fileType,
        },
        fileUploadDrawerVisible: false,
      })
    } else {
      this.setState({ fileUploadDrawerVisible: false })
    }
  }

  handleTagsChange = (values) =>
    this.setState(
      produce((draft) => {
        draft.item.tagSettings = getCustomerTagSettings(values)
      })
    )

  handleSettingsChange = (values) =>
    this.setState(
      produce((draft) => {
        draft.item.generalSettings = values
      })
    )

  handleScannerSettingsChange = (values) =>
    this.setState(
      produce((draft) => {
        draft.item.scannerSettings = values
      })
    )

  handleModuleChange = (values) =>
    this.setState(
      produce((draft) => {
        draft.item.moduleSettings = values
      })
    )

  handlePasswordsChange = (values) =>
    this.setState(
      produce((draft) => {
        draft.item.passwordSettings = values
      })
    )

  render() {
    const { item, image, fieldSettings, customerGroups } = this.state

    if (!item || !fieldSettings) {
      return <Spin />
    }

    const createLabel = createLabelFactory(fieldSettings)
    const createFieldDecorator = createFieldFactory(this.props.form, item, fieldSettings)

    const readOnly =
      isReadOnly(this) ||
      (this.props.userIsDcribCustomer && isNil(this.props.impersonator)) ||
      (getUserPermission('Master') !== 'Yes' && !this.props.userIsInRoleSupplier)

    return (
      <>
        <Form layout="vertical" colon={false} onSubmit={readOnly ? stopEvent : this.handleSubmit}>
          <div className="form-items-container">
            <ValidationError errors={this.state.validationErrors} />
            <Row>
              <Col third>
                <Form.Item label={createLabel('name')}>
                  {createFieldDecorator('name')(<Input readOnly={readOnly} autoFocus />)}
                </Form.Item>
                <Form.Item label={createLabel('contactEmail')}>
                  {createFieldDecorator('contactEmail')(<Input readOnly={readOnly} type="email" />)}
                </Form.Item>
                {this.props.userIsDcribDistributor && (
                  <Form.Item label={createLabel('customerNumber')}>
                    {createFieldDecorator('customerNumber')(<Input readOnly={readOnly} />)}
                  </Form.Item>
                )}
              </Col>
              <Col third>
                <Form.Item label={createLabel('contactFirstName')}>
                  {createFieldDecorator('contactFirstName')(<Input readOnly={readOnly} />)}
                </Form.Item>
                <Form.Item label={createLabel('contactLastName')}>
                  {createFieldDecorator('contactLastName')(<Input readOnly={readOnly} />)}
                </Form.Item>
                {this.props.userIsDcribDistributor && (
                  <Form.Item label={HARD_SPACE}>
                    {createFieldDecorator('active', { valuePropName: 'checked' })(
                      <Checkbox readOnly={readOnly}>{createLabel('active')}</Checkbox>
                    )}
                  </Form.Item>
                )}
              </Col>
              <Col third>
                <InputImage
                  label={t('purchaseOrderLogo')}
                  onUpload={() => this.setState({ fileUploadDrawerVisible: true })}
                  onDelete={() => this.setState({ image: null })}
                  value={image}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Tabs
              activeKey={this.state.tabsActiveKey || 'address'}
              onChange={(value) => this.setState({ tabsActiveKey: value })}
            >
              <Tabs.TabPane key="address" tab={t('address')} forceRender>
                <Row gutter={24}>
                  <Col sm={10} xs={24} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.15)' }}>
                    <Form.Item label={t('billTo')}>
                      {createFieldDecorator('mainAddress.address1')(<Input readOnly={readOnly} />)}
                    </Form.Item>
                    <Row>
                      <Col xs={12}>
                        <Form.Item label={createLabel('mainAddress.city')}>
                          {createFieldDecorator('mainAddress.city')(<Input readOnly={readOnly} />)}
                        </Form.Item>
                      </Col>
                      <Col xs={12}>
                        <Form.Item label={createLabel('mainAddress.state')}>
                          {createFieldDecorator('mainAddress.state')(<Input readOnly={readOnly} />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Form.Item label={createLabel('mainAddress.country')}>
                          {createFieldDecorator('mainAddress.country')(<Input readOnly={readOnly} />)}
                        </Form.Item>
                      </Col>
                      <Col xs={12}>
                        <Form.Item label={createLabel('mainAddress.zip')}>
                          {createFieldDecorator('mainAddress.zip')(<Input readOnly={readOnly} />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label={createLabel('mainAddress.phone')}>
                      {createFieldDecorator('mainAddress.phone')(<Input readOnly={readOnly} />)}
                    </Form.Item>
                    <Form.Item label={createLabel('mainAddress.phone2')}>
                      {createFieldDecorator('mainAddress.phone2')(<Input readOnly={readOnly} />)}
                    </Form.Item>

                    <Form.Item label={createLabel('mainAddress.fax')}>
                      {createFieldDecorator('mainAddress.fax')(<Input readOnly={readOnly} />)}
                    </Form.Item>
                  </Col>
                  <Col sm={10} xs={24}>
                    <Form.Item label={t('shipTo')}>
                      {createFieldDecorator('shipAddress.address1')(<Input readOnly={readOnly} />)}
                    </Form.Item>
                    <Row>
                      <Col xs={12}>
                        <Form.Item label={createLabel('shipAddress.city')}>
                          {createFieldDecorator('shipAddress.city')(<Input readOnly={readOnly} />)}
                        </Form.Item>
                      </Col>
                      <Col xs={12}>
                        <Form.Item label={createLabel('shipAddress.state')}>
                          {createFieldDecorator('shipAddress.state')(<Input readOnly={readOnly} />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Form.Item label={createLabel('shipAddress.country')}>
                          {createFieldDecorator('shipAddress.country')(<Input readOnly={readOnly} />)}
                        </Form.Item>
                      </Col>
                      <Col xs={12}>
                        <Form.Item label={createLabel('shipAddress.zip')}>
                          {createFieldDecorator('shipAddress.zip')(<Input readOnly={readOnly} />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label={createLabel('shipAddress.phone')}>
                      {createFieldDecorator('shipAddress.phone')(<Input readOnly={readOnly} type="tel" />)}
                    </Form.Item>
                    <Form.Item label={createLabel('shipAddress.fax')}>
                      {createFieldDecorator('shipAddress.fax')(<Input readOnly={readOnly} type="tel" />)}
                    </Form.Item>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane key="notifications" tab={t('notifications')} forceRender>
                <Row gutter={12}>
                  <Col third>
                    <Form.Item label={createLabel('replyEmail')}>
                      {createFieldDecorator('replyEmail')(<Input readOnly={readOnly} type="email" />)}
                    </Form.Item>
                    <Form.Item label={createLabel('generalSettings.newJobRequestNotificationUserGroup')}>
                      {createFieldDecorator('generalSettings.newJobRequestNotificationUserGroup')(
                        <Select placeholder={t('none')} readOnly={readOnly} allowClear={false}>
                          <Option value={0}>{t('none')}</Option>
                          {(this.state.userGroups ?? [])
                            .filter((each) => each.id)
                            .map((each) => (
                              <Option key={each.id} value={each.id}>
                                <span {...getOptionProps(each)}>{each.description}</span>
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item label={createLabel('generalSettings.vendingErrorNotificationGroup')}>
                      {createFieldDecorator('generalSettings.vendingErrorNotificationGroup')(
                        <Select placeholder={t('none')} readOnly={readOnly} allowClear={false}>
                          <Option value={0}>{t('none')}</Option>
                          {(this.state.userGroups ?? [])
                            .filter((each) => each.id)
                            .map((each) => (
                              <Option key={each.id} value={each.id}>
                                <span {...getOptionProps(each)}>{each.description}</span>
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item label={createLabel('generalSettings.httpErrorNotificationUserGroup')}>
                      {createFieldDecorator('generalSettings.httpErrorNotificationUserGroup')(
                        <Select placeholder={t('none')} readOnly={readOnly} allowClear={false}>
                          <Option value={0}>{t('none')}</Option>
                          {(this.state.userGroups ?? [])
                            .filter((each) => each.id)
                            .map((each) => (
                              <Option key={each.id} value={each.id}>
                                <span {...getOptionProps(each)}>{each.description}</span>
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {createFieldDecorator('generalSettings.sendOrderCopyToSender', {
                        valuePropName: 'checked',
                      })(
                        <Checkbox readOnly={readOnly}>
                          {createLabel('generalSettings.sendOrderCopyToSender')}
                        </Checkbox>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {createFieldDecorator('generalSettings.sendOrderCopyToCreator', {
                        valuePropName: 'checked',
                      })(
                        <Checkbox readOnly={readOnly}>
                          {createLabel('generalSettings.sendOrderCopyToCreator')}
                        </Checkbox>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {createFieldDecorator('generalSettings.notifyRequisitionerWhenClosed', {
                        valuePropName: 'checked',
                      })(
                        <Checkbox readOnly={readOnly}>
                          {createLabel('generalSettings.notifyRequisitionerWhenClosed')}
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={8}
                    style={{
                      borderRight: '1px solid rgba(0, 0, 0, 0.15)',
                      borderLeft: '1px solid rgba(0, 0, 0, 0.15)',
                    }}
                  >
                    {range(1, 6).map((each) => (
                      <Form.Item key={`notifyEmail${each}`} label={createLabel(`notifyEmail${each}`)}>
                        {createFieldDecorator(`notifyEmail${each}`)(
                          <Input readOnly={readOnly} type="email" />
                        )}
                      </Form.Item>
                    ))}
                  </Col>
                  <Col third>
                    {range(1, 4).map((each) => (
                      <Form.Item key={`approvalEmail${each}`} label={createLabel(`approvalEmail${each}`)}>
                        {createFieldDecorator(`approvalEmail${each}`)(
                          <Input readOnly={readOnly} type="email" />
                        )}
                      </Form.Item>
                    ))}
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane key="settings" tab={t('settings')} forceRender>
                <Settings
                  values={item.generalSettings}
                  onChange={this.handleSettingsChange}
                  readOnly={readOnly}
                />
              </Tabs.TabPane>
              {getUserPermission('Master') === 'Yes' && (
                <Tabs.TabPane key="mobile" tab={t('mobile')} forceRender>
                  <Mobile
                    values={item.scannerSettings}
                    onChange={this.handleScannerSettingsChange}
                    readOnly={readOnly}
                  />
                </Tabs.TabPane>
              )}
              <Tabs.TabPane key="tags" tab={t('tags')} forceRender>
                <Tags
                  items={getCustomerTagSettingItems(item.tagSettings)}
                  onChange={this.handleTagsChange}
                  readOnly={readOnly}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="modules" tab={t('modules')} forceRender>
                <Modules
                  values={item.moduleSettings}
                  onChange={this.handleModuleChange}
                  readOnly={readOnly}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="approvals" tab={t('approvals')} forceRender>
                <ApprovalTree
                  items={this.state.approvalTreeItems ?? []}
                  pageTotals={this.state.approvalTreeItemsTotals ?? {}}
                  onChange={(values) =>
                    this.setState({ approvalTreeItems: orderBy(values, ['orderValueLevel']) })
                  }
                  readOnly={readOnly}
                />
              </Tabs.TabPane>
              {this.props.userIsDcribDistributor && (
                <Tabs.TabPane key="groups" tab={t('groups')} forceRender>
                  <Row>
                    <Col third>
                      <Form.Item label={t('memberOf')}>
                        <CheckboxList
                          dataSource={customerGroups}
                          value={item.customerGroupIds}
                          onChange={(values) => this.setItemValue('customerGroupIds', values)}
                          readOnly={readOnly}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Tabs.TabPane>
              )}
              <Tabs.TabPane key="passwords" tab={t('passwords')} forceRender>
                <Passwords
                  values={item.passwordSettings}
                  onChange={this.handlePasswordsChange}
                  readOnly={readOnly}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="custom" tab={t('custom')} forceRender>
                <Row>
                  <Col third>
                    {range(1, 6).map((each) => (
                      <Form.Item key={`jobCustomLabel${each}`} label={createLabel(`jobCustomLabel${each}`)}>
                        {createFieldDecorator(`jobCustomLabel${each}`)(<Input readOnly={readOnly} />)}
                      </Form.Item>
                    ))}
                  </Col>
                  <Col third>
                    {['customerSpecificField1', 'customerSpecificField2'].map((each) => (
                      <Form.Item key={each} label={createLabel(each)}>
                        {createFieldDecorator(each)(<Input readOnly={readOnly} />)}
                      </Form.Item>
                    ))}
                  </Col>
                </Row>
                <Form.Item label={createLabel('generalSettings.poFooterText')}>
                  {createFieldDecorator('generalSettings.poFooterText')(
                    <Input.TextArea readOnly={readOnly} rows={4} />
                  )}
                </Form.Item>
              </Tabs.TabPane>
            </Tabs>
          </div>
          <div className="form-buttons-container">
            <FormButtons
              readOnly={readOnly}
              onCancel={this.props.onCancel}
              onSubmit={this.handleSubmit}
              saveButtonLoading={this.state.saveButtonLoading}
              saveAndCloseButtonLoading={this.state.saveAndCloseButtonLoading}
            />
          </div>
        </Form>
        <Drawer
          title={t('selectFile')}
          size="xs"
          visible={this.state.fileUploadDrawerVisible}
          onClose={() => this.setState({ fileUploadDrawerVisible: false })}
        >
          <FileUpload
            accept=".jpg,.jpeg,.png,.gif,image/jpeg,image/png,image/gif"
            onUpload={this.handleFileUpload}
            onCancel={() => this.setState({ fileUploadDrawerVisible: false })}
          />
        </Drawer>
      </>
    )
  }
}

export default Form.create()(FormView)
