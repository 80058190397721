import { createListViewContainer } from 'factories/ListView'
import selectors from 'selectors'
import actions from 'actions/documents'
import Component from 'components/Documents'

export default createListViewContainer({
  actions,
  selectors: selectors.documents,
  extraDispatchToProps: (dispatch, props) => ({
    createItems: (params) => dispatch(actions.createItems(params)),
    downloadItem: (params) => dispatch(actions.downloadItem(params)),
  }),
})(Component)
