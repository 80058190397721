import produce from 'immer'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default createActions(entityNames.assetCategories, {
  memoizeGetOptions: true,
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.includeUnassignedSelection = draft.includeUnassignedSelection ?? true
    }),
})
