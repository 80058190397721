import { snakeCase, memoize } from 'lodash'
import { createChildActions } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { createOrphanedChildPost } from 'helpers/api'

const entityName = entityNames.orders
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const childName = 'orderSuppliers'
const CHILD_NAME = snakeCase(childName).toUpperCase()

const generatePurchaseOrderNumber = memoize(
  createOrphanedChildPost(entityName, childName, { action: 'generatePurchaseOrderNumber' }),
  JSON.stringify
)

export default {
  ...createChildActions(entityName, childName),

  previewPurchaseOrder: (params) => ({
    type: `${ENTITY_NAME}_${CHILD_NAME}_PREVIEW_PURCHASE_ORDER`,
    payload: createOrphanedChildPost(entityName, childName, { action: 'previewPurchaseOrder' })(params),
  }),

  generatePurchaseOrderNumber: (params) => ({
    type: `${ENTITY_NAME}_${CHILD_NAME}_GENERATE_PURCHASE_ORDER_NUMBER`,
    payload: generatePurchaseOrderNumber(params),
  }),
}
