import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/AssetWarrantyExpiryReport/Filter'

export default createListViewComponent({
  entityName: entityNames.assetWarrantyExpiryReport,
  filterTemplateType: 'AssetWarrantyExpiryReport',
  initialFilterDto: (self) => ({ expiryDayRange: 30 }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
})(Filter)
