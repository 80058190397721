import actions from 'actions/customers/approvalTree'
import userGroupActions from 'actions/userGroups'
import { createChildFormViewContainer } from 'factories/ChildFormView'
import Component from 'components/Customers/FormView/ApprovalTree/FormView'

export default createChildFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    getUserGroups: (params) => dispatch(userGroupActions.getOptions(params)),
  }),
})(Component)
