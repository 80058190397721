import { get, isEmpty } from 'lodash'
import { getStorageItem } from 'helpers/localStorage'
import startupOptions from 'options/startupOptions'
import { STARTUP_PAGE_STORAGE_KEY, LAST_PAGE_VISITED_STORAGE_KEY } from 'options/auth'

export const getStartupPage = (menuItems) => {
  if (isEmpty(menuItems)) {
    return '/'
  }

  const getPath = (l1) => {
    const menu = menuItems.reduce((acc, each) => ({ ...acc, [each.key]: each }), {})
    const l2 = get(menu, `${l1}.items[0].key`)
    const l3 = get(menu, `${l1}.items[0].items[0].key`)

    return l3 ? `/${l1}/${l2}/${l3}` : l2 ? `/${l1}/${l2}` : '/welcome'
  }

  switch (getStorageItem(STARTUP_PAGE_STORAGE_KEY, startupOptions.showThisPage)) {
    case startupOptions.showLastPageVisited:
      return getStorageItem(LAST_PAGE_VISITED_STORAGE_KEY, '/')

    case startupOptions.showAssetsPage:
      return getPath('assets')

    case startupOptions.showFormsPage:
      return getPath('forms')

    case startupOptions.showInventoryPage:
      return getPath('inventory')

    case startupOptions.showJobsPage:
      return getPath('jobs')

    case startupOptions.showOrdersPage:
      return getPath('orders')

    case startupOptions.showIssuePage:
      return '/inventory/issue'

    case startupOptions.showPeoplePage:
      return getPath('people')

    case startupOptions.showProductsPage:
      return getPath('products')

    case startupOptions.showDashboardViewerPage:
      return getPath('dashboards')

    case startupOptions.showReportsPage:
      return getPath('reports')

    case startupOptions.showSetupPage:
      return getPath('setup')

    case startupOptions.showToolsPage:
      return getPath('tools')

    default:
      return '/'
  }
}

export const getStartupRecord = ({ startupEntityName, startupEntityId }) => {
  if (startupEntityName === 'jobs') {
    return {
      startupPage: '/jobs/jobs',
      startupLinkTarget: {
        linkTargetName: 'jobRecord',
        linkTargetRecord: { jobId: startupEntityId },
        linkTargetText: startupEntityId,
      },
    }
  }

  if (startupEntityName === 'orders') {
    return {
      startupPage: '/orders/orders',
      startupLinkTarget: {
        linkTargetName: 'orderRecord',
        linkTargetRecord: { orderId: startupEntityId },
        linkTargetText: startupEntityId,
      },
    }
  }

  if (startupEntityName === 'requisitions') {
    return {
      startupPage: '/orders/requisitions',
      startupLinkTarget: {
        linkTargetName: 'requisitionRecord',
        linkTargetRecord: { requisitionId: startupEntityId },
        linkTargetText: startupEntityId,
      },
    }
  }

  return null
}
