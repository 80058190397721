import * as React from 'react'
import { Form } from 'antd'
import approvalStatusOptions from 'options/jobs/approvalStatus'
import { showError } from 'helpers/errors'
import { createDropdownRender, createSearchOperators } from 'helpers/formViews'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import { Row, Col } from 'elements/Grid'

const completionStatusOptions = {
  All: 'all',
  Open: 'open',
  ClosedLate: 'closedLate',
  ClosedOnTime: 'closedOnTime',
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchOperators = createSearchOperators(this)
  }

  async componentDidMount() {
    try {
      const { locationGroupIds, locationIds, operatorIds, operatorGroupIds } = this.props.filterDto
      await Promise.all([
        this.props.getLocationGroups(),
        this.props.getLocations({ locationGroupIds, locationIds }),
        this.props.getJobGroups(),
        this.props.getOperators({ operatorIds, operatorGroupIds }),
        this.props.getAssetCategories(),
        this.props.getJobReasons(),
        this.props.getJobStatusOptions(),
      ])
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto, operators } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker filterDto={filterDto} setFilterValue={this.setFilterValue} />
            <Form.Item label={t('completionStatus')}>
              <Select
                onChange={(value) => this.setFilterValue('completionStatus', value || 'All')}
                value={filterDto.completionStatus}
              >
                {Object.entries(completionStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                <Option value={0}>{t('none')}</Option>
                {(this.props.locations ?? [])
                  .filter((each) => each.id)
                  .map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('jobGroup')}>
              <Select
                onChange={(value) => this.setFilterValue('jobGroupIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.jobGroupIds}
              >
                <Option value={0}>{t('none')}</Option>
                {(this.props.jobGroups ?? [])
                  .filter((each) => each.id)
                  .map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('reason')}>
              <Select
                onChange={(value) => this.setFilterValue('jobReasonIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.jobReasonIds}
              >
                <Option value={0}>{t('none')}</Option>
                {(this.props.jobReasons ?? [])
                  .filter((each) => each.id)
                  .map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('assetCategory')}>
              <Select
                onChange={(value) => this.setFilterValue('assetCategoryIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.assetCategoryIds}
              >
                <Option value={0}>{t('none')}</Option>
                {(this.props.assetCategories ?? [])
                  .filter((each) => each.id)
                  .map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('assignedTo')}>
              <Select
                onChange={(value) => this.setFilterValue('operatorIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.operatorIds}
                dropdownRender={createDropdownRender(operators)}
                onSearch={this.searchOperators}
                showSearch
              >
                <Option value={0}>{t('unassigned')}</Option>
                {(operators?.items ?? [])
                  .filter((each) => each.id)
                  .map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) => this.setFilterValue('jobStatusOptionIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.jobStatusOptionIds}
              >
                <Option value={0}>{t('none')}</Option>
                {(this.props.jobStatusOptions ?? [])
                  .filter((each) => each.id)
                  .map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('approvalStatus')}>
              <Select
                onChange={(value) => this.setFilterValue('approvalStatus', value)}
                value={filterDto.approvalStatus}
                placeholder={t('all')}
              >
                {Object.entries(approvalStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('priority')}>
              <Select
                onChange={(value) => this.setFilterValue('priority', value)}
                value={filterDto.priority}
                placeholder={t('all')}
                allowClear
              >
                {[...Array(7).keys()].map((each) => (
                  <Option key={each} value={each}>
                    {each}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
