import { createChildListViewComponent } from 'factories/ChildListView'
import entityNames from 'options/entityNames'
import FormView from 'containers/Customers/FormView/ApprovalTree/FormView'

export default createChildListViewComponent({
  entityName: entityNames.customers,
  childName: 'approvalTree',
  createButtonTextId: 'addApprovalStep',
  createButtonLinkTarget: {
    formSize: 'xs',
    formComponent: FormView,
  },
  primaryLinkTarget: 'approvalTreeRecord',
  allowSorting: (self) => false,
})()
