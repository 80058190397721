import * as React from 'react'
import { Form, Spin, Button, Tooltip } from 'antd'
import { showError, resourceNotFound, ValidationError } from 'helpers/errors'
import { createLabelFactory, createFieldFactory, createHandleSubmit, isReadOnly } from 'helpers/formViews'
import approvalTreeRequirementTypes from 'options/customers/approvalTreeRequirementTypes'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import Select, { Option } from 'elements/Select'
import InputNumber from 'elements/InputNumber'

class FormView extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.handleSubmit = createHandleSubmit(this)
  }

  componentDidMount() {
    this.fetchItem()
  }

  transformSaveItemParams = ({ item, values }) => ({
    requirementDescription: t(approvalTreeRequirementTypes[values.requirementType]),
    description:
      values.requirementType === 'UserPermissionAboveSpecifiedValue'
        ? values.requirementValue
        : values.requirementType === 'UserInGroup'
          ? values.userGroupName
          : '',
  })

  getItemId = () => [this.state.item?.id, this.props.linkTargetRecord?.id].find(Boolean)

  fetchItem = async ({ itemId = this.getItemId() } = {}) => {
    try {
      const responses = await Promise.all([
        itemId
          ? this.props.getItem(itemId).catch((error) => {
              this.props.onCancel?.()
              throw error
            })
          : !this.props.linkTargetRecord
            ? this.props.newItem({
                orderValueLevel: 0,
                requirementType: 'UserPermissionAboveOrderValue',
                requirementValue: 0,
              })
            : resourceNotFound(this),
        this.props.getSettings({ type: this.props.settingsType }),
        this.props.getUserGroups(),
      ])
      this.setState({
        item: responses[0].value.data,
        fieldSettings: responses[1].value.data.fieldSettings,
        userGroups: responses[2]?.value?.data?.items ?? [],
      })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { fieldSettings, item } = this.state

    if (!item || !fieldSettings) {
      return <Spin />
    }

    const createLabel = createLabelFactory(fieldSettings)
    const createFieldDecorator = createFieldFactory(this.props.form, item, fieldSettings)
    const readOnly = isReadOnly(this)

    return (
      <Form layout="vertical" colon={false} onSubmit={readOnly ? stopEvent : this.handleSubmit}>
        <div className="form-items-container">
          <ValidationError errors={this.state.validationErrors} />
          <Form.Item label={createLabel('orderValueLevel')}>
            {createFieldDecorator('orderValueLevel')(<InputNumber readOnly={readOnly} autoFocus />)}
          </Form.Item>
          <Form.Item label={createLabel('requirementType')}>
            {createFieldDecorator('requirementType')(
              <Select readOnly={readOnly} allowClear={false}>
                {Object.entries(approvalTreeRequirementTypes).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {this.props.form.getFieldValue('requirementType') === 'UserPermissionAboveSpecifiedValue' && (
            <Form.Item label={createLabel('requirementValue')}>
              {createFieldDecorator('requirementValue')(<InputNumber readOnly={readOnly} min={0} />)}
            </Form.Item>
          )}
          {this.props.form.getFieldValue('requirementType') === 'UserInGroup' && (
            <Form.Item label={createLabel('userGroupName')}>
              {createFieldDecorator('userGroupName')(
                <Select readOnly={readOnly} allowClear={false}>
                  {(this.state.userGroups ?? []).map((each) => (
                    <Option key={each.description} value={each.description}>
                      {each.description}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}
        </div>
        <div className="form-buttons-container">
          <Button onClick={this.props.onCancel}>{t(readOnly ? 'close' : 'cancel')}</Button>{' '}
          {!readOnly && (
            <Tooltip title={t('childRecordSaveInfo')} placement="topLeft">
              <Button type="primary" htmlType="submit" loading={this.state.saveAndCloseButtonLoading}>
                {!this.props.linkTargetRecord ? t('add') : t('update')}
              </Button>
            </Tooltip>
          )}
        </div>
      </Form>
    )
  }
}

export default Form.create()(FormView)
