import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/OpenOrdersReport/Filter'

export default createListViewComponent({
  entityName: entityNames.openOrdersReport,
  filterTemplateType: 'OpenOrdersReport',
  initialFilterDto: (self) => ({
    dateRange: { dateRangeType: 'YearToDate' },
    balanceDue: 0,
  }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
})(Filter)
