import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import productCategoryActions from 'actions/productCategories'
import supplierActions from 'actions/suppliers'
import Component from 'components/SupplierDetails/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  productCategories: selectors.productCategories.selectOptions(state),
  suppliers: selectors.suppliers.selectOptions(state, { itemsOnly: false }),
})

const mapDispatchToProps = (dispatch, props) => ({
  getProductCategories: (params) =>
    dispatch(productCategoryActions.getOptions({ ...params, active: 'All', pageIndex: 0 })),
  getSuppliers: (params) =>
    dispatch(
      supplierActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
        active: 'All',
      })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
