import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import formBatchActions from 'actions/formBatches'
import formTemplateActions from 'actions/formTemplates'
import customerGroupActions from 'actions/customerGroups'
import customerActions from 'actions/customers'
import Component from 'components/Reports/FormSubmissionReport/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  formBatches: selectors.formBatches.selectOptions(state, { itemsOnly: false }),
  formTemplates: selectors.formTemplates.selectOptions(state, { itemsOnly: false }),
  customerGroups: selectors.customerGroups.selectOptions(state, { itemsOnly: false }),
})

const mapDispatchToProps = (dispatch, props) => ({
  getFormBatches: (params) =>
    dispatch(
      formBatchActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
      })
    ),
  getCustomerGroups: (params) =>
    dispatch(
      customerGroupActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
      })
    ),
  getFormTemplates: (params) =>
    dispatch(
      formTemplateActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
      })
    ),
  getCustomerNumberSelectionList: (params) =>
    dispatch(
      customerActions.getNumberSelectionList({
        pageIndex: 1,
        pageSize: 100,
        ...params,
      })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
