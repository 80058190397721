import { createListViewContainer } from 'factories/ListView'
import selectors from 'selectors'
import actions from 'actions/products'
import Component from 'components/Products'

export default createListViewContainer({
  actions,
  selectors: selectors.products,
  extraDispatchToProps: (dispatch, props) => ({
    getProductCatalogs: () => dispatch(actions.getCatalogs()),
    setPreferredSupplier: (params) => dispatch(actions.setPreferredSupplier(params)),
    setLinkedCatalog: (params) => dispatch(actions.setLinkedCatalog(params)),
    copyToPrivate: (params) => dispatch(actions.copyToPrivate(params)),
  }),
})(Component)
