import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import customerActions from 'actions/customers'
import supplierActions from 'actions/suppliers'
import Component from 'components/Customers/FormView/Settings'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getSettings: (params) => dispatch(customerActions.getSettings(params)),
  getSuppliers: (params) =>
    dispatch(
      supplierActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
        active: 'Active',
      })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
