import { Tooltip, Popover } from 'antd'
import cx from 'clsx'
import styled from 'styled-components'
import { isEmpty, toString as str } from 'lodash'
import entityNames from 'options/entityNames'
import { createChildListPrintComponent } from 'factories/ChildListPrint'
import { RED } from 'options/colors'
import { getUserPermission } from 'helpers/auth'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'
import InputNumber from 'elements/InputNumber'

const QuantityReceived = styled.div`
  white-space: nowrap;
  line-height: 32px;
  padding-right: 6px;
  width: 30%;
`

export default createChildListPrintComponent({
  entityName: entityNames.orders,
  childName: 'orderItems',
  tableCellComponents: {
    quantityOrdered: (self, item) => {
      const notMultipleOfPackageSize =
        self.props.customer.generalSettings.orderCalculationType === 'Accumulate' &&
        item.quantityOrdered > 0 &&
        item.quantityOrdered % item.packageSize > 0
      const quantityReceived =
        self.props.customer.generalSettings.receivingMode === 'EnteredValueIsDelta'
          ? item.quantityReceived + item.quantityReceivedDelta
          : item.quantityReceived

      const userIsSupplierOrDistributer = self.props.userIsInRoleSupplier || self.props.userIsInRoleDistributor
      const orderIsReadOnly = userIsSupplierOrDistributer || ['Draft', 'Cancelled'].includes(item.status)
      const orderIsEditable =
        !orderIsReadOnly &&
        (['New', 'PendingApproval'].includes(item.status) ||
          self.props.customer.generalSettings.allowOrderEditAfterSend)

      return (
        <div className="whitespace-nowrap pr-12">
          <Tooltip title={notMultipleOfPackageSize ? t('errorNotMultipleOfPackageSize') : null}>
            <InputNumber
              key={`${item.id}-${item.timestamp}`}
              value={item.quantityOrdered}
              className={cx({
                'form-field-error': notMultipleOfPackageSize || item.quantityOrdered < quantityReceived,
              })}
              min={0}
              disabled={!orderIsEditable || getUserPermission('Purchase') !== 'Yes'}
              readOnly
            />
          </Tooltip>{' '}
          <Popover
            title={self.props.customer.moduleSettings.enableInventory ? t('inventoryStatus') : t('itemStatus')}
            content={<span dangerouslySetInnerHTML={{ __html: item.inventoryStatusInfo }} />}
          >
            <span
              className="align-middle"
              style={{ visibility: isEmpty(item.inventoryStatusInfo) ? 'hidden' : 'visible' }}
            >
              <Icon type="Search" />
            </span>
          </Popover>
        </div>
      )
    },
    quantityReceived: (self, item) => {
      const disabled =
        self.props.parentRecord.orderIsReadOnly ||
        item.quantityOrdered <= 0 ||
        !self.props.parentRecord.orderIsReceivable ||
        getUserPermission('ReceiveOrders') !== 'Yes' ||
        item.lockedForCycleCount

      const receivedMoreThanOrdered = item.quantityReceived + item.quantityReceivedDelta > item.quantityOrdered
      const receivedLessThanOrdered = item.quantityReceived + item.quantityReceivedDelta < item.quantityOrdered

      return self.props.customer.generalSettings.receivingMode === 'EnteredValueIsDelta' ? (
        <div className="flex">
          <QuantityReceived>{item.quantityReceived}</QuantityReceived>
          <div style={{ width: '70%' }}>
            <InputNumber
              key={`${item.id}-${item.timestamp}`}
              value={item.quantityReceivedDelta}
              className={cx({
                'form-field-error': receivedMoreThanOrdered,
                'form-field-warning': receivedLessThanOrdered,
              })}
              min={-item.quantityReceived}
              suffix={
                item.lockedForCycleCount ? (
                  <Tooltip title={t('onhandQuantitiesLockedForCycleCount')}>
                    <Icon type="Lock" color={RED} />
                  </Tooltip>
                ) : null
              }
              disabled={disabled}
              readOnly
            />
          </div>
        </div>
      ) : (
        <InputNumber
          key={`${item.id}-${item.timestamp}`}
          value={item.quantityReceived}
          className={cx({
            'form-field-error': receivedMoreThanOrdered,
            'form-field-warning': receivedLessThanOrdered,
          })}
          min={0}
          suffix={
            item.lockedForCycleCount ? (
              <Tooltip title={t('onhandQuantitiesLockedForCycleCount')}>
                <Icon type="Lock" color={RED} />
              </Tooltip>
            ) : null
          }
          disabled={disabled}
          readOnly
        />
      )
    },
    source: (self, item) => {
      const source = str(item?.source)

      if (!source.match(/(REQ|RFQ)(\d+)/)) {
        return source
      }

      return source.match(/(REQ|RFQ)(\d+)/g).reduce((acc, each) => [acc, ', ', each])
    },
  },
})()
