import produce from 'immer'
import { baseReportUrl } from 'config'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default {
  ...createActions(entityNames.inventoryDeficiencyReport, {
    basePath: 'report/inventoryReports',
    baseUrl: baseReportUrl,
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.locationVendingTypes = ['NonVending']
      }),
  }),
}
