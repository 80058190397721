import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { t } from 'helpers/i18n'
import { createSearchProducts, createDropdownRender, isReadOnly } from 'helpers/formViews'
import { createGetFieldLabel } from 'helpers/dashboards'
import Select, { Option } from 'elements/Select'
import SelectProductCategories from 'containers/ProductCategories/Select'
import Checkbox from 'elements/Checkbox'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchProducts = createSearchProducts(this)
  }

  async componentDidMount() {
    try {
      const { productBarcodes = [], productCategoryIds = [] } = this.props.filterDto

      await Promise.all([
        this.props.getProductCategories(),
        this.props.getProducts({ productBarcodes, productCategoryIds }),
      ])
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { fields = [], filterDto = {}, products } = this.props

    const getFieldLabel = createGetFieldLabel(fields)
    const readOnly = isReadOnly(this)

    return (
      <>
        {fields.find((one) => one.type === 'productBarcodes') && (
          <Form.Item label={getFieldLabel('productBarcodes')}>
            <Select
              onChange={(values) => this.setFilterValue('productBarcodes', values)}
              value={filterDto.productBarcodes}
              dropdownRender={createDropdownRender(products)}
              onSearch={this.searchProducts}
              placeholder={t('all')}
              mode="multiple"
              readOnly={readOnly}
              showSearch
            >
              {(products?.items ?? []).map((each) => (
                <Option key={each.barcode} value={each.barcode}>
                  {each.displayName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'productCategoryIds') && (
          <Form.Item label={getFieldLabel('productCategoryIds')}>
            <SelectProductCategories
              value={filterDto.productCategoryIds}
              placeholder={t('all')}
              onChange={(value) => this.setFilterValue('productCategoryIds', value)}
              dataSource={this.props.productCategories ?? []}
              readOnly={readOnly}
            />
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'criticalInventoryOnly') && (
          <Form.Item label={getFieldLabel('criticalInventoryOnly')}>
            <Checkbox
              onChange={(e) => this.setFilterValue('criticalInventoryOnly', e.target.checked)}
              checked={filterDto.criticalInventoryOnly}
              readOnly={readOnly}
            ></Checkbox>
          </Form.Item>
        )}
      </>
    )
  }
}

export default Form.create()(Filter)
