import * as React from 'react'
import pluralize from 'pluralize'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card } from 'antd'
import { isNil, get, set, toLower, uniq, camelCase, isEmpty, pick, flow } from 'lodash'
import { useImmer } from 'use-immer'
import { useDebouncedCallback } from 'use-debounce'
import { findUniqOrFirst, DEBOUNCE } from 'helpers/utils'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { getStorageItem, setStorageItem } from 'helpers/localStorage'
import { createDropdownRender, filterByValue } from 'helpers/formViews'
import { stopEvent } from 'helpers/events'
import { showError, showValidationError } from 'helpers/errors'
import { t } from 'helpers/i18n'
import { getPageTitle } from 'helpers/auth'
import { STARTUP_REDIRECT_SESSION_KEY } from 'options/auth'
import Page from 'elements/Page'
import Help from 'elements/Help'
import Modal from 'elements/Modal'
import Drawer from 'elements/Drawer'
import SelectAsset from 'containers/Assets/Select'
import AutoComplete, { Suffix } from 'elements/AutoComplete'
import Checkbox from 'elements/Checkbox'
import FormView from 'containers/TrackAssets/FormView'

export const TRACK_ASSETS_AFTER_CLOSE_KEY = 'assets.trackAssets.afterDrawerClose'

const getStorageKey = () => 'assets.trackAssets'

const TAG_TYPES = ['2', '1', 'A', 'B', 'C', 'D', 'E', '3']

function Component(props) {
  const { form, getAssets, user, customer, getTagListItems, getJobs, getOperators } = props
  const pageTitle = getPageTitle()

  const refs = {
    ref1: React.useRef(null),
    ref2: React.useRef(null),
    ref3: React.useRef(null),
    refA: React.useRef(null),
    refB: React.useRef(null),
    refC: React.useRef(null),
    refD: React.useRef(null),
    refE: React.useRef(null),
  }

  const [state, updateState] = useImmer({
    jobDisplayName: '',
    assetDisplayName: '',
    operatorDisplayName: '',
    ...getStorageItem(getStorageKey()),
  })

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  function beforeValidateFields() {
    updateState((draft) => {
      let fieldsValue = {}

      if (
        (draft.operator?.id || draft.operatorId || draft.operatorDisplayName) &&
        user.coreUserSettings.tagSettings.tag2EnabledAssets
      ) {
        const operators = draft.operators?.items ?? []
        const operatorId = draft.operator?.id ?? draft.operatorId

        draft.operator = operatorId
          ? operators.find((one) => one.id === operatorId)
          : findUniqOrFirst(operators, 'displayName', draft.operatorDisplayName)

        draft.operatorDisplayName = draft.operator?.displayName ?? draft.operatorDisplayName
        ;['id', 'barcode', 'name', 'number'].forEach((one) => {
          set(draft, camelCase(`operator-${one}`), get(draft.operator, one))
        })

        fieldsValue = {
          ...fieldsValue,
          operatorDisplayName: draft.operatorDisplayName,
        }
      }

      if (
        (draft.job?.id || draft.jobId || draft.jobDisplayName) &&
        user.coreUserSettings.tagSettings.tag1EnabledAssets
      ) {
        const jobs = draft.jobs?.items ?? []
        const jobId = draft.job?.id ?? draft.jobId

        draft.job = jobId
          ? jobs.find((one) => one.id === jobId)
          : findUniqOrFirst(jobs, 'displayName', draft.jobDisplayName)

        draft.jobDisplayName = draft.job?.displayName ?? draft.jobDisplayName
        ;['id', 'barcode', 'name', 'number'].forEach((one) => {
          set(draft, camelCase(`job-${one}`), get(draft.job, one))
        })

        fieldsValue = { ...fieldsValue, jobDisplayName: draft.jobDisplayName }
      }

      if (state.assetDisplayName && isNil(state.assetId) && draft.assets?.items) {
        let asset = draft.assets.items.find((one) =>
          toLower(one.displayName).startsWith(toLower(draft.assetDisplayName))
        )

        if (isNil(asset) && uniq(draft.assets.items.map((each) => each.displayName)).length === 1) {
          asset = draft.assets.items[0]
        }

        if (asset) {
          ;['id', 'barcode', 'displayName', 'name', 'number'].forEach((one) => {
            set(draft, camelCase(`asset-${one}`), asset[one])
          })

          fieldsValue = {
            ...fieldsValue,
            assetDisplayName: asset.displayName,
          }
        } else {
          fieldsValue = { assetDisplayName: '' }
        }
      }

      ;['A', 'B', 'C', 'D', 'E'].forEach((type) => {
        if (user.coreUserSettings.tagSettings[`tag${type}EnabledAssets`]) {
          const filteredTagListItems = (state?.tagListItems ?? [])
            .filter((each) => each.tagType === type)
            .map((each) => each.description)
            .filter((each) => toLower(each).indexOf(toLower(get(state, `tag${type}`))) > -1)

          if (filteredTagListItems.length === 1) {
            set(draft, `tag${type}`, filteredTagListItems[0])

            fieldsValue = {
              ...fieldsValue,
              [`tag${type}`]: filteredTagListItems[0],
            }
          }
        }
      })

      form.setFieldsValue(fieldsValue)
    })

    return new Promise((resolve) => {
      window.setTimeout(resolve, 1000)
    })
  }

  async function handleSubmit(e) {
    stopEvent(e)

    await beforeValidateFields()

    form.validateFieldsAndScroll((errors, values) => {
      if (!isEmpty(errors)) {
        showValidationError()
        return
      }

      updateState((draft) => {
        draft.assetDisplayName = values.assetDisplayName
        draft.asset =
          draft.asset ||
          (state?.assets?.items ?? []).find((one) => one.id === values.assetId) ||
          (state?.assets?.items ?? []).find((one) => one.displayName === values.assetDisplayName)

        draft.jobDisplayName = values.jobDisplayName
        draft.job =
          draft.job ||
          (state?.jobs?.items ?? []).find((one) => one.id === values.jobId) ||
          (state?.jobs?.items ?? []).find((one) => one.displayName === values.jobDisplayName)

        draft.operatorDisplayName = values.operatorDisplayName
        draft.operator =
          draft.operator ||
          (state?.operators?.items ?? []).find((one) => one.id === values.operatorId) ||
          (state?.operators?.items ?? []).find((one) => one.displayName === values.operatorDisplayName)

        draft.tagA = values.tagA
        draft.tagB = values.tagB
        draft.tagC = values.tagC
        draft.tagD = values.tagD
        draft.tagE = values.tagE
        draft.quickscan = values.quickscan
        draft.trackAssetDrawerVisible = true
        draft.trackAssetDrawerSaving = false
      })
    })
  }

  function handleSelectAssetOk() {
    const selectedAssetDisplayName = state.selectedAsset.displayName

    updateState((draft) => {
      draft.asset = draft.selectedAsset
      draft.assetId = draft.selectedAsset.id
      draft.assetDisplayName = draft.selectedAsset.displayName
      draft.selectedAsset = null
      draft.selectAssetModalVisible = false
    })

    form.setFieldsValue({ assetDisplayName: selectedAssetDisplayName })

    fetchAssets()
  }

  async function fetchAssets(search = state.assetDisplayName) {
    try {
      const assetId = state.asset?.id ?? state.assetId

      const response = await getAssets({
        search,
        assetIds: assetId ? [assetId] : undefined,
      })

      updateState((draft) => {
        draft.assets = response.value.data

        const asset = response.value.data.items.find((one) => one.id === assetId)

        if (asset) {
          draft.asset = asset
          draft.assetId = asset.id
          draft.assetDisplayName = asset.displayName
        }
      })
    } catch (error) {
      showError({ error })
    } finally {
      updateState((draft) => {
        draft.searching = false
      })
    }
  }

  async function fetchJobs(search = state.jobDisplayName) {
    try {
      const jobId = state.job?.id ?? state.jobId

      const response = await getJobs({
        search,
        jobIds: jobId ? [jobId] : undefined,
      })

      updateState((draft) => {
        draft.jobs = response.value.data

        const job = response.value.data.items.find((one) => one.id === jobId)

        if (job) {
          draft.job = job
          draft.jobId = job.id
          draft.jobDisplayName = job.displayName
        }
      })
    } catch (error) {
      showError({ error })
    } finally {
      updateState((draft) => {
        draft.searching = false
      })
    }
  }

  async function fetchOperators(search = state.operatorDisplayName) {
    try {
      const operatorId = state.operator?.id ?? state.operatorId

      const response = await getOperators({
        search,
        operatorIds: operatorId ? [operatorId] : undefined,
      })

      updateState((draft) => {
        draft.operators = response.value.data

        const operator = response.value.data.items.find((one) => one.id === operatorId)

        if (operator) {
          draft.operator = operator
          draft.operatorId = operator.id
          draft.operatorDisplayName = operator.displayName
        }
      })
    } catch (error) {
      showError({ error })
    } finally {
      updateState((draft) => {
        draft.searching = false
      })
    }
  }

  function handleDrawerClose() {
    const { linkTarget, redirectUrl } = getSessionItem(TRACK_ASSETS_AFTER_CLOSE_KEY, {})

    if (linkTarget && redirectUrl) {
      setSessionItem(STARTUP_REDIRECT_SESSION_KEY, linkTarget)
      removeSessionItem(TRACK_ASSETS_AFTER_CLOSE_KEY)
      props.history.push(redirectUrl)
      return
    }

    updateState((draft) => {
      draft.trackAssetDrawerSaving = false
      draft.trackAssetDrawerVisible = false
      draft.asset = null
      draft.assetId = null
      draft.assetDisplayName = ''

      form.setFieldsValue({ assetDisplayName: '' })

      fetchAssets('')

      if (!draft.quickscan) {
        draft.job = null
        draft.jobDisplayName = ''

        if (user.coreUserSettings.tagSettings.tag1EnabledAssets) {
          fetchJobs('')
        }

        draft.operator = null
        draft.operatorDisplayName = ''

        if (user.coreUserSettings.tagSettings.tag2EnabledAssets) {
          fetchOperators('')
        }

        ;['A', 'B', 'C', 'D', 'E'].forEach((type) => {
          set(draft, `tag${type}`, '')
        })

        form.resetFields(['jobDisplayName', 'operatorDisplayName', 'tagA', 'tagB', 'tagC', 'tagD', 'tagE'])
      }
    })
  }

  function setFormValue(key, value) {
    updateState((draft) => {
      set(draft, key, value)

      form.setFieldsValue({ [key]: value })
    })
  }

  function handlePressEnter(current) {
    return async function (e) {
      stopEvent(e)

      await beforeValidateFields()

      const currentIndex = TAG_TYPES.indexOf(current)

      if (currentIndex > -1) {
        TAG_TYPES.some((next, nextIndex) => {
          if (nextIndex > currentIndex) {
            try {
              get(refs, `ref${next}`).current.focus()

              return true
            } catch (error) {
              console.warn(error)
            }
          }
          return false
        })
      }
    }
  }

  function setTagValue(tagName, value, searching) {
    const entityName = pluralize(tagName)

    updateState((draft) => {
      set(draft, `${tagName}DisplayName`, value)
      form.setFieldsValue({ [`${tagName}DisplayName`]: value })

      draft.searching = searching

      if (searching) {
        set(draft, tagName, null)
        set(draft, entityName, null)
        ;['id', 'barcode', 'name', 'number'].forEach((one) => {
          set(draft, `${camelCase(`${tagName}-${one}`)}`, null)
        })
      } else if (draft[entityName]) {
        set(draft, `${entityName}.pageIndex`, 1)
        set(draft, `${entityName}.pageCount`, 1)
        set(draft, `${entityName}.recordCount`, 1)
        set(
          draft,
          `${entityName}.items`,
          get(draft, `${entityName}.items`, []).filter((each) => each.displayName === value)
        )

        const item = get(draft, `${entityName}.items`, []).find((one) => one.displayName === value)

        ;['id', 'barcode', 'name', 'number'].forEach((one) => {
          set(draft, `${camelCase(`${tagName}-${one}`)}`, get(item, one))
        })

        set(draft, tagName, item)
      }
    })

    if (searching) {
      switch (entityName) {
        case 'operators':
          searchOperators(value)
          break

        case 'assets':
          searchAssets(value)
          break

        case 'jobs':
          searchJobs(value)
          break

        default:
          break
      }
    }
  }

  React.useEffect(() => {
    if (user.coreUserSettings.tagSettings.tag1EnabledAssets) {
      fetchJobs()
    }

    if (user.coreUserSettings.tagSettings.tag2EnabledAssets) {
      fetchOperators()
    }

    fetchAssets()
  }, [])

  React.useEffect(() => {
    async function fetchTagListItems() {
      try {
        const response = await getTagListItems({ tagType: 'All' })

        setState('tagListItems', get(response, 'value.data.items', []))
      } catch (error) {
        showError({ error })
      }
    }

    fetchTagListItems()
  }, [])

  React.useEffect(() => {
    setStorageItem(getStorageKey(), pick(state, ['quickscan']))
  }, [state.quickscan])

  const searchJobs = useDebouncedCallback((search) => fetchJobs(search), DEBOUNCE)
  const searchOperators = useDebouncedCallback((search) => fetchOperators(search), DEBOUNCE)
  const searchAssets = useDebouncedCallback((search) => fetchAssets(search), DEBOUNCE)
  const tagsEnabled = TAG_TYPES.some((type) =>
    get(user, `coreUserSettings.tagSettings.tag${type}EnabledAssets`)
  )

  return (
    <>
      <Page title={pageTitle} scrollable>
        <div style={{ width: '400px' }}>
          <Form layout="vertical" onSubmit={handleSubmit} colon={false}>
            <h2 title={pageTitle}>{pageTitle}</h2>
            {tagsEnabled && (
              <Card className="mb-12">
                {user.coreUserSettings.tagSettings.tag2EnabledAssets && (
                  <Form.Item label={customer.tagSettings.tag2}>
                    {form.getFieldDecorator('operatorDisplayName', {
                      rules: [
                        ...(customer.tagSettings.tag2Required
                          ? [
                              {
                                required: true,
                                message: t('requiredField'),
                              },
                            ]
                          : []),
                        ...(customer.tagSettings.tag2Restricted
                          ? [
                              {
                                validator: (rule, value, callback) => {
                                  callback(
                                    isEmpty(value) ||
                                      (state.operators?.items ?? [])
                                        .map((each) => each.displayName)
                                        .includes(value)
                                      ? []
                                      : [new Error(t('invalidValueEntered'))]
                                  )
                                },
                              },
                            ]
                          : []),
                      ],
                      initialValue: state.operatorDisplayName,
                      validateTrigger: false,
                    })(
                      <AutoComplete
                        ref={refs.ref2}
                        defaultActiveFirstOption={false}
                        dataSource={(state?.operators?.items ?? []).map((each) => each.displayName)}
                        dropdownRender={createDropdownRender(state.operators)}
                        onSearch={(value) => setTagValue('operator', value, true)}
                        onSelect={(value) => setTagValue('operator', value, false)}
                      >
                        <Input
                          onPressEnter={handlePressEnter('2')}
                          autoComplete="off"
                          suffix={<Suffix />}
                          allowClear
                        />
                      </AutoComplete>
                    )}
                  </Form.Item>
                )}
                {user.coreUserSettings.tagSettings.tag1EnabledAssets && (
                  <Form.Item label={customer.tagSettings.tag1}>
                    {form.getFieldDecorator('jobDisplayName', {
                      rules: [
                        ...(customer.tagSettings.tag1Required
                          ? [
                              {
                                required: true,
                                message: t('requiredField'),
                              },
                            ]
                          : []),
                        ...(customer.tagSettings.tag1Restricted
                          ? [
                              {
                                validator: (rule, value, callback) => {
                                  callback(
                                    isEmpty(value) ||
                                      (state.jobs?.items ?? [])
                                        .map((each) => each.displayName)
                                        .includes(value)
                                      ? []
                                      : [new Error(t('invalidValueEntered'))]
                                  )
                                },
                              },
                            ]
                          : []),
                      ],
                      initialValue: state.jobDisplayName,
                      validateTrigger: false,
                    })(
                      <AutoComplete
                        ref={refs.ref1}
                        defaultActiveFirstOption={false}
                        dataSource={(state?.jobs?.items ?? []).map((each) => each.displayName)}
                        dropdownRender={createDropdownRender(state.jobs)}
                        onSearch={(value) => setTagValue('job', value, true)}
                        onSelect={(value) => setTagValue('job', value, false)}
                      >
                        <Input
                          onPressEnter={handlePressEnter('1')}
                          autoComplete="off"
                          suffix={<Suffix />}
                          allowClear
                        />
                      </AutoComplete>
                    )}
                  </Form.Item>
                )}
                {['A', 'B', 'C', 'D', 'E'].map(
                  (tagType) =>
                    user.coreUserSettings.tagSettings[`tag${tagType}EnabledAssets`] && (
                      <Form.Item key={tagType} label={customer.tagSettings[`tag${tagType}`]}>
                        {form.getFieldDecorator(`tag${tagType}`, {
                          rules: [
                            ...(customer.tagSettings[`tag${tagType}Required`]
                              ? [
                                  {
                                    required: true,
                                    message: t('requiredField'),
                                  },
                                ]
                              : []),
                            ...(customer.tagSettings[`tag${tagType}Restricted`]
                              ? [
                                  {
                                    type: 'enum',
                                    enum: (state?.tagListItems ?? [])
                                      .filter((each) => each.tagType === tagType)
                                      .map((each) => each.description),
                                    message: t('invalidValueEntered'),
                                  },
                                ]
                              : []),
                          ],
                          initialValue: get(state, `tag${tagType}`),
                          validateTrigger: false,
                        })(
                          customer.tagSettings[`tag${tagType}ShowList`] ? (
                            <AutoComplete
                              ref={refs[`ref${tagType}`]}
                              defaultActiveFirstOption={false}
                              dataSource={(state?.tagListItems ?? [])
                                .filter((each) => each.tagType === tagType)
                                .map((each) => each.description)}
                              filterOption={filterByValue}
                              onChange={(value) => setFormValue(`tag${tagType}`, value)}
                            >
                              <Input
                                onPressEnter={handlePressEnter(tagType)}
                                autoComplete="off"
                                suffix={<Suffix />}
                                allowClear
                              />
                            </AutoComplete>
                          ) : (
                            <Input
                              ref={refs[`ref${tagType}`]}
                              onPressEnter={handlePressEnter(tagType)}
                              autoComplete="off"
                              allowClear
                            />
                          )
                        )}
                      </Form.Item>
                    )
                )}
                <Form.Item className="mb-0">
                  {form.getFieldDecorator('quickscan', {
                    valuePropName: 'checked',
                    initialValue: state.quickscan,
                  })(
                    <Checkbox onChange={(e) => setFormValue('quickscan', e.target.checked)}>
                      {t('quickscan')} <Help title={t('quickScanInfo')} />
                    </Checkbox>
                  )}
                </Form.Item>
              </Card>
            )}
            <Card className="mb-24">
              <Form.Item label={customer.tagSettings.tag3}>
                {form.getFieldDecorator('assetDisplayName', {
                  rules: [{ required: true, message: t('requiredField') }],
                  initialValue: state.assetDisplayName,
                  validateTrigger: false,
                })(
                  <AutoComplete
                    ref={refs.ref3}
                    defaultActiveFirstOption={false}
                    dataSource={(state?.assets?.items ?? []).map((each) => each.displayName)}
                    dropdownRender={createDropdownRender(state.assets)}
                    onSearch={(value) => setTagValue('asset', value, true)}
                    onSelect={(value) => setTagValue('asset', value, false)}
                  >
                    <Input
                      onPressEnter={() => handleSubmit()}
                      autoComplete="off"
                      suffix={<Suffix />}
                      allowClear
                    />
                  </AutoComplete>
                )}
              </Form.Item>
              <div className="text-right">
                <Button
                  onClick={() => setState('selectAssetModalVisible', true)}
                  className="mr-6"
                  disabled={state.searching}
                >
                  {t('search')}
                </Button>
                <Button type="primary" htmlType="submit" disabled={state.searching}>
                  {t('track')}
                </Button>
              </div>
            </Card>
          </Form>
        </div>
      </Page>
      <Modal
        title={t('searchAssets')}
        visible={state.selectAssetModalVisible}
        okText={t('select')}
        onOk={handleSelectAssetOk}
        okButtonProps={{ disabled: !state.selectedAsset }}
        onCancel={() =>
          updateState((draft) => {
            draft.selectedAsset = null
            draft.selectAssetModalVisible = false
          })
        }
        width={992}
      >
        <SelectAsset onSelect={(values) => setState('selectedAsset', values[0])} />
      </Modal>
      <Drawer
        title={pageTitle}
        size="lg"
        visible={state.trackAssetDrawerVisible}
        onClose={handleDrawerClose}
        saving={state.trackAssetDrawerSaving}
      >
        {state.trackAssetDrawerVisible && (
          <FormView
            linkTargetRecord={{ assetId: state.asset?.id || state.assetId }}
            onSave={(pending) => setState('trackAssetDrawerSaving', pending)}
            onSaveAndClose={handleDrawerClose}
            onCancel={handleDrawerClose}
            tags={{
              jobId: state.job?.id,
              jobDisplayName: state.jobDisplayName,
              operatorId: state.operator?.id,
              operatorDisplayName: state.operatorDisplayName,
              tagA: state.tagA,
              tagB: state.tagB,
              tagC: state.tagC,
              tagD: state.tagD,
              tagE: state.tagE,
            }}
          />
        )}
      </Drawer>
    </>
  )
}

export default flow(withRouter, Form.create())(Component)
