import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import productActions from 'actions/products'
import fileTemplateActions from 'actions/fileTemplates'
import { createDownloadItemsDispatchToProps } from 'helpers/downloadItems'
import Component from 'components/UploadData'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getFileTemplates: (params) => dispatch(fileTemplateActions.getOptions(params)),
  uploadFile: (params) => dispatch(fileTemplateActions.uploadFile(params)),
  getValidSettings: (params) => dispatch(fileTemplateActions.getValidSettings(params)),
  getInvalidSettings: (params) => dispatch(fileTemplateActions.getInvalidSettings(params)),
  getUploadProgress: () => dispatch(fileTemplateActions.getUploadProgress()),
  getUploadResults: (params) => dispatch(fileTemplateActions.getUploadResults(params)),
  downloadUploadResults: (params) => dispatch(fileTemplateActions.downloadUploadResults(params)),
  clearUploadResults: () => dispatch(fileTemplateActions.clearUploadResults()),
  applyUpdate: () => dispatch(fileTemplateActions.applyUpdate()),
  cancelUpload: () => dispatch(fileTemplateActions.cancelUpload()),
  getProductCatalogs: () => dispatch(productActions.getCatalogs()),
  ...createDownloadItemsDispatchToProps(dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
