import { createListModalContainer } from 'factories/ListModal'
import actions from 'actions/operatorIssueLimitLists'
import Component from 'components/IssueLimitLists/Operators'

export default createListModalContainer({
  actions,
  extraDispatchToProps: (dispatch, state) => ({
    createItem: (params, options) => dispatch(actions.createItem(params, options)),
  }),
})(Component)
