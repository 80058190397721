import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import cartTypes from 'options/inventory/cartTypes'
import { createSearchSuppliers, createDropdownRender } from 'helpers/formViews'
import { HARD_SPACE, tryParseFloat } from 'helpers/utils'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { allowPricing } from 'helpers/auth'
import { t } from 'helpers/i18n'
import locationVendingTypesOptions from 'options/locations/locationVendingTypes'
import Select, { Option, getOptionProps } from 'elements/Select'
import Checkbox from 'elements/Checkbox'
import Help from 'elements/Help'
import SelectProductCategories from 'containers/ProductCategories/Select'
import InputNumber from 'elements/InputNumber'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchSuppliers = createSearchSuppliers(this)
  }

  componentDidMount() {
    const {
      filterDto: { locationVendingTypes, locationGroupIds, locationIds, supplierIds, productCategoryIds },
    } = this.props

    Promise.all([
      this.props.getLists({
        type: 'Product',
        dateRange: {
          customStartDate: new Date(1970, 1, 1),
          customEndDate: new Date(new Date().setHours(24, 0, 0, 0)),
        },
      }),
      this.props.getLocationGroups(),
      this.props.getLocations({
        locationVendingTypes,
        locationGroupIds,
        locationIds,
      }),
      this.props.getSuppliers(supplierIds ? { supplierIds } : undefined),
      this.props.getProductCategories({ productCategoryIds }),
    ]).catch((error) => showError({ error }))
  }

  getOnHandStatusOptions = () => ({
    LessThanMin: 'onHandLessThanMin',
    GreaterThanMax: 'onHandGreaterThanMax',
    LessThanMinOrGreaterThanMax: 'onHandLessThanMinOrGreaterThanMax',
    BetweenMinAndMax: 'onHandBetweenMinAndMax',
    ...(this.props.customer.moduleSettings.enableOverstockInventory
      ? { LessThanMinAndExistsInOverstock: 'onHandLessThanMinAndExistsInOverstock' }
      : {}),
  })

  render() {
    const { filterDto, suppliers, enableAssetManagement, tenantGroupIsCardinal } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('locationType')}>
              <Select
                onChange={(value) => this.setFilterValue('locationVendingTypes', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationVendingTypes}
              >
                {Object.entries(locationVendingTypesOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item className="whitespace-nowrap" label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('productCategory')}>
              <SelectProductCategories
                value={filterDto.productCategoryIds}
                placeholder={t('all')}
                onChange={(value) => this.setFilterValue('productCategoryIds', value)}
                dataSource={this.props.productCategories ?? []}
              />
            </Form.Item>
            <Form.Item label={t('preferredSupplier')}>
              <Select
                onChange={(value) => this.setFilterValue('supplierIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.supplierIds}
                dropdownRender={createDropdownRender(suppliers)}
                onSearch={this.searchSuppliers}
                showSearch
              >
                <Option value={0}>{t('notAssigned')}</Option>
                {(suppliers?.items ?? [])
                  .filter((each) => each.id)
                  .map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('list')}>
              <Select
                onChange={(values) => this.setFilterValue('listIds', values)}
                placeholder={t('all')}
                value={filterDto.listIds}
                mode="multiple"
              >
                {(this.props.lists ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item
              label={
                <span>
                  {t('onHandStatus')} <Help title={t('onHandStatusInfo')} />
                </span>
              }
            >
              <Select
                onChange={(value) => this.setFilterValue('onHandStatusFilter', value)}
                value={filterDto.onHandStatusFilter}
                placeholder={t('all')}
              >
                {Object.entries(this.getOnHandStatusOptions()).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {allowPricing() && (
              <Row>
                <Col xs={12}>
                  <Form.Item label={t('minimumPrice')}>
                    <InputNumber
                      min={0}
                      value={filterDto.minimumPrice}
                      onChange={(value) => this.setFilterValue('minimumPrice', tryParseFloat(value, 0))}
                      placeholder="0"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label={t('maximumPrice')}>
                    <InputNumber
                      min={-1}
                      value={filterDto.maximumPrice !== -1 ? filterDto.maximumPrice : null}
                      onChange={(value) => this.setFilterValue('maximumPrice', tryParseFloat(value, -1))}
                      placeholder={t('unlimited')}
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {tenantGroupIsCardinal && (
              <Form.Item label={t('misc3')}>
                <Select
                  onChange={(value) => this.setFilterValue('cartType', value)}
                  placeholder={t('all')}
                  value={filterDto.cartType}
                >
                  {Object.entries(cartTypes).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {t(value)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col fourth>
            <Form.Item label={HARD_SPACE}>
              <Checkbox
                onChange={(e) => this.setFilterValue('doNotReorderItemOnly', e.target.checked)}
                checked={filterDto.doNotReorderItemOnly}
              >
                {t('noInventoryReorder')} <Help title={t('noInventoryReorderInfo')} />
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox
                onChange={(e) => this.setFilterValue('criticalItemsOnly', e.target.checked)}
                checked={filterDto.criticalItemsOnly}
              >
                {t('critical')}
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox
                onChange={(e) => this.setFilterValue('lockedInventoryOnly', e.target.checked)}
                checked={filterDto.lockedInventoryOnly}
              >
                {t('lockedForCycleCount')}
              </Checkbox>
            </Form.Item>
            {enableAssetManagement && (
              <Form.Item>
                <Checkbox
                  onChange={(e) => this.setFilterValue('assetReorderBarcodesOnly', e.target.checked)}
                  checked={filterDto.assetReorderBarcodesOnly}
                >
                  {t('assetReorderBarcodes')} <Help title={t('assetReorderBarcodesInfo')} />
                </Checkbox>
              </Form.Item>
            )}
            <Form.Item>
              <Checkbox
                onChange={(e) => this.setFilterValue('showImages', e.target.checked)}
                checked={filterDto.showImages}
              >
                {t('showImages')}
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox
                onChange={(e) => this.setFilterValue('hasNoImage', e.target.checked)}
                checked={filterDto.hasNoImage}
              >
                {t('itemsWithoutImages')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
