import produce from 'immer'
import { snakeCase, isEmpty } from 'lodash'
import { createActions } from 'helpers/actions'
import { createGet, createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.customers
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    useSelectionList: true,
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.alwaysIncludeCustomerIds =
          draft.alwaysIncludeCustomerIds === false ? false : !isEmpty(draft.customerIds)
      }),
  }),

  getHierarchy: (params) => ({
    type: `${ENTITY_NAME}_HIERARCHY_GET`,
    payload: createGet(entityName, { action: 'hierarchy' })(params),
  }),

  getNumberSelectionList: (params) => ({
    type: `${ENTITY_NAME}_NUMBER_SELECTION_LIST_GET`,
    payload: createPost(entityName, { action: 'numberSelectionList' })(params),
  }),

  updateReferenceDate: (params) => ({
    type: `${ENTITY_NAME}_REFERENCE_DATE_UPDATE`,
    payload: createPost(entityName, { action: `${params.id}/updateReferenceDate` })(params),
  }),
}
