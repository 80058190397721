import { camelCase } from 'lodash'
import { createChildListViewComponent } from 'factories/ChildListView'
import entityNames from 'options/entityNames'
import b64toBlob from 'b64-to-blob'
import { saveAs } from 'file-saver'
import { showError } from 'helpers/errors'
import { stopEvent } from 'helpers/events'
import FileUpload from 'elements/FileUpload'

export default createChildListViewComponent({
  entityName: '__ENTITY_NAME__',
  childName: 'documents',
  getStorageKey: (self) => `${camelCase(self.props.domainObjectType)}.${entityNames.documents}`,
  createButtonTextId: 'addDocuments',
  createButtonLinkTarget: {
    formSize: 'xs',
    formComponent: (props) => <FileUpload mode="multiple" {...props} />,
  },
  tableCellComponents: {
    fileName: (self, item) => (
      <a
        onClick={async (e) => {
          stopEvent(e)

          try {
            if (item.fileContents && item.fileName && item.fileType) {
              saveAs(b64toBlob(item.fileContents, item.fileType), item.fileName)
            } else {
              const response = await self.props.downloadItem({
                request: {
                  domainObjectId: self.props.domainObjectId,
                  domainObjectType: self.props.domainObjectType,
                  documentType: 'ObjectDocument',
                  documentName: item.fileName,
                },
                targetTenantId: self.props.targetTenantId,
              })

              saveAs(response.value.data, item.fileName)
            }
          } catch (error) {
            showError({ error })
          }
        }}
      >
        {item.fileName}
      </a>
    ),
  },
  allowSelection: (self) => !self.props.readOnly,
})()
