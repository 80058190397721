import * as React from 'react'
import { Form, Input } from 'antd'
import { showError } from 'helpers/errors'
import cycleCountTypes from 'options/cycleCounts/cycleCountTypes'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import Help from 'elements/Help'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      const { locationGroupIds, locationIds, userGroupIds = [] } = this.props.filterDto
      await Promise.all([
        this.props.getUserGroups(),
        this.props.getUsers({ userGroupIds }),
        this.props.getLocationGroups(),
        this.props.getLocations({ locationGroupIds, locationIds }),
      ])
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto = {} } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker filterDto={filterDto} setFilterValue={this.setFilterValue} />
          </Col>
          <Col fourth>
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item
              label={
                <span>
                  {t('type')} <Help title={t('cycleCountTypeInfo')} />
                </span>
              }
            >
              <Select
                onChange={(value) => this.setFilterValue('type', value)}
                value={filterDto.type}
                placeholder={t('all')}
              >
                {Object.entries(cycleCountTypes).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('status')}>
              <Input placeholder={t('committed')} disabled></Input>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('createdByGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('userGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.userGroupIds}
                mode="multiple"
                allowClear
              >
                {(this.props.userGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.description}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('createdBy')}>
              <Select
                onChange={(value) => this.setFilterValue('userNames', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.userNames}
                allowClear
              >
                {(this.props.users ?? []).map((each) => (
                  <Option key={each.userName} value={each.userName}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
