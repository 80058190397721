import { connect } from 'react-redux'
import selectors from 'selectors'
import jobGroupActions from 'actions/jobGroups'
import jobReasonActions from 'actions/jobReasons'
import jobReasonGroupActions from 'actions/jobReasonGroups'
import jobStatusOptionActions from 'actions/jobStatusOptions'
import Component from 'components/DashboardViewer/Filter/Jobs'

const mapStateToProps = (state, props) => ({
  jobGroups: selectors.jobGroups.selectOptions(state),
  jobReasons: selectors.jobReasons.selectOptions(state),
  jobReasonGroups: selectors.jobReasonGroups.selectOptions(state),
  jobStatusOptions: selectors.jobStatusOptions.selectOptions(state),
})

const mapDispatchToProps = {
  getJobGroups: jobGroupActions.getOptions,
  getJobReasons: jobReasonActions.getOptions,
  getJobReasonGroups: jobReasonGroupActions.getOptions,
  getJobStatus: jobStatusOptionActions.getOptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
