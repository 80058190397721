import moment from 'moment'
import produce from 'immer'
import { baseReportUrl } from 'config'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default createActions(entityNames.monthlyDetailReport, {
  basePath: 'report/orderReports',
  baseUrl: baseReportUrl,
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.locationVendingTypes = ['NonVending']

      const customStartDate = draft.dateRange?.customStartDate ?? moment().startOf('month')

      draft.dateRange = {
        dateRangeType: 'TwelveMonthPeriod',
        customStartDate,
        customEndDate: moment(customStartDate).clone().add(12, 'months').subtract(1, 'seconds'),
      }
    }),
})
