import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import customerActions from 'actions/customers'
import Component from 'components/Customers/FormView/Mobile'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getSettings: (params) => dispatch(customerActions.getSettings(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
