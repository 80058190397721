import * as React from 'react'
import { Form, Input } from 'antd'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  render() {
    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <Form.Item label={t('barcode')}>
              <Input value={this.props.barcode} disabled />
            </Form.Item>
          </Col>
          <Col twoThirds>
            <Form.Item label={t('description')}>
              <Input value={this.props.description} disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
