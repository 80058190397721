import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import { createChildItemsDispatchToProps } from 'helpers/formViews'
import actions from 'actions/rfqs'
import locationActions from 'actions/locations'
import documentActions from 'actions/documents'
import rfqItemActions from 'actions/rfqs/rfqItems'
import rfqSupplierActions from 'actions/rfqs/rfqSuppliers'
import Component from 'components/Rfqs/Print'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getSettings: (params) => dispatch(actions.getSettings(params)),
  getAddressList: (params) => dispatch(locationActions.getAddressList(params)),
  populateRfqItems: (params) => dispatch(rfqItemActions.populateRfqItems(params)),
  getSupplierPrices: (rfqId) => dispatch(actions.getSupplierPrices(rfqId)),
  getDocumentContents: (params) => dispatch(documentActions.getContents(params)),
  ...createChildItemsDispatchToProps(dispatch, 'Rfq', rfqItemActions),
  ...createChildItemsDispatchToProps(dispatch, 'RfqSupplier', rfqSupplierActions),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
