import * as React from 'react'
import { Form, Button, Input, message } from 'antd'
import { isEmpty } from 'lodash'
import { showError, showValidationError } from 'helpers/errors'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import Modal from 'elements/Modal'

function FormView(props) {
  const [visible, setVisible] = React.useState(false)

  function handleSubmit(e) {
    stopEvent(e)

    props.form.validateFields(async (errors, values) => {
      if (!isEmpty(errors)) {
        showValidationError()
        return
      }

      try {
        await props.resetPassword(values)

        message.success(t('resetPasswordEmailSent'))

        setVisible(false)
      } catch (error) {
        showError({ error })
      }
    })
  }

  return (
    <>
      <Button type="link" onClick={() => setVisible(true)} className="p-0">
        {t('forgotYourPassword')}
      </Button>
      <Modal
        title={t('resetYourPassword')}
        okText={t('sendNewPassword')}
        onOk={handleSubmit}
        onCancel={() => setVisible(false)}
        visible={visible}
      >
        <Form layout="vertical" onSubmit={handleSubmit} colon={false}>
          <p>{t('resetYourPasswordDescription')}</p>
          <Form.Item label={t('customerId')}>
            {props.form.getFieldDecorator('tenant', {
              rules: [{ required: true, message: t('requiredField') }],
              initialValue: props.initialValues?.tenant,
            })(<Input autoCorrect="off" autoCapitalize="none" />)}
          </Form.Item>
          <Form.Item label={t('userId')}>
            {props.form.getFieldDecorator('userName', {
              rules: [{ required: true, message: t('requiredField') }],
              initialValue: props.initialValues?.userName,
            })(<Input autoCorrect="off" autoCapitalize="none" />)}
          </Form.Item>
          <Form.Item label={t('email')}>
            {props.form.getFieldDecorator('email', {
              rules: [
                { required: true, message: t('requiredField') },
                {
                  type: 'email',
                  message: t('errorInvalidEmailAddress'),
                },
              ],
              initialValue: props.initialValues?.email,
              validateTrigger: false,
            })(<Input type="email" />)}
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default Form.create()(FormView)
