import produce from 'immer'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default createActions(entityNames.dashboardThemes, {
  useSelectionList: true,
  memoizeGetOptions: true,
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.originType = draft.originType ?? 'All'
      draft.active = draft.active ?? 'All'
    }),
})
