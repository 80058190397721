import * as React from 'react'
import { Spin, Form, Input, Popover } from 'antd'
import { isEmpty, get, isNil } from 'lodash'
import { useImmer } from 'use-immer'
import { showError } from 'helpers/errors'
import { createLabelFactory } from 'helpers/formViews'
import { stopEvent } from 'helpers/events'
import { isValidDate, formatUserTime } from 'helpers/dateTime'
import { Header, Title } from 'helpers/print'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { allowPricing } from 'helpers/auth'
import { calculateOrderTotals } from 'helpers/procurement'
import requisitionerFields from 'options/requisitionerFields'
import Select, { Option, getOptionProps } from 'elements/Select'
import OrderItems from 'containers/Orders/Print/Items'
import OrderSupplierItems from 'containers/Orders/Print/Suppliers'
import { Row, Col } from 'elements/Grid'
import Icon from 'elements/Icon'

function Component(props) {
  const { parentRecord, customer, tenant } = props

  const [state, updateState] = useImmer({ item: parentRecord })

  const { item } = state

  async function generatePurchaseOrderNumber() {
    try {
      const purchaseOrderNumbers = await Promise.all(
        state.orderSupplierItems.map((each) =>
          each.purchaseOrderNumber
            ? Promise.resolve(each)
            : props
                .generatePurchaseOrderNumber({
                  orderId: each.orderId > 0 ? each.orderId : 0,
                  supplierId: each.supplierId,
                })
                .then((response) => ({
                  orderId: each.orderId > 0 ? each.orderId : 0,
                  supplierId: each.supplierId,
                  ...response.value.data,
                }))
        )
      )

      updateState((draft) => {
        draft.orderSupplierItems.forEach((each) => {
          if (each.id < 0 && isEmpty(each.purchaseOrderNumber)) {
            try {
              each.purchaseOrderNumber = purchaseOrderNumbers.find(
                (one) => one.supplierId === each.supplierId
              ).purchaseOrderNumber
            } catch (error) {
              console.warn(error)
            }
          }
        })

        draft.purchaseOrderNumbers = purchaseOrderNumbers
      })
    } catch (error) {
      console.warn(error)
    }
  }

  function refreshOrderTotals() {
    updateState((draft) => {
      Object.assign(draft.item, calculateOrderTotals({ customer: props.customer, ...draft }))
    })
  }

  React.useEffect(() => {
    async function fetchSettings() {
      try {
        const customerId = item.customerId || props.user.customerId
        const targetTenantId = props.filterDto?.tenantId ?? 0

        const responses = await Promise.all([
          props.getSettings({ type: 'order' }),
          props
            .getDocumentContents({
              request: {
                domainObjectId: tenant.id,
                domainObjectType: 'Customer',
                documentType: 'PurchaseOrderLogo',
                documentName: tenant.subdomain,
              },
            })
            .catch(() => {}),
          props.getAddressList({ type: 'billto', targetTenantId, customerId }),
          props.getAddressList({ type: 'shipto', targetTenantId, customerId }),
          isNil(item.orderItems)
            ? props.getOrderItems(item.id, { includeInventoryStatus: true, targetTenantId })
            : Promise.resolve(null),
          isNil(item.orderSupplierItems)
            ? props.getOrderSupplierItems(item.id, { targetTenantId })
            : Promise.resolve(null),
        ])

        updateState((draft) => {
          draft.fieldSettings = responses[0].value.data.fieldSettings
          draft.logo = get(responses[1], 'value.data', {})
          draft.billToAddressList = get(responses[2], 'value.data.items', [])
          draft.shipToAddressList = get(responses[3], 'value.data.items', [])
          draft.orderItems = item.orderItems ?? get(responses[4], 'value.data.items', [])
          draft.orderSupplierItems = item.orderSupplierItems ?? get(responses[5], 'value.data.items', [])
        })

        refreshOrderTotals()
      } catch (error) {
        showError({ error })
      }
    }

    fetchSettings()
  }, [])

  React.useEffect(() => {
    if (!isEmpty(state.orderSupplierItems)) {
      generatePurchaseOrderNumber()
    }
  }, [state.orderSupplierItems])

  const title = `${customer.displayName} - ${t('order')} ${item.id}`
  const createLabel = createLabelFactory(state.fieldSettings)

  return isEmpty(state.fieldSettings) ? (
    <Spin />
  ) : (
    <>
      <Title>
        <Col xs={16}>
          <h2>{title}</h2>
        </Col>
        <Col xs={8} className="text-right">
          {state.logo && state.logo.fileType && state.logo.contents && (
            <img src={`data:${state.logo.fileType};base64,${state.logo.contents}`} alt="" />
          )}
        </Col>
      </Title>
      <Header>
        <Form layout="vertical" colon={false} onSubmit={stopEvent}>
          <Row>
            <Col xs={8}>
              <Form.Item label={t('orderId')}>
                <Input value={item.id} readOnly />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={createLabel('createDate')}>
                <Input value={formatUserTime(item.createDate, item.userName) || t('na')} readOnly />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={createLabel('statusName')}>
                <Input value={item.statusName} readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={16}>
              <Form.Item label={createLabel('billToAddressId')}>
                <Select value={item.billToAddressId} allowClear={false} readOnly>
                  {(state.billToAddressList ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={createLabel('shipToAddressId')}>
                <Select value={item.shipToAddressId} allowClear={false} readOnly>
                  {(state.shipToAddressList ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Row>
                <Col xs={12}>
                  {requisitionerFields.map(({ key, type }) => (
                    <Form.Item key={key} label={createLabel(key)}>
                      <Input value={get(item, key)} type={type} readOnly />
                    </Form.Item>
                  ))}
                  <Form.Item label={createLabel('accountCode')}>
                    <Input value={item.accountCode} readOnly />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label={
                      <span>
                        {createLabel('approveDate')}{' '}
                        <Popover
                          title={t('approvalStatus')}
                          content={<span dangerouslySetInnerHTML={{ __html: item.orderApprovalInfo }} />}
                        >
                          <span
                            className="align-middle"
                            style={{
                              visibility: isEmpty(item.orderApprovalInfo) ? 'hidden' : 'visible',
                            }}
                          >
                            <Icon type="Search" />
                          </span>
                        </Popover>
                      </span>
                    }
                  >
                    <Input
                      value={
                        isValidDate(item.approveDate)
                          ? formatUserTime(item.approveDate, item.approveUserName)
                          : !['Draft', 'New', 'PendingApproval'].includes(item.status) &&
                              isValidDate(item.confirmDate)
                            ? formatUserTime(item.confirmDate, item.userName)
                            : ''
                      }
                      readOnly
                    />
                  </Form.Item>
                  {['confirmDate', 'receivedDate'].map((each) => (
                    <Form.Item key={each} label={createLabel(each)}>
                      <Input value={formatUserTime(item[each], item.userName)} readOnly />
                    </Form.Item>
                  ))}
                </Col>
              </Row>
            </Col>
            {allowPricing() && (
              <Col xs={8}>
                {[
                  ...(props.customer.moduleSettings.enableTax
                    ? ['exemptTotal', 'taxableTotal', 'taxTotal']
                    : []),
                  'subTotal',
                  'adjustmentsTotal',
                  'freightTotal',
                  'orderTotal',
                ].map((each) => (
                  <Form.Item
                    key={each}
                    label={
                      each === 'taxTotal' ? (
                        <span>
                          {createLabel(each)}{' '}
                          {`(${item.taxRate.toLocaleString(props.locale, {
                            style: 'percent',
                            maximumFractionDigits: 2,
                          })})`}
                        </span>
                      ) : (
                        createLabel(each)
                      )
                    }
                  >
                    <Input className="text-right" value={toLocaleCurrency(item[each])} readOnly />
                  </Form.Item>
                ))}
              </Col>
            )}
          </Row>
        </Form>
      </Header>
      <OrderItems
        title={t('itemsTab')}
        settingsType="order"
        items={state.orderItems}
        parentRecord={{
          ...item,
          orderItems: state.orderItems,
          orderSupplierItems: state.orderSupplierItems,
        }}
      />
      <OrderSupplierItems
        title={t('suppliers')}
        settingsType="order"
        items={state.orderSupplierItems}
        parentRecord={{
          ...item,
          orderItems: state.orderItems,
          orderSupplierItems: state.orderSupplierItems,
        }}
      />
      <h3>{t('comments')}</h3>
      <div
        dangerouslySetInnerHTML={{ __html: item.comment }}
        className="ant-input h-auto whitespace-pre-wrap mt-3 min-h-96"
      />
    </>
  )
}

export default Component
