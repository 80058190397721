import produce from 'immer'
import { snakeCase } from 'lodash'
import { baseReportUrl } from 'config'
import { createActions, normalizeDateRange } from 'helpers/actions'
import { createGet } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.userActionReport
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityNames.userActionReport, {
    basePath: 'report/auditReports',
    baseUrl: baseReportUrl,
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        normalizeDateRange(draft)
      }),
  }),

  getObjectTypes: (params) => ({
    type: `${ENTITY_NAME}_OBJECT_TYPES_GET`,
    payload: createGet('auditReports', {
      action: 'objectTypes',
      baseUrl: baseReportUrl,
      basePath: 'report',
    })(params),
  }),

  getActionTypes: (params) => ({
    type: `${ENTITY_NAME}_ACTION_TYPES_GET`,
    payload: createGet('auditReports', {
      action: 'actionTypes',
      baseUrl: baseReportUrl,
      basePath: 'report',
    })(params),
  }),
}
