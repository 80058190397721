import { Redirect, Route } from 'react-router-dom'
import { isEqual, get, isEmpty, toLower, pick } from 'lodash'
import { baseUrl } from 'config'
import entityNames from 'options/entityNames'
import { LOGO_GET } from 'reducers/auth'
import { apiClient, getConfig, createPost } from 'helpers/api'
import store from 'helpers/store'
import selectors from 'selectors'
import { t } from 'helpers/i18n'

const getRootLocation = () => window.location.pathname.split('/').find((one) => !isEmpty(one))

const getLocationParts = () => window.location.pathname.split('/').filter((each) => !isEmpty(each))

export const getHamburgerSelectedKeys = () => [getRootLocation()].filter((each) => !isEmpty(each))

export const getSideMenuSelectedKeys = () => [getLocationParts().join('/')].filter((each) => !isEmpty(each))

export const getSideMenuMustOpenKeys = () => {
  const [level1, level2] = getLocationParts()

  return level2 ? [[level1, level2].join('/')] : []
}

export const getMenuItemAllLevels = (url = window.location.pathname) => {
  const parts = url.split('/').filter((each) => !isEmpty(each))
  const level0 = selectors.auth.menuItems(store.getState())
  const level1 = level0.find((one) => toLower(one.key) === get(parts, '[0]'))
  const level2 = get(level1, 'items', []).find((one) => one.key === get(parts, '[1]'))
  const level3 = get(level2, 'items', []).find((one) => one.key === get(parts, '[2]'))

  return [level1, level2, level3]
}

const getMenuItemThisLevel = (url = window.location.pathname) => {
  const [level1, level2, level3] = getMenuItemAllLevels(url)

  return level3 || level2 || level1
}

export const getMenuItemPath = (key) => {
  try {
    const firstChild = selectors.auth.menuItems(store.getState()).find((one) => one.key === key).items[0]

    if (isEmpty(firstChild.items)) {
      return `/${key}/${firstChild.key}`
    }

    return `/${key}/${firstChild.key}/${firstChild.items[0].key}`
  } catch (error) {
    return `/${key}`
  }
}

export const getPageTitle = (url = window.location.pathname) =>
  t(getMenuItemThisLevel(url)?.languageKey ?? 'underDevelopment')

export const login = async ({ tenant, userName, password }) => {
  const url = `${baseUrl}/api/login`
  const group = `POST ${url}`
  const data = { tenant, userName, password }

  try {
    const response = await apiClient.post(url, data, getConfig())
    console.groupCollapsed(group)
    console.log('Request:', { tenant, userName, password: 'xxx' })
    console.log('Results:', response)
    console.groupEnd()
    return response
  } catch (error) {
    console.groupCollapsed(group)
    console.log('Request:', { tenant, userName, password: 'xxx' })
    console.error(error)
    console.groupEnd()
    throw error
  }
}

export const loginSso = async ({ tenant, accessToken, idToken }) => {
  const url = `${baseUrl}/api/loginSso`
  const group = `POST ${url}`
  const data = { tenant, accessToken, idToken }

  try {
    const response = await apiClient.post(url, data, getConfig())
    console.groupCollapsed(group)
    console.log('Request:', data)
    console.log('Results:', response)
    console.groupEnd()
    return response
  } catch (error) {
    console.groupCollapsed(group)
    console.log('Request:', data)
    console.error(error)
    console.groupEnd()
    throw error
  }
}

export const loginAsCustomer = async (customerId) => {
  const url = `${baseUrl}/api/dcribMasterLogin/${customerId}`
  const group = `POST ${url}`

  try {
    const response = await apiClient.post(url, null, getConfig())
    console.groupCollapsed(group)
    console.log('Results:', response)
    console.groupEnd()
    return response
  } catch (error) {
    console.groupCollapsed(group)
    console.error(error)
    console.groupEnd()
    throw error
  }
}

export const logout = async () => {
  const url = `${baseUrl}/api/users/logout`
  const group = `POST ${url}`
  const data = {}

  try {
    const response = await apiClient.post(url, data, getConfig())
    console.groupCollapsed(group)
    console.log('Request:', data)
    console.log('Results:', response)
    console.groupEnd()
    return response
  } catch (error) {
    console.groupCollapsed(group)
    console.log('Request:', data)
    console.error(error)
    console.groupEnd()
    throw error
  }
}

export function Restricted({ component: Component, ...rest }) {
  const isLogin = () => !isEmpty(get(store.getState(), 'auth.current.authenticationJwt'))

  return (
    <Route {...rest} render={(props) => (isLogin() ? <Component {...props} /> : <Redirect to="/login" />)} />
  )
}

export const getUserPermission = (permissionType) => {
  try {
    return (
      selectors.auth
        .user(store.getState())
        .coreUserSettings.permissions.reduce(
          (acc, each) => ({ ...acc, [each.permissionType]: each.value }),
          {}
        )[permissionType] ?? 'No'
    )
  } catch (error) {
    console.warn(error)
    return 'No'
  }
}

export const createFilterUserSuppliers = (supplierId) => (supplier) => {
  try {
    const { supplierIds = [] } = selectors.auth.user(store.getState()).coreUserSettings ?? {}

    return isEqual(supplierIds, []) || supplierIds.includes(supplier.id) || supplier.id === supplierId
  } catch (error) {
    return false
  }
}

export const allowPricing = () => {
  try {
    const customer = selectors.auth.customer(store.getState())

    if (customer.moduleSettings.enablePricing !== true) {
      return false
    }

    if (getUserPermission('Master') === 'Yes') {
      return true
    }

    return getUserPermission('DisplayPricing') === 'Yes'
  } catch (error) {
    return false
  }
}

export const getPossibleRoutes = (menuItems = [], parent = '') => {
  const routes = []

  menuItems.forEach(({ items = [], key = '' }) => {
    const route = [parent, key].join('/')

    routes.push(route)

    if (!isEmpty(items)) {
      routes.push(...getPossibleRoutes(items, route))
    }
  })

  return routes
}

export const getLogo = ({ id = 0, logoimg = '' } = {}) => ({
  type: LOGO_GET,
  payload: createPost(entityNames.documents, { action: 'getContents' })({
    request: {
      domainObjectId: id,
      domainObjectType: 'Tenant',
      documentType: 'CompanyLogo',
      documentName: logoimg.split('\\').reverse()[0],
    },
  }),
})

export const createAuthStateToProps = (state, props) =>
  Object.entries(selectors.auth).reduce((acc, each) => ({ ...acc, [each[0]]: each[1](state) }), {})

export const getLogoutUrl = (self) =>
  self.props.tenant?.ssoEnabled && self.props.ssoLogoutUrl
    ? `${self.props.ssoLogoutUrl}&post_logout_redirect_uri=${encodeURIComponent(
        `${window.location.origin}/login`
      )}`
    : '/login'

export const sendClientLog = async (
  type, // 'None' | 'Success' | 'Error' | 'Warning' | 'Information'
  comment,
  payload = {}
) => {
  try {
    await createPost('logger', { action: 'clientLog' })({
      type,
      comment,
      detail: JSON.stringify({
        location: window.location.href,
        environment: pick(process.env, ['REACT_APP_NAME', 'REACT_APP_VERSION']),
        navigator: pick(navigator, [
          'appCodeName',
          'appName',
          'appVersion',
          'platform',
          'product',
          'productSub',
          'userAgent',
          'vendor',
          'vendorSub',
          'language',
          'languages',
        ]),
        window: pick(window, ['innerHeight', 'innerWidth', 'outerHeight', 'outerWidth']),
        screen: pick(window.screen, ['colorDepth', 'height', 'isExtended', 'pixelDepth', 'width']),
        orientation: pick(window.screen?.orientation, ['angle', 'type']),
        indexedDB: Boolean(indexedDB),
        localStorage: Boolean(localStorage),
        sessionStorage: Boolean(sessionStorage),
        ...payload,
      }),
    })
  } catch (error) {
    console.error(error)
  }
}
