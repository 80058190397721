import { createChildListPrintComponent } from 'factories/ChildListPrint'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import Checkbox from 'elements/Checkbox'
import DatePicker from 'elements/DatePicker'

export default createChildListPrintComponent({
  entityName: entityNames.rfqs,
  childName: 'rfqSuppliers',
  tableCellComponents: {
    displaySupplier: (self, item) => <Checkbox checked={item.displaySupplier} readOnly />,
    promiseDate: (self, item) => (
      <DatePicker value={item.promiseDate} placeholder={t('none')} readOnly allowClear />
    ),
  },
})()
