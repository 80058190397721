import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import assetStatusOptions from 'options/assets/statusOptions'
import { HARD_SPACE } from 'helpers/utils'
import { createSearchJobs, createSearchOperators, createDropdownRender } from 'helpers/formViews'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import Checkbox from 'elements/Checkbox'
import DateRangePicker from 'elements/DateRangePicker'
import InputNumber from 'elements/InputNumber'
import { Row, Col } from 'elements/Grid'

const periodTypes = {
  Days: 'days',
  Months: 'months',
  Years: 'years',
}

const dateRangeFields = {
  All: 'all',
  StatusChangeDate: 'statusChangeDate',
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchOperators = createSearchOperators(this)
    this.searchJobs = createSearchJobs(this)
  }

  componentDidMount() {
    const { locationGroupIds, operatorGroupIds, locationIds, operatorIds } = this.props.filterDto

    Promise.all([
      this.props.getLocationGroups(),
      this.props.getLocations({ locationGroupIds, locationIds }),
      this.props.getOperators({ operatorIds, operatorGroupIds }),
      this.props.getAssetCategories(),
      this.props.getJobs(),
      this.props.getUsers(),
    ]).catch((error) => showError({ error }))
  }

  render() {
    const { filterDto, jobs, operators } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker
              filterDto={filterDto}
              setFilterValue={this.setFilterValue}
              dateRangeFields={dateRangeFields}
            />
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('job')}>
              <Select
                onChange={(value) => this.setFilterValue('jobIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.jobIds}
                dropdownRender={createDropdownRender(jobs)}
                onSearch={this.searchJobs}
                showSearch
              >
                {(jobs?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('assetCategory')}>
              <Select
                onChange={(value) => this.setFilterValue('assetCategoryIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.assetCategoryIds}
              >
                {(this.props.assetCategories ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('supervisor')}>
              <Select
                onChange={(value) => this.setFilterValue('supervisors', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.supervisors}
                showSearch
              >
                {(this.props.users ?? []).map((each) => (
                  <Option key={each.userName} value={each.userName}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('operator')}>
              <Select
                onChange={(value) => this.setFilterValue('operatorIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.operatorIds}
                dropdownRender={createDropdownRender(operators)}
                onSearch={this.searchOperators}
                showSearch
              >
                {(operators?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) => this.setFilterValue('assetStatusList', value)}
                value={filterDto.assetStatusList}
                mode="multiple"
                placeholder={t('all')}
              >
                {Object.entries(assetStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('numberOfCheckoutsInPeriod')}>
              <InputNumber
                value={filterDto.periodForCheckoutCount}
                onChange={(value) => this.setFilterValue('periodForCheckoutCount', value)}
                style={{
                  width: 'calc(50% - 3px)',
                  marginRight: '6px',
                }}
                min={0}
              />
              <Select
                value={filterDto.periodForCheckoutCountType}
                onChange={(value) => this.setFilterValue('periodForCheckoutCountType', value)}
                style={{ width: 'calc(50% - 3px)' }}
                allowClear={false}
              >
                {Object.entries(periodTypes).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('calibrationRequiredPeriodInDays')}>
              <InputNumber
                onChange={(value) => this.setFilterValue('calibrationRequiredPeriodInDays', value)}
                value={filterDto.calibrationRequiredPeriodInDays}
                min={0}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={HARD_SPACE}>
              <Checkbox
                onChange={(e) => this.setFilterValue('isRental', e.target.checked)}
                checked={filterDto.isRental}
              >
                {t('rental')}
              </Checkbox>
            </Form.Item>
            <Form.Item label={HARD_SPACE}>
              <Checkbox
                onChange={(e) => this.setFilterValue('isOverdue', e.target.checked)}
                checked={filterDto.isOverdue}
              >
                {t('overdue')}
              </Checkbox>
            </Form.Item>
            <Form.Item label={HARD_SPACE}>
              <Checkbox
                onChange={(e) => this.setFilterValue('isCritical', e.target.checked)}
                checked={filterDto.isCritical}
              >
                {t('critical')}
              </Checkbox>
            </Form.Item>
            <Form.Item label={HARD_SPACE}>
              <Checkbox
                onChange={(e) => this.setFilterValue('showImages', e.target.checked)}
                checked={filterDto.showImages}
              >
                {t('showImages')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
