import { Popover } from 'antd'
import { isEmpty } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'
import Filter from 'containers/Reports/AssetProductAssetsReport/Filter'

export default createListViewComponent({
  entityName: entityNames.assetProductAssetsReport,
  filterTemplateType: 'AssetProductAssetsReport',
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  tableCellComponents: {
    quantityOnOrder: (self, item) => (
      <div>
        {item.quantityOnOrder > 0 ? (
          <>
            <span className="mr-6">{item.quantityOnOrder}</span>
            <Popover
              title={t('onOrder')}
              content={<span dangerouslySetInnerHTML={{ __html: item.onOrderInfo }} />}
            >
              <span
                className="align-middle"
                style={{ visibility: isEmpty(item.onOrderInfo) ? 'hidden' : 'visible' }}
              >
                <Icon type="Search" />
              </span>
            </Popover>
          </>
        ) : (
          <span className="mr-24">{item.quantityOnOrder}</span>
        )}
      </div>
    ),
    quantityOnLists: (self, item) => (
      <div>
        {item.quantityOnLists > 0 ? (
          <>
            <span className="mr-6">{item.quantityOnLists}</span>
            <Popover
              title={t('onLists')}
              content={<span dangerouslySetInnerHTML={{ __html: item.onListInfo }} />}
            >
              <span
                className="align-middle"
                style={{ visibility: isEmpty(item.onListInfo) ? 'hidden' : 'visible' }}
              >
                <Icon type="Search" />
              </span>
            </Popover>
          </>
        ) : (
          <span className="mr-24">{item.quantityOnLists}</span>
        )}
      </div>
    ),
    quantityOnHand: (self, item) => (
      <div>
        {item.quantityOnHand > 0 ? (
          <>
            <span className="mr-6">{item.quantityOnHand}</span>
            <Popover
              title={t('onhand')}
              content={<span dangerouslySetInnerHTML={{ __html: item.onHandInfo }} />}
            >
              <span
                className="align-middle"
                style={{ visibility: isEmpty(item.onHandInfo) ? 'hidden' : 'visible' }}
              >
                <Icon type="Search" />
              </span>
            </Popover>
          </>
        ) : (
          <span className="mr-24">{item.quantityOnHand}</span>
        )}
      </div>
    ),
  },
  initialFilterDto: (self) => ({ active: 'Active' }),
})(Filter)
