import { createListViewContainer } from 'factories/ListView'
import selectors from 'selectors'
import actions from 'actions/inventory'
import locationActions from 'actions/locations'
import Component from 'components/Inventory'

export default createListViewContainer({
  actions,
  selectors: selectors.inventory,
  extraDispatchToProps: (dispatch, props) => ({
    setPreferredSupplier: (params) => dispatch(actions.setPreferredSupplier(params)),
    getLocations: (params) => dispatch(locationActions.getItems(params)),
    duplicateInventory: (params) => dispatch(actions.duplicate(params)),
  }),
})(Component)
