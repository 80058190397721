import { get, set, isNil, isEmpty, cloneDeep, defaultTo, unset } from 'lodash'

export const createSetFilterValue = (self) => async (name, value) => {
  const filterDto = cloneDeep(self.props.filterDto)

  if (name === 'jobReasonGroupIds') {
    unset(filterDto, 'jobReasonId')
    unset(filterDto, 'jobReasonIds')
    self.props.setFilterValue?.('jobReasonId', undefined)
    self.props.setFilterValue?.('jobReasonIds', undefined)

    if (self.props.getJobReasons) {
      const response = await self.props.getJobReasons({ jobReasonGroupIds: value })

      self.setState?.({ jobReasons: get(response, 'value.data.items', []) })
    }
  }

  if (['includeInactiveSuppliers', 'supplierRecordStatus'].includes(name)) {
    unset(filterDto, 'supplierId')
    unset(filterDto, 'supplierIds')
    self.props.setFilterValue?.('supplierId', undefined)
    self.props.setFilterValue?.('supplierIds', undefined)

    self.searchSuppliers?.()
  }

  if (name === 'locationGroupIds') {
    unset(filterDto, 'locationId')
    unset(filterDto, 'locationIds')
    self.props.setFilterValue?.('locationId', undefined)
    self.props.setFilterValue?.('locationIds', undefined)

    if (self.props.getLocations) {
      const { locationVendingTypes = [] } = filterDto

      const response = await self.props.getLocations({
        locationVendingTypes,
        locationGroupIds: defaultTo(value, undefined),
      })

      self.setState?.({ locations: get(response, 'value.data.items', []) })
    }

    if (self.props.customer.moduleSettings.enableOverstockInventory) {
      unset(filterDto, 'overstockLocationId')
      unset(filterDto, 'overstockLocationIds')
      self.props.setFilterValue?.('overstockLocationId', undefined)
      self.props.setFilterValue?.('overstockLocationIds', undefined)

      if (self.props.getOverstockLocations) {
        const response = await self.props.getOverstockLocations({
          locationGroupIds: defaultTo(value, undefined),
        })

        self.setState?.({ overstockLocations: get(response, 'value.data.items', []) })
      }
    }
  }

  if (name === 'locationVendingTypes') {
    unset(filterDto, 'locationId')
    unset(filterDto, 'locationIds')
    self.props.setFilterValue?.('locationId', undefined)
    self.props.setFilterValue?.('locationIds', undefined)

    if (self.props.getLocations) {
      const { locationGroupIds } = filterDto

      const response = await self.props.getLocations({
        locationGroupIds,
        locationVendingTypes: defaultTo(value, []),
      })

      self.setState?.({ locations: get(response, 'value.data.items', []) })
    }
  }

  if (name === 'assetCategoryIds') {
    unset(filterDto, 'assetId')
    unset(filterDto, 'assetIds')
    unset(filterDto, 'assetBarcodes')
    self.props.setFilterValue?.('assetId', undefined)
    self.props.setFilterValue?.('assetIds', undefined)
    self.props.setFilterValue?.('assetBarcodes', undefined)

    if (self.props.getAssets) {
      await self.props.getAssets({ assetCategoryIds: value })
    }
  }

  if (name === 'productCategoryIds') {
    unset(filterDto, 'productId')
    unset(filterDto, 'productIds')
    unset(filterDto, 'productBarcodes')
    self.props.setFilterValue?.('productId', undefined)
    self.props.setFilterValue?.('productIds', undefined)
    self.props.setFilterValue?.('productBarcodes', undefined)

    if (self.props.getProducts) {
      await self.props.getProducts({ productCategoryIds: value })
    }
  }

  if (name === 'operatorGroupIds') {
    unset(filterDto, 'operatorIds')
    self.props.setFilterValue?.('operatorIds', undefined)

    if (self.props.getOperators) {
      const response = await self.props.getOperators({ operatorGroupIds: value })

      self.setState?.({ operators: get(response, 'value.data.items', []) })
    }
  }

  if (name === 'userRecordStatus') {
    unset(filterDto, 'users')
    unset(filterDto, 'userNames')
    self.props.setFilterValue?.('users', undefined)
    self.props.setFilterValue?.('userNames', undefined)

    if (self.props.getUsers) {
      const response = await self.props.getUsers({
        active: value,
        userGroupIds: filterDto?.userGroupIds ?? [],
        tenantId: filterDto.tenantId,
        customerId: filterDto.customerId,
      })

      self.setState?.({ users: get(response, 'value.data.items', []) })
    }
  }

  if (name === 'userGroupIds') {
    unset(filterDto, 'users')
    unset(filterDto, 'userNames')
    self.props.setFilterValue?.('users', undefined)
    self.props.setFilterValue?.('userNames', undefined)

    if (self.props.getUsers) {
      const response = await self.props.getUsers({
        active: filterDto?.userRecordStatus ?? 'All',
        userGroupIds: value,
        tenantId: filterDto.tenantId,
        customerId: filterDto.customerId,
      })

      self.setState?.({ users: get(response, 'value.data.items', []) })
    }
  }

  if (['preferredSupplierIds', 'supplierIds'].includes(name) && isEmpty(value)) {
    if (self.props.getSuppliers) {
      await self.props.getSuppliers()
    }
  }

  if (['operatorIds', 'assignedToId', 'assignedOperatorIds'].includes(name) && isEmpty(value)) {
    if (self.props.getOperators) {
      await self.props.getOperators({ operatorGroupIds: filterDto.operatorGroupIds })
    }
  }

  if (['jobsForAssetIds', 'assetId', 'assetIds'].includes(name) && isEmpty(value)) {
    if (self.props.getAssets) {
      await self.props.getAssets({ assetCategoryIds: filterDto.assetCategoryIds })
    }
  }

  if (
    [
      'supervisor',
      'supervisors',
      'createdByUserName',
      'createdByUserNames',
      'createdBy',
      'createdByUserId',
      'createdByUserIds',
      'userName',
      'userNames',
      'assignedUserId',
      'assignedUserIds',
    ].includes(name) &&
    isEmpty(value)
  ) {
    if (self.props.getUsers) {
      await self.props.getUsers({
        tenantId: filterDto.tenantId,
        customerId: filterDto.customerId,
        userGroupIds: filterDto.userGroupIds,
      })
    }
  }

  if (!isNil(name)) {
    if (!isNil(value)) {
      set(filterDto, name, value)
      self.props.setFilterValue?.(name, value)
    } else {
      set(filterDto, name, undefined)
      self.props.setFilterValue?.(name, undefined)
    }
  }

  self.props.onChange?.({ ...filterDto })
}

export const createHandleFilterChange =
  (self) =>
  (filterDto = {}) => {
    const filterPrev = self.state.filterDto ?? {}

    if (
      filterDto?.dateRange?.dateRangeType === 'Custom' &&
      filterPrev?.dateRange?.dateRangeType !== 'Custom'
    ) {
      self.setState({ filterDto: cloneDeep(filterDto), selectedRowKeys: [] })
    } else {
      self.setState({ filterDto: cloneDeep(filterDto), pageIndex: 1, selectedRowKeys: [] }, self.searchItems)
    }
  }
