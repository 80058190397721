export default {
  Custom: 'custom',
  Today: 'today',
  Yesterday: 'yesterday',
  MonthToDate: 'monthToDate',
  YearToDate: 'yearToDate',
  LastWeek: 'lastWeek',
  LastMonth: 'lastMonth',
  LastYear: 'lastYear',
  LastNDays: 'lastNDays',
  LastNWeeks: 'lastNWeeks',
  LastNMonths: 'lastNMonths',
}
