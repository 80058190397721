import * as React from 'react'
import styled from 'styled-components'
import { HARD_SPACE } from 'helpers/utils'
import Modal from 'elements/Modal'

const Container = styled.div`
  width: 95px;
  height: 95px;
  padding: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
  }
`

function Component({ title, children }) {
  const [visible, setVisible] = React.useState(false)

  return (
    <>
      <Container onClick={() => setVisible(true)}>{children}</Container>
      <Modal
        title={title || HARD_SPACE}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={992}
      >
        <Wrapper>{children}</Wrapper>
      </Modal>
    </>
  )
}

export default Component
