import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { t } from 'helpers/i18n'
import { createSearchOperators, createDropdownRender, isReadOnly } from 'helpers/formViews'
import statusOptions from 'options/statusOptions'
import { createGetFieldLabel } from 'helpers/dashboards'
import Select, { Option, getOptionProps } from 'elements/Select'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchOperators = createSearchOperators(this)
  }

  async componentDidMount() {
    try {
      const { operatorIds, operatorGroupIds, userRecordStatus = 'All' } = this.props.filterDto

      await Promise.all([
        this.props.getOperatorGroups(),
        this.props.getOperators({ operatorIds, operatorGroupIds }),
        this.props.getUsers({ active: userRecordStatus }),
      ])
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { fields = [], filterDto = {}, operators } = this.props

    const getFieldLabel = createGetFieldLabel(fields)
    const readOnly = isReadOnly(this)

    return (
      <>
        {fields.find((one) => one.type === 'usernames') && (
          <Form.Item label={getFieldLabel('usernames')}>
            <Select
              onChange={(value) => this.setFilterValue('userNames', value)}
              mode="multiple"
              value={filterDto.userNames}
              placeholder={t('all')}
              readOnly={readOnly}
              showSearch
            >
              {(this.props.users ?? []).map((each) => (
                <Option key={each.userName} value={each.userName}>
                  <span {...getOptionProps(each)}>{each.displayName}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'userRecordStatus') && (
          <Form.Item label={getFieldLabel('userRecordStatus')}>
            <Select
              onChange={(value) => this.setFilterValue('userRecordStatus', value || 'All')}
              value={filterDto?.userRecordStatus ?? 'All'}
              readOnly={readOnly}
            >
              {Object.entries(statusOptions).map(([key, value]) => (
                <Option key={key} value={key}>
                  {t(value)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'operatorGroupIds') && (
          <Form.Item label={getFieldLabel('operatorGroupIds')}>
            <Select
              onChange={(value) => this.setFilterValue('operatorGroupIds', value)}
              mode="multiple"
              placeholder={t('all')}
              value={filterDto.operatorGroupIds}
              readOnly={readOnly}
            >
              <Option value={-1}>{t('unassigned')}</Option>
              {(this.props.operatorGroups ?? []).map((each) => (
                <Option key={each.id} value={each.id}>
                  <span {...getOptionProps(each)}>{each.displayName}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'operatorIds') && (
          <Form.Item label={getFieldLabel('operatorIds')}>
            <Select
              onChange={(value) => this.setFilterValue('operatorIds', value)}
              mode="multiple"
              placeholder={t('all')}
              value={filterDto.operatorIds}
              dropdownRender={createDropdownRender(operators)}
              onSearch={this.searchOperators}
              readOnly={readOnly}
              showSearch
            >
              {(operators?.items ?? []).map((each) => (
                <Option key={each.id} value={each.id}>
                  <span {...getOptionProps(each)}>{each.displayName}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </>
    )
  }
}

export default Form.create()(Filter)
