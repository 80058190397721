import * as React from 'react'
import styled from 'styled-components'
import { Form, Spin, Input, message } from 'antd'
import { isFinite, get, isEmpty, isEqual, isNil } from 'lodash'
import cx from 'clsx'
import produce from 'immer'
import { showError, showClientNotifications } from 'helpers/errors'
import { t } from 'helpers/i18n'
import { Emitter, stopEvent } from 'helpers/events'
import { FORM_SUBMIT_EVENT } from 'options/events'
import Select, { Option, getOptionProps } from 'elements/Select'
import InputNumber from 'elements/InputNumber'
import { Row, Col } from 'elements/Grid'
import Modal from 'elements/Modal'

const Container = styled(Row)`
  .ant-form-item {
    margin-bottom: 12px !important;

    .ant-form-explain {
      display: none !important;
    }
  }
`

class FormView extends React.Component {
  state = {}

  async componentDidMount() {
    if (isNil(this.props.parentRecord)) {
      this.props.onCancel?.()
      return
    }

    await this.fetchSettings()
    await this.fetchMoveData()

    Emitter.on(FORM_SUBMIT_EVENT, this.handleSubmit)
  }

  componentWillUnmount() {
    Emitter.off(FORM_SUBMIT_EVENT, this.handleSubmit)
  }

  componentDidUpdate(prevProps) {
    if (isNil(this.props.parentRecord)) {
      this.props.onCancel?.()
      return
    }

    if (!isEqual(prevProps.parentRecord, this.props.parentRecord)) {
      this.fetchMoveData()
    }
  }

  fetchSettings = async () => {
    try {
      const { locationGroupId } = await this.props
        .getOverstockLocation(this.props.parentRecord.overstockLocationId)
        .then((r) => r.value.data)

      const locations = await this.props
        .getLocations({ locationVendingTypes: ['NonVending'], locationGroupIds: [locationGroupId] })
        .then((r) => get(r, 'value.data.items', []))

      this.setState({ locations })
    } catch (error) {
      showError({ error })
    }
  }

  fetchMoveData = async () => {
    try {
      this.setState({ fetchMoveDataInProgress: true })

      const { toLocationId = 0, onHandQuantityToMove = 0, onHand2QuantityToMove = 0 } = this.state.item ?? {}

      const [item] = await this.props
        .getOverstockInventoryMoveData([
          {
            barcode: this.props.parentRecord.barcode,
            fromOverstockLocationId: this.props.parentRecord.overstockLocationId,
            fromOverstockInventoryId: this.props.parentRecord.overstockInventoryId,
            fromOverstockBinLocation: this.props.parentRecord.binLocation,
            toLocationId,
            onHandQuantityToMove,
            onHand2QuantityToMove,
          },
        ])
        .then((r) =>
          r.value.data.items.map((each) => ({
            ...each,
            id: undefined,
            toLocationId: each.toLocationId || null,
            onHandQuantityToMove: this.props.customer.generalSettings.enableFillToMax
              ? Math.max(0, each.fillToMaxQuantity)
              : each.onHandQuantityToMove,
          }))
        )

      this.setState({ item })
    } catch (error) {
      showError({ error })
    } finally {
      this.setState({ fetchMoveDataInProgress: false })
    }
  }

  showValidationMessage = () => message.error(t('selectToInventoryLocationFirst'))

  handleSubmit = (e) => {
    stopEvent(e)

    this.props.form.validateFields((errors, values) => {
      if (isEmpty(errors)) {
        if (this.state.item.onHandQuantityToMove === 0 && this.state.item.onHand2QuantityToMove === 0) {
          message.error(t('errorNoTransferQuantity'))
          throw new Error()
        }

        const onHandQuantityToMove = Math.min(
          this.state.item.fromOnHandQuantity,
          this.state.item.onHandQuantityToMove
        )
        const onHand2QuantityToMove = Math.min(
          this.state.item.fromOnHand2Quantity,
          this.state.item.onHand2QuantityToMove
        )
        const moveItems = async () => {
          const { item } = this.state

          try {
            await this.props.onSave?.(true)

            const response = await this.props.moveOverstockInventoryItems([
              {
                ...item,
                onHandQuantityToMove,
                onHand2QuantityToMove,
              },
            ])

            showClientNotifications({ response })

            if (response.value.data.failureCount > 0) {
              throw new Error()
            }

            await this.props.onSave?.(false)
            await this.props.onSaveAndClose?.(response)
          } catch (error) {
            showError({ error })
            await this.props.onSave?.(false)
          }
        }

        if (
          this.props.customer.generalSettings.enableFillToMax &&
          onHandQuantityToMove + onHand2QuantityToMove > this.state.item.fillToMaxQuantity
        ) {
          Modal.confirm({
            autoFocusButton: 'ok',
            title: t('confirmTransferQuantityTitle'),
            content: t('confirmTransferQuantity'),
            okType: 'primary',
            okText: t('transfer'),
            cancelText: t('cancel'),
            onOk: () => moveItems(),
          })
        } else {
          moveItems()
        }
      } else {
        this.showValidationMessage()
      }
    })
  }

  setItemValue = (name, value) =>
    this.setState(
      produce((draft) => {
        if (['onHandQuantityToMove', 'onHand2QuantityToMove'].includes(name)) {
          draft.item[name] = isFinite(value) ? value : 0
        } else {
          draft.item[name] = value
        }
      }),
      async () => {
        await this.props.onChange?.(this.state.item)

        if (name === 'toLocationId') {
          await this.fetchMoveData()
        }
      }
    )

  render() {
    const { parentRecord } = this.props
    const { item, fetchMoveDataInProgress } = this.state

    if (isNil(item) || isNil(this.state.locations) || isNil(item.onHandQuantityToMove)) {
      return <Spin />
    }

    const { enableFillToMax } = this.props.customer.generalSettings
    const transferQuantity = Math.min(item.onHandQuantityToMove, item.fromOnHandQuantity)
    const deficiencyQuantity =
      item.fromOnHandQuantity < item.onHandQuantityToMove
        ? item.onHandQuantityToMove - item.fromOnHandQuantity
        : 0

    const transfer2Quantity = Math.min(item.onHand2QuantityToMove, item.fromOnHand2Quantity)
    const deficiency2Quantity =
      item.fromOnHand2Quantity < item.onHand2QuantityToMove
        ? item.onHand2QuantityToMove - item.fromOnHand2Quantity
        : 0

    return (
      <Form
        layout="vertical"
        onSubmit={fetchMoveDataInProgress ? stopEvent : this.handleSubmit}
        colon={false}
      >
        <Container gutter={24}>
          <Col xs={12} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.15)' }}>
            <Form.Item label={t('fromOverstockLocation')}>
              <Input value={parentRecord.overstockLocationName} disabled />
            </Form.Item>
            <Form.Item label={t('binLocation')}>
              <Input value={parentRecord.binLocation} disabled />
            </Form.Item>
            <Row>
              <Col xs={12}>
                <Form.Item label={t('onHand')}>
                  <Input value={parentRecord.onHand} disabled />
                </Form.Item>
                <Form.Item label={t('quantity')}>
                  <InputNumber
                    value={item.onHandQuantityToMove}
                    onChange={(value) => {
                      if (enableFillToMax && !item.toLocationId) {
                        this.showValidationMessage()
                      } else {
                        this.setItemValue('onHandQuantityToMove', value)
                      }
                    }}
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  label={t('transfer')}
                  className={cx({
                    'form-item-success':
                      transferQuantity === item.onHandQuantityToMove && item.onHandQuantityToMove > 0,
                    'form-item-warning': transferQuantity < item.onHandQuantityToMove,
                  })}
                >
                  <Input value={transferQuantity} disabled />
                </Form.Item>
                <Form.Item
                  label={t('deficiency')}
                  className={cx({
                    'form-item-error': deficiencyQuantity > 0,
                  })}
                >
                  <Input value={deficiencyQuantity} disabled />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label={t('onHand2')}>
                  <Input value={parentRecord.onHand2} disabled />
                </Form.Item>
                <Form.Item label={t('quantity2')}>
                  <InputNumber
                    value={item.onHand2QuantityToMove}
                    onChange={(value) => {
                      if (enableFillToMax && !item.toLocationId) {
                        this.showValidationMessage()
                      } else {
                        this.setItemValue('onHand2QuantityToMove', value)
                      }
                    }}
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  label={t('transfer2')}
                  className={cx({
                    'form-item-success':
                      transfer2Quantity === item.onHand2QuantityToMove && item.onHand2QuantityToMove > 0,
                    'form-item-warning': transfer2Quantity < item.onHand2QuantityToMove,
                  })}
                >
                  <Input value={transfer2Quantity} disabled />
                </Form.Item>
                <Form.Item
                  label={t('deficiency2')}
                  className={cx({
                    'form-item-error': deficiency2Quantity > 0,
                  })}
                >
                  <Input value={deficiency2Quantity} disabled />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Form.Item label={t('toInventoryLocation')} required>
              {this.props.form.getFieldDecorator('toLocationId', {
                rules: [{ required: true, message: t('requiredField') }],
              })(
                <Select onChange={(value) => this.setItemValue('toLocationId', value)} allowClear={false}>
                  {(this.state.locations ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t('binLocation')}>
              <Input value={item.toBinLocation} disabled />
            </Form.Item>
            {enableFillToMax && (
              <>
                <Form.Item label={t('fillToMax')}>
                  <Input value={item.fillToMaxQuantity} disabled />
                </Form.Item>
                <Row>
                  <Col xs={12}>
                    <Form.Item label={t('min')}>
                      <Input value={item.toMin} disabled />
                    </Form.Item>
                    <Form.Item label={t('onhand')}>
                      <Input value={item.toOnHandQuantity} disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item label={t('max')}>
                      <Input value={item.toMax} disabled />
                    </Form.Item>
                    <Form.Item label={t('onHand2')}>
                      <Input value={item.toOnHand2Quantity} disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Container>
      </Form>
    )
  }
}

export default Form.create()(FormView)
