import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { HARD_SPACE } from 'helpers/utils'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { getUserPermission } from 'helpers/auth'
import { PRIVATE_DATABASE } from 'options/products'
import Select, { Option } from 'elements/Select'
import Checkbox from 'elements/Checkbox'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      const response = await this.props.getProductCatalogs()

      this.setState({ catalogs: response.value.data })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto = {}, privateDatabaseOnly } = this.props

    const catalogTableName = filterDto?.catalogTableName ?? PRIVATE_DATABASE

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <Form.Item label={t('database')}>
              <Select
                onChange={(value) => this.setFilterValue('catalogTableName', value || PRIVATE_DATABASE)}
                placeholder={t('database')}
                value={catalogTableName}
                disabled={privateDatabaseOnly}
              >
                <Option value="privateDatabase">{t(PRIVATE_DATABASE)}</Option>
                {getUserPermission('FavoritesOnly') !== 'Yes' &&
                  (this.state.catalogs ?? []).map((each) => (
                    <Option key={each.catalogTableName} value={each.catalogTableName}>
                      {each.longName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          {catalogTableName === PRIVATE_DATABASE && (
            <Col third>
              <Form.Item label={HARD_SPACE}>
                {getUserPermission('FavoritesOnly') === 'Yes' ? (
                  <Checkbox checked disabled>
                    {t('favorites')}
                  </Checkbox>
                ) : (
                  <Checkbox
                    onChange={(e) => this.setFilterValue('selectFavorites', e.target.checked)}
                    checked={filterDto.selectFavorites}
                  >
                    {t('favorites')}
                  </Checkbox>
                )}
              </Form.Item>
            </Col>
          )}
          <Col third>
            <Form.Item label={HARD_SPACE}>
              <Checkbox
                onChange={(e) => this.setFilterValue('showImages', e.target.checked)}
                checked={filterDto.showImages}
              >
                {t('showImages')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
