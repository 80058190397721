import { createChildListModalComponent } from 'factories/ChildListModal'

export default createChildListModalComponent({
  getStorageKey: (self) => 'inventory.issue.formView.validateIssueStatus',
  getSettingsType: (self) => 'validateIssueStatus',
  getRowClassName: (self) => (record, index) => (record.itemCanBeIssued === false ? 'table-row-error' : ''),
  getRowSelectionCheckboxProps: (self) => (record) => ({ disabled: record.itemCanBeIssued === false }),
  tableCellComponents: {
    validateIssueStatusDescription: (self, record) => (
      <div dangerouslySetInnerHTML={{ __html: record.validateIssueStatusDescription }} />
    ),
  },
})()
