import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import jobActions from 'actions/jobs'
import operatorActions from 'actions/operators'
import assetActions from 'actions/assets'
import tagListActions from 'actions/tagLists'
import Component from 'components/TrackAssets'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getJobs: (params) =>
    dispatch(
      jobActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
      })
    ),
  getOperators: (params) =>
    dispatch(
      operatorActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
        active: 'Active',
      })
    ),
  getAssets: (params) =>
    dispatch(
      assetActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
      })
    ),
  getTagListItems: (params) => dispatch(tagListActions.getOptions(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
