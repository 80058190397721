import { createChildListViewContainer } from 'factories/ChildListView'
import actions from 'actions/orders/orderSuppliers'
import Component from 'components/Orders/Suppliers'

export default createChildListViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    previewPurchaseOrder: (params) => dispatch(actions.previewPurchaseOrder(params)),
  }),
})(Component)
