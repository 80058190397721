import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { allowPricing } from 'helpers/auth'
import Filter from 'containers/Reports/InventoryCycleCountDifferenceReport/Filter'

export default createListViewComponent({
  entityName: entityNames.inventoryCycleCountDifferenceReport,
  filterTemplateType: 'InventoryCycleCountDifferenceReport',
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  tableSummary: (self) =>
    allowPricing()
      ? `${t('totalValueDifference:')} ${[
          self.props.list?.totalValueDifference ?? 0,
          self.props.list?.totalValueDifference2 ?? 0,
        ]
          .map(toLocaleCurrency)
          .join(' / ')}`
      : '',
  tableCellComponents: {
    countNotEqualOnHand: (self, item) => (item.countNotEqualOnHand ? t('yes') : t('no')),
    countLessThanOnHand: (self, item) => (item.countLessThanOnHand ? t('yes') : t('no')),
    countGreaterThanOnHand: (self, item) => (item.countGreaterThanOnHand ? t('yes') : t('no')),
  },
})(Filter)
