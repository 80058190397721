import { Button } from 'antd'
import { memoize } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import FormView from 'containers/Lists/FormView'
import Associations from 'containers/Lists/Associations'
import Filter from 'containers/Lists/Filter'

export default createListViewComponent({
  entityName: entityNames.lists,
  filterTemplateType: 'List',
  createButtonTextId: 'createList',
  primaryLinkTarget: 'listRecord',
  createButtonLinkTarget: memoize(
    (self) => ({
      formComponent: (props) => <FormView {...props} settingsType={self.props.type} />,
      formSize: 'xl',
    }),
    (self) => self.props.type
  ),
  initialFilterDto: (self) => ({ type: self.props.type }),
  transformGetItemsParams: (self) => ({ type: self.props.type }),
  extraRowActions: (self) => (
    <>
      <Button
        disabled={self.state.selectedRowKeys.length !== 1}
        onClick={() => self.setState({ listAssociationsVisible: true })}
      >
        <Icon type="SyncAlt" />
        {t('associations')}
      </Button>
      <Modal
        title={t('associations')}
        visible={self.state.listAssociationsVisible}
        onCancel={() => self.setState({ listAssociationsVisible: false })}
        footer={
          <Button onClick={() => self.setState({ listAssociationsVisible: false })}>{t('close')}</Button>
        }
        width={992}
      >
        <Associations
          settingsType={`${self.props.type}ListEntities`}
          filterProps={{
            listType: self.props.type,
            listId: self.state.selectedRowKeys[0],
          }}
          onDelete={self.fetchItems}
          mode="multiple"
          allowDelete
        />
      </Modal>
    </>
  ),
  getSettingsType: (self) => self.props.type,
})(Filter)
