import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import statusOptions from 'options/statusOptions'
import { createDropdownRender, createSearchSuppliers } from 'helpers/formViews'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import SelectProductCategories from 'containers/ProductCategories/Select'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchSuppliers = createSearchSuppliers(this)
  }

  componentDidMount() {
    const { supplierIds, productCategoryIds } = this.props.filterDto

    Promise.all([
      this.props.getSuppliers(supplierIds ? { supplierIds } : undefined),
      this.props.getProductCategories({ productCategoryIds }),
    ]).catch((error) => showError({ error }))
  }

  render() {
    const { filterDto = {}, suppliers } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <Form.Item label={t('productCategory')}>
              <SelectProductCategories
                value={filterDto.productCategoryIds}
                placeholder={t('all')}
                onChange={(value) => this.setFilterValue('productCategoryIds', value)}
                dataSource={this.props.productCategories ?? []}
              />
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('suppliers')}>
              <Select
                onChange={(value) => this.setFilterValue('supplierIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.supplierIds}
                dropdownRender={createDropdownRender(suppliers)}
                onSearch={this.searchSuppliers}
                showSearch
              >
                {(suppliers?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) => this.setFilterValue('active', value || 'Active')}
                value={filterDto.active || 'Active'}
              >
                {Object.entries(statusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
