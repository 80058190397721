import produce from 'immer'
import { snakeCase } from 'lodash'
import { createActions, normalizeDateRange } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.formInstances
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.versionType = draft.versionType ?? 'NonLegacy'

        normalizeDateRange(draft)
      }),
  }),

  downloadAttachmentsMultiple: (params) => ({
    type: `${ENTITY_NAME}_DOWNLOAD_ATTACHMENTS_LIST`,
    payload: createPost(entityName, {
      action: 'downloadAttachments',
      responseType: 'blob',
      acceptMimeType: 'application/zip',
    })(params),
  }),

  updateStatus: (formInstanceId, name, value) => ({
    type: `${ENTITY_NAME}_UPDATE_STATUS`,
    payload: createPost(entityName, { action: `${formInstanceId}/update${name}?status=${value}` })(),
  }),

  submitItem: (formInstanceId) => ({
    type: `${ENTITY_NAME}_SUBMIT_ITEM`,
    payload: createPost(entityName, { action: `${formInstanceId}/submit` })(),
  }),

  getAttachments: (formInstanceId, params) => ({
    type: `${ENTITY_NAME}_LIST_ATTACHMENTS`,
    payload: createPost(entityName, {
      action: `${formInstanceId}/attachmentsList`,
      entityId: 'attachmentId',
    })(params),
  }),

  createAttachments: (formInstanceId, params) => ({
    type: `${ENTITY_NAME}_CREATE_ATTACHMENTS`,
    payload: createPost(entityName, { action: `${formInstanceId}/createAttachments` })(params),
  }),

  deleteAttachments: (formInstanceId, params) => ({
    type: `${ENTITY_NAME}_DELETE_ATTACHMENTS`,
    payload: createPost(entityName, { action: `${formInstanceId}/deleteAttachments` })(params),
  }),

  downloadAttachment: (formInstanceId, params) => ({
    type: `${ENTITY_NAME}_DOWNLOAD_ATTACHMENT`,
    payload: createPost(entityName, {
      action: `${formInstanceId}/downloadAttachment`,
      responseType: 'blob',
    })(params),
  }),

  downloadAttachments: (formInstanceId) => ({
    type: `${ENTITY_NAME}_DOWNLOAD_ATTACHMENTS`,
    payload: createPost(entityName, {
      action: `${formInstanceId}/downloadAttachments`,
      responseType: 'blob',
      acceptMimeType: 'application/zip',
    })(),
  }),
}
