import { connect } from 'react-redux'
import actions from 'actions/dashboardCharts'
import Component from 'components/DashboardViewer/Chart'

const mapDispatchToProps = {
  getChart: actions.getItem,
  updateChart: actions.updateItem,
  getChartData: actions.getChartData,
}

export default connect(null, mapDispatchToProps)(Component)
