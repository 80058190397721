import produce from 'immer'
import cx from 'clsx'
import { Tooltip, Button, Input } from 'antd'
import pluralize from 'pluralize'
import { createChildListViewComponent } from 'factories/ChildListView'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import { isReadOnly } from 'helpers/formViews'
import Icon from 'elements/Icon'
import InputNumber from 'elements/InputNumber'
import PrintLabel from 'containers/PrintLabel'

export default createChildListViewComponent({
  entityName: entityNames.lists,
  childName: 'listItems',
  primaryLinkTarget: 'listItemRecord',
  allowCreate: (self) => false,
  allowCustomize: (self) => true,
  allowSearching: (self) => true,
  tableCellComponents: {
    sequenceValue: (self, item) => {
      const original = self.props.originals.find((one) => one.id === item.id)

      return (
        <Input
          value={item.sequenceValue}
          onChange={async (e) => {
            try {
              await self.props.onChange(
                produce(self.props.items, (draft) => {
                  draft.find((one) => one.id === item.id).sequenceValue = e.target.value
                })
              )
            } catch (error) {
              console.warn(error)
            }
          }}
          readOnly={isReadOnly(self)}
          className={cx({ 'form-field-highlight': original?.sequenceValue !== item?.sequenceValue })}
        />
      )
    },
    quantity: (self, item) => {
      const original = self.props.originals.find((one) => one.id === item.id)

      return (
        <InputNumber
          value={item.quantity}
          onChange={async (value) => {
            try {
              await self.props.onChange(
                produce(self.props.items, (draft) => {
                  draft.find((one) => one.id === item.id).quantity = value
                })
              )
            } catch (error) {
              console.warn(error)
            }
          }}
          readOnly={isReadOnly(self)}
          className={cx({ 'form-field-highlight': original?.quantity !== item?.quantity })}
          min={0}
        />
      )
    },
  },
  extraTableButtons: (self) => {
    if (isReadOnly(self)) {
      return null
    }

    return (
      <>
        <Tooltip title={t('addFromFile')} placement="topRight">
          <Button
            size="small"
            onClick={() => self.props.onTableExtraButtonsClick({ key: 'addFromFile' })}
            disabled={isReadOnly(self)}
          >
            <Icon type="FileUpload" />
          </Button>
        </Tooltip>{' '}
        <Tooltip title={t('addFromList')} placement="topRight">
          <Button
            size="small"
            onClick={() => self.props.onTableExtraButtonsClick({ key: 'addFromList' })}
            disabled={isReadOnly(self)}
          >
            <Icon type="ContentPaste" />
          </Button>
        </Tooltip>{' '}
        <Tooltip title={t(pluralize(`addFrom${self.props.type}`))} placement="topRight">
          <Button
            type="primary"
            size="small"
            onClick={() =>
              self.props.onTableExtraButtonsClick({ key: pluralize(`addFrom${self.props.type}`) })
            }
            disabled={isReadOnly(self)}
          >
            <Icon type="Add" bold />
          </Button>
        </Tooltip>
      </>
    )
  },
  extraRowActions: (self) =>
    ['Asset', 'Product'].includes(self.props.type) ? (
      <PrintLabel
        type={self.props.type}
        itemIds={self.state.selectedRowKeys
          .map((each) => self.props.items.find((one) => one.id === each))
          .filter(Boolean)
          .map((each) => each?.objectId)
          .filter(Boolean)}
      />
    ) : null,
})()
