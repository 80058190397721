import { createListViewContainer } from 'factories/ListView'
import selectors from 'selectors'
import actions from 'actions/jobs'
import userActions from 'actions/users'
import locationActions from 'actions/locations'
import documentActions from 'actions/documents'
import Component from 'components/Jobs'

export default createListViewContainer({
  actions,
  selectors: selectors.jobs,
  extraDispatchToProps: (dispatch, props) => ({
    getUsers: (params) => dispatch(userActions.getItems({ ...params, active: 'All' })),
    getLocations: (params) =>
      dispatch(locationActions.getItems({ ...params, locationVendingTypes: ['NonVending'] })),
    getDocumentContents: (params) => dispatch(documentActions.getContents(params)),
  }),
})(Component)
