import { t } from 'helpers/i18n'

function Component(props) {
  return (
    <span style={{ color: !props.selectedRowKeys?.length ? 'rgba(0, 0, 0, .55)' : '#333' }}>
      {props.selectedRowKeys?.length ?? 0} {t('selected')}
    </span>
  )
}

export default Component
