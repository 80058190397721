import * as React from 'react'
import styled from 'styled-components'
import { TreeSelect } from 'antd'
import { Down } from 'elements/Icon'
import { filterTreeNode, getTreeData } from 'helpers/trees'

const Chevron = styled(Down)`
  position: absolute;
  font-size: 12px;
  color: #999;
  fill: #999;
  right: 12px;
  top: 12px;
`

function SelectProductCategories({ forwardedRef, value, dataSource = [], ...rest }) {
  return (
    <div style={{ position: 'relative' }}>
      <TreeSelect
        ref={forwardedRef}
        dropdownStyle={{ maxHeight: '240px', overflow: 'auto' }}
        showCheckedStrategy={TreeSelect.SHOW_ALL}
        value={value?.filter((v, i, a) => a.indexOf(v) === i)}
        filterTreeNode={filterTreeNode}
        maxTagCount={3}
        treeData={getTreeData(dataSource)}
        multiple
        treeDefaultExpandAll
        treeCheckable
        showSearch
        allowClear
        {...rest}
      />
      <Chevron />
    </div>
  )
}

export default React.forwardRef((props, ref) => <SelectProductCategories {...props} forwardedRef={ref} />)
