import { createListModalComponent } from 'factories/ListModal'
import { PRIVATE_DATABASE } from 'options/products'
import Filter from 'containers/Assets/Select/Filter'

export default createListModalComponent({
  getStorageKey: (self) => 'selectAsset',
  getSettingsType: (self) => 'selectAsset',
  initialFilterDto: (self) => ({
    assetStatus: 'All',
    catalogTableName: PRIVATE_DATABASE,
  }),
})(Filter)
