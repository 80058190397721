import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import userActions from 'actions/users'
import userActionsReportActions from 'actions/reports/userActionReport'
import Component from 'components/Reports/UserActionReport/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
})

const mapDispatchToProps = (dispatch, props) => ({
  getUsers: (params) => dispatch(userActions.getOptions({ ...params, active: 'All' })),
  getObjectTypes: (params) => dispatch(userActionsReportActions.getObjectTypes(params)),
  getActionTypes: (params) => dispatch(userActionsReportActions.getActionTypes(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
