export default {
  Location: 'location',
  JobGroup: 'jobGroup',
  OperatorName: 'assignedTo',
  AssetCategory: 'assetCategory',
  JobReason: 'jobReason',
  Priority: 'priority',
  JobStatusOption: 'status',
  ApprovalStatus: 'approvalStatus',
}
