import actions from 'actions/customers'
import userGroupActions from 'actions/userGroups'
import customerGroupActions from 'actions/customerGroups'
import { createFormViewContainer } from 'factories/FormView'
import authActions from 'actions/auth'
import { createChildItemsDispatchToProps, createDocumentsDispatchToProps } from 'helpers/formViews'
import documentActions from 'actions/documents'
import approvalTreeActions from 'actions/customers/approvalTree'
import Component from 'components/Customers/FormView'

export default createFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    newItem: (params) => dispatch(actions.newItem(params)),
    refreshToken: (params) => dispatch(authActions.refreshToken(params)),
    getUserGroups: (params) => dispatch(userGroupActions.getOptions(params)),
    getCustomerGroups: (params) => dispatch(customerGroupActions.getOptions(params)),
    ...createDocumentsDispatchToProps(dispatch, documentActions),
    ...createChildItemsDispatchToProps(dispatch, 'ApprovalTree', approvalTreeActions),
  }),
})(Component)
