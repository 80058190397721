import * as React from 'react'
import { isNil, cloneDeep, set, range, unset, get } from 'lodash'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import ticketStatus from 'options/tickets/statusOptions'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { getTenantDisplayName } from 'helpers/utils'
import DateRangePicker from 'elements/DateRangePicker'
import Select, { Option, getOptionProps } from 'elements/Select'
import { Row, Col } from 'elements/Grid'

const dateRangeFields = {
  Created: 'created',
  Updated: 'updated',
}

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      const { filterDto = {} } = this.props
      const responses = await Promise.all([
        this.props.getUsers({
          onlyUsersInRoleStaff: false,
          tenantId: filterDto.tenantId ? filterDto.tenantId : undefined,
        }),
        this.props.getUsers({
          onlyUsersInRoleStaff: true,
          includeUnassignedSelection: true,
        }),
        this.props.getTenants(),
        this.props.getTicketCategories(),
      ])

      this.setState({
        tenantUsers: get(responses[0], 'value.data.items', []),
        users: get(responses[1], 'value.data.items', []),
      })
    } catch (error) {
      showError({ error })
    }
  }

  changeTenantId = async (tenantId) => {
    const filterDto = cloneDeep(this.props.filterDto)

    unset(filterDto, 'createdBy')

    if (!isNil(tenantId)) {
      set(filterDto, 'tenantId', tenantId)
    } else {
      unset(filterDto, 'tenantId')
    }

    try {
      const response = await this.props.getUsers({
        onlyUsersInRoleStaff: false,
        tenantId,
      })

      this.setState({ tenantUsers: response.value.data.items })
    } catch (error) {
      showError({ error })
    }

    this.props.onChange({ ...filterDto })
  }

  render() {
    const { filterDto = {} } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker
              filterDto={filterDto}
              setFilterValue={this.setFilterValue}
              dateRangeFields={dateRangeFields}
              defaultDateRangeField="Created"
            />
          </Col>
          <Col fourth>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) => this.setFilterValue('status', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.status}
              >
                {Object.entries(ticketStatus).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('category')}>
              <Select
                onChange={(value) => this.setFilterValue('categoryIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.categoryIds}
              >
                {(this.props.ticketCategories ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            {this.props.userIsInRoleStaff && (
              <Form.Item label={t('tenant')}>
                <Select
                  onChange={(value) => this.changeTenantId(value)}
                  placeholder={t('all')}
                  value={filterDto.tenantId}
                >
                  {(this.props.tenants ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{getTenantDisplayName(each)}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item label={t('createdBy')}>
              <Select
                onChange={(value) => this.setFilterValue('createdBy', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.createdBy}
                showSearch
              >
                {(this.state.tenantUsers ?? []).map((each) => (
                  <Option key={each.userName} value={each.userName}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            {this.props.userIsInRoleStaff && (
              <Form.Item label={t('priority')}>
                <Select
                  onChange={(value) => this.setFilterValue('priority', value)}
                  value={filterDto.priority}
                  placeholder={t('all')}
                  allowClear
                >
                  {range(1, 5).map((each) => (
                    <Option key={each} value={each}>
                      {each}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {this.props.userIsInRoleStaff && (
              <Form.Item label={t('assignedTo')}>
                <Select
                  onChange={(value) => this.setFilterValue('assignedTo', value)}
                  mode="multiple"
                  placeholder={t('all')}
                  value={filterDto.assignedTo}
                  showSearch
                >
                  {(this.state.users ?? []).map((each) => (
                    <Option key={each.userName} value={each.userName}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
