import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import locationVendingTypes from 'options/locations/locationVendingTypes'
import cartTypes from 'options/inventory/cartTypes'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      await this.props.getLocationGroups({ includeAllLocationGroups: true })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto, tenantGroupIsCardinal } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('type')}>
              <Select
                onChange={(value) => this.setFilterValue('locationVendingTypes', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationVendingTypes}
              >
                {Object.entries(locationVendingTypes).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            {tenantGroupIsCardinal && (
              <Form.Item label={t('misc3')}>
                <Select
                  onChange={(value) => this.setFilterValue('cartType', value)}
                  placeholder={t('all')}
                  value={filterDto.cartType}
                >
                  {Object.entries(cartTypes).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {t(value)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
