import * as React from 'react'
import { connect } from 'react-redux'
import { Form, Radio } from 'antd'
import produce from 'immer'
import { set } from 'lodash'
import { showError } from 'helpers/errors'
import { createLabelFactory, isReadOnly } from 'helpers/formViews'
import chartWidthTypes from 'options/dashboards/chartWidthTypes'
import actions from 'actions/dashboardTemplates'
import { t } from 'helpers/i18n'

class Settings extends React.Component {
  state = {
    fieldSettings: [
      {
        dtoFieldName: 'widthType',
        recordLabelLanguageKey: 'width',
        recordInfoLanguageKey: 'chartWidthInfo',
      },
    ],
  }

  componentDidMount() {
    this.fetchSettings()
  }

  fetchSettings = async () => {
    try {
      const response = await this.props.getSettings({ type: 'chart' })

      this.setState({ fieldSettings: response.value.data.fieldSettings })
    } catch (error) {
      showError({ error })
    }
  }

  setItemValue = (name, value) =>
    this.props.onChange(
      produce(this.props.item, (draft) => {
        set(draft, name, value)
      })
    )

  render() {
    const { size = 'default' } = this.props
    const createLabel = createLabelFactory(this.state.fieldSettings)
    const readOnly = isReadOnly(this)

    return (
      <Form.Item label={createLabel('widthType')} colon={false}>
        <Radio.Group
          value={this.props.item.widthType}
          onChange={(e) => readOnly || this.setItemValue('widthType', e.target.value)}
          size={size}
        >
          {Object.entries(chartWidthTypes).map(([key, value]) => (
            <Radio.Button key={key} value={key}>
              {t(value)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    )
  }
}

export default connect(null, { getSettings: actions.getSettings })(Settings)
