import { useImmer } from 'use-immer'
import { Button, message } from 'antd'
import { isEmpty, invoke, set } from 'lodash'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import Icon from 'elements/Icon'
import Drawer from 'elements/Drawer'
import FormView from 'containers/PrintLabel/FormView'

export default function Component(props) {
  const { items = [], itemIds = [], type } = props

  const [state, updateState] = useImmer({
    visible: false,
    loading: false,
    loaded: [],
  })

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  async function handlePrintLabelClick() {
    if (props.parentHasUnsavedChanges) {
      message.error(t('saveChangesFirst'))
      return
    }

    if (isEmpty(itemIds)) {
      setState('visible', true)
    } else {
      try {
        setState('loading', true)

        console.assert(props[`get${type}Items`])

        const response = await invoke(props, `get${type}Items`, [...new Set(itemIds)])

        updateState((draft) => {
          draft.visible = true
          draft.loaded = response.value.data.items
        })
      } catch (error) {
        showError({ error })
      } finally {
        setState('loading', false)
      }
    }
  }

  function handlePrintLabelClose() {
    updateState((draft) => {
      draft.visible = false
      draft.loaded = []
    })
  }

  const buttonEnabled = !isEmpty(itemIds) || !isEmpty(items)

  return (
    <>
      <Button disabled={!buttonEnabled} onClick={handlePrintLabelClick} loading={state.loading}>
        {!state.loading && <Icon type="Subject" />} {t('printLabel')}
      </Button>
      <Drawer title={t('printLabel')} size="xs" visible={state.visible} onClose={handlePrintLabelClose}>
        <FormView
          type={type}
          items={isEmpty(itemIds) ? items : state.loaded}
          onCancel={handlePrintLabelClose}
        />
      </Drawer>
    </>
  )
}
