import * as React from 'react'
import { Form } from 'antd'
import { MONTHS } from 'options/tasks'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option } from 'elements/Select'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  render() {
    const { filterDto = {} } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <Form.Item label={t('year')}>
              <Select
                onChange={(value) => this.setFilterValue('year', value)}
                value={filterDto.year}
                placeholder={new Date().getFullYear()}
              >
                {[...Array(10).keys()].map((each) => {
                  const year = new Date().getFullYear() - each

                  return (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('month')}>
              <Select onChange={(value) => this.setFilterValue('month', value)} value={filterDto.month}>
                {Object.keys(MONTHS).map((each, index) => (
                  <Option key={each} value={index + 1}>
                    {t(each)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
