import moment from 'moment'
import entityNames from 'options/entityNames'
import { isValidDate, tryParseMoment } from 'helpers/dateTime'
import { createListViewComponent } from 'factories/ListView'
import Filter from 'containers/Reports/InventoryMonthlyChangeReport/Filter'

export default createListViewComponent({
  entityName: entityNames.inventoryMonthlyChangeReport,
  filterTemplateType: 'InventoryMonthlyChangeReport',
  initialFilterDto: (self) => ({ endDate: moment().subtract(1, 'months') }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  transformGetItemsParams: (self) => {
    const { endDate } = self.state.filterDto ?? {}

    return isValidDate(endDate)
      ? {
          endDate: undefined,
          endYear: tryParseMoment(endDate).year(),
          endMonth: tryParseMoment(endDate).month() + 1,
        }
      : {}
  },
})(Filter)
