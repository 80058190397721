import { Tooltip, Button } from 'antd'
import { t } from 'helpers/i18n'

function Component(props) {
  const {
    readOnly,
    onCancel,
    onSubmit,
    saveButtonLoading,
    saveAndCloseButtonLoading,
    cancelText = t('cancel'),
    saveText = t('save'),
    saveAndCloseText = t('saveAndClose'),
    saveAndCloseTooltip,
    disabled,
  } = props

  if (readOnly) {
    return <Button onClick={onCancel}>{t('close')}</Button>
  }

  return (
    <>
      <Tooltip title={t('unsavedChangesWillBeLost')}>
        <Button onClick={onCancel} disabled={disabled || saveButtonLoading || saveAndCloseButtonLoading}>
          {cancelText}
        </Button>
      </Tooltip>{' '}
      <Button
        onClick={(e) => onSubmit(e, { saveAndClose: false })}
        loading={saveButtonLoading}
        disabled={disabled || saveAndCloseButtonLoading}
      >
        {saveText}
      </Button>{' '}
      <Tooltip title={saveAndCloseTooltip} placement="topLeft">
        <Button
          type="primary"
          htmlType="submit"
          loading={saveAndCloseButtonLoading}
          disabled={disabled || saveButtonLoading}
        >
          {saveAndCloseText}
        </Button>
      </Tooltip>
    </>
  )
}

export default Component
