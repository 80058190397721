import { Tooltip } from 'antd'
import Icon from 'elements/Icon'

function Component(props) {
  const { icon = 'HelpOutline', title, placement } = props

  return (
    <Tooltip title={<span dangerouslySetInnerHTML={{ __html: title }} />} placement={placement}>
      <span>
        <Icon size={14} type={icon} />
      </span>
    </Tooltip>
  )
}

export default Component
