import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import linkTargets from 'options/linkTargets'
import { t } from 'helpers/i18n'
import Filter from 'containers/Reports/OverstockInventoryCycleCountDifferenceReport/Filter'

export default createListViewComponent({
  entityName: entityNames.overstockInventoryCycleCountDifferenceReport,
  filterTemplateType: 'OverstockInventoryCycleCountDifferenceReport',
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  tableCellComponents: {
    barcode: (self, item) => {
      const { barcode, overstockInventoryId } = item

      if (!overstockInventoryId) {
        return barcode
      }

      const editDrawerLinkTarget = linkTargets.overstockInventoryRecord

      return (
        <a
          onClick={() =>
            self.setState({
              editDrawerLinkTarget,
              editDrawerLinkTargetText: `${t('overstockInventory')} - ${barcode}`,
              editDrawerLinkTargetRecord: { overstockInventoryId },
              editDrawerVisible: true,
              editDrawerSaving: false,
            })
          }
        >
          {barcode}
        </a>
      )
    },
    countNotEqualOnHand: (self, item) => (item.countNotEqualOnHand ? t('yes') : t('no')),
    countLessThanOnHand: (self, item) => (item.countLessThanOnHand ? t('yes') : t('no')),
    countGreaterThanOnHand: (self, item) => (item.countGreaterThanOnHand ? t('yes') : t('no')),
  },
})(Filter)
