import produce from 'immer'
import { isEmpty, snakeCase } from 'lodash'
import { createActions, normalizeDateRange } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.cycleCounts
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    useSelectionList: true,
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.alwaysIncludeCycleCountIds =
          draft.alwaysIncludeCycleCountIds === false ? false : !isEmpty(draft.cycleCountIds)

        normalizeDateRange(draft)
      }),
  }),

  commitCycleCount: (params) => ({
    type: `${ENTITY_NAME}_COMMIT`,
    payload: createPost(entityName, { action: `${params.id}/commit` })(),
  }),

  getSplitDistributionText: (params) => ({
    type: `${ENTITY_NAME}_SPLIT_TEXT`,
    payload: createPost(entityName, { action: `${params.id}/getSplitDistributionText` })(params),
  }),

  splitCycleCount: (params) => ({
    type: `${ENTITY_NAME}_SPLIT_COUNT`,
    payload: createPost(entityName, { action: `${params.id}/splitCount` })(params),
  }),
}
