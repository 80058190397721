import produce from 'immer'
import { createActions, normalizeDateRange } from 'helpers/actions'
import entityNames from 'options/entityNames'

const entityName = entityNames.returnToInventory

export default createActions(entityName, {
  entityUrl: 'orders/orderItems',
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.ordersWithReturnableItemsOnly = draft.ordersWithReturnableItemsOnly ?? true

      normalizeDateRange(draft)
    }),
})
