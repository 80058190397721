import * as React from 'react'
import { Form } from 'antd'
import { get, isEmpty } from 'lodash'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option } from 'elements/Select'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      const { listType, entityType } = this.props
      const responses = await Promise.all([
        !isEmpty(entityType) ? this.props.getListTypes({ type: entityType }) : Promise.resolve(null),
        !isEmpty(listType) ? this.props.getEntityTypes({ type: listType }) : Promise.resolve(null),
      ])

      this.setState({
        listTypes: get(responses[0], 'value.data', []),
        entityTypes: get(responses[1], 'value.data', []),
      })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto = {} } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          {isEmpty(this.props.entityType) && (
            <Col third>
              <Form.Item label={t('recordType')}>
                <Select
                  onChange={(value) => this.setFilterValue('entityType', value)}
                  placeholder={t('all')}
                  value={filterDto.entityType}
                >
                  {(this.state.entityTypes ?? []).map((each) => (
                    <Option key={each.entityType} value={each.entityType}>
                      {each.displayName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
