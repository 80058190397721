import assetActions from 'actions/assets'
import customerDetailActions from 'actions/products/customerDetails'
import inventoryActions from 'actions/inventory'
import overstockInventoryActions from 'actions/overstockInventory'
import jobActions from 'actions/jobs'
import jobTemplateActions from 'actions/jobTemplates'
import operatorActions from 'actions/operators'
import productActions from 'actions/products'
import referencePriceActions from 'actions/products/productReferencePrices'
import productsInCategoryActions from 'actions/products/productsInCategories'
import supplierActions from 'actions/suppliers'
import supplierDetailActions from 'actions/products/supplierDetails'
import locationActions from 'actions/locations'
import overstockLocationActions from 'actions/overstockLocations'
import tagListActions from 'actions/tagLists'

export const createDownloadItemsDispatchToProps = (dispatch) => ({
  downloadInventoryItems: (params) => dispatch(inventoryActions.downloadItems(params)),
  downloadOverstockInventoryItems: (params) => dispatch(overstockInventoryActions.downloadItems(params)),
  downloadProductsItems: (params) => dispatch(productActions.downloadItems(params)),
  downloadSupplierItems: (params) => dispatch(supplierActions.downloadItems(params)),
  downloadProductsATItems: (params) => dispatch(assetActions.downloadItems(params)),
  downloadJobsItems: (params) => dispatch(jobActions.downloadItems(params)),
  downloadOperatorsItems: (params) => dispatch(operatorActions.downloadItems(params)),
  downloadJobsTemplateItems: (params) => dispatch(jobTemplateActions.downloadItems(params)),
  downloadCustomerPricesItems: (params) => dispatch(customerDetailActions.downloadItems(params)),
  downloadSupplierPricesItems: (params) => dispatch(supplierDetailActions.downloadItems(params)),
  downloadProductReferencePricesItems: (params) => dispatch(referencePriceActions.downloadItems(params)),
  downloadProductInCategoryItems: (params) => dispatch(productsInCategoryActions.downloadItems(params)),
  downloadCatalogProductItems: (params) => dispatch(productActions.downloadItems(params)),
  downloadLocationsItems: (params) => dispatch(locationActions.downloadItems(params)),
  downloadOverstockLocationsItems: (params) => dispatch(overstockLocationActions.downloadItems(params)),
  downloadTagListsItems: (params) => dispatch(tagListActions.downloadItems(params)),
})

export const getMimeType = (fileType) => (fileType === 'excel' ? 'application/vnd.ms-excel' : 'text/csv')

export const getFileExtension = (mimeType) => {
  switch (mimeType) {
    case 'application/pdf':
      return 'pdf'

    case 'application/vnd.ms-excel':
      return 'xlsx'

    case 'text/csv':
      return 'csv'

    case 'text/html':
      return 'html'

    default:
      return 'txt'
  }
}
