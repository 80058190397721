import produce from 'immer'
import { baseReportUrl } from 'config'
import { createActions, normalizeDateRange } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default createActions(entityNames.jobCompletionDetailReport, {
  basePath: 'report/jobReports',
  baseUrl: baseReportUrl,
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.locationVendingTypes = ['NonVending']
      draft.approvalStatus = draft.approvalStatus ?? 'All'

      normalizeDateRange(draft)
    }),
})
