import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/FileTemplates/Filter'

export default createListViewComponent({
  entityName: entityNames.fileTemplates,
  createButtonTextId: 'createFileTemplate',
  primaryLinkTarget: 'fileTemplateRecord',
  initialFilterDto: (self) => ({ objectType: 'All' }),
})(Filter)
