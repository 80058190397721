import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import taskTypes from 'options/tasks/taskTypes'
import Filter from 'containers/JobSchedule/Filter'

export default createListViewComponent({
  entityName: entityNames.tasks,
  filterTemplateType: 'ScheduledJob',
  initialFilterDto: (self) => ({ taskType: taskTypes.ScheduledJob, active: 'Active' }),
  allowCreate: (self) => false,
  getSettingsType: (self) => 'jobs',
})(Filter)
