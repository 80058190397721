import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import formGroupActions from 'actions/formGroups'
import formTemplateActions from 'actions/formTemplates'
import userGroupActions from 'actions/userGroups'
import userActions from 'actions/users'
import customerGroupActions from 'actions/customerGroups'
import customerActions from 'actions/customers'
import Component from 'components/FormInstances/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  formGroups: selectors.formGroups.selectOptions(state),
  formTemplates: selectors.formTemplates.selectOptions(state),
  userGroups: selectors.userGroups.selectOptions(state),
  users: selectors.users.selectOptions(state),
  customerGroups: selectors.customerGroups.selectOptions(state),
  customers: selectors.customers.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getFormGroups: (params) => dispatch(formGroupActions.getOptions(params)),
  getFormTemplates: (params) =>
    dispatch(formTemplateActions.getOptions({ versionType: 'NonLegacy', ...params })),
  getUserGroups: (params) => dispatch(userGroupActions.getOptions(params)),
  getUsers: (params) => dispatch(userActions.getOptions({ ...params, active: 'All' })),
  getCustomerGroups: (params) => dispatch(customerGroupActions.getOptions(params)),
  getCustomers: (params) => dispatch(customerActions.getOptions(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
