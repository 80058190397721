import { Modal as AntModal } from 'antd'
import cx from 'clsx'
import { t } from 'helpers/i18n'

function Modal(props) {
  const {
    formContainer,
    visible,
    children,
    width = 440,
    className,
    okText = t('ok'),
    cancelText = t('cancel'),
    ...rest
  } = props

  return (
    <AntModal
      className={cx('tofino-modal', className, {
        'tofino-modal--wide-modal': width > 768,
        'tofino-modal--form-container': formContainer,
      })}
      visible={visible}
      width={width}
      maskClosable={false}
      okText={okText}
      cancelText={cancelText}
      {...rest}
      destroyOnClose
    >
      {visible && <>{children}</>}
    </AntModal>
  )
}

Modal.info = AntModal.info
Modal.confirm = AntModal.confirm
Modal.destroyAll = AntModal.destroyAll

export default Modal
