import * as React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { useSelector } from 'react-redux'
import { createLocale } from 'helpers/i18n'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/es'
import createRoutes from 'routes'
import selectors from 'selectors'
import { getPossibleRoutes } from 'helpers/auth'
import ErrorBoundary from 'components/ErrorBoundary'

function Component(props) {
  const locale = useSelector(selectors.auth.locale)
  const menuItems = useSelector(selectors.auth.menuItems)
  const possibleRoutes = React.useMemo(() => getPossibleRoutes(menuItems), [menuItems])

  React.useEffect(() => {
    moment.locale(locale)
  }, [locale])

  return (
    <ErrorBoundary>
      <ConfigProvider locale={createLocale(locale)}>
        <Router>{createRoutes({ possibleRoutes })}</Router>
      </ConfigProvider>
    </ErrorBoundary>
  )
}

export default Component
