import { createListViewComponent } from 'factories/ListView'
import { allowPricing } from 'helpers/auth'
import { t, toLocaleCurrency } from 'helpers/i18n'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/UsageOrderReport/Filter'

export default createListViewComponent({
  entityName: entityNames.usageOrderReport,
  filterTemplateType: 'UsageOrderReport',
  allowCreate: (self) => false,
  allowDelete: (self) => false,
  allowSelection: (self) => false,
  initialFilterDto: (self) => ({
    dateRange: { dateRangeType: 'MonthToDate' },
    showOrdering: true,
  }),
  tableSummary: (self) =>
    allowPricing() ? `${t('totalValue:')} ${toLocaleCurrency(self.props.list?.total ?? 0)}` : '',
  getSettingsType: (self) => 'orderDetailsReport',
})(Filter)
