import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/FormDataReport/Filter'

export default createListViewComponent({
  entityName: entityNames.formDataReport,
  filterTemplateType: 'FormDataReport',
  initialFilterDto: (self) => ({
    dateRange: { dateRangeType: 'YearToDate' },
    objectType: 'All',
    dateRangeField: 'AssignedDateOrCompletedDate',
  }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  getSettingsType: (self) => self.state.filterDto?.formTemplateId,
  resetColumnsOnFieldSettingChanges: (self) => true,
})(Filter)
