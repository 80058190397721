import { partition, sum } from 'lodash'
import { createCalendarViewComponent } from 'factories/CalendarView'
import { t, toLocaleNumber, toLocaleCurrency } from 'helpers/i18n'
import { RED, DARK_GREEN } from 'options/colors'
import entityNames from 'options/entityNames'
import Filter from 'containers/Orders/CalendarView/Filter'

export default createCalendarViewComponent({
  entityName: entityNames.orders,
  filterTemplateType: 'OrderCalendar',
  createButtonTextId: 'createOrder',
  primaryLinkTarget: 'orderRecord',
  getSettingsType: (self) => 'OrderCalendarTiles',
  getSummarySettingsType: (self) => 'OrderCalendarSummary',
  getDateField: (self) => {
    switch (self.state.filterDto?.dateRangeField) {
      case 'SentDate':
        return 'confirmDate'

      case 'ApprovedDate':
        return 'approveDate'

      case 'ReceivedDate':
        return 'receivedDate'

      default:
        return 'createDate'
    }
  },
  getTileTitle: (self) => (item) => (item.isExpedited ? t('expedited') : ''),
  getSummaryTitle: (self) => (item) => t('orderSummary'),
  allowDelete: (self) => (item) => item.status === 'New',
  initialFilterDto: (self) => ({
    dateRangeField: 'CreatedDate',
  }),
  getTotalValues: (self) => {
    const totalableItems = self.getTotalableItems()
    const [expeditedOrders, standardOrders] = partition(totalableItems, (each) => each.isExpedited)
    const ordered = totalableItems.filter((each) => each.quantityOrdered > 0)
    const issued = totalableItems.filter((each) => each.quantityIssued > 0)
    const totalValue = totalableItems.filter((each) => each.orderTotal > 0)
    const totalLines = totalableItems.filter((each) => each.totalLines > 0)
    const totalOrderedLines = totalableItems.filter((each) => each.totalOrderedLines > 0)
    const nonStockLines = totalableItems.filter((each) => each.nonStockLines > 0)

    return [
      {
        key: 'standardOrders',
        title: t('standard'),
        value: toLocaleNumber(standardOrders.length),
        ids: standardOrders.map((each) => each.id),
      },
      {
        key: 'expeditedOrders',
        title: t('expedited'),
        value: toLocaleNumber(expeditedOrders.length),
        ids: expeditedOrders.map((each) => each.id),
      },
      {
        key: 'ordered',
        title: t('ordered'),
        value: toLocaleNumber(ordered.length),
        ids: ordered.map((each) => each.id),
      },
      {
        key: 'issued',
        title: t('issued'),
        value: toLocaleNumber(issued.length),
        ids: issued.map((each) => each.id),
      },
      {
        key: 'totalValue',
        title: t('totalValue'),
        value: toLocaleCurrency(sum(totalValue.map((each) => each.orderTotal))),
        ids: totalValue.map((each) => each.id),
      },
      {
        key: 'totalLines',
        title: t('totalLines'),
        value: toLocaleNumber(sum(totalLines.map((each) => each.totalLines))),
        ids: totalLines.map((each) => each.id),
      },
      {
        key: 'totalOrderedLines',
        title: t('totalOrderedLines'),
        value: toLocaleNumber(sum(totalOrderedLines.map((each) => each.totalLines))),
        ids: totalOrderedLines.map((each) => each.id),
      },
      {
        key: 'nonStockLines',
        title: t('nonStockLines'),
        value: toLocaleNumber(sum(nonStockLines.map((each) => each.nonStockLines))),
        ids: nonStockLines.map((each) => each.id),
      },
    ]
  },
  getCategorizationFields: (self) => [
    { dtoFieldName: 'itemLocationNames' },
    { dtoFieldName: 'userName' },
    { dtoFieldName: 'statusName' },
    { dtoFieldName: 'itemSupplierNames' },
    { dtoFieldName: 'orderTypeDescription' },
    {
      dtoFieldName: 'isConfirmedDescription',
      options: [
        { value: t('yes'), color: DARK_GREEN },
        { value: t('no'), color: RED },
      ],
    },
  ],
})(Filter)
