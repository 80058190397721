import { orderBy, invoke } from 'lodash'
import { createSorter, formatValue, MINIMUM_COLUMN_WIDTH } from 'helpers/listViews'
import { t } from 'helpers/i18n'

export const TRANSFER_COLUMNS_OPTIONS = [
  'showOnhandAndOnhand2Columns',
  'showOnhandColumnsOnly',
  'showOnhand2ColumnsOnly',
]

export const filterTransferTableColumns = (fieldSettings = [], columnsOption = 'showOnhandColumnsOnly') => {
  if (columnsOption === 'showOnhandColumnsOnly') {
    return fieldSettings.filter(
      (each) =>
        ![
          'fromOnHand2Quantity',
          'onHand2QuantityToMove',
          'transfer2Quantity',
          'deficiency2Quantity',
          'toOnHand2Quantity',
        ].includes(each.dtoFieldName)
    )
  }

  if (columnsOption === 'showOnhand2ColumnsOnly') {
    return fieldSettings.filter(
      (each) =>
        ![
          'fromOnHandQuantity',
          'onHandQuantityToMove',
          'transferQuantity',
          'deficiencyQuantity',
          'toOnHandQuantity',
        ].includes(each.dtoFieldName)
    )
  }

  return fieldSettings
}

export const filterResultsTableColumns = (fieldSettings = [], columnsOption = 'showOnhandColumnsOnly') => {
  if (columnsOption === 'showOnhandColumnsOnly') {
    return fieldSettings.filter(
      (each) =>
        !['movedOnHand2Quantity', 'fromOnHand2Quantity', 'toOnHand2Quantity'].includes(each.dtoFieldName)
    )
  }

  if (columnsOption === 'showOnhand2ColumnsOnly') {
    return fieldSettings.filter(
      (each) => !['movedOnHandQuantity', 'fromOnHandQuantity', 'toOnHandQuantity'].includes(each.dtoFieldName)
    )
  }

  return fieldSettings
}

export const getColumns = ({
  fieldSettings,
  onResize,
  columnWidths = {},
  sortBy,
  sortOrder,
  tableCellComponents = {},
}) => {
  const columns = orderBy(fieldSettings, ['defaultColumnNumber']).map((column) => {
    const {
      dtoFieldName,
      columnWidth,
      columnHeadingLanguageKey,
      alignment,
      displayFormat,
      recordLabelLanguageKey,
    } = column

    return {
      key: dtoFieldName,
      dataIndex: dtoFieldName,
      sorter: createSorter(dtoFieldName),
      sortOrder: dtoFieldName === sortBy ? sortOrder : null,
      width: columnWidths[dtoFieldName]
        ? Math.max(columnWidths[dtoFieldName], MINIMUM_COLUMN_WIDTH)
        : columnWidth
          ? Math.max(columnWidth, MINIMUM_COLUMN_WIDTH)
          : MINIMUM_COLUMN_WIDTH,
      title: t(columnHeadingLanguageKey || recordLabelLanguageKey || dtoFieldName),
      align: alignment || 'left',
      render: (value, record, index) => {
        const text = formatValue({ value, displayFormat })

        if (tableCellComponents[dtoFieldName]) {
          return invoke(tableCellComponents, dtoFieldName, record, column, text)
        }

        return text
      },
      onHeaderCell: (header) => ({
        width: header.width,
        onResize: onResize(header),
      }),
    }
  })

  return columns
}
