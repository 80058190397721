import { Popover } from 'antd'
import { isEmpty } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'
import Filter from 'containers/DashboardThemes/Filter'

export default createListViewComponent({
  entityName: entityNames.dashboardThemes,
  createButtonTextId: 'createDashboardTheme',
  primaryLinkTarget: 'dashboardThemeRecord',
  getDeleteButtonTooltip: (self) => t('deleteDashboardThemeTooltip'),
  tableCellComponents: {
    userCount: (self, item) => (
      <div>
        {item.userCount > 0 ? (
          <>
            <span className="mr-6">{item.userCount}</span>
            <Popover
              title={t('users')}
              content={<span dangerouslySetInnerHTML={{ __html: item.userCountInfo }} />}
            >
              <span
                className="align-middle"
                style={{ visibility: isEmpty(item.userCountInfo) ? 'hidden' : 'visible' }}
              >
                <Icon type="Search" />
              </span>
            </Popover>
          </>
        ) : (
          <span className="mr-24">{item.userCount}</span>
        )}
      </div>
    ),
  },
  initialFilterDto: (self) => ({ active: 'Active', originType: 'All' }),
  allowCreate: (self) => false,
  allowDelete: (self) => false,
  allowSelection: (self) => false,
})(Filter)
