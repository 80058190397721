import actions from 'actions/assets'
import { createFormViewContainer } from 'factories/FormView'
import { createChildItemsDispatchToProps, createDocumentsDispatchToProps } from 'helpers/formViews'
import documentActions from 'actions/documents'
import assetToleranceActions from 'actions/assets/assetTolerances'
import assetHistoryActions from 'actions/assets/assetHistory'
import jobActions from 'actions/jobs'
import operatorActions from 'actions/operators'
import tagListActions from 'actions/tagLists'
import Component from 'components/TrackAssets/FormView'
import locationActions from 'actions/locations'

export default createFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    updateItem: (params) => dispatch(actions.updateTracking(params)),
    getJob: (jobId) => dispatch(jobActions.getItem(jobId)),
    getOperator: (operatorId) => dispatch(operatorActions.getItem(operatorId)),
    getJobs: (params) =>
      dispatch(
        jobActions.getOptions({
          pageIndex: 1,
          pageSize: 100,
          ...params,
        })
      ),
    getOperators: (params) =>
      dispatch(
        operatorActions.getOptions({
          pageIndex: 1,
          pageSize: 100,
          ...params,
          active: 'Active',
        })
      ),
    createTolerancesHistoryItems: (parentId, items) =>
      dispatch(assetToleranceActions.createHistoryItems(parentId, items)),
    getTagListItems: (params) => dispatch(tagListActions.getOptions(params)),
    ...createDocumentsDispatchToProps(dispatch, documentActions),
    ...createChildItemsDispatchToProps(dispatch, 'Tolerance', assetToleranceActions),
    ...createChildItemsDispatchToProps(dispatch, 'History', assetHistoryActions),
    getLocations: (params) =>
      dispatch(locationActions.getItems({ ...params, locationVendingTypes: ['NonVending'] })),
  }),
})(Component)
