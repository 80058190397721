import * as React from 'react'
import styled from 'styled-components'
import { Empty, Radio } from 'antd'
import { isEmpty } from 'lodash'
import { t } from 'helpers/i18n'
import Checkbox, { CheckboxGroup } from 'elements/Checkbox'

const Container = styled.div`
  border: 1px solid #e8e8e8;
  max-height: 100%;

  .ant-checkbox-group {
    width: 100%;
    display: block;
  }

  .ant-checkbox-wrapper {
    padding: 6px 12px;
  }

  .ant-radio-wrapper {
    position: absolute;
    right: 12px;
    top: 6px;
  }
`

const SelectAll = styled.div`
  border-bottom: 1px solid #e8e8e8;
  background-color: rgb(250, 250, 250);

  .ant-checkbox-wrapper {
    font-weight: 500;
  }
`

const Items = styled.div`
  padding: 6px 0;
  min-height: 150px;
  overflow: auto;
`

function CheckboxList(props) {
  const {
    forwardedRef,
    dataSource = [],
    value = [],
    onChange = () => {},
    style,
    readOnly,
    showSelectAll = true,
    className,
    highlighted,
    onHighlight,
    disabled,
    idField = 'id',
    itemsStyle,
    ...rest
  } = props

  return (
    <Container className={className} style={style}>
      {showSelectAll && (
        <SelectAll>
          <Checkbox
            checked={!isEmpty(dataSource) && value?.length === dataSource?.length}
            indeterminate={value?.length > 0 && value?.length < dataSource?.length}
            onChange={() =>
              onChange(value?.length === dataSource?.length ? [] : dataSource.map((each) => each[idField]))
            }
            disabled={isEmpty(dataSource) || disabled || readOnly}
          >{`${value?.length} ${t('selected')}`}</Checkbox>
        </SelectAll>
      )}
      <Items style={itemsStyle}>
        {isEmpty(dataSource) ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <CheckboxGroup
            ref={forwardedRef}
            value={value}
            onChange={(values) =>
              onChange(dataSource.map((each) => each[idField]).filter((each) => values.includes(each)))
            }
            disabled={readOnly || disabled}
            {...rest}
          >
            {dataSource.map((each) => (
              <div key={each[idField]} className="relative">
                <Checkbox value={each[idField]} disabled={readOnly || disabled}>
                  {each.displayName || each.name || each.description}
                </Checkbox>
                {onHighlight && !readOnly && (
                  <Radio
                    checked={each[idField] === highlighted}
                    onClick={() => onHighlight(each[idField])}
                    disabled={readOnly || disabled}
                  />
                )}
              </div>
            ))}
          </CheckboxGroup>
        )}
      </Items>
    </Container>
  )
}

export default React.forwardRef((props, ref) => <CheckboxList {...props} forwardedRef={ref} />)
