import * as React from 'react'
import { Form, Input, Spin, Tabs } from 'antd'
import { createLabelFactory, createFieldFactory, createHandleSubmit, isReadOnly } from 'helpers/formViews'
import { showError, resourceNotFound, ValidationError } from 'helpers/errors'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import FormButtons from 'elements/FormButtons'
import Checkbox from 'elements/Checkbox'
import { Row, Col } from 'elements/Grid'
import Select, { Option } from 'elements/Select'

class FormView extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.handleSubmit = createHandleSubmit(this)
  }

  componentDidMount() {
    this.fetchItem()
  }

  saveItemSuccess = () => this.props.refreshToken(true)

  getItemId = () => [this.state.item?.id, this.props.linkTargetRecord?.id].find(Boolean)

  fetchItem = async ({ itemId = this.getItemId() } = {}) => {
    try {
      const responses = await Promise.all([
        itemId
          ? this.props.getItem(itemId).catch((error) => {
              this.props.onCancel?.()
              throw error
            })
          : !this.props.linkTargetRecord
            ? this.props.newItem({})
            : resourceNotFound(this),
        this.props.getSettings({ type: this.props.settingsType }),
        this.props.getSupplierIntegrations(),
      ])

      this.setState({
        item: responses[0].value.data,
        fieldSettings: responses[1].value.data.fieldSettings,
        supplierIntegrations: responses[2]?.value?.data?.items ?? [],
      })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { item, fieldSettings } = this.state

    if (!item || !fieldSettings) {
      return <Spin />
    }

    const createLabel = createLabelFactory(fieldSettings)
    const createFieldDecorator = createFieldFactory(this.props.form, item, fieldSettings)
    const readOnly = isReadOnly(this)

    return (
      <Form layout="vertical" colon={false} onSubmit={readOnly ? stopEvent : this.handleSubmit}>
        <div className="form-items-container">
          <ValidationError errors={this.state.validationErrors} />
          <Row>
            <Col half>
              <Form.Item label={createLabel('name')}>
                {createFieldDecorator('name')(<Input readOnly={readOnly} autoFocus />)}
              </Form.Item>
            </Col>
            <Col half>
              <Form.Item label={createLabel('contactFirstName')}>
                {createFieldDecorator('contactFirstName')(<Input readOnly={readOnly} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col half>
              <Form.Item label={createLabel('contactEmail')}>
                {createFieldDecorator('contactEmail')(<Input readOnly={readOnly} type="email" />)}
              </Form.Item>
            </Col>
            <Col half>
              <Form.Item label={createLabel('contactLastName')}>
                {createFieldDecorator('contactLastName')(<Input readOnly={readOnly} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col half>
              <Form.Item>
                {createFieldDecorator('sendAsEmail', { valuePropName: 'checked' })(
                  <Checkbox readOnly={readOnly}>{createLabel('sendAsEmail')}</Checkbox>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Tabs defaultActiveKey="address">
            <Tabs.TabPane key="address" tab={t('address')} forceRender>
              <Row>
                <Col half>
                  {[
                    'address.address1',
                    'address.city',
                    'address.state',
                    'address.country',
                    'address.zip',
                  ].map((each) => (
                    <Form.Item key={each} label={createLabel(each)}>
                      {createFieldDecorator(each)(<Input readOnly={readOnly} />)}
                    </Form.Item>
                  ))}
                </Col>
                <Col half>
                  {['address.phone', 'address.phone2'].map((each) => (
                    <Form.Item key={each} label={createLabel(each)}>
                      {createFieldDecorator(each)(<Input readOnly={readOnly} type="tel" />)}
                    </Form.Item>
                  ))}
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane key="notifications" tab={t('notifications')} forceRender>
              <Row>
                <Col half>
                  {['notifyEmail1', 'notifyEmail2', 'notifyEmail3', 'notifyEmail4', 'notifyEmail5'].map(
                    (each) => (
                      <Form.Item key={each} label={createLabel(each)}>
                        {createFieldDecorator(each)(<Input readOnly={readOnly} type="email" />)}
                      </Form.Item>
                    )
                  )}
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane key="integration" tab={t('integration')} forceRender>
              <Row>
                <Col half>
                  <Form.Item className="mb-12">
                    {createFieldDecorator('sendAsCxml', { valuePropName: 'checked' })(
                      <Checkbox readOnly={readOnly}>{createLabel('sendAsCxml')}</Checkbox>
                    )}
                  </Form.Item>
                  <Form.Item label={createLabel('cxmlRecipient')}>
                    {createFieldDecorator('cxmlRecipient')(
                      <Select
                        placeholder={t('none')}
                        disabled={!this.props.form.getFieldValue('sendAsCxml')}
                        readOnly={readOnly}
                        allowClear={false}
                      >
                        <Option value="none">{t('none')}</Option>
                        {(this.state.supplierIntegrations ?? [])
                          .filter((each) => each.supplierIntegrationType === 'XmlOrderRecipient')
                          .map((each) => (
                            <Option key={each.name} value={each.name}>
                              {each.name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                  {this.props.customer.generalSettings.enableExternalItemStatusRequest && (
                    <Form.Item label={createLabel('cxmlItemStatusRequestRecipient')}>
                      {createFieldDecorator('cxmlItemStatusRequestRecipient')(
                        <Select placeholder={t('none')} readOnly={readOnly}>
                          <Option value="">{t('none')}</Option>
                          {(this.state.supplierIntegrations ?? [])
                            .filter((each) => each.supplierIntegrationType === 'ItemStatusRequestRecipient')
                            .map((each) => (
                              <Option key={each.name} value={each.name}>
                                {each.name}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className="form-buttons-container">
          <FormButtons
            readOnly={readOnly}
            onCancel={this.props.onCancel}
            onSubmit={this.handleSubmit}
            saveButtonLoading={this.state.saveButtonLoading}
            saveAndCloseButtonLoading={this.state.saveAndCloseButtonLoading}
          />
        </div>
      </Form>
    )
  }
}

export default Form.create()(FormView)
