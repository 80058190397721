import * as React from 'react'
import { Spin, Form, Input } from 'antd'
import { useImmer } from 'use-immer'
import { get } from 'lodash'
import styled from 'styled-components'
import { showError } from 'helpers/errors'
import { createLabelFactory } from 'helpers/formViews'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { Row, Col } from 'elements/Grid'
import { formatDateTime } from 'helpers/dateTime'
import { Header, Title } from 'helpers/print'
import AssociatedAssets from 'containers/ProcessSetupSheet/Print/AssociatedAssets'
import AssociatedProducts from 'containers/ProcessSetupSheet/Print/AssociatedProducts'

const AssociatedProductsWrapper = styled.div`
  .ant-table-body {
    table {
      border-collapse: collapse;

      tr.tofino-associated-products-sequence-separator {
        border-bottom: solid #fafafa 55px;
      }
    }
  }
`

function Component(props) {
  const { assetId: itemId, tenant } = props

  const [state, updateState] = useImmer({})

  React.useEffect(() => {
    async function fetchSettings() {
      try {
        const responses = await Promise.all([
          props.getItem(itemId),
          props.getSettings({ type: props.settingsType }),
          props.getListItemsSettings({ type: 'assetListProcessSetupSheet' }),
          props.getListItemsSettings({ type: 'productListProcessSetupSheet' }),
          props.getCombinedListForEntity({ pageIndex: 0, listType: 'Asset', entityId: itemId }),
          props.getCombinedListForEntity({ pageIndex: 0, listType: 'Product', entityId: itemId }),
          props
            .getDocumentContents({
              request: {
                domainObjectId: tenant.id,
                domainObjectType: 'Customer',
                documentType: 'PurchaseOrderLogo',
                documentName: tenant.subdomain,
              },
            })
            .catch(() => {}),
        ])

        updateState((draft) => {
          draft.item = responses[0].value.data
          draft.fieldSettings = responses[1].value.data.fieldSettings
          draft.assetListSettings = responses[2].value.data.fieldSettings
          draft.productListSettings = responses[3].value.data.fieldSettings
          draft.assetList = responses[4].value.data
          draft.productList = responses[5].value.data
          draft.logo = get(responses[6], 'value.data', {})
        })
      } catch (error) {
        showError({ error })
      }
    }

    fetchSettings()
  }, [])

  const { fieldSettings, item } = state

  if (!item || !fieldSettings) {
    return <Spin />
  }

  const title = t('processSetupSheet')
  const createLabel = createLabelFactory(fieldSettings)

  // console.log({ props, state })

  return (
    <>
      <Title>
        <Col xs={16}>
          <h2>{title}</h2>
        </Col>
        <Col xs={8} className="text-right">
          {state.logo && state.logo.fileType && state.logo.contents && (
            <img src={`data:${state.logo.fileType};base64,${state.logo.contents}`} alt="" />
          )}
        </Col>
      </Title>
      <Header>
        <Form layout="vertical" colon={false} onSubmit={stopEvent}>
          <Row>
            <Col third>
              <Form.Item label={createLabel('barcode')}>
                <Input value={item.barcode} disabled />
              </Form.Item>
            </Col>
            <Col third>
              <Form.Item label={createLabel('itemNumber')}>
                <Input value={item.itemNumber} disabled />
              </Form.Item>
            </Col>
            <Col third>
              <Form.Item label={createLabel('locationName')}>
                <Input value={item.locationName} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col twoThirds>
              <Form.Item label={createLabel('description')}>
                <Input value={item.description} disabled />
              </Form.Item>
            </Col>
            <Col third>
              <Form.Item label={createLabel('revisionDate')}>
                <Input value={formatDateTime(item.revisionDate)} disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Header>
      <AssociatedAssets
        storageKey="processSetupSheet.formView.associatedAssets"
        title={t('associatedAssets')}
        fieldSettings={state.assetListSettings}
        items={state.assetList.items}
        parentRecord={{ ...item }}
      />
      <AssociatedProductsWrapper>
        <AssociatedProducts
          storageKey="processSetupSheet.formView.associatedProducts"
          title={t('associatedProducts')}
          fieldSettings={state.productListSettings}
          items={state.productList.items}
          totalValue={state.productList.totalValue}
          parentRecord={{ ...item }}
        />
      </AssociatedProductsWrapper>
    </>
  )
}

export default Component
