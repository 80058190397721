import { memoize, snakeCase } from 'lodash'
import { createActions } from 'helpers/actions'
import { createGet } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.filterTemplates
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const getFilterTemplateTypes = memoize(createGet(entityName, { action: 'filterTypes' }), JSON.stringify)

export default {
  ...createActions(entityName),

  getFilterTypes: () => ({
    type: `${ENTITY_NAME}_TYPES_GET`,
    payload: getFilterTemplateTypes(),
  }),
}
