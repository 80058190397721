import * as React from 'react'
import { Form, Button } from 'antd'
import { showError } from 'helpers/errors'
import { tryParseDate } from 'helpers/dateTime'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import usagePurchaseFilterOptions from 'options/orders/usagePurchaseFilterOptions'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DatePicker from 'elements/DatePicker'
import DateRangePicker from 'elements/DateRangePicker'
import SelectProductCategories from 'containers/ProductCategories/Select'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  state = {
    priceReferenceDate: tryParseDate(this.props.customer.generalSettings.priceReferenceDate),
  }

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  componentDidMount() {
    const { locationGroupIds, locationIds, productCategoryIds } = this.props.filterDto

    Promise.all([
      this.props.getLocationGroups(),
      this.props.getLocations({ locationGroupIds, locationIds }),
      this.props.getProductCategories({ productCategoryIds }),
    ]).catch((error) => showError({ error }))
  }

  handleUpdateReferenceDateClick = async () => {
    try {
      this.setState({ updateReferenceDateLoading: true })

      const priceReferenceDate = tryParseDate(this.state.priceReferenceDate)

      await this.props.updateReferenceDate({
        id: this.props.customer.id,
        priceReferenceDate,
      })

      await this.props.refreshToken(true)

      this.setFilterValue('priceReferenceDate', priceReferenceDate)
    } catch (error) {
      showError({ error })
    } finally {
      this.setState({ updateReferenceDateLoading: false })
    }
  }

  render() {
    const { filterDto = {} } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker filterDto={filterDto} setFilterValue={this.setFilterValue} />
          </Col>
          <Col fourth>
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('productCategory')}>
              <SelectProductCategories
                value={filterDto.productCategoryIds}
                placeholder={t('all')}
                onChange={(value) => this.setFilterValue('productCategoryIds', value)}
                dataSource={this.props.productCategories ?? []}
              />
            </Form.Item>
            <Form.Item label={t('usagePurchase')}>
              <Select
                onChange={(value) => this.setFilterValue('usagePurchase', value)}
                placeholder={t('usage')}
                value={filterDto.usagePurchase}
              >
                {Object.entries(usagePurchaseFilterOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('referenceDate')}>
              <Row>
                <Col xs={12}>
                  <DatePicker
                    className="w-full"
                    value={this.state.priceReferenceDate}
                    onChange={(value) => this.setState({ priceReferenceDate: value })}
                    allowClear={false}
                  />
                </Col>
                <Col xs={12}>
                  <Button
                    type="primary"
                    onClick={this.handleUpdateReferenceDateClick}
                    loading={this.state.updateReferenceDateLoading}
                    block
                  >
                    {t('update')}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
