import { createListViewComponent } from 'factories/ListView'
import { allowPricing } from 'helpers/auth'
import { t, toLocaleCurrency } from 'helpers/i18n'
import Filter from 'containers/Reports/UsageDetailsReport/Filter'

export default createListViewComponent({
  entityName: 'usageDetailsReport',
  filterTemplateType: 'UsageDetailsReport',
  allowCreate: (self) => false,
  allowDelete: (self) => false,
  allowSelection: (self) => false,
  initialFilterDto: (self) => ({
    dateRange: { dateRangeType: 'MonthToDate' },
    showOrdering: false,
  }),
  tableSummary: (self) =>
    allowPricing() ? `${t('totalValue:')} ${toLocaleCurrency(self.props.list?.total ?? 0)}` : '',
  getSettingsType: (self) => 'usageDetailsReport',
})(Filter)
