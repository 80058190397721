import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { getUserPermission } from 'helpers/auth'
import Filter from 'containers/Suppliers/Filter'

export default createListViewComponent({
  entityName: entityNames.suppliers,
  filterTemplateType: 'Supplier',
  createButtonTextId: 'createSupplier',
  primaryLinkTarget: 'supplierRecord',
  initialFilterDto: (self) => ({ active: 'Active' }),
  createDisabled: (self) =>
    getUserPermission('Suppliers') !== 'Edit' && getUserPermission('Master') !== 'Yes',
  allowDelete: (self) => false,
  allowDeactivate: (self) => true,
  getFileTemplateTypes: (self) => ['Supplier'],
})(Filter)
