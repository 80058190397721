import * as React from 'react'
import produce from 'immer'
import { useImmer } from 'use-immer'
import { Input, Spin } from 'antd'
import { set, isEmpty, isBoolean, toLower } from 'lodash'
import { tryParseInt } from 'helpers/utils'
import { showError } from 'helpers/errors'
import { t } from 'helpers/i18n'
import Checkbox from 'elements/Checkbox'
import Help from 'elements/Help'
import Icon from 'elements/Icon'
import InputNumber from 'elements/InputNumber'
import { Row, Col } from 'elements/Grid'
import { SettingsList, ListItem } from './Settings'

function Component(props) {
  const { values, onChange, readOnly, getSettings } = props

  const [state, updateState] = useImmer({})
  const { fieldSettings, filterBy = '' } = state

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  async function fetchSettings() {
    try {
      const response = await getSettings({ type: 'scannerSettings' })

      setState('fieldSettings', response.value.data.fieldSettings)
    } catch (error) {
      showError({ error })
    }
  }

  React.useEffect(() => {
    fetchSettings()
  }, [])

  function setValue(key, value) {
    onChange(
      produce(values, (draft) => {
        set(draft, key, value)
      })
    )
  }

  if (!fieldSettings) {
    return <Spin />
  }

  const dataSource = fieldSettings
    .filter((each) => each.displayByDefault) // NOTE: displayable !== displayByDefault
    .filter((each) => {
      if (isEmpty(filterBy)) {
        return true
      }

      const text = t(each.recordLabelLanguageKey)

      return toLower(text).indexOf(toLower(filterBy)) > -1
    })

  return (
    <>
      <Row className="mb-18">
        <Col third>
          <Input.Search
            placeholder={t('search')}
            defaultValue={filterBy}
            onChange={(e) => setState('filterBy', e.target.value)}
            maxLength={200}
            allowClear
          />
        </Col>
      </Row>
      <SettingsList
        header={t('mobileSettings')}
        dataSource={dataSource}
        renderItem={({ dtoFieldName, recordLabelLanguageKey, recordInfoLanguageKey }) => {
          switch (dtoFieldName) {
            case 'maxDataAgeHours':
              return (
                <ListItem>
                  <Row type="flex">
                    <Col className="pt-6">
                      <Icon type="Check" />
                    </Col>
                    <Col>
                      <span dangerouslySetInnerHTML={{ __html: t(recordLabelLanguageKey) }} />
                      <InputNumber
                        value={values[dtoFieldName]}
                        onChange={(e) => setValue(dtoFieldName, tryParseInt(e, 0))}
                        readOnly={readOnly}
                        style={{ width: '80px' }}
                        min={0}
                      />
                      {!isEmpty(recordInfoLanguageKey) && <Help title={t(recordInfoLanguageKey)} />}
                    </Col>
                  </Row>
                </ListItem>
              )

            default:
              return (
                <ListItem>
                  {isBoolean(values[dtoFieldName]) ? (
                    <Checkbox
                      checked={values[dtoFieldName]}
                      onChange={(e) => setValue(dtoFieldName, e.target.checked)}
                      readOnly={readOnly}
                    >
                      <span dangerouslySetInnerHTML={{ __html: t(recordLabelLanguageKey) }} />{' '}
                      {!isEmpty(recordInfoLanguageKey) && <Help title={t(recordInfoLanguageKey)} />}
                    </Checkbox>
                  ) : (
                    <span>
                      {t(recordLabelLanguageKey)}: {values[dtoFieldName]}{' '}
                      {!isEmpty(recordInfoLanguageKey) && <Help title={t(recordInfoLanguageKey)} />}
                    </span>
                  )}
                </ListItem>
              )
          }
        }}
        bordered
      />
    </>
  )
}

export default Component
