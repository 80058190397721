import * as React from 'react'
import { isNil, cloneDeep, set, get, unset } from 'lodash'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import completionStatusOptions from 'options/forms/completionStatusOptions'
import sourceOptions from 'options/forms/sourceOptions'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import { Row, Col } from 'elements/Grid'

const dateRangeFields = {
  All: 'all',
  DueDate: 'dueDate',
  AssignDate: 'assigned',
  CompletionDate: 'completionDate',
}

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      const { userGroupIds = [], formGroupId, customerGroupId, customerIds } = this.props.filterDto

      await Promise.all([
        this.props.getUserGroups(),
        this.props.getUsers({ userGroupIds }),
        this.props.getFormGroups(),
        this.props.getFormTemplates(formGroupId ? { formGroupId } : undefined),
        this.props.userIsDcribDistributor ? this.props.getCustomerGroups() : Promise.resolve(null),
        this.props.userIsDcribDistributor
          ? this.props.getCustomers({ groupId: customerGroupId, customerIds })
          : Promise.resolve(null),
      ])
    } catch (error) {
      showError({ error })
    }
  }

  setFormGroupId = async (formGroupId) => {
    const filterDto = cloneDeep(this.props.filterDto)

    if (!isNil(formGroupId)) {
      set(filterDto, 'formGroupId', formGroupId)
    } else {
      unset(filterDto, 'formGroupId')
    }

    unset(filterDto, 'formTemplateIds')

    try {
      const formTemplates = await this.props.getFormTemplates(formGroupId ? { formGroupId } : undefined)

      this.props.onChange({ ...filterDto })

      this.setState({ formTemplates: get(formTemplates, 'value.data.items', []) })
    } catch (error) {
      showError({ error })
    }
  }

  setCustomerGroupId = async (customerGroupId) => {
    const filterDto = cloneDeep(this.props.filterDto)

    if (!isNil(customerGroupId)) {
      set(filterDto, 'customerGroupId', customerGroupId)
    } else {
      unset(filterDto, 'customerGroupId')
    }

    unset(filterDto, 'customerIds')

    try {
      const customers = await this.props.getCustomers(
        customerGroupId ? { groupId: customerGroupId } : undefined
      )

      this.props.onChange({ ...filterDto })

      this.setState({ customers: get(customers, 'value.data.items', []) })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker
              filterDto={filterDto}
              setFilterValue={this.setFilterValue}
              dateRangeFields={dateRangeFields}
            />
          </Col>
          <Col fourth>
            <Form.Item label={t('formGroup')}>
              <Select
                onChange={(value) => this.setFormGroupId(value)}
                placeholder={t('all')}
                value={filterDto.formGroupId}
                allowClear
              >
                {(this.props.formGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('template')}>
              <Select
                onChange={(value) => this.setFilterValue('formTemplateIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.formTemplateIds}
                allowClear
              >
                {(this.props.formTemplates ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('userGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('userGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.userGroupIds}
                mode="multiple"
                allowClear
              >
                {(this.props.userGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.description}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('users')}>
              <Select
                onChange={(value) => this.setFilterValue('userNames', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.userNames}
                allowClear
              >
                {(this.props.users ?? []).map((each) => (
                  <Option key={each.userName} value={each.userName}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('source')}>
              <Select
                onChange={(value) => this.setFilterValue('objectType', value || 'All')}
                value={filterDto.objectType}
              >
                {Object.entries(sourceOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) => this.setFilterValue('completionStatus', value || 'All')}
                value={filterDto.completionStatus}
              >
                {Object.entries(completionStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {this.props.userIsDcribDistributor && (
          <Row>
            <Col fourth>
              <Form.Item label={t('customerGroup')}>
                <Select
                  onChange={(value) => this.setCustomerGroupId(value)}
                  placeholder={t('all')}
                  value={filterDto.customerGroupId}
                  allowClear
                >
                  {(this.props.customerGroups ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col fourth>
              <Form.Item label={t('customer')}>
                <Select
                  onChange={(values) => this.setFilterValue('customerIds', values)}
                  placeholder={t('all')}
                  value={filterDto.customerIds}
                  mode="multiple"
                  allowClear
                >
                  {(this.props.customers ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    )
  }
}

export default Form.create()(Filter)
