import { connect } from 'react-redux'
import listActions from 'actions/lists'
import entityListActions from 'actions/entityLists'
import Component from 'components/Lists/Associations/Filter'

const mapDispatchToProps = {
  getListTypes: listActions.getListTypes,
  getEntityTypes: entityListActions.getEntityTypes,
}

export default connect(null, mapDispatchToProps)(Component)
