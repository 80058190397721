import produce from 'immer'
import { snakeCase } from 'lodash'
import { createActions, normalizeDateRange } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.jobRequests
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        normalizeDateRange(draft)
      }),
  }),

  sendCreateNotification: (params = {}) => ({
    type: `${ENTITY_NAME}_SEND_CREATE_NOTIFICATION`,
    payload: createPost(entityName, { action: `${params.id}/sendCreateNotification` })(params),
  }),

  createJob: (params = {}) => ({
    type: `${ENTITY_NAME}_CREATE_JOB`,
    payload: createPost(entityName, { action: `${params.id}/createJob` })(params),
  }),

  decline: (params = {}) => ({
    type: `${ENTITY_NAME}_DECLINE`,
    payload: createPost(entityName, { action: `${params.id}/decline` })(params),
  }),
}
