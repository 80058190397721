import * as React from 'react'
import Chart from 'echarts-for-react/lib/core'
import {
  getCategories,
  getPrimarySeries,
  getLegend,
  getTitle,
  getSecondarySeries,
  getPrimaryAxisLabel,
  getSecondaryAxisLabel,
  getDataZoom,
  getGrid,
  getToolbox,
} from 'helpers/charts'
import { tryParseJSON } from 'helpers/utils'
import { DashboardContext } from 'helpers/dashboards'

function Component(props) {
  const { echarts, theme } = React.useContext(DashboardContext)
  const { chart, section, data, showToolbox } = props
  const { showGridlines, dimensionTitle, primaryMetricTitle, secondaryMetricTitle } = tryParseJSON(
    chart.chartJson,
    {}
  )
  const primarySeries = getPrimarySeries({ chart, data })
  const secondarySeries = getSecondarySeries({ chart, data })
  const { secondaryMetricType } = chart ?? {}
  const dataZoom = getDataZoom({ chart, data })

  const option = {
    toolbox: showToolbox ? getToolbox({ theme }) : undefined,
    tooltip: {},
    grid: getGrid({ chart, data }),
    dataZoom,
    title: getTitle({ chart }),
    legend: getLegend({ chart }),
    xAxis: {
      type: 'category',
      data: getCategories({ data }),
      name: dimensionTitle,
      splitLine: { show: showGridlines },
    },
    yAxis: [
      {
        type: 'value',
        name: primaryMetricTitle,
        splitLine: { show: showGridlines },
        axisLabel: getPrimaryAxisLabel({ chart }),
      },
      secondaryMetricType && secondaryMetricType !== 'None'
        ? {
            type: 'value',
            name: secondaryMetricTitle,
            axisLabel: getSecondaryAxisLabel({ chart }),
          }
        : null,
    ].filter(Boolean),
    series: [...primarySeries, secondarySeries].filter(Boolean),
  }

  return (
    <Chart
      echarts={echarts}
      theme={theme?.name}
      style={{ width: '100%', minHeight: `${section.height}px` }}
      option={option}
    />
  )
}

export default Component
