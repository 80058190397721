import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/StevensReport/Filter'

export default createListViewComponent({
  entityName: entityNames.stevensReport,
  filterTemplateType: 'StevensReport',
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  tableSummary: (self) =>
    `Number of customers with orders: ${self.props.list?.numberOfCustomersWithOrders ?? 0}`,
})(Filter)
