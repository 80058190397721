import * as React from 'react'
import { Spin, Form, Input } from 'antd'
import { isEmpty, get, isNil } from 'lodash'
import { useImmer } from 'use-immer'
import { showError } from 'helpers/errors'
import { createLabelFactory } from 'helpers/formViews'
import { stopEvent } from 'helpers/events'
import { formatUserTime } from 'helpers/dateTime'
import { Header, Title } from 'helpers/print'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { allowPricing } from 'helpers/auth'
import requisitionerFields from 'options/requisitionerFields'
import Select, { Option, getOptionProps } from 'elements/Select'
import DatePicker from 'elements/DatePicker'
import RequisitionItems from 'containers/Requisitions/Print/Items'
import { Row, Col } from 'elements/Grid'

function Component(props) {
  const { parentRecord: item, customer, tenant } = props

  const [state, updateState] = useImmer({})

  React.useEffect(() => {
    async function fetchSettings() {
      try {
        const responses = await Promise.all([
          props.getSettings({ type: 'requisition' }),
          props
            .getDocumentContents({
              request: {
                domainObjectId: tenant.id,
                domainObjectType: 'Customer',
                documentType: 'PurchaseOrderLogo',
                documentName: tenant.subdomain,
              },
            })
            .catch(() => {}),
          props.getAddressList({ type: 'shipto' }),
          props.getRequisitionSendToList({ requisitionId: item.id }),
          isNil(item.requisitionItems)
            ? props.getRequisitionItems(item.id, {
                includeItemStatus: true,
                includeIssueStatus: true,
              })
            : Promise.resolve(null),
        ])

        updateState((draft) => {
          draft.fieldSettings = responses[0].value.data.fieldSettings
          draft.logo = get(responses[1], 'value.data', {})
          draft.shipToAddressList = get(responses[2], 'value.data.items', [])
          draft.requisitionSendToList = get(responses[3], 'value.data.items', [])
          draft.requisitionItems = item.requisitionItems ?? get(responses[4], 'value.data.items', [])
        })
      } catch (error) {
        showError({ error })
      }
    }

    fetchSettings()
  }, [])

  const title = `${customer.displayName} - ${t('requisition')} ${item.id}`
  const createLabel = createLabelFactory(state.fieldSettings)

  return isEmpty(state.fieldSettings) ? (
    <Spin />
  ) : (
    <>
      <Title>
        <Col xs={16}>
          <h2>{title}</h2>
        </Col>
        <Col xs={8} className="text-right">
          {state.logo && state.logo.fileType && state.logo.contents && (
            <img src={`data:${state.logo.fileType};base64,${state.logo.contents}`} alt="" />
          )}
        </Col>
      </Title>
      <Header>
        <Form layout="vertical" colon={false} onSubmit={stopEvent}>
          <Row>
            <Col xs={8}>
              <Form.Item label={t('requisitionId')}>
                <Input value={item.id} readOnly />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={createLabel('createDate')}>
                <Input value={formatUserTime(item.createDate, item.userName) || t('na')} readOnly />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Form.Item label={createLabel('statusName')}>
                <Input value={item.statusName} readOnly />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Form.Item label={createLabel('requisitionNumber')}>
                <Input value={item.requisitionNumber} readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={16}>
              <Form.Item label={createLabel('shipToAddressId')}>
                <Select value={item.shipToAddressId} allowClear={false} readOnly>
                  {(state.shipToAddressList ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label={createLabel('approvedDate')}>
                <Input
                  value={item.approved ? formatUserTime(item.approvedDate, item.approveUserName) : null}
                  readOnly
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={16}>
              <Form.Item label={createLabel('description')}>
                <Input value={item.description} readOnly />
              </Form.Item>
              <Row>
                <Col xs={12}>
                  {requisitionerFields.map(({ key, type }) => (
                    <Form.Item key={key} label={createLabel(key)}>
                      <Input value={get(item, key)} type={type} readOnly />
                    </Form.Item>
                  ))}
                </Col>
                <Col xs={12}>
                  <Form.Item label={createLabel('deliveryDueDate')}>
                    <DatePicker value={item.deliveryDueDate} placeholder="" readOnly />
                  </Form.Item>
                  <Form.Item label={createLabel('sendTo')}>
                    <Select value={item.sendTo || ''} readOnly>
                      <Option value="">{t('none')}</Option>
                      {(state.requisitionSendToList ?? []).map((each) => (
                        <Option key={each.userName} value={each.userName} {...getOptionProps(each)}>
                          {each.displayName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {allowPricing() && (
              <Col xs={8}>
                {[
                  ...(customer.moduleSettings.enableTax ? ['exemptTotal', 'taxableTotal', 'taxTotal'] : []),
                  'requisitionTotal',
                ].map((each) => (
                  <Form.Item
                    key={each}
                    label={
                      each === 'taxTotal' ? (
                        <span>
                          {createLabel(each)}{' '}
                          {`(${item.taxRate.toLocaleString(props.locale, {
                            style: 'percent',
                            maximumFractionDigits: 2,
                          })})`}
                        </span>
                      ) : (
                        createLabel(each)
                      )
                    }
                  >
                    <Input className="text-right" value={toLocaleCurrency(item[each])} readOnly />
                  </Form.Item>
                ))}
              </Col>
            )}
          </Row>
        </Form>
      </Header>
      <RequisitionItems title={t('itemsTab')} settingsType="requisition" items={state.requisitionItems} />
      <h3>{t('comments')}</h3>
      <div
        dangerouslySetInnerHTML={{ __html: item.customerComment }}
        className="ant-input h-auto whitespace-pre-wrap mt-3 min-h-96"
      />
    </>
  )
}

export default Component
