import { snakeCase } from 'lodash'
import entityNames from 'options/entityNames'
import fieldSettings from 'options/customers/tagSettings'

const entityName = entityNames.customers
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const childName = 'tagSettings'
const CHILD_NAME = snakeCase(childName).toUpperCase()

export default {
  getSettings: () => ({
    type: `${ENTITY_NAME}_${CHILD_NAME}_SETTINGS_GET`,
    payload: Promise.resolve({ data: { fieldSettings } }),
  }),
}
