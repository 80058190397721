import * as React from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'
import { Form, Tooltip, Button, Card, Alert } from 'antd'
import { get, toString as str, set } from 'lodash'
import { getSectionProps, getChartWidth, DashboardContext } from 'helpers/dashboards'
import { t } from 'helpers/i18n'
import { tryParseJSON, strEqual } from 'helpers/utils'
import { isReadOnly } from 'helpers/formViews'
import { Emitter } from 'helpers/events'
import {
  DASHBOARD_FILTER_OPEN,
  DASHBOARD_FILTER_SAVING,
  DASHBOARD_CHART_FILTER_OPEN,
  DASHBOARD_FETCH_TEMPLATE,
  DASHBOARD_TEMPLATE_FETCHED,
  DASHBOARD_REFRESH_DATA,
} from 'options/events'
import { showError } from 'helpers/errors'
import FormView from 'containers/DashboardCharts/FormView'
import Modal from 'elements/Modal'
import Filter from 'containers/DashboardViewer/Filter'
import Chart from 'containers/DashboardViewer/Chart'
import Icon from 'elements/Icon'
import Drawer from 'elements/Drawer'
import { Row, Col } from 'elements/Grid'

const Container = styled.div`
  .ant-form-item {
    margin-bottom: 12px;
  }
`

const Section = styled(Card)`
  background: none;

  .ant-card-head {
    padding: 0 12px;
    border-bottom: none;

    .ant-card-head-title {
      padding: 12px 0;
    }

    .ant-card-extra {
      padding: 12px 0;

      .ant-btn-link {
        color: unset;
      }
    }
  }

  .ant-card-body {
    padding: 12px;
    padding-bottom: 0 !important;
  }

  &.ant-card-bordered + .ant-card-bordered {
    border-top: none !important;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`

const Actions = styled.div`
  text-align: right;
  white-space: nowrap;
  height: 16px;

  * {
    visibility: ${(props) => (props.focused ? 'visible' : 'hidden')};
  }

  .ant-btn-link {
    color: ${(props) => props.foregroundColor};
    margin-left: 3px;
  }
`

function Component(props) {
  const { foregroundColor } = React.useContext(DashboardContext)

  const { templateId, getTemplate, updateTemplate, active, user } = props

  const [state, updateState] = useImmer({})

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  const filterDto = tryParseJSON(state.template?.filterJson, {})

  function setFocusId(id) {
    setState('focusedId', id)
  }

  function refreshChart(id) {
    setState('refreshId', id)

    if (id) {
      window.setTimeout(() => refreshChart(null), 250)
    }
  }

  function editChart(id) {
    updateState((draft) => {
      draft.editChartVisible = true
      draft.editChartSaving = false
      draft.editChartRecord = { id }
    })
  }

  function handleFilterSettingsOpen(id) {
    if (str(id) === str(templateId)) {
      setState('filterSettingsVisible', true)
    }
  }

  async function handleFilterSettingsUpdate(values) {
    try {
      Emitter.emit(DASHBOARD_FILTER_SAVING, true)
      setState('filterSettingsSaving', true)

      await updateTemplate({ ...state.template, filterJson: JSON.stringify(values) })
      await fetchTemplate()

      setState('filterSettingsVisible', false)
    } catch (error) {
      showError({ error })
    } finally {
      setState('filterSettingsSaving', false)
      Emitter.emit(DASHBOARD_FILTER_SAVING, false)
    }
  }

  function handleFilterSettingsClose() {
    setState('filterSettingsVisible', false)
  }

  async function fetchTemplate() {
    try {
      const response = await getTemplate(templateId).then((r) => r.value.data)

      setState('template', response)

      Emitter.emit(DASHBOARD_TEMPLATE_FETCHED, response)
    } catch (error) {
      showError({ error })
    }
  }

  function handleEditChartSave(pending) {
    setState('editChartSaving', pending)

    if (!pending) {
      fetchTemplate()
      refreshChart(state.editChartRecord?.id)
    }
  }

  React.useEffect(() => {
    fetchTemplate()
  }, [templateId])

  React.useEffect(() => {
    Emitter.on(DASHBOARD_FETCH_TEMPLATE, fetchTemplate)

    return () => Emitter.off(DASHBOARD_FETCH_TEMPLATE, fetchTemplate)
  }, [])

  React.useEffect(() => {
    Emitter.on(DASHBOARD_FILTER_OPEN, handleFilterSettingsOpen)

    return () => Emitter.off(DASHBOARD_FILTER_OPEN, handleFilterSettingsOpen)
  }, [])

  const refreshInterval = React.useRef(null)

  React.useEffect(() => {
    window.clearInterval(refreshInterval.current)

    if (active) {
      refreshInterval.current = window.setInterval(
        () => {
          Emitter.emit(DASHBOARD_REFRESH_DATA)
        },
        get(state, 'template.dataRefreshMinutes', 60) * 60 * 1000
      )
    }

    return () => {
      window.clearInterval(refreshInterval.current)
    }
  }, [get(state, 'template.dataRefreshMinutes', 60), active])

  const readOnly = isReadOnly({ props }) || !strEqual(state.template?.createdBy, user.userName)
  const unauthorized = readOnly && state.template?.accessType === 'Private'

  if (unauthorized) {
    return (
      <Alert
        message={t('unauthorizedAccessTitle')}
        description={t('unauthorizedAccessDescription')}
        type="error"
        showIcon
      />
    )
  }

  return (
    <>
      <Container>
        {(state?.template?.sections ?? []).map((section) => (
          <Section key={section.id} {...getSectionProps(section)}>
            <Row style={{ minHeight: `${section.height + 33}px` }}>
              {(section?.charts ?? []).map((chart) => {
                const chartWidth = getChartWidth(chart.widthType)

                return (
                  <Col
                    key={chart.id}
                    xs={24}
                    sm={Math.min(chartWidth * 3, 24)}
                    md={[8, 16].includes(chartWidth) ? 12 : Math.min(chartWidth * 2, 24)}
                    lg={chartWidth}
                  >
                    <Form.Item
                      onClick={() => setFocusId(chart.id)}
                      onMouseEnter={() => setFocusId(chart.id)}
                      onMouseLeave={() => setFocusId(null)}
                      help={
                        <Actions focused={state.focusedId === chart.id} foregroundColor={foregroundColor}>
                          {!readOnly && (
                            <Tooltip title={t('editChart')}>
                              <Button type="link" size="small" onClick={() => editChart(chart.id)}>
                                <Icon type="Edit" />
                              </Button>
                            </Tooltip>
                          )}
                          <Tooltip title={t('refreshChart')}>
                            <Button type="link" size="small" onClick={() => refreshChart(chart.id)}>
                              <Icon type="Refresh" />
                            </Button>
                          </Tooltip>
                          {chart.chartType !== 'Scorecard' && (
                            <Tooltip title={t('zoom')}>
                              <Button
                                type="link"
                                size="small"
                                onClick={() =>
                                  updateState((draft) => {
                                    draft.zoomChartId = chart.id
                                    draft.zoomChartTitle = chart.name
                                    draft.zoomChartVisible = true
                                  })
                                }
                              >
                                <Icon type="ZoomIn" />
                              </Button>
                            </Tooltip>
                          )}
                          {chart.linkToDashboardFilter ? (
                            <Tooltip title={t('linkedToDashboardFilter')} placement="topLeft">
                              <Button
                                type="link"
                                size="small"
                                onClick={() => handleFilterSettingsOpen(templateId)}
                              >
                                <Icon type="Link" />
                              </Button>
                            </Tooltip>
                          ) : (
                            <Tooltip title={t('showChartFilter')} placement="topLeft">
                              <Button
                                type="link"
                                size="small"
                                onClick={() => Emitter.emit(DASHBOARD_CHART_FILTER_OPEN, chart.id)}
                              >
                                <Icon type="FilterList" />
                              </Button>
                            </Tooltip>
                          )}
                        </Actions>
                      }
                    >
                      {state.refreshId !== chart.id ? (
                        <Chart
                          chartId={chart.id}
                          section={section}
                          template={state.template}
                          filterDto={filterDto}
                          active={active}
                          readOnly={readOnly}
                        />
                      ) : null}
                    </Form.Item>
                  </Col>
                )
              })}
            </Row>
          </Section>
        ))}
      </Container>
      <Drawer
        title={t('dashboardFilterSettings')}
        visible={state.filterSettingsVisible}
        onClose={handleFilterSettingsClose}
        saving={state.filterSettingsSaving}
        size="sm"
      >
        <Filter
          template={state.template}
          filterDto={filterDto}
          onUpdate={handleFilterSettingsUpdate}
          onCancel={handleFilterSettingsClose}
          readOnly={readOnly}
        />
      </Drawer>
      <Drawer
        title={t('editChart')}
        visible={state.editChartVisible}
        saving={state.editChartSaving}
        onClose={() => setState('editChartVisible', false)}
        size="md"
      >
        <FormView
          linkTargetRecord={state.editChartRecord}
          onSave={handleEditChartSave}
          onSaveAndClose={() => setState('editChartVisible', false)}
          onCancel={() => setState('editChartVisible', false)}
          readOnly={readOnly}
        />
      </Drawer>
      <Modal
        visible={state.zoomChartVisible}
        title={state.zoomChartTitle}
        footer={null}
        onCancel={() => setState('zoomChartVisible', false)}
        width={1200}
      >
        <Chart
          chartId={state.zoomChartId}
          section={{ height: 480 }}
          template={state.template}
          filterDto={filterDto}
          active={active}
          readOnly={readOnly}
          showToolbox
        />
      </Modal>
    </>
  )
}

export default Component
