import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/InventoryLocationReport/Filter'

export default createListViewComponent({
  entityName: entityNames.inventoryLocationReport,
  filterTemplateType: 'InventoryLocationReport',
  allowCreate: (self) => false,
  allowSelection: (self) => false,
})(Filter)
