import styled from 'styled-components'
import { Layout, Button } from 'antd'

export const Announcement = styled.div`
  .ant-alert {
    text-align: center;

    .ant-alert-message,
    .ant-alert-close-icon .anticon-close {
      color: white;
    }
  }

  .ant-alert-info {
    background-color: #1890ff;
  }

  .ant-alert-error {
    background-color: #ff4d4f;
  }

  .ant-alert-success {
    background-color: #52c41a;
  }

  .ant-alert-warning {
    background-color: #fffb8f;

    .ant-alert-message,
    .ant-alert-close-icon .anticon-close {
      color: #333;
    }
  }
`

export const LinkButton = styled(Button)`
  color: #bbb;
  padding: 0;
`

export const LoginBack = styled(Button)`
  color: #1890ff !important;
  padding: 0;
`

export const Trigger = styled.a`
  display: block;
  width: 64px;
  height: 64px;
  overflow: hidden;
`

export const Header = styled(Layout.Header)`
  display: flex;
  height: 64px;
  user-select: none;
  padding: 0;
`

export const TitleContainer = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const Logo = styled.div`
  text-align: center;
  padding: 0 12px 0 24px;

  img {
    max-height: 46px;
  }
`

export const Title = styled.h1`
  font-size: 22px;
  line-height: 64px;
  height: 64px;
  flex: 1 1 0%;
  margin: 0;
  margin-left: 24px;
  color: #bbb;
  overflow: hidden;
  text-overflow: ellipsis;
  display: ${(props) => (props.siderCollapsed ? 'none' : 'block')};
`

export const Navbar = styled.div`
  flex-grow: 1;
  padding-top: 10px;

  .ant-menu-horizontal {
    white-space: normal;
  }

  @media screen and (max-width: 768px) {
    .ant-menu {
      display: none;
    }
  }
`

export const UserMenu = styled.div`
  color: #bbb;
  margin-left: 24px;

  .anticon {
    margin-right: 2px;
    vertical-align: text-bottom;
  }

  a,
  button,
  .ant-btn-link[disabled] {
    color: #bbb;

    .anticon {
      vertical-align: middle;
    }
  }

  a:hover,
  a:active {
    color: white;
  }

  .divider {
    margin: 0 10px;
  }

  @media screen and (max-width: 992px) and (min-height: 720px) {
    display: none;
  }
`

export const SideMenuContainer = styled.div`
  user-select: none;
  height: calc(100vh - 130px);
  overflow-y: auto !important;

  @media screen and (max-width: 768px) {
    height: calc(100vh - 65px);
  }
`
