import { connect } from 'react-redux'
import selectors from 'selectors'
import locationGroupActions from 'actions/locationGroups'
import locationActions from 'actions/locations'
import Component from 'components/DashboardViewer/Filter/Locations'

const mapStateToProps = (state, props) => ({
  locationGroups: selectors.locationGroups.selectOptions(state),
  locations: selectors.locations.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getLocationGroups: (params) => dispatch(locationGroupActions.getOptions(params)),
  getLocations: (params) =>
    dispatch(locationActions.getOptions({ ...params, active: 'All', locationVendingTypes: ['NonVending'] })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
