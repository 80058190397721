import { Button, Popover } from 'antd'
import Icon from 'elements/Icon'
import CirclePicker from 'react-color/lib/Circle'

const colors = [
  '#330000',
  '#331900',
  '#333300',
  '#193300',
  '#003300',
  '#003319',
  '#003333',
  '#001933',
  '#000033',
  '#190033',
  '#330033',
  '#330019',
  '#333333',
  '#660000',
  '#663300',
  '#666600',
  '#336600',
  '#006600',
  '#006633',
  '#006666',
  '#003366',
  '#000066',
  '#330066',
  '#660066',
  '#660033',
  '#202020',
  '#990000',
  '#994C00',
  '#999900',
  '#4C9900',
  '#009900',
  '#00994C',
  '#009999',
  '#004C99',
  '#000099',
  '#4C0099',
  '#990099',
  '#99004C',
  '#404040',
  '#CC0000',
  '#CC6600',
  '#CCCC00',
  '#66CC00',
  '#00CC00',
  '#00CC66',
  '#00CCCC',
  '#0066CC',
  '#0000CC',
  '#6600CC',
  '#CC00CC',
  '#CC0066',
  '#606060',
  '#FF0000',
  '#FF8000',
  '#FFFF00',
  '#80FF00',
  '#00FF00',
  '#00FF80',
  '#00FFFF',
  '#0080FF',
  '#0000FF',
  '#7F00FF',
  '#FF00FF',
  '#FF007F',
  '#808080',
  '#FF3333',
  '#FF9933',
  '#FFFF33',
  '#99FF33',
  '#33FF33',
  '#33FF99',
  '#33FFFF',
  '#3399FF',
  '#3333FF',
  '#9933FF',
  '#FF33FF',
  '#FF3399',
  '#A0A0A0',
  '#FF6666',
  '#FFB266',
  '#FFFF66',
  '#B2FF66',
  '#66FF66',
  '#66FFB2',
  '#66FFFF',
  '#66B2FF',
  '#6666FF',
  '#B266FF',
  '#FF66FF',
  '#FF66B2',
  '#C0C0C0',
  '#FF9999',
  '#FFCC99',
  '#FFFF99',
  '#CCFF99',
  '#99FF99',
  '#99FFCC',
  '#99FFFF',
  '#99CCFF',
  '#9999FF',
  '#CC99FF',
  '#FF99FF',
  '#FF99CC',
  '#E0E0E0',
  '#FFCCCC',
  '#FFE5CC',
  '#FFFFCC',
  '#E5FFCC',
  '#CCFFCC',
  '#CCFFE5',
  '#CCFFFF',
  '#CCE5FF',
  '#CCCCFF',
  '#E5CCFF',
  '#FFCCFF',
  '#FFCCE5',
  '#FFFFFF',
]

function Component(props) {
  const { value = '#FFFFFF', onChange = () => {}, foreground, readOnly, style, ...rest } = props

  function handleChange(input) {
    if (readOnly) {
      return
    }

    onChange(input.hex)
  }

  return (
    <Popover
      content={
        <CirclePicker
          width={290}
          circleSize={20}
          circleSpacing={2}
          color={value}
          onChange={handleChange}
          colors={colors}
        />
      }
      placement="bottom"
      trigger={['click']}
    >
      <Button style={{ padding: '0 5px 0 10px', ...style }} {...rest}>
        <span
          style={{
            display: 'inline-block',
            width: foreground ? '20px' : '16px',
            height: foreground ? '20px' : '16px',
            color: foreground ? '#666' : value,
            backgroundColor: foreground ? 'auto' : value,
            outline: foreground ? 'none' : '1px solid #d9d9d9',
            textAlign: 'center',
            marginTop: foreground ? '0' : '4px',
          }}
        >
          <span
            style={{
              fontSize: foreground ? '14px' : '8px',
              borderBottom: foreground ? `3px solid ${value}` : 'none',
              fontWeight: 700,
            }}
          >
            &nbsp;A&nbsp;
          </span>
        </span>{' '}
        <Icon type="KeyboardArrowDown" />
      </Button>
    </Popover>
  )
}

export default Component
