export default {
  Barcode: 'barcode',
  OrderId: 'orderID',
  Location: 'location',
  User: 'user',
  Supplier: 'supplier',
  Job: 'job',
  Operator: 'operator',
  TagA: 'tagA',
  TagB: 'tagB',
  TagC: 'tagC',
  TagD: 'tagD',
  TagE: 'tagE',
  AssetBarcode: 'asset',
}
