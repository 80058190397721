import { isNil, isEmpty, orderBy, get, camelCase } from 'lodash'
import Label from './Label'

function Component(props) {
  const { items, labelTemplate, numberOfCopies = 1, labelTemplateFields = [] } = props

  if (isNil(labelTemplate) || isEmpty(labelTemplateFields) || isEmpty(items)) {
    return null
  }

  let unsortedItems = [...items]

  if (labelTemplate.sortBy !== 'None') {
    const labelTemplateField = labelTemplateFields.find((one) => one.fieldType === labelTemplate.sortBy)
    const dtoFieldName = get(labelTemplateField, 'dtoFieldName', labelTemplate.sortBy)
      .split('.')
      .map(camelCase)
      .join('.')

    unsortedItems = orderBy(
      unsortedItems,
      [dtoFieldName],
      [labelTemplate.sortOrder === 'DESC' ? 'desc' : 'asc']
    )
  }

  if (numberOfCopies > 1) {
    const copies = [...Array(numberOfCopies).keys()]

    if (labelTemplate.groupDuplicates) {
      unsortedItems = unsortedItems.flatMap((item) => copies.map((copy) => item))
    } else {
      unsortedItems = copies.flatMap((copy) => unsortedItems)
    }
  }

  const pages = []
  const mediaTypeIsSheet = labelTemplate.mediaType === 'Sheet'

  if (mediaTypeIsSheet) {
    while (unsortedItems.length) {
      pages.push(unsortedItems.splice(0, labelTemplate.horizontalCount * labelTemplate.verticalCount))
    }
  } else {
    pages.push(unsortedItems)
  }

  const pageWidth = `${(labelTemplate.width + labelTemplate.horizontalGap) * labelTemplate.horizontalCount}${
    labelTemplate.unitType === 'Centimeters' ? 'cm' : 'in'
  }`

  return pages.map((page, pageIndex) => (
    <div
      key={`page-${pageIndex}`}
      style={{
        pageBreakAfter: mediaTypeIsSheet ? 'always' : 'auto',
        display: mediaTypeIsSheet ? 'flex' : 'block',
        flexWrap: mediaTypeIsSheet ? 'wrap' : 'auto',
        width: mediaTypeIsSheet ? pageWidth : 'auto',
      }}
    >
      {page.map((item, labelIndex) => (
        <Label
          key={`${item.id}-${pageIndex}-${labelIndex}`}
          template={labelTemplate}
          item={item}
          labelTemplateFields={labelTemplateFields}
        />
      ))}
    </div>
  ))
}

export default Component
