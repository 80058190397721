import { connect } from 'react-redux'
import { createAuthStateToProps, getUserPermission } from 'helpers/auth'
import selectors from 'selectors'
import listActions from 'actions/lists'
import locationGroupActions from 'actions/locationGroups'
import locationActions from 'actions/locations'
import supplierActions from 'actions/suppliers'
import productCategoryActions from 'actions/productCategories'
import Component from 'components/Inventory/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  lists: selectors.lists.selectOptions(state),
  locationGroups: selectors.locationGroups.selectOptions(state),
  locations: selectors.locations.selectOptions(state),
  suppliers: selectors.suppliers.selectOptions(state, { itemsOnly: false }),
  productCategories: selectors.productCategories.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getLists: (params) => dispatch(listActions.getOptions(params)),
  getLocationGroups: (params) => dispatch(locationGroupActions.getOptions(params)),
  getLocations: (params) =>
    dispatch(
      locationActions.getOptions({
        active: 'All',
        includeAllLocations: getUserPermission('ViewInventoryAllLocations') === 'Yes',
        ...params,
      })
    ),
  getSuppliers: (params) =>
    dispatch(
      supplierActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
        active: 'All',
      })
    ),
  getProductCategories: (params) =>
    dispatch(productCategoryActions.getOptions({ ...params, active: 'All', pageIndex: 0 })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
