import produce from 'immer'
import { snakeCase } from 'lodash'
import { createActions } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.users
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    idField: 'userName',
    memoizeGetOptions: true,
    useSelectionList: true,
    transformGetOptionsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.active = draft.active ?? 'Active'
      }),
  }),

  resetPassword: (params) => ({
    type: `${ENTITY_NAME}_RESET_PASSWORD`,
    payload: createPost(entityName, { action: 'resetPassword' })(params),
  }),

  unlock: (params = {}) => ({
    type: `${ENTITY_NAME}_UNLOCK`,
    payload: createPost(entityName, { action: `unlock/${encodeURIComponent(params.userName)}` })(),
  }),

  getRequisitionSendToList: (params) => ({
    type: `${ENTITY_NAME}_REQUISITION_SEND_TO_LIST`,
    payload: createPost(entityName, { action: 'requisitionSendToList' })(params),
  }),
}
