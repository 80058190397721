import * as React from 'react'
import { Form } from 'antd'
import { isNil, cloneDeep, set, unset } from 'lodash'
import orderStatusOptions from 'options/orders/statusOptions'
import orderTypeOptions from 'options/orders/orderTypes'
import confirmationStatusOptions from 'options/orders/confirmationStatusOptions'
import { showError } from 'helpers/errors'
import { createDropdownRender, createSearchSuppliers } from 'helpers/formViews'
import { HARD_SPACE, getTenantDisplayName } from 'helpers/utils'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import Checkbox from 'elements/Checkbox'
import Help from 'elements/Help'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchSuppliers = createSearchSuppliers(this)
  }

  componentDidMount() {
    const { locationGroupIds, locationIds, supplierIds, tenantId, excludeExternal } = this.props.filterDto

    Promise.all([
      this.props.getSuppliers(supplierIds ? { supplierIds } : undefined),
      this.props.getLocationGroups(),
      this.props.getLocations({
        locationGroupIds,
        locationIds,
        locationVendingTypes: excludeExternal ? ['NonVending'] : ['Vending', 'NonVending'],
      }),
      this.props.getUsers(this.props.userIsInRoleSupplier ? { tenantId } : undefined),
      this.props.getTenants({
        tenantType: 'Trms',
        supplierLinkTenantsOnly: true,
      }),
      this.props.userIsDcribDistributor ? this.props.getCustomers() : Promise.resolve(null),
    ]).catch((error) => showError({ error }))
  }

  changeTenantId = async (tenantId) => {
    const filterDto = cloneDeep(this.props.filterDto)

    if (!isNil(tenantId)) {
      set(filterDto, 'tenantId', tenantId)
    } else {
      unset(filterDto, 'tenantId')
    }

    unset(filterDto, 'createdByUserNames')

    try {
      await this.props.getUsers(this.props.userIsInRoleSupplier ? { tenantId } : undefined)
      await this.props.onChange({ ...filterDto })
    } catch (error) {
      showError({ error })
    }
  }

  changeCustomerId = async (customerId) => {
    const filterDto = cloneDeep(this.props.filterDto)

    if (!isNil(customerId)) {
      set(filterDto, 'customerId', customerId)
    } else {
      unset(filterDto, 'customerId')
    }

    unset(filterDto, 'createdByUserNames')

    try {
      await this.props.getUsers({ customerId })
      await this.props.onChange({ ...filterDto })
    } catch (error) {
      showError({ error })
    }
  }

  changeExcludeExternal = async (excludeExternal) => {
    const filterDto = cloneDeep(this.props.filterDto)

    set(filterDto, 'excludeExternal', excludeExternal)

    if (excludeExternal) {
      set(filterDto, 'locationGroupIds', [])
      set(filterDto, 'locationIds', [])
    }

    try {
      const { locationGroupIds, locationIds } = filterDto

      await this.props.getLocations({
        locationGroupIds,
        locationIds,
        locationVendingTypes: excludeExternal ? ['NonVending'] : ['Vending', 'NonVending'],
      })
      await this.props.onChange({ ...filterDto })
    } catch (error) {
      showError({ error })
    }
  }

  changeConfirmationStatusFilter = (confirmationStatusFilter) => {
    const filterDto = cloneDeep(this.props.filterDto)

    set(filterDto, 'confirmationStatusFilter', confirmationStatusFilter)
    set(filterDto, 'includeUnconfirmedOnly', confirmationStatusFilter === 'Unconfirmed')

    this.props.onChange({ ...filterDto })
  }

  render() {
    const {
      filterDto,
      suppliers,
      userIsInRoleSupplier,
      userIsInRoleDistributor,
      userIsDcribCustomer,
      tenantTypeIsTrms,
    } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <DateRangePicker filterDto={filterDto} setFilterValue={this.setFilterValue} />
            {!userIsInRoleDistributor && !userIsInRoleSupplier && (
              <Form.Item label={t('locationGroup')}>
                <Select
                  onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                  placeholder={t('all')}
                  value={filterDto.locationGroupIds}
                  mode="multiple"
                >
                  {(this.props.locationGroups ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {!userIsInRoleDistributor && !userIsInRoleSupplier && (
              <Form.Item label={t('location')}>
                <Select
                  onChange={(value) => this.setFilterValue('locationIds', value)}
                  mode="multiple"
                  placeholder={t('all')}
                  value={filterDto.locationIds}
                >
                  {(this.props.locations ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col third>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) => this.setFilterValue('statusFilter', value || 'All')}
                value={filterDto.statusFilter || 'All'}
                placeholder={t('all')}
              >
                {Object.entries(orderStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('createdBy')}>
              <Select
                onChange={(value) => this.setFilterValue('createdByUserNames', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.createdByUserNames}
                showSearch
              >
                {(this.props.users ?? []).map((each) => (
                  <Option key={each.userName} value={each.userName}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {tenantTypeIsTrms && (
              <Form.Item label={t('supplier')}>
                <Select
                  onChange={(value) => this.setFilterValue('supplierIds', value)}
                  mode="multiple"
                  placeholder={t('all')}
                  value={filterDto.supplierIds}
                  dropdownRender={createDropdownRender(suppliers)}
                  onSearch={this.searchSuppliers}
                  showSearch
                >
                  {(suppliers?.items ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {!userIsInRoleSupplier && (
              <Form.Item label={t('usagePurchase')}>
                <Select
                  onChange={(value) => this.setFilterValue('issueOrderFilter', value)}
                  value={filterDto.issueOrderFilter}
                  placeholder={t('all')}
                >
                  {Object.entries(orderTypeOptions).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {t(value)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col third>
            {userIsInRoleSupplier && (
              <Form.Item label={t('customer')} required>
                <Select
                  onChange={(value) => this.changeTenantId(value)}
                  placeholder={t('select')}
                  value={filterDto.tenantId}
                  allowClear={false}
                >
                  {(this.props.tenants ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{getTenantDisplayName(each)}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {userIsInRoleDistributor && (
              <Form.Item label={t('customer')}>
                <Select
                  placeholder={t('all')}
                  onChange={(value) => this.changeCustomerId(value)}
                  value={filterDto.customerId}
                >
                  {(this.props.customers ?? []).map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {!userIsInRoleDistributor && !userIsInRoleSupplier && !userIsDcribCustomer && (
              <Form.Item label={t('confirmationStatus')}>
                <Select
                  onChange={(value) => this.changeConfirmationStatusFilter(value)}
                  value={filterDto.includeUnconfirmedOnly ? 'Unconfirmed' : filterDto.confirmationStatusFilter || 'All'}
                  placeholder={t('all')}
                >
                  {Object.entries(confirmationStatusOptions).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {t(value)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {!userIsInRoleDistributor && !userIsInRoleSupplier && !userIsDcribCustomer && (
              <Form.Item label={HARD_SPACE}>
                <Checkbox
                  onChange={(e) => this.setFilterValue('highlightUnconfirmed', e.target.checked)}
                  checked={filterDto.highlightUnconfirmed}
                >
                  {t('highlightUnconfirmed')} <Help title={t('highlightUnconfirmedInfo')} />
                </Checkbox>
              </Form.Item>
            )}
            {!userIsInRoleDistributor && !userIsInRoleSupplier && (
              <Form.Item label={HARD_SPACE}>
                <Checkbox
                  onChange={(e) => this.changeExcludeExternal(e.target.checked)}
                  checked={filterDto.excludeExternal}
                >
                  {t('excludeExternal')}
                </Checkbox>
              </Form.Item>
            )}
            <Form.Item label={HARD_SPACE}>
              <Checkbox
                onChange={(e) => this.setFilterValue('expeditedOnly', e.target.checked)}
                checked={filterDto.expeditedOnly}
              >
                {t('expeditedOnly')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
