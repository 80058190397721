import produce from 'immer'

export const createGetLabelTemplateWithImages = (self) => async (item) => {
  const imageFields = await Promise.all(
    item.lines
      .flatMap((line) => line.fields.filter((field) => field.fieldType === 'Image'))
      .map((field) =>
        self.props
          .getDocumentContents({
            request: {
              domainObjectId: field.imageId,
              domainObjectType: 'LabelTemplate',
              documentType: 'ObjectImage',
              documentName: `${field.imageId}`,
            },
          })
          .then((r) => r.value.data)
          .then((image) => ({ field, image }))
          .catch(() => {})
      )
  )

  return produce(item, (draft) => {
    imageFields.filter(Boolean).forEach((each) => {
      draft.lines
        .find((line) => line.id === each.field.lineId)
        .fields.find((field) => field.id === each.field.id).image = each.image
    })
  })
}
