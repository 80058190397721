import * as React from 'react'
import { Form } from 'antd'
import periodOptions from 'options/assets/periodOptions'
import { showError } from 'helpers/errors'
import { createDropdownRender, createSearchAssets, createSearchOperators } from 'helpers/formViews'
import { HARD_SPACE } from 'helpers/utils'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import Checkbox from 'elements/Checkbox'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchAssets = createSearchAssets(this)
    this.searchOperators = createSearchOperators(this)
  }

  componentDidMount() {
    const {
      filterDto: { locationGroupIds, locationIds, assetCategoryIds, assetIds, operatorGroupIds, operatorIds },
    } = this.props

    Promise.all([
      this.props.getLocationGroups(),
      this.props.getLocations({ locationGroupIds, locationIds }),
      this.props.getJobReasons(),
      this.props.getAssets({ assetCategoryIds, assetIds }),
      this.props.getAssetCategories(),
      this.props.getOperators({ operatorIds, operatorGroupIds }),
    ]).catch((error) => showError({ error }))
  }

  render() {
    const { filterDto, assets, operators } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker
              filterDto={filterDto}
              setFilterValue={this.setFilterValue}
              rangePickerLabel={t('createdDate')}
            />
            <Form.Item label={t('period')}>
              <Select
                onChange={(value) => this.setFilterValue('period', value || 'Month')}
                value={filterDto.period}
              >
                {Object.entries(periodOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.displayName} value={each.id}>
                    {each.displayName ?? each.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('jobReason')}>
              <Select
                onChange={(value) => this.setFilterValue('jobReasonIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.jobReasonIds}
              >
                <Option value={0}>{t('none')}</Option>
                {(this.props.jobReasons ?? [])
                  .filter((each) => each.id)
                  .map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('assetCategory')}>
              <Select
                onChange={(value) => this.setFilterValue('assetCategoryIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.assetCategoryIds}
              >
                <Option value={0}>{t('none')}</Option>
                {(this.props.assetCategories ?? [])
                  .filter((each) => each.id)
                  .map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('asset')}>
              <Select
                onChange={(values) => this.setFilterValue('assetIds', values)}
                placeholder={t('all')}
                value={filterDto.assetIds}
                dropdownRender={createDropdownRender(assets)}
                onSearch={this.searchAssets}
                mode="multiple"
                showSearch
              >
                <Option value={0}>{t('none')}</Option>
                {(assets?.items ?? [])
                  .filter((each) => each.id)
                  .map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={HARD_SPACE}>
              <Checkbox
                onChange={(e) => this.setFilterValue('separateByReason', e.target.checked)}
                checked={filterDto.separateByReason}
              >
                {t('separateByReason')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('operator')}>
              <Select
                onChange={(value) => this.setFilterValue('operatorIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.operatorIds}
                dropdownRender={createDropdownRender(operators)}
                onSearch={this.searchOperators}
                showSearch
              >
                {(operators?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
