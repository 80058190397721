import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { t } from 'helpers/i18n'
import { createGetFieldLabel } from 'helpers/dashboards'
import { isReadOnly } from 'helpers/formViews'
import Select, { Option, getOptionProps } from 'elements/Select'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      const response = await this.props.getDataSelections({ type: this.props.primaryMetricType })

      this.setState({ dataSelectionItems: response.value.data.items })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { fields = [], filterDto = {} } = this.props

    const getFieldLabel = createGetFieldLabel(fields)
    const readOnly = isReadOnly(this)

    return (
      <>
        {fields.find((one) => one.type === 'dataSelection') && (
          <Form.Item label={getFieldLabel('dataSelection')}>
            <Select
              onChange={(value) => this.setFilterValue('dataSelectionEnums', value)}
              value={filterDto.dataSelectionEnums}
              placeholder={t('all')}
              mode="multiple"
              readOnly={readOnly}
            >
              {(this.state.dataSelectionItems ?? []).map((each) => (
                <Option key={each.id} value={each.id}>
                  <span {...getOptionProps(each)}>{each.name}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </>
    )
  }
}

export default Form.create()(Filter)
