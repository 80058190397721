import * as React from 'react'
import { Form } from 'antd'
import reportGroupingOptions from 'options/jobs/reportGroupings'
import { showError } from 'helpers/errors'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import { Row, Col } from 'elements/Grid'
import {
  createDropdownRender,
  createSearchAssets,
  createSearchSuppliers,
  createSearchOperators,
} from 'helpers/formViews'
import { HARD_SPACE } from 'helpers/utils'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Checkbox from 'elements/Checkbox'

const completionStatusOptions = {
  All: 'all',
  Closed: 'closed',
  Open: 'open',
}

const dateRangeFields = {
  JobCreateDate: 'jobCreateDate',
  PartsAndLabourDate: 'partsAndLabourDate',
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchAssets = createSearchAssets(this)
    this.searchSuppliers = createSearchSuppliers(this)
    this.searchOperators = createSearchOperators(this)
  }

  async componentDidMount() {
    const {
      locationGroupIds,
      locationIds,
      assetCategoryIds,
      assetIds,
      operatorGroupIds,
      operatorIds,
      supplierIds,
    } = this.props.filterDto

    try {
      await Promise.all([
        this.props.getLocationGroups(),
        this.props.getLocations({ locationGroupIds, locationIds }),
        this.props.getJobGroups(),
        this.props.getJobReasons(),
        this.props.getAssets({ assetCategoryIds, assetIds }),
        this.props.getAssetCategories(),
        this.props.getOperatorGroups(),
        this.props.getOperators({ operatorIds, operatorGroupIds }),
        this.props.getSuppliers(supplierIds ? { supplierIds } : undefined),
        this.props.getUsers(),
      ])
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto, assets, operators, suppliers } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col fourth>
            <DateRangePicker
              filterDto={filterDto}
              setFilterValue={this.setFilterValue}
              rangePickerLabel={t('createdDate')}
              dateRangeFields={dateRangeFields}
              defaultDateRangeField="JobCreateDate"
            />
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('jobGroup')}>
              <Select
                onChange={(value) => this.setFilterValue('jobGroupIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.jobGroupIds}
              >
                {(this.props.jobGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('reason')}>
              <Select
                onChange={(value) => this.setFilterValue('jobReasonIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.jobReasonIds}
              >
                {(this.props.jobReasons ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('assetCategory')}>
              <Select
                onChange={(value) => this.setFilterValue('assetCategoryIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.assetCategoryIds}
              >
                {(this.props.assetCategories ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('asset')}>
              <Select
                onChange={(values) => this.setFilterValue('assetIds', values)}
                value={filterDto.assetIds}
                dropdownRender={createDropdownRender(assets)}
                onSearch={this.searchAssets}
                placeholder={t('all')}
                mode="multiple"
                showSearch
              >
                {(assets?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('assignedToGroup')}>
              <Select
                onChange={(value) => this.setFilterValue('operatorGroupIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.operatorGroupIds}
              >
                <Option value={-1}>{t('unassigned')}</Option>
                {(this.props.operatorGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('assignedTo')}>
              <Select
                onChange={(value) => this.setFilterValue('operatorIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.operatorIds}
                dropdownRender={createDropdownRender(operators)}
                onSearch={this.searchOperators}
                showSearch
              >
                {(operators?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('completionStatus')}>
              <Select
                onChange={(value) => this.setFilterValue('completionStatus', value || 'All')}
                value={filterDto.completionStatus}
              >
                {Object.entries(completionStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('supplier')}>
              <Select
                onChange={(value) => this.setFilterValue('supplierIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.supplierIds}
                dropdownRender={createDropdownRender(suppliers)}
                onSearch={this.searchSuppliers}
                showSearch
              >
                {(suppliers?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col fourth>
            <Form.Item label={t('supervisor')}>
              <Select
                onChange={(value) => this.setFilterValue('supervisors', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.supervisors}
                showSearch
              >
                {(this.props.users ?? []).map((each) => (
                  <Option key={each.userName} value={each.userName}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('reportGrouping')}>
              <Select
                onChange={(value) => this.setFilterValue('reportGroupings', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.reportGroupings}
              >
                {Object.entries(reportGroupingOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={HARD_SPACE}>
              <Checkbox
                onChange={(e) => this.setFilterValue('includeSupplierLabour', e.target.checked)}
                checked={filterDto.includeSupplierLabour}
              >
                {t('includeSupplierLabour')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
