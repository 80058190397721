export default {
  Line: { languageKey: 'line', iconType: 'show_chart' },
  Column: { languageKey: 'column', iconType: 'bar_chart' },
  Bar: { languageKey: 'bar', iconType: 'bar_chart', iconRotation: 90 },
  Area: { languageKey: 'area', iconType: 'landscape' },
  Pie: { languageKey: 'pie', iconType: 'pie_chart' },
  Donut: { languageKey: 'donut', iconType: 'donut_large' },
  Scatter: { languageKey: 'scatter', iconType: 'scatter_plot' },
  Gauge: { languageKey: 'gauge', iconType: 'speed' },
  Scorecard: { languageKey: 'scorecard', iconType: 'money' },
}
