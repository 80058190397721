import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import productActions from 'actions/products'
import supplierActions from 'actions/suppliers'
import productCategoryActions from 'actions/productCategories'
import Component from 'components/Products/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  suppliers: selectors.suppliers.selectOptions(state, { itemsOnly: false }),
  productCategories: selectors.productCategories.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getSuppliers: (params) =>
    dispatch(
      supplierActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
        active: 'All',
      })
    ),
  getProductCategories: (params) =>
    dispatch(productCategoryActions.getOptions({ ...params, active: 'All', pageIndex: 0 })),
  getProductCatalogs: () => dispatch(productActions.getCatalogs()),
  refreshCatalogItems: (params) => dispatch(productActions.refreshCatalogItems(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
