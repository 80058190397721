import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/InventoryLastUsedReport/Filter'

export default createListViewComponent({
  entityName: entityNames.inventoryLastUsedReport,
  filterTemplateType: 'InventoryLastUsedReport',
  allowSelection: (self) => false,
  allowCreate: (self) => false,
  initialFilterDto: (self) => ({ lastUsedDaysAgo: 30 }),
})(Filter)
