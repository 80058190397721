import EventEmitter from 'eventemitter3'

const eventEmitter = new EventEmitter()

export const Emitter = eventEmitter

export const stopEvent = (e) => {
  if (e) {
    try {
      e.cancelBubble = true
      e.preventDefault?.()
      e.stopPropagation?.()
      e.stopImmediatePropagation?.()
      e.nativeEvent?.stopImmediatePropagation?.()
    } catch (error) {
      console.warn(error)
    }
  }

  return false
}
