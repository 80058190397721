import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/UserActionReport/Filter'

export default createListViewComponent({
  entityName: entityNames.userActionReport,
  filterTemplateType: 'UserActionReport',
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'MonthToDate' } }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
})(Filter)
