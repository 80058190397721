export const RED = '#ff4d4f'
export const LIGHT_RED = '#ffccc7'
export const LIGHTER_RED = '#ffccc780'
export const GREEN = '#a0d911'
export const LIGHT_GREEN = '#d9f7be'
export const LIGHTER_GREEN = '#d9f7be80'
export const DARK_GREEN = '#389e0d'
export const YELLOW = '#faad14'
export const LIGHT_YELLOW = '#ffffcc'
export const LIGHTER_YELLOW = '#ffffcc80'
export const GREY = '#333'
export const LIGHT_GREY = '#999'
export const LIGHTER_GREY = '#fafafa'
export const BLUE = '#1890ff'

export const GOOGLE_COLORS = [
  '#3366cc',
  '#ff9900',
  '#990099',
  '#0099c6',
  '#dd4477',
  '#66aa00',
  '#b82e2e',
  '#316395',
  '#994499',
  '#22aa99',
  '#aaaa11',
  '#6633cc',
  '#e67300',
  '#8b0707',
  '#651067',
  '#329262',
  '#5574a6',
  '#3b3eac',
  '#b77322',
  '#16d620',
  '#b91383',
  '#f4359e',
  '#9c5935',
  '#a9c413',
  '#2a778d',
  '#668d1c',
  '#bea413',
  '#0c5922',
  '#743411',
  '#808000',
  '#48D1CC',
  '#FFC0CB',
  '#FA8072',
  '#40E0D0',
  '#9ACD32',
  '#D87093',
  '#FFD700',
  '#87CEEB',
  '#20B2AA',
  '#FFA07A',
  '#FF8C00',
  '#6495ED',
  '#DEB887',
  '#986B9B',
  '#267A9E',
  '#ED8C37',
  '#5391AE',
  '#85B464',
  '#E2D269',
  '#EA915E',
  '#BC677B',
  '#7A4E8A',
]
