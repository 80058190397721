import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import listActions from 'actions/lists'
import selectors from 'selectors'
import userGroupActions from 'actions/userGroups'
import userActions from 'actions/users'
import Component from 'components/Lists/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  userGroups: selectors.userGroups.selectOptions(state),
  users: selectors.users.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getUserGroups: (params) => dispatch(userGroupActions.getOptions(params)),
  getUsers: (params) => dispatch(userActions.getOptions({ ...params, active: 'All' })),
  getListTypes: (params) => dispatch(listActions.getListTypes(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
