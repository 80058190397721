import { Button } from 'antd'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { encodeState } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { getUserPermission } from 'helpers/auth'
import Icon from 'elements/Icon'
import Filter from 'containers/CycleCounts/Filter'

export default createListViewComponent({
  entityName: entityNames.cycleCounts,
  filterTemplateType: 'CycleCount',
  createButtonTextId: 'createCycleCount',
  primaryLinkTarget: 'cycleCountRecord',
  extraRowActions: (self) => (
    <>
      {getUserPermission('Reports') !== 'No' && (
        <Button
          disabled={self.state.selectedRowKeys.length !== 1}
          onClick={() =>
            self.props.history.push(
              `/reports/inventory/cycleCountDifferenceReport?${encodeState({
                showFilter: true,
                filterDto: {
                  cycleCountId: self.state.selectedRowKeys[0],
                },
              })}`
            )
          }
        >
          <Icon type="InsertChart" />
          {t('cycleCountDifferenceReport')}
        </Button>
      )}
    </>
  ),
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
  getDeleteButtonTooltip: (self) => t('deleteCycleCountsTooltip'),
})(Filter)
