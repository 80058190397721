import { Button, message } from 'antd'
import { isNil } from 'lodash'
import produce from 'immer'
import cx from 'clsx'
import { createChildListViewComponent } from 'factories/ChildListView'
import entityNames from 'options/entityNames'
import { formatValue } from 'helpers/listViews'
import { isValidDate, formatUserTime } from 'helpers/dateTime'
import { t } from 'helpers/i18n'
import { isReadOnly } from 'helpers/formViews'
import Icon from 'elements/Icon'
import InputNumber from 'elements/InputNumber'
import FormView from 'containers/Assets/Tolerances/FormView'

export default createChildListViewComponent({
  entityName: entityNames.assets,
  childName: 'assetTolerances',
  createButtonTextId: 'addTolerance',
  createButtonLinkTarget: {
    formSize: 'xs',
    formComponent: FormView,
  },
  primaryLinkTarget: 'assetToleranceRecord',
  allowCreate: (self) => self.props.settingsType === 'assets',
  allowDelete: (self) => self.props.settingsType === 'assets',
  extraRowActions: (self) => (
    <Button disabled={self.state.selectedRowKeys.length !== 1} onClick={() => message.warn(t('underDevelopment'))}>
      <Icon type="InsertChart" />
      {t('assetToleranceReport')}
    </Button>
  ),
  tableSummary: (self) => {
    const { items = [] } = self.props
    const outOfTolerance = items.filter(
      (each) => isValidDate(each.lastUpdateDate) && (each.lastValue > each.max || each.lastValue < each.min)
    )

    return `${outOfTolerance.length} ${t('of')} ${items.length} ${t('outOfTolerance')}`
  },
  tableCellComponents: {
    newValue: (self, item) => (
      <InputNumber
        value={item.valueChanged ? item.newValue : null}
        onChange={async (value) => {
          try {
            await self.props.onChange(
              produce(self.props.items, (draftItems) => {
                const draftItem = draftItems.find((one) => one.id === item.id)
                if (isNil(value)) {
                  draftItem.newValue = 0
                  draftItem.valueChanged = false
                } else {
                  draftItem.newValue = value
                  draftItem.valueChanged = true
                }
              })
            )
          } catch (error) {
            console.warn(error)
          }
        }}
        className={cx({
          'form-field-error': item.valueChanged && (item.newValue > item.max || item.newValue < item.min),
        })}
        step={0.001}
        disabled={isReadOnly(self)}
        allowClear
      />
    ),
    lastValue: (self, item) => {
      if (!item?.lastUpdated) {
        return ''
      }

      const text = formatValue({
        value: item.lastValue,
        displayFormat: self.state.fieldSettings.find((one) => one.dtoFieldName === 'lastValue').displayFormat,
      })

      return item.lastValue > item.max || item.lastValue < item.min ? (
        <div className="table-cell-error">
          <span>{text}</span>
        </div>
      ) : (
        text
      )
    },
    lastUpdated: (self, item) => formatUserTime(item.lastUpdateDate, item.lastUpdatedBy),
  },
})()
