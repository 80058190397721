import { useEffect } from 'react'
import { Form, Dropdown, Menu, Row, Col } from 'antd'
import { getStartDate, getEndDate } from 'helpers/dateTime'
import { t } from 'helpers/i18n'
import { isEmpty } from 'lodash'
import dateRangeTypes from 'options/dateRangeTypes'
import Select, { Option } from 'elements/Select'
import InputNumber from 'elements/InputNumber'
import Icon from 'elements/Icon'
import DatePicker from 'elements/DatePicker'

function Component(props) {
  const {
    filterDto = {},
    dateRangeFields = {},
    setFilterValue = () => {},
    dateRangeTypeLabel = t('dateRangeType'),
    rangePickerLabel = t('dateRange'),
    defaultDateRangeField = 'All',
    extraDateRangeTypes = {},
  } = props

  const isFieldAll = filterDto.dateRangeField === 'All' && !isEmpty(dateRangeFields)
  const dateRangeType = filterDto.dateRange?.dateRangeType ?? 'YearToDate'
  const isTypeCustom = dateRangeType === 'Custom'
  const isTypeN = dateRangeType.startsWith('LastN') || dateRangeType.startsWith('NextN')
  const lastNValue = filterDto.dateRange?.lastNValue ?? 1
  const pickersDisabled = !isTypeCustom || isFieldAll

  function handleDateRangeTypeChange(value) {
    if (value === 'Custom') {
      setFilterValue('dateRange', {
        dateRangeType: value,
        customStartDate: getStartDate(dateRangeType, lastNValue),
        customEndDate: getEndDate(dateRangeType, lastNValue),
      })
    } else if (value.startsWith('LastN') || value.startsWith('NextN')) {
      setFilterValue('dateRange', { dateRangeType: value, lastNValue })
    } else {
      setFilterValue('dateRange', { dateRangeType: value })
    }
  }

  useEffect(() => {
    if (!Object.keys({ ...dateRangeTypes, ...extraDateRangeTypes }).includes(dateRangeType)) {
      handleDateRangeTypeChange('Custom')
    }
  }, [dateRangeType, extraDateRangeTypes])

  return (
    <>
      <Form.Item label={dateRangeTypeLabel}>
        <Select
          style={{
            width: isTypeN ? 'calc(50% - 3px)' : '100%',
            marginRight: isTypeN ? '6px' : '0px',
          }}
          disabled={isFieldAll}
          onChange={handleDateRangeTypeChange}
          value={dateRangeType}
          allowClear={false}
        >
          {Object.entries({ ...dateRangeTypes, ...extraDateRangeTypes }).map(([key, value]) => (
            <Option key={key} value={key}>
              {t(value)}
            </Option>
          ))}
        </Select>
        <InputNumber
          onChange={(value) => setFilterValue('dateRange.lastNValue', Math.max(value, 1))}
          style={{
            width: 'calc(50% - 3px)',
            display: isTypeN ? '' : 'none',
          }}
          value={filterDto.dateRange?.lastNValue}
          min={1}
          disabled={isFieldAll}
        />
      </Form.Item>
      <Form.Item
        className="whitespace-nowrap"
        label={
          !isEmpty(dateRangeFields) ? (
            <Dropdown
              overlay={
                <Menu onClick={({ key }) => setFilterValue('dateRangeField', key)}>
                  {Object.entries(dateRangeFields).map(([key, value]) => (
                    <Menu.Item key={key}>{t(value)}</Menu.Item>
                  ))}
                </Menu>
              }
            >
              <a>
                {t(
                  dateRangeFields[filterDto.dateRangeField || defaultDateRangeField] ?? defaultDateRangeField
                )}{' '}
                <Icon type="KeyboardArrowDown" />
              </a>
            </Dropdown>
          ) : (
            rangePickerLabel
          )
        }
      >
        <Row gutter={6}>
          <Col xs={12}>
            <DatePicker
              value={
                !isFieldAll
                  ? filterDto.dateRange?.customStartDate ?? getStartDate(dateRangeType, lastNValue)
                  : null
              }
              placeholder={t('startDate')}
              setTimeMode="start"
              onChange={(value) => setFilterValue('dateRange.customStartDate', value.toJSON())}
              disabled={pickersDisabled}
              allowClear={false}
            />
          </Col>
          <Col xs={12}>
            <DatePicker
              value={
                !isFieldAll
                  ? filterDto.dateRange?.customEndDate ?? getEndDate(dateRangeType, lastNValue)
                  : null
              }
              placeholder={t('endDate')}
              setTimeMode="end"
              onChange={(value) => setFilterValue('dateRange.customEndDate', value.toJSON())}
              disabled={pickersDisabled}
              allowClear={false}
            />
          </Col>
        </Row>
      </Form.Item>
    </>
  )
}

export default Component
