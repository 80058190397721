import actions from 'actions/users'
import authActions from 'actions/auth'
import { createFormViewContainer } from 'factories/FormView'
import Component from 'components/Settings/User'

export default createFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    refreshToken: (params) => dispatch(authActions.refreshToken(params)),
  }),
})(Component)
