import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import actions from 'actions/labelTemplates'
import documentActions from 'actions/documents'
import { createDocumentsDispatchToProps } from 'helpers/formViews'
import Component from 'components/PrintLabel/FormView'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch) => ({
  getSettings: (params) => dispatch(actions.getSettings(params)),
  getLabelTemplateFields: (params) => dispatch(actions.getLabelTemplateFields(params)),
  getLabelTemplate: (params) => dispatch(actions.getItem(params)),
  getLabelTemplates: (params) => dispatch(actions.getItems(params)),
  ...createDocumentsDispatchToProps(dispatch, documentActions),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
