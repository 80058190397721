import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import cycleCountActions from 'actions/cycleCounts'
import Component from 'components/Reports/InventoryCycleCountDifferenceReport/Filter'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getCycleCountSelectionList: (params) =>
    dispatch(
      cycleCountActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        ...params,
      })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
