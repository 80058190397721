import * as React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Button, Form, Tabs, Input, Tooltip, Spin, Card, Table, message } from 'antd'
import { useImmer } from 'use-immer'
import { isNil, isEmpty, get, upperFirst, lowerCase, set, isFunction, invoke } from 'lodash'
import { saveAs } from 'file-saver'
import { getStorageItem, setStorageItem } from 'helpers/localStorage'
import { getTableWidth, showTotal, pageSizeOptions } from 'helpers/listViews'
import { showError } from 'helpers/errors'
import { getColumns, mapToFieldSettings } from 'helpers/uploadData'
import { t } from 'helpers/i18n'
import { HARD_SPACE, tryParseJSON, tryParseQueryString } from 'helpers/utils'
import { stopEvent } from 'helpers/events'
import { getMimeType, getFileExtension } from 'helpers/downloadItems'
import { PRIVATE_DATABASE } from 'options/products'
import { getPageTitle } from 'helpers/auth'
import Page from 'elements/Page'
import ResizableCell from 'elements/ResizableCell'
import Select, { Option, getOptionProps } from 'elements/Select'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import Help from 'elements/Help'
import Drawer from 'elements/Drawer'
import FileUpload from 'elements/FileUpload'
import Checkbox from 'elements/Checkbox'
import { Row, Col } from 'elements/Grid'

const humanize = (seconds = 0) =>
  moment.utc(moment.duration(seconds, 'seconds').asMilliseconds()).format('HH:mm:ss')

const getStorageKey = () => 'tools.uploadData'

const COMPLETE = 'Complete'
const CANCELLED = 'Cancelled'

const isInProgress = (uploadValidationStage) =>
  uploadValidationStage && ![COMPLETE, CANCELLED].includes(uploadValidationStage)

const Options = styled(Card)`
  margin: 16px 0;

  .ant-card-body {
    padding: 18px;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }
`

const Actions = styled(Col)`
  text-align: right;

  .ant-btn {
    padding: 5px;
    line-height: 5px;
    height: 26px;
  }
`

const Wrapper = styled.div`
  overflow: auto;
  flex: 1 1 0%;

  @media screen and (min-width: 992px) and (min-height: 720px) {
    .ant-table {
      height: calc(100vh - 450px);
    }

    .ant-table-pagination {
      margin-top: 30px;
      position: fixed;
    }
  }
`

function Component(props) {
  const { applyUpdate, cancelUpload, clearUploadResults, allowedFileTemplateTypes } = props

  const [state, updateState] = useImmer({
    loading: false,
    objectType: Object.keys(allowedFileTemplateTypes)[0],
    validRowsWidths: {},
    invalidRowsWidths: {},
    validRowsSortedInfo: {},
    invalidRowsSortedInfo: {},
    uploadProgress: {},
    validRows: [],
    invalidRows: [],
    ...getStorageItem(getStorageKey()),
    ...tryParseQueryString(window.location.search),
  })
  const {
    objectType = '',
    loading = false,
    backupDownloading,
    catalogTableName,
    fileTemplateId,
    fileTemplates,
    ignoreFirstRow,
    invalidRows,
    invalidRowsFieldSettings,
    invalidRowsSortedInfo,
    invalidRowsWidths,
    updateDatabaseResults,
    uploadProgress,
    validRows,
    validRowsFieldSettings,
    validRowsSortedInfo,
    validRowsWidths,
  } = state

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  const filteredFileTemplates = fileTemplates
    ? fileTemplates.filter((each) => each.objectType === objectType)
    : []

  const selectedFileTemplate = filteredFileTemplates.find((one) => one.id === fileTemplateId)

  const validRowsColumns = getColumns({
    fieldSettings: validRowsFieldSettings || [],
    onResize:
      ({ key }) =>
      (e, { size }) => {
        updateState((draft) => {
          draft.validRowsWidths[key] = size.width
        })
      },
    columnWidths: validRowsWidths,
    ...validRowsSortedInfo,
  })

  const invalidRowsColumns = getColumns({
    fieldSettings: invalidRowsFieldSettings || [],
    onResize:
      ({ key }) =>
      (e, { size }) => {
        updateState((draft) => {
          draft.invalidRowsWidths[key] = size.width
        })
      },
    columnWidths: invalidRowsWidths,
    ...invalidRowsSortedInfo,
  })

  async function changeObjectType(value) {
    try {
      await clearUploadResults()
    } catch (error) {
      console.warn(error)
    }

    updateState((draft) => {
      draft.objectType = value
      draft.fileTemplateId = null
      draft.validRows = []
      draft.invalidRows = []
      draft.validRowsFieldSettings = null
      draft.invalidRowsFieldSettings = null
      draft.validRowsSortedInfo = {}
      draft.invalidRowsSortedInfo = {}
    })

    if (!isEmpty(fileTemplates)) {
      const nextFileTemplateId = fileTemplates.find((one) => one.objectType === value)?.id

      changeFileTemplateId(nextFileTemplateId)

      if (objectType && !nextFileTemplateId) {
        message.error(t('addFileTemplateFirst'))
      }
    }
  }

  async function changeFileTemplateId(value) {
    try {
      await clearUploadResults()
    } catch (error) {
      console.warn(error)
    }

    updateState((draft) => {
      draft.fileTemplateId = value
      draft.validRows = []
      draft.invalidRows = []
      draft.validRowsFieldSettings = null
      draft.invalidRowsFieldSettings = null
      draft.validRowsSortedInfo = {}
      draft.invalidRowsSortedInfo = {}
    })
  }

  async function handleDownloadBackupClick() {
    try {
      setState('backupDownloading', true)

      const acceptMimeType = getMimeType(selectedFileTemplate.fileTemplateFileType)

      console.assert(props[`download${objectType}Items`])

      const response = await invoke(props, `download${objectType}Items`, {
        acceptMimeType,
        selectedColumnKeys: selectedFileTemplate.fields,
      })

      saveAs(
        response.value.data,
        `${t(allowedFileTemplateTypes[objectType])} - ${selectedFileTemplate.name}.${getFileExtension(
          acceptMimeType
        )}`
      )
    } catch (error) {
      if (isFunction(error?.response?.data?.text)) {
        error.response.data
          .text()
          .then((text) => showError({ error: { response: { data: tryParseJSON(text, {}) } } }))
      } else {
        showError({ error })
      }
    }

    setState('backupDownloading', false)
  }

  async function fetchUploadProgress() {
    try {
      const response = await props.getUploadProgress().then((r) => r.value.data)

      updateState((draft) => {
        draft.uploadProgress = response

        if (response.objectType !== 'All') {
          draft.objectType = response.objectType
        }

        if (response.templateId) {
          draft.fileTemplateId = response.templateId
        }

        if (response.ignoreFirstRow) {
          draft.ignoreFirstRow = response.ignoreFirstRow
        }

        if (response.uploadValidationStage === CANCELLED) {
          draft.uploadProgressModalVisible = false
        } else if (response.totalRows > 0) {
          draft.uploadProgressModalVisible = true
        }
      })

      if (isInProgress(response.uploadValidationStage)) {
        window.setTimeout(fetchUploadProgress, 2000)
      }
    } catch (error) {
      showError({ error })
      clearUploadResults()
    }
  }

  async function fetchUploadResults() {
    if (!isInProgress(uploadProgress.uploadValidationStage)) {
      try {
        const responses = await Promise.all([
          props.getUploadResults({ pageIndex: 0 }),
          props.getUploadResults({ pageIndex: 0, getErrors: true }),
        ])

        updateState((draft) => {
          draft.validRows = get(responses[0], 'value.data.items', [])
          draft.invalidRows = get(responses[1], 'value.data.items', [])
        })
      } catch (error) {
        updateState((draft) => {
          draft.validRows = []
          draft.invalidRows = []
        })
      }
    } else {
      updateState((draft) => {
        draft.validRows = []
        draft.invalidRows = []
      })
    }
  }

  async function fetchFileTemplates() {
    try {
      const responses = await Promise.all([props.getFileTemplates(), props.getProductCatalogs()])

      updateState((draft) => {
        draft.fileTemplates = get(responses[0], 'value.data.items', [])
        draft.productCatalogs = responses[1].value.data
      })

      return responses
    } catch (error) {
      showError({ error })

      return error
    }
  }

  async function handleClearUploadClick() {
    try {
      await clearUploadResults()
      await fetchUploadResults()
    } catch (error) {
      showError({ error })
    }
  }

  async function handleUpdateDatabaseClick() {
    try {
      updateState((draft) => {
        draft.uploadProgressModalVisible = false
        draft.loading = true
      })

      const response = await applyUpdate({ fileTemplateId })

      setState('updateDatabaseResults', response.value.data)

      window.setTimeout(fetchUploadProgress, 2000)
    } catch (error) {
      showError({ error })
    } finally {
      setState('loading', false)
    }
  }

  async function handleUploadCancelClick() {
    try {
      await cancelUpload()

      setState('uploadProgressModalVisible', false)
    } catch (error) {
      showError({ error })
    }
  }

  async function fetchFileTemplateSettings() {
    if (fileTemplateId) {
      try {
        const responses = await Promise.all([
          props.getValidSettings({ fileTemplateId }),
          props.getInvalidSettings({ fileTemplateId }),
        ])

        updateState((draft) => {
          draft.validRowsFieldSettings = responses[0].value.data.fieldSettings
          draft.invalidRowsFieldSettings = responses[1].value.data.fieldSettings
        })
      } catch (error) {
        showError({ error })
      }
    }
  }

  async function handleFileUpload(fileList = []) {
    if (isEmpty(fileList)) {
      setState('fileUploadDrawerVisible', false)
    } else {
      try {
        const { fileName, fileContents: contentsAsBase64 } = fileList[0]

        await props.uploadFile({
          fileName,
          contentsAsBase64,
          fileTemplateId,
          fileType: selectedFileTemplate.fileType,
          ignoreFirstRow,
          catalogTableName: objectType === 'CatalogProduct' ? catalogTableName : PRIVATE_DATABASE,
        })

        window.setTimeout(fetchUploadProgress, 2000)
        window.setTimeout(() => setState('fileUploadDrawerVisible', false), 3000)
      } catch (error) {
        showError({ error })
      }
    }
  }

  async function downloadResults({ getErrors }) {
    try {
      const acceptMimeType = getMimeType(selectedFileTemplate.fileTemplateFileType)

      const response = await props.downloadUploadResults({
        pageIndex: 0,
        acceptMimeType,
        getErrors,
      })

      saveAs(
        response.value.data,
        `${t(allowedFileTemplateTypes[objectType])} - ${selectedFileTemplate.name} - ${t(
          getErrors ? 'invalidRows' : 'validRows'
        )}.${getFileExtension(acceptMimeType)}`
      )
    } catch (error) {
      showError({ error })
    }
  }

  React.useEffect(() => {
    setStorageItem(getStorageKey(), {
      objectType,
      ignoreFirstRow,
      fileTemplateId,
      validRowsWidths,
      invalidRowsWidths,
      validRowsSortedInfo,
      invalidRowsSortedInfo,
    })
  })

  React.useEffect(() => {
    fetchFileTemplates()
    fetchUploadProgress()
  }, [])

  React.useEffect(() => {
    fetchFileTemplateSettings()
  }, [fileTemplateId])

  React.useEffect(() => {
    fetchUploadResults()
  }, [uploadProgress])

  React.useEffect(() => {
    if (isNil(fileTemplateId)) {
      updateState((draft) => {
        draft.validRowsFieldSettings = null
        draft.invalidRowsFieldSettings = null
      })
    }
  }, [fileTemplateId])

  React.useEffect(() => {
    if (
      !isNil(fileTemplateId) &&
      !isEmpty(filteredFileTemplates) &&
      !filteredFileTemplates.map((each) => each.id).includes(fileTemplateId)
    ) {
      changeFileTemplateId(null)

      if (objectType) {
        message.error(t('addFileTemplateFirst'))
      }
    }

    if (isNil(fileTemplateId) && !isEmpty(filteredFileTemplates)) {
      const nextFileTemplateId = filteredFileTemplates.find((one) => one.id).id

      if (nextFileTemplateId) {
        changeFileTemplateId(nextFileTemplateId)
      } else if (objectType) {
        message.error(t('addFileTemplateFirst'))
      }
    }
  })

  React.useEffect(() => {
    if (objectType && !fileTemplateId) {
      message.error(t('addFileTemplateFirst'))
    }
  }, [])

  React.useEffect(() => {
    if (objectType && !get(props, `download${objectType}Items`)) {
      console.error(`download${objectType}Items is missing from the createDownloadItemsDispatchToProps`)
    }
  }, [objectType])

  const pageTitle = getPageTitle()

  if (isNil(fileTemplates)) {
    return (
      <Page title={pageTitle}>
        <Spin />
      </Page>
    )
  }

  return (
    <>
      <Page title={pageTitle}>
        <Spin spinning={loading}>
          <Row type="flex" justify="space-between">
            <Col xs={24} sm={6} className="whitespace-nowrap">
              <h2>{pageTitle}</h2>
            </Col>
            <Col xs={24} sm={6} className="text-right">
              <Button
                onClick={handleDownloadBackupClick}
                className="mr-6"
                disabled={!get(props, `download${objectType}Items`) || isNil(selectedFileTemplate)}
                loading={backupDownloading}
              >
                {t('downloadBackup')}
              </Button>
            </Col>
          </Row>
          <Options>
            <Form layout="vertical" colon={false} onSubmit={stopEvent}>
              <Row>
                <Col xs={24} md={5}>
                  <Form.Item
                    label={
                      <span>
                        {t('uploadType')} <Help title={t('uploadTypeInfo')} />
                      </span>
                    }
                  >
                    <Select
                      value={objectType}
                      onChange={changeObjectType}
                      placeholder={t('select')}
                      allowClear={false}
                    >
                      {Object.entries(allowedFileTemplateTypes).map(([key, value]) => (
                        <Option key={key} value={key}>
                          {t(value)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {objectType === 'CatalogProduct' && (
                    <Form.Item label={t('database')}>
                      <Select
                        onChange={(value) => setState('catalogTableName', value)}
                        placeholder={t('select')}
                        value={catalogTableName}
                        allowClear={false}
                      >
                        {(state?.productCatalogs ?? []).map((each) => (
                          <Option key={each.catalogTableName} value={each.catalogTableName}>
                            {each.longName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
                <Col xs={24} md={5}>
                  <Form.Item
                    label={
                      <span>
                        {t('fileTemplate')} <Help title={t('selectFileTemplateInfo')} />
                      </span>
                    }
                  >
                    <Select
                      value={fileTemplateId}
                      onChange={changeFileTemplateId}
                      placeholder={t('none')}
                      allowClear={false}
                      disabled={isEmpty(filteredFileTemplates)}
                    >
                      {filteredFileTemplates.map((each) => (
                        <Option key={each.id} value={each.id}>
                          <span {...getOptionProps(each)}>{each.name}</span>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={5}>
                  <Form.Item label={t('fileType')}>
                    <Input value={t(selectedFileTemplate?.fileTemplateFileType)} readOnly />
                  </Form.Item>
                </Col>
                <Col xs={24} md={5}>
                  <Form.Item label={HARD_SPACE}>
                    <Checkbox
                      checked={ignoreFirstRow}
                      onChange={(e) => setState('ignoreFirstRow', e.target.checked)}
                      disabled={isNil(selectedFileTemplate)}
                    >
                      {t('ignoreFirstRow')}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} md={4} className="text-right">
                  <Form.Item label={HARD_SPACE}>
                    <Tooltip
                      title={<span dangerouslySetInnerHTML={{ __html: t('backupRecommended') }} />}
                      placement="topRight"
                    >
                      <Button
                        onClick={() => setState('fileUploadDrawerVisible', true)}
                        disabled={isNil(selectedFileTemplate)}
                      >
                        {t('selectFile')}
                      </Button>
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Options>
          {validRowsFieldSettings && invalidRowsFieldSettings && (
            <Tabs>
              <Tabs.TabPane
                key="validRows"
                tab={isEmpty(validRows) ? t('validRows') : `${t('validRows')} (${validRows.length})`}
                forceRender
              >
                <Row className="whitespace-nowrap mb-12">
                  <Actions>
                    <Tooltip title={t('download')} placement="topLeft">
                      <Button
                        onClick={() => downloadResults({ getErrors: false })}
                        disabled={isEmpty(validRows)}
                      >
                        <Icon type="FileDownload" />
                      </Button>
                    </Tooltip>
                  </Actions>
                </Row>
                <Wrapper>
                  <Table
                    childrenColumnName={[]}
                    rowKey={(record) => record.row}
                    columns={validRowsColumns}
                    dataSource={validRows.map((each) => mapToFieldSettings(each, validRowsFieldSettings))}
                    size="middle"
                    style={{ width: getTableWidth(validRowsColumns) }}
                    components={{ header: { cell: ResizableCell } }}
                    pagination={{
                      defaultPageSize: 20,
                      showTotal,
                      pageSizeOptions,
                      showSizeChanger: true,
                    }}
                    onChange={(pagination, filters, sorter = {}) => {
                      updateState((draft) => {
                        draft.validRowsSortedInfo = {
                          sortOrder: sorter.order,
                          sortBy: sorter.field,
                        }
                      })
                    }}
                    locale={{ emptyText: t('noData') }}
                  />
                </Wrapper>
                <Row type="flex" justify="space-between" className="mt-12">
                  <Col />
                  <Col className="text-right">
                    <Button
                      onClick={handleClearUploadClick}
                      disabled={isEmpty(validRows) && isEmpty(invalidRows)}
                    >
                      {t('clearUpload')}
                    </Button>{' '}
                    <Button
                      type="primary"
                      onClick={handleUpdateDatabaseClick}
                      disabled={isEmpty(validRows)}
                      loading={loading}
                    >
                      {t('applyUpdate')}
                    </Button>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane
                key="invalidRows"
                tab={isEmpty(invalidRows) ? t('invalidRows') : `${t('invalidRows')} (${invalidRows.length})`}
                disabled={isEmpty(invalidRows)}
                forceRender
              >
                <Row className="whitespace-nowrap mb-12">
                  <Actions>
                    <Tooltip title={t('download')} placement="topLeft">
                      <Button
                        onClick={() => downloadResults({ getErrors: true })}
                        disabled={isEmpty(invalidRows)}
                      >
                        <Icon type="FileDownload" />
                      </Button>
                    </Tooltip>
                  </Actions>
                </Row>
                <Wrapper>
                  <Table
                    childrenColumnName={[]}
                    rowKey={(record) => record.row}
                    columns={invalidRowsColumns}
                    dataSource={invalidRows.map((each) => mapToFieldSettings(each, invalidRowsFieldSettings))}
                    size="middle"
                    style={{ width: getTableWidth(invalidRowsColumns) }}
                    components={{ header: { cell: ResizableCell } }}
                    pagination={{
                      defaultPageSize: 20,
                      showTotal,
                      pageSizeOptions,
                      showSizeChanger: true,
                    }}
                    onChange={(pagination, filters, sorter = {}) => {
                      updateState((draft) => {
                        draft.invalidRowsSortedInfo = {
                          sortOrder: sorter.order,
                          sortBy: sorter.field,
                        }
                      })
                    }}
                    locale={{ emptyText: t('noData') }}
                  />
                </Wrapper>
                <Row type="flex" justify="space-between" className="mt-12">
                  <Col />
                  <Col className="text-right">
                    <Button
                      onClick={handleClearUploadClick}
                      disabled={isEmpty(validRows) && isEmpty(invalidRows)}
                    >
                      {t('clearUpload')}
                    </Button>
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
          )}
        </Spin>
      </Page>
      <Drawer
        title={t('selectFile')}
        size="xs"
        visible={state.fileUploadDrawerVisible}
        onClose={() => setState('fileUploadDrawerVisible', false)}
      >
        <FileUpload
          accept={
            selectedFileTemplate?.fileTemplateFileType === 'excel'
              ? '.xlsx,application/vnd.ms-excel'
              : '.csv,text/csv'
          }
          onUpload={handleFileUpload}
          onCancel={() => setState('fileUploadDrawerVisible', false)}
        />
      </Drawer>
      <Modal
        title={t('uploadProgressTitle')}
        footer={null}
        width={576}
        visible={state.uploadProgressModalVisible}
        onCancel={() => setState('uploadProgressModalVisible', false)}
        closable={!isInProgress(uploadProgress?.uploadValidationStage)}
        formContainer
      >
        <div className="form-items-container">
          <Form.Item label={t('filename')} colon={false}>
            <Input value={uploadProgress.fileName} readOnly />
          </Form.Item>
          <Row>
            <Col xs={12}>
              <Form.Item label={t('totalRows')} colon={false}>
                <Input value={uploadProgress.totalRows} readOnly />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label={t('processedRows')} colon={false}>
                <Input value={uploadProgress.processedRows} readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Item label={t('validRows')} colon={false}>
                <Input value={uploadProgress.validRows} readOnly />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label={t('invalidRows')} colon={false}>
                <Input
                  value={uploadProgress.invalidRows}
                  className={uploadProgress.invalidRows > 0 ? 'form-field-error' : ''}
                  readOnly
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Item label={t('elapsedTime')} colon={false}>
                <Input value={humanize(uploadProgress.elapsedTimeInSeconds)} readOnly />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label={t('remainingTime')} colon={false}>
                <Input value={humanize(uploadProgress.remainingTimeInSeconds)} readOnly />
              </Form.Item>
            </Col>
          </Row>
          {uploadProgress.uploadValidationStage && (
            <p>{upperFirst(lowerCase(uploadProgress.uploadValidationStage))}</p>
          )}
        </div>
        <div className="form-buttons-container">
          {isInProgress(uploadProgress.uploadValidationStage) ? (
            <Button onClick={handleUploadCancelClick}>{t('cancel')}</Button>
          ) : (
            <>
              <Button onClick={() => setState('uploadProgressModalVisible', false)}>{t('preview')}</Button>{' '}
              <Button
                type="primary"
                onClick={handleUpdateDatabaseClick}
                disabled={isEmpty(validRows)}
                loading={loading}
              >
                {t('applyUpdate')}
              </Button>
            </>
          )}
        </div>
      </Modal>
      <Modal
        title={t('updateProgress')}
        visible={!isNil(updateDatabaseResults)}
        onCancel={() => setState('updateDatabaseResults', null)}
        footer={null}
        formContainer
      >
        <div className="form-items-container">
          <Form.Item label={t('validRows')} colon={false}>
            <Input value={updateDatabaseResults?.totalValidRows} readOnly />
          </Form.Item>
          <Form.Item label={t('rowsAdded')} colon={false}>
            <Input value={updateDatabaseResults?.addedRows} readOnly />
          </Form.Item>
          <Form.Item label={t('rowsUpdated')} colon={false}>
            <Input value={updateDatabaseResults?.updatedRows} readOnly />
          </Form.Item>
          {updateDatabaseResults && (
            <p>{upperFirst(lowerCase(t(updateDatabaseResults ? 'updateComplete' : 'awaiting')))}</p>
          )}
        </div>
        <div className="form-buttons-container">
          <Button
            type="primary"
            onClick={() => setState('updateDatabaseResults', null)}
            disabled={!updateDatabaseResults}
          >
            {t('close')}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default Component
