import { Popover } from 'antd'
import { isEmpty } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'
import Filter from 'containers/Reports/RequisitionItemsReport/Filter'

export default createListViewComponent({
  entityName: entityNames.requisitionItemsReport,
  filterTemplateType: 'RequisitionItemsReport',
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  tableCellComponents: {
    onOrder: (self, item) => (
      <div>
        {item.onOrder > 0 ? (
          <>
            <span className="mr-6">{item.onOrder}</span>
            <Popover
              title={t('onOrder')}
              content={<span dangerouslySetInnerHTML={{ __html: item.onOrderInfo }} />}
            >
              <span
                className="align-middle"
                style={{ visibility: isEmpty(item.onOrderInfo) ? 'hidden' : 'visible' }}
              >
                <Icon type="Search" />
              </span>
            </Popover>
          </>
        ) : (
          <span className="mr-24">{item.onOrder}</span>
        )}
      </div>
    ),
    onHand: (self, item) => (
      <div>
        {item.onHand > 0 ? (
          <>
            <span className="mr-6">{item.onHand}</span>
            <Popover
              title={t('onhand')}
              content={<span dangerouslySetInnerHTML={{ __html: item.onHandInfo }} />}
            >
              <span
                className="align-middle"
                style={{ visibility: isEmpty(item.onHandInfo) ? 'hidden' : 'visible' }}
              >
                <Icon type="Search" />
              </span>
            </Popover>
          </>
        ) : (
          <span className="mr-24">{item.onHand}</span>
        )}
      </div>
    ),
  },
})(Filter)
