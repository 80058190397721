import { snakeCase } from 'lodash'
import { createChildActions } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { createOrphanedChildPost } from 'helpers/api'

const entityName = entityNames.requisitions
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const childName = 'requisitionItems'
const CHILD_NAME = snakeCase(childName).toUpperCase()

export default {
  ...createChildActions(entityName, childName),

  populateRequisitionItems: (params) => ({
    type: `${ENTITY_NAME}_${CHILD_NAME}_POPULATE`,
    payload: createOrphanedChildPost(entityName, childName, { action: 'populate' })(params),
  }),

  getOnHandCounts: (params) => ({
    type: `${ENTITY_NAME}_${CHILD_NAME}_COUNTS_GET`,
    payload: createOrphanedChildPost(entityName, childName, { action: 'onHandCounts' })(params),
  }),
}
