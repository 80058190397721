import * as React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { DashboardContext } from 'helpers/dashboards'
import { tryParseJSON, HARD_SPACE } from 'helpers/utils'
import { formatValue } from 'helpers/listViews'
import { getScorecardColor, getComparison } from 'helpers/charts'
import Icon from 'elements/Icon'

const Container = styled.div`
  line-height: 1.5;
`

const Title = styled.div`
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: bold;
`

const Primary = styled.div`
  color: ${(props) => props.color};
  font-size: 28px;
`

const Comparison = styled.div`
  color: ${(props) => props.color};
  font-size: 16px;
`

function Component(props) {
  const { theme } = React.useContext(DashboardContext)
  const { chart, data } = props

  const {
    name: chartTitle,
    primaryMetricDisplayFormat: metricDisplayFormat = 'Text',
    primaryMetricTypeName,
    includeComparisonValue,
  } = chart
  const { showTitleOnChart = true, primaryMetricTitle } = tryParseJSON(chart.chartJson, {})

  const title = showTitleOnChart ? chartTitle : primaryMetricTitle || primaryMetricTypeName
  const titleColor = tryParseJSON(theme?.styleJSON, {}).title?.textStyle?.color ?? '#999999'

  const primaryValue = formatValue({
    value: get(data, 'items[0].primaryData[0].metricValue') || '0',
    displayFormat: metricDisplayFormat,
  })
  const primaryColor = getScorecardColor({ theme, chart, data })

  const { comparisonColor, comparisonIcon, comparisonText, differenceText } = getComparison({
    theme,
    chart,
    data,
  })

  return (
    <Container>
      <Title color={titleColor}>{title}</Title>
      <Primary color={primaryColor}>{primaryValue}</Primary>
      {includeComparisonValue ? (
        <Comparison color={comparisonColor}>
          <Icon type={comparisonIcon} color={comparisonColor} bold /> <>{comparisonText}</>{' '}
          <small>{differenceText}</small>
        </Comparison>
      ) : (
        <Comparison>{HARD_SPACE}</Comparison>
      )}
    </Container>
  )
}

export default Component
