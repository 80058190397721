import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import Filter from 'containers/Reports/ItemHistoryReport/Filter'

export default createListViewComponent({
  entityName: entityNames.itemHistoryReport,
  filterTemplateType: 'ItemHistoryReport',
  allowCreate: (self) => false,
  allowDelete: (self) => false,
  allowSelection: (self) => false,
  initialFilterDto: (self) => ({
    dateRange: { dateRangeType: 'YearToDate' },
    periodType: 'Days',
  }),
  tableSummary: (self) => {
    const { itemDescription } = self.props.list ?? {}

    return itemDescription ? `${t('description')}: ${itemDescription}` : ''
  },
})(Filter)
