import * as React from 'react'
import { useImmer } from 'use-immer'
import { set } from 'lodash'
import { Tooltip, Button, Form, Input } from 'antd'
import { useSelector } from 'react-redux'
import selectors from 'selectors'
import { formatUserTime } from 'helpers/dateTime'
import { t } from 'helpers/i18n'
import Modal from 'elements/Modal'
import Icon from 'elements/Icon'

function Component(props) {
  const user = useSelector(selectors.auth.user)
  const { label, title, readOnly, disabled, value, onChange = () => {}, rows = 5 } = props

  const [state, updateState] = useImmer({})
  const { visible, input = '' } = state

  const setState = React.useCallback((name, val) => {
    updateState((draft) => {
      set(draft, name, val)
    })
  }, [])

  function handleModalOk() {
    onChange(`${formatUserTime(new Date().toJSON(), user.userName)}: ${input}${value ? `\n${value}` : ''}`)

    setState('visible', false)
  }

  React.useEffect(() => {
    setState('input', '')
  }, [visible])

  return (
    <>
      <Form.Item label={label}>
        <div className="tofino-label-buttons">
          <Tooltip title={title}>
            <Button
              type="primary"
              size="small"
              onClick={() => setState('visible', true)}
              disabled={readOnly || disabled}
            >
              <Icon type="Add" bold />
            </Button>
          </Tooltip>
        </div>
        <Input.TextArea rows={rows} value={value} readOnly />
      </Form.Item>
      <Modal
        title={title}
        visible={visible}
        okText={t('add')}
        onOk={handleModalOk}
        okButtonProps={{ disabled: !input }}
        onCancel={() => setState('visible', false)}
        width={640}
      >
        <Input.TextArea
          rows={7}
          value={input}
          onChange={(e) => setState('input', e.target.value)}
          autoFocus
        />
      </Modal>
    </>
  )
}

export default Component
