import produce from 'immer'
import { isEmpty } from 'lodash'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default createActions(entityNames.customerGroups, {
  memoizeGetOptions: true,
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.alwaysIncludeCustomerGroupIds =
        draft.alwaysIncludeCustomerGroupIds === false ? false : !isEmpty(draft.customerGroupIds)
    }),
})
