import * as React from 'react'
import produce from 'immer'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import displayMode, { QUANTITIES_BY_SUPPLIER } from 'options/orders/displayModes'
import { createDropdownRender, createSearchSuppliers } from 'helpers/formViews'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchSuppliers = createSearchSuppliers(this)
  }

  componentDidMount() {
    const { locationGroupIds, locationIds, supplierIds } = this.props.filterDto

    Promise.all([
      this.props.getLocationGroups(),
      this.props.getLocations({ locationGroupIds, locationIds }),
      this.props.getSuppliers(supplierIds ? { supplierIds } : undefined),
    ]).catch((error) => showError({ error }))
  }

  render() {
    const { filterDto, suppliers } = this.props

    const isSupplier = filterDto.displayMode === QUANTITIES_BY_SUPPLIER

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <DateRangePicker filterDto={filterDto} setFilterValue={this.setFilterValue} />
          </Col>
          <Col third>
            <Form.Item label={t('locationGroup')}>
              <Select
                onChange={(values) => this.setFilterValue('locationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.locationGroupIds}
                mode="multiple"
              >
                {(this.props.locationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('location')}>
              <Select
                onChange={(value) => this.setFilterValue('locationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.locationIds}
              >
                {(this.props.locations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('displayMode')}>
              <Select
                onChange={(value) => this.setFilterValue('displayMode', value)}
                placeholder={t('quantitiesCombined')}
                value={filterDto.displayMode}
              >
                {Object.entries(displayMode).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('suppliers')}>
              <Select
                onChange={(value) => {
                  this.props.onChange(
                    produce(filterDto, (draft) => {
                      draft.supplierIds = value
                      draft.supplierNames = value.map(
                        (each) => this.props.suppliers.items.find((one) => one.id === each).name
                      )
                    })
                  )
                }}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.supplierIds}
                dropdownRender={createDropdownRender(suppliers)}
                onSearch={this.searchSuppliers}
                disabled={!isSupplier}
                showSearch
              >
                {(suppliers?.items ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
