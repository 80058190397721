export const AUTH_SESSION_KEY = 'auth'
export const INACTIVITY_SESSION_KEY = 'inactivityWarning'
export const INACTIVITY_TIMEOUT = 2 * 60 * 60 * 1000 // 2 hours
export const REFRESH_TOKEN_INTERVAL = 20 * 60 * 1000 // 20 minutes
export const REFRESH_TOKEN_SESSION_KEY = 'tokenRefreshed'
export const USER_LOGGED_IN_SESSION_KEY = 'userLoggedIn'
export const STARTUP_PAGE_STORAGE_KEY = 'startupPage'
export const STARTUP_REDIRECT_SESSION_KEY = 'startupRedirect'
export const LAST_PAGE_VISITED_STORAGE_KEY = 'lastPageVisited'
export const IMPERSONATION_SOURCE_URL = '/customers/customers'
