import loadable from '@loadable/component'

const JobsFormView = loadable(() => import('containers/Jobs/FormView'))
const LocationsFormView = loadable(() => import('containers/Locations/FormView'))

const linkTargets = {
  approvalTreeRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/Customers/FormView/ApprovalTree/FormView')),
    drawerTitleLanguageKey: 'approval',
  },
  assetRecord: {
    formSize: 'lg',
    formComponent: loadable(() => import('containers/Assets/FormView')),
    drawerTitleLanguageKey: 'asset',
  },
  assetToleranceRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/Assets/Tolerances/FormView')),
  },
  customerDetailRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/Products/CustomerDetails/FormView')),
  },
  customerGroupRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/CustomerGroups/FormView')),
  },
  customerRecord: {
    formSize: 'lg',
    formComponent: loadable(() => import('containers/Customers/FormView')),
    drawerTitleLanguageKey: 'customer',
  },
  customMappingRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/CustomMappings/FormView')),
  },
  userRecord: {
    formSize: 'lg',
    formComponent: loadable(() => import('containers/Users/FormView')),
    drawerTitleLanguageKey: 'user',
    getText: (record) => record.userName,
  },
  profileRecord: {
    formSize: 'lg',
    formComponent: loadable(() => import('containers/Profiles/FormView')),
    drawerTitleLanguageKey: 'profile',
  },
  inventoryRecord: {
    formSize: 'lg',
    formComponent: loadable(() => import('containers/Inventory/FormView')),
    drawerTitleLanguageKey: 'inventory',
  },
  overstockInventoryRecord: {
    formSize: 'lg',
    formComponent: loadable(() => import('containers/OverstockInventory/FormView')),
    drawerTitleLanguageKey: 'overstockInventory',
  },
  jobRecord: {
    formSize: 'lg',
    formComponent: JobsFormView,
    drawerTitleLanguageKey: 'job',
  },
  jobTemplateRecord: {
    formSize: 'lg',
    formComponent: (props) => <JobsFormView {...props} isTemplate />,
    drawerTitleLanguageKey: 'jobTemplate',
  },
  jobGroupRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/JobGroups/FormView')),
  },
  jobLabourRateRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/JobLabourRates/FormView')),
  },
  jobReasonGroupRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/JobReasonGroups/FormView')),
  },
  jobReasonRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/JobReasons/FormView')),
  },
  jobRequestRecord: {
    formSize: 'md',
    formComponent: loadable(() => import('containers/JobRequests/FormView')),
    drawerTitleLanguageKey: 'jobRequest',
  },
  jobStatusOptionRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/JobStatusOptions/FormView')),
  },
  locationGroupRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/LocationGroups/FormView')),
  },
  locationRecord: {
    formSize: 'md',
    formComponent: LocationsFormView,
    drawerTitleLanguageKey: 'location',
  },
  fromLocationRecord: {
    formSize: 'md',
    formComponent: (props) => <LocationsFormView {...props} isFromLocation />,
    drawerTitleLanguageKey: 'location',
  },
  toLocationRecord: {
    formSize: 'md',
    formComponent: (props) => <LocationsFormView {...props} isToLocation />,
    drawerTitleLanguageKey: 'location',
  },
  productRecord: {
    formSize: 'lg',
    formComponent: loadable(() => import('containers/Products/FormView')),
    drawerTitleLanguageKey: 'product',
  },
  supplierDetailRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/Products/SupplierDetails/FormView')),
  },
  supplierRecord: {
    formSize: 'lg',
    formComponent: loadable(() => import('containers/Suppliers/FormView')),
    drawerTitleLanguageKey: 'supplier',
  },
  supplierIntegrationRecord: {
    formSize: 'md',
    formComponent: loadable(() => import('containers/SupplierIntegration/FormView')),
    drawerTitleLanguageKey: 'cxmlRecipient',
  },
  ticketRecord: {
    formSize: 'lg',
    formComponent: loadable(() => import('containers/Tickets/FormView')),
    drawerTitleLanguageKey: 'ticket',
  },
  tagListRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/TagLists/FormView')),
  },
  unitOfMeasureRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/UnitsOfMeasure/FormView')),
  },
  userGroupRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/UserGroups/FormView')),
    drawerTitleLanguageKey: 'user',
  },
  userTypeRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/UserTypes/FormView')),
    drawerTitleLanguageKey: 'userType',
  },
  operatorRecord: {
    formSize: 'lg',
    formComponent: loadable(() => import('containers/Operators/FormView')),
    drawerTitleLanguageKey: 'operator',
  },
  operatorGroupRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/OperatorGroups/FormView')),
  },
  operatorScheduleExceptionsRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/Operators/ScheduleExceptions/FormView')),
  },
  overstockLocationRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/OverstockLocations/FormView')),
  },
  formGroupRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/FormGroups/FormView')),
  },
  assetCategoryRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/AssetCategories/FormView')),
  },
  jobChecklistRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/Jobs/Checklist/FormView')),
  },
  labourRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/Jobs/Labour/FormView')),
  },
  assetWarrantyRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/Assets/Warranties/FormView')),
  },
  fileTemplateRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/FileTemplates/FormView')),
  },
  formBatchRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/FormBatches/FormView')),
  },
  assetTaskRecord: {
    formSize: 'md',
    formComponent: loadable(() => import('containers/Assets/Tasks/FormView')),
    drawerTitleLanguageKey: 'task',
  },
  jobTaskRecord: {
    formSize: 'md',
    formComponent: loadable(() => import('containers/JobSchedule/FormView')),
    drawerTitleLanguageKey: 'task',
  },
  reportTaskRecord: {
    formSize: 'md',
    formComponent: loadable(() => import('containers/Reports/Schedule/FormView')),
    drawerTitleLanguageKey: 'task',
  },
  orderRecord: {
    formSize: 'duplex',
    formComponent: loadable(() => import('containers/Orders/FormView')),
    drawerTitleLanguageKey: 'order',
  },
  orderItemRecord: {
    formSize: 'md',
    formComponent: loadable(() => import('containers/Orders/Items/FormView')),
    drawerTitleLanguageKey: 'orderItem',
  },
  issueItemRecord: {
    formSize: 'md',
    formComponent: loadable(() => import('containers/Issue/Items/FormView')),
    drawerTitleLanguageKey: 'issueItem',
  },
  orderSupplierRecord: {
    formSize: 'sm',
    formComponent: loadable(() => import('containers/Orders/Suppliers/FormView')),
    drawerTitleLanguageKey: 'supplierInformation',
  },
  requisitionRecord: {
    formSize: 'duplex',
    formComponent: loadable(() => import('containers/Requisitions/FormView')),
    drawerTitleLanguageKey: 'requisition',
  },
  requisitionItemRecord: {
    formSize: 'md',
    formComponent: loadable(() => import('containers/Requisitions/Items/FormView')),
    drawerTitleLanguageKey: 'requisitionItem',
  },
  labelTemplateRecord: {
    formSize: 'lg',
    formComponent: loadable(() => import('containers/LabelTemplates/FormView')),
  },
  formTemplateRecord: {
    formSize: 'duplex',
    formComponent: loadable(() => import('containers/FormTemplates/FormView')),
    drawerTitleLanguageKey: 'formTemplate',
  },
  formInstanceRecord: {
    formSize: 'xl',
    formComponent: loadable(() => import('containers/FormInstances/FormView')),
    drawerTitleLanguageKey: 'form',
  },
  cycleCountRecord: {
    formSize: 'xl',
    formComponent: loadable(() => import('containers/CycleCounts/FormView')),
    drawerTitleLanguageKey: 'cycleCount',
  },
  locationCountRecord: {
    formSize: 'xl',
    formComponent: loadable(() => import('containers/LocationCounts/FormView')),
    drawerTitleLanguageKey: 'locationCount',
  },
  issueLimitRecord: {
    formSize: 'xl',
    formComponent: loadable(() => import('containers/IssueLimitLists/FormView')),
    drawerTitleLanguageKey: 'issueLimitList',
  },
  issueLimitItemRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/IssueLimitLists/Items/FormView')),
  },
  dashboardTemplateRecord: {
    formSize: 'duplex',
    formComponent: loadable(() => import('containers/DashboardTemplates/FormView')),
    drawerTitleLanguageKey: 'dashboardTemplate',
  },
  dashboardThemeRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/DashboardThemes/FormView')),
    drawerTitleLanguageKey: 'dashboardTheme',
  },
  listRecord: {
    formSize: 'xl',
    formComponent: loadable(() => import('containers/Lists/FormView')),
  },
  listItemRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/Lists/Items/FormView')),
  },
  rfqRecord: {
    formSize: 'duplex',
    formComponent: loadable(() => import('containers/Rfqs/FormView')),
    drawerTitleLanguageKey: 'rfq',
  },
  rfqItemRecord: {
    formSize: 'md',
    formComponent: loadable(() => import('containers/Rfqs/Items/FormView')),
    drawerTitleLanguageKey: 'rfqItem',
  },
  rfqSupplierRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/Rfqs/Suppliers/FormView')),
    drawerTitleLanguageKey: 'supplierInformation',
  },
  customerDetailEntityRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/CustomerDetails/FormView')),
    drawerTitleLanguageKey: 'customerDetail',
  },
  supplierDetailEntityRecord: {
    formSize: 'xs',
    formComponent: loadable(() => import('containers/SupplierDetails/FormView')),
    drawerTitleLanguageKey: 'supplierDetail',
  },
  ...(process.env.NODE_ENV === 'development' ? {} : {}),
}

Object.values(linkTargets).forEach((each) => each.formComponent?.preload?.())

export default linkTargets
