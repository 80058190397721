import { toLower, isEmpty } from 'lodash'
import { getOptionProps } from 'elements/Select'

const listToTree = (list) => {
  const tree = []
  const childrenOf = {}

  let item, id, parentId

  for (let i = 0, length = list.length; i < length; i++) {
    item = list[i]
    id = item.id
    parentId = item.parentId || 0
    childrenOf[id] = childrenOf[id] || []
    item.children = childrenOf[id]

    if (parentId !== 0) {
      childrenOf[parentId] = childrenOf[parentId] || []
      childrenOf[parentId].push(item)
    } else {
      tree.push(item)
    }
  }

  return tree
}

export const getTreeData = (
  list = [],
  render = (each) => <span {...getOptionProps(each)}>{each.name}</span>
) =>
  listToTree(
    list.map((each) => ({
      ...each,
      key: each.id,
      value: each.id,
      title: render(each),
    }))
  )

export function filterTreeNode(inputValue, treeNode) {
  if (toLower(treeNode.props.name).indexOf(toLower(inputValue)) > -1) {
    return true
  }

  if (toLower(treeNode.props.title).indexOf(toLower(inputValue)) > -1) {
    return true
  }

  if (!isEmpty(treeNode.props.children)) {
    return treeNode.props.children.some((node) => filterTreeNode(inputValue, node))
  }

  return false
}
