import { Popover } from 'antd'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { encodeState } from 'helpers/utils'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'
import Filter from 'containers/Reports/InventoryCycleCountFrequencyReport/Filter'

export default createListViewComponent({
  entityName: entityNames.inventoryCycleCountFrequencyReport,
  filterTemplateType: 'inventoryCycleCountFrequencyReport',
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  tableCellComponents: {
    timesSelected: (self, item) => (
      <div>
        {item.timesSelected > 0 ? (
          <>
            <Link
              to={`/inventory/cycleCounts?${encodeState({
                showFilter: true,
                filterDto: {
                  dateRange: {
                    dateRangeType: 'LastNMonths',
                    lastNValue: 240,
                  },
                  cycleCountIds: item.selectedCycleCountIds,
                  alwaysIncludeCycleCountIds: false,
                },
              })}`}
              className="mr-6"
            >
              {item.timesSelected}
            </Link>
            <Popover
              title={t('cycleCounts')}
              content={<span dangerouslySetInnerHTML={{ __html: item.timesSelectedInfo }} />}
            >
              <span
                className="align-middle color-blue"
                style={{ visibility: isEmpty(item.timesSelectedInfo) ? 'hidden' : 'visible' }}
              >
                <Icon type="Search" />
              </span>
            </Popover>
          </>
        ) : (
          <span className="mr-24">{item.timesSelected}</span>
        )}
      </div>
    ),
    timesCounted: (self, item) => (
      <div>
        {item.timesCounted > 0 ? (
          <>
            <Link
              to={`/inventory/cycleCounts?${encodeState({
                showFilter: true,
                filterDto: {
                  dateRange: {
                    dateRangeType: 'LastNMonths',
                    lastNValue: 240,
                  },
                  cycleCountIds: item.countedCycleCountIds,
                  alwaysIncludeCycleCountIds: false,
                },
              })}`}
              className="mr-6"
            >
              {item.timesCounted}
            </Link>
            <Popover
              title={t('cycleCounts')}
              content={<span dangerouslySetInnerHTML={{ __html: item.timesCountedInfo }} />}
            >
              <span
                className="align-middle color-blue"
                style={{ visibility: isEmpty(item.timesCountedInfo) ? 'hidden' : 'visible' }}
              >
                <Icon type="Search" />
              </span>
            </Popover>
          </>
        ) : (
          <span className="mr-24">{item.timesCounted}</span>
        )}
      </div>
    ),
    timesAdjusted: (self, item) => (
      <div>
        {item.timesAdjusted > 0 ? (
          <>
            <Link
              to={`/inventory/cycleCounts?${encodeState({
                showFilter: true,
                filterDto: {
                  dateRange: {
                    dateRangeType: 'LastNMonths',
                    lastNValue: 240,
                  },
                  cycleCountIds: item.adjustedCycleCountIds,
                  alwaysIncludeCycleCountIds: false,
                },
              })}`}
              className="mr-6"
            >
              {item.timesAdjusted}
            </Link>
            <Popover
              title={t('cycleCounts')}
              content={<span dangerouslySetInnerHTML={{ __html: item.timesAdjustedInfo }} />}
            >
              <span
                className="align-middle color-blue"
                style={{ visibility: isEmpty(item.timesAdjustedInfo) ? 'hidden' : 'visible' }}
              >
                <Icon type="Search" />
              </span>
            </Popover>
          </>
        ) : (
          <span className="mr-24">{item.timesAdjusted}</span>
        )}
      </div>
    ),
  },
})(Filter)
