import { Tooltip } from 'antd'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { RED, YELLOW, GREEN } from 'options/colors'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'
import Filter from 'containers/Reports/DataTransferAuditReport/Filter'

const statusOptionsMappings = {
  Success: {
    iconType: 'CheckCircle',
    iconColor: GREEN,
    tooltipTitle: 'success',
  },
  Error: { iconType: 'Error', iconColor: RED, tooltipTitle: 'error' },
  SuccessWithErrors: {
    iconType: 'Warning',
    iconColor: YELLOW,
    tooltipTitle: 'successWithErrors',
  },
}

export default createListViewComponent({
  entityName: entityNames.dataTransferAuditReport,
  filterTemplateType: 'DataTransferAuditReport',
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  tableCellComponents: {
    status: (self, item) => {
      const status = statusOptionsMappings[item.status]

      return status ? (
        <Tooltip title={t(status.tooltipTitle)}>
          <span>
            <Icon type={status.iconType} color={status.iconColor} />
          </span>
        </Tooltip>
      ) : null
    },
  },
})(Filter)
