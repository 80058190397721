import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/JobRequests/Filter'

export default createListViewComponent({
  entityName: entityNames.jobRequests,
  filterTemplateType: 'JobRequest',
  createButtonTextId: 'createJobRequest',
  primaryLinkTarget: 'jobRequestRecord',
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
})(Filter)
