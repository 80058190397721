import * as React from 'react'
import { Form, Button, Upload, Alert } from 'antd'
import { round, isEmpty, uniqBy } from 'lodash'
import { showError } from 'helpers/errors'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import Icon from 'elements/Icon'
import { Row, Col } from 'elements/Grid'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      let encoded = reader.result.replace(/^data:(.*;base64,)?/, '')
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4))
      }
      resolve(encoded)
    }
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
  })
}

function FormView(props) {
  const { onUpload, onSaveAndClose, onCancel, accept, extra, loading, mode = 'default' } = props

  const [fileList, setFileList] = React.useState([])

  async function handleSubmit(e) {
    stopEvent(e)

    try {
      const fileListContents = await Promise.all(fileList.map(getBase64))

      ;(onUpload ?? onSaveAndClose)(
        fileList.map((file, index) => ({
          id: file.name,
          documentId: file.name,
          fileName: file.name,
          fileSize: `${round(file.size / 1024, 2)} KB`,
          fileDate: new Date().toJSON(),
          fileType: file.type,
          fileContents: fileListContents[index],
        }))
      )
    } catch (error) {
      showError({ error })
    }
  }

  const draggerProps = {
    name: 'fileList',
    onRemove: (file) => setFileList(fileList.filter((each) => each !== file)),
    beforeUpload: (file) => {
      if (mode === 'multiple') {
        setFileList((prev) => uniqBy([...prev, file], 'name'))
      } else {
        setFileList([file])
      }

      return false // NOTE: before upload check, return false will stop upload, only for modern browsers
    },
    fileList,
    accept,
    multiple: mode === 'multiple',
  }

  return (
    <Form layout="vertical" colon={false} onSubmit={handleSubmit}>
      <div className="form-items-container">
        <Row>
          <Col>
            {extra && (
              <Alert
                className="mb-12"
                message={extra.message}
                description={<div dangerouslySetInnerHTML={{ __html: extra.description }} />}
                type={extra.type}
                showIcon
              />
            )}
            <Upload.Dragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <Icon type="Inbox" size={48} />
              </p>
              <p className="ant-upload-text">
                {mode === 'multiple' ? t('uploadMultipleFiles') : t('uploadFile')}
              </p>
              <p className="ant-upload-hint">
                {mode === 'multiple' ? t('uploadMultipleHint') : t('uploadHint')}
              </p>
            </Upload.Dragger>
          </Col>
        </Row>
      </div>
      <div className="form-buttons-container">
        <Button onClick={onCancel}>{t('cancel')}</Button>{' '}
        <Button type="primary" htmlType="submit" disabled={isEmpty(fileList)} loading={loading}>
          {t('upload')}
        </Button>
      </div>
    </Form>
  )
}

export default Form.create()(FormView)
