import styled from 'styled-components'
import { Button, Tooltip } from 'antd'
import { isEmpty } from 'lodash'
import Modal from 'elements/Modal'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'
import SelectedRows from 'elements/SelectedRows'

const Container = styled.div`
  .ant-btn {
    border: none;
    box-shadow: none;
    background: white;
    padding: 0 0 0 15px;

    .anticon-loading {
      margin-left: 0;
    }

    &:hover {
      background-color: transparent;
    }
  }
`

function Component(props) {
  const {
    selectedRowKeys = [],
    extraActions,
    allowDelete,
    deleteDisabled,
    allowDeactivate,
    deactivateDisabled,
    deleteLoading,
    deactivateLoading,
    deleteTooltip,
    deactivateTooltip,
  } = props

  function handleDeleteClick() {
    Modal.confirm({
      autoFocusButton: 'ok',
      title: t('confirmDeleteSelectedItems'),
      okType: 'danger',
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk: () => props.onDelete?.(),
    })
  }

  function handleDeactivateClick() {
    Modal.confirm({
      autoFocusButton: 'ok',
      title: t('confirmDeactivateSelectedItems'),
      okType: 'danger',
      okText: t('deactivate'),
      cancelText: t('cancel'),
      onOk: () => props.onDeactivate?.(),
    })
  }

  return (
    <Container>
      <SelectedRows selectedRowKeys={selectedRowKeys} />
      {allowDelete && (
        <Tooltip title={deleteTooltip}>
          <Button
            disabled={isEmpty(selectedRowKeys) || deleteDisabled}
            onClick={handleDeleteClick}
            loading={deleteLoading}
          >
            {!deleteLoading && <Icon type="Delete" />}
            {t('delete')}
          </Button>
        </Tooltip>
      )}
      {allowDeactivate && (
        <Tooltip title={deactivateTooltip}>
          <Button
            disabled={isEmpty(selectedRowKeys) || deactivateDisabled}
            onClick={handleDeactivateClick}
            loading={deactivateLoading}
          >
            {!deactivateLoading && <Icon type="Hotel" />}
            {t('deactivate')}
          </Button>
        </Tooltip>
      )}
      {extraActions}
    </Container>
  )
}

export default Component
