import { snakeCase } from 'lodash'
import { createActions } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.documents
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName),

  getContents: (params) => ({
    type: `${ENTITY_NAME}_ITEM_GET_CONTENTS`,
    payload: createPost(entityName, { action: 'getContents' })(params),
  }),

  downloadItem: (params) => ({
    type: `${ENTITY_NAME}_ITEM_DOWNLOAD`,
    payload: createPost(entityName, {
      action: 'download',
      responseType: 'blob',
    })(params),
  }),

  copyAllObjectDocuments: (params) => ({
    type: `${ENTITY_NAME}_COPY_ALL_OBJECT_DOCUMENTS`,
    payload: createPost(entityName, { action: 'copyAllObjectDocuments' })(params),
  }),
}
