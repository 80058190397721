import { createChildListViewContainer } from 'factories/ChildListView'
import actions from 'actions/documents'
import Component from 'components/Documents/ChildListView'

export default createChildListViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    downloadItem: (params) => dispatch(actions.downloadItem(params)),
  }),
})(Component)
