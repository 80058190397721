import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Tickets/Filter'

export default createListViewComponent({
  entityName: entityNames.tickets,
  filterTemplateType: 'Ticket',
  createButtonTextId: 'createTicket',
  primaryLinkTarget: 'ticketRecord',
  allowSelection: (self) => false,
  allowDelete: (self) => false,
  initialFilterDto: (self) => ({
    dateRangeField: 'Updated',
    dateRange: { dateRangeType: 'YearToDate' },
  }),
})(Filter)
