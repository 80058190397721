import { createListViewContainer } from 'factories/ListView'
import selectors from 'selectors'
import actions from 'actions/formTemplates'
import Component from 'components/FormTemplates'

export default createListViewContainer({
  actions,
  selectors: selectors.formTemplates,
  extraDispatchToProps: (dispatch, props) => ({
    migrateToTofinoCloud: (params) => dispatch(actions.migrateToTofinoCloud(params)),
  }),
})(Component)
