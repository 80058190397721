import { Button, Popover } from 'antd'
import { saveAs } from 'file-saver'
import { isEmpty, get, toLower } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { showError } from 'helpers/errors'
import linkTargets from 'options/linkTargets'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'
import Filter from 'containers/FormInstances/Filter'

export default createListViewComponent({
  entityName: entityNames.formInstances,
  filterTemplateType: 'Form',
  createButtonTextId: 'createFormInstance',
  primaryLinkTarget: 'formInstanceRecord',
  extraRowActions: (self) => (
    <Button
      disabled={isEmpty(self.state.selectedRowKeys)}
      onClick={async () => {
        try {
          self.setState({ downloadAttachmentsLoading: true })

          const response = await self.props.downloadAttachmentsMultiple(self.state.selectedRowKeys)

          saveAs(response.value.data, `${t('attachments')}.zip`)
        } catch (error) {
          showError({ error })
        } finally {
          self.setState({ downloadAttachmentsLoading: false })
        }
      }}
      loading={self.state.downloadAttachmentsLoading}
    >
      {!self.state.downloadAttachmentsLoading && <Icon type="FileDownload" />}
      {t('downloadAttachments')}
    </Button>
  ),
  initialFilterDto: (self) => ({
    dateRangeField: 'All',
    dateRange: { dateRangeType: 'YearToDate' },
    objectType: 'All',
    completionStatus: 'All',
  }),
  getDeleteButtonTooltip: (self) => t('deleteFormsTooltip'),
  tableCellComponents: {
    source: (self, item) => {
      const { source, objectType, objectId } = item

      if (objectType === 'User' || !objectId) {
        return source
      }

      const editDrawerLinkTarget = get(linkTargets, `${toLower(objectType)}Record`)

      return (
        <a
          onClick={() =>
            self.setState({
              editDrawerLinkTarget,
              editDrawerLinkTargetText: source,
              editDrawerLinkTargetRecord: { objectId },
              editDrawerVisible: true,
              editDrawerSaving: false,
              editDrawerLinkTargetReadOnly: true,
            })
          }
        >
          {source}
        </a>
      )
    },
    attachmentCount: (self, item) =>
      item.attachmentCount > 0 ? (
        <Popover
          title={`${item.attachmentCount} ${t(item.attachmentCount > 1 ? 'attachments' : 'attachment')}`}
          content={<span dangerouslySetInnerHTML={{ __html: item.attachmentInfo }} />}
        >
          <span>
            <Icon type="AttachFile" />
          </span>
        </Popover>
      ) : null,
  },
  allowCreate: (self) => !(self.props.tenantTypeIsDcrib && self.props.userIsDcribDistributor),
  allowDelete: (self) => !(self.props.tenantTypeIsDcrib && self.props.userIsDcribDistributor),
})(Filter)
