import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import actions from 'actions/users'
import Component from 'components/Profiles/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  users: selectors.users.selectOptions(state),
})

export default connect(mapStateToProps, { getUsers: actions.getOptions })(Component)
