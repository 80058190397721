import { createListViewContainer } from 'factories/ListView'
import selectors from 'selectors'
import actions from 'actions/customers'
import authActions from 'actions/auth'
import Component from 'components/Customers'

export default createListViewContainer({
  actions,
  selectors: selectors.customers,
  extraDispatchToProps: (dispatch, props) => ({
    loginAsCustomer: (customerId) => dispatch(authActions.loginAsCustomer(customerId)),
  }),
})(Component)
