import { Alert } from 'antd'
import { t } from 'helpers/i18n'
import Page from 'elements/Page'

function Component(props) {
  return (
    <Page title={t('underDevelopment')}>
      <Alert
        message={t('underDevelopment')}
        description={t('underDevelopmentDescription')}
        type="warning"
        showIcon
      />
    </Page>
  )
}

export default Component
