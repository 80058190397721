import produce from 'immer'
import { snakeCase, memoize } from 'lodash'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { createPost, createGet } from 'helpers/api'

const entityName = entityNames.dashboardTemplates
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const getChartMetricTypes = memoize(createGet(entityName, { action: 'chartMetrics' }), JSON.stringify)

export default {
  ...createActions(entityName, {
    useSelectionList: true,
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.metricType = draft.metricType ?? 'All'
        draft.accessType = draft.accessType ?? 'All'
        draft.active = draft.active ?? 'All'
      }),
  }),

  getChartMetricTypes: (params) => ({
    type: `${ENTITY_NAME}_CHART_METRIC_TYPES_GET`,
    payload: getChartMetricTypes(params),
  }),

  getChartTypes: (params) => ({
    type: `${ENTITY_NAME}_CHART_TYPES_GET`,
    payload: createPost(entityName, { action: 'chartTypeSelections' })(params),
  }),

  getDimensionTypes: (params) => ({
    type: `${ENTITY_NAME}_CHART_DIMENSION_TYPES_GET`,
    payload: createPost(entityName, { action: 'dimensionSelections' })(params),
  }),

  getDistributionTypes: (params) => ({
    type: `${ENTITY_NAME}_CHART_DISTRIBUTION_TYPES_GET`,
    payload: createPost(entityName, { action: 'distributionSelections' })(params),
  }),

  getDataSelections: (params) => ({
    type: `${ENTITY_NAME}_DATA_SELECTIONS_GET`,
    payload: createGet(entityName, { action: 'dataSelections' })(params),
  }),
}
