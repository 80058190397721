import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import actions from 'actions/lists/listItems'
import Component from 'components/Lists/Items'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  getSettings: actions.getSettings,
  getChildItems: actions.getChildItems,
  downloadItems: actions.downloadChildItems,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
