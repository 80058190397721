import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import customerGroupActions from 'actions/customerGroups'
import formTemplateActions from 'actions/formTemplates'
import Component from 'components/Reports/FormDataReport/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  formBatches: selectors.formBatches.selectOptions(state, { itemsOnly: false }),
  formTemplates: selectors.formTemplates.selectOptions(state, { itemsOnly: false }),
  customerGroups: selectors.customerGroups.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getFormTemplates: (params) =>
    dispatch(
      formTemplateActions.getOptions({
        pageIndex: 1,
        pageSize: 100,
        active: 'All',
        versionType: 'NonLegacy',
        ...params,
      })
    ),
  getCustomerGroups: (params) => dispatch(customerGroupActions.getOptions(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
