import * as React from 'react'
import { Form, Input, Spin, Button, Tooltip } from 'antd'
import { showError, resourceNotFound, ValidationError } from 'helpers/errors'
import { createLabelFactory, createFieldFactory, createHandleSubmit, isReadOnly } from 'helpers/formViews'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import InputNumber from 'elements/InputNumber'

class FormView extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.handleSubmit = createHandleSubmit(this)
  }

  componentDidMount() {
    this.fetchItem()
  }

  getItemId = () => [this.state.item?.id, this.props.linkTargetRecord?.id].find(Boolean)

  fetchItem = async ({ itemId = this.getItemId() } = {}) => {
    try {
      const responses = await Promise.all([
        itemId
          ? this.props.getItem(itemId).catch((error) => {
              this.props.onCancel?.()
              throw error
            })
          : !this.props.linkTargetRecord
            ? this.props.newItem({})
            : resourceNotFound(this),
        this.props.getSettings({ type: this.props.settingsType }),
      ])

      this.setState({
        item: responses[0].value.data,
        fieldSettings: responses[1].value.data.fieldSettings,
      })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { item, fieldSettings } = this.state

    if (!item || !fieldSettings) {
      return <Spin />
    }

    const createLabel = createLabelFactory(fieldSettings)
    const createFieldDecorator = createFieldFactory(this.props.form, item, fieldSettings)
    const readOnly = isReadOnly(this)

    return (
      <Form layout="vertical" colon={false} onSubmit={readOnly ? stopEvent : this.handleSubmit}>
        <div className="form-items-container">
          <ValidationError errors={this.state.validationErrors} />
          <Form.Item label={createLabel('name')}>
            {createFieldDecorator('name')(<Input readOnly={readOnly} autoFocus />)}
          </Form.Item>
          <Form.Item label={createLabel('min')}>
            {createFieldDecorator('min', {
              extraRules: [
                {
                  validator: (rule, value, callback) => {
                    callback(
                      value > this.props.form.getFieldValue('max') ? [new Error(t('minValidationError'))] : []
                    )
                  },
                },
              ],
            })(<InputNumber readOnly={readOnly} step={0.001} allowClear />)}
          </Form.Item>
          <Form.Item label={createLabel('max')}>
            {createFieldDecorator('max', {
              extraRules: [
                {
                  validator: (rule, value, callback) => {
                    callback(
                      value < this.props.form.getFieldValue('min') ? [new Error(t('maxValidationError'))] : []
                    )
                  },
                },
              ],
            })(<InputNumber readOnly={readOnly} step={0.001} allowClear />)}
          </Form.Item>
          <Form.Item label={createLabel('notify')}>
            {createFieldDecorator('notify')(<Input type="email" readOnly={readOnly} />)}
          </Form.Item>
        </div>
        <div className="form-buttons-container">
          <Button onClick={this.props.onCancel}>{t(readOnly ? 'close' : 'cancel')}</Button>{' '}
          {!readOnly && (
            <Tooltip title={t('childRecordSaveInfo')} placement="topLeft">
              <Button type="primary" htmlType="submit" loading={this.state.saveAndCloseButtonLoading}>
                {!this.props.linkTargetRecord ? t('add') : t('update')}
              </Button>
            </Tooltip>
          )}
        </div>
      </Form>
    )
  }
}

export default Form.create()(FormView)
