import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import tagTypes from 'options/tagTypes'
import PrintLabel from 'containers/PrintLabel'
import Filter from 'containers/TagLists/Filter'

export default createListViewComponent({
  entityName: entityNames.tagLists,
  filterTemplateType: 'TagList',
  createButtonTextId: 'createTag',
  primaryLinkTarget: 'tagListRecord',
  extraRowActions: (self) => (
    <PrintLabel
      type="Tag"
      items={self.state.selectedRowKeys.map((each) => self.state.items.find((one) => one.id === each))}
      parentHasUnsavedChanges={self.hasUnsavedChanges()}
    />
  ),
  initialFilterDto: (self) => ({ tagType: Object.keys(tagTypes)[0] }),
  getFileTemplateTypes: (self) => ['TagLists'],
})(Filter)
