import produce from 'immer'
import { isEmpty } from 'lodash'
import { createActions, normalizeDateRange } from 'helpers/actions'
import entityNames from 'options/entityNames'

const entityName = entityNames.locationCounts

export default {
  ...createActions(entityName, {
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.alwaysIncludeLocationCountIds =
          draft.alwaysIncludeLocationCountIds === false ? false : !isEmpty(draft.locationCountIds)

        normalizeDateRange(draft)
      }),
  }),
}
