import moment from 'moment'
import { Form, Row, Col } from 'antd'
import { t } from 'helpers/i18n'
import Select, { Option } from 'elements/Select'
import { MonthPicker } from 'elements/DatePicker'
import { get } from 'lodash'

function Component(props) {
  const {
    filterDto = {},
    setFilterValue = () => {},
    dateRangeTypeLabel = t('dateRangeType'),
    rangePickerLabel = t('dateRange'),
  } = props

  function handleCustomStartDateChange(value) {
    const customStartDate = moment(value).startOf('month')
    const customEndDate = moment(value).startOf('month').add(12, 'months').subtract(1, 'seconds')

    setFilterValue('dateRange', {
      dateRangeType: 'TwelveMonthPeriod',
      customStartDate: customStartDate.toJSON(),
      customEndDate: customEndDate.toJSON(),
    })
  }

  return (
    <>
      <Form.Item label={dateRangeTypeLabel}>
        <Select
          style={{
            width: '100%',
            marginRight: '0px',
          }}
          value="TwelveMonthPeriod"
          allowClear={false}
          disabled
        >
          {Object.entries({
            TwelveMonthPeriod: 'twelveMonthPeriod',
          }).map(([key, value]) => (
            <Option key={key} value={key}>
              {t(value)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item className="whitespace-nowrap" label={rangePickerLabel}>
        <Row gutter={6}>
          <Col xs={12}>
            <MonthPicker
              value={moment(get(filterDto, 'dateRange.customStartDate', moment().startOf('month')))}
              placeholder={t('startDate')}
              setTimeMode="start"
              onChange={handleCustomStartDateChange}
              allowClear={false}
            />
          </Col>
          <Col xs={12}>
            <MonthPicker
              value={moment(
                get(
                  filterDto,
                  'dateRange.customEndDate',
                  moment().startOf('month').add(12, 'months').subtract(1, 'seconds')
                )
              )}
              placeholder={t('endDate')}
              setTimeMode="end"
              disabled
            />
          </Col>
        </Row>
      </Form.Item>
    </>
  )
}

export default Component
