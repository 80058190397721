import * as React from 'react'
import styled from 'styled-components'
import { set } from 'lodash'
import { Form, Spin } from 'antd'
import Select, { Option } from 'elements/Select'
import { useImmer } from 'use-immer'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import Modal from 'elements/Modal'
import Help from 'elements/Help'
import Checkbox from 'elements/Checkbox'

const Container = styled(Form)`
  .ant-form-item {
    margin-bottom: 12px;

    .ant-form-item-label {
      line-height: 2;
    }
  }
`

function Component(props) {
  const [state, updateState] = useImmer({})

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  function setItemValue(path, value) {
    setState(`item.${path}`, value)
  }

  React.useEffect(() => {
    if (props.visible) {
      updateState((draft) => {
        draft.item = props.values
      })
    }
  }, [props.visible])

  return (
    <Modal
      title={t('settings')}
      visible={props.visible}
      okText={t('save')}
      onOk={() => props.onChange(state.item)}
      onCancel={props.onCancel}
    >
      {state.item && props.sortableColumns ? (
        <Container onSubmit={stopEvent} colon={false}>
          <Form.Item label={t('sortBy')}>
            <Select
              value={state.item.sortBy || null}
              onChange={(value) => setItemValue('sortBy', value || null)}
            >
              <Option value={null}>{t('default')}</Option>
              {props.sortableColumns.map((each) => (
                <Option key={each.dtoFieldName} value={each.dtoFieldName}>
                  {t(each.columnHeadingLanguageKey || each.recordLabelLanguageKey || each.dtoFieldName)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('sortOrder')}>
            <Select
              value={state.item.sortOrder || null}
              onChange={(value) => setItemValue('sortOrder', value || null)}
            >
              <Option value={null}>{t('default')}</Option>
              <Option value="ascend">{t('ascending')}</Option>
              <Option value="descend">{t('descending')}</Option>
            </Select>
          </Form.Item>
          {[
            'showLabelsOnTiles',
            'showWeekends',
            'showCategorization',
            props.allowTotals ? 'showTotals' : null,
          ]
            .filter(Boolean)
            .map((each) => (
              <Form.Item key={each} style={{ marginBottom: 0 }}>
                <Checkbox onChange={(e) => setItemValue(each, e.target.checked)} checked={state.item[each]}>
                  {t(each)} {each === 'showLabelsOnTiles' && <Help title={t('showLabelsOnTilesInfo')} />}
                </Checkbox>
              </Form.Item>
            ))}
        </Container>
      ) : (
        <Spin />
      )}
    </Modal>
  )
}

export default Component
