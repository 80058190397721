import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/OrderAuditReport/Filter'

export default createListViewComponent({
  entityName: entityNames.orderAuditReport,
  filterTemplateType: 'OrderAuditReport',
  initialFilterDto: (self) => ({
    dateRange: { dateRangeType: 'MonthToDate' },
    auditReportType: 'Order',
  }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  getSettingsType: (self) => 'order',
})(Filter)
