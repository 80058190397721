import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import { createChildItemsDispatchToProps } from 'helpers/formViews'
import actions from 'actions/orders'
import locationActions from 'actions/locations'
import userActions from 'actions/users'
import documentActions from 'actions/documents'
import orderItemActions from 'actions/orders/orderItems'
import orderSupplierActions from 'actions/orders/orderSuppliers'
import Component from 'components/Orders/Print'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getSettings: (params) => dispatch(actions.getSettings(params)),
  getOrderSendToList: (params) => dispatch(userActions.getOrderSendToList(params)),
  getDocumentContents: (params) => dispatch(documentActions.getContents(params)),
  getAddressList: (params) => dispatch(locationActions.getAddressList(params)),
  populateOrderItems: (params) => dispatch(orderItemActions.populateOrderItems(params)),
  generatePurchaseOrderNumber: (params) => dispatch(orderSupplierActions.generatePurchaseOrderNumber(params)),
  ...createChildItemsDispatchToProps(dispatch, 'Order', orderItemActions),
  ...createChildItemsDispatchToProps(dispatch, 'OrderSupplier', orderSupplierActions),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
