import { createListModalComponent } from 'factories/ListModal'
import Filter from 'containers/DashboardTemplates/Select/Filter'

export default createListModalComponent({
  getStorageKey: (self) => 'selectDashboardTemplate',
  getSettingsType: (self) => 'selectDashboardTemplate',
  initialFilterDto: (self) => ({
    active: 'Active',
    accessType: 'All',
    metricType: 'All',
  }),
})(Filter)
