import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import selectors from 'selectors'
import userActions from 'actions/users'
import dataTransferActionActions from 'actions/dataTransferActions'
import Component from 'components/Reports/DataTransferAuditReport/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  users: selectors.users.selectOptions(state),
  dataTransferActions: selectors.dataTransferActions.selectOptions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  getUsers: (params) => dispatch(userActions.getOptions({ ...params, active: 'All' })),
  getDataTransferActions: (params) => dispatch(dataTransferActionActions.getOptions(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
