import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { toLocaleCurrency } from 'helpers/i18n'

export default createListViewComponent({
  entityName: entityNames.cbVmiReport,
  filterTemplateType: 'CbVmiReport',
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  allowSearching: (self) => false,
  tableSummary: (self) => `Total: ${toLocaleCurrency(self.props.list?.totalCharge ?? 0)}`,
})()
