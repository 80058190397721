import produce from 'immer'
import { snakeCase, set } from 'lodash'
import { createActions, normalizeDateRange } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.rfqs
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.locationVendingTypes = ['NonVending']

        normalizeDateRange(draft)
      }),
  }),

  saveRfq: (params = {}, options) => ({
    type: `${ENTITY_NAME}_SAVE`,
    payload: createPost(entityName, { action: 'save' })(
      produce(params, (draft) => {
        set(draft, 'rfqItems.creating', (draft.rfqItems?.creating ?? []).reverse())
      }),
      options
    ),
  }),

  sendRfq: (rfqId) => ({
    type: `${ENTITY_NAME}_SEND`,
    payload: createPost(entityName, { action: `${rfqId}/send` })(),
  }),

  resendRfq: (rfqId) => ({
    type: `${ENTITY_NAME}_RESEND`,
    payload: createPost(entityName, { action: `${rfqId}/send` })(),
  }),

  cancelRfq: (rfqId) => ({
    type: `${ENTITY_NAME}_CANCEL`,
    payload: createPost(entityName, { action: `${rfqId}/cancel` })(),
  }),

  closeRfq: (rfqId) => ({
    type: `${ENTITY_NAME}_CLOSE`,
    payload: createPost(entityName, { action: `${rfqId}/close` })(),
  }),

  emailRfq: (params) => ({
    type: `${ENTITY_NAME}_EMAIL`,
    payload: createPost(entityName, { action: 'email' })(params),
  }),

  updatePrices: (params) => ({
    type: `${ENTITY_NAME}_UPDATE_PRICES`,
    payload: createPost(entityName, { action: 'updatePrices' })(params),
  }),

  getSupplierPrices: (rfqId) => ({
    type: `${ENTITY_NAME}_SUPPLIER_PRICES_GET`,
    payload: createPost(entityName, { action: `${rfqId}/supplierPrices` })(),
  }),

  reopenRfq: (rfqId) => ({
    type: `${ENTITY_NAME}_REOPEN`,
    payload: createPost(entityName, { action: 'reopen' })([rfqId]),
  }),
}
