import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { t } from 'helpers/i18n'
import locationVendingTypesOptions from 'options/locations/locationVendingTypes'
import { createGetFieldLabel } from 'helpers/dashboards'
import { isReadOnly } from 'helpers/formViews'
import Select, { Option, getOptionProps } from 'elements/Select'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      const { locationVendingTypes, locationGroupIds, locationIds } = this.props.filterDto

      await Promise.all([
        this.props.getLocationGroups(),
        this.props.getLocations({
          locationVendingTypes,
          locationGroupIds,
          locationIds,
        }),
      ])
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { fields = [], filterDto = {} } = this.props

    const getFieldLabel = createGetFieldLabel(fields)
    const readOnly = isReadOnly(this)

    return (
      <>
        {fields.find((one) => one.type === 'locationGroupIds') && (
          <Form.Item label={getFieldLabel('locationGroupIds')}>
            <Select
              onChange={(values) => this.setFilterValue('locationGroupIds', values)}
              placeholder={t('all')}
              value={filterDto.locationGroupIds}
              mode="multiple"
              readOnly={readOnly}
            >
              {(this.props.locationGroups ?? []).map((each) => (
                <Option key={each.id} value={each.id}>
                  <span {...getOptionProps(each)}>{each.displayName}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'locationVendingTypes') && (
          <Form.Item label={getFieldLabel('locationVendingTypes')}>
            <Select
              onChange={(value) => this.setFilterValue('locationVendingTypes', value)}
              mode="multiple"
              placeholder={t('all')}
              value={filterDto.locationVendingTypes}
              readOnly={readOnly}
            >
              {Object.entries(locationVendingTypesOptions).map(([key, value]) => (
                <Option key={key} value={key}>
                  {t(value)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {fields.find((one) => one.type === 'locationIds') && (
          <Form.Item label={getFieldLabel('locationIds')}>
            <Select
              onChange={(value) => this.setFilterValue('locationIds', value)}
              mode="multiple"
              placeholder={t('all')}
              value={filterDto.locationIds}
              readOnly={readOnly}
            >
              {(this.props.locations ?? []).map((each) => (
                <Option key={each.id} value={each.id}>
                  <span {...getOptionProps(each)}>{each.displayName}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </>
    )
  }
}

export default Form.create()(Filter)
