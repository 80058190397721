import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import statusOptions from 'options/statusOptions'
import { t } from 'helpers/i18n'
import Select, { Option } from 'elements/Select'
import { Row, Col } from 'elements/Grid'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  async componentDidMount() {
    try {
      const response = await this.props.getFilterTemplateTypes()

      this.setState({ filterTemplateTypes: response.value.data.items })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto = {} } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <Form.Item label={t('reportType')}>
              <Select
                onChange={(value) => this.setFilterValue('filterTemplateType', value)}
                placeholder={t('all')}
                value={filterDto.filterTemplateType}
              >
                {(this.state.filterTemplateTypes ?? []).map((each) => (
                  <Option key={each.filterTemplateType} value={each.filterTemplateType}>
                    {t(each.displayLanguageKey)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) => this.setFilterValue('active', value || 'Active')}
                value={filterDto.active || 'Active'}
              >
                {Object.entries(statusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
