import produce from 'immer'
import { snakeCase, memoize, isEmpty, pick } from 'lodash'
import { createActions } from 'helpers/actions'
import { createPost, createGet, ignoreForbiddenWith } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.inventory
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const fetchSettings = memoize(createGet(entityName, { action: 'clientSettings' }), JSON.stringify)

export default {
  ...createActions(entityName, {
    useSelectionList: true,
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.minimumPrice = draft.minimumPrice ?? 0
        draft.maximumPrice = draft.maximumPrice ?? -1
        draft.onHandStatusFilter = draft.onHandStatusFilter ?? 'All'

        draft.alwaysIncludeInventoryIds =
          draft.alwaysIncludeInventoryIds === false ? false : !isEmpty(draft.inventoryIds)
      }),
  }),

  moveItems: (params) => ({
    type: `${ENTITY_NAME}_ITEMS_MOVE`,
    payload: createPost(entityName, { action: 'move' })(
      params.map((each) =>
        pick(each, [
          'barcode',
          'fromLocationId',
          'toLocationId',
          'fromBinLocation',
          'toBinLocation',
          'onHandQuantityToMove',
          'onHand2QuantityToMove',
        ])
      )
    ),
  }),

  getMoveData: (params = []) => ({
    type: `${ENTITY_NAME}_GET_MOVE_DATA`,
    payload: createPost(entityName, { action: 'getMoveData' })(
      params.map((each) =>
        pick(each, [
          'barcode',
          'onHandQuantityToMove',
          'onHand2QuantityToMove',
          'fromLocationId',
          'toLocationId',
        ])
      )
    ),
  }),

  getTransferTableSettings: () => ({
    type: `${ENTITY_NAME}_TRANSFER_TABLE_SETTINGS_GET`,
    payload: fetchSettings({ type: 'transferTable' }),
  }),

  getTransferResultsSettings: (params) => ({
    type: `${ENTITY_NAME}_TRANSFER_RESULTS_SETTINGS_GET`,
    payload: fetchSettings({ type: 'transferResults' }),
  }),

  getAvailability: (params) => ({
    type: `${ENTITY_NAME}_AVAILABILITY_GET`,
    payload: createPost(entityName, { action: 'getAvailability' })(params).catch(
      ignoreForbiddenWith({ data: {} })
    ),
  }),

  setPreferredSupplier: (params = {}) => ({
    type: `${ENTITY_NAME}_SET_PREFERRED_SUPPLIER`,
    payload: createPost(entityName, { action: 'setPreferredSupplier' })(params),
  }),

  rebuildDeficiency: (params) => ({
    type: `${ENTITY_NAME}_REBUILD_DEFICIENCY`,
    payload: createPost(entityName, { action: 'rebuildDeficiency' })(params),
  }),

  updateMinMax: (params = []) => ({
    type: `${ENTITY_NAME}_UPDATE_MIN_MAX`,
    payload: createPost(entityName, { action: 'updateMinMax' })(
      params.map((each) => pick(each, ['id', 'barcode', 'locationId', 'min', 'max']))
    ),
  }),

  duplicate: (params = {}) => ({
    type: `${ENTITY_NAME}_DUPLICATE`,
    payload: createPost(entityName, { action: 'duplicate' })(params),
  }),
}
