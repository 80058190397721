import produce from 'immer'
import { toString as str } from 'lodash'
import { baseReportUrl } from 'config'
import { createActions, normalizeDateRange } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default createActions(entityNames.formDataReport, {
  basePath: 'report/formReports',
  baseUrl: baseReportUrl,
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      normalizeDateRange(
        draft,
        draft.status === 'Incomplete' ? 'AssignedDateOnly' : 'AssignedDateOrCompletedDate'
      )

      console.assert(draft.dateRangeField, 'dateRangeField is undefined')

      draft.dateSelectionType = draft.dateRangeField
    }),
  transformDownloadItemsParams: (params = {}) =>
    produce(params, (draft) => {
      normalizeDateRange(draft)
    }),
  transformGetItemsResponse: (response) =>
    produce(response, (draft) => {
      draft.data.items.forEach((item) => {
        item.formAnswers = item.requestedColumnData.reduce(
          (acc, each) => ({ ...acc, [str(each.formQuestion)]: str(each.formAnswer) }),
          {}
        )
      })
    }),
})
