import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import assetActions from 'actions/assets'
import productActions from 'actions/products'
import locationActions from 'actions/locations'
import Component from 'components/PrintLabel'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getAssetItems: (assetIds) =>
    dispatch(assetActions.getItems({ assetIds, alwaysIncludeAssetIds: false, pageIndex: 0 })),
  getProductItems: (productIds) =>
    dispatch(productActions.getItems({ productIds, alwaysIncludeProductIds: false, pageIndex: 0 })),
  getLocationItems: (productIds) =>
    dispatch(locationActions.getItems({ productIds, alwaysIncludeLocationIds: false, pageIndex: 0 })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
