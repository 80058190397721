import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/AssetUtilizationReport/Filter'

export default createListViewComponent({
  entityName: entityNames.assetUtilizationReport,
  filterTemplateType: 'AssetUtilizationReport',
  initialFilterDto: (self) => ({
    dateRange: { dateRangeType: 'YearToDate' },
    groupByPeriod: 'Day',
    meantimeBasedOn: 'AssetDowntime',
  }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
})(Filter)
