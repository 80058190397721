import * as React from 'react'
import { Form, Input, Spin, Button, Dropdown, Menu } from 'antd'
import {
  camelCase,
  cloneDeep,
  debounce,
  get,
  isEmpty,
  isNil,
  set,
  toLower,
  uniq,
  toString as str,
  pick,
} from 'lodash'
import produce from 'immer'
import pluralize from 'pluralize'
import { Base64 } from 'js-base64'
import * as CSV from 'csv-string'
import { getKeyValueItem, setKeyValueItem } from 'helpers/keyValueStorage'
import { showError, showClientNotifications, showValidationError } from 'helpers/errors'
import { tryParseInt, mergeAll, createResetState, findUniqOrFirst, DEBOUNCE } from 'helpers/utils'
import {
  createDropdownRender,
  createSearchAssetTag,
  createSearchJobTag,
  createSearchOperatorTag,
  filterByValue,
  isReadOnly,
} from 'helpers/formViews'
import { Emitter, stopEvent } from 'helpers/events'
import { SIDE_FORM_ADD_FROM_MODAL_CLOSE } from 'options/events'
import { t } from 'helpers/i18n'
import { PRIVATE_DATABASE } from 'options/products'
import Modal from 'elements/Modal'
import InputNumber from 'elements/InputNumber'
import AutoComplete, { Suffix } from 'elements/AutoComplete'
import Punchout from 'containers/Punchout'
import SelectListItems from 'containers/Lists/Items/Select'
import SelectFileItems from '../containers/FileItems'
import Drawer from 'elements/Drawer'
import FileUpload from 'elements/FileUpload'
import Select, { Option, getOptionProps } from 'elements/Select'
import Help from 'elements/Help'

const tagTypes = ['Location', '2', '1', '3', 'A', 'B', 'C', 'D', 'E', 'Barcode', 'Quantity']

export default ({
    getStorageKey = (self) => self.props.storageKey ?? '__SIDE_FORM__',
    addItemLanguageKey = 'addItem',
    addItemsLanguageKey = 'addItems',
    searchLanguageKey = 'search',
    submitButtonLanguageKey = 'add',
    tagSettingsEnabledSuffix = '',
    dropDownButtonMenuItems = (self) => [],
    dropdownButtonMenuClickHandler = (self) => (e) => {},
  } = {}) =>
  (Search) => {
    class FormView extends React.Component {
      state = {}

      constructor(props) {
        super(props)

        this.searchOperators = createSearchOperatorTag(this)
        this.searchJobs = createSearchJobTag(this)
        this.searchAssets = createSearchAssetTag(this)
        this.searchBarcodes = debounce(this.searchBarcodeTag, DEBOUNCE)

        this.ref1 = React.createRef()
        this.ref2 = React.createRef()
        this.ref3 = React.createRef()
        this.refA = React.createRef()
        this.refB = React.createRef()
        this.refC = React.createRef()
        this.refD = React.createRef()
        this.refE = React.createRef()
        this.refBarcode = React.createRef()
        this.refQuantity = React.createRef()
        this.resetState = createResetState(this, { quantity: null, requireQuantity: true })
        this.saveState = debounce(this.saveState)
      }

      async componentDidMount() {
        const savedState = await getKeyValueItem(getStorageKey(this), {})
        await this.resetState(savedState)
        await this.fetchSettings()

        Emitter.on(SIDE_FORM_ADD_FROM_MODAL_CLOSE, this.handleAddFromModalClose)
      }

      componentWillUnmount() {
        this.saveState.flush()
        Emitter.off(SIDE_FORM_ADD_FROM_MODAL_CLOSE, this.handleAddFromModalClose)
      }

      saveState = () => setKeyValueItem(getStorageKey(this), pick(this.state, ['locationId']))

      fetchSettings = async () => {
        const {
          locationId,
          locationDisplayName,
          jobId,
          jobDisplayName,
          assetId,
          assetDisplayName,
          operatorId,
          operatorDisplayName,
          displayName,
        } = this.state

        try {
          const [barcodes, locations, jobs, operators, assets] = await Promise.all([
            this.props.getBarcodes({ search: displayName, locationIds: locationId ? [locationId] : [] }),
            this.props.getLocations({
              locationVendingTypes: ['NonVending'],
              locationIds: locationId ? [locationId] : [],
              search: locationDisplayName && !locationId ? locationDisplayName : undefined,
            }),
            this.props.user.coreUserSettings.tagSettings[`tag1Enabled${tagSettingsEnabledSuffix}`]
              ? this.props.getJobs({
                  jobIds: jobId ? [jobId] : [],
                  search: jobDisplayName && !jobId ? jobDisplayName : undefined,
                })
              : Promise.resolve(null),
            this.props.user.coreUserSettings.tagSettings[`tag2Enabled${tagSettingsEnabledSuffix}`]
              ? this.props.getOperators({
                  operatorIds: operatorId ? [operatorId] : [],
                  search: operatorDisplayName && !operatorId ? operatorDisplayName : undefined,
                })
              : Promise.resolve(null),
            this.props.user.coreUserSettings.tagSettings[`tag3Enabled${tagSettingsEnabledSuffix}`]
              ? this.props.getAssets({
                  assetIds: assetId ? [assetId] : [],
                  search: assetDisplayName && !assetId ? assetDisplayName : undefined,
                })
              : Promise.resolve(null),
          ])

          const tagListItems = await this.props.getTagListItems({ tagType: 'All' })

          return this.setState({
            barcodes: get(barcodes, 'value.data', {}),
            locations: get(locations, 'value.data', {}),
            jobs: get(jobs, 'value.data', {}),
            operators: get(operators, 'value.data', {}),
            assets: get(assets, 'value.data', {}),
            tagListItems: get(tagListItems, 'value.data.items', []),
          })
        } catch (error) {
          showError({ error })

          return error
        }
      }

      setTagValue = (tagName, value, searching) => {
        const entityName = pluralize(tagName)

        this.setState(
          produce((draft) => {
            set(draft, `${tagName}DisplayName`, value)
            this.props.form.setFieldsValue({ [`${tagName}DisplayName`]: value })

            draft.searching = searching

            if (searching) {
              set(draft, tagName, null)
              set(draft, entityName, null)
              ;['id', 'barcode', 'name', 'number'].forEach((one) => {
                set(draft, `${camelCase(`${tagName}-${one}`)}`, null)
              })
            } else {
              set(draft, `${entityName}.pageIndex`, 1)
              set(draft, `${entityName}.pageCount`, 1)
              set(draft, `${entityName}.recordCount`, 1)
              set(
                draft,
                `${entityName}.items`,
                get(draft, `${entityName}.items`, []).filter((each) => each.displayName === value)
              )

              const item = get(draft, `${entityName}.items`, []).find((one) => one.displayName === value)

              ;['id', 'barcode', 'name', 'number'].forEach((one) => {
                set(draft, `${camelCase(`${tagName}-${one}`)}`, get(item, one))
              })

              set(draft, tagName, item)
            }
          }),
          () => {
            if (!searching) {
              this.saveState()
              return
            }

            switch (entityName) {
              case 'operators':
                this.searchOperators(value)
                break

              case 'assets':
                this.searchAssets(value)
                break

              case 'jobs':
                this.searchJobs(value)
                break

              default:
                break
            }
          }
        )
      }

      setBarcodeValue = (value, searching) =>
        this.setState(
          produce((draft) => {
            draft.displayName = value
            draft.searching = searching

            if (searching) {
              draft.barcodes = null
            } else if (draft.barcodes) {
              draft.barcodes.pageIndex = 1
              draft.barcodes.pageCount = 1
              draft.barcodes.recordCount = 1
              draft.barcodes.items = draft.barcodes.items.filter((each) => each.displayName === value)
            }
          }),
          () => {
            if (searching) {
              this.searchBarcodes(value)
            }
          }
        )

      setLocation = (locationId) =>
        this.setState(
          produce((draft) => {
            draft.location = (draft?.locations?.items ?? []).find((one) => one.id === locationId)
            draft.locationId = locationId
            draft.locationDisplayName = draft.location?.displayName

            draft.displayName = ''
            draft.displayNameItem = null

            this.props.form.resetFields(['displayName'])
          }),
          () => {
            this.saveState()
            this.searchBarcodes('')
          }
        )

      handlePressEnter = (current) => async (e) => {
        if (current === 'Barcode' && !this.props.requireQuantity) {
          return
        }

        stopEvent(e)

        await this.beforeValidateFields()

        const currentIndex = tagTypes.indexOf(current)

        if (currentIndex > -1) {
          tagTypes.some((one, index) => {
            if (index > currentIndex) {
              try {
                get(this, `ref${one}`).current.focus()

                return true
              } catch (error) {
                console.warn(error)
              }
            }
            return false
          })
        }
      }

      beforeValidateFields = () => {
        try {
          this.refQuantity.current.blur()
        } catch (error) {
          //
        }

        this.setState(
          produce((draft) => {
            let fieldsValue = {}

            if (
              (draft.operator?.id || draft.operatorId || draft.operatorDisplayName) &&
              this.props.user.coreUserSettings.tagSettings[`tag2Enabled${tagSettingsEnabledSuffix}`]
            ) {
              const operators = draft.operators?.items ?? []
              const operatorId = draft.operator?.id ?? draft.operatorId

              draft.operator = operatorId
                ? operators.find((one) => one.id === operatorId)
                : findUniqOrFirst(operators, 'displayName', draft.operatorDisplayName)

              draft.operatorDisplayName = draft.operator?.displayName ?? draft.operatorDisplayName
              draft.operatorId = draft.operator?.id ?? draft.operatorId

              fieldsValue = {
                ...fieldsValue,
                operatorDisplayName: draft.operatorDisplayName,
              }
            }

            if (
              (draft.job?.id || draft.jobId || draft.jobDisplayName) &&
              this.props.user.coreUserSettings.tagSettings[`tag1Enabled${tagSettingsEnabledSuffix}`]
            ) {
              const jobs = draft.jobs?.items ?? []
              const jobId = draft.job?.id ?? draft.jobId

              draft.job = jobId
                ? jobs.find((one) => one.id === jobId)
                : findUniqOrFirst(jobs, 'displayName', draft.jobDisplayName)

              draft.jobDisplayName = draft.job?.displayName ?? draft.jobDisplayName
              draft.jobId = draft.job?.id ?? draft.jobId

              fieldsValue = {
                ...fieldsValue,
                jobDisplayName: draft.jobDisplayName,
              }
            }

            if (
              (draft.asset?.id || draft.assetId || draft.assetDisplayName) &&
              this.props.user.coreUserSettings.tagSettings[`tag3Enabled${tagSettingsEnabledSuffix}`]
            ) {
              const assets = draft.assets?.items ?? []
              const assetId = draft.asset?.id ?? draft.assetId

              draft.asset = assetId
                ? assets.find((one) => one.id === assetId)
                : findUniqOrFirst(assets, 'displayName', draft.assetDisplayName)

              draft.assetDisplayName = draft.asset?.displayName ?? draft.assetDisplayName
              draft.assetId = draft.asset?.id ?? draft.assetId

              fieldsValue = {
                ...fieldsValue,
                assetDisplayName: draft.assetDisplayName,
              }
            }

            ;['A', 'B', 'C', 'D', 'E'].forEach((type) => {
              if (this.props.user.coreUserSettings.tagSettings[`tag${type}EnabledAssets`]) {
                const filteredTagListItems = draft.tagListItems
                  .filter((each) => each.tagType === type)
                  .map((each) => each.description)
                  .filter((each) => toLower(each).indexOf(toLower(get(this.state, `tag${type}`))) > -1)

                if (filteredTagListItems.length === 1) {
                  set(draft, `tag${type}`, filteredTagListItems[0])

                  fieldsValue = {
                    ...fieldsValue,
                    [`tag${type}`]: filteredTagListItems[0],
                  }
                }
              }
            })

            if (draft.displayName && draft.barcodes?.items) {
              let item = draft.barcodes.items.find((one) =>
                toLower(one.displayName).startsWith(toLower(draft.displayName))
              )

              if (!item && uniq(draft.barcodes.items.map((each) => each.displayName)).length === 1) {
                item = draft.barcodes.items[0]
              }

              if (item) {
                draft.displayName = item.displayName
                draft.displayNameItem = item

                fieldsValue = { ...fieldsValue, displayName: draft.displayName }
              } else {
                draft.displayNameItem = null
              }
            }

            draft.quantity = tryParseInt(draft.quantity, 1)

            fieldsValue = { ...fieldsValue, quantity: draft.quantity }

            this.props.form.setFieldsValue(fieldsValue)
          }),
          this.saveState
        )

        return new Promise((resolve) => {
          window.setTimeout(resolve, 1000)
        })
      }

      handleSubmit = async (e) => {
        stopEvent(e)

        if (this.state.submitting) {
          return Promise.reject('submitting')
        }

        if (this.state.searching) {
          return Promise.reject('searching')
        }

        try {
          this.setState({ submitting: true })

          const { searchSubmitting } = this.state

          await this.beforeValidateFields()

          return new Promise((resolve, reject) => {
            this.props.form.validateFieldsAndScroll(async (errors, values) => {
              if (!isEmpty(errors)) {
                showValidationError()
                reject()
                return
              }

              try {
                this.props.onSubmitting?.(true)

                const { barcodes, operators, jobs, assets, locations, filterDto, searchedItem, ...rest } =
                  this.state
                const params = mergeAll({}, rest, values) // NOTE: Using merge due to nested dtoFieldNames
                const displayName = str(params?.displayName)
                const barcode =
                  this.state.displayNameItem?.barcode ??
                  get(displayName.match(/^(.*?) - /), '[1]', displayName)
                const catalogTableName = filterDto?.catalogTableName ?? PRIVATE_DATABASE

                if (catalogTableName !== PRIVATE_DATABASE && searchedItem) {
                  await this.props
                    .copyToPrivate({
                      catalogProductIds: [searchedItem.id],
                      catalogTableName,
                      addingItemToOrder: true,
                    })
                    .catch(() => {})
                }

                const success = await this.props.onSubmit({
                  ...params,
                  sourceCatalogName: catalogTableName !== PRIVATE_DATABASE ? catalogTableName : undefined,
                  quantity: params.quantity || 1,
                  barcode,
                  operator: params.operatorId
                    ? (operators?.items ?? []).find((one) => one.id === params.operatorId)
                    : null,
                  job: params.jobId ? (jobs?.items ?? []).find((one) => one.id === params.jobId) : null,
                  asset: params.assetId
                    ? (assets?.items ?? []).find((one) => one.id === params.assetId)
                    : null,
                  location: params.locationId
                    ? (locations?.items ?? []).find((one) => one.id === params.locationId)
                    : null,
                })

                if (success) {
                  this.setState(
                    produce((draft) => {
                      draft.displayName = ''
                      draft.displayNameItem = null
                      draft.quantity = null

                      if (!this.props.quickscan && !searchSubmitting) {
                        draft.operatorDisplayName = ''
                        draft.operator = null
                        draft.operatorId = null
                        draft.jobDisplayName = ''
                        draft.job = null
                        draft.jobId = null
                        draft.assetDisplayName = ''
                        draft.asset = null
                        draft.assetId = null
                        draft.tagA = ''
                        draft.tagB = ''
                        draft.tagC = ''
                        draft.tagD = ''
                        draft.tagE = ''
                      }
                    }),
                    () => {
                      this.props.form.resetFields(
                        !this.props.quickscan && !searchSubmitting
                          ? [
                              'displayName',
                              'quantity',
                              'assetDisplayName',
                              'jobDisplayName',
                              'operatorDisplayName',
                              'tagA',
                              'tagB',
                              'tagC',
                              'tagD',
                              'tagE',
                            ]
                          : ['displayName', 'quantity']
                      )

                      this.searchBarcodes('')

                      if (!searchSubmitting) {
                        if (this.props.quickscan) {
                          this.refBarcode.current?.focus?.()
                        } else {
                          tagTypes
                            .filter((each) => each !== 'Location')
                            .some((one) => {
                              try {
                                get(this, `ref${one}`).current.focus()

                                return true
                              } catch (error) {
                                console.warn(error)
                              }

                              return false
                            })
                        }
                      }
                    }
                  )
                }

                resolve()
              } catch (error) {
                showError({ error })

                reject()
              } finally {
                this.props.onSubmitting?.(false)
                this.saveState()
              }
            })
          })
        } finally {
          this.setState({ submitting: false })
        }
      }

      handleSearchSubmit = () =>
        this.setState(
          produce((draft) => {
            draft.searchItemAdded = true
            draft.displayName = draft.searchedItem.displayName
            draft.displayNameItem = draft.searchedItem

            if (draft.searchedItem.locationId) {
              draft.locationId = draft.searchedItem.locationId
              draft.location = (draft.locations?.items ?? []).find((one) => one.id === draft.locationId)
              draft.locationDisplayName = get(draft.location, 'displayName', '')
            }

            draft.searching = true
            draft.searchSubmitting = true

            this.props.form.setFieldsValue({
              displayName: draft.displayName,
              locationId: draft.locationId,
            })
          }),
          async () => {
            try {
              await this.searchBarcodeTag(this.state.searchedItem.barcode)
              await this.handleSubmit()
            } catch (error) {
              showError({ error })
            } finally {
              this.setState({ searchSubmitting: false }, () => this.saveState())
            }
          }
        )

      searchBarcodeTag = async (search) => {
        try {
          this.setState({ searching: true })

          const { locationId, locations } = this.state
          const location = (locations?.items ?? []).find((one) => one.id === locationId)
          const response = await this.props.getBarcodes({
            search,
            locationIds: location ? [location.id] : [],
          })

          this.setState({ barcodes: response.value.data })
        } catch (error) {
          showError({ error })
        } finally {
          this.setState({ searching: false })
        }
      }

      validateNonItemFields = async () => {
        await this.beforeValidateFields()

        return new Promise((resolve, reject) => {
          this.props.form.validateFieldsAndScroll(
            [
              'locationId',
              'operatorDisplayName',
              'jobDisplayName',
              'assetDisplayName',
              'tagA',
              'tagB',
              'tagC',
              'tagD',
              'tagE',
            ],
            (errors, values) => {
              if (isEmpty(errors)) {
                resolve()
              } else {
                showValidationError()
                reject()
              }
            }
          )
        })
      }

      handleSearchButtonClick = async () => {
        try {
          await this.validateNonItemFields()

          this.setState({ searchVisible: true, searchedItem: null, searchItemAdded: false })
        } catch (error) {
          showError({ error })
        }
      }

      handleSearchCancel = () => {
        const { searchItemAdded } = this.state

        this.setState(
          produce((draft) => {
            draft.searchVisible = false
            draft.searchedItem = null

            if (!this.props.quickscan && searchItemAdded) {
              draft.operatorDisplayName = ''
              draft.operator = null
              draft.operatorId = null
              draft.jobDisplayName = ''
              draft.job = null
              draft.jobId = null
              draft.assetDisplayName = ''
              draft.asset = null
              draft.assetId = null
              draft.tagA = ''
              draft.tagB = ''
              draft.tagC = ''
              draft.tagD = ''
              draft.tagE = ''
            }
          }),
          () =>
            this.props.form.resetFields(
              !this.props.quickscan && searchItemAdded
                ? [
                    'displayName',
                    'quantity',
                    'assetDisplayName',
                    'jobDisplayName',
                    'operatorDisplayName',
                    'tagA',
                    'tagB',
                    'tagC',
                    'tagD',
                    'tagE',
                  ]
                : ['displayName', 'quantity']
            )
        )
      }

      handlePunchoutClick = async () => {
        try {
          await this.validateNonItemFields()

          this.setState({ punchoutVisible: true })
        } catch (error) {
          showError({ error })
        }
      }

      handleAddFromListClick = async () => {
        try {
          await this.validateNonItemFields()

          this.setState({ addFromListVisible: true, addFromListItems: null })
        } catch (error) {
          showError({ error })
        }
      }

      handleAddFromFileClick = async () => {
        try {
          await this.validateNonItemFields()

          this.setState({
            fileUploadDrawerVisible: true,
            selectFileItemsVisible: false,
            selectFileListItems: null,
            addFromListItems: null,
          })
        } catch (error) {
          showError({ error })
        }
      }

      handleFileUpload = async (fileList = []) => {
        const { locationId } = this.state

        this.setState({
          fileUploadDrawerVisible: false,
          selectFileItemsVisible: true,
          selectFileListItems: null,
          addFromListItems: null,
        })

        try {
          const listItems = CSV.parse(Base64.decode(fileList[0].fileContents)).map((parts) => ({
            barcode: parts[0],
            quantity: tryParseInt(parts[1], 1),
          }))

          const response = await this.props.populateListItems({
            domainObjectType: this.props.domainObjectType,
            locationId,
            listItems,
            listType: 'Product',
          })

          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          const seed = Date.now()
          const populatedItems = get(response, 'value.data.items', []).map((each, index) => ({
            ...each,
            id: each.id > 0 ? seed + index : (seed + index) * -1,
          }))

          this.setState({ selectFileListItems: isEmpty(populatedItems) ? [] : populatedItems })
        } catch (error) {
          showError({ error })

          this.setState({ selectFileListItems: [] })
        }
      }

      handleAddFromModalClose = () =>
        this.setState({
          fileUploadDrawerVisible: false,
          selectFileItemsVisible: false,
          selectFileListItems: null,
          addFromListVisible: false,
          addFromListItems: null,
        })

      render() {
        const { user, customer } = this.props

        if (isNil(this.state.locations)) {
          return (
            <div className="form-items-container">
              <Spin />
            </div>
          )
        }

        const readOnly = isReadOnly(this)

        return (
          <>
            <Form layout="vertical" colon={false} onSubmit={readOnly ? stopEvent : this.handleSubmit}>
              <div className="form-items-container">
                <Form.Item label={t('location')}>
                  {this.props.form.getFieldDecorator('locationId', {
                    rules: [
                      {
                        required: true,
                        message: t('requiredField'),
                      },
                    ],
                    initialValue: this.state.locationId,
                  })(
                    <Select onChange={(value) => this.setLocation(value)} allowClear={false}>
                      {(this.state.locations?.items ?? []).map((each) => (
                        <Option key={each.id} value={each.id}>
                          <span {...getOptionProps(each)}>{each.displayName}</span>
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                {user.coreUserSettings.tagSettings[`tag2Enabled${tagSettingsEnabledSuffix}`] && (
                  <Form.Item label={customer.tagSettings.tag2}>
                    {this.props.form.getFieldDecorator('operatorDisplayName', {
                      rules: [
                        ...(customer.tagSettings.tag2Required
                          ? [
                              {
                                required: true,
                                message: t('requiredField'),
                              },
                            ]
                          : []),
                        ...(customer.tagSettings.tag2Restricted
                          ? [
                              {
                                validator: (rule, value, callback) => {
                                  callback(
                                    isEmpty(value) ||
                                      (this.state.operators?.items ?? [])
                                        .map((each) => each.displayName)
                                        .includes(value)
                                      ? []
                                      : [new Error(t('invalidValueEntered'))]
                                  )
                                },
                              },
                            ]
                          : []),
                      ],
                      initialValue: this.state.operatorDisplayName,
                      validateTrigger: false,
                    })(
                      <AutoComplete
                        ref={this.ref2}
                        defaultActiveFirstOption={false}
                        dataSource={(this.state.operators?.items ?? []).map((each) => each.displayName)}
                        dropdownRender={createDropdownRender(this.state.operators)}
                        onSearch={(value) => this.setTagValue('operator', value, true)}
                        onSelect={(value) => this.setTagValue('operator', value, false)}
                      >
                        <Input
                          onPressEnter={this.handlePressEnter('2')}
                          autoComplete="off"
                          suffix={<Suffix />}
                          allowClear
                        />
                      </AutoComplete>
                    )}
                  </Form.Item>
                )}
                {user.coreUserSettings.tagSettings[`tag1Enabled${tagSettingsEnabledSuffix}`] && (
                  <Form.Item label={customer.tagSettings.tag1}>
                    {this.props.form.getFieldDecorator('jobDisplayName', {
                      rules: [
                        ...(customer.tagSettings.tag1Required
                          ? [
                              {
                                required: true,
                                message: t('requiredField'),
                              },
                            ]
                          : []),
                        ...(customer.tagSettings.tag1Restricted
                          ? [
                              {
                                validator: (rule, value, callback) => {
                                  callback(
                                    isEmpty(value) ||
                                      (this.state.jobs?.items ?? [])
                                        .map((each) => each.displayName)
                                        .includes(value)
                                      ? []
                                      : [new Error(t('invalidValueEntered'))]
                                  )
                                },
                              },
                            ]
                          : []),
                      ],
                      initialValue: this.state.jobDisplayName,
                      validateTrigger: false,
                    })(
                      <AutoComplete
                        ref={this.ref1}
                        defaultActiveFirstOption={false}
                        dataSource={(this.state.jobs?.items ?? []).map((each) => each.displayName)}
                        dropdownRender={createDropdownRender(this.state.jobs)}
                        onSearch={(value) => this.setTagValue('job', value, true)}
                        onSelect={(value) => this.setTagValue('job', value, false)}
                      >
                        <Input
                          onPressEnter={this.handlePressEnter('1')}
                          autoComplete="off"
                          suffix={<Suffix />}
                          allowClear
                        />
                      </AutoComplete>
                    )}
                  </Form.Item>
                )}
                {user.coreUserSettings.tagSettings[`tag3Enabled${tagSettingsEnabledSuffix}`] && (
                  <Form.Item label={customer.tagSettings.tag3}>
                    {this.props.form.getFieldDecorator('assetDisplayName', {
                      rules: [
                        ...(customer.tagSettings.tag3Required
                          ? [
                              {
                                required: true,
                                message: t('requiredField'),
                              },
                            ]
                          : []),
                        ...(customer.tagSettings.tag3Restricted
                          ? [
                              {
                                validator: (rule, value, callback) => {
                                  callback(
                                    isEmpty(value) ||
                                      (this.state.assets?.items ?? [])
                                        .map((each) => each.displayName)
                                        .includes(value)
                                      ? []
                                      : [new Error(t('invalidValueEntered'))]
                                  )
                                },
                              },
                            ]
                          : []),
                      ],
                      initialValue: this.state.assetDisplayName,
                      validateTrigger: false,
                    })(
                      <AutoComplete
                        ref={this.ref3}
                        defaultActiveFirstOption={false}
                        dataSource={(this.state.assets?.items ?? []).map((each) => each.displayName)}
                        dropdownRender={createDropdownRender(this.state.assets)}
                        onSearch={(value) => this.setTagValue('asset', value, true)}
                        onSelect={(value) => this.setTagValue('asset', value, false)}
                      >
                        <Input
                          onPressEnter={this.handlePressEnter('3')}
                          autoComplete="off"
                          suffix={<Suffix />}
                          allowClear
                        />
                      </AutoComplete>
                    )}
                  </Form.Item>
                )}
                {['A', 'B', 'C', 'D', 'E'].map(
                  (type) =>
                    user.coreUserSettings.tagSettings[`tag${type}Enabled${tagSettingsEnabledSuffix}`] && (
                      <Form.Item key={type} label={customer.tagSettings[`tag${type}`]}>
                        {this.props.form.getFieldDecorator(`tag${type}`, {
                          rules: [
                            ...(customer.tagSettings[`tag${type}Required`]
                              ? [
                                  {
                                    required: true,
                                    message: t('requiredField'),
                                  },
                                ]
                              : []),
                            ...(customer.tagSettings[`tag${type}Restricted`]
                              ? [
                                  {
                                    type: 'enum',
                                    enum: (this.state.tagListItems ?? [])
                                      .filter((each) => each.tagType === type)
                                      .map((each) => each.description),
                                    message: t('invalidValueEntered'),
                                  },
                                ]
                              : []),
                          ],
                          initialValue: get(this.state, `tag${type}`),
                          validateTrigger: false,
                        })(
                          customer.tagSettings[`tag${type}ShowList`] ? (
                            <AutoComplete
                              ref={get(this, `ref${type}`)}
                              defaultActiveFirstOption={false}
                              dataSource={(this.state.tagListItems ?? [])
                                .filter((each) => each.tagType === type)
                                .map((each) => each.description)}
                              filterOption={filterByValue}
                              onChange={(value) =>
                                this.setState({ [`tag${type}`]: value }, () =>
                                  this.props.form.setFieldsValue({ [`tag${type}`]: value })
                                )
                              }
                            >
                              <Input
                                onPressEnter={this.handlePressEnter(type)}
                                autoComplete="off"
                                suffix={<Suffix />}
                                allowClear
                              />
                            </AutoComplete>
                          ) : (
                            <Input
                              ref={get(this, `ref${type}`)}
                              onPressEnter={this.handlePressEnter(type)}
                              autoComplete="off"
                              onChange={(e) =>
                                this.setState(
                                  produce((draft) => {
                                    set(draft, `tag${type}`, e.target.value)
                                  })
                                )
                              }
                              allowClear
                            />
                          )
                        )}
                      </Form.Item>
                    )
                )}
                <Form.Item
                  label={
                    this.state.locationId ? (
                      t('item')
                    ) : (
                      <span>
                        {t('item')} <Help title={t('selectLocationFirst')} placement="topRight" />
                      </span>
                    )
                  }
                  required
                >
                  {this.props.form.getFieldDecorator('displayName', {
                    rules: [
                      {
                        required: true,
                        message: t('requiredField'),
                      },
                    ],
                    initialValue: this.state.displayName,
                    validateTrigger: false,
                  })(
                    <AutoComplete
                      ref={this.refBarcode}
                      defaultActiveFirstOption={false}
                      dataSource={(this.state.barcodes?.items ?? []).map((each) => each.displayName)}
                      dropdownRender={createDropdownRender(this.state.barcodes)}
                      onSearch={(value) => this.setBarcodeValue(value, true)}
                      onSelect={(value) => this.setBarcodeValue(value, false)}
                      disabled={!this.state.locationId}
                    >
                      <Input
                        onPressEnter={this.handlePressEnter('Barcode')}
                        autoComplete="off"
                        suffix={<Suffix />}
                        disabled={!this.state.locationId}
                        allowClear
                      />
                    </AutoComplete>
                  )}
                </Form.Item>
                {this.props.requireQuantity && (
                  <Form.Item label={t('quantity')}>
                    {this.props.form.getFieldDecorator('quantity', {
                      rules: [
                        {
                          required: true,
                          message: t('requiredField'),
                        },
                      ],
                      validateTrigger: false,
                    })(
                      <InputNumber
                        ref={this.refQuantity}
                        onChange={(value) =>
                          this.setState({ quantity: Math.max(value, this.props.quantityMin ?? 1) })
                        }
                        min={this.props.quantityMin ?? 1}
                        allowClear
                      />
                    )}
                  </Form.Item>
                )}
              </div>
              <div className="form-buttons-container">
                {isEmpty(dropDownButtonMenuItems(this)) ? (
                  <Button
                    onClick={this.handleSearchButtonClick}
                    className="mr-6"
                    disabled={this.props.disabled}
                  >
                    {t(searchLanguageKey)}
                  </Button>
                ) : (
                  <Dropdown.Button
                    trigger={['hover', 'click']}
                    overlay={
                      <Menu onClick={dropdownButtonMenuClickHandler(this)}>
                        {dropDownButtonMenuItems(this)}
                      </Menu>
                    }
                    placement="topRight"
                    onClick={this.handleSearchButtonClick}
                    className="mr-6"
                    disabled={this.props.disabled || this.state.searching}
                  >
                    {t(searchLanguageKey)}
                  </Dropdown.Button>
                )}
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.submitting || (this.props.loading && !this.state.searchSubmitting)}
                  disabled={this.props.disabled || this.state.searching || isNil(this.state.barcodes)}
                >
                  {t(submitButtonLanguageKey)}
                </Button>
              </div>
            </Form>
            {!isNil(Search) && (
              <Modal
                title={t(searchLanguageKey)}
                visible={this.state.searchVisible}
                onCancel={this.handleSearchCancel}
                footer={
                  <>
                    <Button onClick={this.handleSearchCancel}>{t('close')}</Button>
                    <Button
                      onClick={this.handleSearchSubmit}
                      loading={this.state.searchSubmitting}
                      type="primary"
                      disabled={isNil(this.state.searchedItem)}
                    >
                      {t(addItemLanguageKey)}
                    </Button>
                  </>
                }
                width={992}
              >
                <Search
                  initialFilterDto={{
                    locationIds: this.state.locationId ? [this.state.locationId] : [],
                  }}
                  onSelect={(values, filterDto) => this.setState({ searchedItem: values[0], filterDto })}
                />
              </Modal>
            )}
            {this.state.punchoutVisible && this.props.parentRecord?.id && (
              <Punchout
                domainObjectType={this.props.domainObjectType}
                onCancel={() => this.setState({ punchoutVisible: false })}
                onComplete={() => this.setState({ punchoutVisible: false }, this.props.onPunchout)}
                parentRecord={{ ...this.props.parentRecord }}
                sideFormState={{ ...this.state }}
              />
            )}
            <Modal
              title={t('addFromList')}
              visible={this.state.addFromListVisible}
              onCancel={this.handleAddFromModalClose}
              footer={
                <>
                  <Button onClick={this.handleAddFromModalClose}>{t('close')}</Button>
                  <Button
                    onClick={() => this.props.onAddFromList?.(cloneDeep(this.state))}
                    loading={this.props.addFromListLoading}
                    type="primary"
                    disabled={isEmpty(this.state.addFromListItems)}
                  >
                    {t(addItemsLanguageKey)}
                  </Button>
                </>
              }
              width={992}
            >
              <SelectListItems
                onSelect={(values) => this.setState({ addFromListItems: values })}
                settingsType="productListProcurementRecord"
                filterProps={{
                  locationId: this.state.locationId,
                  domainObjectType: this.props.domainObjectType,
                  entityType: 'Procurement',
                  listType: 'Product',
                }}
              />
            </Modal>
            <Modal
              title={t('addFromFile')}
              visible={this.state.selectFileItemsVisible}
              onCancel={this.handleAddFromModalClose}
              footer={
                <>
                  <Button onClick={this.handleAddFromModalClose}>{t('close')}</Button>
                  <Button
                    onClick={() => this.props.onAddFromList?.(cloneDeep(this.state))}
                    loading={this.props.addFromListLoading}
                    type="primary"
                    disabled={isEmpty(this.state.addFromListItems)}
                  >
                    {t(addItemsLanguageKey)}
                  </Button>
                </>
              }
              width={992}
            >
              {isNil(this.state.selectFileListItems) ? (
                <Spin />
              ) : (
                <SelectFileItems
                  items={this.state.selectFileListItems ?? []}
                  onSelect={(values) => this.setState({ addFromListItems: values })}
                  settingsType="fileItemList"
                  mode="multiple"
                />
              )}
            </Modal>
            <Drawer
              title={t('addFromFile')}
              size="xs"
              visible={this.state.fileUploadDrawerVisible}
              onClose={this.handleAddFromModalClose}
            >
              <FileUpload
                accept=".csv,text/csv"
                onUpload={this.handleFileUpload}
                onCancel={this.handleAddFromModalClose}
                extra={{
                  type: 'info',
                  message: t('fileRequirements'),
                  description: t('procurementUploadInfo'),
                }}
              />
            </Drawer>
          </>
        )
      }
    }

    return Form.create()(FormView)
  }
