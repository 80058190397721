import produce from 'immer'
import { isEmpty } from 'lodash'
import { createActions, normalizeDateRange } from 'helpers/actions'
import entityNames from 'options/entityNames'

export default createActions(entityNames.formBatches, {
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.alwaysIncludeFormBatchIds =
        draft.alwaysIncludeFormBatchIds === false ? false : !isEmpty(draft.formBatchIds)

      normalizeDateRange(draft)
    }),
})
