import { Layout, Alert } from 'antd'
import { Helmet } from 'react-helmet'
import { t } from 'helpers/i18n'

function Component(props) {
  return (
    <Layout style={{ minHeight: '100vh', padding: '24px' }}>
      <Helmet>
        <title>{t('resourceNotFound')} - TRMS</title>
      </Helmet>
      <Alert
        message={t('resourceNotFound')}
        description={t('requestedResourceNotFound')}
        type="error"
        showIcon
      />
    </Layout>
  )
}

export default Component
