import { getPageTitle } from 'helpers/auth'
import FormView from 'containers/PrintLabels/FormView'
import Page from 'elements/Page'

function Component(props) {
  const pageTitle = getPageTitle()

  return (
    <Page title={pageTitle} scrollable>
      <div style={{ width: '400px' }}>
        <FormView title={pageTitle} />
      </div>
    </Page>
  )
}

export default Component
