import produce from 'immer'
import { snakeCase, memoize, isEmpty } from 'lodash'
import { createActions } from 'helpers/actions'
import { createPost, createGet } from 'helpers/api'
import entityNames from 'options/entityNames'
import { PRIVATE_DATABASE } from 'options/products'

const entityName = entityNames.products
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const getCatalogs = memoize(createGet(entityName, { action: 'catalogs' }), JSON.stringify)

const getSupplierDetailsSettings = memoize(
  createGet(entityName, { action: 'supplierDetails/clientSettings' }),
  JSON.stringify
)

export default {
  ...createActions(entityName, {
    useSelectionList: true,
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        if (draft.catalogTableName === PRIVATE_DATABASE || isEmpty(draft.catalogTableName)) {
          draft.useReferenceCatalog = false
          draft.catalogTableName = ''
        } else {
          draft.useReferenceCatalog = true
        }

        draft.alwaysIncludeProductIds =
          draft.alwaysIncludeProductIds === false ? false : !isEmpty(draft.productIds)
        draft.alwaysIncludeProductBarcodes =
          draft.alwaysIncludeProductBarcodes === false ? false : !isEmpty(draft.productBarcodes)
      }),
  }),

  findByBarcode: (params) => ({
    type: `${ENTITY_NAME}_BARCODE_FIND`,
    payload: createPost(entityName, { action: 'findByBarcode' })(params),
  }),

  getCatalogs: () => ({
    type: `${ENTITY_NAME}_CATALOGS_GET`,
    payload: getCatalogs(),
  }),

  setPreferredSupplier: (params = {}) => ({
    type: `${ENTITY_NAME}_SET_PREFERRED_SUPPLIER`,
    payload: createPost(entityName, { action: 'setPreferredSupplier' })(params),
  }),

  setLinkedCatalog: (params = {}) => ({
    type: `${ENTITY_NAME}_SET_LINKED_CATALOG`,
    payload: createPost(entityName, { action: 'setLinkedCatalog' })(params),
  }),

  refreshCatalogItems: (params = {}) => ({
    type: `${ENTITY_NAME}_REFRESH_CATALOG_ITEMS`,
    payload: createPost(entityName, {
      action: `refreshCatalogItems/${encodeURIComponent(params.linkedCatalogTableName)}`,
    })(),
  }),

  copyToPrivate: (params) => ({
    type: `${ENTITY_NAME}_COPY_TO_PRIVATE`,
    payload: createPost(entityName, { action: 'copyToPrivate' })(params),
  }),

  migrate: (params) => ({
    type: `${ENTITY_NAME}_MIGRATE`,
    payload: createPost(entityName, { action: 'migrate' })(params),
  }),

  getSupplierDetailsSettings: (params) => ({
    type: `${ENTITY_NAME}_SUPPLIER_DETAILS_SETTINGS_GET`,
    payload: getSupplierDetailsSettings(params),
  }),
}
