import { Button, Popover, message } from 'antd'
import { isEmpty, isNil } from 'lodash'
import produce from 'immer'
import { createChildListViewComponent } from 'factories/ChildListView'
import entityNames from 'options/entityNames'
import { t } from 'helpers/i18n'
import { DEBOUNCE } from 'helpers/utils'
import Icon from 'elements/Icon'
import InputNumber from 'elements/InputNumber'
import Filter from 'containers/Orders/Items/Filter'

export default createChildListViewComponent({
  filterItems: (self) => (each) => {
    const { supplierId } = self.state.filterDto ?? {}
    const usagePurchase = self.state.filterDto?.usagePurchase ?? 'All'

    if (!isNil(supplierId) && each.supplierId !== supplierId) {
      return false
    }

    if (usagePurchase === 'UsageOnly' && each.quantityUsed === 0 && each.quantityUsed2 === 0) {
      return false
    }

    if (usagePurchase === 'PurchaseOnly' && each.quantityOrdered === 0) {
      return false
    }

    return true
  },
  entityName: entityNames.orders,
  childName: 'orderItems',
  primaryLinkTarget: 'issueItemRecord',
  allowCreate: (self) => false,
  allowCustomize: (self) => true,
  extraRowActions: (self) => (
    <>
      <Button disabled={self.state.selectedRowKeys.length !== 1} onClick={() => message.warn(t('underDevelopment'))}>
        <Icon type="InsertChart" />
        {t('itemHistoryReport')}
      </Button>
    </>
  ),
  tableCellComponents: {
    quantityIssued: (self, item) => (
      <div className="whitespace-nowrap pr-12">
        <InputNumber
          value={item.quantityIssued}
          onChange={async (value) => {
            try {
              await self.props.onChange(
                produce(self.props.items, (draft) => {
                  draft.find((one) => one.id === item.id).quantityIssued = Math.max(value, 1)
                })
              )
            } catch (error) {
              console.warn(error)
            }
          }}
          min={1}
          debounce={DEBOUNCE}
        />{' '}
        <Popover
          title={t('inventoryStatus')}
          content={<span dangerouslySetInnerHTML={{ __html: item.inventoryStatusInfo }} />}
        >
          <span
            className="align-middle"
            style={{ visibility: isEmpty(item.inventoryStatusInfo) ? 'hidden' : 'visible' }}
          >
            <Icon type="Search" />
          </span>
        </Popover>
      </div>
    ),
  },
})(Filter)
