import * as React from 'react'
import { connect } from 'react-redux'
import { Layout, Alert, Menu, Divider, List, TimePicker, DatePicker, Spin } from 'antd'
import { uniq, trim, isFinite, isNil, toString as str, get, isEmpty, toLower, set, pick } from 'lodash'
import { Link, useHistory } from 'react-router-dom'
import cx from 'clsx'
import { Helmet } from 'react-helmet'
import { Resizable } from 'react-resizable'
import { useImmer } from 'use-immer'
import { useIdleTimer } from 'react-idle-timer'
import { setStorageItem, getStorageItem } from 'helpers/localStorage'
import {
  INACTIVITY_SESSION_KEY,
  INACTIVITY_TIMEOUT,
  REFRESH_TOKEN_INTERVAL,
  LAST_PAGE_VISITED_STORAGE_KEY,
} from 'options/auth'
import {
  getPageTitle,
  getUserPermission,
  getPossibleRoutes,
  getHamburgerSelectedKeys,
  getSideMenuMustOpenKeys,
  getSideMenuSelectedKeys,
  getMenuItemAllLevels,
  getMenuItemPath,
  createAuthStateToProps,
  getLogoutUrl,
  logout,
} from 'helpers/auth'
import { setSessionItem } from 'helpers/sessionStorage'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import tofino from 'images/logo.png'
import open from 'images/open.png'
import close from 'images/close.png'
import { Emitter } from 'helpers/events'
import { DEFER_FORBIDDEN_ERROR_WITH } from 'options/events'
import adminActions from 'actions/admin'
import authActions from 'actions/auth'
import announcementActions from 'actions/announcements'
import notificationActions from 'actions/notifications'
import Icon from 'elements/Icon'
import Hamburger from 'elements/Hamburger'
import Drawer from 'elements/Drawer'
import Modal from 'elements/Modal'
import UnderDevelopment from 'elements/UnderDevelopment'
import UserSettingsFormView from 'containers/Settings/User'
import CustomerSettingsFormView from 'containers/Settings/Customer'
import TenantSettingsFormView from 'containers/Settings/Tenant'
import {
  Announcement,
  LinkButton,
  LoginBack,
  Trigger,
  Header,
  TitleContainer,
  Logo,
  Title,
  Navbar,
  UserMenu,
  SideMenuContainer,
} from './Page.styled'

let announcementClosed = false

const getStorageKey = () => 'page'

const DEFAULT_SIDER_WIDTH = 280

const settingsForms = {
  settings: {
    formSize: 'md',
    formComponent: TenantSettingsFormView,
    drawerTitleLanguageKey: 'settings',
  },
  userSettings: {
    formSize: 'xs',
    formComponent: UserSettingsFormView,
    drawerTitleLanguageKey: 'userSettings',
  },
  customerSettings: {
    formSize: 'lg',
    formComponent: CustomerSettingsFormView,
    drawerTitleLanguageKey: 'customerSettings',
  },
}

function Component(props) {
  const history = useHistory()
  const [level1] = getMenuItemAllLevels()
  const sideMenuTitle = level1?.languageKey
  const userDisplayName = props.user.fullname || props.user.userName
  const currentPage = window.location.pathname
  const getScrollStorageKey = () => trim(`${getStorageKey()}.${str(sideMenuTitle)}`, '.')
  const customerSettingsDisabled =
    (props.userIsInRoleDistributor || props.userIsInRoleSupplier) && getUserPermission('Master') !== 'Yes'
  const sideMenuSelectedKeys = getSideMenuSelectedKeys()
  const possibleRoutes = React.useMemo(() => getPossibleRoutes(props.menuItems), [props.menuItems])
  const currentPageIsAuthorized = ['/welcome', ...possibleRoutes].map(toLower).includes(toLower(currentPage))

  const [state, updateState] = useImmer({
    jumpToLocation: window.location.pathname,
    ...getStorageItem(getStorageKey()),
    ...getStorageItem(getScrollStorageKey()),
  })

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  function handleMenuClick({ key }) {
    switch (key) {
      case 'existAsCustomer':
        props.existAsCustomer()
        break

      case 'logout':
        logout().finally(() => {
          sessionStorage.clear()
          window.location.href = getLogoutUrl({ props })
        })
        break

      case 'userSettings':
        updateState((draft) => {
          const settingsForm = settingsForms.userSettings

          draft.settingsLinkTargetRecord = props.user
          draft.settingsFormView = settingsForm.formComponent
          draft.settingsDrawerSize = settingsForm.formSize
          draft.settingsDrawerTitleLanguageKey = settingsForm.drawerTitleLanguageKey
          draft.settingsDrawerVisible = true
          draft.settingsDrawerSaving = false
        })
        break

      case 'customerSettings':
        updateState((draft) => {
          if (customerSettingsDisabled) {
            return
          }

          const settingsRecord =
            props.userIsInRoleSupplier || props.userIsInRoleDistributor ? { id: 1 } : props.customer
          const settingsForm =
            props.userIsInRoleSupplier || props.userIsInRoleDistributor
              ? settingsForms.settings
              : settingsForms.customerSettings

          draft.settingsLinkTargetRecord = settingsRecord
          draft.settingsFormView = settingsForm.formComponent
          draft.settingsDrawerSize = settingsForm.formSize
          draft.settingsDrawerTitleLanguageKey = settingsForm.drawerTitleLanguageKey
          draft.settingsDrawerVisible = true
          draft.settingsDrawerSaving = false
        })
        break

      default:
        history.push(getMenuItemPath(key))
        break
    }
  }

  React.useEffect(() => {
    if (!window.impersonationInProgress) {
      setStorageItem(LAST_PAGE_VISITED_STORAGE_KEY, currentPage)
    }
  }, [currentPage])

  const menuContainerRef = React.useRef(null)
  React.useEffect(() => {
    if (!isNil(state.menuScrollTop)) {
      setStorageItem(getScrollStorageKey(), pick(state, ['menuScrollTop']))
    }

    if (!isNil(state.siderWidth) || !isNil(state.siderCollapsed)) {
      setStorageItem(getStorageKey(), pick(state, ['siderWidth', 'siderCollapsed']))
    }

    if (menuContainerRef.current) {
      menuContainerRef.current.scrollTop = isFinite(state.menuScrollTop) ? state.menuScrollTop : 0
    }
  }, [state.menuScrollTop, state.siderWidth, state.siderCollapsed])

  const sessionTimeoutRef = React.useRef()

  const onIdle = () => {
    if (window.location.pathname === '/dashboards/dashboardViewer') return
    console.log('Idle timer started')
    sessionTimeoutRef.current = window.setTimeout(() => {
      logout().finally(() => {
        sessionStorage.clear()
        setSessionItem(INACTIVITY_SESSION_KEY, true)
        window.location.href = getLogoutUrl({ props })
      })
    }, INACTIVITY_TIMEOUT)
  }

  const onActive = () => {
    props.refreshToken()
    if (!sessionTimeoutRef.current) return
    console.log('Idle timer stopped')
    window.clearTimeout(sessionTimeoutRef.current)
    sessionTimeoutRef.current = null
  }

  useIdleTimer({
    onIdle,
    onActive,
    crossTab: true,
    timeout: 60 * 1000,
    throttle: 500,
    eventsThrottle: 500,
  })

  const refreshTokenRef = React.useRef(null)
  React.useEffect(() => {
    props.refreshToken()
    refreshTokenRef.current = window.setInterval(props.refreshToken, REFRESH_TOKEN_INTERVAL)

    return () => {
      window.clearInterval(refreshTokenRef.current)
    }
  }, [])

  React.useEffect(() => {
    async function fetchAnnouncements() {
      try {
        const response = await props.getAnnouncements()

        setState('announcements', response.value.data.items)
      } catch (error) {
        showError({ error })
      }
    }

    if (!announcementClosed) {
      fetchAnnouncements()
    }
  }, [])

  React.useEffect(() => {
    async function fetchNotifications() {
      try {
        const response = await props.getNotifications()

        setState(
          'notifications',
          response.value.data.items.filter((each) => each.active && each.isNew)
        )
      } catch (error) {
        showError({ error })
      }
    }

    fetchNotifications()
  }, [])

  React.useEffect(() => {
    Emitter.on(DEFER_FORBIDDEN_ERROR_WITH, showError)

    return () => Emitter.off(DEFER_FORBIDDEN_ERROR_WITH, showError)
  }, [])

  const SettingsFormView = state?.settingsFormView ?? UnderDevelopment

  if (isEmpty(props.user)) {
    return <Spin className="m-12" />
  }

  return (
    <>
      <Helmet>
        <title>{props.title ?? getPageTitle()} - TRMS</title>
      </Helmet>
      <Layout className={cx('min-h-screen', props.className)} style={props.style}>
        {!isEmpty(state.announcements) && !announcementClosed && (
          <Announcement>
            <Alert
              message={<span dangerouslySetInnerHTML={{ __html: get(state, 'announcements[0].subject') }} />}
              type={toLower(get(state, 'announcements[0].type'))}
              showIcon={false}
              onClose={() => {
                announcementClosed = true
              }}
              closable
              banner
            />
          </Announcement>
        )}
        <Header>
          <Logo>
            <Link to="/">
              <img
                src={props.logo ? `data:${props.logo.fileType};base64,${props.logo.contents}` : tofino}
                alt={props.customer?.displayName}
              />
            </Link>
          </Logo>
          <Navbar>
            <Menu
              theme="dark"
              mode="horizontal"
              className="tofino-navbar-menu"
              onClick={({ key }) => history.push(getMenuItemPath(key))}
              selectable={false}
              selectedKeys={getHamburgerSelectedKeys()}
            >
              {(props?.menuItems ?? []).map((each) => (
                <Menu.Item key={each.key}>{t(each.languageKey)}</Menu.Item>
              ))}
            </Menu>
          </Navbar>
          {getUserPermission('AllowCreateJobRequestOnly') !== 'Yes' && (
            <UserMenu>
              {props.impersonator && (
                <>
                  <Icon type="ExitToApp" color="#1890ff" />{' '}
                  <LoginBack type="link" onClick={() => handleMenuClick({ key: 'existAsCustomer' })}>
                    {t('logBackIn')}
                  </LoginBack>
                  <span className="divider">|</span>
                </>
              )}
              <LinkButton
                type="link"
                onClick={() => handleMenuClick({ key: 'customerSettings' })}
                disabled={customerSettingsDisabled}
              >
                <Icon type="Store" /> {props.customer?.displayName}
              </LinkButton>
              <span className="divider">|</span>
              <LinkButton type="link" onClick={() => handleMenuClick({ key: 'userSettings' })}>
                <Icon type="Person" /> {userDisplayName}
              </LinkButton>
            </UserMenu>
          )}
          <Hamburger
            userDisplayName={userDisplayName}
            customerDisplayName={props.customer?.displayName}
            onClick={handleMenuClick}
            customerSettingsDisabled={customerSettingsDisabled}
            notifications={state.notifications}
          />
        </Header>
        <Layout className="tofino-layout__container">
          {level1 && (
            <Resizable
              width={state.siderWidth || DEFAULT_SIDER_WIDTH}
              height={0}
              onResize={(e, { size: { width } }) => setState('siderWidth', width)}
              minConstraints={[180, Infinity]}
              maxConstraints={[576, Infinity]}
            >
              <Layout.Sider
                width={state.siderWidth || DEFAULT_SIDER_WIDTH}
                collapsedWidth="64"
                breakpoint="md"
                onCollapse={(value) => value && setState('siderCollapsed', value)}
                collapsed={state.siderCollapsed}
                trigger={null}
                collapsible
              >
                <TitleContainer>
                  <Title title={t(sideMenuTitle)} siderCollapsed={state.siderCollapsed}>
                    {t(sideMenuTitle)}
                  </Title>
                  <Trigger onClick={() => setState('siderCollapsed', !state.siderCollapsed)}>
                    <img
                      src={state.siderCollapsed ? open : close}
                      alt={state.siderCollapsed ? t('open') : t('close')}
                    />
                  </Trigger>
                </TitleContainer>
                <SideMenuContainer
                  ref={menuContainerRef}
                  onScroll={(e) => setState('menuScrollTop', e.target.scrollTop)}
                >
                  <Menu
                    mode="inline"
                    theme="dark"
                    selectedKeys={sideMenuSelectedKeys}
                    onClick={({ key }) => history.push(`/${key}`)}
                    onOpenChange={(openKeys) => setStorageItem(`${level1.key}.sideMenu.openKeys`, openKeys)}
                    defaultOpenKeys={
                      !state.siderCollapsed
                        ? uniq([
                            ...getSideMenuMustOpenKeys(),
                            ...getStorageItem(`${level1.key}.sideMenu.openKeys`, []),
                          ])
                        : []
                    }
                  >
                    {(level1?.items ?? []).map((level2) =>
                      isEmpty(level2.items) ? (
                        <Menu.Item key={`${level1.key}/${level2.key}`} className="mb-4">
                          {level2.iconName && <Icon type={level2.iconName} />}
                          <span title={t(level2.languageKey)}>{t(level2.languageKey)}</span>
                        </Menu.Item>
                      ) : (
                        <Menu.SubMenu
                          key={`${level1.key}/${level2.key}`}
                          className={cx({
                            'ant-menu-submenu-active-child': sideMenuSelectedKeys.some(
                              (one) => one.split('/')[1] === level2.key
                            ),
                          })}
                          title={
                            <span>
                              {level2.iconName && <Icon type={level2.iconName} />}
                              <span title={t(level2.languageKey)}>{t(level2.languageKey)}</span>
                            </span>
                          }
                        >
                          {(level2?.items ?? []).map((level3) => (
                            <Menu.Item key={`${level1.key}/${level2.key}/${level3.key}`}>
                              {level3.iconName && <Icon type={level3.iconName} />}
                              <span title={t(level3.languageKey)}>{t(level3.languageKey)}</span>
                            </Menu.Item>
                          ))}
                        </Menu.SubMenu>
                      )
                    )}
                  </Menu>
                </SideMenuContainer>
              </Layout.Sider>
            </Resizable>
          )}
          <Layout.Content
            className={cx('tofino-layout__content', {
              'tofino-layout__content--scrollable': props.scrollable,
            })}
            style={props.contentStyle}
          >
            {window.impersonationInProgress ? (
              <Spin />
            ) : currentPageIsAuthorized ? (
              <>{props.children}</>
            ) : (
              <Alert
                message={t('unauthorizedAccessTitle')}
                description={t('unauthorizedAccessDescription')}
                type="error"
                showIcon
              />
            )}
          </Layout.Content>
        </Layout>
      </Layout>
      <Drawer
        title={t(state.settingsDrawerTitleLanguageKey || 'settings')}
        size={state.settingsDrawerSize}
        visible={state.settingsDrawerVisible}
        onClose={() => setState('settingsDrawerVisible', false)}
        saving={state.settingsDrawerSaving}
      >
        <SettingsFormView
          linkTargetRecord={state.settingsLinkTargetRecord}
          onSave={(pending) => setState('settingsDrawerSaving', pending)}
          onSaveAndClose={() => setState('settingsDrawerVisible', false)}
          onCancel={() => setState('settingsDrawerVisible', false)}
        />
      </Drawer>
      <Modal>
        <DatePicker />
        <TimePicker />
        <Divider />
        <List />
      </Modal>
    </>
  )
}

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  requestTimeout: adminActions.requestTimeout,
  refreshToken: authActions.refreshToken,
  existAsCustomer: authActions.existAsCustomer,
  getAnnouncements: announcementActions.getOptions,
  getNotifications: notificationActions.getOptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
