import * as React from 'react'
import { isNil, cloneDeep, set, unset } from 'lodash'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import completionStatusOptions from 'options/forms/completionStatusOptions'
import { t } from 'helpers/i18n'
import Select, { Option, getOptionProps } from 'elements/Select'
import DateRangePicker from 'elements/DateRangePicker'
import { Row, Col } from 'elements/Grid'

const dateRangeFields = {
  All: 'all',
  DueDate: 'dueDate',
  AssignDate: 'assigned',
  CompletionDate: 'completionDate',
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  componentDidMount() {
    const { formGroupId } = this.props.filterDto

    Promise.all([
      this.props.getFormGroups(),
      this.props.getFormTemplates(formGroupId ? { formGroupId } : undefined),
    ]).catch((error) => showError({ error }))
  }

  changeTemplateGroup = async (templateGroupId) => {
    const filterDto = cloneDeep(this.props.filterDto)

    if (!isNil(templateGroupId)) {
      set(filterDto, 'formGroupId', templateGroupId)
    } else {
      unset(filterDto, 'formGroupId')
    }

    unset(filterDto, 'formTemplateIds')

    try {
      await this.props.getFormTemplates(templateGroupId ? { formGroupId: templateGroupId } : undefined)
      await this.props.onChange({ ...filterDto })
    } catch (error) {
      showError({ error })
    }
  }

  render() {
    const { filterDto = {} } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <DateRangePicker
              filterDto={filterDto}
              setFilterValue={this.setFilterValue}
              dateRangeFields={dateRangeFields}
            />
          </Col>
          <Col third>
            <Form.Item label={t('formGroup')}>
              <Select
                onChange={(value) => this.changeTemplateGroup(value)}
                placeholder={t('all')}
                value={filterDto.formGroupId}
                allowClear
              >
                {(this.props.formGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('template')}>
              <Select
                onChange={(value) => this.setFilterValue('formTemplateIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.formTemplateIds}
                allowClear
              >
                {(this.props.formTemplates ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('status')}>
              <Select
                onChange={(value) => this.setFilterValue('completionStatus', value || 'All')}
                value={filterDto.completionStatus}
              >
                {Object.entries(completionStatusOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
