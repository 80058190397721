import { useImmer } from 'use-immer'
import { Button } from 'antd'
import { isEmpty, set } from 'lodash'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import SelectMetrics from 'containers/DashboardTemplates/FormView/Metrics/Select'

function Component(props) {
  const { items = [], onSelect = () => {}, ...rest } = props

  const [state, updateState] = useImmer({ selectedItems: [] })

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }
  return (
    <div>
      <Button
        style={{ padding: '0 10px' }}
        onClick={() =>
          updateState((draft) => {
            draft.modalVisible = true
            draft.selectedItems = []
          })
        }
        disabled={isEmpty(items)}
      >
        <Icon type="Search" />
      </Button>
      <Modal
        title={t('chartMetrics')}
        visible={state.modalVisible}
        onCancel={() => setState('modalVisible', false)}
        footer={
          <>
            <Button onClick={() => setState('modalVisible', false)}>{t('cancel')}</Button>
            <Button
              onClick={() => {
                onSelect(state.selectedItems)
                window.setTimeout(() => setState('modalVisible', false), 500)
              }}
              type="primary"
              disabled={isEmpty(state.selectedItems)}
            >
              {t('select')}
            </Button>
          </>
        }
        width={992}
      >
        <SelectMetrics
          settingsType="chartMetric"
          items={items}
          onSelect={(values) => setState('selectedItems', values)}
          {...rest}
        />
      </Modal>
    </div>
  )
}

export default Component
