import { Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { createListViewComponent } from 'factories/ListView'
import { encodeState } from 'helpers/utils'
import { t } from 'helpers/i18n'
import entityNames from 'options/entityNames'
import PrintLabel from 'containers/PrintLabel'
import Filter from 'containers/OverstockLocations/Filter'

export default createListViewComponent({
  entityName: entityNames.overstockLocations,
  filterTemplateType: 'OverstockLocation',
  createButtonTextId: 'createOverstockLocation',
  primaryLinkTarget: 'overstockLocationRecord',
  allowDelete: (self) => false,
  tableCellComponents: {
    itemsAtLocation: (self, item) => {
      if (item.itemsAtLocation === 0) {
        return 0
      }

      if (!item.userIsAuthorizedForLocationGroup) {
        return <Tooltip title={t('notAuthorizedForLocationGroup')}>{item.itemsAtLocation}</Tooltip>
      }

      return (
        <Link
          to={`/inventory/overstockInventory?${encodeState({
            showFilter: true,
            filterDto: {
              locationGroupId: item.locationGroupId,
            },
          })}`}
        >
          {item.itemsAtLocation}
        </Link>
      )
    },
  },
  extraRowActions: (self) => (
    <PrintLabel
      type="OverstockLocation"
      items={self.state.selectedRowKeys.map((each) => self.state.items.find((one) => one.id === each))}
      parentHasUnsavedChanges={self.hasUnsavedChanges()}
    />
  ),
})(Filter)
