import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/LocationCounts/Filter'
import linkTargets from 'options/linkTargets'
import { t } from 'helpers/i18n'

export default createListViewComponent({
  entityName: entityNames.locationCounts,
  filterTemplateType: 'LocationCount',
  primaryLinkTarget: 'locationCountRecord',
  allowCreate: () => false,
  allowDelete: () => false,
  allowSelection: () => false,
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
  tableCellComponents: {
    orderId: (self, item, column, text) =>
      item.orderId ? (
        <a
          onClick={() =>
            self.setState({
              editDrawerLinkTarget: linkTargets.orderRecord,
              editDrawerLinkTargetText: `${t('order')} - ${text}`,
              editDrawerLinkTargetRecord: item,
              editDrawerVisible: true,
              editDrawerSaving: false,
            })
          }
        >
          {text}
        </a>
      ) : (
        ''
      ),
  },
})(Filter)
