import cx from 'clsx'
import produce from 'immer'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import InputNumber from 'elements/InputNumber'
import Filter from 'containers/CustomerDetails/Filter'

export default createListViewComponent({
  entityName: entityNames.customerDetails,
  createButtonTextId: 'addCustomerDetail',
  primaryLinkTarget: 'customerDetailEntityRecord',
  getSettingsType: () => 'customerDetails',
  filterTemplateType: 'CustomerDetail',
  tableCellComponents: {
    price: (self, item) => (
      <InputNumber
        className={cx({
          'form-field-highlight':
            self.props.items.find((one) => one.id === item.id)?.price !==
            self.state.items.find((one) => one.id === item.id)?.price,
        })}
        value={item.price}
        onChange={(value) =>
          self.setState(
            produce((draft) => {
              draft.items.find((one) => one.id === item.id).price = value
            })
          )
        }
        min={0}
        currency
      />
    ),
  },
  allowInlineEditing: (self) => true,
})(Filter)
