import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'

export default ({ actions, extraStateToProps, extraDispatchToProps } = {}) =>
  (Component) => {
    const mapStateToProps = (state, props) => ({
      ...createAuthStateToProps(state, props),
      ...(extraStateToProps ? extraStateToProps(state, props) : {}),
    })

    const mapDispatchToProps = (dispatch, props) => ({
      getSettings: (params) => dispatch(actions.getSettings(params)),
      getItems: (params) => dispatch(actions.getItems(params)),
      deleteItems: (params) => dispatch(actions.deleteItems(params)),
      ...(extraDispatchToProps ? extraDispatchToProps(dispatch, props) : {}),
    })

    return connect(mapStateToProps, mapDispatchToProps)(Component)
  }
