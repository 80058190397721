import produce from 'immer'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'

const entityName = entityNames.tasks

export default {
  ...createActions(entityName, {
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.active = draft.active ?? 'Active'
        draft.taskType = draft.taskType ?? 'ScheduledReport'
      }),
  }),
}
