switch (window.location.hostname.toLowerCase()) {
  case 'web.tofino1.com':
  case 'opticrib.tofino1.com':
  case 'stevens.tofino1.com':
  case 'login.tofino1.com':
  case 'www.tofino1.com':
    window.getEnv = () => 'Production'
    module.exports = require('./config-prod')
    break

  case 'web.trmsdr.com':
    window.getEnv = () => 'Production'
    module.exports = require('./config-prod-dr')
    break

  case 'web-s.trmstest.com':
    window.getEnv = () => 'Staging'
    module.exports = require('./config-staging')
    break

  case 'web-s.trmsdr.com':
    window.getEnv = () => 'Staging'
    module.exports = require('./config-staging-dr')
    break

  case 'web.trmstest.com':
  case 'opticrib.trmstest.com':
    window.getEnv = () => 'Test'
    module.exports = require('./config-test')
    break

  case 'web-t.trmsdr.com':
    window.getEnv = () => 'Test'
    module.exports = require('./config-test-dr')
    break

  default:
    window.getEnv = () => 'Development'
    module.exports = require('./config-dev')
    break
}
