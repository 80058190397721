import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'

export default createListViewComponent({
  entityName: entityNames.tenants,
  primaryLinkTarget: 'tenantRecord',
  allowCreate: (self) => false,
  allowSearching: (self) => false,
  allowSelection: (self) => false,
  initialFilterDto: (self) => ({
    tenantType: 'Trms',
    supplierLinkTenantsOnly: true,
  }),
})()
