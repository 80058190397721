import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { allowPricing } from 'helpers/auth'
import Filter from 'containers/Reports/ExcessInventoryReport/Filter'

export default createListViewComponent({
  entityName: entityNames.excessInventoryReport,
  filterTemplateType: 'ExcessInventoryReport',
  allowSelection: (self) => false,
  allowCreate: (self) => false,
  initialFilterDto: (self) => ({ excessInventoryOnly: true }),
  tableSummary: (self) =>
    allowPricing()
      ? `${t('totalValue:')} ${toLocaleCurrency(self.props.list?.totalExcessInventoryValue ?? 0)}`
      : '',
})(Filter)
