import * as React from 'react'
import styled from 'styled-components'
import { Tree, Button, Tooltip, Form, Input, Spin, Menu, message } from 'antd'
import { isEmpty, get, toString as str, isNil, set, pick } from 'lodash'
import { useImmer } from 'use-immer'
import { showError, showValidationError } from 'helpers/errors'
import { getTreeData } from 'helpers/trees'
import { tryParseInt, mergeAll } from 'helpers/utils'
import { isReadOnly } from 'helpers/formViews'
import { stopEvent } from 'helpers/events'
import { setStorageItem, getStorageItem } from 'helpers/localStorage'
import { t } from 'helpers/i18n'
import { getPageTitle } from 'helpers/auth'
import Page from 'elements/Page'
import Drawer from 'elements/Drawer'
import Toolbar from 'elements/Toolbar'
import Icon from 'elements/Icon'
import Checkbox from 'elements/Checkbox'
import FormButtons from 'elements/FormButtons'

const getStorageKey = () => 'setup.productCategories'

const Container = styled(Page)`
  * {
    cursor: ${(props) => (props.saving ? 'progress !important' : 'auto')};
  }
`

const Categories = styled(Tree)`
  margin-bottom: 24px;

  .ant-tree-node-selected {
    font-weight: bold;

    .ant-btn {
      padding: 0;
      height: auto;
      border: none;
      box-shadow: none;
      vertical-align: middle;
      margin-left: 6px;
      background-color: transparent;
    }
  }
`

function Component(props) {
  const { form, getSettings, getItems, createItem, updateItem, settingsType } = props

  const inputRef = React.useRef(null)

  const [state, updateState] = useImmer({
    expandedKeys: ['1'],
    ...getStorageItem(getStorageKey()),
  })

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  const selectedItem = (props?.items ?? []).find((one) => one.id === state.selectedId)

  function handleAddClick(e) {
    stopEvent(e)

    updateState((draft) => {
      draft.item = {
        parentId: selectedItem.id,
        active: true,
      }
      draft.drawerVisible = true
      draft.drawerTitle = t('addChild')
    })

    window.setTimeout(() => inputRef.current?.focus?.(), 500)
  }

  function handleEditClick(e) {
    stopEvent(e)

    updateState((draft) => {
      draft.item = { ...selectedItem }
      draft.drawerVisible = true
      draft.drawerTitle = t('edit')
    })

    window.setTimeout(() => inputRef.current?.focus?.(), 500)
  }

  function handleSubmit(
    e,
    {
      saveAndClose = true,
      saveButtonLoading = saveAndClose ? 'saveAndCloseButtonLoading' : 'saveButtonLoading',
    } = {}
  ) {
    stopEvent(e)

    form.validateFields(async (errors, values) => {
      if (!isEmpty(errors)) {
        showValidationError()
        return
      }

      try {
        updateState((draft) => {
          draft.drawerSaving = true
          draft[saveButtonLoading] = true
        })

        if (state.item?.id) {
          await updateItem(mergeAll({}, selectedItem, values)) // NOTE: Using merge due to nested dtoFieldNames
        } else {
          const response = await createItem({
            ...values,
            parentId: selectedItem.id,
          })

          if (isNil(response.value.data)) {
            throw new Error('Save response is invalid')
          }

          updateState((draft) => {
            draft.item = response.value.data
            draft.selectedId = draft.item.id
            draft.drawerTitle = t('edit')
          })
        }

        if (saveAndClose) {
          setState('drawerVisible', false)
        }

        await fetchItems()
      } catch (error) {
        showError({ error })
      } finally {
        updateState((draft) => {
          draft.drawerSaving = false
          draft[saveButtonLoading] = false
        })
      }
    })
  }

  async function fetchItems() {
    try {
      const response = await getItems({ active: 'All', pageIndex: 0 })

      setState('items', get(response, 'value.data.items', []))
    } catch (error) {
      showError({ error })
    }
  }

  async function fetchSettings() {
    try {
      const response = await getSettings({ type: settingsType })

      setState('fieldSettings', get(response, 'value.data.fieldSettings', []))
    } catch (error) {
      showError({ error })
    }
  }

  async function handleDrop({
    node: {
      props: { id: parentId },
    },
    dragNode: {
      props: { id, name, active },
    },
  }) {
    try {
      setState('saving', true)
      await updateItem({ id, name, active, parentId })
      await fetchItems()
    } catch (error) {
      showError({ error })
    } finally {
      setState('saving', false)
    }
  }

  function handleActionsMenuClick({ key }) {
    switch (key) {
      case 'expandAll':
        setState('expandedKeys', (state?.items ?? []).map((each) => each.id).map(str))
        break

      case 'collapseAll':
        updateState((draft) => {
          const root = str(get(treeData, '[0].id'))
          draft.expandedKeys = root ? [root] : []
        })
        break

      default:
        message.warn(t('underDevelopment'))
        break
    }
  }

  const treeData = getTreeData(state?.items ?? [], (each) => (
    <>
      <span
        style={{
          marginRight: '18px',
          textDecoration: each.active ? 'inherit' : 'line-through',
        }}
      >
        {each.name}
      </span>
      {each.id === state.selectedId && (
        <>
          <Tooltip title={t('addChild')}>
            <Button onClick={handleAddClick}>
              <Icon type="PlaylistAdd" size={18} />
            </Button>
          </Tooltip>
          <Tooltip title={t('edit')}>
            <Button onClick={handleEditClick}>
              <Icon type="Edit" size={18} />
            </Button>
          </Tooltip>
        </>
      )}
    </>
  ))

  React.useEffect(() => {
    fetchSettings()
  }, [])

  React.useEffect(() => {
    fetchItems()
  }, [])

  React.useEffect(() => {
    setStorageItem(getStorageKey(), pick(state, ['expandedKeys']))
  }, [state.expandedKeys])

  const pageTitle = getPageTitle()

  if (isEmpty(state.fieldSettings)) {
    return (
      <Container title={pageTitle} scrollable>
        <Spin />
      </Container>
    )
  }

  const readOnly = isReadOnly({ props })

  return (
    <Container title={pageTitle} saving={state.saving} scrollable>
      <Toolbar
        title={pageTitle}
        actionsMenuItems={[
          <Menu.Item key="expandAll">{t('expandAll')}</Menu.Item>,
          <Menu.Item key="collapseAll">{t('collapseAll')}</Menu.Item>,
        ]}
        onActionsMenuClick={handleActionsMenuClick}
      />
      {!isEmpty(state.items) && (
        <Categories
          treeData={treeData}
          expandedKeys={state.expandedKeys}
          onExpand={(values) => setState('expandedKeys', values)}
          onSelect={(values) => setState('selectedId', tryParseInt(values[0]))}
          selectedKeys={state.selectedId ? [str(state.selectedId)] : []}
          onDrop={handleDrop}
          defaultExpandAll
          selectable
          draggable
          showLine
        />
      )}
      <Drawer
        title={state.drawerTitle}
        visible={state.drawerVisible}
        onClose={() => setState('drawerVisible', false)}
        saving={state.drawerSaving}
        size="xs"
      >
        <Form layout="vertical" onSubmit={handleSubmit} colon={false}>
          <div className="form-items-container">
            <Form.Item label={t('name')}>
              {form.getFieldDecorator('name', {
                rules: [{ required: true, message: t('requiredField') }],
                initialValue: state.item?.name,
              })(<Input ref={inputRef} autoFocus />)}
            </Form.Item>
            {state.item?.parentId > 0 && (
              <Form.Item>
                {form.getFieldDecorator('active', {
                  valuePropName: 'checked',
                  initialValue: state.item?.active,
                })(<Checkbox>{t('active')}</Checkbox>)}
              </Form.Item>
            )}
          </div>
          <div className="form-buttons-container">
            <FormButtons
              readOnly={readOnly}
              onCancel={() => setState('drawerVisible', false)}
              onSubmit={handleSubmit}
              saveButtonLoading={state.saveButtonLoading}
              saveAndCloseButtonLoading={state.saveAndCloseButtonLoading}
            />
          </div>
        </Form>
      </Drawer>
    </Container>
  )
}

export default Form.create()(Component)
