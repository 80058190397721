export default {
  showThisPage: 'showThisPage',
  showLastPageVisited: 'showLastPageVisited',
  showOrdersPage: 'showOrdersPage',
  showIssuePage: 'showIssuePage',
  showInventoryPage: 'showInventoryPage',
  showJobsPage: 'showJobsPage',
  showAssetsPage: 'showAssetsPage',
  showProductsPage: 'showProductsPage',
  showPeoplePage: 'showPeoplePage',
  showFormsPage: 'showFormsPage',
  showDashboardViewerPage: 'showDashboardViewerPage',
  showReportsPage: 'showReportsPage',
  showToolsPage: 'showToolsPage',
  showSetupPage: 'showSetupPage',
}
