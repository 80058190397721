import { isNil, toString as str, isEmpty } from 'lodash'

function getScoped(key) {
  return [process.env.REACT_APP_NAME, key]
    .map(str)
    .filter((each) => !isEmpty(each))
    .join('.')
}

export function getSessionItem(key, defaultValue) {
  try {
    const serializedValue = sessionStorage.getItem(getScoped(key))

    return isNil(serializedValue) ? defaultValue : JSON.parse(str(serializedValue))
  } catch (error) {
    return defaultValue
  }
}

export function setSessionItem(key, value) {
  sessionStorage.setItem(getScoped(key), JSON.stringify(value))
}

export function removeSessionItem(key) {
  try {
    sessionStorage.removeItem(getScoped(key))
  } catch (error) {
    console.warn(error)
  }
}
