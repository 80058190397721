import { createListViewContainer } from 'factories/ListView'
import selectors from 'selectors'
import actions from 'actions/returnToInventory'
import orderActions from 'actions/orders'
import Component from 'components/ReturnToInventory'

export default createListViewContainer({
  actions,
  selectors: selectors.returnToInventory,
  extraDispatchToProps: (dispatch, props) => ({
    returnItems: (params) => dispatch(orderActions.returnItems(params)),
  }),
})(Component)
