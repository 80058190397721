import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/JobLabourRates/Filter'

export default createListViewComponent({
  entityName: entityNames.jobLabourRates,
  filterTemplateType: 'JobLabourRate',
  createButtonTextId: 'create',
  primaryLinkTarget: 'jobLabourRateRecord',
  allowDelete: (self) => false,
  allowDeactivate: (self) => true,
  initialFilterDto: (self) => ({ active: 'Active' }),
})(Filter)
