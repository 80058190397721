import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import { createChildItemsDispatchToProps } from 'helpers/formViews'
import actions from 'actions/requisitions'
import locationActions from 'actions/locations'
import userActions from 'actions/users'
import documentActions from 'actions/documents'
import requisitionItemActions from 'actions/requisitions/requisitionItems'
import Component from 'components/Requisitions/Print'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getSettings: (params) => dispatch(actions.getSettings(params)),
  getAddressList: (params) => dispatch(locationActions.getAddressList(params)),
  getRequisitionSendToList: (params) => dispatch(userActions.getRequisitionSendToList(params)),
  getDocumentContents: (params) => dispatch(documentActions.getContents(params)),
  ...createChildItemsDispatchToProps(dispatch, 'Requisition', requisitionItemActions),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
