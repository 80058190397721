import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'

export default createListViewComponent({
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  allowSearching: (self) => false,
  allowActionsMenu: (self) => false,
  entityName: entityNames.customMappings,
  filterTemplateType: 'CustomMappings',
  primaryLinkTarget: 'customMappingRecord',
})()
