import styled from 'styled-components'
import { Alert, notification, message } from 'antd'
import { isEmpty, invoke, isNil, camelCase } from 'lodash'
import { t } from 'helpers/i18n'

export const showError = ({ error } = {}) => {
  if (isNil(error)) {
    return
  }

  console.error(error)

  if (!isEmpty(error.data?.clientNotifications)) {
    showClientNotifications({ clientNotifications: error.data.clientNotifications })

    return
  }

  if (!isEmpty(error.response?.data?.Errors)) {
    error.response.data.Errors.forEach((each) => {
      const notificationType = camelCase(
        each.Type || each.type || each.ErrorType || each.errorType || 'error'
      )
      const titleLanguageKey =
        each.TitleLanguageKey || each.titleLanguageKey || each.Title || each.title || 'error'

      invoke(notification, notificationType, {
        message: t(titleLanguageKey),
        description: t(
          each.DescriptionLanguageKey ||
            each.descriptionLanguageKey ||
            each.Description ||
            each.description ||
            each.DeveloperError ||
            each.developerError
        ),
      })
    })

    return
  }

  if (error.message) {
    notification.error({
      message: t('error'),
      description: error.message,
    })
  }
}

export const showClientNotifications = ({
  response,
  clientNotifications = response?.value?.data?.clientNotifications ?? [],
} = {}) =>
  clientNotifications.forEach(({ type, description }) => {
    switch (type) {
      case 'Success':
        message.success(t(description))
        break

      case 'Warning':
        message.warning(t(description))
        break

      case 'Error':
        message.error(t(description))
        break

      default:
        message.info(t(description))
        break
    }
  })

export function resourceNotFound(self) {
  invoke(self, 'props.onCancel')
  throw new Error(t('requestedResourceNotFound'))
}

export function ValidationError(props) {
  return !isEmpty(props.errors) ? (
    <Alert
      className="mb-24"
      message={t('errorValidation')}
      description={t('validationErrorDescription')}
      type="error"
    />
  ) : null
}

export function showValidationError() {
  notification.error({
    message: t('errorValidation'),
    description: t('validationErrorDescription'),
    duration: 2.5,
  })
}

const AlertOverflow = styled(Alert)`
  .ant-alert-description {
    display: block;
    max-height: 94px;
    overflow-y: auto;
  }
`

export function ClientNotifications(props) {
  return !isEmpty(props.notifications) ? (
    <AlertOverflow
      className="mb-24"
      message={t('clientNotificationsTitle')}
      description={
        <ul>
          {props.notifications.map((each) => (
            <li key={each.description}>{each.description}</li>
          ))}
        </ul>
      }
      type="error"
      closable
    />
  ) : null
}
