import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'

export default createListViewComponent({
  entityName: entityNames.fastenalSafetyReport,
  filterTemplateType: 'FastenalSafetyReport',
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  allowSearching: (self) => false,
})()
