import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import Filter from 'containers/Reports/TransactionAuditReport/Filter'

export default createListViewComponent({
  entityName: entityNames.transactionAuditReport,
  filterTemplateType: 'TransactionAuditReport',
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'MonthToDate' } }),
  allowCreate: (self) => false,
  allowSelection: (self) => false,
  tableCellComponents: {
    lineNumber: (self, item, column, text) => (item.lineNumber > 0 ? item.lineNumber : null),
  },
})(Filter)
