import produce from 'immer'
import { Tooltip, Popover } from 'antd'
import Barcode from 'react-barcode'
import {
  get,
  isArray,
  isDate,
  isEmpty,
  isNil,
  isFinite,
  isString,
  toString as str,
  trim,
  orderBy,
  invoke,
  toLower,
  intersection,
  sum,
  min,
  max,
  omitBy,
  isUndefined,
  omit,
} from 'lodash'
import { tryParseInt, tryParseFloat, tryParseJSON, sortByKeys, EMAIL_REGEX, normalizeJSON } from 'helpers/utils'
import { formatDateTime } from 'helpers/dateTime'
import { t } from 'helpers/i18n'
import store from 'helpers/store'
import { RED } from 'options/colors'
import Icon from 'elements/Icon'
import selectors from 'selectors'
import ObjectImage from 'elements/ObjectImage'

export const MINIMUM_COLUMN_WIDTH = 64
export const TOTALS_ROW_ID = -9999999999999
export const pageSizeOptions = ['10', '20', '50', '100', '200']

export const renderBarcode = ({ displayFormat, value, marginTop = 6, marginBottom = 6, height = 32, width = 1 }) => (
  <Barcode
    format={displayFormat === 'BarcodeCode39' ? 'CODE39' : 'CODE128'}
    value={str(value)}
    width={width}
    height={height}
    margin={0}
    marginTop={marginTop}
    marginBottom={marginBottom}
    displayValue={false}
  />
)

export const renderEmail = (text) => (
  <span
    dangerouslySetInnerHTML={{
      __html: trim(text).replace(EMAIL_REGEX, '<a href="mailto:$&">$&</a>'),
    }}
  />
)

export const renderTel = (text) => <a href={`tel:${text}`}>{text}</a>

export const createSorter = (dtoFieldName) => (first, second) => {
  const a = first[dtoFieldName]
  const b = second[dtoFieldName]

  if (a === b) {
    return 0
  }

  if (isNil(a) && !isNil(b)) {
    return -1
  }

  if (!isNil(a) && isNil(b)) {
    return 1
  }

  if (isString(a) && isString(b)) {
    return a.localeCompare(b)
  }

  if (isFinite(a) && isFinite(b)) {
    return a - b
  }

  if (isDate(a) && isDate(b)) {
    return a.getTime() - b.getTime()
  }

  if (isArray(a) && isArray(b)) {
    return a.length - b.length
  }

  return str(a).localeCompare(str(b))
}

export const formatValue = ({ value, displayFormat = 'Text', dtoFieldName = '' }) => {
  const getOptions = (digits, style, currency) => ({
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    style,
    currency,
  })

  const text = trim(str(value))

  if (isEmpty(text)) {
    return text
  }

  if (toLower(dtoFieldName).endsWith('id')) {
    return value < 0 ? t('na') : text
  }

  const locale = selectors.auth.locale(store.getState())

  try {
    switch (displayFormat) {
      case 'Int':
        return tryParseInt(text, 0).toLocaleString(locale, getOptions(0))

      case 'Float':
        return tryParseFloat(text, 0).toLocaleString(locale)

      case 'Float1':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(1))

      case 'Float2':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(2))

      case 'Float3':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(3))

      case 'Float4':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(4))

      case 'Currency':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(undefined, 'currency', 'USD'))

      case 'Currency0':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(0, 'currency', 'USD'))

      case 'Currency1':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(1, 'currency', 'USD'))

      case 'Currency2':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(2, 'currency', 'USD'))

      case 'Currency3':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(3, 'currency', 'USD'))

      case 'Currency4':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(4, 'currency', 'USD'))

      case 'Percent':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(undefined, 'percent'))

      case 'Percent0':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(0, 'percent'))

      case 'Percent1':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(1, 'percent'))

      case 'Percent2':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(2, 'percent'))

      case 'Date':
        return formatDateTime(text, { showDate: true, showTime: false })

      case 'Time':
        return formatDateTime(text, { showDate: false, showTime: true })

      case 'DateTime':
        return formatDateTime(text, { showDate: true, showTime: true })

      case 'DateTimeSeconds':
        return formatDateTime(text, { showDate: true, showTime: true, showSeconds: true })

      case 'Boolean':
        return ['yes', 'true', '1'].includes(toLower(text)) ? t('yes') : t('no')

      default:
        return text
    }
  } catch (error) {
    return text
  }
}

export const isValidLinkTarget = ({ linkTarget, text: value, primaryLinkTarget, secondaryLinkTargets = [] }) => {
  const text = toLower(value)

  if (text === '') {
    return false
  }

  if (['', 'none'].includes(linkTarget)) {
    return false
  }

  if (linkTarget === primaryLinkTarget) {
    return true
  }

  if (secondaryLinkTargets.includes(linkTarget)) {
    return true
  }

  if (['0', 'not assigned', 'no user', '*', '<not set>'].includes(text)) {
    return false
  }

  return true
}

export const getVisibleColumnKeys = (fieldSettings) => {
  const sortedFieldSettings = orderBy(fieldSettings, ['defaultColumnNumber'])

  const displayByDefaults = sortedFieldSettings
    .filter((each) => each.isDisplayable && each.displayByDefault)
    .map((each) => each.dtoFieldName)

  return !isEmpty(displayByDefaults)
    ? displayByDefaults
    : sortedFieldSettings.filter((each) => each.isDisplayable).map((each) => each.dtoFieldName)
}

const tableHeadGroupWithChildren = ({
  self,
  dtoFieldName,
  tableHeadGroups,
  columnHeadingLanguageKey,
  recordLabelLanguageKey,
}) => {
  const children = invoke(tableHeadGroups(self), dtoFieldName)
  return {
    key: dtoFieldName,
    align: 'center',
    title: t(columnHeadingLanguageKey || recordLabelLanguageKey || dtoFieldName),
    width: !isEmpty(children)
      ? children.map((each) => each?.width ?? MINIMUM_COLUMN_WIDTH).reduce((acc, each) => acc + each, 0)
      : MINIMUM_COLUMN_WIDTH,
    children: !isEmpty(children)
      ? children
      : [
          {
            title: t('na'),
            key: dtoFieldName,
            dataIndex: dtoFieldName,
            width: MINIMUM_COLUMN_WIDTH,
            align: 'center',
            render: () => t('na'),
          },
        ],
  }
}

const sharedTableCellComponents = {
  expedite: (self, item) => (item.expedite ? <Icon type="PriorityHigh" color={RED} /> : null),
  isExpedited: (self, item) => (item.isExpedited ? <Icon type="PriorityHigh" color={RED} /> : null),
  documentCount: (self, item) =>
    item.documentCount > 0 ? (
      <Popover
        title={`${item.documentCount} ${t(item.documentCount > 1 ? 'documents' : 'document')}`}
        content={<span dangerouslySetInnerHTML={{ __html: item.documentInfo }} />}
      >
        <span>
          <Icon type="AttachFile" />
        </span>
      </Popover>
    ) : null,
}

export const getColumns = ({
  self,
  fieldSettings = [],
  pageTotals = {},
  primaryLinkTarget,
  sortBy,
  sortOrder,
  selectedColumnKeys = [],
  sortedColumnKeys = [],
  onClick,
  onResize,
  columnWidths = {},
  tableCellComponents: extraTableCellComponents = {},
  totalsRowComponents = {},
  tableHeadGroups = () => ({}),
  filterDto = {},
}) => {
  const tableCellComponents = { ...sharedTableCellComponents, ...extraTableCellComponents }
  const visibleColumnKeys = isEmpty(selectedColumnKeys) ? getVisibleColumnKeys(fieldSettings) : selectedColumnKeys
  const pageTotalsKeys = Object.keys(pageTotals)

  const unsortedColumns = visibleColumnKeys.flatMap((key) =>
    fieldSettings
      .filter((one) => one.dtoFieldName === key)
      .map((column) => {
        const {
          dtoFieldName,
          sortByEnumValue,
          columnWidth,
          columnHeadingLanguageKey,
          recordLabelLanguageKey,
          alignment,
          displayFormat,
          columnHeadingTooltipLanguageKey,
          columnHeadingIconName,
          linkTarget,
          totalCalculationType,
        } = column

        if (get(tableHeadGroups(self), dtoFieldName)) {
          return tableHeadGroupWithChildren({
            self,
            dtoFieldName,
            tableHeadGroups,
            columnHeadingLanguageKey,
            recordLabelLanguageKey,
          })
        }

        return {
          key: dtoFieldName,
          dataIndex: dtoFieldName,
          sorter: sortByEnumValue >= 0,
          sortOrder: dtoFieldName === sortBy ? sortOrder : null,
          width: columnWidths[dtoFieldName]
            ? Math.max(columnWidths[dtoFieldName], MINIMUM_COLUMN_WIDTH)
            : columnWidth
              ? Math.max(columnWidth, MINIMUM_COLUMN_WIDTH)
              : MINIMUM_COLUMN_WIDTH,
          title: t(columnHeadingLanguageKey || recordLabelLanguageKey || dtoFieldName),
          align: columnHeadingIconName ? 'center' : alignment || 'left',
          render: (value, record, index) => {
            const text = formatValue({
              value,
              displayFormat,
              dtoFieldName,
            })

            if (record.id === TOTALS_ROW_ID) {
              if (!pageTotalsKeys.includes(dtoFieldName)) {
                return null
              }

              if (totalsRowComponents[dtoFieldName]) {
                return invoke(totalsRowComponents, dtoFieldName, self, record, column, text)
              }

              return <Tooltip title={t(totalCalculationType)}>{text}</Tooltip>
            }

            if (displayFormat.startsWith('Barcode')) {
              return renderBarcode({ displayFormat, value })
            }

            if (tableCellComponents[dtoFieldName]) {
              return invoke(tableCellComponents, dtoFieldName, self, record, column, text)
            }

            if (
              onClick && // NOTE: Modal select lists must not render linkTargets
              isValidLinkTarget({ linkTarget, text, primaryLinkTarget })
            ) {
              return <a onClick={() => onClick({ record, column, text, index })}>{text}</a>
            } else if (displayFormat === 'Tel') {
              return renderTel(text)
            } else if (displayFormat === 'Email') {
              return renderEmail(text)
            }

            return text
          },
          onHeaderCell: (header) => ({
            columnHeadingTooltipLanguageKey,
            columnHeadingIconName,
            width: header.width,
            onResize: onResize(header),
          }),
        }
      })
  )

  const sortedColumns = sortByKeys(unsortedColumns, sortedColumnKeys)

  if (filterDto?.showImages) {
    sortedColumns.unshift({
      key: 'objectImage',
      width: 105,
      render: (value, record, index) =>
        record.objectImageContentType && record.objectImageContentBase64 ? (
          <ObjectImage title={record.displayName}>
            <img
              src={`data:${record.objectImageContentType};base64,${record.objectImageContentBase64}`}
              alt={record.displayName ?? ''}
            />
          </ObjectImage>
        ) : null,
    })
  }

  return sortedColumns
}

export const getChildColumns = ({
  self,
  fieldSettings = [],
  pageTotals = {},
  sortBy,
  sortOrder,
  primaryLinkTarget,
  secondaryLinkTargets = [],
  onClick,
  onResize,
  columnWidths = {},
  tableCellComponents: extraTableCellComponents = {},
  totalsRowComponents = {},
  tableHeadGroups = () => ({}),
  selectedColumnKeys = [],
  sortedColumnKeys = [],
  allowSorting = true,
}) => {
  const tableCellComponents = { ...sharedTableCellComponents, ...extraTableCellComponents }
  const visibleColumnKeys = isEmpty(selectedColumnKeys) ? getVisibleColumnKeys(fieldSettings) : selectedColumnKeys
  const pageTotalsKeys = Object.keys(pageTotals)

  const unsortedColumns = visibleColumnKeys.flatMap((key) =>
    fieldSettings
      .filter((one) => one.dtoFieldName === key)
      .map((column) => {
        const {
          dtoFieldName,
          columnWidth,
          columnHeadingLanguageKey,
          alignment,
          linkTarget,
          columnHeadingTooltipLanguageKey,
          columnHeadingIconName,
          displayFormat,
          totalCalculationType,
          recordLabelLanguageKey,
        } = column

        if (get(tableHeadGroups(self), dtoFieldName)) {
          return tableHeadGroupWithChildren({
            self,
            dtoFieldName,
            tableHeadGroups,
            columnHeadingLanguageKey,
            recordLabelLanguageKey,
          })
        }

        return {
          key: dtoFieldName,
          dataIndex: dtoFieldName,
          sorter: allowSorting ? (isEmpty(pageTotalsKeys) ? createSorter(dtoFieldName) : true) : false,
          sortOrder: dtoFieldName === sortBy ? sortOrder : null,
          width: columnWidths[dtoFieldName]
            ? Math.max(columnWidths[dtoFieldName], MINIMUM_COLUMN_WIDTH)
            : columnWidth
              ? Math.max(columnWidth, MINIMUM_COLUMN_WIDTH)
              : MINIMUM_COLUMN_WIDTH,
          title: t(columnHeadingLanguageKey || recordLabelLanguageKey || dtoFieldName),
          align: columnHeadingIconName ? 'center' : alignment || 'left',
          render: (value, record, index) => {
            const text = formatValue({
              value,
              displayFormat,
              dtoFieldName,
            })

            if (record.id === TOTALS_ROW_ID) {
              if (!pageTotalsKeys.includes(dtoFieldName)) {
                return null
              }

              if (totalsRowComponents[dtoFieldName]) {
                return invoke(totalsRowComponents, dtoFieldName, self, record, column, text)
              }

              return <Tooltip title={t(totalCalculationType)}>{text}</Tooltip>
            }

            if (displayFormat.startsWith('Barcode')) {
              return renderBarcode({ displayFormat, value })
            }

            if (tableCellComponents[dtoFieldName]) {
              return invoke(tableCellComponents, dtoFieldName, self, record, column, text)
            }

            if (
              onClick && // NOTE: Modal select lists must not render linkTargets
              isValidLinkTarget({ linkTarget, text, primaryLinkTarget, secondaryLinkTargets })
            ) {
              return (
                <a
                  onClick={() => {
                    onClick({
                      record,
                      column,
                      text,
                      index,
                      readOnly: ![primaryLinkTarget, ...secondaryLinkTargets].includes(linkTarget),
                    })
                  }}
                >
                  {text}
                </a>
              )
            } else if (displayFormat === 'Tel') {
              return renderTel(text)
            } else if (displayFormat === 'Email') {
              return renderEmail(text)
            }

            return text
          },
          onHeaderCell: (header) => ({
            columnHeadingTooltipLanguageKey,
            columnHeadingIconName,
            width: header.width,
            onResize: onResize(header),
          }),
        }
      })
  )

  return sortByKeys(unsortedColumns, sortedColumnKeys)
}

export const getPrintColumns = ({
  self,
  fieldSettings,
  tableCellComponents: extraTableCellComponents = {},
  tableHeadGroups = () => ({}),
  selectedColumnKeys = [],
  sortedColumnKeys = [],
  sortBy,
  sortOrder,
}) => {
  const tableCellComponents = { ...sharedTableCellComponents, ...extraTableCellComponents }
  const visibleColumnKeys = isEmpty(selectedColumnKeys) ? getVisibleColumnKeys(fieldSettings) : selectedColumnKeys

  const unsortedColumns = visibleColumnKeys.flatMap((key) =>
    fieldSettings
      .filter((one) => one.dtoFieldName === key)
      .map((column) => {
        const {
          dtoFieldName,
          columnHeadingLanguageKey,
          alignment,
          columnHeadingIconName,
          displayFormat,
          recordLabelLanguageKey,
        } = column

        if (get(tableHeadGroups(self), dtoFieldName)) {
          return tableHeadGroupWithChildren({
            self,
            dtoFieldName,
            tableHeadGroups,
            columnHeadingLanguageKey,
            recordLabelLanguageKey,
          })
        }

        return {
          key: dtoFieldName,
          dataIndex: dtoFieldName,
          sorter: createSorter(dtoFieldName),
          sortOrder: dtoFieldName === sortBy ? sortOrder : null,
          title: t(columnHeadingLanguageKey || recordLabelLanguageKey || dtoFieldName),
          align: columnHeadingIconName ? 'center' : alignment || 'left',
          render: (value, record, index) => {
            const text = formatValue({
              value,
              displayFormat,
              dtoFieldName,
            })

            if (displayFormat.startsWith('Barcode')) {
              return renderBarcode({ displayFormat, value })
            }

            if (tableCellComponents[dtoFieldName]) {
              return invoke(tableCellComponents, dtoFieldName, self, record, column, text)
            }

            return text
          },
        }
      })
  )

  return sortByKeys(unsortedColumns, sortedColumnKeys)
}

export const mapSearchFields = (searchFields = [], fieldSettings = []) =>
  searchFields
    .map((each) =>
      isString(each)
        ? each
        : isFinite(each)
          ? fieldSettings.find((one) => one.searchEnumValue === each).dtoFieldName
          : undefined
    )
    .filter((each) => !isNil(each))
    .filter((v, i, a) => a.indexOf(v) === i) // uniq

export const mapGetItemsFields = ({
  sortBy: sortByField,
  sortOrder,
  selectedColumnKeys = [],
  fieldSettings = [],
  searchFields = [],
}) => {
  const visibleColumnKeys = isEmpty(selectedColumnKeys) ? getVisibleColumnKeys(fieldSettings) : selectedColumnKeys

  return {
    // Sort
    ...(sortByField && visibleColumnKeys.includes(sortByField)
      ? {
          sortByField,
          sortOrder: sortOrder === 'descend' ? 'DESC' : 'ASC',
        }
      : {}),

    // Search
    searchFields: mapSearchFields(searchFields, fieldSettings),

    // Selected columns
    selectedColumnKeys: isEmpty(selectedColumnKeys) ? visibleColumnKeys : selectedColumnKeys,
  }
}

export const mapGetCalendarFields = ({ sortBy: sortByField, sortOrder, fieldSettings = [], searchFields = [] }) =>
  omitBy(
    {
      // Sort by
      sortByField,

      // Sort order
      sortOrder: sortOrder === 'descend' ? 'DESC' : sortOrder === 'ascend' ? 'ASC' : undefined,

      // Search
      searchFields: mapSearchFields(searchFields, fieldSettings),
    },
    isUndefined
  )

export const getTableWidth = (columns = [], allowSelection = false) =>
  sum(columns.map(({ width = MINIMUM_COLUMN_WIDTH }) => Math.max(width, MINIMUM_COLUMN_WIDTH))) +
  (allowSelection ? MINIMUM_COLUMN_WIDTH : 0)

export const createHandleColumnResize =
  (self) =>
  ({ key }) =>
  (e, { size: { width } }) =>
    self.setState(
      produce((draft) => {
        draft.columnWidths = draft.columnWidths ?? {}
        draft.columnWidths[key] = width
      }),
      self.saveState
    )

export const createListViewHandleTableChange =
  (self) =>
  (pagination, filters, sorter = {}) => {
    const { sortOrder, sortBy } = self.state
    const { field, order } = sorter

    if (sortBy !== field || sortOrder !== order) {
      const nextState = {
        sortBy: order ? field : undefined,
        sortOrder: order,
      }

      self.setState(nextState, () => {
        self.props.onTableChange?.(nextState)
        self.fetchItems?.()
      })
    }
  }

export const createChildListViewHandleTableChange =
  (self) =>
  (pagination, filters, sorter = {}) => {
    const { field, order } = sorter
    const nextState = {
      sortBy: order ? field : undefined,
      sortOrder: order,
      pageIndex: pagination?.current ?? 1,
      ...pagination,
    }

    self.setState(nextState, () => {
      self.props.onTableChange?.(nextState)
      self.saveState?.()
    })
  }

export const showTotal = (total, range) =>
  `${t('showing')} ${total > 0 ? `${range[0]}-${range[1]}` : 0} ${t('of')} ${total} ${t('items')}`

export const createHandleCustomizeOk =
  (self, saveState = false) =>
  ({ selectedColumnKeys = [], sortedColumnKeys = [], columnWidths = {} }) =>
    self.setState(
      produce((draft) => {
        const { sortBy, sortOrder, fieldSettings } = draft
        const visibleColumnKeys = isEmpty(selectedColumnKeys) ? getVisibleColumnKeys(fieldSettings) : selectedColumnKeys

        return {
          customizeColumnsVisible: false,
          selectedColumnKeys,
          sortedColumnKeys,
          columnWidths,
          ...(visibleColumnKeys.includes(sortBy) ? { sortBy, sortOrder } : { sortBy: undefined, sortOrder: undefined }),
        }
      }),
      async () => {
        await self.fetchItems?.()

        if (saveState) {
          await self.saveState?.()
        }
      }
    )

export const getPageTotals = (items = [], columns = [], pageTotals = {}) => {
  const columnKeys = columns.map((each) => each.key)
  const pageTotalKeys = Object.keys(pageTotals)

  if (
    isEmpty(items) ||
    isEmpty(columns) ||
    isEmpty(pageTotalKeys) ||
    isEmpty(intersection(columnKeys, pageTotalKeys))
  ) {
    return []
  }

  return [
    {
      id: TOTALS_ROW_ID,
      ...pageTotals,
    },
  ]
}

export const getChildPageTotals = ({ dataSource = [], columns = [], pageTotals = {}, fieldSettings = [] }) => {
  const columnKeys = columns.map((each) => each.key)
  const pageTotalsKeys = Object.keys(pageTotals)

  if (
    isEmpty(dataSource) ||
    isEmpty(columns) ||
    isEmpty(pageTotalsKeys) ||
    isEmpty(intersection(columnKeys, pageTotalsKeys))
  ) {
    return null
  }

  const calculateTotal = (dtoFieldName) => {
    try {
      const { totalCalculationType } = fieldSettings.find((one) => one.dtoFieldName === dtoFieldName)

      const total = sum(dataSource.map((each) => get(each, dtoFieldName, 0)))

      switch (totalCalculationType) {
        case 'Min':
          return min(dataSource.map((each) => get(each, dtoFieldName)).filter((each) => !isNil(each)))

        case 'Max':
          return max(dataSource.map((each) => get(each, dtoFieldName)).filter((each) => !isNil(each)))

        case 'Count':
          return dataSource.map((each) => get(each, dtoFieldName)).filter((each) => Boolean(each)).length

        case 'Avg':
          return total / dataSource.length

        default:
          return total
      }
    } catch (error) {
      return null
    }
  }

  return {
    id: TOTALS_ROW_ID,
    ...pageTotalsKeys.reduce((acc, each) => ({ ...acc, [each]: calculateTotal(each) }), {}),
  }
}

export const filterUntitled = (each) => each?.name === ''

const reportJsonIgnore = ['pageIndex', 'pageSize', 'filterTemplateId', 'filterTemplateName']

export const getReportJSON = (response) =>
  JSON.stringify(omit(tryParseJSON(response?.value?.config?.data, {}), reportJsonIgnore))

export const parseReportJSON = (reportJSON, defaultValue) =>
  omit(normalizeJSON(tryParseJSON(reportJSON, defaultValue)), reportJsonIgnore)

export const createY2KNullTableCellComponent = (dtoFieldName) => (self, item) => {
  const value = get(item, dtoFieldName, '')
  const displayFormat =
    (self.state.fieldSettings ?? []).find((one) => one.dtoFieldName === dtoFieldName)?.displayFormat ?? 'Date'

  return !value.startsWith('2000-01-01') ? formatValue({ value, displayFormat, dtoFieldName }) : null
}
