import * as React from 'react'
import { Form } from 'antd'
import { showError } from 'helpers/errors'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { createSearchSuppliers, createDropdownRender } from 'helpers/formViews'
import Select, { Option, getOptionProps } from 'elements/Select'
import { Row, Col } from 'elements/Grid'
import SelectProductCategories from 'containers/ProductCategories/Select'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
    this.searchSuppliers = createSearchSuppliers(this)
  }

  componentDidMount() {
    const { productCategoryIds, supplierId } = this.props.filterDto

    Promise.all([
      this.props.getProductCategories({ productCategoryIds }),
      this.props.getSuppliers(supplierId ? { supplierIds: [supplierId] } : undefined),
    ]).catch((error) => showError({ error }))
  }

  render() {
    const { filterDto, suppliers } = this.props

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <Form.Item label={t('supplier')}>
              <Select
                onChange={(value) => this.setFilterValue('supplierId', value)}
                placeholder={t('all')}
                value={filterDto.supplierId}
                dropdownRender={createDropdownRender(suppliers)}
                onSearch={this.searchSuppliers}
                showSearch
              >
                {(suppliers?.items ?? [])
                  .filter((each) => each.id)
                  .map((each) => (
                    <Option key={each.id} value={each.id}>
                      <span {...getOptionProps(each)}>{each.displayName}</span>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('productCategory')}>
              <SelectProductCategories
                value={filterDto.productCategoryIds}
                placeholder={t('all')}
                onChange={(value) => this.setFilterValue('productCategoryIds', value)}
                dataSource={this.props.productCategories ?? []}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
