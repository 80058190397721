import * as React from 'react'
import { Form, Spin, Button, Input, Tabs } from 'antd'
import { get, set, range, toLower, cloneDeep, camelCase, trimStart } from 'lodash'
import produce from 'immer'
import pluralize from 'pluralize'
import { showError, ValidationError } from 'helpers/errors'
import Select, { Option, getOptionProps } from 'elements/Select'
import InputNumber from 'elements/InputNumber'
import DatePicker from 'elements/DatePicker'
import Icon from 'elements/Icon'
import {
  createLabelFactory,
  createFieldFactory,
  createSaveToleranceHistoryItems,
  createDropdownRender,
  createSearchJobTag,
  createSearchOperatorTag,
  filterByValue,
  createHandleSubmit,
  isReadOnly,
} from 'helpers/formViews'
import { findUniqOrFirst } from 'helpers/utils'
import { stopEvent } from 'helpers/events'
import serviceStatusOptions from 'options/assets/serviceStatusOptions'
import itemConditions from 'options/assets/itemConditions'
import { t } from 'helpers/i18n'
import Documents from 'containers/Documents/ChildListView'
import Tolerances from 'containers/Assets/Tolerances'
import History from 'containers/Assets/History'
import AutoComplete, { Suffix } from 'elements/AutoComplete'
import FormButtons from 'elements/FormButtons'
import { Row, Col } from 'elements/Grid'

class FormView extends React.Component {
  state = {
    listItems: [],
    warrantyItems: [],
    toleranceItems: [],
    tags: this.props.tags, // Saving tags coming from parent
  }

  constructor(props) {
    super(props)

    this.handleSubmit = createHandleSubmit(this)
    this.saveToleranceHistoryItems = createSaveToleranceHistoryItems(this)
    this.searchOperators = createSearchOperatorTag(this)
    this.searchJobs = createSearchJobTag(this)
  }

  componentDidMount() {
    this.fetchItem()
  }

  getItemId = () => [this.state.item?.id, this.props.linkTargetRecord?.assetId].find(Boolean)

  fetchItem = async ({ itemId = this.getItemId() } = {}) => {
    try {
      const { tags } = this.state
      const responses = await Promise.all([
        this.props.getItem(itemId).catch((error) => {
          this.props.onCancel?.()
          throw error
        }),
        this.props.getSettings({ type: this.props.settingsType }),
      ])

      const item = cloneDeep({ ...responses[0].value.data, ...tags }) // Applying tags coming from parent
      this.setState({ tags: null }) // Resetting tags coming from parent

      const [jobs, operators, locations] = await Promise.all([
        this.props.user.coreUserSettings.tagSettings.tag1EnabledAssets
          ? this.props.getJobs({ jobIds: item.jobId ? [item.jobId] : [] })
          : Promise.resolve(null),
        this.props.user.coreUserSettings.tagSettings.tag2EnabledAssets
          ? this.props.getOperators({ operatorIds: item.operatorId ? [item.operatorId] : [] })
          : Promise.resolve(null),
        this.props.getLocations(),
      ])

      const operatorName = trimStart(item.operatorName, '*')
      item.operatorDisplayName = item.operatorId
        ? await this.props
            .getOperator(item.operatorId)
            .then((r) => r.value.data.displayName)
            .catch(() => operatorName)
        : operatorName

      const jobName = trimStart(item.jobName, '*')
      item.jobDisplayName = item.jobId
        ? await this.props
            .getJob(item.jobId)
            .then((r) => r.value.data.displayName)
            .catch(() => jobName)
        : jobName

      const [documentItems, toleranceItems, historyItems] = await Promise.all([
        this.props.getDocumentItems({
          request: {
            domainObjectId: item.id,
            domainObjectType: 'Asset',
            documentType: 'ObjectDocument',
          },
        }),
        this.props.getToleranceItems(item.id),
        this.props.getHistoryItems(item.id),
      ])

      const tagListItems = await this.props.getTagListItems({ tagType: 'All' })

      this.setState({
        item,
        fieldSettings: responses[1].value.data.fieldSettings,
        documentItems: get(documentItems, 'value.data.items', []),
        toleranceItems: get(toleranceItems, 'value.data.items', []),
        toleranceItemsOriginal: get(toleranceItems, 'value.data.items', []),
        toleranceItemsTotals: get(toleranceItems, 'value.data.pageTotals', {}),
        historyItems: get(historyItems, 'value.data.items', []),
        historyItemsOriginal: get(historyItems, 'value.data.items', []),
        historyItemsTotals: get(historyItems, 'value.data.pageTotals', {}),
        tagListItems: get(tagListItems, 'value.data.items', []),
        jobs: get(jobs, 'value.data', {}),
        operators: get(operators, 'value.data', {}),
        locations: get(locations, 'value.data.items', {}),
      })
    } catch (error) {
      showError({ error })
    }
  }

  saveItem = async (item) => {
    if (!item) {
      throw new Error('item is undefined')
    }

    const saved = await this.props.updateItem(item)

    await this.saveToleranceHistoryItems(saved.value.data.id)

    return saved
  }

  setItemValue = (name, value) =>
    this.setState(
      produce((draft) => {
        set(draft.item, name, value)

        this.props.form.setFieldsValue({ [name]: value })
      })
    )

  setTagValue = (key, value) =>
    this.setState(
      produce((draft) => {
        const item = get(draft, `${pluralize(key)}.items`, []).find((one) => one.displayName === value)

        set(draft, `item.${key}DisplayName`, value)
        ;['id', 'barcode', 'name', 'number'].forEach((one) => {
          set(draft, `item.${camelCase(`${key}-${one}`)}`, get(item, one))
        })

        set(draft, key, item)

        this.props.form.setFieldsValue({ [`${key}DisplayName`]: value })
      })
    )

  beforeValidateFields = () => {
    const promises = []
    const { item, tagListItems, operators, jobs } = this.state
    const { operatorId, operatorDisplayName, jobId, jobDisplayName } = item

    if (
      (operatorId || operatorDisplayName) &&
      this.props.user.coreUserSettings.tagSettings.tag2EnabledAssets
    ) {
      promises.push(
        new Promise((resolve) => {
          this.setState(
            produce((draft) => {
              draft.operator = operatorId
                ? operators.items.find((one) => one.id === operatorId)
                : findUniqOrFirst(operators.items, 'displayName', operatorDisplayName)

              draft.operatorDisplayName = draft.operator?.displayName ?? operatorDisplayName
              ;['id', 'barcode', 'name', 'number'].forEach((one) => {
                set(draft.item, camelCase(`operator-${one}`), get(draft.operator, one))
              })

              this.props.form.setFieldsValue({ operatorDisplayName: draft.item.operatorDisplayName })
            }),
            resolve
          )
        })
      )
    }

    if ((jobId || jobDisplayName) && this.props.user.coreUserSettings.tagSettings.tag1EnabledAssets) {
      promises.push(
        new Promise((resolve) => {
          this.setState(
            produce((draft) => {
              draft.job = jobId
                ? jobs.items.find((one) => one.id === jobId)
                : findUniqOrFirst(jobs.items, 'displayName', jobDisplayName)

              draft.jobDisplayName = draft.job?.displayName ?? draft.jobDisplayName
              ;['id', 'barcode', 'name', 'number'].forEach((one) => {
                set(draft.item, camelCase(`job-${one}`), get(draft.job, one))
              })

              this.props.form.setFieldsValue({ jobDisplayName: draft.item.jobDisplayName })
            }),
            resolve
          )
        })
      )
    }

    ;['A', 'B', 'C', 'D', 'E'].forEach((type) => {
      if (this.props.user.coreUserSettings.tagSettings[`tag${type}EnabledAssets`]) {
        const filteredTagListItems = tagListItems
          .filter((each) => each.tagType === type)
          .map((each) => each.description)
          .filter((each) => toLower(each).indexOf(toLower(get(item, `tag${type}`))) > -1)

        if (filteredTagListItems.length === 1) {
          promises.push(
            new Promise((resolve) => {
              this.setState(
                produce((draft) => {
                  set(draft, `item.tag${type}`, filteredTagListItems[0])

                  this.props.form.setFieldsValue({ [`tag${type}`]: filteredTagListItems[0] })
                }),
                resolve
              )
            })
          )
        }
      }
    })

    return Promise.all(promises)
  }

  transformSaveItemParams = ({ params }) => ({
    jobName: params.jobId
      ? (this.state.jobs?.items ?? []).find((one) => one.id === params.jobId)?.name ?? params.jobDisplayName
      : params.jobDisplayName,
    operatorName: params.operatorId
      ? (this.state.operators?.items ?? []).find((one) => one.id === params.operatorId)?.name ??
        params.operatorDisplayName
      : params.operatorDisplayName,
    jobDisplayName: undefined,
    operatorDisplayName: undefined,
  })

  render() {
    const { item, fieldSettings, tagListItems, jobs, operators } = this.state

    if (!item || !fieldSettings) {
      return <Spin />
    }

    const createLabel = createLabelFactory(fieldSettings)
    const createFieldDecorator = createFieldFactory(this.props.form, item, fieldSettings)

    const showTags = ['2', '1', 'A', 'B', 'C', 'D', 'E'].some((type) =>
      get(this.props.user, `coreUserSettings.tagSettings.tag${type}EnabledAssets`)
    )
    const readOnly = isReadOnly(this)

    return (
      <Form layout="vertical" colon={false} onSubmit={readOnly ? stopEvent : this.handleSubmit}>
        <div className="form-items-container">
          <ValidationError errors={this.state.validationErrors} />
          <Row>
            <Col third>
              <Form.Item label={createLabel('locationId')}>
                {createFieldDecorator('locationId')(
                  <Select
                    allowClear={false}
                    placeholder={t('none')}
                    onChange={(value) => this.setItemValue('locationId', value)}
                    readOnly={readOnly}
                  >
                    {this.state.locations.map((each) => (
                      <Option key={each.id} value={each.id}>
                        <span {...getOptionProps(each)}>{each.displayName}</span>
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col third>
              <Form.Item label={createLabel('binLocation')}>
                <Input value={item.binLocation} disabled />
              </Form.Item>
            </Col>
            <Col third>
              <Form.Item label={createLabel('serviceStatus')}>
                {createFieldDecorator('serviceStatus')(
                  <Select
                    onChange={(value) => this.setItemValue('serviceStatus', value)}
                    placeholder={t('none')}
                    allowClear={false}
                    disabled={item.status === 'Out'}
                  >
                    {Object.entries(serviceStatusOptions).map(([key, value]) => (
                      <Option key={key} value={key}>
                        {t(value)}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col third>
              <Form.Item label={createLabel('barcode')}>
                <Input value={item.barcode} disabled />
              </Form.Item>
            </Col>
            <Col third>
              <Form.Item label={createLabel('itemNumber')}>
                <Input value={item.itemNumber} disabled />
              </Form.Item>
            </Col>
            <Col third>
              <Form.Item label={createLabel('manufacturer')}>
                <Input value={item.manufacturer} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={16} xs={24}>
              <Form.Item label={createLabel('description')}>
                <Input value={item.description} disabled />
              </Form.Item>
            </Col>
            <Col third>
              <Form.Item label={createLabel('replacementBarcode')}>
                <div className="tofino-label-buttons">
                  <Button size="small" disabled>
                    <Icon type="ShoppingCart" />
                  </Button>
                </div>
                <Input value={item.replacementBarcode} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Tabs
            activeKey={this.state.tabsActiveKey || 'maintenance'}
            onChange={(tabsActiveKey) => this.setState({ tabsActiveKey })}
          >
            <Tabs.TabPane key="maintenance" tab={t('maintenance')} forceRender>
              <Row>
                <Col third>
                  <Form.Item label={createLabel('lastCalibrationDate')}>
                    <DatePicker
                      onChange={(value) => this.setItemValue('lastCalibrationDate', value)}
                      value={item.lastCalibrationDate}
                    />
                  </Form.Item>
                </Col>
                <Col third>
                  <Form.Item label={createLabel('nextCalibrationDate')}>
                    <DatePicker
                      onChange={(value) => this.setItemValue('nextCalibrationDate', value)}
                      value={item.nextCalibrationDate}
                    />
                  </Form.Item>
                </Col>
                <Col third>
                  <Form.Item label={createLabel('serviceHours')}>
                    {createFieldDecorator('serviceHours')(<InputNumber min={0} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col third>
                  <Form.Item label={createLabel('itemCondition')}>
                    {createFieldDecorator('itemCondition')(
                      <Select
                        onChange={(value) => this.setItemValue('itemCondition', value)}
                        placeholder={t('none')}
                        allowClear={false}
                      >
                        {Object.entries(itemConditions).map(([key, value]) => (
                          <Option key={key} value={key}>
                            {t(value)}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col third>
                  <Form.Item label={createLabel('lifeRemaining')}>
                    {createFieldDecorator('lifeRemaining')(
                      <Select
                        onChange={(value) => this.setItemValue('lifeRemaining', value)}
                        placeholder={t('select')}
                        allowClear={false}
                      >
                        {range(100, -10, -10).map((each) => (
                          <Option key={each} value={each}>{`${each}%`}</Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col third>
                  <Form.Item label={createLabel('mileage')}>
                    {createFieldDecorator('mileage')(<InputNumber min={0} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={createLabel('historyComment')}>
                {createFieldDecorator('historyComment')(<Input.TextArea rows={5} />)}
              </Form.Item>
            </Tabs.TabPane>
            {showTags && (
              <Tabs.TabPane key="tags" tab={t('tags')} forceRender>
                <Row>
                  <Col half>
                    {this.props.user.coreUserSettings.tagSettings.tag2EnabledAssets && (
                      <Form.Item label={this.props.customer.tagSettings.tag2}>
                        {this.props.form.getFieldDecorator('operatorDisplayName', {
                          rules: [
                            ...(this.props.customer.tagSettings.tag2Required
                              ? [
                                  {
                                    required: true,
                                    message: t('requiredField'),
                                  },
                                ]
                              : []),
                          ],
                          initialValue: item.operatorDisplayName,
                          validateTrigger: false,
                        })(
                          <AutoComplete
                            defaultActiveFirstOption={false}
                            dataSource={(operators?.items ?? []).map((each) => each.displayName)}
                            dropdownRender={createDropdownRender(operators)}
                            onChange={(value) => this.setTagValue('operator', value)}
                            onSearch={this.searchOperators}
                          >
                            <Input
                              onPressEnter={stopEvent}
                              autoComplete="off"
                              suffix={<Suffix />}
                              allowClear
                            />
                          </AutoComplete>
                        )}
                      </Form.Item>
                    )}
                    {this.props.user.coreUserSettings.tagSettings.tag1EnabledAssets && (
                      <Form.Item label={this.props.customer.tagSettings.tag1}>
                        {this.props.form.getFieldDecorator('jobDisplayName', {
                          rules: [
                            ...(this.props.customer.tagSettings.tag1Required
                              ? [
                                  {
                                    required: true,
                                    message: t('requiredField'),
                                  },
                                ]
                              : []),
                          ],
                          initialValue: item.jobDisplayName,
                          validateTrigger: false,
                        })(
                          <AutoComplete
                            defaultActiveFirstOption={false}
                            dataSource={(jobs?.items ?? []).map((each) => each.displayName)}
                            dropdownRender={createDropdownRender(jobs)}
                            onChange={(value) => this.setTagValue('job', value)}
                            onSearch={this.searchJobs}
                          >
                            <Input
                              onPressEnter={stopEvent}
                              autoComplete="off"
                              suffix={<Suffix />}
                              allowClear
                            />
                          </AutoComplete>
                        )}
                      </Form.Item>
                    )}
                    {['A', 'B', 'C', 'D', 'E'].map(
                      (type) =>
                        this.props.user.coreUserSettings.tagSettings[`tag${type}EnabledAssets`] && (
                          <Form.Item key={type} label={this.props.customer.tagSettings[`tag${type}`]}>
                            {this.props.form.getFieldDecorator(`tag${type}`, {
                              rules: [
                                ...(this.props.customer.tagSettings[`tag${type}Required`]
                                  ? [
                                      {
                                        required: true,
                                        message: t('requiredField'),
                                      },
                                    ]
                                  : []),
                                ...(this.props.customer.tagSettings[`tag${type}Restricted`]
                                  ? [
                                      {
                                        type: 'enum',
                                        enum: tagListItems
                                          .filter((each) => each.tagType === type)
                                          .map((each) => each.description),
                                        message: t('invalidValueEntered'),
                                      },
                                    ]
                                  : []),
                              ],
                              initialValue: get(item, `tag${type}`),
                              validateTrigger: false,
                            })(
                              this.props.customer.tagSettings[`tag${type}ShowList`] ? (
                                <AutoComplete
                                  defaultActiveFirstOption={false}
                                  dataSource={tagListItems
                                    .filter((each) => each.tagType === type)
                                    .map((each) => each.description)}
                                  filterOption={filterByValue}
                                  onChange={(value) => this.setItemValue(`tag${type}`, value)}
                                >
                                  <Input
                                    onPressEnter={stopEvent}
                                    autoComplete="off"
                                    suffix={<Suffix />}
                                    allowClear
                                  />
                                </AutoComplete>
                              ) : (
                                <Input onPressEnter={stopEvent} autoComplete="off" allowClear />
                              )
                            )}
                          </Form.Item>
                        )
                    )}
                  </Col>
                </Row>
              </Tabs.TabPane>
            )}
            <Tabs.TabPane key="tolerances" tab={t('tolerances')} forceRender>
              <Tolerances
                settingsType="jobs"
                items={this.state.toleranceItems ?? []}
                pageTotals={this.state.toleranceItemsTotals ?? {}}
                onChange={(values) => this.setState({ toleranceItems: values })}
                parentRecord={item}
              />
            </Tabs.TabPane>
            <Tabs.TabPane key="history" tab={t('history')} forceRender>
              <History
                items={this.state.historyItems ?? []}
                pageTotals={this.state.historyItemsTotals ?? {}}
                onChange={(values) => this.setState({ historyItems: values })}
              />
            </Tabs.TabPane>
            <Tabs.TabPane key="documents" tab={t('documents')} forceRender>
              <Documents
                domainObjectId={item.id}
                domainObjectType="Asset"
                items={this.state.documentItems ?? []}
                readOnly
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className="form-buttons-container">
          <Row>
            <Col xs={12} className="text-left">
              {item?.status === 'In' && (
                <Button type="primary" onClick={() => this.setItemValue('status', 'Out')}>
                  {t('checkOut')}
                </Button>
              )}
              {item?.status === 'Out' && (
                <Button type="primary" onClick={() => this.setItemValue('status', 'In')}>
                  {t('checkIn')}
                </Button>
              )}
            </Col>
            <Col xs={12} className="text-right">
              <FormButtons
                readOnly={readOnly}
                onCancel={this.props.onCancel}
                onSubmit={this.handleSubmit}
                saveButtonLoading={this.state.saveButtonLoading}
                saveAndCloseButtonLoading={this.state.saveAndCloseButtonLoading}
              />
            </Col>
          </Row>
        </div>
      </Form>
    )
  }
}

export default Form.create()(FormView)
