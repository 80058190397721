import { connect } from 'react-redux'
import selectors from 'selectors'
import locationGroupActions from 'actions/locationGroups'
import { createAuthStateToProps } from 'helpers/auth'
import Component from 'components/Reports/OverstockInventoryCycleCountDifferenceReport/Filter'

const mapStateToProps = (state, props) => ({
  ...createAuthStateToProps(state, props),
  locationGroups: selectors.locationGroups.selectOptions(state),
})

export default connect(mapStateToProps, {
  getLocationGroups: locationGroupActions.getOptions,
})(Component)
