import { createListViewContainer } from 'factories/ListView'
import selectors from 'selectors'
import actions from 'actions/locations'
import inventoryActions from 'actions/inventory'
import Component from 'components/Locations'

export default createListViewContainer({
  actions,
  selectors: selectors.locations,
  extraDispatchToProps: (dispatch, props) => ({
    moveAssets: (params) => dispatch(actions.moveAssets(params)),
    checkInAssets: (params) => dispatch(actions.checkInAssets(params)),
    getLocations: (params) => dispatch(actions.getItems(params)),
    duplicateInventory: (params) => dispatch(inventoryActions.duplicate(params)),
  }),
})(Component)
