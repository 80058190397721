import * as React from 'react'
import { Form } from 'antd'
import { get, cloneDeep, set, isNil, unset } from 'lodash'
import { showError } from 'helpers/errors'
import itemAssociationStatus from 'options/assets/itemAssociationStatus'
import itemReorderStatus from 'options/assets/itemReorderStatus'
import { createSetFilterValue } from 'helpers/filters'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import Help from 'elements/Help'
import Select, { Option, getOptionProps } from 'elements/Select'
import SelectProductCategories from 'containers/ProductCategories/Select'
import { Row, Col } from 'elements/Grid'

const inServiceStatusFilterOptions = {
  All: 'all',
  InServiceOnly: 'inServiceOnly',
  OutOfServiceOnly: 'outOfServiceOnly',
}

const NOT_ASSOCIATED_WITH_ASSET = 'IncludeItemsNotAssociatedWithAsset'

class Filter extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.setFilterValue = createSetFilterValue(this)
  }

  componentDidMount() {
    this.fetchSettings()
  }

  fetchSettings = async () => {
    try {
      const { assetLocationGroupIds, inventoryLocationGroupIds, productCategoryIds } = this.props.filterDto
      const [locationGroups, assetLocations, inventoryLocations] = await Promise.all([
        this.props.getLocationGroups(),
        this.props.getLocations(
          assetLocationGroupIds ? { locationGroupIds: assetLocationGroupIds } : undefined
        ),
        this.props.getLocations(
          inventoryLocationGroupIds ? { locationGroupIds: inventoryLocationGroupIds } : undefined
        ),
        this.props.getAssetCategories(),
        this.props.getProductCategories({ productCategoryIds }),
      ])

      this.setState({
        assetLocationGroups: get(locationGroups, 'value.data.items', []),
        inventoryLocationGroups: get(locationGroups, 'value.data.items', []),
        assetLocations: get(assetLocations, 'value.data.items', []),
        inventoryLocations: get(inventoryLocations, 'value.data.items', []),
      })
    } catch (error) {
      showError({ error })
    }
  }

  updateFilterValue = async (name, value) => {
    const params = cloneDeep(this.props.filterDto)

    let { assetLocations, inventoryLocations } = this.state

    try {
      if (name === 'assetLocationGroupIds') {
        unset(params, 'assetLocationIds')

        const response = await this.props.getLocations(value ? { locationGroupIds: value } : undefined)

        assetLocations = get(response, 'value.data.items', [])

        this.setState({ assetLocations })
      }

      if (name === 'inventoryLocationGroupIds') {
        unset(params, 'inventoryLocationIds')

        const response = await this.props.getLocations(value ? { locationGroupIds: value } : undefined)

        inventoryLocations = get(response, 'value.data.items', [])

        this.setState({ inventoryLocations })
      }
    } catch (error) {
      showError({ error })
    }

    if (!isNil(name)) {
      if (!isNil(value)) {
        set(params, name, value)
      } else {
        set(params, name, undefined)
      }
    }

    this.props.onChange({ ...params, assetLocations, inventoryLocations })
  }

  render() {
    const { filterDto = {} } = this.props

    const isNotAssociated = filterDto.itemAssociationStatus === NOT_ASSOCIATED_WITH_ASSET

    return (
      <Form layout="vertical" colon={false} onSubmit={stopEvent}>
        <Row>
          <Col third>
            <Form.Item label={t('assetLocationGroup')}>
              <Select
                onChange={(values) => this.updateFilterValue('assetLocationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.assetLocationGroupIds}
                mode="multiple"
              >
                {(this.state.assetLocationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('assetLocation')}>
              <Select
                onChange={(value) => this.setFilterValue('assetLocationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.assetLocationIds}
              >
                {(this.state.assetLocations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName ?? each.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('assetCategory')}>
              <Select
                onChange={(value) => this.setFilterValue('assetCategoryIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.assetCategoryIds}
              >
                {(this.props.assetCategories ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item label={t('inventoryLocationGroup')}>
              <Select
                onChange={(values) => this.updateFilterValue('inventoryLocationGroupIds', values)}
                placeholder={t('all')}
                value={filterDto.inventoryLocationGroupIds}
                mode="multiple"
              >
                {(this.state.inventoryLocationGroups ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('inventoryLocation')}>
              <Select
                onChange={(value) => this.setFilterValue('inventoryLocationIds', value)}
                mode="multiple"
                placeholder={t('all')}
                value={filterDto.inventoryLocationIds}
              >
                {(this.state.inventoryLocations ?? []).map((each) => (
                  <Option key={each.id} value={each.id}>
                    <span {...getOptionProps(each)}>{each.displayName}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('productCategory')}>
              <SelectProductCategories
                value={filterDto.productCategoryIds}
                placeholder={t('all')}
                onChange={(value) => this.setFilterValue('productCategoryIds', value)}
                dataSource={this.props.productCategories ?? []}
              />
            </Form.Item>
          </Col>
          <Col third>
            <Form.Item
              label={
                <span>
                  {t('itemAssociation')} <Help title={t('itemAssociationInfo')} />
                </span>
              }
            >
              <Select
                onChange={(value) => this.setFilterValue('itemAssociationStatus', value)}
                placeholder={t('all')}
                value={filterDto.itemAssociationStatus}
              >
                {Object.entries(itemAssociationStatus).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('assetServiceStatus')}>
              <Select
                onChange={(value) => this.setFilterValue('inServiceStatus', value)}
                placeholder={t('all')}
                value={filterDto.inServiceStatus}
                disabled={isNotAssociated}
              >
                {Object.entries(inServiceStatusFilterOptions).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('itemReorderStatus')} <Help title={t('reorderStatusInfo')} />
                </span>
              }
            >
              <Select
                onChange={(value) => this.setFilterValue('reOrderStatus', value)}
                placeholder={t('all')}
                value={filterDto.reOrderStatus}
              >
                {Object.entries(itemReorderStatus).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Filter)
