import 'core-js'
import ReactDOM from 'react-dom'
import { Spin } from 'antd'
import { Provider as StoreProvider } from 'react-redux'
import { enableAllPlugins } from 'immer'
import i18n from 'actions/i18n'
import store from 'helpers/store'
import { AUTH_SESSION_KEY } from 'options/auth'
import { getSessionItem } from 'helpers/sessionStorage'
import * as serviceWorker from './serviceWorker'
import App from 'components/App'
import { GlobalStyle } from './styles'

enableAllPlugins()

const { REACT_APP_NAME = 'TRMS20-Web', REACT_APP_VERSION = 'x.x.x', NODE_ENV } = process.env
console.log(`${REACT_APP_NAME.toUpperCase()} v${REACT_APP_VERSION} (${window.getEnv()})`)

if (NODE_ENV === 'production') {
  fetch(`/asset-manifest.json?t=${Date.now()}`)
}

const root = document.getElementById('root')
const locale = getSessionItem(AUTH_SESSION_KEY, {}).current?.user?.languageCode ?? 'en'

;(async () => {
  ReactDOM.render(
    <>
      <GlobalStyle />
      <Spin className="m-12" />
    </>,
    root
  )

  try {
    await store.dispatch(i18n.getTranslations(locale))

    ReactDOM.render(
      <StoreProvider store={store}>
        <GlobalStyle />
        <App />
      </StoreProvider>,
      root
    )
  } catch (error) {
    ReactDOM.render(
      <>
        <GlobalStyle />
        <div className="p-12">{error.message}</div>
      </>,
      root
    )
  }
})()

// Learn more: https://cra.link/PWA
serviceWorker.unregister()
