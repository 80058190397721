import * as React from 'react'
import styled from 'styled-components'
import { Button, Radio, Table } from 'antd'
import { isEmpty, set } from 'lodash'
import { useImmer } from 'use-immer'
import { sortByKeys, moveByDelta } from 'helpers/utils'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import { Row, Col } from 'elements/Grid'

const List = styled(Table)`
  .ant-table-header {
    overflow: hidden !important;
    margin: 0 !important;
  }
`

function Component(props) {
  const {
    title = t('customizeColumns'),
    header = t('column'),
    columnWidths = {},
    displayableColumns = [],
    sortedColumnKeys = [],
    selectedColumnKeys = [],
  } = props

  const [state, updateState] = useImmer({
    dataSource: [],
    selectedRowKeys: [],
  })

  function setState(path, value) {
    updateState((draft) => {
      set(draft, path, value)
    })
  }

  function moveHighlightedFieldName(delta) {
    updateState((draft) => {
      draft.dataSource = moveByDelta(
        draft.dataSource,
        draft.dataSource.findIndex((one) => one.dtoFieldName === state.highlightedRowKey),
        delta
      )
    })
  }

  function handleRestoreDefaultsClick() {
    const defaultSelectedRowKeys = displayableColumns
      .filter((each) => each.displayByDefault)
      .map((each) => each.dtoFieldName)

    updateState((draft) => {
      draft.resetColumnWidths = true
      draft.highlightedRowKey = null
      draft.selectedRowKeys = !isEmpty(defaultSelectedRowKeys)
        ? defaultSelectedRowKeys
        : displayableColumns.map((each) => each.dtoFieldName)
      draft.dataSource = displayableColumns.map((each) => ({
        column: t(each.columnHeadingLanguageKey || each.recordLabelLanguageKey || each.dtoFieldName),
        ...each,
      }))
    })
  }

  function handleModalOkClick() {
    const dtoFieldNames = (state?.dataSource ?? []).map((each) => each.dtoFieldName)

    props.onOk({
      sortedColumnKeys: dtoFieldNames,
      selectedColumnKeys: dtoFieldNames.filter((each) => (state?.selectedRowKeys ?? []).includes(each)),
      columnWidths: state.resetColumnWidths ? {} : columnWidths,
    })
  }

  React.useEffect(() => {
    if (props.visible) {
      updateState((draft) => {
        draft.resetColumnWidths = false
        draft.highlightedRowKey = null
        draft.selectedRowKeys = !isEmpty(selectedColumnKeys)
          ? selectedColumnKeys
          : displayableColumns.filter((each) => each.displayByDefault).map((each) => each.dtoFieldName)
        draft.dataSource = sortByKeys(
          displayableColumns.map((each) => ({
            key: each.dtoFieldName,
            column: t(each.columnHeadingLanguageKey || each.recordLabelLanguageKey || each.dtoFieldName),
            ...each,
          })),
          sortedColumnKeys
        )
      })
    }
  }, [props.visible])

  return (
    <Modal
      title={title}
      visible={props.visible}
      okText={t('update')}
      okButtonProps={{ disabled: isEmpty(state.selectedRowKeys) }}
      onOk={handleModalOkClick}
      onCancel={props.onCancel}
    >
      <Row type="flex" justify="space-between" align="middle">
        <Col xs={21}>
          <List
            rowKey={(record) => record.dtoFieldName}
            columns={[
              {
                key: 'column',
                title: `${header} (${state?.selectedRowKeys?.length ?? 0} ${t('selected')})`,
                dataIndex: 'column',
              },
              {
                key: 'order',
                align: 'center',
                width: 60,
                render: (each) => (
                  <Radio
                    checked={each.dtoFieldName === state.highlightedRowKey}
                    onChange={() => setState('highlightedRowKey', each.dtoFieldName)}
                  />
                ),
              },
            ]}
            dataSource={state.dataSource}
            size="small"
            pagination={false}
            scroll={{ y: 250 }}
            rowSelection={{
              selectedRowKeys: state.selectedRowKeys,
              onChange: (rowKeys) => setState('selectedRowKeys', rowKeys),
            }}
          />
        </Col>
        <Col xs={3}>
          <Button
            type="primary"
            className="mb-12"
            onClick={() => moveHighlightedFieldName(-1)}
            disabled={!state.highlightedRowKey}
          >
            <Icon type="KeyboardArrowUp" />
          </Button>
          <Button
            type="primary"
            onClick={() => moveHighlightedFieldName(1)}
            disabled={!state.highlightedRowKey}
          >
            <Icon type="KeyboardArrowDown" />
          </Button>
        </Col>
      </Row>
      <Button style={{ position: 'absolute', bottom: '10px' }} onClick={handleRestoreDefaultsClick}>
        {t('restoreDefaults')}
      </Button>
    </Modal>
  )
}

export default Component
