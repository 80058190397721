import * as React from 'react'
import Chart from 'echarts-for-react/lib/core'
import {
  getLegend,
  getTitle,
  getPrimaryAxisLabel,
  getDimensionAxisLabel,
  getScatterSeries,
  getToolbox,
} from 'helpers/charts'
import { tryParseJSON } from 'helpers/utils'
import { DashboardContext } from 'helpers/dashboards'

function Component(props) {
  const { echarts, theme } = React.useContext(DashboardContext)
  const { chart, section, data, showToolbox } = props
  const { showGridlines, dimensionTitle, primaryMetricTitle } = tryParseJSON(chart.chartJson, {})

  const option = {
    toolbox: showToolbox ? getToolbox({ theme }) : undefined,
    dataZoom: [
      {
        type: 'slider',
        xAxisIndex: 0,
        filterMode: 'empty',
      },
      {
        type: 'slider',
        yAxisIndex: 0,
        filterMode: 'empty',
      },
      {
        type: 'inside',
        xAxisIndex: 0,
        filterMode: 'empty',
      },
      {
        type: 'inside',
        yAxisIndex: 0,
        filterMode: 'empty',
      },
    ],
    tooltip: {},
    grid: { containLabel: true },
    title: getTitle({ chart }),
    legend: getLegend({ chart }),
    xAxis: {
      name: dimensionTitle,
      splitLine: { show: showGridlines },
      axisLabel: getDimensionAxisLabel({ chart }),
    },
    yAxis: {
      name: primaryMetricTitle,
      splitLine: { show: showGridlines },
      axisLabel: getPrimaryAxisLabel({ chart }),
    },
    series: getScatterSeries({ chart, data }),
  }

  return (
    <Chart
      echarts={echarts}
      theme={theme?.name}
      style={{ width: '100%', minHeight: `${section.height}px` }}
      option={option}
    />
  )
}

export default Component
