import { orderBy } from 'lodash'
import { createSorter } from 'helpers/listViews'
import { t } from 'helpers/i18n'

const MINIMUM_COLUMN_WIDTH = 104

export const getColumns = ({ fieldSettings, sortBy, sortOrder, onResize, columnWidths = {} }) =>
  orderBy(fieldSettings, ['defaultColumnNumber']).map((column) => {
    const {
      dtoFieldName,
      columnWidth,
      columnHeadingLanguageKey,
      columnHeadingTooltipLanguageKey,
      columnHeadingIconName,
      recordLabelLanguageKey,
    } = column

    return {
      key: dtoFieldName,
      dataIndex: dtoFieldName,
      sorter: createSorter(dtoFieldName),
      sortOrder: dtoFieldName === sortBy ? sortOrder : null,
      width: columnWidths[dtoFieldName]
        ? Math.max(columnWidths[dtoFieldName], MINIMUM_COLUMN_WIDTH)
        : columnWidth
          ? Math.max(columnWidth, MINIMUM_COLUMN_WIDTH)
          : MINIMUM_COLUMN_WIDTH,
      title: t(columnHeadingLanguageKey || recordLabelLanguageKey || dtoFieldName),
      onHeaderCell: (header) => ({
        columnHeadingTooltipLanguageKey,
        columnHeadingIconName,
        width: header.width,
        onResize: onResize(header),
      }),
    }
  })

export const mapToFieldSettings = (row, fieldSettings) =>
  orderBy(fieldSettings, ['defaultColumnNumber'])
    .map((each) => each.dtoFieldName)
    .reduce((acc, each, index) => ({ ...acc, [each]: row[index] }), {})
