import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import inventoryActions from 'actions/inventory'
import overstockLocationActions from 'actions/overstockLocations'
import overstockInventoryActions from 'actions/overstockInventory'
import locationActions from 'actions/locations'
import locationGroupActions from 'actions/locationGroups'
import Component from 'components/Inventory/Transfer'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  getLocation: locationActions.getItem,
  getLocations: locationActions.getItems,
  getLocationGroup: locationGroupActions.getItem,
  getLocationGroups: locationGroupActions.getItems,
  getOverstockLocation: overstockLocationActions.getItem,
  getOverstockLocations: overstockLocationActions.getItems,
  getInventoryItem: inventoryActions.getItem,
  getInventoryItems: inventoryActions.getItems,
  getInventoryMoveData: inventoryActions.getMoveData,
  moveInventoryItems: inventoryActions.moveItems,
  getOverstockInventoryItem: overstockInventoryActions.getItem,
  getOverstockInventoryItems: overstockInventoryActions.getItems,
  getOverstockInventoryMoveData: overstockInventoryActions.getMoveData,
  moveOverstockInventoryItems: overstockInventoryActions.moveItems,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
