import * as React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import cx from 'clsx'
import { InputNumber as AntInputNumber } from 'antd'
import { isFinite, toString as str } from 'lodash'

function InputNumber(props) {
  const {
    forwardedRef,
    onChange = () => {},
    className,
    allowClear = false,
    allowSteppers = true,
    currency = false,
    disabled = false,
    debounce = 0,
    key: _IGNORE_KEY,
    value: _IGNORE_VALUE,
    defaultValue: _IGNORE_DEFAULT,
    ...rest
  } = props

  const numberValue = isFinite(props.value) ? props.value : allowClear ? null : 0

  const changeCallback = (e) => {
    if (isFinite(e)) {
      onChange(e)
    } else if (allowClear) {
      onChange(null)
    } else {
      onChange(0)
    }
  }

  const debouncedCallback = useDebouncedCallback(changeCallback, debounce)
  const value = debounce ? { defaultValue: numberValue } : { value: numberValue }

  return (
    <AntInputNumber
      key={debounce ? Date.now() : props.key}
      ref={forwardedRef}
      onChange={debounce ? debouncedCallback : changeCallback}
      onFocus={(e) => {
        if (str(e.target.value) === '0') {
          e.target.select()
        }
      }}
      onBlur={() => {
        debouncedCallback.flush()
      }}
      className={cx('w-full', { [className]: !disabled }, { 'hide-steppers': !allowSteppers || currency })}
      disabled={disabled}
      {...(currency
        ? {
            formatter: (e) => (e < 0 ? `-$${str(e).replace(/[$-]/g, '')}` : `$${str(e).replace(/[$]/g, '')}`),
            parser: (e) => str(e).replace(/\$/g, ''),
          }
        : {})}
      {...value}
      {...rest}
    />
  )
}

export default React.forwardRef((props, ref) => <InputNumber {...props} forwardedRef={ref} />)
