import styled from 'styled-components'
import { Button, Radio } from 'antd'

export const Title = styled.div`
  margin-bottom: 12px;
`

export const Container = styled.div`
  overflow-x: auto;

  & > table {
    width: 100%;
    border: 1px solid #d9d9d9;

    & > thead > tr > th {
      color: #333;
      border: 1px solid #d9d9d9;
      padding: 16px 24px;
      padding-bottom: 0;
      white-space: nowrap;
      font-size: 16px;
      font-weight: 500;

      .ant-col {
        margin-bottom: 16px;
      }
    }

    & > tbody > tr > td {
      vertical-align: top;
      border: 1px solid #d9d9d9;
      padding: 16px 24px;

      .ant-form-item {
        margin-bottom: 6px !important;
      }
    }
  }
`

export const Calendar = styled.div`
  & > table {
    width: 100%;
    border: 1px solid #d9d9d9;

    & > thead > tr > th {
      padding: 3px 6px;
      font-size: 14px;
      border: 1px solid #d9d9d9;
      text-align: center;
      font-weight: normal;
    }

    & > tbody > tr > td {
      border: 1px solid #d9d9d9;
      vertical-align: top;
    }
  }
`

export const SelectMode = styled(Radio.Group)`
  font-weight: normal;
`

export const ToggleButton = styled(Button)`
  padding: 0;
  height: unset;
  border: none;
  box-shadow: none;
  vertical-align: middle;
`

export const Totals = styled.div`
  position: relative;
`

export const TotalValue = styled.div`
  border-radius: 3px;
  margin: 0 24px 12px 0;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? '#f5f5f5' : 'inherit')};
  padding: 3px;
`

export const Summary = styled.div`
  overflow: auto;
  max-height: 300px;
  max-width: 250px;
  margin: -12px -16px;
  padding: 12px 16px;
`

export const Bullet = styled.span`
  background-color: ${(props) => props.color};
  display: inline-block;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  vertical-align: middle;
  margin-right: 6px;
  line-height: 14px;
`

export const Tile = styled.div`
  break-inside: avoid;
  margin-top: 6px;
  opacity: ${(props) => (props.isSelectedByTotal && props.isSelectedByColor ? 1 : 0.4)};
`

export const TileHeader = styled.div`
  border-radius: 3px 3px 0 0;
  font-size: 11px;
  color: white;
  padding: 0 3px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`

export const TileBody = styled.div`
  padding: 1px 3px;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0 0 3px 3px;
`

export const Delete = styled.div`
  white-space: nowrap;
  padding-top: 1px;
`

export const Number = styled.div`
  cursor: pointer;
  width: 26px;
  height: 30xp;
  margin: 0 auto;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 30px;
  border-color: ${(props) => (props.isSelected ? '#1890ff' : 'transparent')};
  background-color: ${(props) => (props.isToday ? '#1890ff' : 'transparent')};
  color: ${(props) => (props.isToday ? 'white' : 'inherit')};
`

export const Colors = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  min-height: 300px;
  max-height: ${(props) => (props.printPreview ? 'inherit' : '800px')};
  overflow-y: auto;
`

export const Color = styled.div`
  line-height: 24px;
  background-color: ${(props) => (props.isSelected ? '#f5f5f5' : 'inherit')};
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
`

export const More = styled.div`
  color: #666;
  margin-top: 6px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  padding: 3px;
  background-color: #f5f5f5;
  border-style: solid;
  border-color: #f5f5f5;
  border-width: 3px;
`

export const Cell = styled.div`
  padding: 6px;
  min-height: 150px;
  pointer-events: ${(props) => (props.isSameMonth ? 'inherit' : 'none')};
  opacity: ${(props) => (props.isSameMonth ? 1 : 0.4)};
`
