export default {
  Initial: 'initial',
  Investigating: 'investigating',
  WaitingForCustomer: 'waitingForCustomer',
  WaitingForDevelopment: 'waitingForDevelopment',
  Queued: 'queued',
  UnderDevelopment: 'underDevelopment',
  AcceptanceTest: 'acceptanceTest',
  ReadyForDeployment: 'readyForDeployment',
  Closed: 'closed',
}
