import * as React from 'react'
import produce from 'immer'
import pluralize from 'pluralize'
import { Base64 } from 'js-base64'
import * as CSV from 'csv-string'
import { Form, Input, Spin, Dropdown, Menu, Button, message } from 'antd'
import { isNil, get, isEmpty, uniqBy, set, isEqual, cloneDeep } from 'lodash'
import { showError, resourceNotFound, ValidationError, showClientNotifications } from 'helpers/errors'
import { tryParseInt } from 'helpers/utils'
import {
  createLabelFactory,
  createFieldFactory,
  createHandleSubmit,
  getChangedItems,
  isReadOnly,
} from 'helpers/formViews'
import { getFileExtension } from 'helpers/downloadItems'
import { formatUserTime } from 'helpers/dateTime'
import { t } from 'helpers/i18n'
import { Emitter, stopEvent } from 'helpers/events'
import { LIST_MODAL_CLEAR_SELECTION } from 'options/events'
import FormButtons from 'elements/FormButtons'
import Icon from 'elements/Icon'
import ListItems from 'containers/Lists/Items'
import Modal from 'elements/Modal'
import SelectProducts from 'containers/Products/Select'
import SelectAssets from 'containers/Assets/Select'
import SelectUsers from 'containers/Users/Select'
import SelectListItems from 'containers/Lists/Items/Select'
import Drawer from 'elements/Drawer'
import FileUpload from 'elements/FileUpload'
import SelectFileItems from 'containers/Lists/FormView/FileItems'
import Associations from 'containers/Lists/Associations'
import { Row, Col } from 'elements/Grid'

class FormView extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.handleSubmit = createHandleSubmit(this)
  }

  componentDidMount() {
    this.fetchItem()
  }

  getItemId = () => [this.state.item?.id, this.props.linkTargetRecord?.listId].find(Boolean)

  fetchItem = async ({ itemId = this.getItemId() } = {}) => {
    try {
      const item = itemId
        ? await this.props.getItem(itemId).catch((error) => {
            this.props.onCancel?.()
            throw error
          })
        : !this.props.linkTargetRecord
          ? await this.props.newItem({ type: this.props.settingsType })
          : resourceNotFound(this)

      const [fieldSettings, listItems] = await Promise.all([
        this.props.getSettings({ type: item.value.data.type }),
        item.value.data.id
          ? this.props.getListItems(item.value.data.id, {
              listType: item.value.data.type,
              listItemMode: `${item.value.data.type}ListDefault`,
              domainObjectType: 'None',
              locationId: 0,
            })
          : Promise.resolve(null),
      ])

      this.setState({
        item: cloneDeep(item.value.data),
        itemOriginal: cloneDeep(item.value.data),
        fieldSettings: fieldSettings.value.data.fieldSettings,
        listItems: get(listItems, 'value.data.items', []),
        listItemsOriginal: get(listItems, 'value.data.items', []),
        formHasChanged: false,
      })
    } catch (error) {
      showError({ error })
    }
  }

  saveItem = async (item) => {
    if (!item) {
      throw new Error('item is undefined')
    }

    const saved = item.id ? await this.props.updateItem(item) : await this.props.createItem(item)

    await this.saveListItems(saved.value.data.id)

    return saved
  }

  saveListItems = async (listId) => {
    const { creating, updating, deleting } = getChangedItems(this.state.listItemsOriginal, this.state.listItems)

    if (!isEmpty(deleting)) {
      const response = await this.props.deleteListItems(
        listId,
        deleting.map((each) => each.id)
      )

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }

    if (!isEmpty(updating)) {
      const response = await this.props.updateListItems(
        listId,
        updating.map((each) => ({ ...each, listId }))
      )

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }

    if (!isEmpty(creating)) {
      const response = await this.props.createListItems(
        listId,
        creating.map(({ id, assetToleranceId, ...rest }) => ({
          ...rest,
          listId,
        }))
      )

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }
  }

  handleActionsMenuClick = ({ key }) => {
    switch (key) {
      case 'text/csv':
      case 'text/html':
      case 'application/pdf':
      case 'application/vnd.ms-excel':
        if (this.hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          Emitter.emit('lists.listItems.downloadItems', {
            parentId: this.state.item.id,
            acceptMimeType: key,
            pageTitle: this.state.item.name,
            fileExtension: getFileExtension(key),
            listType: this.state.item.type,
            listItemMode: `${this.state.item.type}ListDefault`,
            domainObjectType: 'None',
            locationId: 0,
          })
        }
        break

      case 'listAssociations':
        if (this.hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          this.setState({ listAssociationsVisible: true })
        }
        break

      default:
        message.warn(t('underDevelopment'))
    }
  }

  handleItemsTableExtraButtonsClick = ({ key }) => {
    switch (key) {
      case 'addFromProducts':
      case 'addFromAssets':
      case 'addFromUsers':
        this.setState({ addFromEntityTypeVisible: true, addFromEntityTypeItems: [] })
        break

      case 'addFromList':
        this.setState({ addFromListVisible: true, addFromListItems: [] })
        break

      case 'addFromFile':
        this.setState({ addFromFileUploadVisible: true, addFromFileSelectData: [] })
        break

      default:
        message.warn(t('underDevelopment'))
        break
    }
  }

  handleAddFromListSubmit = () => {
    try {
      this.setState({ addFromListLoading: true })

      const seed = Date.now()
      const populatedItems = this.state.addFromListItems.map((each, index) => ({
        ...each,
        id: (seed + index) * -1,
        listId: this.state.item.id,
      }))

      this.setState(
        produce((draft) => {
          draft.listItems =
            draft.item.type === 'Product'
              ? [...draft.listItems, ...populatedItems]
              : uniqBy([...draft.listItems, ...populatedItems], 'barcode')
        })
      )

      message.success(`${populatedItems.length} ${t('itemsAdded')}`)

      Emitter.emit(LIST_MODAL_CLEAR_SELECTION)
    } catch (error) {
      showError({ error })
    } finally {
      this.setState({ addFromListLoading: false })
    }
  }

  handleAddFromEntityTypeSubmit = async () => {
    try {
      this.setState({ addFromEntityTypeLoading: true })

      const listItems = this.state.addFromEntityTypeItems.map((each) => ({
        barcode: this.state.item.type === 'User' ? each.userName : each.barcode,
      }))

      const response = await this.props.populateListItems({
        listItems,
        listType: this.state.item.type,
      })

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }

      const seed = Date.now()
      const populatedItems = get(response, 'value.data.items', []).map((each, index) => ({
        ...each,
        id: each.id > 0 ? seed + index : (seed + index) * -1,
        listId: this.state.item.id,
      }))

      this.setState(
        produce((draft) => {
          draft.listItems =
            draft.item.type === 'Product'
              ? [...draft.listItems, ...populatedItems]
              : uniqBy([...draft.listItems, ...populatedItems], 'barcode')
          draft.addFromEntityTypeItems = []
          draft.addFromEntityTypeVisible = false
        })
      )
    } catch (error) {
      showError({ error })
    } finally {
      this.setState({ addFromEntityTypeLoading: false })
    }
  }

  handleAddFromFileUpload = async (fileList = []) => {
    try {
      const listItems = CSV.parse(Base64.decode(fileList[0].fileContents)).map((parts) => ({
        barcode: parts[0],
        quantity: tryParseInt(parts[1], 0),
        sequenceValue: parts[2],
      }))

      const response = await this.props.populateListItems({
        listItems,
        listType: this.state.item.type,
      })

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }

      const seed = Date.now()
      const populatedItems = get(response, 'value.data.items', []).map((each, index) => ({
        ...each,
        id: each.id > 0 ? seed + index : (seed + index) * -1,
        listId: this.state.item.id,
      }))

      this.setState({
        addFromFileUploadVisible: false,
        addFromFileSelectData: populatedItems,
        addFromFileSelectVisible: true,
      })
    } catch (error) {
      showError({ error })

      this.setState({
        addFromFileSelectVisible: false,
        addFromFileSelectItems: [],
      })
    }
  }

  handleAddFromFileSelectSubmit = () => {
    const count = this.state.addFromFileSelectItems.length

    this.setState(
      produce((draft) => {
        draft.listItems =
          draft.item.type === 'Product'
            ? [...draft.listItems, ...draft.addFromFileSelectItems]
            : uniqBy([...draft.listItems, ...draft.addFromFileSelectItems], 'barcode')
      }),
      () => message.success(`${count} ${t('itemsAdded')}`)
    )
  }

  setItemValue = (name, value) =>
    this.setState(
      produce((draft) => {
        set(draft.item, name, value)

        this.props.form.setFieldsValue({ [name]: value })
      })
    )

  hasUnsavedChanges = () =>
    this.state.formHasChanged ||
    !this.state.item?.id ||
    !isEqual(this.state.item, this.state.itemOriginal) ||
    !isEqual(this.state.listItems, this.state.listItemsOriginal)

  render() {
    const { item, fieldSettings } = this.state

    if (!item || !fieldSettings) {
      return <Spin />
    }

    const createLabel = createLabelFactory(fieldSettings)
    const createFieldDecorator = createFieldFactory(this.props.form, item, fieldSettings)

    const readOnly = isReadOnly(this)

    // console.log({ props: this.props, state: this.state })

    return (
      <>
        <Form layout="vertical" colon={false} onSubmit={readOnly ? stopEvent : this.handleSubmit}>
          {item.id ? (
            <Dropdown
              overlay={
                <Menu onClick={this.handleActionsMenuClick}>
                  <Menu.Item key="listAssociations" disabled={readOnly}>
                    {t('listAssociations...')}
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.SubMenu key="export" title={t('export')}>
                    <Menu.Item key="text/csv">{t('asCsv')}</Menu.Item>
                    <Menu.Item key="application/vnd.ms-excel">{t('asExcel')}</Menu.Item>
                    <Menu.Item key="application/pdf">{t('asPdf')}</Menu.Item>
                    <Menu.Item key="text/html">{t('asHtml')}</Menu.Item>
                  </Menu.SubMenu>
                </Menu>
              }
              trigger={['click']}
            >
              <Button className="form-items-actions">
                {t('actions')}
                <Icon type="KeyboardArrowDown" />
              </Button>
            </Dropdown>
          ) : null}
          <div className="form-items-container">
            <ValidationError errors={this.state.validationErrors} />
            <Row>
              <Col xs={24} md={12} xl={6}>
                <Form.Item label={createLabel('name')}>
                  {createFieldDecorator('name')(
                    <Input onChange={(e) => this.setItemValue('name', e.target.value)} readOnly={readOnly} autoFocus />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={6}>
                <Form.Item label={createLabel('typeName')}>
                  {createFieldDecorator('typeName')(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={6}>
                <Form.Item label={createLabel('createdDate')}>
                  <Input value={formatUserTime(item.createdDate, item.createdBy) || t('na')} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={6} xl={3}>
                <Form.Item label={createLabel('numberOfItemsOnList')}>
                  <Input value={this.state.listItems?.length ?? 0} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={6} xl={3}>
                <Form.Item label={createLabel('numberOfAssociations')}>
                  <Input value={item.numberOfAssociations} disabled />
                </Form.Item>
              </Col>
            </Row>
            <ListItems
              type={item.type}
              parentRecord={item}
              items={this.state.listItems ?? []}
              originals={this.state.listItemsOriginal ?? []}
              settingsType={`${item.type}ListDefault`}
              onChange={(values) => this.setState({ listItems: values })}
              onTableExtraButtonsClick={this.handleItemsTableExtraButtonsClick}
              readOnly={readOnly}
            />
          </div>
          <div className="form-buttons-container">
            <FormButtons
              readOnly={readOnly}
              onCancel={this.props.onCancel}
              onSubmit={this.handleSubmit}
              saveButtonLoading={this.state.saveButtonLoading}
              saveAndCloseButtonLoading={this.state.saveAndCloseButtonLoading}
            />
          </div>
        </Form>
        <Modal
          title={t(pluralize(`addFrom${item.type}`))}
          visible={this.state.addFromEntityTypeVisible}
          onCancel={() => this.setState({ addFromEntityTypeVisible: false })}
          footer={
            <>
              <Button onClick={() => this.setState({ addFromEntityTypeVisible: false })}>{t('close')}</Button>
              <Button
                onClick={this.handleAddFromEntityTypeSubmit}
                loading={this.state.addFromEntityTypeLoading}
                type="primary"
                disabled={isEmpty(this.state.addFromEntityTypeItems)}
              >
                {t('addToList')}
              </Button>
            </>
          }
          width={992}
        >
          {item.type === 'Product' ? (
            <SelectProducts
              onSelect={(values) => this.setState({ addFromEntityTypeItems: values })}
              mode="multiple"
              filterProps={{ privateDatabaseOnly: true }}
            />
          ) : item.type === 'Asset' ? (
            <SelectAssets onSelect={(values) => this.setState({ addFromEntityTypeItems: values })} mode="multiple" />
          ) : item.type === 'User' ? (
            <SelectUsers onSelect={(values) => this.setState({ addFromEntityTypeItems: values })} mode="multiple" />
          ) : null}
        </Modal>
        <Modal
          title={t('addFromList')}
          visible={this.state.addFromListVisible}
          onCancel={() => this.setState({ addFromListVisible: false })}
          footer={
            <>
              <Button onClick={() => this.setState({ addFromListVisible: false })}>{t('close')}</Button>
              <Button
                onClick={this.handleAddFromListSubmit}
                loading={this.state.addFromListLoading}
                type="primary"
                disabled={isEmpty(this.state.addFromListItems)}
              >
                {t('addToList')}
              </Button>
            </>
          }
          width={992}
        >
          <SelectListItems
            onSelect={(values) => this.setState({ addFromListItems: values })}
            settingsType={`${item.type}ListDefault`}
            mode="multiple"
            filterProps={{
              listType: item.type,
              listItemMode: `${item.type}ListDefault`,
            }}
          />
        </Modal>
        <Drawer
          title={t('addFromFile')}
          size="xs"
          visible={this.state.addFromFileUploadVisible}
          onClose={() => this.setState({ addFromFileUploadVisible: false })}
        >
          <FileUpload
            accept=".csv,text/csv"
            onUpload={this.handleAddFromFileUpload}
            onCancel={() => this.setState({ addFromFileUploadVisible: false })}
            extra={{
              type: 'info',
              message: t('fileRequirements'),
              description: t(item.type === 'Product' ? 'productListUploadInfo' : 'listUploadInfo'),
            }}
          />
        </Drawer>
        <Modal
          title={t('addFromFile')}
          visible={this.state.addFromFileSelectVisible}
          onCancel={() => this.setState({ addFromFileSelectVisible: false })}
          footer={
            <>
              <Button onClick={() => this.setState({ addFromFileSelectVisible: false })}>{t('close')}</Button>
              <Button
                onClick={this.handleAddFromFileSelectSubmit}
                type="primary"
                disabled={isEmpty(this.state.addFromFileSelectItems)}
              >
                {t('addToList')}
              </Button>
            </>
          }
          width={992}
        >
          {isNil(this.state.addFromFileSelectData) ? (
            <Spin />
          ) : (
            <SelectFileItems
              items={this.state.addFromFileSelectData ?? []}
              onSelect={(values) => this.setState({ addFromFileSelectItems: values })}
              settingsType={`${item.type}ListUpload`}
              mode="multiple"
            />
          )}
        </Modal>
        <Modal
          title={t('associations')}
          visible={this.state.listAssociationsVisible}
          onCancel={() => this.setState({ listAssociationsVisible: false })}
          footer={<Button onClick={() => this.setState({ listAssociationsVisible: false })}>{t('close')}</Button>}
          width={992}
        >
          <Associations
            settingsType={`${item.type}ListEntities`}
            filterProps={{ listType: item.type, listId: item.id }}
            onDelete={async () => {
              await this.fetchItem()
              await this.props.onSave?.(false)
            }}
            mode="multiple"
            allowDelete
          />
        </Modal>
      </>
    )
  }
}

export default Form.create()(FormView)
