import { Button } from 'antd'
import { toString as str } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import entityNames from 'options/entityNames'
import { getUserPermission } from 'helpers/auth'
import linkTargets from 'options/linkTargets'
import { t } from 'helpers/i18n'
import { tryParseInt } from 'helpers/utils'
import { createPrintHandler, resetPrintHandler } from 'helpers/print'
import { showError } from 'helpers/errors'
import Icon from 'elements/Icon'
import Modal from 'elements/Modal'
import Filter from 'containers/Orders/Filter'
import Print from 'containers/Orders/Print'

export default createListViewComponent({
  entityName: entityNames.orders,
  filterTemplateType: 'Order',
  createButtonTextId: 'createOrder',
  primaryLinkTarget: 'orderRecord',
  allowCreate: (self) => !self.props.userIsInRoleSupplier && !self.props.userIsInRoleDistributor,
  allowDelete: (self) => !self.props.userIsInRoleSupplier && !self.props.userIsInRoleDistributor,
  createDisabled: (self) => getUserPermission('Purchase') !== 'Yes',
  initialFilterDto: (self) => ({ dateRange: { dateRangeType: 'YearToDate' } }),
  tableCellComponents: {
    sourceName: (self, item) => {
      const sourceName = str(item?.sourceName)

      if (!sourceName.match(/(REQ|RFQ)(\d+)/)) {
        return sourceName
      }

      return sourceName
        .match(/(REQ|RFQ)(\d+)/g)
        .map((each) => {
          const match = each.match(/(REQ|RFQ)(\d+)/)

          return (
            <a
              key={each}
              onClick={() =>
                self.setState({
                  editDrawerLinkTarget: match[1] === 'RFQ' ? linkTargets.rfqRecord : linkTargets.requisitionRecord,
                  editDrawerLinkTargetText: match[0],
                  editDrawerLinkTargetRecord:
                    match[1] === 'RFQ'
                      ? { rfqId: tryParseInt(match[2], 0) }
                      : { requisitionId: tryParseInt(match[2], 0) },
                  editDrawerVisible: true,
                  editDrawerSaving: false,
                  editDrawerLinkTargetReadOnly: true,
                })
              }
            >
              {each}
            </a>
          )
        })
        .reduce((acc, each) => [acc, ', ', each])
    },
    supplierNames: (self, item) => <span dangerouslySetInnerHTML={{ __html: item.supplierNames }} />,
  },
  extraRowActions: (self) => (
    <>
      <Button
        disabled={self.state.selectedRowKeys.length !== 1}
        onClick={async () => {
          try {
            self.setState({ printLoading: true })

            const printItems = await Promise.all(
              self.state.selectedRowKeys.map((each) => self.props.getItem(each).then((r) => r.value.data))
            )

            self.setState({
              printItems,
              printVisible: true,
            })
          } catch (error) {
            showError({ error })
          } finally {
            self.setState({ printLoading: false })
          }
        }}
        loading={self.state.printLoading}
      >
        <Icon type="print" />
        {t('print')}
      </Button>
      <Modal
        title={t('printPreview')}
        okText={t('print')}
        onOk={createPrintHandler(self.printableRef)}
        onCancel={() => self.setState({ printVisible: false }, resetPrintHandler)}
        width={992}
        visible={self.state.printVisible}
      >
        <div ref={self.printableRef} className="tofino-print-preview">
          {(self.state.printItems ?? [])
            .filter((each) => self.state.selectedRowKeys.includes(each.id))
            .map((item) => (
              <div key={item.id} className="mb-24">
                <Print
                  parentRecord={{
                    ...item,
                  }}
                />
              </div>
            ))}
        </div>
      </Modal>
    </>
  ),
})(Filter)
