export const getSupplierIntegrationFilterOptions = (enableExternalItemStatusRequest = false) => {
  if (enableExternalItemStatusRequest) {
    return {
      All: 'all',
      PunchoutRecipient: 'punchoutRecipient',
      XmlOrderRecipient: 'xmlOrderRecipient',
      ItemStatusRequestRecipient: 'itemStatusRequestRecipient',
      SupplierPortal: 'supplierPortal',
    }
  }

  return {
    All: 'all',
    PunchoutRecipient: 'punchoutRecipient',
    XmlOrderRecipient: 'xmlOrderRecipient',
    SupplierPortal: 'supplierPortal',
  }
}

export const getSupplierIntegrationFormOptions = (enableExternalItemStatusRequest = false) => {
  if (enableExternalItemStatusRequest) {
    return {
      PunchoutRecipient: 'punchoutRecipient',
      XmlOrderRecipient: 'xmlOrderRecipient',
      ItemStatusRequestRecipient: 'itemStatusRequestRecipient',
    }
  }

  return {
    PunchoutRecipient: 'punchoutRecipient',
    XmlOrderRecipient: 'xmlOrderRecipient',
  }
}
